import { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";

function useMySocket() {
  const reduxToken = useSelector((s) => s.token);

  const mySocket = useMemo(() => {
    const url = `ws://20.13.166.30:8085/websocket?token=${reduxToken}`;

    return io(url, {
      reconnection: true,
    });
  }, [reduxToken]);

  useEffect(() => {
    mySocket.connect();

    mySocket.on("connect", () => {
      console.log("Connected to server");

      loadEvents();
    });

    const loadEvents = () => {
      mySocket.on("message", (messagereceived) => {
        const actualPayload = messagereceived.data.messages?.[0];
        if (!actualPayload.isGroup) {
          emit("incomingMessage", actualPayload);
        } else {
          emit("incomingGroup", actualPayload);
        }
      });
    };

    mySocket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    const emit = (eventName, data) => {
      const customEvent = new CustomEvent(eventName, {
        detail: data,
      });
      document.dispatchEvent(customEvent);
    };

    return () => {
      mySocket.disconnect();
    };
  }, []);

  return { mySocket };
}

export default useMySocket;