import ApiMethod from "const/API2";
import { apiRoutes } from "const/apiroutes";

const API = new ApiMethod();

class Calendar {
  static getCalendarList(callback, errorCallback) {
    API.getData(
      apiRoutes.calendar.get,
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errorCallback) errorCallback(error);
      }
    );
  }

  static getCalendarItemByID(id, callback, errorCallback) {
    if (!id) return;
    API.getData(
      apiRoutes.calendar.getByID(id),
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errorCallback) errorCallback(error);
      }
    );
  }

  static Create(data, callback, errorCallback) {
    if (!data) return;
    delete data.id;
    API.postData(
      apiRoutes.calendar.post,
      data,
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errorCallback) errorCallback(error);
      }
    );
  }

  static Update(data, callback, errorCallback) {
    if (!data) return;
    API.putData(
      apiRoutes.calendar.put(data.id),
      data,
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errorCallback) errorCallback(error);
      }
    );
  }

  static Delete(id, callback) {
    if (!id) return;
    API.deleteData(
      apiRoutes.calendar.delete(id),
      (data) => {
        if (callback) callback(true, data);
      },
      (err) => {
        if (callback) callback(false, err);
      }
    );
  }
}

export default Calendar;
