import React, { Component } from "react";

// >>>
import {
  Utils as QbUtils,
  Query,
  Builder,
  BasicConfig,
} from "@react-awesome-query-builder/ui";
import {Button} from 'antd';
import "@react-awesome-query-builder/ui/css/styles.css";
import i18n from "i18n";
import Separation from "models/Separation";
import SimpleTable from "components/Elements/Tabulator/SimpleTable";
import GL from "libs/GL";
const { t } = i18n;
// or import '@react-awesome-query-builder/ui/css/compact_styles.css';
const InitialConfig = BasicConfig;

// <<<

// You need to provide your own config. See below 'Config format'
const config = {
  ...InitialConfig,
  fields: {
    excutor: {
      label: t("director"),
      type: "text",
      valueSources: ["value"],
      preferWidgets: ["text"],
    },
    priority: {
      label: t("pirority"),
      type: "number",
      valueSources: ["value"],
      fieldSettings: {
        min: 0,
        max: 10,
        step: 0.25,
      },
      preferWidgets: ["slider"],
    },
    last_execute_date: {
      label: t("global_lastexecute"),
      type: "select",
      valueSources: ["value"],
      fieldSettings: {
        listValues: [
          { value: "carisaat", title: t("current_hour") },
          { value: "carigun", title: t("current_day") },
          { value: "carihafta", title: t("current_week") },
          { value: "cariay", title: t("current_month") },
          { value: "cariyil", title: t("current_year") },
        ],
      },
    },
    risk_status: {
      label: t("risk_type"),
      type: "select",
      valueSources: ["value"],
      fieldSettings: {
        listValues: [
          { value: "pre", title: "Pre" },
          { value: "post", title: "Post" },
        ],
      },
    },
    tax_id: {
      label: t("voen"),
      type: "select",
      valueSources: ["value"],
      fieldSettings: {
        listValues: [
          { value: ">=", title: ">=" },
          { value: "<=", title: "<=" },
          { value: "in", title: t("separating_contains") },
          { value: "notin", title: t("separatin_not_contains") },
          { value: "inlist", title: t("separating_in_list") },
          { value: "notinlist", title: t("separating_not_in_list") },
          { value: "inportfolio", title: t("separating_at_portoflio") },
          {
            value: "notinportfolio yoxdur",
            title: t("separating_at_not_portoflio"),
          },
        ],
      },
    },
    kurator: {
      label: t("global_kurator"),
      type: "text",
      valueSources: ["value"],
      preferWidgets: ["text"],
    },
  },
};

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const queryValue = { id: QbUtils.uuid(), type: "group" };

class DemoQueryBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tree: QbUtils.loadTree(queryValue),
      config: config,
    };
  }

  testSQL(sql){
    Separation.TEST(sql,(status,data)=>{
      if(status){
        var columns = {
          id:"ID",
          taxId:"VOEN",
          lastExecuteDate:t('global_lastexecute'),
          note:t('global_qeyd'),
          scenarios:t('scenario_id'),
          priority:t('pirority')
        };
        GL.openSimpleTable(t('separating_result_count',{size:data.length}),<SimpleTable data={data} columns={columns}/>);
      }
    });
  }

  componentDidUpdate(prevProps) {
    const prevId = prevProps.formik.values.info.sqlJson?.id;
    const newId = this.props.formik.values.info.sqlJson?.id;
    const newSqlJson = this.props.formik.values.info.sqlJson;
    let newState;

    if (newSqlJson) {
      if (Object.keys(newSqlJson).length) {
        newState = {
          tree: QbUtils.loadTree(newSqlJson),
          config: config,
        };
      } else {
        newState = {
          tree: QbUtils.loadTree(queryValue),
          config: config,
        };
      }
    } else {
      newState = {
        tree: QbUtils.loadTree(queryValue),
        config: config,
      };
    }

    if (prevId !== newId) {
      this.setState(newState);
    }
  }

  render = () => {
    return (
      <div>
        <label>SQL Konstruktor</label>
        <Query
          {...config}
          value={this.state.tree}
          onChange={this.onChange}
          renderBuilder={this.renderBuilder}
        />
        {this.renderResult(this.state)}
      </div>
    );
  };

  renderBuilder = (props) => (
    <div className="query-builder-container">
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  renderResult = ({ tree: immutableTree, config }) => (
    <div className="query-builder-result">
      <div className="sqltext">
        <b>Konstruktor Query Where Condition :</b>
        <pre>
          Select * FROM risk WHERE{" "}
          <span style={{ color: "green" }}>
            {QbUtils.sqlFormat(immutableTree, config)}
          </span>
        </pre>
      </div>
      <div style={{display:'flex',justifyContent:'end'}}>
        <Button onClick={this.testSQL.bind(this,QbUtils.sqlFormat(immutableTree, config))} style={{marginTop:'10px'}} type="primary">{t('separating_result')}</Button>
      </div>
    </div>
  );

  onChange = (immutableTree, config) => {
    this.setState({ tree: immutableTree, config: config });
    const jsonTree = QbUtils.getTree(immutableTree);
    const sqlText = QbUtils.sqlFormat(immutableTree, config);
    this.props.onUpdate(jsonTree, sqlText);

    // ! =================================================================
    // const MY_IMMUTABLE_TREE = QbUtils.loadTree(jsonTree);
    // console.log("jsonTree ===>", jsonTree);
    // console.log("immutableTree ===>", immutableTree);
    // console.log("MY_IMMUTABLE_TREE ===>", MY_IMMUTABLE_TREE);
    // console.log("checkTree ===>", QbUtils.checkTree(MY_IMMUTABLE_TREE, config));
    // ! =================================================================
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  };
}
export default DemoQueryBuilder;
