import React from "react";
import { Space, Content, Button, Typography } from "antd";
const { Title } = Typography;
import { useEffect } from "react";
import { useState } from "react";
import {
  RightOutlined,
  DoubleRightOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useMailContext } from "./MailContextAPI";
import { useSelector } from "react-redux";
import MailReply from "./MailReply";
import axios from "axios";
import MailForward from "./MailForward";
import { fileTypeIconsEnum } from "const/fileTypeIconsEnum";
import { MailModel } from "./models/MailModel";
import MailDisplaySkeleton from "./MailDisplaySkeleton";
import { Fragment } from "react";
import { useHistory } from "react-router";
import GL from "libs/GL";
const MailDescWithReply = ({ selectedRow, handleClickTab }) => {
  const selectedMailsKeys = selectedRow.map((row) => row.key);
  const selectedMailKeysForDelete = selectedMailsKeys.join(",");
  const currentUserId = useSelector((state) => state.user.data.user.id);
  const { state, dispatch } = useMailContext();
  const [hover, onHover] = useState(false);
  const {
    recordKey,
    isReplyVisible,
    replyText,
    isForwardVisible,
    value,
    forwardText,
  } = state;

  const { currentPage, currentInboxPage, currentSendPage, pageSize } =
    state.paginationInfo;
  const history = useHistory();

  const searchParamsHandler = new URLSearchParams(history.location.search);
  const activeTab = searchParamsHandler.get("tab");

  const refreshPage = () => {
    activeTab === "inbox"
      ? MailModel.getInbox(
          currentInboxPage,
          pageSize,
          (modelDataInbox) => {
            dispatch({
              type: "SET_MAIL_INBOX",
              payload: modelDataInbox,
            });
          },
          (err) => {
            console.log(err, "ERROR MESSAGE FROM INBOX REQUEST"); //errtext
          }
        )
      : MailModel.getSend(
          currentSendPage,
          pageSize,
          (modelDataSend) => {
            dispatch({
              type: "SET_MAIL_SENT",
              payload: modelDataSend,
            });
          },
          (err) => {
            console.log(err, "ERROR MESSAGE FROM SEND REQUEST");
          }
        );
  };

  const handleReplyMail = async (e) => {
    e.preventDefault();
    const requestData = {
      from: currentUserId,
      userIds:
        selectedRow[selectedRow.length - 1].from.id === currentUserId
          ? selectedRow[selectedRow.length - 1].users.map((user) => user.userId)
          : [selectedRow[selectedRow.length - 1].from.id],
      typeId: 5,
      content: replyText,
      emailTypeId: 3,
      parentEmailId:
        selectedRow[selectedRow.length - 1].parentId !== null
          ? selectedRow[selectedRow.length - 1].parentId
          : selectedRow[selectedRow.length - 1].key,
    };

    MailModel.postReplyMail(
      requestData,
      (responseReply) => {
        if (typeof responseReply === "number") {
          handleClickTab("tab", activeTab);
          refreshPage();
          GL.notify.success({
            title: "Uğurlu əməliyyat!",
            text: "Cavab göndərildi",
          });
        }
      },
      (err) => {
        console.log("error", err);
      }
    );
  };

  const handleForwardMail = async (e) => {
    const requestData = {
      from: currentUserId,
      userIds: value,
      typeId: 5,
      content: forwardText,
      emailTypeId: 2,
      parentEmailId:
        selectedRow[selectedRow.length - 1].parentId !== null
          ? selectedRow[selectedRow.length - 1].parentId
          : selectedRow[selectedRow.length - 1].key,
    };

    MailModel.postForwardMail(
      requestData,
      (responseReply) => {
        if (typeof responseReply === "number") {
          handleClickTab("tab", activeTab);
          refreshPage();
          GL.notify.success({
            title: "Uğurlu əməliyyat!",
            text: "Cavab yönləndirildi.",
          });
        }
      },
      (err) => {
        console.log("POST FORWARD UNSUCCESSFULL", err);
      }
    );
  };

  const handleDeleteThread = (e, selectedMailKeysForDelete) => {
    MailModel.deleteSingleMail(
      selectedMailKeysForDelete,
      (responseReply) => {
        if (responseReply) {
          handleClickTab("tab", "inbox");
          refreshPage();
          GL.notify.success({
            title: "Uğurlu əməliyyat!",
            text: "Bütün maillər silindi.",
          });
        }
      },
      (err) => {
        console.log("THREAD DELETE UNSUCCESSFULL", err);
      }
    );
  };

  const normalRowItem = selectedRow.find((data) => data.type === "Normal");
  const notNormalRowItem = selectedRow
    .filter((data) => data.type !== "Normal")
    ?.sort((a, b) => a.key - b.key);

  return (
    <Fragment>
      {selectedRow.map(
        (mail) =>
          mail.type === "Normal" && (
            <Space
              direction="horizontal"
              size="middle"
              style={{
                paddingTop: "10px",
                paddingLeft: "10px",
                marginBottom: "10px",
              }}
            >
              <Space
                onClick={() => {
                  dispatch({ type: "SET_MAIN_OPEN", payload: true });
                  handleClickTab("tab", activeTab);
                }}
                onMouseEnter={() => onHover(true)}
                onMouseLeave={() => onHover(false)}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  backgroundColor: hover ? "#F1F1F1" : "#fff",
                  borderRadius: "50%",
                  padding: "3px",
                  paddingTop: "8px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  justifyContent: "center",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.57 5.92999L3.5 12L9.57 18.07"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.4999 12H3.66992"
                    stroke="#292D32"
                    strokeWidth="2"
                    stroke-miterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Space>

              <Title
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#1C2940",
                  paddingBottom: "10px",
                }}
              >
                {mail.title}
              </Title>
            </Space>
          )
      )}

      <Space
        direction="vertical"
        style={{
          border: "1px solid #EBEDF0",
          borderRadius: "6px",
          boxShadow: "0px 16px 40px -12px rgba(171, 186, 201, 0.20)",
        }}
      >
        {normalRowItem && <MailDisplaySkeleton mail={normalRowItem} />}

        {notNormalRowItem.map(
          (mail) =>
            mail.type !== "Normal" && <MailDisplaySkeleton mail={mail} />
        )}
      </Space>

      {isReplyVisible && (
        <MailReply selectedRowY={selectedRow[selectedRow.length - 1]} />
      )}
      {isForwardVisible && <MailForward />}

      <Space
        direction="horizontal"
        style={{
          justifyContent: "space-between",
          padding: "15px",
          background: "white",
        }}
      >
        <Button
          className="btn-delete-mail"
          style={{
            background: "#F8F9FB",
            borderRadius: "4px",
            color: "black",
            fontWeight: "600",
          }}
          onClick={(e) => {
            GL.yesNo("Bütün maillər silinsin?", (answer) => {
              if (answer) {
                handleDeleteThread(e, selectedMailKeysForDelete);
              }
            });
          }}
          icon={<DeleteOutlined />}
        >
          Sil
        </Button>
        <Space>
          {!isForwardVisible && (
            <Button
              className="btn-reply-mail"
              style={{
                borderRadius: "4px",
                color: "#F8F9FB",
                fontWeight: "600",
              }}
              onClick={(e) => {
                isReplyVisible && handleReplyMail(e);
                dispatch({ type: "SET_REPLY_VISIBLE", payload: true });
              }}
              icon={<RightOutlined />}
            >
              Cavabla
            </Button>
          )}
          {!isReplyVisible && (
            <Button
              className="btn-forward-mail"
              style={{
                backgroundColor: "#09A552",
                borderRadius: "4px",
                color: "#F8F9FB",
                fontWeight: "600",
              }}
              icon={<DoubleRightOutlined />}
              onClick={(e) => {
                isForwardVisible && handleForwardMail(e);
                dispatch({ type: "SET_FORWARD_VISIBLE", payload: true });
              }}
            >
              Yönləndir{" "}
            </Button>
          )}
          {isReplyVisible && (
            <Button
              className="cancel-button-new-mail"
              style={{
                backgroundColor: "#EBEDF0",
                color: "#000",
                borderRadius: "4px",
                transition: "0.4s all ease-out",
              }}
              onClick={() => {
                dispatch({ type: "SET_REPLY_VISIBLE", payload: false });
              }}
            >
              Ləğv et{" "}
            </Button>
          )}
          {isForwardVisible && (
            <Button
              className="cancel-button-new-mail"
              style={{
                backgroundColor: "#EBEDF0",
                color: "#000",
                borderRadius: "4px",
                transition: "0.4s all ease-out",
              }}
              onClick={() =>
                dispatch({ type: "SET_FORWARD_VISIBLE", payload: false })
              }
            >
              Ləğv et{" "}
            </Button>
          )}
        </Space>
      </Space>
    </Fragment>
  );
};

export default MailDescWithReply;
