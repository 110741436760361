import xls from "../assets/img/xls.svg";
import pdf from "../assets/img/pdf.svg";
import doc from "../assets/img/doc.svg";
import svg from "../assets/img/svg.png";
import defaultImg from "../assets/img/default.svg";
import text from "../assets/img/text.svg";
import jpgsvg from "../assets/img/jpg-svg.svg";

export const fileTypeIconsEnum = {
  "text/plain": text,
  "image/svg+xml": svg,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": xls,
  "application/vnd.ms-excel": xls,
  "application/pdf": pdf,
  "image/jpeg": jpgsvg,
  "image/jpg": jpgsvg,
  "image/png": jpgsvg,
  "image/x-png": jpgsvg,
  "application/msword": doc,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    doc,
  default: defaultImg,
};
