//import Portfolios from '../components/Pages/Portfolios/Portfolios';
import API from '../const/API2';
import {apiRoutes} from '../const/apiroutes';
import User from './User';

var api = new API();

class Portfolio {

  constructor(obj) {
    this.id = obj['id'];
    this.name = obj['name'];
    this.appointment = obj['appointment'];
    this.companies = obj['companies'];
    this.managerDirection = obj['managerDirection'];
    if(obj['managerId']!==null){
      this.managerId = new User(obj['managerId']);
    }else{
      this.managerId = new User();
    }
    this.isActive = obj['isActive'];
  }

  getName(){
    return this.name;
  }

  getFullName(){
    return this.totalName;
  }

  static getPortfoliosFromArray(data){
    return data.map((a)=>{
      return new Portfolio(a);
    });
  }

  static getPortfolioList(callback,errCallback){
    api.getData(apiRoutes.portfolios.list,(data)=>{
      var arr = Portfolio.getPortfoliosFromArray(data);
      callback(arr);
    },errCallback);
  }

  getTableItem(){
    return {
      id: this.id,
      name: this.name,
      appointment: this.appointment,
      managerDirection: this.managerDirection,
      manager: this.managerId.getName(),
      managerId: this.managerId.id,
      company_count:this.companies.length,
      isActive: this.isActive,
      companies:this.companies
    };
  }

  static getForTableData(data){
    return data.map((a)=>{
      return a.getTableItem()
    });
  }


  static sortDESC(a, b) {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    return 0;
  }

  static getArrayForSelect(data) {
    return data.map((a) => {
      return {
        value: a.id,
        label: a.name
      };
    });
  }

  static UPDATE(data,callback,errCallback){
    if(data==false){
      return null;
    }
    var id = data['id'];
    if(id!==0 && id!=='' && id!==null && id!==undefined){
      api.putData(apiRoutes.portfolios.put+'/'+id,data,(res)=>{
        if(callback!==undefined){callback(res);}
      },errCallback);
    }
  }

  static CREATE(data,callback,errCallback){
    if(data==false){
      return null;
    }
    if(data['id']!==undefined){
      delete data['id'];
    }
    api.postData(apiRoutes.portfolios.add,data,(res)=>{
      if(callback!==undefined){callback(res);}
    },errCallback);
  }

  static DELETE(id,callback){
    if(id===0 || id==='' || id===null || id===undefined){
      return null;
    }
    id = Number(id);
    api.deleteData(`${apiRoutes.portfolios.put}/${id}`,(data)=>{
      callback(true,data);
    },(err)=>{
      callback(false,err);
    });
  }

  
}

export default Portfolio;