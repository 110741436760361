import React, { useState } from "react";
import GL from "../../../libs/GL";
import { useTranslation } from "react-i18next";
import DynamicTable from "./DynamicTable";

function SimpleTable(props) {
  debugger;
  const { t } = useTranslation();
  const {columns, data} = props;
  var id = 'simpletable'+Date.now();
  var cols = [];
  var filtering = {
    id: id,
    uniqueColumn: "id",
    filters: {},
    styles: {
      simpleFilterInput: "white",
      filterButton: "buto",
    },
    secretColumns: [],
    columnSort: [],
    rowColor: {},
  };

  for(var i in data[0]){
    filtering.filters[i]={
      dataType: "text",
      placeholder: t("global_search"),
    };
    if(columns[i]!==undefined){
      cols.push({
        title: columns[i],
        field: i,
      });
    }
  }

  var buttonPressed = {
    type: "",
    data: {},
  };

  var tableSettings = {
    pagination: true,
    hideColumns: [],
    columnSort: [],
    rowNums: 10,
    data:data
  };


  return (
    <DynamicTable
          buttonPressed={buttonPressed}
          columns={cols}
          filtering={filtering}
          settings={tableSettings}
        />
  );
}

export default SimpleTable;