import React, { useState, useEffect } from "react";
import SubClosableTabs from "../../../../Elements/SubClosableTabs/SubClosableTabs";
import Execute from "../../Components/ExecuteInner/Execute";
import { useSelector } from "react-redux";

const RiskReview = ({ addNewTab, addTabWithSubTab, deleteTab }) => {
  const [TabComponentEnum, setTabComponentEnum] = useState({});
  const tabs = useSelector(({ home }) => home.tabs);

  useEffect(() => {
    let Enum = {};
    if (tabs) {
      const tab = tabs.find((item) => item.key === "riskReview");
      const subtabs = tab?.subTabs || [];

      for (let sub of subtabs) {
        Enum[sub.children] = (props) => <Execute riskId={sub.key} {...props} />;
      }
    }
    setTabComponentEnum(Enum);
  }, [tabs]);

  return (
    <SubClosableTabs
      reduxName="home"
      TabComponentEnum={TabComponentEnum}
      addNewTab={addNewTab}
      deleteTab={deleteTab}
    />
  );
};

export default RiskReview;
