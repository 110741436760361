import API from "../const/API2";
import { apiRoutes } from "../const/apiroutes";

var api = new API();

class MyList {
  static createPostData(data) {
    const { info } = data;

    const infoCopy = JSON.parse(JSON.stringify(info));

    delete infoCopy.form_name;
    delete infoCopy.form_type;
    delete infoCopy.form_field;
    delete infoCopy.form_nullable;
    delete infoCopy.form_default;
    delete infoCopy.data;
    delete infoCopy.id;
    delete infoCopy.isActive;

    infoCopy.settings = { fields: JSON.parse(JSON.stringify(infoCopy.fields)) };
    delete infoCopy.fields;

    return infoCopy;
  }

  static createPutData(data) {
    const { info } = data;

    const infoCopy = JSON.parse(JSON.stringify(info));

    delete infoCopy.form_name;
    delete infoCopy.form_type;
    delete infoCopy.form_field;
    delete infoCopy.form_nullable;
    delete infoCopy.form_default;
    delete infoCopy.data;
    delete infoCopy.id;
    delete infoCopy.isActive;
    delete infoCopy.tableName;

    infoCopy.settings = { fields: JSON.parse(JSON.stringify(infoCopy.fields)) };
    delete infoCopy.fields;

    return infoCopy;
  }

  static createSelectData(data) {
    return data.map((listItem) => ({
      label: listItem.name,
      value: listItem.id,
    }));
  }

  static async DELETE(id, callback, errCalback) {
    api.deleteData(
      `${apiRoutes.myList.delete}/${id}`,
      (_) => {
        callback();
      },
      (err) => {
        if (errCalback) {
          errCalback(err);
        }
      }
    );
  }

  static async GET(id, callback, errCalback) {
    api.getData(
      `${apiRoutes.myList.get}/${id}`,
      (apiData) => {
        const fields = [...apiData.settings.fields];
        apiData.fields = fields;
        delete apiData.settings;

        apiData.form_name = "";
        apiData.form_type = "";
        apiData.form_field = "";
        apiData.form_nullable = "";
        apiData.form_default = "";

        callback(apiData);
      },
      (err) => {
        if (errCalback) {
          errCalback(err);
        }
      }
    );
  }

  static async LIST(callback, errCalback) {
    api.getData(
      apiRoutes.myList.list,
      (data) => {
        callback(data);
      },
      (err) => {
        if (errCalback) {
          errCalback(err);
        }
      }
    );
  }

  static async EDIT(id, data, callback, errCalback) {
    const putData = this.createPutData(data);

    api.putData(
      `${apiRoutes.myList.put}/${id}`,
      putData,
      (data) => {
        callback(data);
      },
      (err) => {
        if (errCalback) {
          errCalback(err);
        }
      }
    );
  }

  static async CREATE(data, callback, errCalback) {
    const postData = this.createPostData(data);

    api.postData(
      apiRoutes.myList.add,
      postData,
      (data) => {
        callback(data);
      },
      (err) => {
        if (errCalback) {
          errCalback(err);
        }
      }
    );
  }
}

export default MyList;
