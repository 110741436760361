import React, { useEffect, useMemo, useState } from "react";
import {
  GetPagePermission,
  GetTableSettings
} from "../../../../hooks/permissions";
import GL from "../../../../libs/GL";
import SuperTable from "../../../Elements/Tabulator/SuperTable";
import { useHistory } from "react-router-dom";
import { Skeleton } from "antd";
import Department from "../../../../models/Department";
import EmptyPage from "../../../Elements/Skelethon/EmptyPage";
import ConfirmDelete from "../../../Elements/ConfirmDelete/ConfirmDelete";
import { useTranslation } from "react-i18next";

const pageId = "departments";

function Departments(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [departmentList, setDepartmentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const permissions = GetPagePermission(15);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%"
    }),
    []
  );

  var urlFilter = GL.getURLQuery();

  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function(data) {
        history.push({
          pathname: "departments/create",
          state: {
            info: {
              id: data.id,
              name: data.name,
              totalName: data.totalName,
              company: data.companyId,
              manager: data.managerId,
              isActive: data.isActive
            },
            method: "edit"
          }
        });
      }
    },
    delete: {
      status: permissions["delete"],
      click: function(data) {
        //debugger;
        GL.confirmDelete(
          <Skeleton active />,
          callback => {
            Department.getUsers(data.id, users => {
              //debugger;
              Department.getSectorsByDepartmentID(data.id, sectors => {
                const companySectorsList = sectors;
                var json = {
                  id: data.id,
                  title: t("question_deleting"),
                  desc: t("companies_delete"),
                  items: [
                    {
                      count: companySectorsList.length,
                      detail: companySectorsList,
                      required: true,
                      name: "Sube",
                      route: `/sectors?departmentId=${data.id}`
                    },
                    {
                      count: users.length,
                      required: false,
                      detail: users,
                      name: "Istifadeci",
                      route: `/users?departmentId=${data.id}`
                    }
                  ]
                };
                json.okButtonStatus =
                  companySectorsList.length > 0 ? false : true;
                callback(<ConfirmDelete config={json} />, json);
              });
            });
          },
          status1 => {
            //debugger;
            if (status1) {
              Department.DELETE(data.id, (status2, res) => {
                if (status2) {
                  GL.notify.success({
                    title: t("answer_success"),
                    text: t("department_deleted")
                  });
                  getDepartmentList();
                } else {
                  GL.notify.error({
                    title: t("answer_error"),
                    text: t("department_not_deleted")
                  });
                }
              });
            }
          }
        );
      }
    },
    show: {
      status: permissions["show"],
      click: function(data) {}
    },
    copy: {
      status: false,
      click: function(data) {
        history.push({
          pathname: "departments/create",
          state: {
            info: {
              id: 0,
              name: data.name,
              totalName: data.totalName,
              company: data.companyId,
              manager: data.managerId,
              isActive: data.isActive
            },
            method: "copy"
          }
        });
      }
    }
  };
  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/departments/add"
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/departments/multiDelete"
      }
    },
    settings: {
      id: pageId,
      type: "data",
      data: departmentList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "+ " + t("global_create", { name: t("department") }),
      createNewRoute: "departments/create",
      state: {
        info: {
          id: 0,
          name: "",
          totalName: "",
          company: null,
          manager: null,
          isActive: true
        },
        method: "create"
      }
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 70,

        formatter: function(cell) {
          return GL.setSelectStatus(cell);
        }
      },
      {
        title: t("global_name"),
        field: "name"
      },
      {
        title: t("activites_numberinlist"),
        field: "totalName"
      },
      {
        title: t("company"),
        field: "company"
      },

      {
        title: t("global_manager"),
        field: "manager"
      },
      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function(cell) {
          return GL.setButtons(cell, operatorSet);
        }
      }
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        id: {
          dataType: "number",
          placeholder: t("global_search")
        },
        name: {
          dataType: "text",
          placeholder: t("global_search")
        },
        totalName: {
          dataType: "text",
          placeholder: t("global_search")
        },
        manager: {
          dataType: "text",
          placeholder: t("global_search")
        },
        company: {
          dataType: "text",
          placeholder: t("global_search")
        }
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto"
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {}
    }
  };

  const getDepartmentList = () => {
    setLoading(true);
    Department.getDepartmenstList(
      data => {
        //debugger;
        //data.reverse();
        var tableData = Department.getForTableData(data);
        if (urlFilter.status > 0) {
          tableData = GL.dataFilter(tableData, urlFilter);
        }
        setDepartmentList(tableData);
        setLoading(false);
      },
      err => {}
    );
  };
  useEffect(() => {
    getDepartmentList();
  }, []);

  if (loading) {
    return <Skeleton active />;
  } else {
    if (departmentList.length == 0) {
      return (
        <EmptyPage
          route="departments/create"
          name="Departament"
          state={options.settings.state}
        />
      );
    } else {
      return (
        <div style={containerStyle}>
          <div className="main-layout-wrapper__head">
            <div className="simple-head">
              <h1>
                {t("departments")}
              </h1>
              <span>
                {t("departments_desc")}
              </span>
            </div>
          </div>
          <SuperTable data={options} />
        </div>
      );
    }
  }
}

export default Departments;
