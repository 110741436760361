import React, { useEffect, useMemo, useState } from "react";
import {
  GetPagePermission,
  GetTableSettings,
} from "../../../hooks/permissions";
import GL from "../../../libs/GL";
import SuperTable from "../../Elements/Tabulator/SuperTable";
import { useHistory } from "react-router";
import { Skeleton } from "antd";
import Portfolio from "../../../models/Portfolio";
import EmptyPage from "../../Elements/Skelethon/EmptyPage";
import { useTranslation } from "react-i18next";

const pageId = "portfolios";

function Portfolios(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [portfoliosList, setPortfoliosList] = useState([]);
  const [loading, setLoading] = useState(false);

  const permissions = GetPagePermission(18);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );
  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {
        history.push({
          pathname: "portfolios/create",
          state: {
            info: {
              id: data.id,
              name: data.name,
              manager: data.managerId,
              appointment: data.appointment,
              isActive: data.isActive,
              managerDirection: data.managerDirection,
              companies: data.companies,
            },
            method: "edit",
          },
        });
      },
    },
    delete: {
      status: permissions["delete"],
      click: function (data) {
        GL.yesNo(t("question_deleting"), (status1) => {
          if (status1) {
            Portfolio.DELETE(data.id, (status2, res) => {
              if (status2) {
                GL.notify.success({
                  title: t("answer_success"),
                  text: t("portfolio_deleted"),
                });
                getPortfoliosList();
              } else {
                GL.notify.error({
                  title: t("answer_error"),
                  text: t("portfolio_not_deleted"),
                });
              }
            });
          }
        });
      },
    },
    show: {
      status: permissions["show"],
      click: function (data) {},
    },
    copy: {
      status: false,
      click: function (data) {
        history.push({
          pathname: "portfolios/create",
          state: {
            info: {
              id: 0,
              name: data.name,
              manager: data.managerId,
              appointment: data.appointment,
              isActive: data.isActive,
              managerDirection: data.managerDirection,
              companies: data.companies,
            },
            method: "copy",
          },
        });
      },
    },
  };
  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/portfolios/add",
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/portfolios/multiDelete",
      },
    },
    settings: {
      id: pageId,
      type: "data",
      data: portfoliosList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "+ " + t("global_create", { name: t("portfolio") }),
      createNewRoute: "portfolios/create",
      state: {
        info: {
          id: 0,
          name: "",
          appointment: "",
          direction: null,
          manager: null,
          isActive: true,
          companies: [],
        },
        method: "create",
      },
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 70,

        formatter: function (cell) {
          return GL.setSelectStatus(cell);
        },
      },
      {
        title: t("global_name"),
        field: "name",
      },
      {
        title: t("portfolio_appointment"),
        field: "appointment",
      },
      {
        title: t("portfolio_kurator_send"),
        field: "managerDirection",
      },
      {
        title: t("global_kurator"),
        field: "manager",
      },
      {
        title: t("portfolio_voen"),
        field: "company_count",
      },
      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        name: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        appointment: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        company_count: {
          dataType: "number",
          placeholder: t("global_search"),
        },
        managerDirection: {
          dataType: "checkbox",
          placeholder: t("global_search"),
          mean: {
            true: t("global_beli"),
            false: t("global_xeyr"),
          },
        },
        manager: {
          dataType: "text",
          placeholder: t("global_search"),
        },
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {},
    },
  };

  const getPortfoliosList = () => {
    setLoading(true);
    Portfolio.getPortfolioList(
      (data) => {
        //data.reverse()
        setPortfoliosList(Portfolio.getForTableData(data));
        setLoading(false);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    getPortfoliosList();
  }, []);

  if (loading) {
    return <Skeleton active />;
  } else {
    if (portfoliosList.length === 0) {
      return (
        <EmptyPage
          route="portfolios/create"
          name={t("portfolios")}
          state={options.settings.state}
        />
      );
    } else {
      return (
        <div style={containerStyle}>
          <div className="main-layout-wrapper__head">
            <div className="simple-head">
              <h1>{t("portfolios")}</h1>
              <span>{t("portfolios_desc")}</span>
            </div>
          </div>
          <SuperTable data={options} />
        </div>
      );
    }
  }
}

export default Portfolios;
