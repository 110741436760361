import React from "react";
import "./Recommend.scss";
import { Checkbox, Row, Col, Space } from "antd";
import SelectBox from "components/Elements/FileUploadForm/Form/SelectBox";
import TextareaComponent from "components/Elements/FileUploadForm/Form/Textarea";
import { getIn } from "formik";
import { invoiceSettingsChecks } from "const/initialDatas";

const Recommend = ({
  disabled,
  title,
  hasCenter,
  className,
  commentCategories,
  formik,
  itemName,
}) => {
  return (
    <div className={`Recommend ${className ?? ""}`}>
      {disabled && <div className="RecommendDisabled"></div>}
      <div className="RecommendHeader">
        <h5>{title}</h5>
      </div>
      {hasCenter && (
        <div className="RecommendCenter">
          <Space direction="vertical" size={20}>
            <Row gutter={40}>
              <Col sm={24} md={12} lg={12}>
                <RecommendCheckbox
                  label="Tərəflərin məlumatlandırılması:"
                  formik={formik}
                  field={`info.${itemName}.checks.isInfo`}
                  isDisabled={formik.values.info[itemName]?.checks.isClosable}
                />
              </Col>
              <Col sm={24} md={12} lg={12}>
                <RecommendCheckbox
                  label="OVN:"
                  formik={formik}
                  field={`info.${itemName}.checks.isOvn`}
                  isDisabled={formik.values.info[itemName]?.checks.isClosable}
                />
              </Col>
              <Col sm={24} md={12} lg={12}>
                <RecommendCheckbox
                  label="Təqdim edənin RVÖ elan edilməsi:"
                  formik={formik}
                  field={`info.${itemName}.checks.isRvo`}
                  isDisabled={formik.values.info[itemName]?.checks.isClosable}
                />
              </Col>
              <Col sm={24} md={12} lg={12}>
                <RecommendCheckbox
                  label="SVY:"
                  formik={formik}
                  field={`info.${itemName}.checks.isSvy`}
                  isDisabled={formik.values.info[itemName]?.checks.isClosable}
                />
              </Col>
              <Col sm={24} md={12} lg={12}>
                <RecommendCheckbox
                  label="Bağlanmalı risk:"
                  formik={formik}
                  field={`info.${itemName}.checks.isClosable`}
                  onHandleChange={(isChecked) => {
                    if (isChecked) {
                      const updatedValues = {
                        ...invoiceSettingsChecks,
                        isClosable: isChecked,
                        reasonId: formik.values.info[itemName].checks.reasonId,
                      };
                      formik.setFieldValue(
                        `info.${itemName}.checks`,
                        updatedValues
                      );
                    }
                  }}
                />
              </Col>
              <Col sm={24} md={12} lg={12}>
                <RecommendCheckbox
                  label="KVY:"
                  formik={formik}
                  field={`info.${itemName}.checks.isKvy`}
                  isDisabled={formik.values.info[itemName]?.checks.isClosable}
                />
              </Col>
              <Col sm={24} md={12} lg={12}>
                <RecommendCheckbox
                  label="Sahə təhkimçiliyi:"
                  formik={formik}
                  field={`info.${itemName}.checks.isSerfdom`}
                  isDisabled={formik.values.info[itemName]?.checks.isClosable}
                />
              </Col>
            </Row>
            <Row gutter={40}>
              <Col sm={24} md={12} lg={12}>
                <SelectBox
                  label="Səbəb seç"
                  data={commentCategories}
                  formik={formik}
                  field={`${itemName}.checks.reasonId`}
                />
              </Col>
            </Row>
          </Space>
        </div>
      )}

      <div className="RecommendFooter">
        <TextareaComponent
          placeholder="Qeyd əlavə et"
          formik={formik}
          field={`${itemName}.note`}
        />
      </div>
    </div>
  );
};

export function RecommendCheckbox({
  label,
  formik,
  field,
  isDisabled,
  onHandleChange,
}) {
  const isChecked = getIn(formik?.values, field);
  const onChange = (e) => {
    formik.setFieldValue(field, e.target.checked);
    onHandleChange && onHandleChange(e.target.checked);
  };

  return (
    <div className="RecommendCheckbox">
      <label>
        <span>{label}</span> <div className="RecommendLine" />{" "}
        <Checkbox
          onChange={onChange}
          checked={isChecked}
          disabled={isDisabled}
        />
      </label>
    </div>
  );
}

export default Recommend;
