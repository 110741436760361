import Styles from "./InfoWidget.module.scss";

import React from "react";
import moment from "moment";

function InfoWidget({ title, infoNumber, btnTxt, onClick, lastUpdated }) {
  lastUpdated = moment(lastUpdated).format("DD.MM.YYYY");
  return (
    <div className={Styles.Widget}>
      <div className={Styles.WidgetContent}>
        <div className={Styles.WidgetContentTop}>
          <div className={Styles.WidgetContentTopTitle}>{title}</div>
          <div className={Styles.WidgetContentTopButton}>
            <button onClick={onClick}>{btnTxt}</button>
          </div>
        </div>
        <div className={Styles.WidgetContentNumbers}>{infoNumber}</div>
      </div>
      <div className={Styles.WidgetDate}>
        <span className={Styles.WidgetDateText}>Son yenilənmə:</span>
        <span className={Styles.WidgetDateDate}>{lastUpdated}</span>
      </div>
    </div>
  );
}

export default InfoWidget;
