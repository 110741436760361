import React, { useMemo } from "react";
import { Space, Table, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import GL from "libs/GL";
import { useTranslation } from "react-i18next";

const UserGroupTable = ({ rows, onSelect }) => {
  const { t } = useTranslation();
  function onDeleteTableItem(item) {
    GL.yesNo(t("user_delete_question"), (isDelete) => {
      if (!isDelete) return;
      const updatedRows = rows.filter((row) => row.id !== item.key);
      onSelect(updatedRows);
    });
  }

  const columns = [
    {
      title: t("stages_username"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("global_operations"),
      key: "action",
      render: (item) => (
        <Space size="middle" className="table-flex">
          <Button type="primary" onClick={() => onDeleteTableItem(item)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];
  const dataSource = useMemo(() => {
    const convertToTableData = rows?.map((row) => ({
      key: row.id,
      name: `${row.firstName} ${row.lastName}`,
    }));

    return convertToTableData;
  }, [rows]);

  if (!Boolean(dataSource.length)) return null;
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      bordered
      pagination={{ hideOnSinglePage: true, pageSize: 5 }}
    />
  );
};
export default UserGroupTable;
