import React, { createContext, useContext, useReducer } from "react";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";

const initialState = {
  socket: null,
};

const SocketContext = createContext(initialState);

export const useSocketContext = () => useContext(SocketContext);

function socketReducer(state, action) {
  switch (action.type) {
    case "SET_SOCKET":
      return { ...state, socket: action.payload };
    default:
      return state;
  }
}

export function SocketProvider({ children }) {
  const [socketContext, dispatchSocketContext] = useReducer(
    socketReducer,
    initialState
  );

  const contextValue = {
    state: socketContext,
    dispatch: dispatchSocketContext,
  };

  const reduxToken = useSelector((s) => s.token);
  const url = `ws://20.13.166.30:8085/websocket?token=${reduxToken}`;

  // useEffect(() => {
  //   const newSocket = io(url, {
  //     reconnection: true,
  //   });

  //   dispatchSocketContext({ type: "SET_SOCKET", payload: newSocket });

  // }, [reduxToken, url]);

  // useEffect(() => {
  //   if (socketContext.socket) {
  //     socketContext.socket.connect();

  //     socketContext.socket.on("connect", () => {
  //       console.log("Socket connected");
  //     });

  //     socketContext.socket.on("disconnect", () => {
  //       console.log("Socket disconnected");
  //     });

  //     return () => {
  //       console.log("disconnecting the socket");
  //       socketContext.socket.disconnect();
  //     };
  //   }
  // }, [socketContext.socket]);

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
}
