import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Space, Result, Skeleton } from "antd";
import Search from "./components/Search";
import Accordions from "./components/Accordions";
import ActionTypesInfoTable from "./components/ActionTypesInfoTable";
import UserInfo from "./components/UserInfo";
import { sublist } from ".";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();
  const { location } = useHistory();
  const path = location.pathname + location.hash;
  const [profileInfo, setProfileInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const onSetProfileInfo = (info) => setProfileInfo(() => info);
  const onSetLoading = (isLoading) => setIsLoading(isLoading);
  let isInfoDataEmpty = profileInfo && Object.keys(profileInfo).length === 0;
  return (
    <div className="risk-page profile">
      <Space direction="vertical" size={0}>
        <Search
          onSetProfileInfo={onSetProfileInfo}
          onSetLoading={onSetLoading}
        />
        <div className="profile-body">
          <div className="profile-body__aside">
            <ul className="profile-body__list">
              {sublist?.map((sub) => (
                <li key={sub.id}>
                  <Link
                    className={path === sub.route ? "active" : ""}
                    to={sub.route}
                  >
                    {sub.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="profile-body__left">
            <Space direction="vertical" size={16}>
              {isLoading && <Skeleton />}
              {isInfoDataEmpty && (
                <Result
                  status="404"
                  title={t("search_result")}
                  subTitle={t("search_not_found")}
                />
              )}
              {profileInfo?.items?.map((item, index) => {
                const { type, data, img, columns, title, openIndex, items } =
                  item;
                switch (type) {
                  case "photoinfo":
                    return <UserInfo key={index} data={data} src={img} />;
                  case "table":
                    return (
                      <ActionTypesInfoTable
                        key={index}
                        data={data}
                        columns={columns}
                        title={title}
                      />
                    );
                  case "accordion":
                    return (
                      <Accordions
                        key={index}
                        openIndex={openIndex}
                        items={items}
                      />
                    );
                  default:
                    return (
                      <Result
                        key={index}
                        status="404"
                        title={t("search_result")}
                        subTitle={t("search_not_found")}
                      />
                    );
                }
              })}
            </Space>
          </div>
        </div>
      </Space>
    </div>
  );
};

export default Profile;
