import React, { createContext, useContext, useReducer } from "react";
import { useEffect } from "react";
import { MailModel } from "./models/MailModel";
import { useHistory } from "react-router";

const initialState = {
  isInboxOpen: true,
  isSentOpen: false,
  newMail: false,
  open: false,
  mailInbox: [],
  paginationInfo: {
    currentPage: 1,
    currentInboxPage: 1,
    currentSendPage: 1,
    pageSize: 10
  },
  curPage: 1,
  totalElements: null,
  totalElementsSend: null,
  mailSent: [],
  isMainOpen: true,
  selectedRow: [],
  value: [],
  options: [],
  recordKey: null,
  disabledBtn: false,
  isReplyVisible: false,
  replyText: null,
  isForwardVisible: false,
  forwardText: null
};

const MailContext = createContext(initialState);

export const useMailContext = () => useContext(MailContext);

function mailReducer(state, action) {
  switch (action.type) {
    case "SET_INBOX_OPEN":
      return { ...state, isInboxOpen: action.payload };
    case "SET_SENT_OPEN":
      return { ...state, isSentOpen: action.payload };
    case "SET_NEW_MAIL":
      return { ...state, newMail: action.payload };
    case "SET_SHOW_MODAL":
      return { ...state, open: action.payload };
    case "SET_MAIL_INBOX":
      return { ...state, mailInbox: action.payload };
    case "SET_MAIL_SENT":
      return { ...state, mailSent: action.payload };
    case "SET_MAIN_OPEN":
      return { ...state, isMainOpen: action.payload };
    case "SET_SELECTED_ROW":
      return { ...state, selectedRow: action.payload };
    case "SET_VALUE":
      return { ...state, value: action.payload };
    case "SET_RECORD_KEY":
      return { ...state, recordKey: action.payload };
    case "SET_DISABLED_BTN":
      return { ...state, disabledbtn: action.payload };
    case "SET_REPLY_VISIBLE":
      return { ...state, isReplyVisible: action.payload };
    case "SET_REPLY_TEXT":
      return { ...state, replyText: action.payload };
    case "SET_FORWARD_VISIBLE":
      return { ...state, isForwardVisible: action.payload };
    case "SET_FORWARD_TEXT":
      return { ...state, forwardText: action.payload };
    case "SET_PAGINATION_INFO":
      return {
        ...state,
        paginationInfo: action.payload
      };
    case "SET_TOTAL_ELEMENTS":
      return { ...state, totalElements: action.payload };
    case "SET_CURRENT_PAGE":
      return { ...state, curPage: action.payload };
    case "SET_TOTAL_ELEMENTS_SEND":
      return { ...state, totalElementsSend: action.payload };
    default:
      return state;
  }
}

export function MailProvider({ children }) {
  const [state, dispatch] = useReducer(mailReducer, initialState);
  const {
    currentPage,
    currentInboxPage,
    currentSendPage,
    pageSize
  } = state.paginationInfo;
  const history = useHistory();

  const searchParamsHandler = new URLSearchParams(history.location.search);
  const activeTab = searchParamsHandler.get("tab");

  useEffect(
    () => {
      MailModel.getInbox(
        currentInboxPage,
        pageSize,
        (modelDataInbox, pagination) => {
          dispatch({
            type: "SET_MAIL_INBOX",
            payload: modelDataInbox
          });
          dispatch({
            type: "SET_TOTAL_ELEMENTS",
            payload: pagination.totalElements
          });
        },
        err => {
          console.log(err, "ERROR MESSAGE FROM INBOX REQUEST");
        }
      );

      MailModel.getSend(
        currentSendPage,
        pageSize,
        (modelDataSend, pagination) => {
          dispatch({
            type: "SET_MAIL_SENT",
            payload: modelDataSend
          });
          dispatch({
            type: "SET_TOTAL_ELEMENTS_SEND",
            payload: pagination.totalElements
          });
        },
        err => {
          console.log(err, "ERROR MESSAGE FROM SEND REQUEST");
        }
      );
    },
    [state.paginationInfo, activeTab]
  );

  const contextValue = { state, dispatch };
  return (
    <MailContext.Provider value={contextValue}>
      {children}
    </MailContext.Provider>
  );
}
