/* eslint-disable array-callback-return */
import "./DynamicTableCss.css";

import React, { useCallback, useEffect, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { DndContext } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { Table, Switch } from "antd";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

function getLocalStorage(p) {
  var sts = localStorage.getItem("shortTableSettings");
  if (sts !== null) {
    sts = JSON.parse(sts);
    if (p.settings.id === sts.id) {
      return sts;
    }
  } else {
    return p.settings;
  }
}

var columns = [];
var secretColumns = [];
var columnSort = [];

const TableSettings = (props) => {
  const { t } = useTranslation();
  var data = getLocalStorage(props);
  secretColumns = data.secretColumns;
  columnSort = data.columnSort;
  columns = data.columns;
  const [dataSource, setDataSource] = useState([]);

  const tcolumns = [
    {
      key: "sort",
      title: t("global_sort"),
      width: 50,
    },
    {
      title: t("table_column_name"),
      dataIndex: "title",
    },
    {
      title: t("global_visibility"),
      dataIndex: "checked",
      width: 50,
    },
  ];

  function updateLocalStorage(arr) {
    var newSort = [];
    arr.map((a) => newSort.push(a.field));
    var obj = {
      id: data.id,
      columns: columns,
      secretColumns: secretColumns,
      columnSort: newSort,
    };
    localStorage.setItem("shortTableSettings", JSON.stringify(obj));
  }

  function handleSwitchChange(checked, field) {
    setDataSource((prevData) => {
      const index = secretColumns.indexOf(field);
      if (checked) {
        if (index !== -1) {
          secretColumns.splice(index, 1);
        }
      } else {
        if (index === -1) {
          secretColumns.push(field);
        }
      }
      var newSource = populateRow(columnSort, columns, secretColumns);
      updateLocalStorage(newSource);
      return newSource;
    });
  }

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        var newarr = arrayMove(previous, activeIndex, overIndex);
        updateLocalStorage(newarr);
        return newarr;
      });
    }
  };

  const populateRow = useCallback((sortarr, columns, secretColumns) => {
    var arr = [];
    if (columns.length > 0) {
      sortarr.map((a, i) => {
        var col = columns.find((b) => b.field === a);
        if (col) {
          if (["operations", "id"].indexOf(col.field) === -1) {
            arr.push({
              key: `${i}`,
              title: col.title,
              field: col.field,
              status: !secretColumns.includes(col.field),
              checked: (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Switch
                    checked={!secretColumns.includes(col.field)}
                    onChange={(checked) =>
                      handleSwitchChange(checked, col.field)
                    }
                    style={{ marginLeft: 8 }}
                  />
                </div>
              ),
            });
          }
        }
      });
    }
    return arr;
  }, []);

  useEffect(() => {
    var tableData = populateRow(columnSort, columns, secretColumns);
    setDataSource(tableData);
  }, [populateRow]);

  return (
    <div>
      <h2 style={{ marginBottom: "20px !important" }}>{t("table_settings")}</h2>
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            pagination={false}
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            columns={tcolumns}
            dataSource={dataSource}
            style={{ width: "100%" }}
            className="my-custom-table"
          />
        </SortableContext>
      </DndContext>
    </div>
  );
};
export default TableSettings;
