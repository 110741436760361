import React from "react";
import SuperTable from "components/Elements/Tabulator/SuperTable";
import { GetPagePermission, GetTableSettings } from "hooks/permissions";
import GL from "libs/GL";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";

const pageId = "riskdate";

const RiskDates = ({ riskStageHistory }) => {
  const { t } = useTranslation();
  //const permissions = GetPagePermission(6);
  const tableSettings = GetTableSettings(pageId);
  const operatorSet = {
    show: {
      status: true,
      click: function (data) {},
    },
    edit: {
      status: false,
    },
    delete: {
      status: false,
    },
    copy: {
      status: false,
    },
  };

  const options = {
    permissions: {},
    settings: {
      id: pageId,
      type: "data",
      data: riskStageHistory,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      columnSort: [], //tableSettings.columnSort,
      // ! create button settings -----------------------------------------
      createIsDisabled: true,
      // ! create button settings -----------------------------------------
    },
    columns: [
      {
        title: t("stage"),
        field: "stage.name",
      },
      {
        title: "check_date",
        field: "checkDate",
      },

      {
        title: t("risk_status"),
        field: "riskStatus.name",
      },

      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {},
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: [], //tableSettings.hideColumns,
      columnSort: [], //tableSettings.columnSort,
      rowColor: {
        field: "priority",
        type: "range",
        rules: {
          min: 0,
          max: 10,
          ranges: [
            { max: 6, color: "#FFDD263d" },
            { max: 7, color: "#FFB0343d" },
            { max: 8, color: "#FF681C3d" },
            { max: 9, color: "#DF26383d" },
            { max: 10, color: "#8638e53d" },
          ],
        },
      },
    },
  };

  return (
    <div className="risk-page">
      <h1 className="risk-page__title">{t("risk_dates")}</h1>
      <Row gutter={24}>
        <Col span={24}>
          <SuperTable data={options} />
        </Col>
      </Row>
    </div>
  );
};

export default RiskDates;
