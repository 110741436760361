import React, { useState } from "react";
import { Select, Space } from "antd";
import { useEffect } from "react";
import { useMailContext } from "./MailContextAPI";
import { useSelector } from "react-redux";
import "./Mail.css";
import { MailModel } from "./models/MailModel";

const SelectUserSearch = () => {
  const { state, dispatch } = useMailContext();
  const { value } = state;
  const currentUserMail = useSelector(state => state.user.data.user.email);
  const [options, setOptions] = useState([]);

  const filterOptions = (input, option) => {
    return option.label.toLowerCase().startsWith(input.toLowerCase());
  };

  useEffect(() => {
    MailModel.getUsers(result => {
      result.map(user => {
        if (user.email !== currentUserMail) {
          setOptions(prevState => [
            ...prevState,
            {
              label: `${user.firstName} ${user.lastName} - ${user.email}`,
              value: user.id
            }
          ]);
        }
      });
    });
  }, []);

  const selectProps = {
    mode: "multiple",
    value,
    options,
    onChange: newValue => {
      dispatch({ type: "SET_VALUE", payload: newValue });
    },
    placeholder: "Daxil et",
    maxTagCount: "responsive",
    filterOption: filterOptions
  };
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%"
      }}
    >
      <Select {...selectProps} />
    </Space>
  );
};
export default SelectUserSearch;
