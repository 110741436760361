import React from "react";
import { Collapse } from "antd";
const Accordions = ({ openIndex, items }) => {
  return (
    <div className="profile-accordions">
      <Collapse
        defaultActiveKey={openIndex}
        accordion
        expandIconPosition="end"
        className="profile-accordions__body"
      >
        {items?.map((item, index) => {
          const { title, data } = item;
          return (
            <Collapse.Panel header={title} key={index}>
              <AccordionPanel list={data} />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export const AccordionPanel = ({ list }) => {
  return (
    <div className="accordion-panel">
      {list?.map((item, index) => (
        <AccourdionInfo key={index} title={item.key} value={item.value} />
      ))}
    </div>
  );
};

export const AccourdionInfo = ({ title, value }) => (
  <div className="accordion-panel__info">
    <span>{title}</span>
    <strong>{value}</strong>
  </div>
);

export default Accordions;
