import { useState } from "react";

import { apiRoutes } from "const/apiroutes";
import API from "const/API2";
import GL from "libs/GL";

const api = new API();

function useChangeFileOrFolderName({
  id,
  isFolder,
  getLastTableData,
  getLastAdded = null,
}) {
  const [changeNameModalIsOpen, setChangeNameModalIsOpen] = useState(false);

  const openChangeNameModal = () => {
    setChangeNameModalIsOpen(true);
  };

  const closeChangeNameModal = () => {
    setChangeNameModalIsOpen(false);
  };

  const submitRenameFileOrFolder = (formikValues, callBack, errCallback) => {
    const postUrl = isFolder
      ? apiRoutes.library.renameFolder({ folderId: id })
      : apiRoutes.library.renameFile({ id: id });

    const postData = {
      name: formikValues,
    };
    // if (!isFolder) {
    //   postData.name = formikValues;
    // }

    api.putData(
      postUrl,
      postData,
      (data) => {
        getLastTableData();
        getLastAdded && getLastAdded();
        callBack && callBack(data);
        GL.notify.success({ text: "Uğurla dəyişdirildi" });
      },
      (err) => {
        GL.notify.error({ text: "Dəyişdirilə bilmədi" });
        errCallback && errCallback(err);
      }
    );
  };

  return {
    changeNameModalIsOpen,
    openChangeNameModal,
    closeChangeNameModal,
    submitRenameFileOrFolder,
  };
}

export default useChangeFileOrFolderName;
