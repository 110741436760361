export const SET_USER = "SET_USER";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_USER_LOGGED_IN = "SET_USER_LOGGED_IN";
export const SET_USER_REFRESH_TOKEN = "SET_USER_REFRESH_TOKEN";
export const LOG_OUT = "LOG_OUT";

export const SET_TOKEN = "SET_TOKEN";

export const LOADING_ON = "LOADING_ON";
export const LOADING_OFF = "LOADING_OFF";

export const GET_CATS = "GET_CATS";
export const GET_CATS_ERROR = "GET_CATS_ERROR";

export const SET_EDIT = "SET_EDIT";
export const CANCEL_EDIT = "CANCEL_EDIT";

export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_STOCK = "SET_STOCK";

export const SET_RISK_DATA = "SET_RISK_DATA";
export const SET_ROLES_DATA = "SET_ROLES_DATA";

export const SET_PYTHON = "SET_PYTHON";

export const SET_MY_LIST_DATA = "SET_MY_LIST_DATA";

export const SET_RISK_SEPERATE = "SET_RISK_SEPERATE";
export const SET_LANGUAGES = "SET_LANGUAGES";

/* -------------- TABLE SETTINGS ------------------- */
export const SET_TABLE_SETTINGS = "SET_TABLE_SETTINGS";
export const GET_TABLE_SETTINGS = "GET_TABLE_SETTINGS";

export const SET_COMPANY_DATA = "SET_COMPANY_DATA";
export const CLEAR_COMPANY = "CLEAR_COMPANY";
export const SET_DEPARTMENT_DATA = "SET_DEPARTMENT_DATA";
export const SET_SECTOR_DATA = "SET_SECTOR_DATA";
export const SET_RISK_GROUP_DATA = "SET_RISK_GROUP_DATA";
export const SET_RISK_STATUS_DATA = "SET_RISK_STATUS_DATA";
export const SET_POSITION_DATA = "SET_POSITION_DATA";
export const SET_ENUM_DATA = "SET_ENUM_DATA";

/* -------------- USERS ACTIONS ------------------- */
export const SET_USERS_INFO = "SET_USERS_INFO";
export const SET_USER_GROUP_INFO = "SET_USER_GROUP_INFO";

/* -------------- SUBLIST ACTIONS ------------------- */
export const SET_SUBLIST_INFO = "SET_SUBLIST_INFO";

/* ----------------- HOME ACTIONS ----------------- */
export const ADD_TAB = "ADD_TAB";
export const REMOVE_TAB = "REMOVE_TAB";

export const ADD_SUB_TAB = "ADD_SUB_TAB";
export const REMOVE_SUB_TAB = "REMOVE_SUB_TAB";

export const ADD_TAB_WITH_CHILD = "ADD_TAB_WITH_CHILD";
export const RESET_TABS = "RESET_TABS";
