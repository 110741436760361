import "./XmlViewer.scss";

import React from "react";
import XMLViewer from "react-xml-viewer";

import XmlModal from "../XmlModal/XmlModal";
import { useTranslation } from "react-i18next";

const XmlViewer = (props) => {
  const { t } = useTranslation();
  const { formik, formikPath, isError, error, open, setOpen } = props;

  const customTheme = {
    attributeKeyColor: "#FF0000",
    attributeValueColor: "#000FF",
    overflowBreak: true,
  };

  return (
    <div>
      {open ? (
        <XmlModal
          open={open}
          setOpen={setOpen}
          formik={formik}
          formikPath={formikPath}
          isError={isError}
          error={error}
          title={t("xml_editor")}
        />
      ) : null}
      <div className={isError ? "xml-viewer error" : "xml-viewer"}>
        <XMLViewer
          xml={formik.getFieldProps(formikPath).value}
          theme={customTheme}
          collapsible={true}
        />
      </div>
    </div>
  );
};

export default XmlViewer;
