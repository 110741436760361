import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Skeleton } from "antd";

import { GetPagePermission, GetTableSettings } from "hooks/permissions";
import EmptyPage from "components/Elements/Skelethon/EmptyPage";
import SuperTable from "components/Elements/Tabulator/SuperTable";
import StageGrouping from "./StageGrouping";
import { apiRoutes } from "const/apiroutes";
import API from "const/API2";
import GL from "libs/GL";

const pageId = "melheleler";

function Stages() {
  const { t } = useTranslation();
  const api = new API();
  const history = useHistory();
  let mainUrl = apiRoutes.stages;
  const reduxRisk = useSelector((s) => s.risk);
  const [stagesList, setStagesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const permissions = GetPagePermission(2);
  const tableSettings = GetTableSettings(pageId);

  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {
        history.push({
          pathname: "add-stages",
          state: {
            id: data.id,
            method: "edit",
          },
        });
      },
    },
    delete: {
      status: permissions["delete"],
      click: function (data) {
        GL.yesNo(t("stages_delete_confirm"), (status) => {
          if (status) {
            api.deleteData(
              `${mainUrl.put}/${data.id}`,
              (_) => {
                GL.notify.success({
                  title: t("answer_success"),
                  text: t("stages_deleted"),
                });
                getStagesList();
              },
              (err) => {
                GL.notify.error({
                  title: t("answer_error"),
                  text: t("stages_not_deleted"),
                });
                console.log("err -->", err);
              }
            );
          }
        });
      },
    },
    show: {
      status: permissions["show"],
      click: function (data) {},
    },
    copy: {
      status: permissions["copy"],
    },
  };

  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/companies/add",
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/companies/multiDelete",
      },
    },
    settings: {
      id: pageId,
      type: "data",
      data: stagesList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "+ " + t("global_create", { name: t("stage") }),
      createNewRoute: "departments/create",
      toolbarStatus: false,

      state: {
        select: 0,
      },
    },
    columns: [
      {
        title: "Seç",
        field: "select",
        width: 100,
        formatter: function (cell) {
          return `<label style="display: flex; align-items: center; justify-content: center;">
                     <input style="height: 15px; width: 15px;" type="checkbox"/>          
                  </label>`;
        },
      },
      {
        title: "ID",
        field: "id",
      },
      {
        title: t("global_name"),
        field: "name",
      },
      {
        title: t("global_query_type"),
        field: "queryType",
      },
      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        select: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        condition: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        order: {
          dataType: "number",
          placeholder: t("global_search"),
        },
        id: {
          dataType: "number",
          placeholder: t("global_search"),
        },
        name: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        requestType: {
          dataType: "text",
          placeholder: t("global_search"),
        },
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {},
    },
  };

  const getValidArray = (data) => {
    const newData = data
      .filter((stage) => stage.scenariosDto.id === reduxRisk.id)
      .map((stage) => {
        // const checkParametr = stage.parametersDto.id;
        // delete stage.parametersDto;
        delete stage.scenariosDto;
        delete stage.operations;
        delete stage.select;
        // const stageObj = {
        //   ...stage,
        //   checkParametr,
        // };
        // return stageObj;
        return stage;
      });
    return newData;
  };

  function getStagesList() {
    setLoading(true);

    api.getData(
      mainUrl.list,
      (data) => {
        var d = getValidArray(data);
        setStagesList(d);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  }

  const handleAdd = () => {
    history.push({
      pathname: "add-stages",
      state: {
        method: "create",
      },
    });
  };

  useEffect(() => {
    getStagesList();
  }, []);

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {stagesList.length === 0 ? (
            <EmptyPage
              route="add-stages"
              name="Mərhələ"
              state={{ method: "create" }}
            />
          ) : (
            <div className="risk-page">
              <span className="risk-page__title">{t("scenario_stages")}</span>
              <button className="risk-page__add-button" onClick={handleAdd}>
                {t("global_add")}
              </button>
              <SuperTable data={options} />
              <div
                style={{
                  marginTop: "32px",
                }}
              >
                <span className="risk-page__title">{t("stages_grouping")}</span>
                <StageGrouping stages={stagesList} />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Stages;
