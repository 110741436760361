import "./StageGrouping.scss";

import { useSelector } from "react-redux";
import React, { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

import GL from "libs/GL";
import API from "const/API2";
import { apiRoutes } from "const/apiroutes";
import { useTranslation } from "react-i18next";

function StageGrouping(props) {
  const { t } = useTranslation();
  var api = new API();

  var stages = props.stages;

  const reduxStages = useSelector((s) => s.risk.info.info.stageGroup);
  const reduxScenarioId = useSelector((s) => s.risk.id);

  //var colors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#ff5722', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#03a9f4', '#795548', '#9e9e9e', '#607d8b'];
  var [groups, setGroups] = useState(
    reduxStages
      ? reduxStages.json
      : {
          operator: "and",
          type: "group",
          rules: [],
        }
  );
  var [sql, setSQL] = useState(reduxStages ? reduxStages.sql : "");

  function buildExpression(json) {
    if (json.type === "single") {
      return `{${json.id}}`;
    } else if (json.type === "group") {
      if (json.rules.length > 0) {
        const subConditions = json.rules.map((rule) => buildExpression(rule));
        const operator = json.operator === "and" ? "&&" : "||";
        return `(${subConditions.join(` ${operator} `)})`;
      } else {
        return "true";
      }
    }
  }

  const submitGrouping = () => {
    api.putData(
      `${apiRoutes.scenarios.put}/${reduxScenarioId}`,
      { stageGroup: { json: groups, sql: sql } },
      (_) => {
        GL.notify.success({
          title: t("answer_success"),
          text: t("stages_grouping_updated"),
        });
      },
      (err) => {
        GL.notify.error({
          title: t("answer_error"),
          text: t("stages_grouping_not_updated"),
        });
        console.log("err -->", err);
      }
    );
  };

  var andOrOptinos = [
    { value: "and", label: "AND" },
    { value: "or", label: "OR" },
  ];
  var stageOptions = [];
  if (stages.length > 0) {
    stages.map((a) => {
      stageOptions.push({ value: a.id, label: a.name });
    });
  }

  function deleteGroup(i, j) {
    var newGroups = { ...groups };
    var parentRule = newGroups;
    for (var k = 0; k < i - 1; k++) {
      parentRule = parentRule.rules[j];
    }
    parentRule.rules.splice(j, 1);
    setGroups(newGroups);
    var sql = buildExpression(newGroups);
    setSQL(sql);
  }

  function deleteRow(i, j) {
    var newGroups = { ...groups };
    var parentRule = newGroups;
    for (var k = 0; k < i; k++) {
      parentRule = parentRule.rules[k];
    }
    parentRule.rules.splice(j, 1);
    setGroups(newGroups);
    var sql = buildExpression(newGroups);
    setSQL(sql);
  }

  function addGroup(i, j) {
    var slct = document.getElementById(`andor-${i}=${j}`);
    var ind = slct.selectedIndex;
    var id = slct.options[ind].value;
    var newRule = {
      type: "group",
      operator: id,
      rules: [],
    };

    var newGroups = { ...groups };
    var parentRule = newGroups;
    for (var k = 0; k < i; k++) {
      parentRule = parentRule.rules[j];
    }
    parentRule.rules.push(newRule);
    setGroups(newGroups);
    var sql = buildExpression(newGroups);
    setSQL(sql);
  }

  function addRow(i, j) {
    var slct = document.getElementById(`stages-${i}=${j}`);
    var ind = slct.selectedIndex;
    var id = slct.options[ind].value;
    var text = slct.options[ind].innerText;
    var newRule = {
      type: "single",
      name: text,
      id: id,
    };
    var newGroups = { ...groups };
    var parentRule = newGroups;
    for (var k = 0; k < i; k++) {
      parentRule = parentRule.rules[j];
    }
    var kontrol = parentRule.rules.find((a) => a.id == newRule.id);
    if (kontrol === undefined) {
      parentRule.rules.push(newRule);
      setGroups(newGroups);
      var sql = buildExpression(newGroups);
      setSQL(sql);
    } else {
      GL.notify.warning({
        title: t("answer_error"),
        text: t("stages_columns_err"),
      });
    }
  }

  function createDivs(data, i, j) {
    if (data.rules === undefined) {
      data.rules = [];
    }
    if (data.type === "group") {
      i++;
    }
    return (
      <div className="grupcontainer">
        {data.type === "group" && (
          <div className="operator">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "space-around",
                justifyContent: "space-between",
              }}
            >
              <div>
                <span
                  style={{
                    marginRight: "10px",
                    marginLeft: "5px",
                    color: "#333",
                  }}
                >
                  {data.operator.toUpperCase()}
                </span>
                <select
                  style={{
                    width: "200px",
                    marginRight: "10px",
                    height: "40px",
                    border: "1px solid #ccc",
                  }}
                  id={`stages-${i}=${j}`}
                >
                  {stageOptions.map((a) => (
                    <option value={a.value}>{a.label}</option>
                  ))}
                </select>
                <Button
                  group={i}
                  row={j}
                  style={{ marginRight: "10px" }}
                  onClick={(e) => {
                    addRow(i, j);
                  }}
                >
                  {t("global_add")}
                </Button>
              </div>
              <div>
                <select
                  style={{
                    width: "100px",
                    marginRight: "10px",
                    height: "40px",
                    border: "1px solid #ccc",
                  }}
                  id={`andor-${i}=${j}`}
                  options={andOrOptinos}
                >
                  {andOrOptinos.map((a) => (
                    <option value={a.value}>{a.label}</option>
                  ))}
                </select>
                <Button
                  type="primary"
                  style={{ marginRight: "10px" }}
                  onClick={(e) => {
                    addGroup(i, j);
                  }}
                >
                  {t("stages_add_group")}
                </Button>
                <Button
                  type="primary"
                  onClick={(e) => {
                    deleteGroup(i, j);
                  }}
                >
                  {t("stages_delete_group")}
                </Button>
              </div>
            </div>
          </div>
        )}
        {data.rules.map((rule, index) => {
          return (
            <React.Fragment key={index}>
              {createDivs(rule, i, index)}
            </React.Fragment>
          );
        })}
        {data.type === "single" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            group={i}
            row={j}
          >
            <div>{data.name}</div>
            <Tooltip title={t("global_delete")}>
              <Button
                group={i}
                row={j}
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  deleteRow(i, j);
                }}
              />
            </Tooltip>
          </div>
        )}
      </div>
    );
  }

  if (stages.length > 0) {
    return (
      <>
        <div>{createDivs(groups, -1, 0)}</div>
        <div
          style={{
            marginTop: "16px",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Tooltip title={t("global_save")}>
            <Button type="primary" onClick={submitGrouping}>
              {t("stages_save_group")}
            </Button>
          </Tooltip>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

export default StageGrouping;
