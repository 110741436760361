import React, { useEffect, useMemo, useState } from "react";
import {
  GetPagePermission,
  GetTableSettings,
} from "../../../hooks/permissions";
import GL from "../../../libs/GL";
import SuperTable from "../../Elements/Tabulator/SuperTable";
import { useHistory } from "react-router-dom";
import { Skeleton } from "antd";
import Activity from "../../../models/Activity";
import EmptyPage from "../../Elements/Skelethon/EmptyPage";
import { useTranslation } from "react-i18next";

const pageId = "activities";

function Activities(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const permissions = GetPagePermission(17);
  const [loading, setLoading] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );
  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {
        history.push({
          pathname: "activities/create",
          state: {
            id: data.id,
            name: data.name,
            numberInList: data.numberInList,
            parentId: data.parentId,
            isProductiveActivity: data.isProductiveActivity,
            explanation: data.explanation,
            isActive: data.isActive,
            method: "edit",
          },
        });
      },
    },
    delete: {
      status: permissions["delete"],
      click: function (data) {
        GL.yesNo(t("question_deleting"), (status1) => {
          if (status1) {
            Activity.DELETE(data.id, (status2, res) => {
              if (status2) {
                GL.notify.success({
                  title: t("answer_success"),
                  text: t("activities_deleted"),
                });
                getActivityList();
              } else {
                GL.notify.error({
                  title: t("answer_error"),
                  text: t("activities_not_deleted"),
                });
              }
            });
          }
        });
      },
    },
    show: {
      status: permissions["show"],
      click: function (data) {},
    },
    copy: {
      status: false,
      click: function (data) {
        history.push({
          pathname: "activities/create",
          state: {
            id: 0,
            name: data.name,
            numberInList: data.numberInList,
            parentId: data.parentId,
            isProductiveActivity: data.isProductiveActivity,
            explanation: data.explanation,
            isActive: data.isActive,
            method: "create",
          },
        });
      },
    },
  };
  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/activities/add",
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/activities/multiDelete",
      },
    },
    settings: {
      id: pageId,
      type: "data",
      data: activityList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "+ " + t("global_create", { name: t("activities_title") }),
      createNewRoute: "activities/create",
      state: {
        id: 0,
        name: "",
        numberInList: null,
        parentId: null,
        isProductiveActivity: true,
        explanation: "",
        isActive: true,
        method: "create",
      },
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 100,

        formatter: function (cell) {
          return GL.setSelectStatus(cell);
        },
      },
      {
        title: t("global_name"),
        field: "name",
      },
      {
        title: t("activites_numberinlist"),
        field: "numberInList",
      },
      {
        title: t("activities_isproduct"),
        field: "isProductiveActivity",
      },
      {
        title: t("activities_parentid"),
        field: "parentId",
      },
      {
        title: t("activities_explanation"),
        field: "explanation",
      },
      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        id: {
          dataType: "number",
          placeholder: t("global_search"),
        },
        name: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        numberInList: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        isProductiveActivity: {
          dataType: "checkbox",
          placeholder: t("global_search"),
          mean: {
            true: t("global_yes"),
            false: t("global_no"),
            null: t("global_unknow"),
            type: "simple",
            mean: {
              true: t("global_yes"),
              false: t("global_no"),
            },
          },
        },
        parentId: {
          dataType: "number",
          placeholder: t("global_search"),
        },
        explanation: {
          dataType: "text",
          placeholder: t("global_search"),
        },
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {},
    },
  };

  const getActivityList = () => {
    setLoading(true);
    Activity.getActivityList(
      (sts, data) => {
        //data.reverse();
        setActivityList(data);
        setLoading(false);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    getActivityList();
  }, []);

  if (loading) {
    return <Skeleton active />;
  } else {
    if (activityList.length == 0) {
      return (
        <EmptyPage
          route="activities/create"
          name={t("activities_plural")}
          state={options.settings.state}
        />
      );
    } else {
      return (
        <>
          <div style={containerStyle}>
            <div className="main-layout-wrapper__head">
              <div className="simple-head">
                <h1>{t("activities_plural")}</h1>
                <span>{t("activites_desc")}</span>
              </div>
            </div>
            <SuperTable data={options} />
          </div>{" "}
        </>
      );
    }
  }
}

export default Activities;
