import "../../../../RiskPageStyles.scss";

import { useTranslation } from "react-i18next";
import { Row, Col, Select, Input } from "antd";
import React from "react";

import FormulBuilder from "components/Elements/Formul/FormulBuilder";
import FormSubmit from "components/Elements/FormSubmit/FormSubmit2";

function Sql(props) {
  const { t } = useTranslation();
  const { formik, resetFormik, submitForm, inputHasError } = props;
  const { TextArea } = Input;

  return (
    <div className="risk-page">
      <h1 className="risk-page__title">
        {`${t("stages_add_stage")} - ${t("stages_sql")}`}
      </h1>
      <div className="risk-page__elements">
        <Row gutter={24}>
          <Col sm={24} md={8} lg={8}>
            <div className="input-box">
              <span className="input-box__title">{t("global_type")}</span>
              <Select
                options={[
                  { value: "mssql", label: "MSSQL" },
                  { value: "oracle", label: "Oracle" },
                  { value: "postgreSql", label: "PostgreSQL" },
                ]}
                value={formik.values.stages.addStagesForm.sql.type}
                onChange={(value) =>
                  formik.setFieldValue("stages.addStagesForm.sql.type", value)
                }
                onBlur={formik.handleBlur("stages.addStagesForm.sql.type")}
                status={
                  inputHasError("stages.addStagesForm.sql.type") ? "error" : ""
                }
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.sql.type")
                  ? formik.errors.stages.addStagesForm.sql.type
                  : ""}
              </div>
            </div>
            <div className="input-box">
              <span className="input-box__title">{t("stages_host")}</span>
              <input
                type="text"
                className={
                  inputHasError("stages.addStagesForm.sql.host") ? "error" : ""
                }
                {...formik.getFieldProps("stages.addStagesForm.sql.host")}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.sql.host")
                  ? formik.errors.stages.addStagesForm.sql.host
                  : ""}
              </div>
            </div>
            <div className="input-box">
              <span className="input-box__title">{t("stages_port")}</span>
              <input
                type="number"
                className={
                  inputHasError("stages.addStagesForm.sql.port") ? "error" : ""
                }
                {...formik.getFieldProps("stages.addStagesForm.sql.port")}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.sql.port")
                  ? formik.errors.stages.addStagesForm.sql.port
                  : ""}
              </div>
            </div>
          </Col>
          <Col sm={24} md={8} lg={8}>
            <div className="input-box">
              <span className="input-box__title">{t("stages_username")}</span>
              <input
                type="text"
                className={
                  inputHasError("stages.addStagesForm.sql.username")
                    ? "error"
                    : ""
                }
                {...formik.getFieldProps("stages.addStagesForm.sql.username")}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.sql.username")
                  ? formik.errors.stages.addStagesForm.sql.username
                  : ""}
              </div>
            </div>
            <div className="input-box">
              <span className="input-box__title">{t("stages_password")}</span>
              <input
                type="password"
                className={
                  inputHasError("stages.addStagesForm.sql.password")
                    ? "error"
                    : ""
                }
                {...formik.getFieldProps("stages.addStagesForm.sql.password")}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.sql.password")
                  ? formik.errors.stages.addStagesForm.sql.password
                  : ""}
              </div>
            </div>
            <div className="input-box">
              <span className="input-box__title">{t("global_key")}</span>
              <input
                type="password"
                className={
                  inputHasError("stages.addStagesForm.sql.key") ? "error" : ""
                }
                {...formik.getFieldProps("stages.addStagesForm.sql.key")}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.sql.key")
                  ? formik.errors.stages.addStagesForm.sql.key
                  : ""}
              </div>
            </div>
          </Col>
          <Col sm={24} md={8} lg={8}>
            <div className="input-box">
              <span className="input-box__title">{t("stages_dbname")}</span>
              <input
                type="text"
                className={
                  inputHasError("stages.addStagesForm.sql.dbname")
                    ? "error"
                    : ""
                }
                {...formik.getFieldProps("stages.addStagesForm.sql.dbname")}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.sql.dbname")
                  ? formik.errors.stages.addStagesForm.sql.dbname
                  : ""}
              </div>
            </div>
            <div className="input-box">
              <span className="input-box__title">
                {t("stages_sql_request")}
              </span>
              <TextArea
                autoSize
                rows={6}
                className={
                  inputHasError("stages.addStagesForm.sql.sql") ? "error" : ""
                }
                {...formik.getFieldProps("stages.addStagesForm.sql.sql")}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.sql.sql")
                  ? formik.errors.stages.addStagesForm.sql.sql
                  : ""}
              </div>
            </div>
          </Col>
          <Col span={24}>
            <h2 className="risk-page__title">{t("global_result")}</h2>
            <FormulBuilder
              formik={formik}
              formikPath="stages.addStagesForm.sql"
            />{" "}
            <div className="input-box__error-field">
              {inputHasError("stages.addStagesForm.sql.resultValid")
                ? formik.errors.stages.addStagesForm.sql.resultValid
                : ""}
            </div>
          </Col>
        </Row>
      </div>

      <div className="risk-page__submittion">
        <FormSubmit cancel={resetFormik} submit={submitForm} />
      </div>
    </div>
  );
}

export default Sql;
