import React, { useState, useMemo, memo, useEffect } from "react";
import { GetPagePermission, GetTableSettings } from "../../../hooks/permissions";
import GL from "libs/GL";
import SuperTable from "../Tabulator/SuperTable";
import { Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const DynamicTable = memo(
  ({
    pageId,
    addNewTab,
    addTabWithSubTab,
    deleteTab,
    data,
    onCheckboxSelect,
    selected,
  }) => {
    const { activeRole } = useSelector(({ user }) => ({
      activeRole: user.data.roles?.find(({ status }) => status),
    }));

    const history = useHistory();
    const queryParams = new URLSearchParams(history.location.search);
    const parentTabQuery = queryParams.get("tab");
    const isExecute =
      activeRole?.label !== "inspector" && parentTabQuery !== "unallocatedRisks";
    const isLeader = activeRole?.label === "leader";
    const isPool = parentTabQuery === "unallocatedRisks";

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const permissions = GetPagePermission(9);
    const tableSettings = GetTableSettings(pageId);
    const containerStyle = useMemo(
      () => ({
        width: "100%",
        height: "100%",
      }),
      []
    );

    const operatorSet = {
      edit: {
        status: false,
      },
      delete: {
        status: false,
      },
      show: {
        status: true,
        click: function (data) {
          addTabWithSubTab(
            "Riskə baxış",
            "riskReview",
            data.risks.taxId,
            `${data.risks.id}`
          );
        },
      },
      copy: {
        status: false,
      },
    };

    const [options] = useState({
      permissions: {},
      settings: {
        id: pageId,
        type: "data",
        data,
        maxRow: tableSettings.rowNums,
        pagination: tableSettings.pagination,
        createIsDisabled: true,
        exportIsDisabled: true,
        printIsDisabled: true,
        searchIsDisabled: true,
        settingsIsDisabled: true,
      },
      columns: [
        {
          title: "ID",
          field: "id",
          width: 70,

          formatter: function (cell) {
            return GL.setSelectStatus(cell, true, onCheckboxSelect, selected);
          },
        },
        {
          title: "Ssenari növü",
          field: "risks.scenarios.name",
        },
        {
          title: "Nömrəsi",
          field: "number",
        },
        {
          title: t("voen"),
          field: "risks.taxId",
        },
        {
          title: "Vergi ödəyicisinin adı",
          field: "taxPayer",
        },
        {
          title: "Vergi orqanı",
          field: "taxAuthority",
        },
        {
          title: "Əməliyyat məbləği",
          field: "transactionAmount",
        },
        {
          title: "Qalan gün",
          field: "risks.lastExecuteDate",
          formatter: (cell) => {
            const data = cell.getData();
            const date = data?.risks?.lastExecuteDate;
            const day = GL.calculateTwoDaysBetween(date);
            return GL.renderBadgeHtml(day);
          },
          visible: !isPool,
        },
        {
          title: "İcra statusu",
          field: "risks.executeStatus.name",
          visible: isExecute,
        },
        {
          title: "İcraçı",
          field: "risks.user",
          visible: isExecute && !isPool,
          formatter: (cell) => {
            const data = cell.getData();
            const executor = data?.risks?.user;
            const surnameChar = executor?.lastName[0];
            const firstname = executor?.firstName;
            return `${surnameChar}.${firstname}`;
          },
        },
        {
          title: "Şöbə rəisi",
          field: "headOfDivision",
          visible: isExecute,
          formatter: (cell) => {
            const data = cell.getData();
            const head = data?.risks?.scenarios?.leaders?.userIds[0];
            if (!head) return "";
            const surnameChar = head?.lastName[0];
            const firstname = head?.firstName;
            return `${surnameChar}.${firstname}`;
          },
        },
        {
          title: "Fərqli qərar",
          field: "differentDecision",
          visible: isLeader,
          formatter: (cell) => {
            const data = cell.getData();
            const differentDecision = data?.differentDecision;
            return GL.setButtons(cell, {
              danger: { status: differentDecision, click: () => "" },
            });
          },
        },
        {
          title: "Növü",
          field: "type",
        },
        {
          title: "Əməliyyatlar",
          field: "operations",
          width: 110,
          formatter: function (cell) {
            return GL.setButtons(cell, operatorSet);
          },
        },
      ],
      filtering: {
        id: pageId,
        uniqueColumn: "id",
        filters: {
          taxAuthority: {
            dataType: "text",
            placeholder: t("global_search"),
          },
          "risks.scenarios.name": {
            dataType: "text",
            placeholder: t("global_search"),
          },
          number: {
            dataType: "text",
            placeholder: t("global_search"),
          },
          taxPayer: {
            dataType: "text",
            placeholder: t("global_search"),
          },
          "risks.taxId": {
            dataType: "text",
            placeholder: t("global_search"),
          },
        },
        styles: {
          simpleFilterInput: "white",
          filterButton: "buto",
        },
        secretColumns: tableSettings.hideColumns,
        rowColor: {},
      },
    });

    if (loading) {
      return <Skeleton active />;
    } else {
      return (
        <div style={containerStyle}>
          <SuperTable data={options} />
        </div>
      );
    }
  }
);

export default DynamicTable;
