import { useState, useEffect, useCallback, useRef } from "react";
import Invoice from "models/Invoice";

const useInvoices = (tools) => {
  const { riskId, activeRisk } = tools;

  const [isGivenLoading, setIsGivenLoading] = useState(false);
  const given = useRef([]);
  const setGivenData = (data) => (given.current = data);
  const givenData = given.current;

  const getRiskGivenData = useCallback(() => {
    setIsGivenLoading(true);
    Invoice.getInvoicesList(riskId, (data) => {
      const addItemsKey = data?.map((item, index) => ({ ...item, key: index }));
      setGivenData(addItemsKey);
      setIsGivenLoading(false);
    });
  }, []);

  useEffect(() => {
    if (activeRisk === riskId) getRiskGivenData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskId, activeRisk]);
  return { isGivenLoading, givenData, getRiskGivenData };
};

export default useInvoices;
