import React, { useEffect, useState } from "react";

import SuperTable from "components/Elements/Tabulator/SuperTable";
import { GetTableSettings } from "hooks/permissions";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import Risks from "models/Risks";

const pageId = "parameters";

const Parameters = ({ values }) => {
  const { t } = useTranslation();
  const tableSettings = GetTableSettings(pageId);
  const [data, setData] = useState([]);
  const ownParameters = values?.parameters;
  const getParameterList = (id) => {
    Risks.ParameterList(id, (data) => {
      const updateData = data.map((item) => ({
        ...item,
        value: ownParameters[item.code] ? ownParameters[item.code] : "",
      }));
      setData(updateData);
    });
  };

  useEffect(() => {
    if (values.scenarios?.id) getParameterList(values.scenarios?.id);
  }, [values.scenarios.id]);
  const options = {
    permissions: {},
    settings: {
      id: pageId,
      type: "data",
      data: data,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      columnSort: [], //tableSettings.columnSort,
      // ! create button settings -----------------------------------------
      createIsDisabled: true,
      // ! create button settings -----------------------------------------
    },
    columns: [
      {
        title: t("parametr_name"),
        field: "name",
      },
      {
        title: t("parametr_code"),
        field: "code",
      },
      {
        title: t("parametr_type"),
        field: "type",
      },
      {
        title: t("identifier"),
        field: "isUnique",
        formatter: (cell) => {
          var values = cell.getData();
          var isUnique = values["isUnique"];
          return isUnique ? "Hə" : "Yox";
        },
      },
      {
        title: t("isVoen"),
        field: "isVoen",
        formatter: (cell) => {
          var values = cell.getData();
          var isVoen = values["isVoen"];
          return isVoen ? "Hə" : "Yox";
        },
      },
      {
        title: t("global_qeyd"),
        field: "note",
      },
      {
        title: t("parametr_value"),
        field: "value",
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {},
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: [], //tableSettings.hideColumns,
      columnSort: [], //tableSettings.columnSort,
      rowColor: {
        field: "priority",
        type: "range",
        rules: {
          min: 0,
          max: 10,
          ranges: [
            { max: 6, color: "#FFDD263d" },
            { max: 7, color: "#FFB0343d" },
            { max: 8, color: "#FF681C3d" },
            { max: 9, color: "#DF26383d" },
            { max: 10, color: "#8638e53d" },
          ],
        },
      },
    },
  };

  return (
    <div className="risk-page">
      <h1 className="risk-page__title">{t("parameter_title")}</h1>
      <Row gutter={24}>
        <Col span={24}>
          <SuperTable data={options} />
        </Col>
      </Row>
    </div>
  );
};

export default Parameters;
