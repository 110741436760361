import { useState } from "react";

import API from "const/API2";
import { apiRoutes } from "const/apiroutes";
import GL from "libs/GL";

const api = new API();

function useDeleteFileOrFolder({
  id,
  isFolder,
  getLastTableData,
  getLastAdded = null,
}) {
  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] =
    useState(false);

  const openConfirmDeleteModal = () => {
    setConfirmDeleteModalIsOpen(true);
  };

  const closeConfirmDeleteModal = () => {
    setConfirmDeleteModalIsOpen(false);
  };

  const submitDeleteFileOrFolder = (callBack, errCallback) => {
    const postUrl = apiRoutes.library.deleteFileOrFolder({ id });

    api.deleteData(
      postUrl,
      (data) => {
        getLastTableData();
        getLastAdded && getLastAdded();
        callBack && callBack(data);
        GL.notify.success({ text: "Uğurla silindi" });
      },
      (err) => {
        GL.notify.error({ text: "Siline bilmədi" });
        if (errCallback) {
          errCallback(err);
        }
      },
    );
  };

  return {
    confirmDeleteModalIsOpen,
    openConfirmDeleteModal,
    closeConfirmDeleteModal,
    submitDeleteFileOrFolder,
  };
}

export default useDeleteFileOrFolder;
