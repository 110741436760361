import { useEffect, useState } from "react";

import LibraryMainModel from "../models/LibraryMainModel";

export function useLastViewed() {
  const [lastViewedList, setLastViewedList] = useState([]);
  const [lastViewedLoading, setLastViewedLoading] = useState(false);

  const getLasrViewedListForTable = () => {
    setLastViewedLoading(true);
    LibraryMainModel.getLastViewed(
      (data) => {
        setLastViewedLoading(false);
        setLastViewedList(data);
      },
      (err) => {
        setLastViewedLoading(false);
        console.log("err ==>", err);
      }
    );
  };

  useEffect(() => {
    getLasrViewedListForTable();
  }, []);

  return { lastViewedList, getLasrViewedListForTable, lastViewedLoading };
}
