import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import PageTitle from "components/Elements/PageTitle/PageTitle";
import AntTabs from "components/Elements/Tabs/AntTabs";
import { setRiskData } from "redux/actions/index";
import { scenario } from "const/initialDatas";
import { apiRoutes } from "const/apiroutes";
import RestApi from "./RestApi/RestApi";
import Phyton from "./Phyton/Phyton";
import Statik from "./Statik/Statik";
import Soap from "./Soap/Soap";
import Info from "./Info/Info";
import API from "const/API2";
import Sql from "./Sql/Sql";

function AddStages(props) {
  const { t } = useTranslation();
  var api = new API();
  const dispatch = useDispatch();
  const history = useHistory();

  const hash = history.location.hash;
  const pageState = props.location.state;
  const reduxRisk = useSelector((state) => state.risk);
  const [validState, setValidState] = useState(null);
  const [activeTab, setActiveTab] = useState("");

  // ! Dev: Aslan :
  const method = pageState?.method || "";
  const id = pageState?.id || "";
  // ! "method" variable comes from source page. Has value of "create", "copy" or "edit"
  // ! "id" variable comes from source page. Has "id" of scenario
  // ! if "method" is edit, id is required

  const addStagesEndpoint = apiRoutes.stages.add;
  const getStagesByIdEndpoint = apiRoutes.stages.get;
  const putStagesEndpoint = apiRoutes.stages.put;

  let initialValues = scenario;
  if (method === "create") {
    // ! on stages create, current redux risk state is called to preserve addrisk tabs data. only parameters field is resetted
    initialValues = {
      ...reduxRisk,
      stages: {
        addStagesForm: scenario.stages.addStagesForm,
      },
    };
  }

  if (method === "edit" || method === "create2") {
    initialValues = reduxRisk;
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validState,
    onSubmit: (values) => {
      if (method === "create2") {
        postStages(values);
      }
      if (method === "edit") {
        putStages(values);
      }
    },
  });

  const resetFormik = () => {
    formik.setFieldValue("stages.addStagesForm", scenario.stages.addStagesForm);

    history.replace({
      pathname: "add-risk",
      hash: "stages",
      state: { method: "edit", id: reduxRisk.id },
    });
  };

  const submitForm = () => {
    navigateToErrTab();
    formik.handleSubmit();
  };

  // ! ======================================== Formik shorthands =====================================
  const queryTypeValue = formik.values.stages.addStagesForm.info.queryType;
  const repeatErrValue = formik.values.stages.addStagesForm.info.repeatErr;
  // const controlRuleValue = formik.values.stages.addStagesForm.info.controlRule;
  const chooseCompareOptionValue =
    formik.values.stages.addStagesForm.statik.chooseCompareOption;
  // ! ================================= End Formik shorthands ======================================

  // ! =================================== Formik Enums =========================================
  const queryTypeEnum = {
    optionsArr: [
      { value: "statik", label: t("stages_query_statik") },
      { value: "restApi", label: t("stages_query_restapi") },
      { value: "soap", label: t("stages_query_soap") },
      { value: "sql", label: t("stages_query_sql") },
      { value: "python", label: t("stages_query_phyton") },
    ],
  };
  if (
    queryTypeValue === "python"
    // && controlRuleValue !== "directUsage"
  ) {
    // queryTypeEnum.checkParametr = false;
    // queryTypeEnum.controlRule = false;
    queryTypeEnum.compareValue = false;
    queryTypeEnum.python = true;
  } else {
    // queryTypeEnum.checkParametr = true;
    // queryTypeEnum.controlRule = true;
    queryTypeEnum.compareValue = true;
    queryTypeEnum.python = false;
  }

  const repeatErrEnum = {};
  if (
    repeatErrValue === true
    // && controlRuleValue !== "directUsage"
  ) {
    repeatErrEnum.repeatPer = true;
    repeatErrEnum.repeatLast = true;
  } else {
    repeatErrEnum.repeatPer = false;
    repeatErrEnum.repeatLast = false;
  }
  const controlRuleEnum = {
    optionsArr: [
      { value: "=", label: "=" },
      { value: "<>", label: "<>" },
      { value: ">", label: ">" },
      { value: ">=", label: ">=" },
      { value: "<", label: "<" },
      { value: "<=", label: "<=" },
      { value: "isList", label: t("stages_control_islist") },
      { value: "between", label: t("stages_control_between") },
      { value: "outside", label: t("stages_control_outside") },
      { value: "directUsage", label: t("stages_control_directusage") },
    ],
  };
  if (
    false
    // controlRuleValue === "directUsage"
  ) {
    controlRuleEnum.queryType = false;
    controlRuleEnum.repeatErr = false;
  } else {
    controlRuleEnum.queryType = true;
    controlRuleEnum.repeatErr = true;
  }
  const chooseCompareOption = {
    optionsArr: [
      { value: "static", label: t("stages_choose_static") },
      {
        value: "parameter",
        label: t("stages_choose_parameter"),
      },
      { value: "list", label: t("stages_choose_list") },
    ],
  };
  chooseCompareOption.static = chooseCompareOptionValue === "static";
  chooseCompareOption.parameter = chooseCompareOptionValue === "parameter";
  chooseCompareOption.list = chooseCompareOptionValue === "list";
  // ! ======================================== End Formik Enums ===========================================

  const formConfigs = {
    queryTypeEnum,
    repeatErrEnum,
    controlRuleEnum,
    queryTypeValue,
  };

  const inputHasError = (string) => {
    const keys = string.split(".");
    let fieldTouched = formik.touched;
    let fieldErrors = formik.errors;
    keys.forEach((keyStr) => {
      fieldTouched = fieldTouched?.[keyStr];
      fieldErrors = fieldErrors?.[keyStr];
    });

    if (fieldTouched && fieldErrors) {
      return true;
    }

    return false;
  };

  function getbodyData(values) {
    const addStagesForm = values.stages.addStagesForm;
    const { info } = addStagesForm;

    var tabInfo = addStagesForm[info.queryType];
    var tabJSON = {};

    if (info.queryType === "statik") {
      switch (tabInfo.chooseCompareOption) {
        case "parameter": {
          tabJSON = {
            type: "parameter",
            value: tabInfo["parameter"],
            result: tabInfo["result"],
            formul: tabInfo["formul"],
            resultValid: tabInfo["resultValid"],
          };
          break;
        }
        case "static": {
          tabJSON = {
            type: "static",
            value: tabInfo["static"],
            result: tabInfo["result"],
            formul: tabInfo["formul"],
            resultValid: tabInfo["resultValid"],
          };
          break;
        }
        case "list": {
          tabJSON = {
            type: "list",
            value: tabInfo["list"],
            result: tabInfo["result"],
            formul: tabInfo["formul"],
            resultValid: tabInfo["resultValid"],
          };
          break;
        }
      }
    } else {
      tabJSON = tabInfo;
    }

    const bodyData = {
      isActive: true,
      name: info.name,
      note: info.note,
      status: info.status,
      orderNum: info.orderNum,
      scenariosDto: {
        id: values.id,
      },
      compareValue: queryTypeEnum.compareValue ? info.compareValue : null,
      // controlRule: queryTypeEnum.controlRule ? info.controlRule : null,
      // parametersDto: queryTypeEnum.checkParametr
      //   ? {
      //       id: info.checkParametr,
      //     }
      //   : null,
      queryType: controlRuleEnum.queryType ? info.queryType : null,
      repeatErr: controlRuleEnum.repeatErr ? info.repeatErr : null,
      repeatLast: repeatErrEnum.repeatLast ? info.repeatLast : null,
      repeatPer: repeatErrEnum.repeatPer ? info.repeatPer : null,

      rules: tabJSON,
    };
    return bodyData;
  }

  function getStageById(id) {
    api.getData(
      `${getStagesByIdEndpoint}/${id}`,
      (data) => {
        const stagesData = JSON.parse(JSON.stringify(data));
        const { scenariosDto, parametersDto, rules, ...rest } = stagesData;
        // rest.checkParametr = parametersDto.id;

        formik.setFieldValue("stages.addStagesForm.info", rest);

        if (rest.queryType !== "statik") {
          formik.setFieldValue(`stages.addStagesForm.${rest.queryType}`, rules);
        } else {
          const { type, value, result, formul, resultValid } = rules;
          const statikTabData = {
            chooseCompareOption: "",
            static: "",
            parameter: "",
            list: "",
          };
          statikTabData.chooseCompareOption = type;
          statikTabData.result = result;
          statikTabData.formul = formul;
          statikTabData.resultValid = resultValid;

          switch (type) {
            case "parameter":
              {
                statikTabData.parameter = value;
              }
              break;
            case "static":
              {
                statikTabData.static = value;
              }
              break;
            case "list":
              {
                statikTabData.list = value;
              }
              break;
          }

          formik.setFieldValue(`stages.addStagesForm.statik`, statikTabData);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function postStages(values) {
    const data = getbodyData(values);
    api.postData(
      addStagesEndpoint,
      data,
      (res) => {
        history.replace({
          pathname: "add-risk",
          hash: "stages",
          state: { method: "edit", id: values.id },
        });
      },
      (err) => {
        console.log("post stages err", err);
      }
    );
  }

  function putStages(values) {
    const data = getbodyData(values);
    api.putData(
      `${putStagesEndpoint}/${id}`,
      data,
      (res) => {
        history.replace({
          pathname: "add-risk",
          hash: "stages",
          state: { method: "edit", id: values.id },
        });
      },
      (err) => {
        console.log("post stages err", err);
      }
    );
  }

  function updateSchema() {
    setValidState(
      Yup.object().shape({
        stages: Yup.object().shape({
          addStagesForm: Yup.object().shape({
            info: Yup.object().shape({
              name: Yup.string().required(t("yup_name")),
              status: Yup.string().required(t("yup_choose_value")),
              orderNum: Yup.string().required(t("stages_yup_ordernum")),
              queryType: controlRuleEnum.queryType
                ? Yup.string().required(t("yup_choose_value"))
                : Yup.string().nullable(),
              repeatErr: controlRuleEnum.repeatErr
                ? Yup.string().required(t("yup_choose_value"))
                : Yup.string().nullable(),
              repeatPer: repeatErrEnum.repeatPer
                ? Yup.string().required(t("stages_yup_repeatper"))
                : Yup.string().nullable(),
              repeatLast: repeatErrEnum.repeatLast
                ? Yup.string().required(t("stages_yup_repeatlast"))
                : Yup.string().nullable(),
              // checkParametr: queryTypeEnum.checkParametr
              //   ? Yup.string().required(t("yup_choose_value"))
              //   : Yup.string().nullable(),
              // controlRule: queryTypeEnum.controlRule
              //   ? Yup.string().required(t("yup_choose_value"))
              //   : Yup.string().nullable(),
            }),
            python: queryTypeEnum.python
              ? Yup.object().shape({
                  name: Yup.string().required(t("yup_choose_value")),
                })
              : Yup.object().shape({
                  name: Yup.string().nullable(),
                }),
            statik:
              queryTypeValue === "statik" &&
              Yup.object().shape({
                chooseCompareOption: Yup.string().required(
                  t("stages_yup_compare")
                ),
                static: chooseCompareOption.static
                  ? Yup.string().required(t("yup_choose_value"))
                  : Yup.string().nullable(),

                parameter: chooseCompareOption.parameter
                  ? Yup.string().required(t("yup_choose_value"))
                  : Yup.string().nullable(),

                list: chooseCompareOption.list
                  ? Yup.string().required(t("yup_choose_value"))
                  : Yup.string().nullable(),
                // result: Yup.string().required(t("stages_yup_result")),
                resultValid: Yup.string().required(t("stages_yup_result")),
              }),
            restApi:
              queryTypeValue === "restApi" &&
              Yup.object().shape({
                method: Yup.string().required(t("yup_choose_value")),
                endPoint: Yup.string().required(t("stages_yup_endpoint")),
                headers: Yup.lazy((headersObj) => {
                  return Yup.object()
                    .shape(
                      Object.keys(headersObj).reduce((acc, key) => {
                        acc[key] = Yup.mixed()
                          .required(`${key} ${t("yup_value")}`)
                          .test(
                            "is-not-null-undefined",
                            `${key} ${t("stages_yup_not_null")}`,
                            (value) => {
                              return value !== null && value !== undefined;
                            }
                          );
                        return acc;
                      }, {})
                    )
                    .test(
                      "is-not-empty",
                      { err: t("stages_yup_headers") },
                      (value) => {
                        return Object.keys(value).length > 0;
                      }
                    );
                }),
                body: Yup.lazy((bodyObj) => {
                  if (Array.isArray(bodyObj)) {
                    return Yup.array()
                      .min(1, t("stages_yup_body"))
                      .required(t("stages_yup_body"));
                  } else {
                    return Yup.object()
                      .shape(
                        Object.keys(bodyObj).reduce((acc, key) => {
                          acc[key] = Yup.mixed()
                            .required(`${key} ${t("yup_value")}`)
                            .test(
                              "is-not-null-undefined",
                              `${key} ${t("stages_yup_not_null")}`,
                              (value) => {
                                return value !== null && value !== undefined;
                              }
                            );
                          return acc;
                        }, {})
                      )
                      .test(
                        "is-not-empty",
                        { err: t("stages_yup_body") },
                        (value) => {
                          return Object.keys(value).length > 0;
                        }
                      );
                  }
                }),
                // result: Yup.string().required(t("stages_yup_result")),
                parser: Yup.array()
                  .min(1, t("stages_yup_parser"))
                  .required(t("stages_yup_parser")),
                resultValid: Yup.string().required(t("stages_yup_result")),
              }),
            soap:
              queryTypeValue === "soap" &&
              Yup.object().shape({
                method: Yup.string().required(t("yup_choose_value")),
                endPoint: Yup.string().required(t("stages_yup_endpoint")),
                headers: Yup.lazy((headersObj) => {
                  return Yup.object()
                    .shape(
                      Object.keys(headersObj).reduce((acc, key) => {
                        acc[key] = Yup.mixed()
                          .required(`${key} üçün dəyər daxil edin`)
                          .test(
                            "is-not-null-undefined",
                            `${key} ${t("yup_value")}`,
                            (value) => {
                              return value !== null && value !== undefined;
                            }
                          );
                        return acc;
                      }, {})
                    )
                    .test(
                      "is-not-empty",
                      { err: t("stages_yup_headers") },
                      (value) => {
                        return Object.keys(value).length > 0;
                      }
                    );
                }),
                body: Yup.string()
                  .required(t("stages_yup_xml"))
                  .test("xml is valid", t("stages_yup_xml_format"), (value) => {
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(
                      value,
                      "application/xml"
                    );
                    return (
                      xmlDoc.getElementsByTagName("parsererror").length === 0
                    );
                  }),
                // result: Yup.string().required(t("stages_yup_result")),
                parser: Yup.array()
                  .min(1, t("stages_yup_parser"))
                  .required(t("stages_yup_parser")),
                resultValid: Yup.string().required(t("stages_yup_result")),
              }),
            sql:
              queryTypeValue === "sql" &&
              Yup.object().shape({
                type: Yup.string().required(t("yup_choose_value")),
                host: Yup.string().required(t("stages_yup_host")),
                port: Yup.string().required(t("stages_yup_port")),
                username: Yup.string().required(t("stgaes_yup_username")),
                password: Yup.string().required(t("stages_yup_password")),
                dbname: Yup.string().required(t("stages_yup_dbname")),
                sql: Yup.string().required(t("stages_yup_sql")),
                // result: Yup.string().required(t("stages_yup_result")),
                resultValid: Yup.string().required(t("stages_yup_result")),
              }),
          }),
        }),
      })
    );
  }

  function onTabsChange(keyStr) {
    history.push({ hash: keyStr, state: { ...pageState } });
  }

  function navigateToErrTab() {
    const formikErr = formik.errors.stages?.addStagesForm || {};
    const formikErrorsArr = Object.keys(formikErr);
    if (formikErrorsArr?.length) {
      history.replace({
        hash: `#${formikErrorsArr[0]}`,
        state: { ...pageState },
      });
    }
  }

  const items = [
    {
      key: "#info",
      label: t("global_info"),
      children: (
        <Info
          formik={formik}
          resetFormik={resetFormik}
          submitForm={submitForm}
          inputHasError={inputHasError}
          formConfigs={formConfigs}
        />
      ),
    },
    {
      key: "#statik",
      label: t("stages_static"),
      children: (
        <Statik
          formik={formik}
          resetFormik={resetFormik}
          submitForm={submitForm}
          inputHasError={inputHasError}
          formConfigs={chooseCompareOption}
        />
      ),
      disabled:
        formik.values.stages.addStagesForm.info.queryType !== "statik" && true,
    },
    {
      key: "#restApi",
      label: t("stages_restapi"),
      children: (
        <RestApi
          formik={formik}
          resetFormik={resetFormik}
          submitForm={submitForm}
          inputHasError={inputHasError}
        />
      ),
      disabled:
        formik.values.stages.addStagesForm.info.queryType !== "restApi"
          ? true
          : false,
    },

    {
      key: "#soap",
      label: t("stages_soap"),
      children: (
        <Soap
          formik={formik}
          resetFormik={resetFormik}
          submitForm={submitForm}
          inputHasError={inputHasError}
        />
      ),
      disabled:
        formik.values.stages.addStagesForm.info.queryType !== "soap" && true,
    },
    {
      key: "#sql",
      label: t("stages_sql"),
      children: (
        <Sql
          formik={formik}
          resetFormik={resetFormik}
          submitForm={submitForm}
          inputHasError={inputHasError}
        />
      ),
      disabled:
        formik.values.stages.addStagesForm.info.queryType !== "sql" && true,
    },
    {
      key: "#python",
      label: "Python",
      children: (
        <Phyton
          formik={formik}
          resetFormik={resetFormik}
          submitForm={submitForm}
          inputHasError={inputHasError}
        />
      ),
      disabled:
        formik.values.stages.addStagesForm.info.queryType !== "python" && true,
    },
  ];

  useEffect(() => {
    if (!method || !pageState) {
      history.replace("/");
      return;
    } else if (!hash) {
      history.replace({ hash: "info", state: { ...pageState } });
      return;
    }
    setActiveTab(hash);
  }, [hash]);

  useEffect(() => {
    if (hash === "#info") {
      history.push({ hash: "info", state: { ...pageState } });
    } else if (hash === `#${queryTypeValue}`) {
      history.push({ hash, state: { ...pageState } });
    } else {
      history.replace({ hash: "info", state: { ...pageState } });
    }
  }, [queryTypeValue]);

  useEffect(() => {
    dispatch(setRiskData(formik.values));
    updateSchema();
  }, [formik.values]);

  useEffect(() => {
    if (method === "edit") {
      getStageById(id);
    }
  }, []);

  useEffect(() => {
    if (method === "create" && Object.keys(formik.touched).length) {
      history.replace({ hash, state: { method: "create2" } });
    }
  }, [formik.touched]);

  useEffect(() => {
    formik.setFieldTouched("setFormikTouched");
  }, []);

  return (
    <div className="main-layout-wrapper">
      <PageTitle big={t("scenarios")} small={t("scenario_desc")} />

      <AntTabs
        items={items}
        activeTab={activeTab}
        onTabsChange={onTabsChange}
      />
    </div>
  );
}

export default AddStages;
