import React from "react";
import { Typography } from "antd";
import { Space } from "antd";
import "./RTMSMessenger.css";
import { useAppContext } from "./AppContext";
import { useEffect } from "react";
import { MessengerModel } from "./model/MessengerModel";
const { Title } = Typography;
const ContactChat = () => {
  const { state, dispatch, handleItemClick } = useAppContext();
  const { contacts } = state;


  useEffect(() => {
    MessengerModel.getContacts((data) => {
      dispatch({ type: "SET_CONTACTS", payload: data });
    });
  }, []);

  return (
    <div>
      {" "}
      <Space
        direction="vertical"
        size="small"
        style={{
          alignItems: "start",
          justifyContent: "start",
        }}
      >
        {contacts &&
          contacts.map((contact) => {
            return (
              <Space
                key={contact.uid}
                direction="horizontal"
                size="small"
                style={{
                  cursor: "pointer",
                  
                  borderRadius: "4px",
                  width: 250,
                  alignItems: "center",
                  justifyContent: "start",
                }}
                className="user-item"
                onClick={()=>{
                  // console.log(e, "E");
                  // console.log(group, "GROUP");
                  dispatch({ type: "SET_IS_RECENT_OPEN", payload: true });
                  dispatch({ type: "SET_IS_GROUP_OPEN", payload: false });
                  dispatch({ type: "SET_IS_CONTACTS_OPEN", payload: false });
                  dispatch({ type: "TOGGLE_CHAT", payload: true });
                  // dispatch({ type: "SET_CLICK_ID", payload: group.uid });
                  // dispatch({ type: "SET_CLICK_GROUP", payload: true });
                  handleItemClick(contact.uid);
                  // dispatch({ type: "SET_CLICK_ID", payload: group.id });

                  dispatch({ type: "SET_SELECTED_USER", payload: contact });
                  dispatch({ type: "SET_SELECTED_ITEM", payload: contact.uid });
                  dispatch({ type: "TOGGLE_INFO", payload: false });
                }}
              >
                <Space style={{ paddingTop: "5px" }}>
                  {" "}
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="20" fill="white" />
                    <rect
                      width="20"
                      height="20"
                      transform="translate(10 10)"
                      fill="white"
                    />
                    <path
                      d="M28.3102 26.4062C27.239 24.5262 25.5665 23.0614 23.5617 22.2476C24.5587 21.4999 25.2951 20.4574 25.6666 19.2679C26.0382 18.0784 26.0261 16.8022 25.632 15.62C25.2379 14.4377 24.4819 13.4095 23.4709 12.6808C22.46 11.9522 21.2454 11.5601 19.9992 11.5601C18.753 11.5601 17.5384 11.9522 16.5275 12.6808C15.5165 13.4095 14.7605 14.4377 14.3664 15.62C13.9723 16.8022 13.9602 18.0784 14.3318 19.2679C14.7034 20.4574 15.4398 21.4999 16.4367 22.2476C14.432 23.0614 12.7594 24.5262 11.6883 26.4062C11.6219 26.5129 11.5777 26.6319 11.5582 26.756C11.5387 26.8802 11.5444 27.007 11.5749 27.1289C11.6054 27.2508 11.6602 27.3653 11.7358 27.4657C11.8115 27.566 11.9066 27.6501 12.0154 27.713C12.1242 27.7759 12.2445 27.8162 12.3693 27.8317C12.494 27.8471 12.6206 27.8373 12.7414 27.8028C12.8623 27.7684 12.975 27.71 13.0728 27.6311C13.1706 27.5522 13.2516 27.4545 13.3109 27.3437C14.7266 24.8968 17.2266 23.4374 19.9992 23.4374C22.7719 23.4374 25.2719 24.8976 26.6875 27.3437C26.816 27.5503 27.0199 27.6989 27.256 27.758C27.4921 27.8171 27.7419 27.7821 27.9526 27.6603C28.1633 27.5386 28.3184 27.3396 28.3852 27.1056C28.4519 26.8715 28.425 26.6207 28.3102 26.4062ZM15.9367 17.4999C15.9367 16.6964 16.175 15.911 16.6214 15.2429C17.0678 14.5748 17.7022 14.0541 18.4446 13.7466C19.1869 13.4392 20.0037 13.3587 20.7918 13.5155C21.5798 13.6722 22.3037 14.0591 22.8718 14.6273C23.44 15.1954 23.8269 15.9193 23.9837 16.7073C24.1404 17.4954 24.06 18.3122 23.7525 19.0546C23.445 19.7969 22.9243 20.4314 22.2562 20.8777C21.5881 21.3241 20.8027 21.5624 19.9992 21.5624C18.9222 21.5612 17.8896 21.1328 17.128 20.3712C16.3664 19.6096 15.938 18.577 15.9367 17.4999Z"
                      fill="#C64D42"
                    />
                  </svg>
                </Space>

                <Title
                  style={{
                    fontSize: "12px",
                    color: "#000",
                    fontWeight: "600",
                    alignItems: "center",
                  }}
                >
                  {contact.name}
                </Title>
              </Space>
            );
          })}
      </Space>
    </div>
  );
};

export default ContactChat;
