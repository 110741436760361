import CollaboratorsModel from "models/Collaborators";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { URLQueryMethods } from "utils/URLQueryMethods";

function useCollaboratorsFilter({ roleId }) {
  const [collabTableData, setCollabTableData] = useState([]);
  const [sectorOptions, setSectorOptions] = useState([]);
  const [userGroupOptions, setUserGroupOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [collabCount, setCollabCount] = useState({});

  const history = useHistory();
  const searchParamsHandler = new URLSearchParams(history.location.search);

  //* sectorId ----------------------------------------
  const sectorId = searchParamsHandler.get("sectorId") ?? null;
  const handleSectorSelect = (val) => {
    URLQueryMethods({
      history,
      searchParamsHandler,
      set: [{ setQueryType: "sectorId", setQueryValue: val }],
      deleteParam: [{ deleteQueryType: "groupId" }, { deleteQueryType: "userIds" }],
    });
  };
  const handleClearSector = () => {
    URLQueryMethods({
      history,
      searchParamsHandler,
      deleteParam: [{ deleteQueryType: "sectorId" }],
    });
  };
  const sectorSelectProps = {
    onChange: handleSectorSelect,
    onClear: handleClearSector,
    value: sectorId ? +sectorId : null,
    options: sectorOptions,
  };
  //* sectorId ----------------------------------------

  //* groupId ----------------------------------------
  const groupId = searchParamsHandler.get("groupId") ?? null;
  const handleUserGroupSelect = (val) => {
    URLQueryMethods({
      history,
      searchParamsHandler,
      set: [{ setQueryType: "groupId", setQueryValue: val }],
      deleteParam: [{ deleteQueryType: "userIds" }],
    });
  };
  const handleClearUserGroup = () => {
    URLQueryMethods({
      history,
      searchParamsHandler,
      deleteParam: [{ deleteQueryType: "groupId" }],
    });
  };
  const userGroupSelectProps = {
    onChange: handleUserGroupSelect,
    onClear: handleClearUserGroup,
    value: groupId ? +groupId : groupId,
    options: userGroupOptions,
  };
  //* groupId ----------------------------------------

  //* userIds ----------------------------------------
  const userIds = searchParamsHandler.get("userIds") ?? null;
  const handleUserSelect = (val) => {
    URLQueryMethods({
      history,
      searchParamsHandler,
      set: [{ setQueryType: "userIds", setQueryValue: val }],
      // deleteParam: [{ deleteQueryType: "userIds" }],
    });
  };
  const handleClearUser = () => {
    URLQueryMethods({
      history,
      searchParamsHandler,
      deleteParam: [{ deleteQueryType: "userIds" }],
    });
  };
  const userSelectProps = {
    onChange: handleUserSelect,
    onClear: handleClearUser,
    value: userIds ? +userIds : userIds,
    options: userOptions,
  };
  //* userId ----------------------------------------

  //* collaborators -------------------------------
  const getAllCollaborators = () => {
    const searchParamsForApi = new URLSearchParams(searchParamsHandler.toString());

    searchParamsForApi.delete("tab");
    searchParamsForApi.delete("subTab");

    CollaboratorsModel.getAllCollaborators(
      roleId,
      searchParamsForApi.toString(),
      (data) => {
        const { collaborators, ...collabCount } = data;
        setCollabTableData(collaborators);
        setCollabCount(collabCount);
      },
      (err) => {
        console.log("err ==>", err);
      }
    );
  };
  const collaboratorsTableProps = {
    collabTableData,
    getAllCollaborators,
  };
  //* collaborators -------------------------------

  useEffect(() => {
    CollaboratorsModel.getSectorSelectData(
      roleId,
      (data) => {
        const sectorOptionsData = data.map((sector) => ({
          value: sector.id,
          label: sector.name,
        }));
        setSectorOptions(sectorOptionsData);
      },
      (err) => {
        console.log("sectors err ==>", err);
      }
    );
    getAllCollaborators();
  }, []);

  useEffect(() => {
    CollaboratorsModel.getGroupSelectData(
      roleId,
      sectorId,
      (data) => {
        const sectorOptionsData = data.map((userGroup) => ({
          value: userGroup.id,
          label: userGroup.name,
        }));
        setUserGroupOptions(sectorOptionsData);
      },
      (err) => {
        console.log("sectors err ==>", err);
      }
    );
  }, [sectorId]);

  useEffect(() => {
    CollaboratorsModel.getUsersSelectData(
      roleId,
      groupId,
      (data) => {
        const sectorOptionsData = data.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }));
        setUserOptions(sectorOptionsData);
      },
      (err) => {
        console.log("sectors err ==>", err);
      }
    );
  }, [groupId]);

  return {
    sectorSelectProps,
    userGroupSelectProps,
    userSelectProps,
    collaboratorsTableProps,
    collabCount,
  };
}

export default useCollaboratorsFilter;
