import API from '../const/API2';
import {apiRoutes} from '../const/apiroutes';

var api = new API();

class RiskGroup {

  constructor(obj) {
    this.id = obj['id'];
    this.name = obj['name'];
    this.totalName = obj['totalName'];
    this.parentId = obj['parentId'];
    this.code = obj['code'];
    this.isActive = obj['isActive'];
    this.departments = [];
  }

  getName(){
    return this.name;
  }

  getFullName(){
    return this.totalName;
  }

  static getRiskGroupFromArray(data){
    var arr = [];
    data.map((a)=>{
      var c = new RiskGroup(a);
      arr.push(c);
    });
    return arr;
  }

  static getRiskGroupList(callback,errCallback){
    api.getData(apiRoutes.riskGroups.list,(data)=>{
      var arr = RiskGroup.getRiskGroupFromArray(data);
      callback(arr);
    },errCallback);
  }

  getTableItem(){
    return {
      id: this.id,
      name: this.name,
      totalName: this.totalName,
      code: this.code,
      parentId: this.parentId,
      isActive: this.isActive
    };
  }

  static getForTableData(data){
    var arr = [];
    data.map((a)=>{
      arr.push(a.getTableItem());
    });
    return arr;
  }

//   getDepartments(callback){
//     debugger;
//     api.getData(apiRoutes.departments.list,(data)=>{
//       if(callback!==undefined){
//         var filtered_data = data.filter((a)=>{
//           if(a.companyDto==null){
//             return false;
//           }
//           if(this.id==a.companyDto.id){
//             return true;
//           }
//         });
//         this.departments = filtered_data;
//         callback(filtered_data);
//       }
//     });
//   }

//   static getDepartmentsByCompanyID(id,callback){
//     api.getData(apiRoutes.departments.list,(data)=>{
//       if(callback!==undefined){
//         var filtered_data = data.filter((a)=>{
//           if(a.companiesDto==null){
//             return false;
//           }
//           if(a.companiesDto.id==id){
//             return true;
//           }
//         });
//         callback(filtered_data);
//       }
//     });
//   }

  static sortDESC(a, b) {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    return 0;
  }

  static getArrayForSelect(data) {
    var arr = [];
    data.map((a) => {
      arr.push({
        value: a.id,
        label: a.name
      });
    });
    return arr;
  }

  static UPDATE(data,callback,errCallback){
    if(data==false){
      return null;
    }
    var id = data['id'];
    
    if(id!==0 && id!=='' && id!==null && id!==undefined){
      api.putData(apiRoutes.riskGroups.put+'/'+id,data,(res)=>{
        if(callback!==undefined){callback(res);}
      },errCallback);
    }
  }

  static CREATE(data,callback,errCallback){
    if(data==false){
      return null;
    }
    if(data['id']!==undefined){
      delete data['id'];
    }
    
    api.postData(apiRoutes.riskGroups.add,data,(res)=>{
      if(callback!==undefined){callback(res);}
    },errCallback);
  }

  static DELETE(id,callback){
    if(id==0 || id=='' || id==null || id==undefined){
      return null;
    }
    id = Number(id);
    api.deleteData(`${apiRoutes.riskGroups.put}/${id}`,(data)=>{
      callback(true,data);
    },(err)=>{
      callback(false,err);
    });
  }

  
}

export default RiskGroup;