import React, { useState } from "react";

import { Space } from "antd";
import {
  HistoryOutlined,
  UsergroupAddOutlined,
  BookOutlined
} from "@ant-design/icons";
import { useAppContext } from "./AppContext";

import "./RTMSMessenger.css";

const MessengerMenu = () => {
  const { state, dispatch } = useAppContext();
  const { isRecentOpen, isGroupOpen, isContactOpen } = state;

  return (
    <div>
      {" "}<Space
        direction="horizontal"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: "20px 0",
          marginRight: "23px",
          marginLeft: "23px"
        }}
      >
        <HistoryOutlined
          style={{
            fontSize: "16px",
            color: isRecentOpen ? "#C64D42" : "#B6C0D0",
            transition: "0.3s all ease-in-out",
            backgroundColor: "white",
            borderRadius: "4px",
            padding: "10px 20px"
          }}
          onClick={() => {
            dispatch({ type: "SET_IS_RECENT_OPEN", payload: true });
            dispatch({ type: "SET_IS_GROUP_OPEN", payload: false });
            dispatch({ type: "SET_IS_CONTACTS_OPEN", payload: false });
          }}
        />
        <UsergroupAddOutlined
          style={{
            fontSize: "16px",
            color: isGroupOpen ? "#C64D42" : "#B6C0D0",
            transition: "0.3s all ease-in-out",
            backgroundColor: "white",
            borderRadius: "4px",
            padding: "10px 20px"
          }}
          onClick={() => {
            dispatch({ type: "SET_IS_RECENT_OPEN", payload: false });
            dispatch({ type: "SET_IS_GROUP_OPEN", payload: true });
            dispatch({ type: "SET_IS_CONTACTS_OPEN", payload: false });
          }}
        />
        <BookOutlined
          style={{
            fontSize: "16px",
            color: isContactOpen ? "#C64D42" : "#B6C0D0",
            transition: "0.3s all ease-in-out",
            backgroundColor: "white",
            borderRadius: "4px",
            padding: "10px 20px"
          }}
          onClick={() => {
            dispatch({ type: "SET_IS_RECENT_OPEN", payload: false });
            dispatch({ type: "SET_IS_GROUP_OPEN", payload: false });
            dispatch({ type: "SET_IS_CONTACTS_OPEN", payload: true });
          }}
        />
      </Space>
    </div>
  );
};

export default MessengerMenu;
