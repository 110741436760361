import ApiMethods from "const/API2";
import { apiRoutes } from "const/apiroutes";
import { recomendedData } from "const/initialDatas";

const API = new ApiMethods();

export default class Risks {
  constructor(risk) {
    this.id = risk?.id || "";
    this.name = risk?.scenarios?.name || "";
    this.status = risk?.riskStatus?.name || "";
    this.executorFullName = `${risk?.executor?.firstName} ${risk?.executor?.lastName}`;
    this.executor = risk?.executor;
    this.taxId = risk?.taxId || "";
    this.riskType = risk?.scenarios?.riskType || "";
    this.note = risk?.note || "";
    this.precedent = risk?.scenarios?.precedent || "";
    this.riskGroup = risk?.scenarios?.riskGroup?.name || "";
    this.workFlow = risk?.scenarios?.workFlow;
    this.hasWorkFlow = Boolean(risk?.scenarios?.workFlow);
    this.curator = risk?.scenarios?.users
      ? `${risk?.scenarios?.users?.firstName} ${risk?.scenarios?.users?.lastName}`
      : "";
    this.createdAt = risk?.createdAt || "";
    var d2 = new Date(this.createdAt);
    var d3 = d2.getTime() + 1000 * 60 * 60 * 4;
    var d4 = new Date(d3);
    this.lastExecuteDate = d4;
    this.tags = risk?.tags ? risk?.tags.map((tag) => ({ id: tag, name: tag })) : [];
    this.riskStageHistory = risk?.riskStageHistory || [];
    this.riskFiles = risk?.riskFiles || [];
    this.parameters = risk?.parameters || [];
    this.riskHistory = risk?.riskHistory || [];
    this.riskComments = risk.riskComments || [];
    this.scenarios = risk?.scenarios;
    this.riskStatus = risk?.riskStatus;

    this.settings = risk?.settings;
    if (risk.settings && Object.keys(risk.settings).length === 0)
      this.settings = recomendedData;
  }

  static LIST(param, callback, errCallback) {
    API.getData(
      apiRoutes.risks.list + param,
      (data, paginationInfo) => {
        callback(data, paginationInfo);
      },
      (err) => {
        errCallback(err);
      }
    );
  }

  static ParameterList(param, callback, errCallback) {
    API.getData(
      apiRoutes.parameters.search + param,
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errCallback) errCallback(error);
      }
    );
  }

  static getRiskByID(riskID, callback) {
    API.getData(apiRoutes.risks.getByID + riskID, (response) => {
      callback(new Risks(response));
    });
  }

  static getRiskFullDataById(riskId, callback) {
    API.getData(apiRoutes.risks.getRiskFullData + riskId, (response) => {
      callback(response);
    });
  }

  static getExportFileToExcel(url, callback, errCallback) {
    API.getData(
      `${apiRoutes.risks.excelExport}${url}`,
      (response) => {
        console.log(response);
        if (callback) callback(response);
      },
      (error) => {
        if (errCallback) errCallback(error);
      }
    );
  }

  static getExportFileToPDF(url, callback, errCallback) {
    API.getData(
      `${apiRoutes.risks.export}${url}`,
      (response) => {
        console.log(response);
        if (callback) callback(response);
      },
      (error) => {
        if (errCallback) errCallback(error);
      }
    );
  }

  static Update(data, callback, errCallback) {
    if (!data) return;
    API.putData(apiRoutes.risks.assignRisk + data.id, data, callback, errCallback);
  }

  static sendRisk(data, callback, errCallback) {
    if (!data) return;
    API.putData(apiRoutes.risks.sendRisk, data, callback, errCallback);
  }

  static createComment(data, callback, errCallback) {
    if (!data) return;
    API.postData(apiRoutes.comments.post, data, callback, errCallback);
  }

  static uploadRiskFile(riskId, form, callback, errCallback, onUploadProgress) {
    if (!riskId) return;
    if (!form) return;
    const formData = new FormData(form);
    formData.delete("info.name");
    formData.delete("info.description");
    API.postData(
      `${apiRoutes.riskFiles.upload}${riskId}`,
      formData,
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errCallback) errCallback(error);
      },
      onUploadProgress
    );
  }

  static downloadRiskFile(fileId, callback, errCallback, onDownloadProgress) {
    if (!fileId) return;

    API.getData(
      `${apiRoutes.riskFiles.download}${fileId}`,
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errCallback) errCallback(error);
      },
      onDownloadProgress
    );
  }

  static downloadRiskExcelAndPDFFile(queries, callback, errCallback) {
    if (!queries) return;

    delete queries.text;
    delete queries.currentPage;
    delete queries.currentSize;
    delete queries.totalPages;
    delete queries.totalElements;
    delete queries.isLoading;

    queries.page = 0;
    queries.size = 1000;

    let url = "?";

    for (let [key, value] of Object.entries(queries)) {
      url += value !== undefined && value !== "" ? `${key}=${value}&` : "";
    }

    API.getData(
      apiRoutes.risks.list + url,
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errCallback) errCallback(error);
      }
    );
  }

  static deleteRiskFile(riskId, fileId, roleId, callback, errCallback) {
    if (!riskId) return;
    if (!fileId) return;
    if (!roleId) return;
    API.deleteData(
      apiRoutes.riskFiles.delete(riskId, fileId, roleId),
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errCallback) errCallback(error);
      }
    );
  }

  static getRiskGivenData(callback, errCallback) {
    API.getData(
      apiRoutes.risks.getRules,
      (data) => callback && callback(data),
      (error) => errCallback && errCallback(error)
    );
  }

  static getRiskDetails(key, callback, errCallback) {
    // console.log("key ==>", key);
    if (!key) return;
    API.getData(
      `${apiRoutes.risks.details}?page=0&size=1000&key=${key}`,
      (data) => callback && callback(data),
      (error) => errCallback && errCallback(error)
    );
  }

  static getRiskFiles(riskId, itemId, callback, errCallback) {
    if (!riskId) return;
    API.getData(
      apiRoutes.risks.getFiles(riskId, itemId),
      (data) => callback && callback(data),
      (error) => errCallback && errCallback(error)
    );
  }

  static postRiskApprove(data, callback, errCallback) {
    if (!data) return;
    API.postData(
      apiRoutes.risks.approve,
      data,
      (response) => callback && callback(response),
      (error) => errCallback && errCallback(error)
    );
  }
  static postRiskSendBack(data, callback, errCallback) {
    if (!data) return;
    API.postData(
      apiRoutes.risks.sendBack,
      data,
      (response) => callback && callback(response),
      (error) => errCallback && errCallback(error)
    );
  }
  static postRiskSetExecute(data, callback, errCallback) {
    if (!data) return;
    API.postData(
      apiRoutes.risks.setExecute,
      data,
      (data) => callback && callback(data),
      (error) => errCallback && errCallback(error)
    );
  }

  static postSaveRiskData(data, callback, errCallback) {
    if (!data) return;
    API.postData(
      apiRoutes.risks.save,
      data,
      (data) => callback && callback(data),
      (error) => errCallback && errCallback(error)
    );
  }
}
