import ApiMethods from "const/API2";
import { apiRoutes } from "const/apiroutes";

const API = new ApiMethods();

class CollaboratorsModel {
  static getAllCollaborators(roleId, queryString, callback, errCallback) {
    API.getData(
      apiRoutes.collaborators.getAllCollaborators({ roleId, queryString }),
      (data) => callback && callback(data),
      (error) => errCallback && errCallback(error)
    );
  }
  static getSectorSelectData(roleId, callback, errCallback) {
    API.getData(
      apiRoutes.collaborators.getSectorSelectData({ roleId }),
      (data) => callback && callback(data),
      (error) => errCallback && errCallback(error)
    );
  }
  static getGroupSelectData(roleId, sectorId, callback, errCallback) {
    API.getData(
      apiRoutes.collaborators.getGroupSelectData({ roleId, sectorId }),
      (data) => callback && callback(data),
      (error) => errCallback && errCallback(error)
    );
  }
  static getUsersSelectData(roleId, userGroupId, callback, errCallback) {
    API.getData(
      apiRoutes.collaborators.getUsersSelectData({ roleId, userGroupId }),
      (data) => callback && callback(data),
      (error) => errCallback && errCallback(error)
    );
  }
}

export default CollaboratorsModel;
