import ApiMethods from "const/API2";
import { apiRoutes } from "const/apiroutes";

const API = new ApiMethods();

class Profile {
  static getProfileData(fields, callback, errCallback) {
    if (!fields) return;
    API.getData(
      `${apiRoutes.profile.get}${Profile.createUrl(fields)}`,
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errCallback) errCallback(error);
      }
    );
  }

  static createUrl(fields) {
    let url = "?";
    for (let [key, value] of Object.entries(fields)) {
      url += value ? `${key}=${value}&` : "";
    }
    return url;
  }
}

export default Profile;
