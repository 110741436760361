import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import TableToolbar from "./TableToolbar";
import DynamicTable from "./DynamicTable";
import GL from "../../../libs/GL";
import TableSettings from "./TableSettings";
import AsyncTable from "./AsyncTabulator";

function SuperTable(props) {
  const history = useHistory();
  var data = props.data;
  var columns = data.columns ?? [];
  var filtering = data.filtering;
  var isPagination = data.settings["pagination"] ?? false;
  var tableSettings = data.settings;
  var table = null;

  const [buttonPressed, setButtonPressed] = React.useState({
    type: "",
    data: {},
  });

  const { isAsync, onPageSettingsChange, table: asyncTable } = tableSettings;

  const setTable = (data) => {
    table = data;
  };

  const openTableSettings = () => {
    table = table ? table : asyncTable;
    if (table !== null) {
      let tabele_id = table.GL.settings.id;
      var localData = localStorage.getItem("tables");
      if (localData == null) {
        localData = "{}";
      }
      localData = JSON.parse(localData);
      var t = localData[`table-${tabele_id}`];
      if (t !== undefined) {
        var filteredColumns = t.columns.filter(
          (a) => ["operations", "id"].indexOf(a.field) == -1
        );
        var columnSort = [];
        var lenn = t.columns.length - 2;
        if (t.columnSort.length == lenn) {
          columnSort = t.columnSort;
        } else {
          filteredColumns.map((a) => {
            columnSort.push(a.field);
          });
        }
        var tableData = {
          id: tabele_id,
          columns: filteredColumns,
          secretColumns: t.secretColumns,
          columnSort: columnSort,
        };
        GL.openTableSettings(
          <TableSettings settings={tableData} />,
          (newSettings) => {
            if (newSettings !== false) {
              var newSet = localStorage.getItem("shortTableSettings");
              if (newSet !== null) {
                tableData = JSON.parse(newSet);
                table.GL.settings.columnSort = tableData.columnSort;
                table.GL.settings.secretColumns = tableData.secretColumns;
                table.GL.localStorageUpdate();
                localStorage.removeItem("shortTableSettings");
                tableData.columns.map((f) => {
                  table.showColumn(f.field);
                });
                tableData.secretColumns.map((f) => {
                  table.hideColumn(f);
                });
                table.GL.updateColumns();
              }
            } else {
              localStorage.removeItem("shortTableSettings");
            }
          }
        );
      } else {
        table.GL.localStorageUpdate();
        openTableSettings();
      }
    }
  };

  tableSettings.setTable = setTable;

  var toolbar = {
    doSearch(search) {
      if (table && !isAsync) {
        table.globalSearch(search);
      } else if (onPageSettingsChange) {
        onPageSettingsChange("text", search);
      }
    },
    exportTable(e) {
      setButtonPressed({
        type: "export",
        data: {
          fileName: tableSettings.id,
        },
      });
    },
    printTable(e) {
      setButtonPressed({
        type: "print",
        data: {
          fileName: tableSettings.id,
        },
      });
    },

    hideColumn() {
      openTableSettings();
    },
    createNew(e) {
      //debugger;
      history.push({
        pathname: data.settings.createNewRoute,
        state: data.settings.state,
      });
    },
    createIsDisabled: data.settings.createIsDisabled,
    buttonText: data.settings.buttonText,
    status:
      data.settings.toolbarStatus == undefined
        ? true
        : data.settings.toolbarStatus,
    // ! settings for continue editing button -------------------------------------------------------------------
    continueEditing() {
      history.push({
        pathname: data.settings.createNewRoute,
        state: data.settings.editState,
      });
    },
    editButtonText: data.settings.editButtonText,
    editDisabled: data.settings.editDisabled ?? true,
    // ! end settings for continue editing button -------------------------------------------------------------------
    // ! settings for risk table configs -------------------------------------------------------------------------
    scenarioConfigs: data.settings.scenarioConfigs ?? false,
    scenarioConfigRoute: data.settings.scenarioConfigRoute ?? "",
    exportIsDisabled: data.settings.exportIsDisabled,
    printIsDisabled: data.settings.printIsDisabled,
    searchIsDisabled: data.settings.searchIsDisabled,
    settingsIsDisabled: data.settings.settingsIsDisabled,
    configScenario() {
      history.push(this.scenarioConfigRoute);
    },
    // ! end settings for risk table configs ---------------------------------------------------------------------

    // ! settings for showCalendarBtn button -------------------------------------------------------------------
    showCalendarBtn: data.settings.showCalendarBtn ?? false,
  };

  var settings = {
    table: null,
    toolbar: toolbar,
  };

  return (
    <>
      {settings.toolbar.status === true ? (
        <TableToolbar
          settings={settings.toolbar}
          tableSettings={tableSettings}
        />
      ) : null}

      {tableSettings.isAsync ? (
        <AsyncTable
          buttonPressed={buttonPressed}
          columns={columns}
          filtering={filtering}
          settings={tableSettings}
          pageSettings={props.pageSettings}
        />
      ) : (
        <DynamicTable
          buttonPressed={buttonPressed}
          columns={columns}
          filtering={filtering}
          settings={tableSettings}
        />
      )}
    </>
  );
}

export default SuperTable;
