// import ReactDomServer from "react-dom/server";
import "../../../../RiskPageStyles.scss";

import React, { useState } from "react";
import { Row, Col, Select } from "antd";

import FormSubmit from "components/Elements/FormSubmit/FormSubmit2";
import JsonViewer from "components/Elements/JsonViewer/JsonViewer";
import { useTranslation } from "react-i18next";
import DataParser from "../DataParser";
import FormulBuilder from "components/Elements/Formul/FormulBuilder";

function RestApi(props) {
  const { t } = useTranslation();
  const { formik, resetFormik, submitForm, inputHasError } = props;

  const [showHeaderModal, setShowHeaderModal] = useState(false);
  const [showBodyModal, setShowBodyModal] = useState(false);

  return (
    <div className="risk-page">
      <h1 className="risk-page__title">
        {`${t("stages_add_stage")} - ${t("stages_restapi")}`}
      </h1>

      <div className="risk-page__elements">
        <Row gutter={24}>
          <Col span={24}>
            <div className="input-box">
              <span className="input-box__title">{t("global_method")}</span>
              <Select
                options={[
                  { value: "get", label: "GET" },
                  { value: "post", label: "POST" },
                ]}
                value={formik.values.stages.addStagesForm.restApi.method}
                onChange={(value) =>
                  formik.setFieldValue(
                    "stages.addStagesForm.restApi.method",
                    value
                  )
                }
                onBlur={formik.handleBlur(
                  "stages.addStagesForm.restApi.method"
                )}
                status={
                  inputHasError("stages.addStagesForm.restApi.method")
                    ? "error"
                    : ""
                }
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.restApi.method")
                  ? formik.errors.stages.addStagesForm.restApi.method
                  : ""}
              </div>
            </div>

            <div className="input-box">
              <span className="input-box__title">{t("global_endpoint")}</span>
              <input
                type="text"
                className={
                  inputHasError("stages.addStagesForm.restApi.endPoint")
                    ? "error"
                    : ""
                }
                {...formik.getFieldProps(
                  "stages.addStagesForm.restApi.endPoint"
                )}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.restApi.endPoint")
                  ? formik.errors.stages.addStagesForm.restApi.endPoint
                  : ""}
              </div>
            </div>
          </Col>
          <Col sm={24} md={8} lg={8}>
            <div className="input-box height-unset">
              <span className="input-box__title-with-btn">
                <span>{t("stages_headers")}</span>
                <button onClick={() => setShowHeaderModal(true)}>
                  {t("global_add_edit")}
                </button>
              </span>
              <JsonViewer
                formik={formik}
                src={formik.values.stages.addStagesForm.restApi.headers}
                formikPath="stages.addStagesForm.restApi.headers"
                isError={inputHasError("stages.addStagesForm.restApi.headers")}
                open={showHeaderModal}
                setOpen={setShowHeaderModal}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.restApi.headers")
                  ? "Headers json boş qala bilməz və `null` dəyəri ala bilməz"
                  : ""}
              </div>
            </div>
          </Col>
          <Col sm={24} md={8} lg={8}>
            <div className="input-box height-unset">
              <span className="input-box__title-with-btn">
                <span>{t("stages_body")}</span>
                <button onClick={() => setShowBodyModal(true)}>
                  {t("global_add_edit")}
                </button>
              </span>
              <JsonViewer
                formik={formik}
                src={formik.values.stages.addStagesForm.restApi.body}
                formikPath="stages.addStagesForm.restApi.body"
                isError={inputHasError("stages.addStagesForm.restApi.body")}
                open={showBodyModal}
                setOpen={setShowBodyModal}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.restApi.body")
                  ? "Body json boş qala bilməz və `null` dəyəri ala bilməz"
                  : ""}
              </div>
            </div>
          </Col>
          <Col sm={24} md={8} lg={8}>
            <DataParser
              stageFormik={formik}
              formikPath="stages.addStagesForm.restApi.parser"
            />
            <div className="input-box__error-field">
              {inputHasError("stages.addStagesForm.restApi.parser")
                ? formik.errors.stages.addStagesForm.restApi.parser
                : ""}
            </div>
          </Col>
          <Col span={24}>
            <h2 className="risk-page__title">{t("global_result")}</h2>
            <FormulBuilder
              formik={formik}
              formikPath="stages.addStagesForm.restApi"
            />
            <div className="input-box__error-field">
              {inputHasError("stages.addStagesForm.restApi.resultValid")
                ? formik.errors.stages.addStagesForm.restApi.resultValid
                : ""}
            </div>
          </Col>
        </Row>
      </div>

      <div className="risk-page__submittion">
        <FormSubmit cancel={resetFormik} submit={submitForm} />
      </div>
    </div>
  );
}

export default RestApi;
