import React from "react";
import "./FooterTools.scss";
import { Button } from "antd";

const FooterTools = (props) => {
  const iconButtons = props?.iconButtons;
  const extraButtons = props?.extraButtons;
  const buttons = props?.buttons;

  return (
    <div className="FooterTools">
      <div className="FooterToolsBody">
        <div className="FooterToolsLeft">
          {iconButtons?.map((props, index) => {
            const { isActive, ...button } = props;
            const hasActive = isActive ?? true;
            if (!hasActive) return "";
            if (hasActive) return <Button {...button} key={index} />;
          })}
        </div>

        <div className="FooterToolsRight">
          {extraButtons && (
            <React.Fragment>
              <div className="FooterToolsExtra">
                {extraButtons?.map((props, index) => {
                  const { isActive, ...button } = props;
                  const hasActive = isActive ?? true;
                  if (!hasActive) return "";
                  if (hasActive) return <Button {...button} key={index} />;
                })}
              </div>
              <div className="FooterToolsLine" />
            </React.Fragment>
          )}

          <div className="FooterToolsButtons">
            {buttons?.map((props, index) => {
              const { isActive, ...button } = props;
              const hasActive = isActive ?? true;
              if (!hasActive) return "";
              if (hasActive) return <Button {...button} key={index} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterTools;
