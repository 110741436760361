import Department from "./Department";
import User from "./User";
import API from "../const/API2";
import { apiRoutes } from "../const/apiroutes";
var api = new API();

class Sector {
  constructor(obj) {
    if (obj === undefined) {
      obj = {};
    }
    this.id = obj["id"] === undefined ? null : obj["id"];
    this.name = obj["name"] === undefined ? null : obj["name"];
    this.totalName = obj["totalName"] === undefined ? null : obj["totalName"];
    this.isActive = obj["isActive"] === undefined ? null : obj["isActive"];
    if (obj["managerId"] !== null) {
      this.manager = new User(obj["managerId"]);
    } else {
      this.manager = null;
    }
    if (obj["departmentsDto"] !== null) {
      this.department = new Department(obj["departmentsDto"]);
    } else {
      this.department = null;
    }

    // if(obj['companiesDto']!==null){
    //   this.company = new Company(obj['companiesDto']);
    // }else{
    //   this.company = null;
    // }
  }

  static getEmptyState() {
    return {
      id: 0,
      name: "",
      totalName: "",
      company: "",
      dapartment: "",
      manager: "",
      isActive: true,
      method: "create",
    };
  }

  getTableItem() {
    var a = this;
    return {
      id: a.id,
      name: a.name,
      totalName: a.totalName,
      manager: a.manager !== null ? a.manager.name : "",
      managerId: a.manager !== null ? a.manager.id : "",
      companyId: a.department !== null ? a.department.company.id : null,
      company: a.department !== null ? a.department.company.name : null,
      department: a.department !== null ? a.department.name : "",
      departmentId: a.department !== null ? a.department.id : "",
      isActive: a.isActive,
    };
  }

  static getSectorsByDepartmentID(id, callback) {
    api.getData(
      `${apiRoutes.sectors.search}?departmentId=${id}`,
      (response) => {
        const convertFromArray = Sector.getSectorsFromArray(response);
        callback(convertFromArray);
      }
    );
  }

  static getForTableData(data) {
    return data.map((a) => {
      return a.getTableItem();
    });
  }

  static getSectorsFromArray(data) {
    return data.map((a) => {
      return new Sector(a);
    });
  }

  static getSectorList(callback, errCallback) {
    api.getData(
      apiRoutes.sectors.list,
      (data) => {
        var arr = Sector.getSectorsFromArray(data);
        callback(arr);
      },
      errCallback
    );
  }

  static DELETE(id, callback) {
    if (id === 0 || id === "" || id === null || id === undefined) {
      return null;
    }
    id = Number(id);
    api.deleteData(
      `${apiRoutes.sectors.put}/${id}`,
      (data) => {
        callback(true, data);
      },
      (err) => {
        callback(false, err);
      }
    );
  }

  static UPDATE(data, callback, errCallback) {
    if (data === false) {
      return null;
    }
    var id = data["id"];
    if (data.manager !== null) {
      data.managerId = {
        id: data.manager,
      };
    } else {
      delete data.manager;
    }

    if (data.company !== null) {
      data.companiesDto = {
        id: data.company,
      };
    } else {
      delete data.company;
    }

    if (data.department !== null) {
      data.departmentsDto = {
        id: data.department,
      };
    } else {
      delete data.department;
    }

    if (id !== 0 && id !== "" && id !== null && id !== undefined) {
      api.putData(
        apiRoutes.sectors.put + "/" + id,
        data,
        (res) => {
          if (callback !== undefined) {
            callback(res);
          }
        },
        errCallback
      );
    }
  }

  static CREATE(data, callback, errCallback) {
    if (data === false) {
      return null;
    }
    if (data["id"] !== undefined) {
      delete data["id"];
    }

    if (data.manager !== null) {
      data.managerId = {
        id: data.manager,
      };
    } else {
      delete data.manager;
    }

    if (data.company !== null) {
      data.companiesDto = {
        id: data.company,
      };
    } else {
      delete data.company;
    }

    if (data.department !== null) {
      data.departmentsDto = {
        id: data.department,
      };
    } else {
      delete data.department;
    }
    api.postData(
      apiRoutes.sectors.add,
      data,
      (res) => {
        //debugger;
        if (callback !== undefined) {
          callback(res);
        }
      },
      errCallback
    );
  }
}

export default Sector;
