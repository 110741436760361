import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Select } from "antd";

import API from "const/API2";
import { apiRoutes } from "const/apiroutes";
import FormErrorHandler from "./FormErrorHandler";

var api = new API();

function UsersSelect(props) {
  // ! props fallbacks ------------------------------------
  const selectFallbacks = {
    formik: props.formik,
    field: props.field,
    onSelect: props.onSelect ?? undefined,
    label: props.label ?? "",
    required: props.required ?? false,
    disabled: props.disabled ?? false,
    placeholder: props.placeholder ?? "",
    options: props.options ?? [],
  };
  const { disabled, field, formik, label, onSelect, placeholder, required } =
    selectFallbacks;
  // ! props fallbacks ------------------------------------

  const currentUserMail = useSelector((state) => state.user.data.user.email);
  const [options, setOptions] = useState([]);

  const filterOptions = (input, option) =>
    (option?.label ?? "").includes(input);

  const handleChangeUserSelect = (val) => {
    formik?.setFieldValue(`info.${field}`, val);
    onSelect && onSelect(val);
  };

  useEffect(() => {
    function getUsersSelectData() {
      api.getData(
        apiRoutes.users.getAll,
        (data) => {
          const selectData = data
            .filter((user) => user.email !== currentUserMail)
            .map((user) => {
              return { label: user.email, value: `${user.id}` };
            });
          setOptions(selectData);
        },
        (err) => {
          console.log("get users err ===>", err);
        }
      );
    }
    getUsersSelectData();
  }, []);

  return (
    <div className="input-box">
      <span className="input-box__title">
        {label}{" "}
        {required === true ? <span style={{ color: "red" }}>*</span> : null}
      </span>
      <Select
        mode="multiple"
        placeholder="Please select"
        style={{ width: "100%" }}
        options={options}
        filterOption={filterOptions}
        value={formik?.values?.info?.[field]}
        disabled={disabled}
        onChange={handleChangeUserSelect}
      />
      <FormErrorHandler formik={formik} field={`info.${field}`} />
    </div>
  );
}

export default UsersSelect;
