import React from "react";
import { Typography, Form, Input, Space } from "antd";
import { useMailContext } from "./MailContextAPI";

const { Title } = Typography;
const { TextArea } = Input;
import "./Mail.css";
import SelectUserSearch from "./SelectUserSearch";
const MailForward = () => {
  const { state, dispatch } = useMailContext();
  return (
    <Space direction="vertical" size="middle">
      <Space direction="vertical">
        <Title style={{ fontSize: "16px" }}>
          {" "}<svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
          >
            <path
              d="M7.75065 6.16667L10.084 3.83333M10.084 3.83333L7.75065 1.5M10.084 3.83333H3.66732C3.04848 3.83333 2.45499 4.07917 2.0174 4.51675C1.57982 4.95434 1.33398 5.54783 1.33398 6.16667C1.33398 6.7855 1.57982 7.379 2.0174 7.81658C2.45499 8.25417 3.04848 8.5 3.66732 8.5H4.25065"
              stroke="black"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>{" "}
          Kimə
        </Title>
        <SelectUserSearch />
      </Space>
      <Space direction="vertical">
        <Title style={{ fontSize: "14px", marginTop: "10px" }}>Mətn</Title>

        <Form.Item>
          <TextArea
            autoSize={{
              minRows: 5,
              maxRows: 5
            }}
            style={{
              borderRadius: "6px",
              border: "1px solid #EBEDF0",
              boxShadow: "0px 16px 40px -12px rgba(171, 186, 201, 0.20)"
            }}
            onChange={e => {
              dispatch({ type: "SET_FORWARD_TEXT", payload: e.target.value });
            }}
          />
        </Form.Item>
      </Space>
    </Space>
  );
};

export default MailForward;
