import { SET_USERS_INFO, SET_USER_GROUP_INFO } from "../types";

export const setUsersInfo = (values) => {
  return {
    type: SET_USERS_INFO,
    payload: values,
  };
};

export const setUserGroupInfo = (values) => {
  return {
    type: SET_USER_GROUP_INFO,
    payload: values,
  };
};
