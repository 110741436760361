import "../RiskPageStyles.scss";

import React, { useEffect, useState } from "react";
import { Divider, Row, Col, Select } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import PageTitle from "../../../Elements/PageTitle/PageTitle";
import FormSubmit from "../../../Elements/FormSubmit/FormSubmit2";
import API from "../../../../const/API2";
import { apiRoutes } from "../../../../const/apiroutes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import GL from "libs/GL";

function ScenarioConfig() {
  const { t } = useTranslation();
  const api = new API();
  const history = useHistory();

  const [scenarios, setScenarios] = useState([]);
  const [users, setUsers] = useState([]);

  const getScenarios = () => {
    const createValidArr = (data) => {
      return data.map((element) => {
        return {
          scenario: {
            value: element.id,
            label: element.name,
          },
          kurator: element.usersDto
            ? {
                value: element.usersDto.id,
                label:
                  element.usersDto.firstName + " " + element.usersDto.lastName,
              }
            : {
                value: "",
                label: "",
              },
        };
      });
    };
    api.getData(
      apiRoutes.scenarios.list,
      (data) => {
        const validArr = createValidArr(data);
        setScenarios(validArr);
      },
      (err) => {
        console.log("err=>", err);
      }
    );
  };

  const getKurators = () => {
    const createValidArr = (data) => {
      return data.map((element) => {
        return {
          value: element.id,
          label: element.firstName + " " + element.lastName,
        };
      });
    };
    api.getData(
      apiRoutes.users.getAll,
      (data) => {
        const validArr = createValidArr(data);
        setUsers(validArr);
      },
      (err) => {
        console.log("err=>", err);
      }
    );
  };

  const handleChange = (scenarioId, curatorId) => {
    const currScenarios = JSON.parse(JSON.stringify(scenarios));
    const editedScenarioIdx = currScenarios.findIndex(
      (el) => el.scenario.value === scenarioId
    );
    currScenarios[editedScenarioIdx].kurator.value = curatorId;
    setScenarios(currScenarios);
  };

  const onCancel = () => {
    history.replace("/");
  };

  const onSubmit = () => {
    const data = scenarios.map((el) => {
      return {
        scenariosId: el.scenario.value,
        userId: el.kurator.value || null,
      };
    });

    api.putData(
      `${apiRoutes.scenarios.config}`,
      data,
      () => {
        GL.notify.success({
          title: t("answer_success"),
          text: t("scenario_success_update"),
        });
        history.push("/");
      },
      () => {
        getScenarios();
        getKurators();
      }
    );
  };

  useEffect(() => {
    getScenarios();
    getKurators();
  }, []);

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={t("scenarios")}
        small={t("scenario_desc")}
        // isDeactive={formik.values.info.status == "deactive"}
      />
      <Divider />
      <div
        className="risk-page"
        //   onSubmit={formik.handleSubmit}
      >
        <div className="risk-page__elements">
          <Row gutter={24}>
            <Col span={11}>
              <span className="input-box__title">{t("scenario")}</span>
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
              <span className="input-box__title">{t("user")}</span>
            </Col>
          </Row>

          {scenarios.map((row) => {
            return (
              <Row gutter={24} key={row.scenario.value}>
                <Col span={11}>
                  <div className="input-box">
                    <Select
                      defaultValue={row.scenario.value}
                      disabled
                      options={[row.scenario]}
                    />
                  </div>
                </Col>
                <Col
                  span={2}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    marginTop: "13px",
                  }}
                >
                  <ArrowRightOutlined />
                </Col>
                <Col span={11}>
                  <div className="input-box">
                    <Select
                      defaultValue={row.kurator.value}
                      options={users}
                      value={row.kurator.value}
                      onChange={(kuratorId) =>
                        handleChange(row.scenario.value, kuratorId)
                      }
                      // value={handleChange}

                      //   value={formik.values.info.info.status}
                      //   onChange={(value) =>
                      //     formik.setFieldValue("info.info.status", value)
                      //   }
                      //   onBlur={formik.handleBlur("info.info.status")}
                      //   status={inputHasError("info.info.status") ? "error" : ""}
                    />
                    <div className="input-box__error-field">
                      {/* {inputHasError("info.info.name")
                ? formik.errors.info.info.name
                : ""} */}
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </div>
        <div className="risk-page__submittion">
          <FormSubmit cancel={onCancel} submit={onSubmit} />
        </div>
      </div>
    </div>
  );
}

export default ScenarioConfig;
