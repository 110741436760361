import API from "const/API2";
import { apiRoutes } from "const/apiroutes";

var api = new API();

export class MailModel {
  static async getInbox(currentPage, pageSize, callback, errCallback) {
    api.getData(
      `${apiRoutes.mail.getInbox}?page=${currentPage - 1}&size=${pageSize}`,
      (data, pagination) => {
        if (callback) {
          callback(data, pagination);
        }
      },
      err => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async getSend(currentPage, pageSize, callback, errCallback) {
    api.getData(
      `${apiRoutes.mail.getSend}?page=${currentPage - 1}&size=${pageSize}`,
      (data, pagination) => {
        if (callback) {
          callback(data, pagination);
        }
      },
      err => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async getUsers(callback, errCallback) {
    api.getData(
      `${apiRoutes.mail.getUsers}`,
      data => {
        if (callback) {
          callback(data);
        }
      },
      err => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async getMailDescription(recordKey, callback, errCallback) {
    api.getData(
      `${apiRoutes.mail.getMailDescription}/${recordKey}`,
      data => {
        if (callback) {
          callback(data);
        }
      },
      err => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async getMailDescriptionWithParentId(
    recordKey,
    parentId,
    callback,
    errCallback
  ) {
    api.getData(
      `${apiRoutes.mail.getMailDescription}/${recordKey}?parentId=${parentId}`,
      data => {
        if (callback) {
          callback(data);
        }
      },
      err => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async postReplyMail(requestBody, callback, errCallback) {
    api.postData(
      apiRoutes.mail.postReplyMail,
      requestBody,
      data => {
        if (callback) {
          callback(data);
        }
      },
      err => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async postForwardMail(requestBody, callback, errCallback) {
    api.postData(
      apiRoutes.mail.postReplyMail,
      requestBody,
      data => {
        if (callback) {
          callback(data);
        }
      },
      err => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async deleteMailWithType(
    selectedMailsForDelete,
    curTab,
    callback,
    errCallback
  ) {
    api.deleteData(
      `${apiRoutes.mail
        .deleteMailWithType}/${curTab}/delete?mailIds=${selectedMailsForDelete}`,
      data => {
        if (callback) {
          callback(data);
        }
      },
      err => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async deleteSingleMail(recordKey, callback, errCallback) {
    api.deleteData(
      `${apiRoutes.mail.deleteMailWithType}/delete?mailIds=${recordKey}`,
      data => {
        if (callback) {
          callback(data);
        }
      },
      err => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async sendNewMail(bodyData, callback, errCallback) {
    api.postData(
      apiRoutes.mail.sendNewMail,
      bodyData,
      data => {
        if (callback) {
          callback(data);
        }
      },
      err => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async uploadFile(formData, callback, errCallback, onUploadProgress) {
    api.postData(
      apiRoutes.mail.uploadFile,
      formData,
      data => {
        if (callback) {
          callback(data);
        }
      },
      err => {
        if (errCallback) {
          errCallback(err);
        }
      },
      onUploadProgress
    );
  }

  static async removeFile(fileId, callback, errCallback) {
    api.deleteData(
      `${apiRoutes.mail.removeFile}/${fileId}`,
      data => {
        if (callback) {
          callback(data);
        }
      },
      err => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async getMailForSearch(query, callback, errCallback) {
    api.getData(
      `${apiRoutes.mail.getMailSearch}?key=${query}`,
      data => {
        if (callback) {
          callback(data);
        }
      },
      err => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }
}
