import API from "../const/API2";
import { apiRoutes } from "../const/apiroutes";
import GL from "../libs/GL";
import i18n from "i18n";

const { t } = i18n;
const api = new API();

class Roles {
  static createPostData(formValues) {
    return formValues;
  }

  static getValidArrForPerm(rawValues) {
    //  !================================================================
    //  !================================================================

    // const rawValues = {
    //   path: "/",
    //   id: "",
    //   code: "rol_adi",
    //   name: "1234",
    //   status: true,
    //   rolePermRelDtos: [
    //     {
    //       code: "main-menu",
    //       name: "Sol Taraf Ana Menuler",
    //       id: 1,
    //       permissions: [
    //         {
    //           code: "deleted",
    //           name: "Show Deleted Rows",
    //           id: 1,
    //           status: true,
    //         },
    //         {
    //           code: "menu-scenario",
    //           name: "Ssenari idarəetmə",
    //           id: 2,
    //           status: false,
    //         },
    //       ],
    //     },
    //   ],
    // };

    // const targetData = {
    //   default_path: "/",
    //   name: "rol adi",
    //   code: "rol_adi",
    //   status: true,
    //   permissions: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    // };

    const { path, rolePermRelDtos, ...rest } = rawValues;

    const permissions = [];
    rolePermRelDtos.forEach((role) => {
      role.permissions.forEach((perm) => {
        if (perm.status === true) permissions.push(perm.id);
      });
    });

    return { ...rest, default_path: path, permissions };
    //  !================================================================
    //  !================================================================
  }

  static getValidArrFromPagePerms = (data) => {
    const perData = {};
    data.forEach((perm) => {
      const relId = perm.id;
      const pageId = perm.pagesDto.id;
      const rolePermDtosId = null;

      if (perData[pageId]) {
        const pagePermsArr = perData[pageId].permissions;
        pagePermsArr.push({
          ...perm.permissionsDto,
          relId,
          rolePermDtosId,
          isSelected: false,
        });
      } else {
        perData[pageId] = {
          ...perm.pagesDto,
          permissions: [
            {
              ...perm.permissionsDto,
              relId,
              rolePermDtosId,
              isSelected: false,
            },
          ],
        };
      }
    });
    const pagePerms = [];
    Object.keys(perData).forEach((data) => pagePerms.push(perData[data]));
    return pagePerms;
  };

  static getValidArrFromPagePerms = (data) => {
    const perData = {};
    data.forEach((perm) => {
      const relId = perm.id;
      const pageId = perm.pagesDto.id;
      const rolePermDtosId = null;

      if (perData[pageId]) {
        const pagePermsArr = perData[pageId].permissions;
        pagePermsArr.push({
          ...perm.permissionsDto,
          relId,
          rolePermDtosId,
          isSelected: false,
        });
      } else {
        perData[pageId] = {
          ...perm.pagesDto,
          permissions: [
            {
              ...perm.permissionsDto,
              relId,
              rolePermDtosId,
              isSelected: false,
            },
          ],
        };
      }
    });
    const pagePerms = [];
    Object.keys(perData).forEach((data) => pagePerms.push(perData[data]));
    return pagePerms;
  };

  static getValidArrAfterGetById = (data) => {
    const permPages = {};
    data.rolePermRelDtos.forEach((permRel) => {
      const rolePermDtosId = permRel.id;
      const isSelected = permRel.status;
      const pageId = permRel.pagesPermission.pagesDto.id;
      const relId = permRel.pagesPermission.id;

      if (permPages[pageId]) {
        permPages[pageId].permissions.push({
          ...permRel.pagesPermission.permissionsDto,
          relId,
          rolePermDtosId,
          isSelected,
        });
      } else {
        permPages[pageId] = {
          ...permRel.pagesPermission.pagesDto,
          permissions: [
            {
              ...permRel.pagesPermission.permissionsDto,
              relId,
              rolePermDtosId,
              isSelected,
            },
          ],
        };
      }
    });

    const pagePermsArr = [];
    Object.keys(permPages).forEach((data) =>
      pagePermsArr.push(permPages[data])
    );

    return {
      id: data.id,
      label: data.label,
      name: data.name,
      status: data.status,
      rolePermRelDtos: pagePermsArr,
    };
  };

  static async getRolesList(callback, errCallback) {
    api.getData(
      apiRoutes.roles.list,
      (data) => {
        callback(data);
      },
      (err) => {
        if (!errCallback) return;
        errCallback(err);
      }
    );
  }

  static async getRole(id, callback, errCallback) {
    api.getData(
      `${apiRoutes.roles.get}/${id}`,
      (data) => {
        callback(data);
      },
      (err) => {
        if (!errCallback) return;
        errCallback(err);
      }
    );
  }

  static async CREATE(postData, callback, errCallback) {
    const submitData = this.getValidArrForPerm(postData);
    api.postData(
      apiRoutes.roles.add,
      submitData,
      (data) => {
        GL.notify.success({
          title: t("answer_success"),
          text: "Rol yaradıldı",
        });
        callback(data);
      },
      (err) => {
        GL.notify.error({
          title: t("answer_unsuccess"),
          text: "Rol yaradıla bilmədi",
        });
        if (!errCallback) return;
        errCallback(err);
      }
    );
  }

  static async EDIT(id, body, callback, errCallback) {
    const putData = this.getValidArrForPerm(body);
    api.putData(
      `${apiRoutes.roles.put}/${id}`,
      putData,
      (data) => {
        GL.notify.success({
          title: t("answer_success"),
          text: "Rol dəyişdirildi",
        });
        callback(data);
      },
      (err) => {
        GL.notify.error({
          title: t("answer_unsuccess"),
          text: "Rol dəyişdirilə bilmədi",
        });
        if (!errCallback) return;
        errCallback(err);
      }
    );
  }

  static async DELETE(id, callback, errCallback) {
    api.deleteData(
      `${apiRoutes.roles.put}/${id}`,
      (data) => {
        callback(data);
      },
      (err) => {
        if (!errCallback) return;
        errCallback(err);
      }
    );
  }

  static async getPagePermissions(callback, errCallback) {
    api.getData(
      apiRoutes.pages.perms,
      (data) => {
        // GL.notify.success({
        //   title: t('answer_success'),
        //   text: "Rol yaradıldı",
        // });
        callback(data);
      },
      (err) => {
        GL.notify.error({
          title: t("answer_unsuccess"),
          text: "Səhifə rolları tapılmadı",
        });
        if (!errCallback) return;
        errCallback(err);
      }
    );
  }
}

export default Roles;
