import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Skeleton } from "antd";

import { GetPagePermission, GetTableSettings } from "hooks/permissions";
import GL from "libs/GL";
import SuperTable from "components/Elements/Tabulator/SuperTable";
import Sublist from "models/Sublist";
import { useTranslation } from "react-i18next";

const pageId = "List";

const ListItem = () => {
  const { t } = useTranslation();
  const { sublistId } = useParams();
  const history = useHistory();
  const permissions = GetPagePermission(21);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );

  const [isLoading, setIsLoading] = useState(false);

  const getListItemByID = (id) => {
    setIsLoading(true);
    Sublist.getSublistByMyListID(id, (response) => {
      const {
        data,
        id,
        name,
        description,
        settings: { fields },
      } = response;
      const item = {
        id,
        name,
        description,
      };

      const filters = {};
      let columns = [];

      for (let field of fields) {
        filters[field.field] = {
          dataType: field.type,
          placeholder: t("global_search"),
        };
        columns.push({ field: field.field, title: field.name });
      }

      const settings = { ...initialOptions.settings, data };
      const filtering = { ...initialOptions.filtering, filters };
      columns = [...columns, ...initialOptions.columns];

      setListItem(item);
      setOptions({ ...initialOptions, settings, columns, filtering });
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getListItemByID(sublistId);
  }, [sublistId]);

  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {
        history.push(`/sublist/create/${sublistId}/${data.id}`);
      },
    },
    delete: {
      status: permissions["delete"],
      click: function (data) {
        GL.yesNo(t("question_deleting"), (isSuccess) => {
          if (isSuccess) {
            Sublist.Delete(
              sublistId,
              data.id,
              () => {
                GL.notify.success({
                  title: t("answer_success"),
                  text: "Alt siyahı silindi",
                });
                getListItemByID(sublistId);
              },
              () => {
                GL.notify.error({
                  title: t("answer_error"),
                  text: "Alt siyahı silinmədi",
                });
              }
            );
          }
        });
      },
    },
    show: {
      status: false,
    },
    copy: {
      status: false,
    },
  };

  const initialOptions = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/sublist/create/" + sublistId,
      },
    },
    settings: {
      id: pageId,
      type: "data",
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "+ " + t("global_create", { name: t("sublist") }),
      createNewRoute: "/sublist/create/" + sublistId,
      state: {
        sublistId,
        method: "create",
      },
    },
    columns: [
      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {},
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {},
    },
  };

  const [options, setOptions] = useState(initialOptions);

  const [listItem, setListItem] = useState({
    id: "",
    name: "",
    description: "",
  });

  if (isLoading) return <Skeleton active />;

  return (
    <div style={containerStyle}>
      <div className="main-layout-wrapper__head">
        <div className="simple-head">
          <h1>{listItem.name}</h1>
          <span>{listItem.description}</span>
        </div>
      </div>
      <SuperTable data={options} />
    </div>
  );
};

export default ListItem;
