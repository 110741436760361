import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col, Input } from "antd";
import PageTitle from "../../Elements/PageTitle/PageTitle";
import FormSubmit from "../../Elements/FormSubmit/FormSubmit";
import { useHistory } from "react-router-dom";
import GL from "../../../libs/GL";
import Activity from "../../../models/Activity";
import InputBox from "../../Elements/FileUploadForm/Form/InputBox";
import SelectBox from "../../Elements/FileUploadForm/Form/SelectBox";
import { useTranslation } from "react-i18next";

function ActivitiesEdit(props) {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const history = useHistory();
  const [activityList, setActivityList] = useState([]);
  var state = props.location.state;

  const initialValues = {
    info: state
  };

  const getActivityList = () => {
    Activity.getActivityList(
      (sts, data) => {
        if (sts) {
          //data.reverse();
          setActivityList(data);
        }
      },
      err => {}
    );
  };

  useEffect(() => {
    getActivityList();
  }, []);

  var submitSettings = {
    type: state.method,
    save: async () => {
      if (state.id === 0) {
        var sendData = await GL.getFormikData(formik, [
          "name",
          "numberInList",
          "isProductiveActivity",
          "parentId",
          "explanation"
        ]);
        Activity.CREATE(sendData, res => {
          GL.notify.success({ text: t("answer_success") });
          history.push("/activities");
        });
      }
    },

    edit: async () => {
      if (state.id !== 0) {
        var sendData = await GL.getFormikData(formik, [
          "id",
          "name",
          "numberInList",
          "isProductiveActivity",
          "parentId",
          "explanation"
        ]);
        Activity.UPDATE(sendData, res => {
          GL.notify.success({ text: t("answer_success") });
          history.push("/activities");
        });
      }
    },
    cancel: () => {
      // formRef.current.resetFields();
      history.push("/activities");
    }
  };

  const validation = Yup.object().shape({
    info: Yup.object().shape({
      id: Yup.number(t("yup_id")),
      name: Yup.string().required(t("yup_name")),
      numberInList: Yup.number().required(t("activities_yup_numberinlist")),
      explanation: Yup.string().required(),
      parentId: Yup.number().nullable(),
      isProductiveActivity: Yup.boolean().nullable()
    })
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      // onPostLogin(values, resetForm);
    }
  });

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={
          state.method === "edit" ? t("activities_edit") : t("activities_add")
        }
        small={t("activites_desc")}
      />
      <div className="risk-page">
        <div className="risk-page__elements">
          <h2 style={{ marginBottom: "20px" }}>
            {t("global_info")}
          </h2>
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label="ID"
                formik={formik}
                field="id"
                type="number"
                required={false}
                visibilty={state.method === "edit" ? true : false}
                disabled={state.method === "edit" ? true : false}
                isFake={true}
              />
              <InputBox
                label={t("activites_numberinlist")}
                formik={formik}
                field="numberInList"
                required={true}
              />
              <InputBox
                label={t("global_name")}
                formik={formik}
                field="name"
                required={true}
              />
              <SelectBox
                label={t("activities_parentid")}
                formik={formik}
                field="parentId"
                data={activityList}
                type="complex"
                settings={{
                  parser: { value: "id", label: "name" },
                  columns: [
                    "id",
                    "name",
                    "numberInList",
                    "isProductiveActivity",
                    "explanation"
                  ]
                }}
              />
            </Col>
            <Col sm={24} md={8} lg={8}>
              <SelectBox
                label="İstehsal fəaliyyətidir?"
                formik={formik}
                field="isProductiveActivity"
                data={[
                  { value: "true", label: t("global_yes") },
                  { value: "false", label: t("global_no") }
                ]}
                type="simple"
                settings={{
                  parser: { value: "value", label: "label" },
                  columns: []
                }}
              />
              <div className="input-box">
                <span className="input-box__title">
                  {t("activities_explanation")}
                </span>
                <TextArea
                  autoSize
                  rows={6}
                  {...formik.getFieldProps("info.explanation")}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit myEvents={submitSettings} />
        </div>
      </div>
    </div>
  );
}

export default ActivitiesEdit;
