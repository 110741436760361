import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Collaborators from "../Collaborators/Collaborators";
import ClosableTabs from "components/Elements/ClosableTabs/ClosableTabs";
import RiskReview from "components/Pages/Main/Home/RiskReview/RiskReview";
import Pool from "components/Pages/Main/Home/Pool/Pool";
import Resend from "components/Pages/Main/Home/Resend/Resend";
import Executives from "components/Pages/Main/Home/Executives/Executives";
import Executives2 from "components/Pages/Main/Home/Executives/Executives2";
import Executives3 from "components/Pages/Main/Home/Executives/Executives3";
import Executives4 from "components/Pages/Main/Home/Executives/Executives4";

function CollabHome() {
  const TabComponentEnum = {
    default: Collaborators,
    risksInExecution: Executives,
    risksInExecutionOpen: Executives2,
    risksInExecutionLate: Executives3,
    // risksInExecutionUser: Executives4,
    unallocatedRisks: Pool,
    riskReview: RiskReview,
    returnRisks: Resend,
  };

  return (
    <div className="main-layout-wrapper">
      <ClosableTabs
        reduxName="home"
        className="remove-relative"
        TabComponentEnum={TabComponentEnum}
      />
    </div>
  );
}

export default CollabHome;
