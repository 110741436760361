import React, { useState } from "react";
import { Select, Button } from "antd";
import { getIn } from "formik";
import { FilterOutlined } from "@ant-design/icons";
import FormErrorHandler from "./FormErrorHandler";
import SelectionModal from "../../SelectionModal/SelectionModal";

function SelectBox(props) {
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const handleSearch = (value) => {
    setSearchText(value);
  };
  const { Option } = Select;

  var formik = props.formik;
  var field = props.field;
  var label = props.label;
  var onSelect =
    props["onSelect"] === undefined ? undefined : props["onSelect"];
  var settings =
    props.settings === undefined
      ? { parser: { value: "id", label: "name" }, columns: ["id", "name"] }
      : props.settings;
  var required =
    props.required === undefined
      ? false
      : props.required === true
      ? true
      : false;
  var disabled =
    props.disabled === undefined
      ? false
      : props.disabled === true
      ? true
      : false;
  var visibilty =
    props.visibilty === undefined
      ? true
      : props.visibilty === true
      ? true
      : false;
  var multi =
    props.multi === undefined ? false : props.multi === true ? true : false;
  var type = props.type === undefined ? "simple" : props.type;
  var isFake =
    props.isFake === undefined ? false : props.isFake === true ? true : false;
  var mode = props.mode || "";
  const placeholder = props.placeholder ?? "";

  var data = props.data === undefined ? [] : props.data;
  var options = [];
  if (data.length > 0) {
    data.map((a) => {
      options.push({
        value: a[settings.parser.value],
        label: a[settings.parser.label],
        type: a[settings.parser.type],
      });
    });
  }

  const filteredOptions = options.filter((option) => {
    const label = option.label?.toLowerCase();
    return label?.includes(searchText.toLocaleLowerCase());
  });

  const setFormValue = (val, option) => {
    if (!val) formik.setFieldValue(`info.${field}`, "");
    if (val) formik.setFieldValue(`info.${field}`, val);
    if (onSelect !== undefined) {
      onSelect(val, option);
    }
  };

  const inputHasError = () => {
    const infoError = formik?.errors?.info || {};
    const infoTouched = formik?.touched?.info || {};

    return infoError[`${field}`] && infoTouched[`${field}`] && "error";
  };

  if (isFake === true) {
    return (
      <>
        <div
          style={{ display: visibilty === true ? "block" : "none" }}
          className="input-box"
        >
          <span className="input-box__title">
            {label}{" "}
            {required === true ? <span style={{ color: "red" }}>*</span> : null}
          </span>
          <Select
            value={formik?.values.info[field]}
            options={data}
            disabled={true}
          />
          <FormErrorHandler formik={formik} field={`info.${field}`} />
        </div>
      </>
    );
  } else {
    if (type === "simple") {
      return (
        <div
          className="input-box"
          style={{ display: visibilty === true ? "block" : "none" }}
        >
          <span className="input-box__title">
            {label}{" "}
            {required === true ? <span style={{ color: "red" }}>*</span> : null}
          </span>
          <Select
            mode={mode}
            disabled={disabled}
            showSearch
            onSearch={handleSearch}
            optionFilterProp="children"
            allowClear
            value={getIn(formik?.values?.info, field)}
            onChange={(value, option) => {
              setFormValue(value, option);
            }}
            onBlur={formik?.handleBlur(`info.${field}`)}
            status={inputHasError(`info.${field}`) ? "error" : ""}
            placeholder={placeholder}
          >
            {filteredOptions.map((option) => (
              <Option
                key={option.value}
                value={option.value}
                type={option.type}
                label={option.label}
              >
                {option.label}
              </Option>
            ))}
          </Select>
          <FormErrorHandler formik={formik} field={`info.${field}`} />
        </div>
      );
    } else {
      var selectModalJSON = {
        title: "Zəhmət olmasa detallı cədvəldən seçim edin",
        multi: multi,
        type: "data", // !url
        data: data,
        selecteds: [],
        columns: settings.columns,
        onSelected: (selecteds) => {
          if (selecteds.length > 0) {
            if (multi) {
              selectModalJSON.selecteds = selecteds;
              if (onSelect !== undefined) {
                onSelect(selecteds);
              }
              formik.setFieldValue(`info.${field}`, selecteds);
            } else {
              var sel = selecteds[0];
              var id = sel.id;
              selectModalJSON.selecteds = [id];

              formik.setFieldValue(`info.${field}`, id);
            }
          } else {
            selectModalJSON.selecteds = [];
          }
        },
        onCancel: () => {},
      };
      return (
        <>
          <div className="input-box">
            <span className="input-box__title">
              {label}{" "}
              {required === true ? (
                <span style={{ color: "red" }}>*</span>
              ) : null}
            </span>
            <div className="nested-box">
              <Select
                showSearch
                onSearch={handleSearch}
                optionFilterProp="children"
                allowClear
                value={formik?.values?.info[field]}
                onChange={(value) => {
                  setFormValue(value);
                }}
                style={{ display: multi == true ? "none" : "block" }}
                onBlur={formik?.handleBlur(`info.${field}`)}
                status={inputHasError(`info.${field}`) ? "error" : ""}
                placeholder={placeholder}
              >
                {filteredOptions.map((option) => {
                  return (
                    <Option
                      key={option.value}
                      value={option.value}
                      type={option.type}
                      label={option.label}
                    >
                      {option.label}
                    </Option>
                  );
                })}
              </Select>

              <Button
                className="button"
                style={{
                  width: multi == true && type == "complex" ? "100%" : "",
                }}
                onClick={() => {
                  if (disabled === false) {
                    setOpen(true);
                  }
                }}
              >
                <FilterOutlined />{" "}
                {multi == true && type == "complex"
                  ? "Lutfen Listeden Secim Yapiniz"
                  : ""}
              </Button>

              <SelectionModal
                open={open}
                setOpen={setOpen}
                settings={selectModalJSON}
              />
            </div>
            <div className="input-box__error-field">
              {inputHasError(`info.${field}`) ? formik.errors.info[field] : ""}
            </div>
            <FormErrorHandler formik={formik} field={`info.${field}`} />
          </div>
        </>
      );
    }
  }
}

export default SelectBox;
