import { Fragment } from "react";
import "./ConfirmDelete.scss";
import { useTranslation } from "react-i18next";

import React from "react";
import {
  RightOutlined,
  LinkOutlined,
  WarningOutlined,
} from "@ant-design/icons";

function ConfirmDelete(props) {
  const { t } = useTranslation();
  const {
    config: { title, desc, items, hasError, hasWarning },
    type,
  } = props;

  let renderBody;

  if (type === "isUser") {
    renderBody = (
      <div className="confirm-delete__items">
        {items.map(({ count, name, required, detail }) => {
          if (count > 0) {
            return (
              <Fragment>
                <div>
                  {hasError && <h2>{name}</h2>}
                  {hasWarning && <h2>{name}</h2>}
                </div>
                {detail?.map((text) => {
                  return (
                    <div className="item">
                      <span className="item__text">
                        <WarningOutlined style={{ marginTop: "4px" }} />
                        {text}
                        <span
                          style={{
                            display: required ? "block" : "none",
                            color: "red",
                            marginLeft: "5px",
                          }}
                        >
                          *
                        </span>
                      </span>
                    </div>
                  );
                })}
              </Fragment>
            );
          }
        })}
      </div>
    );
  } else {
    renderBody = (
      <div className="confirm-delete__items">
        {items.map(({ count, name, route, required }) => {
          if (count > 0) {
            //debugger;
            return (
              <div className="item">
                <span className="item__text">
                  <LinkOutlined style={{ marginTop: "4px" }} />
                  {t("confrimdelete_find_count", { count, name })}
                  <span
                    style={{
                      display: required ? "block" : "none",
                      color: "red",
                      marginLeft: "5px",
                    }}
                  >
                    *
                  </span>
                </span>
                <a className="item__link" href={route} target="blank">
                  {required
                    ? t("confrimdelete_change_info")
                    : t("confrimdelete_look_info")}
                  <span style={{ marginLeft: "4px", marginTop: "4px" }}>
                    <RightOutlined />
                  </span>
                </a>
              </div>
            );
          }
        })}
      </div>
    );
  }

  return (
    <div className="confirm-delete">
      <h1 className="confirm-delete__title">{title}</h1>
      <p className="confirm-delete__desc">{desc}</p>
      {renderBody}
    </div>
  );
}
export default ConfirmDelete;
