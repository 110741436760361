import React, { useMemo, useState } from "react";
import { Modal, Row, Col, Space, Steps } from "antd";
import SelectBox from "../FileUploadForm/Form/SelectBox";
import Textarea from "../FileUploadForm/Form/Textarea";
import RiskStatus from "models/RiskStatus";
import GroupsUsersSelect from "../GroupsUsersSelect/GroupsUsersSelect";
import { useTranslation } from "react-i18next";
import Risks from "models/Risks";
import GL from "libs/GL";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";

const LIST_SCHEME = {
  parser: { value: "value", label: "label", type: "type" },
};

const initialValues = {
  info: {
    riskStatus: null,
    receiver: null,
    note: "",
    group: false,
    nextStep: null,
  },
};

const RiskSendModal = ({
  isModalOpen,
  riskFormik,
  onShowModal,
  pathConfig,
  poolOrUser,
}) => {
  const { t } = useTranslation();
  const { isOperator, userId } = useSelector(({ user }) => ({
    isOperator: user?.data?.roles?.some((role) => role.label === "operator"),
    userId: user?.data?.user?.id,
  }));

  const history = useHistory();
  const riskFormikValues = riskFormik?.values?.info || {};
  const hasWorkFlow = riskFormikValues.hasWorkFlow;
  const workFlow = riskFormikValues.workFlow;

  const [statusTexts, setStatusTexts] = useState([]);
  const [riskStatuses, setRiskStatuses] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  function onTabsChange(key) {
    setActiveTab(key);
  }

  let validationRiskSendSchema = Yup.object().shape({
    info: Yup.object().shape({
      riskStatus: Yup.string().required(t("risk_yup_next_status")),
      receiver: Yup.string().required(t("risk_yup_next_step")),
      note: Yup.string().required(t("risk_yup_note")),
      nextStep: Yup.string().required(t("risk_yup_next_step")),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationRiskSendSchema,
    onSubmit: (values, { resetForm }) => {
      const data = values.info;
      const riskId = riskFormik?.values.info.id;
      const body = {
        ...data,
        riskId,
      };

      const comment = {
        comment: data.note,
        riskId,
      };

      delete body.nextStep;

      Risks.sendRisk(body, () => {
        Risks.createComment(comment, () => {
          resetForm();
          GL.notify.success({ text: t("risk_send_success") });
          history.push(pathConfig[poolOrUser].list);
        });
      });
    },
  });

  useMemo(() => {
    if (!hasWorkFlow) {
      let texts = [];
      for (let [id, groupObj] of Object.entries(workFlow)) {
        texts.push({ id, name: groupObj.status_mean });
      }

      setStatusTexts(texts);

      RiskStatus.getRiskStatusList((data) => setRiskStatuses(data));
    } else {
      const myData = workFlow?.nodeDataArray?.find(
        (work) => work.id === `user_${userId}`
      );
      const linkFromMy = workFlow?.linkDataArray
        ?.filter((link) => link.from === myData.key)
        .map((link) => link.to);

      const persons = workFlow?.nodeDataArray
        ?.filter((work) => linkFromMy.includes(work.id))
        .map(({ id, text, risk_status }) => ({
          name: text,
          id,
          risk_status,
        }));
      setRiskStatuses(persons);
    }
  }, [workFlow]);

  function onSelectStatus(selected) {
    const splitSelected = selected.split("_");
    if (splitSelected[0].includes("group")) {
      formik.setFieldValue("info.group", true);
    }
    const risk = workFlow?.nodeDataArray?.find((node) => node.id === selected);
    formik.setFieldValue("info.receiver", Number(splitSelected[1]));
    formik.setFieldValue("info.riskStatus", risk.risk_status);
  }

  function onGroupSelect(selected) {
    const splitSelected = selected.split("_");
    if (splitSelected[0].includes("group")) {
      formik.setFieldValue("info.group", true);
    }

    formik.setFieldValue("info.receiver", Number(splitSelected[1]));
  }
  const { values, handleSubmit, errors, touched } = formik;

  function checkFirstStep() {
    const hasErrorFirstTab = Boolean(errors.info?.nextStep);
    const isTouchedFirstTab = Boolean(touched.info?.nextStep);
    const { nextStep, riskStatus } = values.info;

    return (
      !hasErrorFirstTab &&
      isTouchedFirstTab &&
      Boolean(nextStep) &&
      Boolean(riskStatus)
    );
  }

  function checkSecondStep() {
    const hasErrorSecondTab = Boolean(errors.info?.note);
    const isTouchedSecondTab = Boolean(touched.info?.note);
    const { note } = values.info;

    return !hasErrorSecondTab && isTouchedSecondTab && Boolean(note);
  }

  const items = [
    {
      title: "İş axını və ya addım seçimi",
      key: 0,
    },
    {
      title: "Qeydin daxil edilməsi",
      key: 1,
      disabled: !checkFirstStep(),
    },
    {
      title: "Riskin göndərilməsi",
      key: 2,
      disabled: !checkSecondStep(),
    },
  ];

  const onNext = (activeTab) => {
    const stepValues = values?.info;
    if (stepValues.nextStep && stepValues.riskStatus && activeTab === 0) {
      onTabsChange(1);
    } else if (stepValues.note && activeTab === 1) {
      onTabsChange(2);
    } else {
      handleSubmit();
    }
  };

  const onPrev = (activeTab) => {
    if (activeTab < 0) {
      onShowModal(false);
    } else {
      onTabsChange(activeTab);
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={() => {
        if (isOperator) {
          onNext(activeTab);
        } else {
          handleSubmit();
        }
      }}
      onCancel={() => {
        if (isOperator) {
          onPrev(activeTab - 1);
        } else {
          onShowModal(false);
        }
      }}
      width={800}
      bodyStyle={{
        minHeight: 400,
      }}
      okText={activeTab === 2 ? "Bəli" : "Növbəti"}
      cancelText="Geri"
    >
      <Row gutter={24}>
        <Col span={24}>
          <Space size="large" direction="vertical" style={{ width: "100%" }}>
            {isOperator && hasWorkFlow && (
              <>
                {/* <AntTabs
                  items={items}
                  activeTab={activeTab}
                  onTabsChange={onTabsChange}
                /> */}
                <Steps className="primary" current={activeTab} items={items} />
                <Space
                  size="large"
                  direction="vertical"
                  style={{
                    width: "100%",
                    display: activeTab === 0 ? "flex" : "none",
                  }}
                >
                  {/* <SelectBox
            label="Status mətni"
            disabled={!hasWorkFlow} 
            data={statusTexts}
            formik={formik}
            field="nextRiskStatusText"
          /> */}
                  {/* <GroupsUsersSelect
                    onSelect={onGroupSelect}
                    label={t("risk_send_nextstep")}
                    formik={formik}
                    field="info.nextStep"
                    required={true}
                    settings={LIST_SCHEME}
                  /> */}
                  <SelectBox
                    label={t("risk_send_nextstep")}
                    required={true}
                    // disabled={hasWorkFlow}
                    formik={formik}
                    field="nextStep"
                    data={riskStatuses}
                    onSelect={(selected) => onSelectStatus(selected)}
                  />
                  {/* <SelectBox
                      label={t("risk_send_nextstatus")}
                      required={true}
                      // disabled={hasWorkFlow}
                      formik={formik}
                      field="riskStatus"
                      data={riskStatuses}
                    /> */}
                </Space>
                <Space
                  size="large"
                  direction="vertical"
                  style={{
                    width: "100%",
                    display: activeTab === 1 ? "flex" : "none",
                  }}
                >
                  <Textarea
                    label={t("global_qeyd")}
                    field="note"
                    required={true}
                    formik={formik}
                  />
                </Space>
                <Space
                  size="large"
                  direction="vertical"
                  style={{
                    width: "100%",
                    display: activeTab === 2 ? "flex" : "none",
                  }}
                >
                  <h2>Riskin iş axını üzrə göndərilməsinə əminsinizmi?</h2>
                </Space>
              </>
            )}

            {/* <SelectBox
              label="Növbəti addımı (şəxsi) seçin"
              required={true}
              // disabled={hasWorkFlow}
              // formik={formik}
              field=""
            /> */}
            {!isOperator && (
              <>
                <h2>{t("risk_step_choose")}</h2>
                <GroupsUsersSelect
                  onSelect={onGroupSelect}
                  label={t("risk_send_nextstep")}
                  formik={formik}
                  field="info.nextStep"
                  required={true}
                  settings={LIST_SCHEME}
                />
                <SelectBox
                  label={t("risk_send_nextstatus")}
                  required={true}
                  // disabled={hasWorkFlow}
                  formik={formik}
                  field="riskStatus"
                  data={riskStatuses}
                />
                <Textarea
                  label={t("global_qeyd")}
                  field="note"
                  required={true}
                  formik={formik}
                />
              </>
            )}
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default RiskSendModal;
