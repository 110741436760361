import React from "react";
import { DatePicker } from "antd";
import FormErrorHandler from "./FormErrorHandler";
import { clsx } from "clsx";
import "dayjs/locale/az";
import locale from "antd/es/date-picker/locale/az_AZ";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);

const DateTimePicker = (props) => {
  var formik = props.formik;
  var field = props.field;
  var label = props.label;
  var required = props.required ? true : false;
  var disabled = props.disabled ?? false;
  var visibilty = props.visibilty ?? true;
  let type = props.type || "date";
  let isTime = type === "time";
  let date = formik?.values?.info[field] || null;
  let formatType =
    props.format && props.showTime
      ? props.format
      : props.format
      ? props.format
      : props.showTime
      ? "YYYY-MM-DD HH:mm:ss"
      : type === "time"
      ? "HH:mm:ss"
      : "YYYY-MM-DD";

  let hasDate = date ? dayjs(date, formatType) : null;
  let showTime =
    props.showTime && typeof props.showTime === "object"
      ? props.showTime
      : props.showTime && typeof props.showTime !== "object"
      ? { defaultValue: dayjs("00:00:00", "HH:mm:ss") }
      : false;
  const infoError = formik?.errors.info || {};
  const infoTouched = formik?.touched.info || {};
  const classes = clsx(
    disabled && "input-disabled",
    infoError[`${field}`] && infoTouched[`${field}`] && "error"
  );

  const onChange = (_, dateString) => {
    let date = dateString || null;
    if (isTime && date) {
      const [hours, minutes, seconds] = dateString.split(":");
      const newDate = new Date();
      newDate.setHours(hours);
      newDate.setMinutes(minutes);
      newDate.setSeconds(seconds);
      date = newDate;
    }

    let convertDate = date ? dayjs(new Date(date)).format(formatType) : null;
    formik.setFieldValue(`info.${field}`, convertDate);
  };

  return (
    <div
      className="input-box"
      style={{ display: visibilty === true ? "block" : "none" }}
    >
      <span className="input-box__title">
        {label} {required ? <span style={{ color: "red" }}>*</span> : null}
      </span>
      <DatePicker
        picker={type}
        onChange={onChange}
        className={classes}
        value={hasDate}
        format={formatType}
        locale={locale}
        showTime={showTime}
      />

      <FormErrorHandler formik={formik} field={`info.${field}`} />
    </div>
  );
};

export default DateTimePicker;
