import co from "../../const/permissions";
import { mapPermissions } from "../../const/permissions";
import tableSettingsReducer from "./tableSettingsReducer";
import usersReducer, { userGroupReducer } from "./usersReducer";
import sublistReducer from "./sublistReducer";
import homeReducer from "./homeReducer";
import {
  SET_USER,
  SET_USER_ERROR,
  SET_USER_LOGGED_IN,
  LOG_OUT,
  LOADING_OFF,
  LOADING_ON,
  CANCEL_EDIT,
  SET_EDIT,
  SET_NOTIFICATION,
  SET_USER_ROLE,
  SET_RISK_DATA,
  SET_MY_LIST_DATA,
  SET_COMPANY_DATA,
  SET_DEPARTMENT_DATA,
  SET_SECTOR_DATA,
  SET_TOKEN,
  SET_RISK_GROUP_DATA,
  SET_RISK_STATUS_DATA,
  SET_POSITION_DATA,
  SET_ENUM_DATA,
  CLEAR_COMPANY,
  SET_ROLES_DATA,
  SET_RISK_SEPERATE,
  SET_LANGUAGES,
  SET_PYTHON,
  SET_USER_REFRESH_TOKEN,
} from "./../types";

import {
  scenario,
  myList,
  company,
  department,
  sector,
  roles,
  riskSeperating,
  riskGroup,
  position,
  riskStatus,
  languages,
  python,
} from "../../const/initialDatas";
import { enumFormInitialData } from "../../const/enumFormInitialData";

const initialUser = {
  isLoggedIn: false,
  data: {},
  message: "",
  notify: true,
};

function userReducer(userData = initialUser, action) {
  switch (action.type) {
    case SET_USER_REFRESH_TOKEN:
      return { ...userData, data: { ...userData.data, ...action.payload } };
    case SET_USER_LOGGED_IN:
      return {
        ...userData,
        data: {
          token: action.payload.token,
          user: action.payload.user,
        },
        isLoggedIn: action.payload.token !== null ? true : false,
        message: "",
        notify: !userData.notify,
      };
    case SET_USER:
      var d = {
        ...userData,
        data: action.payload,
        isLoggedIn: action.payload.token != null ? true : false,
        message: "Successfully logged in",
        notify: !userData.notify,
      };
      return d;
    case SET_USER_ROLE:
      const newUserData = JSON.parse(JSON.stringify(userData));
      newUserData.data.roles = action.payload;
      return newUserData;
    case SET_USER_ERROR:
      return {
        ...userData,
        message: action.payload.message,
        notify: !userData.notify,
      };
    case LOG_OUT:
      return initialUser;
    default:
      return userData;
  }
}

function tokenReducer(token = "", action) {
  switch (action.type) {
    case SET_TOKEN:
      return action.payload;
    case LOG_OUT:
      return "";
    default:
      return token;
  }
}

function loaderReducer(isLoading = 0, action) {
  switch (action.type) {
    case LOADING_ON:
      return ++isLoading;
    case LOADING_OFF:
      return isLoading === 0 ? 0 : --isLoading;
    default:
      return isLoading;
  }
}

function notificationReducer(
  data = { description: "", isHappy: true, notify: false },
  action
) {
  switch (action.type) {
    case SET_NOTIFICATION:
      let newData = { ...action.payload, notify: !data.notify };
      return newData;
    default:
      return data;
  }
}

const edit = (data = false, action) => {
  switch (action.type) {
    case SET_EDIT:
      return true;
    case CANCEL_EDIT:
      return false;
    default:
      return data;
  }
};

// ====================================== RTMS ======================================= //
function riskReducer(state = scenario, action) {
  switch (action.type) {
    case SET_RISK_DATA:
      return action.payload;

    default:
      return state;
  }
}

function myListReducer(state = myList, action) {
  switch (action.type) {
    case SET_MY_LIST_DATA:
      return action.payload;

    default:
      return state;
  }
}

function companyReducer(state = company, action) {
  switch (action.type) {
    case SET_COMPANY_DATA:
      return action.payload;
    case CLEAR_COMPANY:
      return action.payload;
    default:
      return state;
  }
}

function departmentReducer(state = department, action) {
  switch (action.type) {
    case SET_DEPARTMENT_DATA:
      return action.payload;

    default:
      return state;
  }
}

function sectorReducer(state = sector, action) {
  switch (action.type) {
    case SET_SECTOR_DATA:
      return action.payload;

    default:
      return state;
  }
}

function riskGroupReducer(state = riskGroup, action) {
  switch (action.type) {
    case SET_RISK_GROUP_DATA:
      return action.payload;

    default:
      return riskGroup;
  }
}

function riskStatusReducer(state = riskStatus, action) {
  switch (action.type) {
    case SET_RISK_STATUS_DATA:
      return action.payload;

    default:
      return state;
  }
}

function positionReducer(state = position, action) {
  switch (action.type) {
    case SET_POSITION_DATA:
      return action.payload;

    default:
      return state;
  }
}
function enumReducer(state = enumFormInitialData, action) {
  switch (action.type) {
    case SET_ENUM_DATA:
      return action.payload;

    default:
      return state;
  }
}

function roleReducer(state = roles, action) {
  switch (action.type) {
    case SET_ROLES_DATA:
      return action.payload;

    default:
      return state;
  }
}

function pythonReducer(state = roles, action) {
  switch (action.type) {
    case SET_PYTHON:
      return action.payload;

    default:
      return state;
  }
}

function riskSeperateReducer(state = python, action) {
  switch (action.type) {
    case SET_RISK_SEPERATE:
      return action.payload;

    default:
      return state;
  }
}

function LanguagesReducer(state = languages, action) {
  switch (action.type) {
    case SET_LANGUAGES:
      return action.payload;

    default:
      return state;
  }
}

var allReducers = {
  userReducer,
  tokenReducer,
  loaderReducer,
  notificationReducer,
  edit,
  riskReducer,
  companyReducer,
  departmentReducer,
  sectorReducer,
  riskGroupReducer,
  riskStatusReducer,
  positionReducer,
  enumReducer,
  tableSettingsReducer,
  roleReducer,
  riskSeperateReducer,
  usersReducer,
  userGroupReducer,
  myListReducer,
  sublistReducer,
  LanguagesReducer,
  pythonReducer,
  homeReducer,
};
export default allReducers;
// ====================================== RTMS ======================================= //
