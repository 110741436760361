import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col } from "antd";
import PageTitle from "../../Elements/PageTitle/PageTitle";
import FormSubmit from "../../Elements/FormSubmit/FormSubmit";
// import { apiRoutes } from "../../../const/apiroutes";
// import API from "../../../const/API2";
import { useHistory } from "react-router-dom";
import GL from "../../../libs/GL";
import InputBox from "../../Elements/FileUploadForm/Form/InputBox";
import Position from "../../../models/Position";
import { useDispatch, useSelector } from "react-redux";
import { setPositionData } from "../../../redux/actions";
import { useTranslation } from "react-i18next";
function PositionsEdit(props) {
  const { t } = useTranslation();
  // var api = new API()
  const history = useHistory();
  // const formRef = React.createRef();
  // const mainUrl1 = apiRoutes.positions.add;
  // const mainUrl2 = apiRoutes.positions.put;

  var state = props.location.state;

  // if (state.method == 'edit') {
  //
  // } else {
  //
  // }

  const dispatch = useDispatch();
  const reduxPosition = useSelector(state => state.position);
  if (state == undefined) {
    state = reduxPosition;
  }

  let initialValues;

  const { method, ...stateRest } = state;
  if (state.method === "create") {
    if (reduxPosition !== undefined) {
      if (reduxPosition.method == undefined) {
        reduxPosition.method = "create";
      }
      initialValues = reduxPosition;
    } else {
      initialValues = stateRest;
    }
  }
  if (state.method === "edit") {
    initialValues = stateRest;
  }
  if (state.method === "copy") {
    initialValues = stateRest;
    state.method = "create";
  }

  useEffect(() => {
    formik.setFieldTouched("setFormikTouched");
    // populateList();
  }, []);

  var submitSettings = {
    type: state.method,
    save: async () => {
      if (state.id == 0) {
        var sendData = await GL.getFormikData(formik, ["name"]);

        Position.CREATE(sendData, res => {
          GL.notify.success({ text: t("answer_success") });
          history.push("/positions");
        });
      }
    },

    edit: async () => {
      if (state.id !== 0) {
        var sendData = await GL.getFormikData(formik, ["id", "name"]);
        Position.UPDATE(sendData, res => {
          GL.notify.success({ text: t("answer_success") });
          history.push("/positions");
        });
      }
    },
    cancel: () => {
      history.push("/positions");
    }
  };

  const postPositionSchema = Yup.object().shape({
    info: Yup.object().shape({
      id: Yup.string().required(t("yup_id")),
      name: Yup.string().required(t("yup_name"))
    })
  });

  const formik = useFormik({
    initialValues,
    validationSchema: postPositionSchema,
    onSubmit: (values, { resetForm }) => {
      // onPostLogin(values, resetForm);
    }
  });

  useEffect(
    () => {
      console.log("formik useEffect", formik.errors);
    },
    [formik.errors]
  );

  useEffect(
    () => {
      dispatch(setPositionData(formik.values));
    },
    [formik.values]
  );

  useEffect(
    () => {
      if (method === "create" && Object.keys(formik.touched).length) {
        history.replace({ state: { ...state, method: "create" } });
      }
    },
    [formik.touched]
  );

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={
          state.method == "edit"
            ? t("position_edit")
            : state.method == "copy" ? t("position_add") : t("position_add")
        }
        small={t("positions_desc")}
      />
      <div className="risk-page">
        <div className="risk-page__elements">
          <h2>
            {t("global_info")}
          </h2>
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label="ID"
                formik={formik}
                field="id"
                type="number"
                required={false}
                visibilty={state.method == "edit" ? true : false}
                disabled={state.method == "edit" ? true : false}
              />
              <InputBox
                label={t("global_name")}
                formik={formik}
                field="name"
                required={true}
              />
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit myEvents={submitSettings} />
        </div>
      </div>
    </div>
  );
}

export default PositionsEdit;
