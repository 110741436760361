import React, { useEffect, useMemo, useState } from "react";
import {
  GetPagePermission,
  GetTableSettings,
} from "../../../hooks/permissions";
import GL from "../../../libs/GL";
import SuperTable from "../../Elements/Tabulator/SuperTable";
import { Skeleton } from "antd";
import { useHistory } from "react-router-dom";
import Position from "../../../models/Position";
import Logs from "../../../models/Logs";
import EmptyPage from "../../Elements/Skelethon/EmptyPage";
import { useTranslation } from "react-i18next";
import ShowModal from "./components/ShowModal";

const pageId = "logs";

function LogsPage(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [logsList, setLogsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowOpenModal, setIsShowOpenModal] = useState(false);
  const [showModalData, setShowModalData] = useState(null);
  const permissions = GetPagePermission(13);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );

  const onSetIsShowOpenModal = (isOpen) => setIsShowOpenModal(isOpen);

  const operatorSet = {
    edit: {
      status: false,
      click: function (data) {},
    },
    delete: {
      status: false,
      click: function (data) {},
    },
    show: {
      status: permissions["show"],
      click: function (data) {
        setShowModalData(data);
        onSetIsShowOpenModal(true);
      },
    },
    copy: {
      status: false,
      click: function (data) {},
    },
  };
  const options = {
    permissions: {
      add: {
        status: false,
        endpoint: "/logs/add",
      },
      multiDelete: {
        status: false,
        endpoint: "/positions/multiDelete",
      },
    },
    settings: {
      id: pageId,
      type: "data",
      data: logsList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "",
      createNewRoute: "logs/create",
      state: {},
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 70,

        formatter: function (cell) {
          return GL.setSelectStatus(cell);
        },
      },
      {
        title: "Kullanici",
        field: "user_name",
      },
      {
        title: "Endpoint",
        field: "endpoint",
      },
      {
        title: "Tarih",
        field: "dateCreated",
      },
      {
        title: "METHOD",
        field: "method",
      },
      {
        title: t("global_operations"),
        field: "details",
        width: 130,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        user_name: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        dateCreated: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        endpoint: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        method: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        method: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        operations: {
          // dataType: "text",
        },
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {},
    },
  };

  const getLogsList = () => {
    setLoading(true);
    Logs.getLogsList(
      (data) => {
        setLogsList(Logs.getForTableData(data));
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    getLogsList();
  }, []);

  if (loading) {
    return <Skeleton active />;
  } else {
    if (logsList.length == 0) {
      return (
        <EmptyPage
          route="positions/create"
          name={t("positions")}
          state={options.settings.state}
        />
      );
    } else {
      return (
        <div style={containerStyle}>
          <div className="main-layout-wrapper__head">
            <div className="simple-head">
              <h1>{t("logs")}</h1>
              <span>{t("positions_desc")}</span>
            </div>
          </div>
          <SuperTable data={options} />
          {isShowOpenModal && (
            <ShowModal
              isShowOpenModal={isShowOpenModal}
              onSetIsShowOpenModal={onSetIsShowOpenModal}
              showModalData={showModalData}
            />
          )}
        </div>
      );
    }
  }
}

export default LogsPage;
