import React from "react";
import { Space } from "antd";
import MailHeader from "./MailHeader";
import MailMain from "./MailMain";
import MailDesc from "./MailDesc";
import { useMailContext } from "./MailContextAPI";
import { useState } from "react";
import { useHistory } from "react-router";
import { useEffect } from "react";

const Mail = () => {
  const history = useHistory();
  const [newRowSelected, setNewRowSelected] = useState([]);

  const searchParamsHandler = new URLSearchParams(history.location.search);
  const urlMailId = searchParamsHandler.get("mailId");

  const setQueryParam = (queryType, queryValue) => {
    searchParamsHandler.set(queryType, queryValue);
    const newQueryString = searchParamsHandler.toString();
    history.replace({
      pathname: history.location.pathname,
      search: newQueryString
    });
  };

  const handleClickTab = (queryType, queryValue) => {
    searchParamsHandler.delete("mailId");
    searchParamsHandler.set(queryType, queryValue);
    const newQueryString = searchParamsHandler.toString();
    history.replace({
      pathname: history.location.pathname,
      search: newQueryString
    });
  };

  useEffect(() => {
    if (!searchParamsHandler.get("tab")) {
      setQueryParam("tab", "inbox");
    }
  }, []);

  return (
    <Space
      direction="vertical"
      size="large"
      style={{
        padding: "20px",
        marginRight: "90px",
        fontStyle: "normal",
        fontFamily: "Poppins",
        backgroundColor: "#fff"
      }}
    >
      <MailHeader
        setQueryParam={setQueryParam}
        handleClickTab={handleClickTab}
      />
      {urlMailId
        ? <MailDesc
            setQueryParam={setQueryParam}
            handleClickTab={handleClickTab}
            newRowSelected={newRowSelected}
          />
        : <MailMain
            handleClickTab={handleClickTab}
            setQueryParam={setQueryParam}
            setNewRowSelected={setNewRowSelected}
          />}
    </Space>
  );
};

export default Mail;
