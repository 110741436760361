import React from "react";
import "../Voen.scss";
import VoenDetailItem from "./VoenDetailItem";
import { Col, Row, Button, Space } from "antd";

const VoenDetail = ({ hasHistoryButton }) => {
  return (
    <div className="VoenDetail">
      <Space size={10} direction="vertical">
        <Row gutter={24} span={24}>
          <Col sm={24} md={12} lg={12}>
            <VoenDetailItem
              title="Risk əsası:"
              value="Ssenari 1- Alış olmadan satış edən ödəyici"
            />
            <VoenDetailItem title="Vergi orqanı:" value="KSİBİ" />
            <VoenDetailItem title="VÖEN-i:" value="200456789098" />
            <VoenDetailItem title="Adı:" value="Lorem İpsum MMC" />
          </Col>
          <Col sm={24} md={12} lg={12}>
            <VoenDetailItem
              title="Statusu:"
              value="İcra edilmiş"
              href="/main/status"
            />
            <VoenDetailItem title="Olduğu siyahılar:" value="(Q) (Q) (Q)" />
            <VoenDetailItem title="Qeydiyyat tarixi:" value="dd/mm/yyyy" />
            <VoenDetailItem title="ƏDV qeydiyyatı:" value="dd/mm/yyyy" />
            <VoenDetailItem title="RVO:" value="dd/mm/yyyy" />
          </Col>
        </Row>
        <Row gutter={10} span={24}>
          <Col span={hasHistoryButton ? 22 : 24}>
            <div className="VoenDetailProduct">
              <VoenDetailItem title="Mal qalığı 01/01:" value="1.000" />
            </div>
          </Col>
          {hasHistoryButton && (
            <Col span={2}>
              <Button className="btn-primary w-100">Tarixçə</Button>
            </Col>
          )}
        </Row>
      </Space>
    </div>
  );
};

export default VoenDetail;
