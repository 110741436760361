export function convertFileSize(count) {
  var level = 0;

  while (count > 1024) {
    count /= 1024;
    level++;
  }

  count = Math.round(count * 100) / 100;

  level = ["", "K", "M", "G", "T"][level];

  return `${count} ${level}B`;
}
