/*eslint-disable*/
import { useSelector } from "react-redux";
import { menuJSON } from "../const/menuJSON";
import { useState } from "react";
import { useEffect } from "react";

/* Dev : Ali Kilic - It's for getting table settings of page tables. 
All page has a pageId. You can use pageId like table_id parameter*/
export const GetTableSettings = table_id => {
  var table = localStorage.getItem("tables");
  var secretColumns = [];
  var columnSort = [];
  if (table !== null) {
    table = JSON.parse(table);

    if (table[`table-${table_id}`] !== undefined) {
      secretColumns = table[`table-${table_id}`]["secretColumns"];
      columnSort = table[`table-${table_id}`]["columnSort"];
    }
  }
  var settings = {
    pagination: true,
    hideColumns: secretColumns,
    columnSort: columnSort,
    rowNums: 10
  };

  const mystate = useSelector(state => {
    return state.tableSettings;
  });

  const data = JSON.parse(JSON.stringify(mystate));
  if (data !== null) {
    if (data[table_id] !== undefined) {
      settings = data[table_id];
    }
  }

  return settings;
};

/* Dev : Ali Kilic, All page or permission group has a id so when you open a page you can get all permission */
export const GetPagePermission = group_id => {
  const user = useSelector(state => state.user);
  if (user.isLoggedIn == false) {
    return [];
  }
  const roles = user.data.roles;
  var activeRoleIndex = -1;
  for (var i = 0; i < roles.length; i++) {
    if (roles[i].status == true) {
      activeRoleIndex = i;
      break;
    }
  }
  if (activeRoleIndex == -1) {
    return [];
  }
  const reduxPermissions = roles[activeRoleIndex].permissions;
  var perms = {
    menu: false,
    add: false,
    edit: false,
    delete: false,
    multidelete: false,
    show: false,
    copy: false
  };
  const data = JSON.parse(JSON.stringify(reduxPermissions));
  const filteredData = data.filter(a => a.group === group_id);
  filteredData.forEach(a => {
    perms[a.permission_name] = true;
  });
  return perms;
};

export const getSimplePermissions = () => {
  var obj = {};
  const user = useSelector(state => state.user.data);
  if (user.roles.length > 0) {
    var role = false;
    for (var i = 0; i < user.roles.length; i++) {
      if (user.roles[i].status == true) {
        role = user.roles[i];
        break;
      }
    }
    if (role == false) {
      return obj;
    }
    role.permissions.map(p => {
      if (obj[p.group] == undefined) {
        obj[p.group] = [p.permission];
      } else {
        obj[p.group].push(p.permission);
      }
    });

    return obj;
  } else {
    return obj;
  }
};

/* Dev : Aslan, This function returning menu item's permission id list for setting visible status */
export const GetMenuList = () => {
  const [lastMenuState, setLastMenuState] = useState([]);
  const roles = useSelector(state => state.user.data.roles);

  useEffect(
    () => {
      var activeRoleIndex = -1;
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].status == true) {
          activeRoleIndex = i;
          break;
        }
      }
      if (activeRoleIndex == -1) {
        return [];
      }
      const reduxPermissions = roles[activeRoleIndex].permissions;

      const data = JSON.parse(JSON.stringify(reduxPermissions));

      const permgroups = {};

      data.forEach(item => {
        if (permgroups[item.group] === undefined) {
          permgroups[item.group] = [item.permission];
        } else {
          permgroups[item.group].push(item.permission);
        }
      });

      const lastMenu = menuJSON.map(menu => {
        let mgroup = menu.group;
        let mPerm = menu.permission;
        if (permgroups[mgroup] === undefined) {
          menu.visibilty = false;
        } else {
          if (permgroups[mgroup].indexOf(mPerm) === -1) {
            menu.visibilty = false;
          } else {
            menu.visibilty = true;
          }
        }
        if (menu.sub.length > 0) {
          let sub = menu.sub;
          sub = sub.map(submenu => {
            let smgroup = submenu.group;
            let smPerm = submenu.permission;
            if (permgroups[smgroup] === undefined) {
              submenu.visibilty = false;
            } else {
              if (permgroups[smgroup].indexOf(smPerm) === -1) {
                submenu.visibilty = false;
              } else {
                submenu.visibilty = true;
              }
            }
            return submenu;
          });
          menu.sub = sub;
        }
        return menu;
      });

      setLastMenuState(lastMenu);
    },
    [roles]
  );

  return lastMenuState;
};
