export const types = {
  ".doc": "application/msword",
  ".dot": "application/msword",
  ".docx":
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ".dotx":
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  ".docm": "application/vnd.ms-word.document.macroEnabled.12",
  ".dotm": "application/vnd.ms-word.template.macroEnabled.12",
  ".xls": "application/vnd.ms-excel",
  ".xlt": "application/vnd.ms-excel",
  ".xla": "application/vnd.ms-excel",
  ".xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ".xltx":
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  ".xlsm": "application/vnd.ms-excel.sheet.macroEnabled.12",
  ".xltm": "application/vnd.ms-excel.template.macroEnabled.12",
  ".xlam": "application/vnd.ms-excel.addin.macroEnabled.12",
  ".xlsb": "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  ".ppt": "application/vnd.ms-powerpoint",
  ".pptx": "application/vnd.ms-powerpoint",
  ".jpg": "image/jpg",
  ".jpeg": "image/jpeg",
  ".png": "image/png",
  ".gif": "image/gif",
  ".pdf": "application/pdf",
  ".mp3": "audio/mpeg",
  ".wav": "audio/wav",
  ".mp4": "video/mp4",
  ".avi": "video/x-msvideo",
  ".zip": "application/zip",
  ".tar": "application/x-tar",
  ".txt": "text/plain",
};
