import API from "../const/API2";
import { apiRoutes } from "../const/apiroutes";
import User from "./User";
import Company from "./Company";

var api = new API();

class Department {
  constructor(obj) {
    this.id = obj["id"];
    this.name = obj["name"];
    this.totalName = obj["totalName"];
    this.companiesDto = obj["companiesDto"];

    if (obj["managerId"] !== null) {
      this.manager = new User(obj["managerId"]);
    } else {
      this.manager = new User();
    }
    if (obj["companiesDto"] !== null) {
      this.company = new Company(obj["companiesDto"]);
    } else {
      this.company = null;
    }
    this.isActive = obj["isActive"];
  }

  getName() {
    return this.name;
  }

  getFullName() {
    return this.totalName;
  }

  static getEmptyState() {
    return {
      id: 0,
      name: "",
      totalName: "",
      company: null,
      manager: null,
      isActive: true,
    };
  }

  static getDepartmentsByCompanyID(id, callback) {
    api.getData(
      `${apiRoutes.departments.search}/?companyId=${id}`,
      (response) => {
        const convertFromArray = Department.getDepartmentsFromArray(response);
        callback(convertFromArray);
      }
    );
  }

  static getSectorsByDepartmentID(id, callback) {
    api.getData(
      apiRoutes.sectors.put + "/search?departmentId=" + Number(id),
      (data) => {
        if (callback !== undefined) {
          callback(data);
        }
      }
    );
  }

  static getUsers(department_id, callback, errCallback) {
    api.getData(
      `${apiRoutes.user.search}?departmentId=${Number(department_id)}`,
      callback,
      errCallback
    );
  }

  static getDepartmentsFromArray(data) {
    return data.map((a) => new Department(a));
  }

  static getDepartmenstList(callback, errCallback) {
    api.getData(
      apiRoutes.departments.list,
      (data) => {
        var arr = Department.getDepartmentsFromArray(data);
        callback(arr);
      },
      errCallback
    );
  }

  getTableItem() {
    return {
      id: this.id,
      name: this.name,
      totalName: this.totalName,
      company: this.companiesDto.totalName,
      companyId: this.companiesDto.id,
      manager: this.manager.getName(),
      managerId: this.manager.id,
      isActive: this.isActive,
    };
  }

  static getForTableData(data) {
    return data.map((a) => {
      return a.getTableItem();
    });
  }

  // getCompanies(callback){
  //   api.getData(apiRoutes.companies.list,(data)=>{
  //     if(callback!==undefined){
  //       var filtered_data = data.filter((a)=>{
  //         if(a.companyDto==null){
  //           return false;
  //         }
  //         if(this.id==a.companyDto.id){
  //           return true;
  //         }
  //       });
  //       this.companies = filtered_data;
  //       callback(filtered_data);
  //     }
  //   });
  // }

  // static getCompaniesByCompanyID(id,callback){
  //   api.getData(apiRoutes.companies.list,(data)=>{
  //     if(callback!==undefined){
  //       var filtered_data = data.filter((a)=>{
  //         if(a.companiesDto==null){
  //           return false;
  //         }
  //         if(a.companiesDto.id==id){
  //           return true;
  //         }
  //       });
  //       callback(filtered_data);
  //     }
  //   });
  // }

  static sortDESC(a, b) {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    return 0;
  }

  static getArrayForSelect(data) {
    var arr = [];
    data.map((a) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    return arr;
  }

  static UPDATE(data, callback, errCallback) {
    if (data == false) {
      return null;
    }
    var id = data["id"];
    if (data.manager !== null) {
      data.managerId = {
        id: data.manager,
      };
    } else {
      delete data.manager;
    }
    if (data.company !== null) {
      data.companiesDto = {
        id: data.company,
      };
    } else {
      delete data.company;
    }
    if (id !== 0 && id !== "" && id !== null && id !== undefined) {
      api.putData(
        apiRoutes.departments.put + "/" + id,
        data,
        (res) => {
          if (callback !== undefined) {
            callback(res);
          }
        },
        errCallback
      );
    }
  }

  static CREATE(data, callback, errCallback) {
    if (data == false) {
      return null;
    }
    if (data["id"] !== undefined) {
      delete data["id"];
    }
    if (data.manager !== null) {
      data.managerId = {
        id: data.manager,
      };
    } else {
      delete data.manager;
    }
    if (data.company !== null) {
      data.companiesDto = {
        id: data.company,
      };
    } else {
      delete data.company;
    }
    api.postData(
      apiRoutes.departments.add,
      data,
      (res) => {
        if (callback !== undefined) {
          callback(res);
        }
      },
      errCallback
    );
  }

  static DELETE(id, callback) {
    if (id == 0 || id == "" || id == null || id == undefined) {
      return null;
    }
    id = Number(id);
    api.deleteData(
      `${apiRoutes.departments.put}/${id}`,
      (data) => {
        callback(true, data);
      },
      (err) => {
        callback(false, err);
      }
    );
  }
}

export default Department;
