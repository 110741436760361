import "./ClosableTabs.scss";

import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addTab,
  removeTab,
  addTabWithChild,
} from "../../../redux/actions/homeActions";

function ClosableTabs(props) {
  const { reduxName, TabComponentEnum } = props;

  const queryType = "tab";
  const reduxTabType = "tabs";

  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const tabQuery = queryParams.get(queryType); //! potentially null
  const childTabQuery = queryParams.get("subTab"); //! potentially null

  const reduxValue = useSelector((state) => state[reduxName]);

  const [activeKey, setActiveKey] = useState(tabQuery);
  const [items, setItems] = useState([]);

  const setQueryParam = (queryType, queryValue) => {
    queryParams.set(queryType, queryValue);
    const newQueryString = queryParams.toString();
    history.push({
      pathname: history.location.pathname,
      search: newQueryString,
    });
  };

  const deleteQuery = (queryType) => {
    queryParams.delete(queryType);
    const newQueryString = queryParams.toString();
    history.push({
      pathname: history.location.pathname,
      search: newQueryString,
    });
  };

  const handleTabFocusAfterDelete = (targetKey) => {
    //! focusda olan tabin silinmesi (case 1)
    //! subtabi olan tablarin silinmesi (case 2)
    //! CASE 1 ===========================================
    const targetKeyReduxIndex = reduxValue[reduxTabType].findIndex(
      (reduxTab) => reduxTab.key === targetKey
    );

    const isFocusedIndex = targetKey === tabQuery;
    if (isFocusedIndex && reduxValue[reduxTabType][targetKeyReduxIndex - 1]) {
      // ! if there is an previous tab in redux
      setQueryParam(
        queryType,
        reduxValue[reduxTabType][targetKeyReduxIndex - 1].key
      );
    }
    if (isFocusedIndex && !reduxValue[reduxTabType][targetKeyReduxIndex - 1]) {
      // ! if there is no previous tab in redux go to default tab
      setQueryParam(queryType, "default");
    }
    // ! CASE 1 ===========================================
    // ! CASE 2 ===========================================
    if (childTabQuery) {
      deleteQuery("subTab");
    }
    //! CASE 2 ===========================================
  };

  const addNewTab = (tabLabel, key) => {
    const tabAlreadyOpen = reduxValue[reduxTabType].some(
      (reduxTab) => reduxTab.key === key
    );

    if (!tabAlreadyOpen) {
      const newTab = {
        label: tabLabel,
        children: `${key}`,
        key: `${key}`,
        closable: true,
        subTabs: [],
      };
      dispatch(addTab(newTab));
    }
    setQueryParam(queryType, key);
  };

  const deleteTab = (targetKey) => {
    handleTabFocusAfterDelete(targetKey);
    dispatch(removeTab(targetKey));
  };

  const onEdit = (targetKey, action) => {
    if (action !== "add") {
      deleteTab(targetKey);
    }
  };

  const onChange = (targetKey) => {
    deleteQuery("subTab");
    setQueryParam(queryType, targetKey);
  };

  const addTabWithSubTab = (
    parentTabName,
    parentTabKey,
    subTabName,
    subTabKey
  ) => {
    const tabAlreadyOpen = reduxValue[reduxTabType].some(
      (reduxTab) => reduxTab.key === parentTabKey
    );

    // if (!tabAlreadyOpen) {
    dispatch(
      addTabWithChild(parentTabName, parentTabKey, subTabName, subTabKey)
    );
    // }
    setQueryParam(queryType, parentTabKey);
  };

  useEffect(() => {
    setActiveKey(tabQuery);
    if (!tabQuery) {
      setQueryParam(queryType, "default");
    }
  }, [history.location]);

  useEffect(() => {
    const newTabComponentEnum = { ...TabComponentEnum };
    for (let [key, Value] of Object.entries(newTabComponentEnum)) {
      newTabComponentEnum[key] = (
        <Value
          addNewTab={addNewTab}
          addTabWithSubTab={addTabWithSubTab}
          deleteTab={deleteTab}
          key={key}
        />
      );
    }
    const itemsWithRealComponents = reduxValue.tabs.map((item) => {
      if (newTabComponentEnum[item.children]) {
        return { ...item, children: newTabComponentEnum[item.children] };
      } else {
        return { ...item, children: <h1>Tapilmadi</h1> };
      }
    });
    setItems(itemsWithRealComponents);
  }, [reduxValue, history.location]);

  const className = props.className || "";

  return (
    <div className="custom-editable-ant-tabs">
      <Tabs
        type="editable-card"
        onChange={onChange}
        activeKey={activeKey}
        onEdit={onEdit}
        items={items}
        hideAdd
        className={className}
      />
    </div>
  );
}

export default ClosableTabs;
