import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { setUserGroupInfo } from "../../../redux/actions/usersActions";
import * as Yup from "yup";
import { Row, Col, Skeleton } from "antd";
import PageTitle from "../../Elements/PageTitle/PageTitle";
import FormSubmit from "../../Elements/FormSubmit/FormSubmit2";
import { useHistory } from "react-router-dom";
import GL from "../../../libs/GL";
import User from "../../../models/User";
import UserGroup from "../../../models/UserGroup";
import InputBox from "../../Elements/FileUploadForm/Form/InputBox";
import SelectBox from "../../Elements/FileUploadForm/Form/SelectBox";
import UserGroupTable from "./components/UserGroupTable";
import { userGroup } from "const/initialDatas";
import { useTranslation } from "react-i18next";
import Sector from "models/Sector";

function UserGroupEdit(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userGroupState = useSelector(({ userGroup }) => userGroup);
  const [managerList, setManagerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sectors, setSectors] = useState([]);
  var state = props.location.state;
  const groupId = state.id;
  const method = state.method;
  const isCreate = method === "create";

  let initialValues;
  if (method) {
    initialValues = userGroup;
  }
  if (method === "create") {
    initialValues = userGroupState;
  }

  const getSectors = () => {
    Sector.getSectorList((data) => {
      setSectors(data);
    });
  };

  const populateList = () => {
    User.getUserList((data) => {
      setManagerList(data);
    });
  };

  useEffect(() => {
    populateList();
    getSectors();
  }, []);

  const validation = Yup.object().shape({
    info: Yup.object().shape({
      id: Yup.number(t("yup_number")),
      name: Yup.string().required(t("yup_name")),
      manager: Yup.string().required(t("usergroup_yup_leader")),
      users: Yup.array().required(t("usergroup_yup_group")),
      sectorId: Yup.number().required("Şöbə daxil edin"),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values) => {
      const body = values.info;
      const userGroupsRelList = body?.users?.map(({ id }) => ({
        usersDto: {
          id,
        },
      }));

      const updatedBody = {
        id: body.id,
        name: body.name,
        manager: body.manager,
        sectorId: body.sectorId,
        userGroupsRelList,
      };
      // onPostLogin(values, resetForm);
      if (isCreate) {
        UserGroup.CREATE(updatedBody, () => {
          GL.notify.success({ text: t("success_message") });
          history.push("/user-groups");
          dispatch(setUserGroupInfo(userGroup));
          onReset();
        });
      } else {
        UserGroup.UPDATE(updatedBody, () => {
          GL.notify.success({ text: t("success_message") });
          history.push("/user-groups");
          dispatch(setUserGroupInfo(userGroup));
          onReset();
        });
      }
    },
  });

  const {
    setFieldValue,
    values,
    handleSubmit,
    setValues,
    setTouched,
    setErrors,
  } = formik;

  const getUserGroupById = (groupId) => {
    if (groupId && groupId !== 0) {
      setIsLoading(true);
      UserGroup.getUserGroupByID(groupId, (response) => {
        const { id, isActive, manager, name, userGroupsRelList, sectorId } =
          response;
        const updatedValues = {
          info: {
            id,
            isActive,
            manager: manager.id,
            name,
            sectorId,
            users: userGroupsRelList,
          },
        };
        setValues(updatedValues);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    getUserGroupById(groupId);
  }, [groupId]);

  useEffect(() => {
    let dispatchTimer;
    if (isCreate) {
      dispatchTimer = setTimeout(() => {
        dispatch(setUserGroupInfo(values));
      }, 500);
    }

    return () => {
      clearTimeout(dispatchTimer);
    };
  }, [values, isCreate, dispatch]);

  function onSubmit() {
    handleSubmit();
  }

  function onReset() {
    setValues(state);
    setTouched({});
    setErrors({});
    history.replace("/user-groups");
  }

  const onSelect = (selecteds) => {
    setFieldValue("info.users", selecteds);
  };

  if (isLoading) return <Skeleton active />;

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={
          state.method === "edit" ? t("usergroup_edit") : t("usergroup_create")
        }
        small={t("usergroup_desc")}
      />
      <div className="risk-page">
        <div className="risk-page__elements">
          <h2>{t("global_info")}</h2>
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label="ID"
                formik={formik}
                field="id"
                type="number"
                required={false}
                visibilty={state.method === "edit" ? true : false}
                disabled={state.method === "edit" ? true : false}
                isFake={true}
              />
              <InputBox
                label={t("global_name")}
                formik={formik}
                field="name"
                required={true}
              />
              <SelectBox
                label={t("usergroup_leader")}
                formik={formik}
                field="manager"
                data={managerList}
                type="complex"
                settings={{
                  parser: { value: "id", label: "name" },
                  columns: ["id", "name", "manager"],
                }}
              />
              <SelectBox
                label="Şöbə"
                formik={formik}
                field="sectorId"
                data={sectors}
              />
              <SelectBox
                onSelect={onSelect}
                label={t("group")}
                formik={formik}
                field="users"
                data={managerList}
                multi={true}
                type="complex"
                settings={{
                  parser: { value: "id", label: "name" },
                  columns: ["id", "name", "manager"],
                }}
              />
              <UserGroupTable rows={values?.info?.users} onSelect={onSelect} />
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit cancel={onReset} submit={onSubmit} />
        </div>
      </div>
    </div>
  );
}

export default UserGroupEdit;
