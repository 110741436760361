import cookie from "react-cookies";
import axios from "axios";
// import siteUrl from "./const";
import { logOut } from "../redux/actions";

const siteUrl = process.env.REACT_APP_API_URL
const admin = axios.create({
  baseURL: `${siteUrl}`,
  headers: { "Content-Type": "application/json" },
});

admin.interceptors.request.use(
  (config) => {
    // console.log("get cookie inside axios admin", cookie.load('accessToken'))
    config.headers["Authorization"] = cookie.load("accessToken")
      ? "Bearer " + cookie.load("accessToken")
      : null;
    config.headers["accept-language"] = localStorage.getItem("locale")
      ? localStorage.getItem("locale")
      : "az";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

admin.interceptors.response.use(
  function (response) {
    localStorage.setItem("error", false);
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        logOut();
        localStorage.setItem("error", true);
        localStorage.removeItem("access_token");
        window.location.reload();
      } else {
        console.log("xeta");
      }
      return Promise.reject(error);
    } else {
      localStorage.setItem("error", false);
    }
  }
);

admin.getData = function (url, callback, errCallback) {
  admin
    .get(url)
    .then((res) => {
      if (callback !== undefined) {
        callback(res.data.data);
      }
    })
    .catch((err) => {
      if (errCallback !== undefined) {
        errCallback(err);
      }
      admin.errorHandler(err);
    });
};

admin.postData = function (url, bodyData, callback, errCallback) {
  admin
    .post(url, bodyData)
    .then((res) => {
      if (callback !== undefined) {
        callback(res.data.data);
      }
    })
    .catch((err) => {
      if (errCallback !== undefined) {
        errCallback(err);
      }
      admin.errorHandler(err);
    });
};

admin.putData = function (url, bodyData, callback, errCallback) {
  admin
    .put(url, bodyData)
    .then((res) => {
      if (callback !== undefined) {
        callback(res.data.data);
      }
    })
    .catch((err) => {
      if (errCallback !== undefined) {
        errCallback(err);
      }
      admin.errorHandler(err);
    });
};

admin.pushNotify = (type, message) => {
  alert(message);
  // error kirmizi, warning orange, info blue, success yasil
};

admin.errorHandler = (err) => {
  console.log(err);
  var message = err.response.data.error;
  switch (err.response.status) {
    case 404: {
      admin.pushNotify("error", message);
      break;
    }
    default:{
      admin.pushNotify("error", message);
      break;
    }
  }
};

// ====================================== Axios instance base url to json-server =============================== //

const user = axios.create({
  baseURL: `${process.env.REACT_APP_JSON_URL}`,
  headers: { "Content-Type": "application/json" },
});

// user.interceptors.request.use(
//   (config) => config,
//   (error) => Promise.reject(error)
// );

// user.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject(error)
// );

// export  {admin};
export { user, admin };
