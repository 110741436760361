export const sector = {
  info: {
    id: 0,
    name: "",
    totalName: "",
    company: "",
    dapartment: "",
    manager: "",
    isActive: true,
    method: "create",
  },
  method: "create",
};

export const position = {
  info: {
    id: "",
    name: "",
  },
  method: "create",
};

export const riskGroup = {
  info: {
    id: 0,
    name: "",
    totalName: "",
    code: "",
    parentId: "",
  },
  method: "create",
};

export const risk = {
  info: {
    id: 0,
    name: "",
    status: "",
    executorFullName: "",
    taxId: "",
    riskType: "",
    note: "",
    precedent: "",
    riskGroup: "",
    curator: "",
    createdAt: "",
    lastExecuteDate: "",
    scenarios: {},
    tags: [],
    riskStageHistory: [],
    riskFiles: [],
    parameters: [],
    riskHistory: [],
    riskComments: [],
  },
};

export const python = {
  name: "",
  code: "",
  newName: "",
};

export const riskStatus = {
  info: {
    id: "",
    name: "",
    message: "",
  },
  method: "create",
};

export const riskSeperating = {
  info: {
    id: "",
    name: "",
    type: "",
    status: "",
    repeat: "",
    repeatPer: "",
    sqlJson: {},
    sqlText: "",
    userType: "",
    precedent: "",
    lastExecDate: "",
    note: "",
    tags: [],
    defaultStatus: "",
    usersGroupsId: "",
  },
  method: "create",
};

export const company = {
  info: {
    id: "",
    name: "",
    totalName: "",
    manager: "",
  },
  method: "create",
};

export const department = {
  info: {
    id: "",
    name: "",
    totalName: "",
    manager: "",
    company: "",
  },
  method: "create",
};

export const scenario = {
  id: "",
  info: {
    info: {
      name: "",
      code: "",
      status: "",
      precedent: "",
      riskType: "",
      riskGroup: "",
      riskBreak: "",
      repeatErr: "",
      executionPer: "",
      repeatLast: "",
      repeatPer: "",
      // curator: "",
      note: "",
      stageGroup: "",
      tags: [],
      leaders: [],
      execUserGroup: null,
    },
  },
  stages: {
    addStagesForm: {
      info: {
        id: "",
        name: "",
        status: "",
        note: "",
        queryType: "",
        repeatErr: "",
        repeatPer: "",
        repeatLast: "",
        checkParametr: "",
        orderNum: "",
        controlRule: "",
        compareValue: "",
      },
      python: { code: "", name: "" },
      statik: {
        chooseCompareOption: "",
        static: "",
        parameter: "",
        list: "",
        result: "",
        formul: [],
        resultValid: "",
      },
      restApi: {
        method: "",
        endPoint: "",
        headers: {},
        body: {},
        result: "",
        formul: [],
        resultValid: "",
        parser: [],
      },
      soap: {
        method: "",
        endPoint: "",
        headers: {},
        body: "",
        result: "",
        formul: [],
        resultValid: "",
        parser: [],
      },
      sql: {
        type: "",
        host: "",
        port: "",
        username: "",
        password: "",
        key: "",
        dbname: "",
        sql: "",
        result: "",
        formul: [],
        resultValid: "",
      },
    },
    stagesList: [],
  },
  workFlow: {
    addWorkFlowForm: {
      id: "",
      no: "",
      is_last_stage: "",
      risk_status: "",
      status_mean: "",
      recivers: [],
      type: "",
      name: "",
      risk_status_text: "",
    },
    workFlowList: {
      class: "go.GraphLinksModel",
      linkFromPortIdProperty: "fromPort",
      linkToPortIdProperty: "toPort",
      nodeDataArray: [
        { key: "start", category: "Start", loc: "175 0", text: "Basla" },
        { key: "finish", category: "Finish", loc: "175 660", text: "Bitir" },
      ],
      linkDataArray: [],
    },
  },
  parameters: {
    addParametersForm: {
      id: "",
      name: "",
      code: "",
      type: "",
      isUnique: "",
      isVoen: "",
      note: "",
    },
    parametersList: [],
  },
};

export const roles = {
  id: "",
  code: "", //kod
  name: "", // adi
  status: "", // status
  rolePermRelDtos: [
    // { //! page
    //   code: "main-menu",
    //   name: "Sol Taraf Ana Menuler",
    //   id: 1,
    //   permissions: [
    //     { // ! perm
    //       code: "deleted",
    //       name: "Show Deleted Rows",
    //       id: 1,
    //       status: false,
    //     },
    //     {
    //       code: "menu-scenario",
    //       name: "Ssenari idarəetmə",
    //       id: 2,
    //       status: false,
    //     },
    //   ],
    // },
  ],
  path: "/",
};

export const userData = {
  info: {
    birthday: null,
    companiesDto: null,
    cooperationNumber: "",
    dateCreated: "",
    departmentsDto: null,
    email: "",
    firstName: "",
    gender: null,
    id: 0,
    isActive: true,
    lastName: "",
    patronymic: "",
    phone: "",
    photoPath: "",
    pin: "",
    positionDto: null,
    sectorsDto: null,
    password: "",
    password2: "",
    userDefaultRole: "",
    roles: "",
    roleList: [],
  },
};

export const userGroup = {
  info: {
    id: 0,
    name: "",
    manager: null,
    users: [],
    isActive: true,
    sectorId: 0,
  },
};

export const myList = {
  info: {
    id: "",
    name: "",
    description: "",
    fields: [],
    data: [],
    isActive: "",
    form_name: "",
    form_type: "",
    form_field: "",
    form_nullable: "",
    form_default: "",
    form_enum: "",
    form_unique: false,
  },
};

export const sublist = {
  info: {
    tin: "",
    taxpayer: "",
    tax_organization: "",
    note: "",
  },
};

export const languages = {
  info: {
    code: "",
    az: "",
    tr: "",
    ru: "",
    en: "",
  },
};

export const home = {
  tabs: [
    {
      label: "Ana səhifə",
      children: "default",
      key: "default",
      closable: false,
      subTabs: [],
    },
  ],
  subTabs: [],
};

export const recomendedData = {
  info: {
    leader: {
      note: "",
      checks: {
        isKvy: false,
        isOvn: false,
        isRvo: false,
        isSvy: false,
        isInfo: false,
        isSerfdom: false,
        isClosable: false,
        reasonId: null,
      },
      status: "",
      userId: 0,
    },
    executor: {
      note: "",
      checks: {
        isKvy: false,
        isOvn: false,
        isRvo: false,
        isSvy: false,
        isInfo: false,
        isSerfdom: false,
        isClosable: false,
        reasonId: null,
      },
      status: "",
      userId: 0,
    },
    headofdepartment: {
      note: "",
      checks: {
        isKvy: false,
        isOvn: false,
        isRvo: false,
        isSvy: false,
        isInfo: false,
        isSerfdom: false,
        isClosable: false,
        reasonId: null,
      },
      status: "",
      userId: 0,
    },
  },
};

export const invoiceSettingsChecks = {
  isKvy: false,
  isOvn: false,
  isRvo: false,
  isSvy: false,
  isInfo: false,
  isSerfdom: false,
  isClosable: false,
  reasonId: null,
};
