import React, { useEffect, useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Skeleton } from "antd";

import { GetPagePermission, GetTableSettings } from "hooks/permissions";
import SuperTable from "components/Elements/Tabulator/SuperTable";
import EmptyPage from "components/Elements/Skelethon/EmptyPage";
import Risks from "models/Risks";
import GL from "libs/GL";
import ShowRisk from "./ShowRisk";

const pageId = "risk";
// const Risks = new RisksClass();

const initialSettings = {
  currentPage: 0,
  currentSize: 10,
  totalElements: 0,
  totalPages: 0,
  precedent: 0,
  riskAmount: 0,
  text: "",
  isLoading: false,
};

function Risk(props) {
  const { t } = useTranslation();
  const history = useHistory();
  var pathname = history.location.pathname;
  var poolOrUser = pathname === "/risk-list/user" ? "user" : "group";
  var pathConfig = {
    user: {
      edit: "/risk-list/user/edit",
      add: "/risk-list/user/add",
      multidelete: "/risk-list/user/multiDelete",
    },
    group: {
      edit: "/risk-list/pool/edit",
      add: "/risk-list/pool/add",
      multidelete: "/risk-list/pool/multiDelete",
    },
  };

  const [showRiskModal, setShowRiskModal] = useState(false);
  const [modalId, setModalId] = useState(null);
  const exportQueries = useRef(initialSettings);
  const [pageSettings, setPageSettings] = useState(initialSettings);
  const permissions = GetPagePermission(6);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );

  const onGetRisksList = (filter = initialSettings) => {
    setPageSettings((prev) => ({ ...prev, isLoading: true }));
    const {
      currentPage,
      currentSize,
      scenario,
      status,
      precedent,
      riskAmount,
      riskType,
      currentExecutor,
      text,
    } = filter;

    let url = text
      ? `/search?page=${currentPage}&size=${currentSize}${
          text ? `&text=${text}` : ""
        }`
      : `?page=${currentPage}&size=${currentSize}${
          scenario ? `&scenario=${scenario}` : ""
        }${precedent !== undefined ? `&precedent=${precedent}` : ""}${
          riskAmount !== undefined ? `&riskAmount=${riskAmount}` : ""
        }${status ? `&status=${status}` : ""}${
          riskType ? `&riskType=${riskType}` : ""
        }${
          currentExecutor ? `&currentExecutor=${currentExecutor}` : ""
        }&userOrGroup=${poolOrUser}`;

    Risks.LIST(
      url,
      (data, paginationInfo) => {
        let table = options.settings.table;
        table.setData([]);
        table.setData(data);
        setPageSettings((prev) => ({
          ...prev,
          ...paginationInfo,
          isLoading: false,
        }));
      },
      () => {
        setPageSettings(initialSettings);
      }
    );
  };

  function onPageSettingsChange(key, value) {
    let updateSettings = {};
    setPageSettings((prevState) => {
      updateSettings = { ...prevState, [key]: value };
      exportQueries.current = updateSettings;
      return updateSettings;
    });

    setTimeout(() => onGetRisksList(updateSettings), 500);
  }

  function onRiskExportExcelAndPDF(exportType) {
    const updateQueries = { ...exportQueries.current, exportType };
    Risks.downloadRiskExcelAndPDFFile(updateQueries, (file) => {
      let dataType =
        exportType === "excel"
          ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          : "application/pdf";
      let fileExtension = exportType === "excel" ? ".xlsx" : ".pdf";
      var a = document.createElement("a");
      a.href = `data:${dataType};base64,${file}`;
      a.download = `Riskler${fileExtension}`;
      a.click();
    });
  }

  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {
        history.push({
          pathname: pathConfig[poolOrUser].edit,
          state: {
            info: {
              id: data.id,
              isActive: data.isActive,
            },
            method: "edit",
          },
        });
      },
    },
    delete: {
      status: false,
      click: function (data) {},
    },
    show: {
      status: permissions["show"],
      click: function (data) {
        setModalId(data.id);
        setShowRiskModal(true);
      },
    },
    copy: {
      status: false,
      click: function (data) {},
    },
  };

  const [options] = useState({
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: pathConfig[poolOrUser].add,
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: pathConfig[poolOrUser].multidelete,
      },
    },
    settings: {
      id: pageId,
      type: "data",
      data: [],
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      columnSort: [], //tableSettings.columnSort,
      // ! create button settings -----------------------------------------
      createIsDisabled: true,
      isAsync: true,
      onPageSettingsChange,
      table: null,
      onRiskExportExcelAndPDF,
      // ! create button settings -----------------------------------------
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 70,

        formatter: function (cell) {
          return GL.setSelectStatus(cell);
        },
      },
      {
        title: t("voen"),
        field: "taxId",
      },
      {
        title: t("scenario"),
        field: "scenarios.name",
      },
      {
        title: t("separating_cari"),
        field: "riskStatus.name",
      },
      {
        title: t("pirority"),
        field: "priority",
      },
      {
        title: t("scenario_risk_precedent"),
        field: "scenarios.precedent",
      },
      {
        title: t("risk_type"),
        field: "scenarios.riskType",
      },
      {
        title: t("risk_group"),
        field: "grup",
        formatter: () => {
          return `EQF`;
        },
      },
      {
        title: t("current_executor"),
        field: "executor",
      },
      {
        title: t("createdat"),
        field: "createdAt",
        formatter: (cell) => {
          var d = cell.getData();
          var tar = d["createdAt"];
          console.log(tar);
          if (tar !== null) {
            var tar2 = GL.datetimeToString(tar);
            return tar2;
          } else {
            return "";
          }
        },
      },
      {
        title: t("global_lastexecute"),
        field: "lastExecuteDate",
        formatter: (cell) => {
          var d = cell.getData();
          var tar = d["createdAt"];
          var d2 = new Date(tar);
          var d3 = d2.getTime() + 1000 * 60 * 60 * 4;
          var d4 = new Date(d3);
          if (tar !== null) {
            var tar2 = GL.datetimeToString(d4);
            return tar2;
          } else {
            return "";
          }
        },
      },

      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        "scenarios.name": {
          dataType: "text",
          placeholder: t("global_search"),
          onChange: function (_, value) {
            onPageSettingsChange("scenario", value);
          },
          isAsync: true,
        },
        "riskStatus.name": {
          dataType: "text",
          placeholder: t("global_search"),
          onChange: function (_, value) {
            onPageSettingsChange("status", value);
          },
          isAsync: true,
        },
        priority: {
          dataType: "number",
          placeholder: t("global_search"),
        },
        taxId: {
          dataType: "number",
          placeholder: t("global_search"),
        },
        executor: {
          dataType: "number",
          placeholder: t("global_search"),
          onChange: function (_, value) {
            onPageSettingsChange("currentExecutor", value);
          },
          isAsync: true,
        },
        createdAt: {
          dataType: "date",
          placeholder: t("global_search"),
        },
        lastExecuteDate: {
          dataType: "date",
          placeholder: t("global_search"),
        },
        "scenarios.precedent": {
          dataType: "text",
          placeholder: t("global_search"),
          onChange: function (_, value) {
            onPageSettingsChange("precedent", value);
          },
          isAsync: true,
        },
        "scenarios.riskType": {
          dataType: "text",
          placeholder: t("global_search"),
          onChange: function (_, value) {
            onPageSettingsChange("riskType", value);
          },
          isAsync: true,
        },
        grup: {
          dataType: "text",
          placeholder: t("global_search"),
        },
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: [], //tableSettings.hideColumns,
      columnSort: [], //tableSettings.columnSort,
      rowColor: {
        field: "priority",
        type: "range",
        rules: {
          min: 0,
          max: 10,
          ranges: [
            { max: 6, color: "#FFDD263d" },
            { max: 7, color: "#FFB0343d" },
            { max: 8, color: "#FF681C3d" },
            { max: 9, color: "#DF26383d" },
            { max: 10, color: "#8638e53d" },
          ],
        },
      },
    },
  });

  useEffect(() => {
    onGetRisksList(pageSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolOrUser]);

  return (
    <div style={containerStyle}>
      <div className="main-layout-wrapper__head">
        <div className="simple-head">
          <h1>{t("risks")}</h1>
          <span>{t("risks_desc")}</span>
        </div>
      </div>
      <SuperTable data={options} pageSettings={pageSettings} />
      {showRiskModal && (
        <ShowRisk
          showModal={showRiskModal}
          setShowModal={setShowRiskModal}
          modalData={modalId}
        />
      )}
    </div>
  );
}

export default Risk;
