import API from "../const/API2";
import { apiRoutes } from "../const/apiroutes";

var api = new API();

class Sublist {
  static getSublistByMyListID(id, callback, errCalback) {
    api.getData(
      `${apiRoutes.sublist.get}/${id}`,
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errCalback) errCalback(error);
      }
    );
  }

  static getSublistByID(sublistId, rowId, callback, errCalback) {
    api.getData(
      `${apiRoutes.sublist.get}/${sublistId}/${rowId}`,
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errCalback) errCalback(error);
      }
    );
  }

  static Create(sublistId, data, callback, errCalback) {
    if (!sublistId) return;
    if (!data) return;

    const body = {};
    for (let [key, value] of Object.entries(data)) {
      body[key] = value !== undefined ? value : null;
    }
    delete body.fields;
    api.postData(
      `${apiRoutes.sublist.post}/${sublistId}`,
      body,
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errCalback) errCalback(error);
      }
    );
  }

  static Update(sublistId, rowId, data, callback, errCalback) {
    if (!sublistId) return;
    if (!rowId) return;
    if (!data) return;

    const body = {};
    for (let [key, value] of Object.entries(data)) {
      body[key] = value !== undefined ? value : null;
    }
    delete body.fields;
    api.putData(
      `${apiRoutes.sublist.put}/${sublistId}/${rowId}`,
      body,
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errCalback) errCalback(error);
      }
    );
  }

  static Delete(sublistId, rowId, callback, errCalback) {
    if (!sublistId) return;
    if (!rowId) return;

    api.deleteData(
      `${apiRoutes.sublist.delete}/${sublistId}/${rowId}`,
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errCalback) errCalback(error);
      }
    );
  }
}

export default Sublist;
