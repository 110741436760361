import React, { useState } from "react";
import { Collapse } from "antd";
import SuperTable from "components/Elements/Tabulator/SuperTable";
import { GetTableSettings } from "hooks/permissions";

const pageId = "profile-actiontypes";

const ActionTypesInfoTable = ({ data, columns, title }) => {
  columns = columns.map((a) => {
    a.headerFilter = "input";
    return a;
  });
  const tableSettings = GetTableSettings(pageId);
  const [options] = useState({
    permissions: {},
    settings: {
      id: pageId,
      type: "data",
      data,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      state: {},
      toolbarStatus: false,
    },
    columns,
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {},
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      rowColor: {},
    },
  });

  return (
    <div className="profile-actiontypes">
      <div className="profile-actiontypes__info"></div>
      <Collapse accordion expandIconPosition="end" className="">
        <Collapse.Panel header={title} key="1">
          <SuperTable data={options} />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default ActionTypesInfoTable;
