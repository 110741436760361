class HTML {

  static getClass(name){
    return document.getElementsByClassName(name);
  }

  static getId(id){
    return document.getElementById(id);
  }

  static button(obj){
    var el = document.createElement('button');
    var type = obj['type'] ?? 'simple';
    var id = obj['id'] ?? null;
    if(type==='simple'){
      el.type = 'button';
      el.className = 'ant-btn ant-btn-default';
    }
    el.innerHTML = obj['innerHTML'];
    if(id!==null){el.id = id;}
    if(obj['click']!==undefined){
      el.addEventListener('click',(e)=>{obj['click'](e);},false);
    }
    return el;
  }
}

export default HTML;