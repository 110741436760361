import { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import Invoice from "models/Invoice";
import { invoiceSettingsChecks } from "const/initialDatas";

export const roles = {
  inspector: "executor",
  leader: "leader",
  departmenthead: "headofdepartment",
};

const useInvoice = (invoiceId, riskId, getRiskGivenData, setFormikValues) => {
  const { activeRole } = useSelector(({ user }) => ({
    activeRole: user.data.roles?.find(({ status }) => status) || {},
  }));
  const role = roles[activeRole.label];
  const invoicesData = useRef([]);
  const onSetInvoices = (data) => (invoicesData.current = data);
  const invoices = () => invoicesData.current;
  // const settingsData = useRef({});
  // const onSetSettings = (data) => (settingsData.current = data);
  // const settings = settingsData.current;
  const [settings, setSettings] = useState({});
  const [isPending, setIsPending] = useState(false);
  const onSetSettings = (data) => setSettings(data);

  const checkIsEmpty = (obj) =>
    typeof obj === "object" && Object.keys(obj).length === 0;
  const getInvoiceItemById = useCallback((id) => {
    setIsPending(true);
    Invoice.getInvoiceItemById(id, (invoice) => {
      const settings = invoice.settings;
      const executor = settings.executor;
      const headofdepartment = settings.headofdepartment;
      const leader = settings.leader;

      if (checkIsEmpty(executor.checks))
        executor.checks = invoiceSettingsChecks;

      if (checkIsEmpty(headofdepartment.checks))
        headofdepartment.checks = invoiceSettingsChecks;

      if (checkIsEmpty(leader.checks)) leader.checks = invoiceSettingsChecks;

      onSetInvoices(invoice?.invoiceList);
      onSetSettings({ info: { ...settings } });
      setFormikValues({ info: { ...settings } });
      setIsPending(false);
    });
  }, []);

  useEffect(() => {
    if (invoiceId) getInvoiceItemById(invoiceId);
  }, [invoiceId]);

  const addSuccessAndErrorClass = ($this, className) => {
    $this.classList.add(className);
    setTimeout(() => {
      $this.classList.remove(className);
    }, 600);
  };
  const sendChangableDecisionData = useCallback((row, input) => {
    const newData = [...invoices()];
    const index = newData.findIndex((item) => row.id === item.itemId);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    onSetInvoices(newData);
    Invoice.putInvoiceItem(
      {
        id: row.id,
        realPrice: Number(row.realPrice),
        price: Number(row.price),
        vat: Number(row.vat),
        roleId: activeRole.id,
      },
      () => {
        addSuccessAndErrorClass(input, "success-border-color");
        getRiskGivenData(riskId);
      },
      () => addSuccessAndErrorClass(input, "danger-border-color")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveInvoiceItem = useCallback((values, { onOk, resetForm }) => {
    const body = {
      invoiceId,
      roleId: activeRole.id,
      settings: values[role],
    };

    Invoice.saveInvoiceItem(body, () => {
      getRiskGivenData(riskId);
      resetForm();
      onOk();
    });
  }, []);

  return {
    invoices: invoices(),
    settings,
    isPending,
    activeRole: role,
    onSetInvoices,
    onSetSettings,
    sendChangableDecisionData,
    saveInvoiceItem,
  };
};

export default useInvoice;
