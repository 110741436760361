import React from "react";
import { Space } from "antd";
import { Button, Table } from "antd";
import { useState } from "react";
import { useMailContext } from "./MailContextAPI";
import { useSelector } from "react-redux";
import NewMail from "./NewMail";
import { PlusOutlined } from "@ant-design/icons";

import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
import { fileTypeIconsEnum } from "const/fileTypeIconsEnum";
import { MailModel } from "./models/MailModel";
import { useHistory } from "react-router-dom";
import { fileTypeDownloadEnum } from "const/fileTypeDownloadEnum";
import GL from "libs/GL";
let fullDateFormatType = "YYYY-MM-DD HH:mm";

const columns = [
  {
    title: "Kimdən",
    dataIndex: "name",
    render: (data, rowData) => <span>{rowData.from.name}</span>,
    width: 250,
  },

  {
    title: "Mesaj",
    width: 900,
    dataIndex: "content",
    render: (data, rowData) => {
      return (
        <span>
          <div style={{ marginBottom: "5px" }}>
            {" "}
            <span
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#1C2945",
                marginRight: "5px",
              }}
            >
              {rowData.files.length > 0 && (
                <span style={{ fontSize: "18px", marginTop: "10px" }}>
                  {" "}
                  <ion-icon name="attach-outline"></ion-icon>
                </span>
              )}
              {rowData.title && rowData.title.substring(0, 10)}{" "}
              {rowData.title && rowData.title.length > 10 && "... "}
              {rowData.title && "-"}{" "}
            </span>
            <span>
              {data && data.substring(0, 40)}
              {data && data.length > 40 && "... "}
            </span>
          </div>

          {/* <div>
              {" "}
              {rowData.files.length > 0 &&
                rowData.files.map((file) => {
                  console.log(file, "FILE ITSELF");
                  console.log(file.type, "FILE TYPE");
                  return (
                    <Button
                      key={file.fileId}
                      shape="round"
                      style={{ marginRight: "5px", border: "1px solid #B6C0D0" }}
                    >
                      <Space direction="horizontal" size="small">
                        <img
                          src={
                            fileTypeIconsEnum[file.type] ||
                            fileTypeIconsEnum.default
                          }
                          alt=""
                          width="15"
                          height="15"
                        />
                        <span> {file.name}</span>
                      </Space>
                    </Button>
                  );
                })}
            </div> */}
        </span>
      );
    },
  },

  {
    title: "Vaxt",
    dataIndex: "sendTime",
    width: "250px",
    render: (data) => {
      const timestamp = dayjs(data).format(fullDateFormatType);
      return timestamp;
    },
  },
];
const columnsSent = [
  {
    title: "Kimə",
    dataIndex: "name",
    render: (data, rowData) =>
      rowData.users.slice(0, 1).map((userSent) => (
        <span>
          <span>{userSent.name}</span>
          {rowData.users.length > 1 && (
            <span>
              <span>+</span> {rowData.users.length - 1}
            </span>
          )}
        </span>
      )),

    width: 250,
  },

  {
    title: "Mesaj",
    dataIndex: "content",
    width: 900,
    render: (data, rowData) => {
      const fileNum = 3;

      return (
        <span>
          <div style={{ marginBottom: "5px" }}>
            {" "}
            <span
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#1C2945",
                marginRight: "5px",
              }}
            >
              {rowData.files.length > 0 && (
                <span style={{ fontSize: "18px", marginTop: "10px" }}>
                  {" "}
                  <ion-icon name="attach-outline"></ion-icon>
                </span>
              )}
              {rowData.title && rowData.title.substring(0, 10)}
              {rowData.title && rowData.title.length > 10 && "... "}
              {rowData.title && "-"}
            </span>
            <span>
              {data && data.substring(0, 40)}{" "}
              {data && data.length > 40 && "... "}
            </span>
          </div>

          {/* <Space direction="horizontal">
              {" "}
              {rowData.files.length > 0 &&
                rowData.files.slice(0, 3).map((file) => (
                  <Button
                    shape="round"
                    style={{
                      marginRight: "5px",
                      border: "1px solid #B6C0D0",
                    }}
                  >
                    <Space
                      direction="horizontal"
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <Space direction="horizontal" size="small">
                        <img
                          src={
                            fileTypeIconsEnum[file.type] ||
                            fileTypeIconsEnum.default
                          }
                          alt=""
                          width="15"
                          height="15"
                        />
                        <span> {file.name}</span>
                      </Space>
                    </Space>
                  </Button>
                ))}
              {rowData.files.length > fileNum && (
                <Button
                  style={{
                    fontSize: "16px",
                    backgroundColor: "white",
                  }}
                  shape="round"
                  icon={<PlusOutlined style={{ fontSize: "16px" }} />}
                >
                  {rowData.files.length - fileNum} fayl{" "}
                </Button>
              )}
            </Space> */}
        </span>
      );
    },
  },

  {
    title: "Vaxt",
    dataIndex: "sendTime",
    width: "250px",
    render: (data) => {
      const timestamp = dayjs(data).format(fullDateFormatType);
      return timestamp;
    },
  },
];

const MailMain = ({ setQueryParam, handleClickTab }) => {
  const { state, dispatch } = useMailContext();
  const [hover, onHover] = useState(false);

  const [hoverRefresh, onHoverRefresh] = useState(false);
  const {
    open,
    mailInbox,
    paginationInfo,
    isInboxOpen,
    mailSent,
    totalElements,
    totalElementsSend,
  } = state;

  const { currentPage, currentInboxPage, currentSendPage, pageSize } =
    state.paginationInfo;

  // ~ SELECTION HANDLING START
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const getCheckboxProps = (record) => record;

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelect: getCheckboxProps,
  };
  const hasSelected = selectedRowKeys.length > 0;
  // ~ SELECTION HANDLING END

  const history = useHistory();
  const searchParamsHandler = new URLSearchParams(history.location.search);
  const activeTab = searchParamsHandler.get("tab");

  const handlePageChangeInbox = (page, pageSize) => {
    dispatch({
      type: "SET_PAGINATION_INFO",
      payload: {
        currentInboxPage: page,
        currentSendPage: 1,
        pageSize,
      },
    });
  };

  const handlePageChangeSend = (page, pageSize) => {
    dispatch({
      type: "SET_PAGINATION_INFO",
      payload: {
        currentInboxPage: 1,
        currentSendPage: page,
        pageSize,
      },
    });
  };

  const refreshPage = (curTab) => {
    curTab === "inbox"
      ? MailModel.getInbox(
          currentInboxPage,
          pageSize,
          (modelDataInbox) => {
            dispatch({
              type: "SET_MAIL_INBOX",
              payload: modelDataInbox,
            });

            if (modelDataInbox) {
            }
          },
          (err) => {
            console.log(err, "ERROR MESSAGE FROM INBOX REQUEST"); //errtext
          }
        )
      : MailModel.getSend(
          currentSendPage,
          pageSize,
          (modelDataSend) => {
            dispatch({
              type: "SET_MAIL_SENT",
              payload: modelDataSend,
            });
          },
          (err) => {
            console.log(err, "ERROR MESSAGE FROM SEND REQUEST");
          }
        );
  };

  const selectedMailsForDelete = selectedRowKeys.join(",");
  const handleDeleteMail = (e, curTab) => {
    e.preventDefault();
    MailModel.deleteMailWithType(
      selectedMailsForDelete,
      curTab,
      (resDelete) => {
        if (resDelete) {
          handleClickTab("tab", "inbox");
          refreshPage(activeTab);
          GL.notify.success({
            title: "Uğurlu əməliyyat!",
            text: "Seçilmiş maillər silindi.",
          });
        }
      },
      (err) => {
        console.log("DELETE UNSUCCESSFULL", err);
      }
    );
  };

  // ~ Mail isRead functionality
  const getRowClassName = (record) => {
    return record.readTime === null ? "baby-blue-row" : "";
  };

  return (
    <div>
      <Space
        direction="horizontal"
        style={{
          marginBottom: "16px",
          alignItems: "center",
        }}
      >
        {" "}
        <span
          style={{
            cursor: "pointer",
            marginLeft: "2px",
            backgroundColor: hoverRefresh ? "#F1F1F1" : "#fff",
            borderRadius: "50%",
            padding: "3px",
            paddingTop: "12px",
            paddingLeft: "8px",
            paddingRight: "8px",
            paddingBottom: "5px",

            justifyContent: "center",
            transition: "all 0.3s ease-in-out",
          }}
          onMouseEnter={() => onHoverRefresh(true)}
          onMouseLeave={() => onHoverRefresh(false)}
          onClick={() => {
            refreshPage(activeTab);
          }}
        >
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M11.6277 7.60726C11.5175 8.44723 11.1808 9.24138 10.6536 9.90452C10.1264 10.5677 9.42868 11.0748 8.63518 11.3715C7.84168 11.6682 6.98237 11.7434 6.14942 11.5888C5.31646 11.4343 4.54129 11.056 3.90702 10.4944C3.27276 9.93275 2.80333 9.20908 2.54909 8.40097C2.29485 7.59285 2.26539 6.73077 2.46386 5.90718C2.66233 5.08359 3.08125 4.32956 3.67569 3.72596C4.27012 3.12236 5.01766 2.69196 5.83812 2.48092C8.11253 1.89759 10.4669 3.06834 11.336 5.25001M11.6673 2.33334V5.25001H8.75065"
              stroke="black"
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        {hasSelected && (
          <span
            style={{
              marginLeft: 8,
              cursor: "pointer",
              backgroundColor: hover ? "#F1F1F1" : "#fff",
              borderRadius: "50%",
              padding: "3px",
              paddingTop: "12px",
              paddingLeft: "8px",
              paddingRight: "8px",
              paddingBottom: "5px",

              justifyContent: "center",
              transition: "all 0.3s ease-in-out",
            }}
            onMouseEnter={() => onHover(true)}
            onMouseLeave={() => onHover(false)}
            onClick={(e) => {
              let curTab = isInboxOpen ? "inbox" : "send";
              GL.yesNo("Seçilmiş maillər silinsin?", (asnwer) => {
                if (asnwer) {
                  handleDeleteMail(e, curTab);
                }
              });
            }}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M2.33398 4.08333H11.6673M2.91732 4.08333L3.50065 11.0833C3.50065 11.3928 3.62357 11.6895 3.84236 11.9083C4.06115 12.1271 4.3579 12.25 4.66732 12.25H9.33398C9.6434 12.25 9.94015 12.1271 10.1589 11.9083C10.3777 11.6895 10.5007 11.3928 10.5007 11.0833L11.084 4.08333M5.25065 4.08333V2.33333C5.25065 2.17862 5.31211 2.03025 5.42151 1.92085C5.5309 1.81146 5.67927 1.75 5.83398 1.75H8.16732C8.32203 1.75 8.4704 1.81146 8.5798 1.92085C8.68919 2.03025 8.75065 2.17862 8.75065 2.33333V4.08333M5.83398 7L8.16732 9.33333M8.16732 7L5.83398 9.33333"
                stroke="black"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>{" "}
          </span>
        )}
      </Space>

      {activeTab === "inbox" && (
        <Table
          onRow={(record, index) => {
            return {
              onClick: (e) => {
                dispatch({ type: "SET_RECORD_KEY", payload: record.key });
                dispatch({ type: "SET_SHOW_MODAL", payload: false });
                dispatch({ type: "SET_MAIN_OPEN", payload: false });
                setQueryParam("mailId", record.key);
              },
            };
          }}
          rowClassName={getRowClassName}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={mailInbox}
          pagination={{
            current: paginationInfo.currentInboxPage,
            pageSize: paginationInfo.pageSize,
            total: totalElements,
            onChange: handlePageChangeInbox,
          }}
        />
      )}
      {activeTab === "sent" && (
        <Table
          onRow={(record, index) => {
            return {
              onClick: (e) => {
                dispatch({ type: "SET_RECORD_KEY", payload: record.key });
                dispatch({ type: "SET_SHOW_MODAL", payload: false });
                dispatch({ type: "SET_MAIN_OPEN", payload: false });
                setQueryParam("mailId", record.key);
              },
            };
          }}
          className="table-mail"
          rowSelection={rowSelection}
          columns={columnsSent}
          dataSource={mailSent}
          pagination={{
            current: paginationInfo.currentSendPage,
            pageSize: paginationInfo.pageSize,
            total: totalElementsSend,
            onChange: handlePageChangeSend,
          }}
        />
      )}

      {open && <NewMail setQueryParam={setQueryParam} />}
    </div>
  );
};

export default MailMain;
