import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { setUsersInfo } from "../../../redux/actions/usersActions";
import User from "../../../models/User";
import AntTabs from "../../Elements/Tabs/AntTabs";
import PageTitle from "../../Elements/PageTitle/PageTitle";
import Info from "./Info";
import Password from "./Password";
import Roles from "./Roles";
import GL from "../../../libs/GL";
import FormSubmit from "../../Elements/FormSubmit/FormSubmit2";
import { userData } from "const/initialDatas";
import { useTranslation } from "react-i18next";

function UserListEdit(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { users } = useSelector(({ users }) => ({ users }));

  var state = props.location.state;
  const method = state.method;
  const userId = state.id;
  let isCreate = method === "create" || method === "create2";
  const history = useHistory();
  const hash = history.location.hash;
  const [activeTab, setActiveTab] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let initialValues;
  if (method) {
    initialValues = userData;
  }
  if (method === "create" || method === "create2") {
    initialValues = users;
  }

  function onTabsChange(keyStr) {
    history.push({ hash: keyStr, state: state });
  }

  useEffect(() => {
    if (!hash) {
      history.replace({ hash: "#info", state });
    }
    setActiveTab(hash);
  }, [hash]);

  const validationObj = {
    info: Yup.object().shape({
      id: Yup.number(t("yup_number")),
      firstName: Yup.string().required(t("yup_name")),
      lastName: Yup.string().required(t("yup_surname")),
      patronymic: Yup.string().required(t("yup_patronymic")),
      birthday: Yup.string().required(t("yup_birthday")),
      pin: Yup.string().min(7, t("user_yup_fin")).required(t("yup_fin")),
      phone: Yup.string().required(t("yup_phone")),
      email: Yup.string().required(t("yup_email")),
      gender: Yup.boolean().required(t("yup_gender")),
      isCreate: Yup.boolean().optional(),
      password: isCreate
        ? Yup.string().required(t("yup_paswword"))
        : Yup.string().optional(),
      password2: isCreate
        ? Yup.string()
            .oneOf([Yup.ref("password"), ""], t("yup_confrim_password"))
            .required(t("yup_confrim_password"))
        : Yup.string().optional(),
      userDefaultRole: Yup.string().required(t("user_yup_roles")),
      roles: Yup.string(),
    }),
  };

  let validation = Yup.object().shape(validationObj);

  function navigateToErrTab() {
    const formikErr = formik.errors.info || {};

    const formikErrorsArr = Object.keys(formikErr);
    let hash = "#info";
    const isPassword = formikErrorsArr.some((item) =>
      item.includes("password")
    );

    hash = isPassword ? "#password" : "#info";
    const isRole = formikErrorsArr.some((item) =>
      item.includes("userDefaultRole")
    );
    if (!isPassword && isRole) hash = "#roles";

    if (formikErrorsArr?.length) {
      history.replace({ hash: hash, state: state });
    }
  }

  function onSubmit() {
    formik.handleSubmit();
    navigateToErrTab();
  }

  function onReset() {
    history.replace({ hash: hash, state: { method: "create2" } });
    formik.setValues(userData);
    formik.setTouched({});
    formik.setErrors({});
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      const body = values.info;
      const roles = body?.roleList?.map((role) => ({
        role: {
          id: role.value,
        },
        root: role.value === body.userDefaultRole,
      }));

      const companiesDto = body.companiesDto ? { id: body.companiesDto } : null;
      const departmentsDto = body.departmentsDto
        ? { id: body.departmentsDto }
        : null;
      const sectorsDto = body.sectorsDto ? { id: body.sectorsDto } : null;
      const positionDto = body.positionDto ? { id: body.positionDto } : null;

      const updateBody = {
        ...body,
        companiesDto,
        departmentsDto,
        sectorsDto,
        positionDto,
        userRolesDto: roles,
      };

      delete updateBody.roleList;
      delete updateBody.roles;
      if (isCreate) {
        User.Create(updateBody, () => {
          onReset();
          GL.notify.success({ text: t("success_message") });
          dispatch(setUsersInfo(userData));
          history.push("/users");
        });
      } else {
        User.Update(updateBody, () => {
          onReset();
          GL.notify.success({ text: t("success_message") });
          dispatch(setUsersInfo(userData));
          history.push("/users");
        });
      }
    },
  });

  const { values, touched, setValues } = formik;

  const getUserById = (userId) => {
    if (userId && userId !== 0) {
      setIsLoading(true);
      User.getUserById(userId, (response) => {
        const { company, department, sector, position, defaultRole, roles } =
          response;
        const roleList = roles?.map((role) => ({
          value: role.id,
          label: role.name,
          root: defaultRole === role.id,
        }));
        const updateResponse = {
          info: {
            ...response,
            companiesDto: company && company.id,
            departmentsDto: department && department.id,
            sectorsDto: sector && sector.id,
            positionDto: position && position.id,
            userDefaultRole: defaultRole,
            roleList,
          },
        };
        delete updateResponse.info.company;
        delete updateResponse.info.department;
        delete updateResponse.info.sector;
        delete updateResponse.info.position;
        delete updateResponse.info.roles;
        setValues(updateResponse);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    getUserById(userId);
  }, [userId]);

  useEffect(() => {
    let dispatchTimer;
    if (isCreate) {
      dispatchTimer = setTimeout(() => {
        dispatch(setUsersInfo(values));
      }, 500);
    }

    return () => {
      clearTimeout(dispatchTimer);
    };
  }, [values, isCreate]);

  useEffect(() => {
    if (method === "create" && Object.keys(touched).length) {
      history.replace({ hash, state: { method: "create2" } });
    }
  }, [touched]);

  const items = [
    {
      label: t("global_info"),
      children: <Info method={method} formik={formik} isLoading={isLoading} />,
      key: "#info",
    },
    {
      label: t("login_password"),
      children: <Password formik={formik} />,
      key: "#password",
    },
    {
      label: t("menu_roles"),
      children: <Roles method={method} formik={formik} />,
      key: "#roles",
    },
  ];

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={state.method == "edit" ? t("user_edit") : t("user_add")}
        small={t("user_edit_desc")}
      />
      <AntTabs
        items={items}
        activeTab={activeTab}
        onTabsChange={onTabsChange}
      />
      <div className="risk-page__submittion">
        <FormSubmit cancel={onReset} submit={onSubmit} />
      </div>
    </div>
  );
}

export default UserListEdit;
