import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";

import PageTitle from "components/Elements/PageTitle/PageTitle";
import FormSubmit from "components/Elements/FormSubmit/FormSubmit";
import { useHistory } from "react-router-dom";
import GL from "libs/GL";
import EnumCategory from "models/EnumCategory";
import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import { setEnumData } from "redux/actions";
import { useTranslation } from "react-i18next";

function EnumCategoriesEdit(props) {
  const { t } = useTranslation();
  const history = useHistory();
  var state = props.location.state;

  const dispatch = useDispatch();
  const reduxEnum = useSelector(state => state.enum);
  if (state == undefined) {
    state = reduxEnum;
  }

  let initialValues;

  const { method, ...stateRest } = state;
  if (state.method === "create") {
    if (reduxEnum !== undefined) {
      if (reduxEnum.method == undefined) {
        reduxEnum.method = "create";
      }
      initialValues = reduxEnum;
    } else {
      initialValues = stateRest;
    }
  }
  if (state.method === "edit") {
    initialValues = stateRest;
  }
  if (state.method === "copy") {
    initialValues = stateRest;
    state.method = "create";
  }

  var submitSettings = {
    type: state.method,
    save: async () => {
      if (state.id == 0) {
        var sendData = await GL.getFormikData(formik, ["name"]);
        EnumCategory.CREATE(sendData, res => {
          GL.notify.success({ text: t("answer_success") });
          history.push("/enum-categories");
        });
      }
    },

    edit: async () => {
      if (state.id !== 0) {
        var sendData = await GL.getFormikData(formik, ["id", "name"]);
        EnumCategory.UPDATE(sendData, res => {
          GL.notify.success({ text: t("answer_success") });
          history.push("/enum-categories");
        });
      }
    },
    cancel: () => {
      history.push("/enum-categories");
    }
  };

  const validation = Yup.object().shape({
    info: Yup.object().shape({
      id: Yup.number(t("yup_id")),
      name: Yup.string().required(t("yup_name"))
    })
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {}
  });

  useEffect(
    () => {
      dispatch(setEnumData(formik.values));
    },
    [formik.values]
  );

  useEffect(
    () => {
      if (method === "create" && Object.keys(formik.touched).length) {
        history.replace({ state: { ...state, method: "create" } });
      }
    },
    [formik.touched]
  );

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={
          state.method == "edit"
            ? t("enum_category_edit")
            : t("enum_category_add")
        }
        small={t("enum_categories_desc")}
      />
      <div className="risk-page">
        <div className="risk-page__elements">
          <h2>
            {t("global_info")}
          </h2>
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label="ID"
                formik={formik}
                field="id"
                type="number"
                required={false}
                visibilty={state.method == "edit" ? true : false}
                disabled={state.method == "edit" ? true : false}
                isFake={true}
              />
              <InputBox
                label={t("global_name")}
                formik={formik}
                field="name"
                required={true}
              />
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit myEvents={submitSettings} />
        </div>
      </div>
    </div>
  );
}

export default EnumCategoriesEdit;
