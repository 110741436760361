import React from "react";
import demoPicture from "assets/img/144x144.png";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons/lib/icons";
import { AccordionPanel } from "./Accordions";

const UserInfo = ({ data, src }) => {
  return (
    <div className="profile-userinfo">
      <div className="profile-userinfo__body">
        <div className="profile-userinfo__image">
          <Avatar shape="square" src={src} size={144} icon={<UserOutlined />} />
        </div>
        <div className="profile-userinfo__info">
          <AccordionPanel list={data} />
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
