/* eslint-disabled*/
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { Skeleton } from "antd";

import { GetPagePermission, GetTableSettings } from "hooks/permissions";
import SuperTable from "components/Elements/Tabulator/SuperTable";
import { apiRoutes } from "const/apiroutes";
import CsvModal from "./CsvModal";
import API from "const/API2";
import GL from "libs/GL";

const pageId = "pre-risks";

function PreRisk() {
  const { t } = useTranslation();
  const api = new API();
  const mainUrl = apiRoutes.scenarios;
  const history = useHistory();
  const reduxRisk = useSelector((s) => s.risk);

  const [risksList, setRisksList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCsvModal, setShowCsvModal] = useState(false);
  const [csvModalData, setCsvModalData] = useState(null);
  const [scenarioCode, setScenarioCode] = useState(null);
  const permissions = GetPagePermission(2);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );
  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {
        history.push({
          pathname: "add-risk",
          state: {
            id: data.id,
            method: "edit",
          },
        });
      },
    },
    delete: {
      status: permissions["delete"],
      click: function (data) {
        GL.yesNo(t("scenarios_delete_confirm"), (status) => {
          if (status) {
            api.deleteData(
              `${mainUrl.delete}/${data.id}`,
              (_) => {
                GL.notify.success({
                  title: t("answer_success"),
                  text: t("scenarios_deleted"),
                });

                getRiskList();
              },
              (err) => {
                GL.notify.error({
                  title: t("answer_error"),
                  text: t("scenarios_not_deleted"),
                });
                console.log("err -->", err);
              }
            );
          }
        });
      },
    },
    show: {
      status: permissions["show"],
      click: function (data) {
        setCsvModalData(data.id);
        setScenarioCode(data.code)
        setShowCsvModal(true);
      },
    },
    copy: {
      status: permissions["copy"],
      click: function (data) {
        GL.yesNo(t("scenarios_edit_confirm"), (status) => {
          if (status) {
            api.putData(
              `${mainUrl.copy}/${data.id}`,
              {},
              (newScenarioData) => {
                history.push({
                  pathname: "add-risk",
                  state: {
                    id: newScenarioData.id,
                    method: "edit",
                  },
                });
                GL.notify.success({
                  title: t("answer_success"),
                  text: t("scenarios_copied"),
                });
              },
              (err) => {
                GL.notify.error({
                  title: t("answer_error"),
                  text: t("scenarios_not_copied"),
                });
                console.log("err -->", err);
              }
            );
          }
        });
      },
    },
  };
  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/companies/add",
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/companies/multiDelete",
      },
    },
    settings: {
      id: pageId,
      type: "data",
      data: risksList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "+ " + t("global_create", { name: t("scenario") }),

      createNewRoute: "add-risk",
      state: { method: "create" },
      // ! settings for continue editing button -------------------------------------------------------------------
      editState: { method: "edit", id: reduxRisk.id },
      editButtonText: t("global_continue_edit"),
      editDisabled: reduxRisk.id ? false : true,
      // ! end settings for continue editing button ----------------------------------------------------------------
      // ! settings for risk table configs -------------------------------------------------------------------------
      scenarioConfigs: true,
      scenarioConfigRoute: "scenario-config",
      // ! end settings for risk table configs ---------------------------------------------------------------------
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 100,
        formatter: function (cell) {
          return GL.setSelectStatus(cell);
        },
      },
      {
        title: t("global_name"),
        field: "name",
      },
      {
        title: t("global_code"),
        field: "code",
      },
      {
        title: "Risk əmsalı",
        field: "coefficient",
        formatter: (cell) => {
          var d = cell.getData();
          var veri = d["coefficient"];
          var color = GL.getRiskColor(veri);
          return `<span style="display: flex; flex-direction: row; align-items: center;">${veri} <div style="background-color:${color}; width:10px; height:10px; margin-left:5px; border-radius:100%;"></div></span>`;
        },
      },
      {
        title: "Status",
        field: "status",
        align: "center",
        formatter: (cell) => {
          var d = cell.getData();
          var veri = d["status"];
          return `<div class="ant-tag ${
            veri == true ? "ant-tag-green" : "ant-tag-volcano"
          } css-yp8pcc" style="border-radius: 10px;">${
            veri == true ? t("global_active") : t("global_deactive")
          }</div>`;
        },
      },
      {
        title: t("global_qeyd"),
        field: "note",
      },
      {
        title: "URL",
        field: "url",
      },
      {
        title: t("stages_count"),
        field: "stagesCount",
      },
      {
        title: t("parameters_count"),
        field: "parametersCount",
      },
      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        id: {
          dataType: "number",
          placeholder: t("global_search"),
        },
        name: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        code: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        coefficient: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        status: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        note: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        url: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        stages: {
          dataType: "number",
          placeholder: t("global_search"),
        },
        parametres: {
          dataType: "number",
          placeholder: t("global_search"),
        },

        operations: {},
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {},
    },
  };

  const getValidArray = (data) => {
    return data.map((element) => {
      return {
        ...element,
        coefficient: element.precedent,
        stages: 0,
        parametres: 0,
      };
    });
  };

  function getRiskList() {
    setLoading(true);

    api.getData(
      mainUrl.list,
      (data) => {
        var d = getValidArray(data);
        //d = d.reverse();
        setRisksList(d);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  }
  
  useEffect(() => {
    getRiskList();
  }, []);

  return (
    <>
      <div style={containerStyle}>
        <div className="main-layout-wrapper__head">
          <div className="simple-head">
            <h1>{t("scenarios")}</h1>
            <span>{t("scenario_desc")}</span>
          </div>
        </div>
        {loading ? <Skeleton active /> : <SuperTable data={options} />}
      </div>
      {showCsvModal && (
        <CsvModal
          showModal={showCsvModal}
          setShowModal={setShowCsvModal}
          csvModalData={csvModalData}
          scenarioCode={scenarioCode}
        />
      )}
    </>
  );
}

export default PreRisk;
