import "./FormSubmit.scss";
import React from "react";
import { useTranslation } from "react-i18next";
const FormSubmit = React.forwardRef((props) => {
  const { t } = useTranslation();
  const { save, cancel, edit, type } = props.myEvents;

  return (
    <div className="form-submit">
      <button onClick={cancel} className="form-submit__cancel">
        {t("cancel")}
      </button>
      <button
        type="button"
        onClick={type == "create" ? save : edit}
        className="form-submit__submit"
      >
        {type == "create" ? t("save") : t("save_changes")}
      </button>
    </div>
  );
});

export default FormSubmit;
