import React, { useEffect, useState } from "react";
import { Row, Col, Button, Skeleton } from "antd";
import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import SelectBox from "components/Elements/FileUploadForm/Form/SelectBox";
import DateTimePicker from "components/Elements/FileUploadForm/Form/DatePicker";
import { SearchOutlined } from "@ant-design/icons";
import InfoWidget from "components/Elements/InfoWidget/InfoWidget";
import MessagesTable from "components/Elements/MessagesTable/MessagesTable";
import { useSelector } from "react-redux";
import Resend from "models/Resend";
import { useHistory } from "react-router-dom";

const titles = {
  unallocatedRisks: "Bölüşdürülməmiş risklər",
  risksInExecution: "İcrada olan risklər",
  returnRisks: "Geri verilənlər",
};

function Default({ addNewTab, addTabWithSubTab, deleteTab }) {
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const tab = queryParams.get("tab");
  const { activeRole } = useSelector(({ user }) => ({
    activeRole: user.data.roles?.find(({ status }) => status),
  }));

  const [isWidgetsLoading, setWidgetsLoading] = useState(false);
  const [widgets, setWidgets] = useState(null);

  const getResendRiskNumber = () => {
    setWidgetsLoading(true);
    Resend.getResendRiskNumber(activeRole?.id, (data) => {
      setWidgets(data);
      setWidgetsLoading(false);
    });
  };

  useEffect(() => {
    if (tab === "default") getResendRiskNumber();
  }, [tab]);

  return (
    <div className="risk-page">
      <div className="risk-page__elements">
        <form className="home-page__top-filters">
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label="VÖEN"
                type="number"
                required={false}
                placeholder="Daxil et"
              />
              <SelectBox
                label="Vergi orqanı"
                required={false}
                placeholder="Seç"
              />
            </Col>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label="Adı"
                type="text"
                required={false}
                placeholder="Daxil et"
              />
              <DateTimePicker label="İlk tarix" required={false} />
            </Col>
            <Col sm={24} md={8} lg={8}>
              <SelectBox
                label="Risk statusu"
                required={false}
                placeholder="Seç"
              />
              <DateTimePicker label="Son tarix" required={false} />
            </Col>
          </Row>
          <Row>
            <div className="home-page__top-filters-submit">
              <Button
                type="primary"
                icon={<SearchOutlined />}
                style={{ borderRadius: "4px", display: "inline-block" }}
              >
                Axtar
              </Button>
            </div>
          </Row>
        </form>
        <section className="home-page__widgets-wrapper">
          {isWidgetsLoading && <Skeleton active />}
          {widgets &&
            Object.entries(widgets)?.map(([key, value]) => {
              const title = titles[key];
              if (
                activeRole?.label === "inspector" &&
                key === "unallocatedRisks"
              )
                return "";
              return (
                <InfoWidget
                  key={key}
                  title={title}
                  infoNumber={value}
                  btnTxt="Siyahını göstər"
                  onClick={() => addNewTab(title, key)}
                />
              );
            })}
        </section>
        <section className="home-page__table-messages">
          <h2 className="table-title">Son mesajlar</h2>
          <div className="table-wrapper">
            <MessagesTable />
          </div>
        </section>
        <div></div>
      </div>
    </div>
  );
}

export default Default;
