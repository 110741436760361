import React, { useState, useRef, useEffect } from "react";
import SuperTable from "components/Elements/Tabulator/SuperTable";
import { GetPagePermission, GetTableSettings } from "hooks/permissions";
import GL from "libs/GL";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FileUploadRef from "components/Elements/FileUploadForm/FileUpload";
import Risks from "models/Risks";
import ProgressModal from "components/Elements/FileUploadForm/ProgressModal";
import { types } from "utils/base64Types";
const pageId = "riskfiles";

const RiskFiles = ({ riskFiles, riskID, setFieldValue }) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  //const permissions = GetPagePermission(6);
  const [fileFormat, setFileFormat] = useState("");
  const [percent, setPercent] = useState(0);
  const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);

  const tableSettings = GetTableSettings(pageId);

  function onOpenFileWindow(file) {
    setFileFormat(() => file);
    setTimeout(() => {
      fileInputRef.current.click();
    }, 500);
  }

  function onChangeFileInput(form) {
    setIsOpenDownloadModal(true);
    Risks.uploadRiskFile(
      riskID,
      form,
      (response) => {
        setFieldValue("info.riskFiles", [...riskFiles, response]);
        GL.notify.success({ text: t("risk_files_success") });
        setPercent(0);
        setIsOpenDownloadModal(false);
      },
      () => {
        GL.notify.error({ text: t("risk_file_error") });
        setPercent(0);
        setIsOpenDownloadModal(false);
      },
      (progress) => {
        const { loaded, total } = progress;
        const percentCompleted = Math.round((loaded * 100) / total);
        setPercent(() => percentCompleted);
      }
    );
  }

  const operatorSet = {
    download: {
      status: true,
      click: (data) => {
        setIsOpenDownloadModal(true);
        Risks.downloadRiskFile(
          data.id,
          (response) => {
            const dataType = data.name.match(/\.[a-zA-Z0-9]+$/);

            if (dataType) {
              var a = document.createElement("a");
              a.href = `data:${types[dataType[0]]};base64,${response}`;
              a.download = data.name;
              a.click();
              setPercent(0);
              setIsOpenDownloadModal(false);
            } else {
              GL.notify.error({ text: t("risk_file_error") });
            }
          },
          (error) => {
            console.log(error);
            setPercent(0);
            setIsOpenDownloadModal(false);
          },
          (progress) => {
            const { loaded, total } = progress;
            const percentCompleted = Math.round((loaded * 100) / total);
            setPercent(() => percentCompleted);
          }
        );
      },
    },
    delete: {
      status: true,
      click: function (data) {
        GL.yesNo(t("risk_files_delete_warning"), (isDelete) => {
          if (!isDelete) return;
          Risks.deleteRiskFile(
            riskID,
            data.id,
            () => {
              const updateRiskFiles = riskFiles?.filter(
                ({ id }) => id !== data.id
              );
              setFieldValue("info.riskFiles", updateRiskFiles);
              GL.notify.success({ text: t("risk_files_delete_success") });
            },
            () => {
              GL.notify.error({ text: t("answer_unsuccess") });
            }
          );
        });
      },
    },
  };

  const [options, setOptions] = useState({
    permissions: {},
    settings: {
      id: pageId,
      type: "data",
      data: riskFiles,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      columnSort: [], //tableSettings.columnSort,
      // ! create button settings -----------------------------------------
      createIsDisabled: true,
      uploadFileDisabled: true,
      uploadFileFormSettings: {
        forms: [
          {
            type: "simpleselect",
            field: "fileFormat",
            value: "",
            label: "Sənəd tipi seçin",
            placeholder: "Sənəd tipi seçin",
            dataType: "string",
            data: [
              { value: ".jpeg", label: "JPEG" },
              { value: ".jpg", label: "JPG" },
              { value: ".pdf", label: "PDF" },
              { value: ".xls,.xlsx", label: "Excel" },
              { value: ".doc,.docx", label: "Word" },
              { value: ".ppt,.pptx", label: "Power Point" },
              { value: ".txt", label: t("text") },
            ],
            validation: Yup.string().required(
              t("risk_files_select_document_type")
            ),
          },
        ],
        callback: (data) => {
          if (!data.fileFormat) return;
          onOpenFileWindow(data.fileFormat);
        },
      },
    },
    columns: [
      {
        title: t("file_name"),
        field: "name",
      },
      {
        title: t("global_qeyd"),
        field: "note",
      },
      {
        title: t("upload_date"),
        field: "uploadDate",
        formatter: (cell) => {
          var d = cell.getData();
          var tar = d["uploadDate"];
          if (tar !== null) {
            var tar2 = GL.datetimeToString(tar);
            return tar2;
          } else {
            return "";
          }
        },
      },

      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {},
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: [], //tableSettings.hideColumns,
      columnSort: [], //tableSettings.columnSort,
      rowColor: {
        field: "priority",
        type: "range",
        rules: {
          min: 0,
          max: 10,
          ranges: [
            { max: 6, color: "#FFDD263d" },
            { max: 7, color: "#FFB0343d" },
            { max: 8, color: "#FF681C3d" },
            { max: 9, color: "#DF26383d" },
            { max: 10, color: "#8638e53d" },
          ],
        },
      },
    },
  });

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      settings: { ...prevOptions.settings, data: riskFiles },
    }));
  }, [riskFiles]);

  return (
    <div className="risk-page">
      <h1 className="risk-page__title">{t("risk_files_title")}</h1>
      <Row gutter={24}>
        <Col span={24}>
          <SuperTable data={options} />
          <FileUploadRef
            ref={fileInputRef}
            fileFormat={fileFormat}
            onChangeFileInput={onChangeFileInput}
          />
          <ProgressModal percent={percent} isModalOpen={isOpenDownloadModal} />
        </Col>
      </Row>
    </div>
  );
};

export default RiskFiles;
