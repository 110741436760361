import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col, Select, Divider, Modal, Collapse, Switch } from "antd";
import { LinkOutlined, RightOutlined } from "@ant-design/icons";

import FormSubmit from "components/Elements/FormSubmit/FormSubmit2";
import PageTitle from "components/Elements/PageTitle/PageTitle";
import { roles as rolesFormInitData } from "const/initialDatas";
import Roles from "models/Roles";
import { setRolesData } from "redux/actions/index";
import { useTranslation } from "react-i18next";

function RolesEdit(props) {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const history = useHistory();
  const pageState = props.location.state;
  const rolesRedux = useSelector((state) => state.roles);

  // ! Dev: Aslan :
  const method = pageState?.method || "";
  const id = pageState?.id || "";
  // ! "method" variable comes from source page. Has value of "create" or "edit"
  // ! "id" variable comes from source page. Has "id" of scenario
  // ! if "method" is edit, id is required

  const [showModal, setShowModal] = useState(false);

  var initialValues = rolesRedux;
  if (method === "create") {
    initialValues = rolesFormInitData;
  }

  if (method === "edit" || method === "create2") {
    initialValues = rolesRedux;
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("yup_name")),
    code: Yup.string().required(t("yup_code")),
    status: Yup.boolean().required(t("yup_select")),
    path: Yup.string().required(t("role_yup_url")),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (method === "create2") {
        Roles.CREATE(
          values,
          () => {
            formik.setValues(rolesFormInitData);
            history.push("/roles");
          },
          (err) => {
            console.log("err =>", err);
          }
        );
      }
      if (method === "edit") {
        Roles.EDIT(
          id,
          values,
          () => {
            formik.setValues(rolesFormInitData);
            history.push("/roles");
          },
          (err) => {
            console.log("err =>", err);
          }
        );
      }
    },
  });

  const submitForm = () => {
    formik.handleSubmit();
  };

  const resetFormik = () => {
    formik.setValues(rolesFormInitData);
  };

  const inputHasError = (string) => {
    const keys = string.split(".");
    let fieldTouched = formik.touched;
    let fieldErrors = formik.errors;
    keys.forEach((keyStr) => {
      fieldTouched = fieldTouched?.[keyStr];
      fieldErrors = fieldErrors?.[keyStr];
    });
    if (fieldTouched && fieldErrors) {
      return true;
    }
    return false;
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleOk = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const onChangePermission = (pegId, permId) => {
    const rolePermRelDtosCopy = JSON.parse(
      JSON.stringify(formik.values.rolePermRelDtos)
    );
    const changedPage = rolePermRelDtosCopy.find((page) => {
      return page.id === pegId;
    });
    const changedPerm = changedPage.permissions.find((perm) => {
      return perm.id === permId;
    });
    changedPerm.status = !changedPerm.status;
    formik.setFieldValue(`rolePermRelDtos`, rolePermRelDtosCopy);
  };

  useEffect(() => {
    if (method === "edit") {
      Roles.getRole(
        id,
        (data) => {
          console.log("row get by id ==>", data);
          const { permissions, ...rest } = data;
          formik.setValues({ ...rest, rolePermRelDtos: permissions });
        },
        (err) => {
          console.log("err ==>", err);
        }
      );
    } else if (method === "create") {
      Roles.getPagePermissions(
        (data) => {
          formik.setFieldValue("rolePermRelDtos", data);
        },
        (err) => {
          console.log("err =>", err);
        }
      );
    }
  }, []);

  useEffect(() => {
    dispatch(setRolesData(formik.values));
  }, [formik.values]);

  useEffect(() => {
    if (method === "create" && Object.keys(formik.touched).length) {
      history.replace({ state: { method: "create2" } });
    }
  }, [formik.touched]);

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={method == "edit" ? t("role_edit") : t("role_add")}
        small={t("roles_desc")}
      />

      <div className="risk-page">
        <h1 className="risk-page__title">{t("global_info")}</h1>
        <div className="risk-page__elements">
          <Row gutter={24}>
            <Col
              sm={24}
              md={8}
              lg={8}
            >
              {formik.values.id ? (
                // Dev: Aslan. Dont`t show id field if the value is empty
                <div className="input-box">
                  <span className="input-box__title">ID</span>
                  <input
                    type="number"
                    disabled={true}
                    className={
                      inputHasError("id")
                        ? "error input-disabled"
                        : " input-disabled"
                    }
                    value={formik.getFieldProps("id").value}
                  />
                  <div className="input-box__error-field">
                    {inputHasError("id") ? formik.errors.id : ""}
                  </div>
                </div>
              ) : null}

              <div className="input-box">
                <span className="input-box__title">{t("global_name")}</span>
                <input
                  type="text"
                  className={inputHasError("name") ? "error " : ""}
                  {...formik.getFieldProps("name")}
                />
                <div className="input-box__error-field">
                  {inputHasError("name") ? formik.errors.name : ""}
                </div>
              </div>

              <div className="input-box">
                <span className="input-box__title">{t("global_code")}</span>
                <input
                  type="text"
                  className={inputHasError("code") ? "error " : ""}
                  {...formik.getFieldProps("code")}
                />
                <div className="input-box__error-field">
                  {inputHasError("code") ? formik.errors.label : ""}
                </div>
              </div>

              <div className="input-box">
                <span className="input-box__title">{t("role_path")}</span>
                <input
                  type="text"
                  className={inputHasError("path") ? "error " : ""}
                  {...formik.getFieldProps("path")}
                />
                <div className="input-box__error-field">
                  {inputHasError("path") ? formik.errors.path : ""}
                </div>
              </div>

              <div className="input-box">
                <span className="input-box__title">{t("global_status")}</span>
                <Select
                  options={[
                    { value: true, label: "Aktiv" },
                    { value: false, label: "Deaktiv" },
                  ]}
                  value={formik.values.status}
                  onChange={(value) => formik.setFieldValue("status", value)}
                  onBlur={formik.handleBlur("status")}
                  status={inputHasError("status") ? "error" : ""}
                />
                <div className="input-box__error-field">
                  {inputHasError("status") ? formik.errors.status : ""}
                </div>

                <div className="open-modal">
                  <span className="name">
                    <LinkOutlined />
                    <span>{t("role_selahiyet")}</span>
                  </span>
                  <button
                    className="btn"
                    onClick={handleShowModal}
                  >
                    <span>{t("role_table")}</span>
                    <RightOutlined />
                  </button>
                </div>
                <Divider />
              </div>
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit
            cancel={resetFormik}
            submit={submitForm}
          />
        </div>
      </div>

      <Modal
        title="Rol səlahiyyətləri"
        bodyStyle={{ height: 500 }}
        width={1000}
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        className="panel-modal"
      >
        <Collapse
          className="role-collapse"
          accordion
        >
          {formik.values.rolePermRelDtos.map((page, pageIdx) => {
            return (
              <Panel
                header={`${pageIdx + 1} - ${page.name}`}
                className="role-panel"
                key={`${page.id}`}
              >
                {page.permissions?.map((perm, permIdx) => {
                  return (
                    <div
                      className="panel-content"
                      key={perm.id}
                    >
                      <span>{perm.name}</span>
                      <Switch
                        checked={perm.status}
                        onChange={() => onChangePermission(page.id, perm.id)}
                        // onBlur={formik.handleBlur("rolePermRelDtos")}
                      />
                    </div>
                  );
                })}
              </Panel>
            );
          })}
          {/* {formik.values.rolePermRelDtos?.map((page, pageIdx) => {
            return (
              <Panel
                header={`${pageIdx + 1} - ${page.name}`}
                key={`${page.id}`}
                className="role-panel"
              >
                {page.permissions?.map((perm, permIdx) => {
                  return (
                    <div
                      className="panel-content"
                      key={perm.id}
                    >
                      <span>{perm.name}</span>
                      <Switch
                        onChange={() => onChangePermission(pageIdx, permIdx)}
                        checked={perm.isSelected}
                        onBlur={formik.handleBlur("rolePermRelDtos")}
                      />
                    </div>
                  );
                })}
              </Panel>
            );
          })} */}
        </Collapse>
      </Modal>
    </div>
  );
}

export default RolesEdit;
