import React, { useEffect, useMemo, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col, Skeleton } from "antd";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { setUsersInfo } from "../../../../redux/actions/usersActions";
import InputBox from "../../../Elements/FileUploadForm/Form/InputBox";
import SelectBox from "../../../Elements/FileUploadForm/Form/SelectBox";
import FormSubmit from "../../../Elements/FormSubmit/FormSubmit2";
import DateTimePicker from "../../../Elements/FileUploadForm/Form/DatePicker";
import FileUpload from "../../../Elements/FileUploadForm/Form/FileUpload";
import Company from "../../../../models/Company";
import Department from "../../../../models/Department";
import Sector from "../../../../models/Sector";
import Position from "../../../../models/Position";
import { useTranslation } from "react-i18next";

const STATUS_LIST = [
  { id: true, name: "Aktiv" },
  { id: false, name: "Deaktiv" }
];

const GENDER_LIST = [{ id: true, name: "Kişi" }, { id: false, name: "Qadın" }];

const Info = ({ method, formik, isLoading }) => {
  const { t } = useTranslation();
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [positionList, setPositionList] = useState([]);

  const initailFetchDepartmentRef = useRef(true);
  const initialFetchSectorsRef = useRef(true);

  const {
    values: { info: { companiesDto, departmentsDto } },
    setFieldValue
  } = formik;

  useMemo(() => {
    Company.getCompanyList(companies => setCompanyList(companies));
    Position.getPositionList(positions => setPositionList(positions));
  }, []);

  useEffect(
    () => {
      if (companiesDto && initailFetchDepartmentRef.current) {
        onDepartmentSelectFromCompanyID(companiesDto);
        initailFetchDepartmentRef.current = false;
      }
      if (departmentsDto && initialFetchSectorsRef.current) {
        onSectorSelectFromDepartmentID(departmentsDto);
        initialFetchSectorsRef.current = false;
      }
    },
    [companiesDto, departmentsDto]
  );

  function onDepartmentSelectFromCompanyID(companyId) {
    if (!initailFetchDepartmentRef.current) {
      setDepartmentList([]);
      setSectorList([]);
      setFieldValue("info.departmentsDto", "");
      setFieldValue("info.sectorsDto", "");
    }

    if (companyId)
      Department.getDepartmentsByCompanyID(companyId, departments =>
        setDepartmentList(departments)
      );
  }

  function onSectorSelectFromDepartmentID(departmentId) {
    if (!initialFetchSectorsRef.current) {
      setSectorList([]);
      setFieldValue("info.sectorsDto", "");
    }
    if (departmentId)
      Sector.getSectorsByDepartmentID(departmentId, sectors =>
        setSectorList(sectors)
      );
  }

  if (isLoading) return <Skeleton active />;

  return (
    <div className="risk-page">
      <div className="risk-page__elements">
        <h2>
          {t("global_info")}
        </h2>
        <Row gutter={24}>
          <Col sm={24} md={8} lg={8}>
            <InputBox
              label="ID"
              formik={formik}
              field="id"
              type="number"
              required={false}
              visibilty={method === "edit"}
              disabled={method === "edit"}
              isFake={true}
            />
            <InputBox
              label={t("global_name")}
              formik={formik}
              field="firstName"
              required={true}
            />
            <InputBox
              label={t("global_surname")}
              formik={formik}
              field="lastName"
              required={true}
            />
            <InputBox
              label={t("global_father_name")}
              formik={formik}
              field="patronymic"
              required={true}
            />
            <DateTimePicker
              label={t("global_birthday")}
              formik={formik}
              field="birthday"
              required={true}
            />
            <InputBox
              label={t("pin")}
              formik={formik}
              field="pin"
              required={true}
            />
          </Col>
          <Col sm={24} md={8} lg={8}>
            <SelectBox
              label={t("company")}
              formik={formik}
              field="companiesDto"
              data={companyList}
              onSelect={onDepartmentSelectFromCompanyID}
            />
            <SelectBox
              label={t("department")}
              formik={formik}
              field="departmentsDto"
              data={departmentList}
              onSelect={onSectorSelectFromDepartmentID}
              disabled={!Boolean(departmentList.length)}
            />
            <SelectBox
              label={t("sector")}
              formik={formik}
              field="sectorsDto"
              data={sectorList}
              disabled={!Boolean(sectorList.length)}
            />
            <SelectBox
              label={t("user_position")}
              formik={formik}
              field="positionDto"
              data={positionList}
            />
            <SelectBox
              label={t("global_gender")}
              formik={formik}
              field="gender"
              required={true}
              data={GENDER_LIST}
            />
          </Col>
          <Col sm={24} md={8} lg={8}>
            <InputBox
              label={t("phone_number")}
              formik={formik}
              field="phone"
              required={true}
            />
            <InputBox
              label={t("phone_corparate")}
              formik={formik}
              field="cooperationNumber"
            />
            <InputBox
              label={t("email_address")}
              formik={formik}
              field="email"
              required={true}
            />
            <SelectBox
              label={t("global_status")}
              formik={formik}
              field="isActive"
              required={true}
              data={STATUS_LIST}
            />
            <FileUpload
              label={t("global_photo")}
              formik={formik}
              field="photoPath"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Info;
