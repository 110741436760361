import React, { useMemo, useState } from "react";
import { Row, Col, Radio, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Roles from "models/Roles";
import SelectBox from "../../../Elements/FileUploadForm/Form/SelectBox";
import FormErrorHandler from "../../../Elements/FileUploadForm/Form/FormErrorHandler";
import GL from "libs/GL";
import { useTranslation } from "react-i18next";
const RolesTab = ({ formik }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = formik;
  const [rolesData, setRolesData] = useState([]);

  useMemo(() => {
    Roles.getRolesList((roles) => setRolesData(roles));
  }, []);

  function onSelect(roleId, option = null) {
    if (!option) return;
    const oldList = values.info.roleList;
    const isRoot = oldList.some(({ root }) => root);
    const newOption = { ...option, root: !isRoot };
    setFieldValue("info.roleList", [...oldList, newOption]);
    if (!isRoot) onRadioButtonChange("userDefaultRole", roleId);
  }

  function onRemove(roleId) {
    GL.yesNo(t("role_sure_message"), (status) => {
      if (status) {
        const oldList = values.info.roleList;
        const oldValue = values.info.userDefaultRole;
        const removedList = oldList.filter(({ value }) => value !== roleId);
        setFieldValue("info.roleList", removedList);
        if (oldValue === roleId) onRadioButtonChange("userDefaultRole", "");
      }
    });
  }

  function onRadioButtonChange(name, value) {
    const field = `info.${name}`;
    setFieldValue(field, value);
  }

  return (
    <div className="risk-page">
      <div className="risk-page__elements">
        <h2>{t("menu_roles")}</h2>

        <Row gutter={24}>
          <Col sm={24} md={12} lg={12}>
            <SelectBox
              label={t("role_choose")}
              data={rolesData}
              formik={formik}
              field="roles"
              onSelect={onSelect}
              required={true}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Col span={12}>
              <FormErrorHandler
                formik={formik}
                field={`info.userDefaultRole`}
              />
            </Col>
          </Col>

          <Col span={12}>
            <Radio.Group
              name="userDefaultRole"
              defaultValue={1}
              className="radio-group__row"
              onChange={(event) =>
                onRadioButtonChange(event.target.name, event.target.value)
              }
              value={values?.info?.userDefaultRole}
            >
              {values.info?.roleList?.map((role) => (
                <div className="radio-group__item" key={role.value}>
                  <Radio value={role.value}>{role.label}</Radio>
                  <Button type="primary" onClick={() => onRemove(role.value)}>
                    <DeleteOutlined />
                  </Button>
                </div>
              ))}
            </Radio.Group>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RolesTab;
