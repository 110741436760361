import "../TopMenu/TopMenu.scss";

import { Modal } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import i18n from "i18n";

import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import { useSelector } from "react-redux";
import User from "models/User";
import GL from "libs/GL";

function UserInfoEdit({ showPasswordModal, closeModal }) {
  const { t } = i18n;
  const userIdRedux = useSelector(s => s.user.data.user.id);

  // ! ======================== FORMIK ==================== //
  const initialValues = {
    info: {
      old: "",
      new: "",
      newConfirm: ""
    }
  };

  const validationSchema = Yup.object().shape({
    info: Yup.object().shape({
      old: Yup.string().required("Dəyər daxil edin"),
      new: Yup.string().required("Dəyər daxil edin"),
      newConfirm: Yup.string()
        .required("Dəyər daxil edin")
        .oneOf([Yup.ref("new"), null], "Yeni şifrə ilə eyni olmalıdır")
    })
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      User.CHANGE_PASSWORD(
        userIdRedux,
        {
          old: values.info.old,
          new: values.info.new
        },
        _ => {
          GL.notify.success({
            title: t("answer_success"),
            text: "İstifadəçi şifrəsi dəyişdirildi"
          });
          closeModal();
        }
      );
    }
  });

  const resetFormik = () => {
    formik.resetForm();
  };

  const submitForm = () => {
    formik.handleSubmit();
  };

  const handleSubmit = () => {
    submitForm();
  };
  // ! ======================== FORMIK ==================== //

  const handleCancel = () => {
    resetFormik();
    closeModal();
  };

  return (
    <Modal
      open={showPasswordModal}
      title="Şifrə dəyişdir"
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      <div className="risk-page">
        <div className="risk-page__elements">
          <InputBox
            label="Hal hazırki şifrə"
            formik={formik}
            field="old"
            type="password"
            required={true}
          />
          <InputBox
            label="Yeni şifrə"
            formik={formik}
            field="new"
            type="password"
            required={true}
          />
          <InputBox
            label="Yeni şifrə təsdiqləyin"
            formik={formik}
            field="newConfirm"
            type="password"
            required={true}
          />
        </div>
      </div>
    </Modal>
  );
}

export default UserInfoEdit;
