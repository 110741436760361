import React from "react";
import { Layout } from "antd";
import { Typography } from "antd";
import { Space } from "antd";
import { WechatFilled } from "@ant-design/icons";
import "./RTMSMessenger.css";
import startchat from "../../../assets/img/startchat.svg";
const { Content } = Layout;
const { Title } = Typography;

const InitialView = () => {
  return (
    <Content>
      <Space
        direction="vertical"
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginTop: "35vh"
        }}
      >
        <img src={startchat} alt="start chatting icon" />
        <Title style={{ fontSize: "16px" }}>Mesajlaşmağa başla</Title>
      </Space>
    </Content>
  );
};

export default InitialView;
