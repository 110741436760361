import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Skeleton, Modal } from "antd";

import { GetPagePermission, GetTableSettings } from "hooks/permissions";
import GL from "libs/GL";
import SuperTable from "components/Elements/Tabulator/SuperTable";
import Enum from "models/Enum";
import EnumCategory from "models/EnumCategory";
import EmptyPage from "components/Elements/Skelethon/EmptyPage";
import api from "const/API2";
import { apiRoutes } from "const/apiroutes";
import { useTranslation } from "react-i18next";

const pageId = "enums";

function EnumCategories() {
  const { t } = useTranslation();
  const API = new api();
  const history = useHistory();
  const [enumsList, setEnums] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const permissions = GetPagePermission(20);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );

  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {
        history.push({
          pathname: "enum-categories/create",
          state: {
            info: {
              id: data.id,
              name: data.name,
            },
            method: "edit",
          },
        });
      },
    },
    delete: {
      status: permissions["delete"],
      click: function (rawData) {
        API.getData(
          `${apiRoutes.enums.search}${rawData.id}`,
          (apiData) => {
            if (apiData.length > 0) {
              Modal.error({
                title: t("error_cant_delete"),
                content: <span>{t("enum_cant_delete")}</span>,
                okText: t("global_gotit"),
              });
            } else {
              GL.yesNo(t("question_deleting"), (isOk) => {
                if (isOk) {
                  EnumCategory.DELETE(rawData.id, (result) => {
                    if (result) {
                      GL.notify.success({
                        title: t("answer_success"),
                        text: t("enum_category_deleted"),
                      });
                      getEnumsList();
                    } else {
                      GL.notify.error({
                        title: t("answer_error"),
                        text: t("enum_category_not_deleted"),
                      });
                    }
                  });
                }
              });
            }
          },
          (err) => {}
        );
      },
    },
    show: {
      status: permissions["show"],
      click: function (data) {},
    },
    copy: {
      status: false,
      click: function (data) {},
    },
  };
  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/enums/add",
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/enums/multiDelete",
      },
    },
    settings: {
      id: pageId,
      type: "data",
      data: enumsList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "+ " + t("global_create", { name: t("enum_category") }),
      createNewRoute: "enum-categories/create",
      state: {
        id: 0,
        name: "",
        eCategory: null,
        isActive: true,
        method: "create",
      },
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 70,

        formatter: function (cell) {
          return GL.setSelectStatus(cell);
        },
      },
      {
        title: t("global_name"),
        field: "name",
      },

      {
        title: t("global_operations"),
        field: "operations",
        width: 130,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        name: {
          dataType: "text",
          placeholder: t("global_search"),
        },
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {},
    },
  };

  const getEnumsList = () => {
    setLoading(true);
    EnumCategory.getEnumCategoriesList(
      (data) => {
        //data.reverse();
        setEnums(data);
        setLoading(false);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    getEnumsList();
  }, []);

  if (loading) {
    return <Skeleton active />;
  } else {
    if (enumsList.length == 0) {
      return (
        <EmptyPage
          route="enums/create"
          name={t("enum_categories")}
          state={options.settings.state}
        />
      );
    } else {
      return (
        <div style={containerStyle}>
          <div className="main-layout-wrapper__head">
            <div className="simple-head">
              <h1>{t("enum_categories")}</h1>
              <span>{t("enum_categories_desc")}</span>
            </div>
          </div>
          <SuperTable data={options} />
          <Modal
            title={t("enum_category_delete")}
            open={isModalOpen}
            // onOk={handleOk}
            // onCancel={handleCancel}
          >
            delete modal
          </Modal>
        </div>
      );
    }
  }
}

export default EnumCategories;
