import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col, Select, Table, Button, Input } from "antd";
import PageTitle from "../../Elements/PageTitle/PageTitle";
import FormSubmit from "../../Elements/FormSubmit/FormSubmit";
import { useHistory } from "react-router-dom";
import GL from "../../../libs/GL";
import User from "../../../models/User";
import Portfolio from "../../../models/Portfolio";
import InputBox from "../../Elements/FileUploadForm/Form/InputBox";
import SelectBox from "../../Elements/FileUploadForm/Form/SelectBox";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
function PortfoliosEdit(props) {
  debugger;
  const { t } = useTranslation();
  const history = useHistory();
  const [managerList, setManagerList] = useState([]);
  var state = props.location.state;

  const initialValues = {
    info: state.info
  };

  if (state.method === "copy") {
    state.method = "create";
  }

  const populateList = () => {
    User.getUserList(data => {
      setManagerList(data);
    });
  };

  useEffect(() => {
    populateList();
  }, []);

  var submitSettings = {
    type: state.method,
    save: async () => {
      if (state.info.id == 0) {
        debugger;
        var sendData = await GL.getFormikData(formik, [
          "name",
          "manager",
          "appointment",
          "managerDirection"
        ]);
        var clist = [];
        companyList.map(a => {
          clist.push({ tin: a.tin, name: a.name });
        });
        var data = {
          name: sendData["name"],
          appointment: sendData["appointment"],
          managerId:
            sendData["manager"] == null
              ? null
              : {
                  id: sendData["manager"]
                },
          managerDirection: sendData["managerDirection"],
          companies: clist
        };
        Portfolio.CREATE(data, res => {
          GL.notify.success({ text: t("answer_success") });
          history.push("/portfolios");
        });
      }
    },

    edit: async () => {
      debugger;
      if (state.info.id !== 0) {
        var sendData = await GL.getFormikData(formik, [
          "id",
          "name",
          "manager",
          "appointment",
          "managerDirection"
        ]);
        debugger;
        var clist = [];
        companyList.map(a => {
          clist.push({ tin: a.tin, name: a.name });
        });
        var data = {
          id: sendData["id"],
          name: sendData["name"],
          appointment: sendData["appointment"],
          managerId:
            sendData["manager"] == null
              ? null
              : {
                  id: sendData["manager"]
                },
          managerDirection: sendData["managerDirection"],
          companies: clist
        };
        Portfolio.UPDATE(data, res => {
          GL.notify.success({ text: t("answer_success") });
          history.push("/portfolios");
        });
      }
    },
    cancel: () => {
      // formRef.current.resetFields();
      history.push("/portfolios");
    }
  };

  const validation = Yup.object().shape({
    info: Yup.object().shape({
      id: Yup.number(t("yup_id")),
      name: Yup.string().required(t("yup_name")),
      appointment: Yup.string().required(t("portfolio_yup_appointment")),
      direction: Yup.boolean().nullable(),
      manager: Yup.string().required(t("portfolio_yup_kurator"))
    })
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      // onPostLogin(values, resetForm);
    }
  });

  const formik1 = useFormik({
    initialValues: {
      tin: "",
      name: ""
    },
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      // onPostLogin(values, resetForm);
    }
  });

  const removFromCompanyList = index => {
    GL.yesNo(t("question_deleting"), status => {
      if (status) {
        var list = [];
        for (var i = 0; i < companyList.length; i++) {
          if (i !== index) {
            list.push(companyList[i]);
          }
        }
        setCompanyList(list);
      }
    });
  };
  const addToCompanyList = () => {
    debugger;
    var tin = formik1.values.tin;
    var name1 = formik1.values.name;
    if (tin == "") {
      GL.notify.warning({
        title: t("error_missind"),
        text: t("error_voen")
      });
      return false;
    }
    if (name1 == "") {
      GL.notify.warning({
        title: t("error_missind"),
        text: t("error_muessise")
      });
      return false;
    }
    var newList = [];
    for (var i = 0; i < companyList.length; i++) {
      newList.push(companyList[i]);
    }
    var isHave = companyList.find(a => a.tin == tin);

    if (!isHave) {
      newList.push({
        tin: tin,
        name: name1,
        delete: (
          <Button
            style={{ width: "100%" }}
            onClick={addToCompanyList}
            type="primary"
          >
            <DeleteOutlined />
            {t("global_delete_it")}
          </Button>
        )
      });
      formik1.setFieldValue("tin", "");
      formik1.setFieldValue("name", "");
      setCompanyList(newList);
    } else {
      GL.notify.warning({
        title: t("global_same"),
        text: t("error_same_voen")
      });
      return false;
    }
  };

  const [companyList, setCompanyList] = useState(state.info.companies);

  const heYox = [
    { value: true, label: t("global_yes") },
    { value: false, label: t("global_no") }
  ];

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={state.method == "edit" ? t("portfolio_edit") : t("portfolio_add")}
        small={t("portfolio_desc")}
      />
      <div className="risk-page">
        <div className="risk-page__elements">
          <h2>
            {t("global_info")}
          </h2>
          <Row gutter={24}>
            <Col sm={24} md={12} lg={12}>
              <InputBox
                label="ID"
                formik={formik}
                field="id"
                type="number"
                required={false}
                visibilty={state.method == "edit" ? true : false}
                disabled={state.method == "edit" ? true : false}
                isFake={true}
              />
              <InputBox
                label={t("global_name")}
                formik={formik}
                field="name"
                required={true}
              />
              <InputBox
                label={t("portfolio_appointment")}
                formik={formik}
                field="appointment"
                required={true}
              />
              <SelectBox
                label={t("global_kurator")}
                formik={formik}
                required={true}
                field="manager"
                data={managerList}
                type="complex"
                settings={{
                  parser: { value: "id", label: "name" },
                  columns: ["id", "name", "email"]
                }}
              />
              <SelectBox
                label={t("portfolio_kurator_send2")}
                formik={formik}
                required={false}
                field="managerDirection"
                data={heYox}
                type="simple"
                settings={{
                  parser: { value: "value", label: "label" },
                  columns: []
                }}
              />
              <span className="input-box__title">
                {t("portfolio_voen_list")} *
              </span>
              <table style={{ width: "100%" }}>
                <tr>
                  <td width="5%">#</td>
                  <td width="35%">
                    <Input
                      value={formik1.values.tin}
                      onChange={value =>
                        formik1.setFieldValue("tin", value.currentTarget.value)}
                      id="tin"
                      placeholder="VÖEN Giriniz"
                      required={true}
                    />
                  </td>
                  <td width="35%">
                    <Input
                      value={formik1.values.name}
                      onChange={value =>
                        formik1.setFieldValue(
                          "name",
                          value.currentTarget.value
                        )}
                      id="tinname"
                      placeholder="Müəssisənin Adı Giriniz"
                      required={true}
                    />
                  </td>
                  <td width="25%">
                    <Button
                      style={{ width: "100%" }}
                      onClick={addToCompanyList}
                      type="default"
                    >
                      <PlusCircleOutlined /> {t("portfolio_voen_add")}
                    </Button>
                  </td>
                </tr>
              </table>
              <table style={{ width: "100%" }}>
                {companyList.map((a, s) => {
                  return (
                    <tr>
                      <td width="5%">
                        {s + 1}
                      </td>
                      <td width="35%" style={{ paddingLeft: "13px" }}>
                        {a.tin}
                      </td>
                      <td width="35%" style={{ paddingLeft: "13px" }}>
                        {a.name}
                      </td>
                      <td width="25%">
                        <Button
                          style={{ width: "100%" }}
                          onClick={removFromCompanyList.bind(this, s)}
                          type="primary"
                        >
                          <DeleteOutlined /> {t("global_delete_it")}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit myEvents={submitSettings} />
        </div>
      </div>
    </div>
  );
}

export default PortfoliosEdit;
