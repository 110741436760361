import React from "react";

const Warning = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12987 2.0709C7.03982 1.69399 8.01509 1.5 9 1.5C9.98491 1.5 10.9602 1.69399 11.8701 2.0709C12.7801 2.44781 13.6069 3.00026 14.3033 3.6967C14.9997 4.39314 15.5522 5.21993 15.9291 6.12987C16.306 7.03982 16.5 8.01509 16.5 9C16.5 9.98491 16.306 10.9602 15.9291 11.8701C15.5522 12.7801 14.9997 13.6069 14.3033 14.3033C13.6069 14.9997 12.7801 15.5522 11.8701 15.9291C10.9602 16.306 9.98491 16.5 9 16.5C8.01509 16.5 7.03982 16.306 6.12987 15.9291C5.21993 15.5522 4.39314 14.9997 3.6967 14.3033C3.00026 13.6069 2.44781 12.7801 2.0709 11.8701C1.69399 10.9602 1.5 9.98491 1.5 9C1.5 8.01509 1.69399 7.03982 2.0709 6.12987C2.44781 5.21993 3.00026 4.39314 3.6967 3.6967C4.39314 3.00026 5.21993 2.44781 6.12987 2.0709ZM9 3C8.21207 3 7.43185 3.15519 6.7039 3.45672C5.97595 3.75825 5.31451 4.20021 4.75736 4.75736C4.20021 5.31451 3.75825 5.97595 3.45672 6.7039C3.15519 7.43185 3 8.21207 3 9C3 9.78793 3.15519 10.5681 3.45672 11.2961C3.75825 12.0241 4.20021 12.6855 4.75736 13.2426C5.31451 13.7998 5.97595 14.2417 6.7039 14.5433C7.43185 14.8448 8.21207 15 9 15C9.78793 15 10.5681 14.8448 11.2961 14.5433C12.0241 14.2417 12.6855 13.7998 13.2426 13.2426C13.7998 12.6855 14.2418 12.0241 14.5433 11.2961C14.8448 10.5681 15 9.78793 15 9C15 8.21207 14.8448 7.43185 14.5433 6.7039C14.2417 5.97595 13.7998 5.31451 13.2426 4.75736C12.6855 4.20021 12.0241 3.75825 11.2961 3.45672C10.5681 3.15519 9.78793 3 9 3Z"
        fill="#DF2638"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6C9.41421 6 9.75 6.33579 9.75 6.75V9.75C9.75 10.1642 9.41421 10.5 9 10.5C8.58579 10.5 8.25 10.1642 8.25 9.75V6.75C8.25 6.33579 8.58579 6 9 6Z"
        fill="#DF2638"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11.25C9.41421 11.25 9.75 11.5858 9.75 12V12.0075C9.75 12.4217 9.41421 12.7575 9 12.7575C8.58579 12.7575 8.25 12.4217 8.25 12.0075V12C8.25 11.5858 8.58579 11.25 9 11.25Z"
        fill="#DF2638"
      />
    </svg>
  );
};

export default Warning;
