import React, { useState, useMemo } from "react";
import Attachment from "components/Elements/Icons/Attachment";
import Export from "components/Elements/Icons/Export";
const useButtons = (tools) => {
  const { isDisabled, onOpenFileModal, setIsOpenModal, handleSubmit, onOk } =
    tools;
  const buttons = useMemo(
    () => [
      {
        className: "btn-secondary",
        children: "Bağla",
        onClick: onOk,
      },
      {
        className: "btn-success",
        children: "Yadda saxla",
        onClick: handleSubmit,
        disabled: isDisabled,
      },
    ],
    [isDisabled]
  );

  const iconButtonList = useMemo(
    () => [
      {
        icon: <Attachment />,
        className:
          "btn-danger d-flex align-items-center justify-content-center",
        onClick: () => setIsOpenModal(true),
      },
      {
        icon: <Export />,
        className:
          "btn-danger d-flex align-items-center justify-content-center",
        onClick: onOpenFileModal,
        disabled: isDisabled,
      },
    ],
    [isDisabled]
  );

  const extraButtons = useMemo(
    () => [
      {
        icon: <Export />,
        className:
          "btn-primary d-flex align-items-center justify-content-center",
        children: "Export",
      },
    ],
    []
  );
  return { buttons, iconButtonList, extraButtons };
};

export default useButtons;
