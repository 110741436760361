import { Row, Col, Select, Input, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setPython } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import FormSubmit from "components/Elements/FormSubmit/FormSubmit2";
import { python } from "const/initialDatas";
import { apiRoutes } from "const/apiroutes";
import API from "const/API2";
import GL from "libs/GL";

import { highlight, languages } from "prismjs";
import "prismjs/components/prism-python";
import "prismjs/themes/prism.css";
import SimpleCodeEditor from "react-simple-code-editor";

function Phyton(props) {
  const api = new API();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { formik, resetFormik, submitForm, inputHasError } = props;

  const scenarioPythonRedux = useSelector(
    (s) => s.risk.stages.addStagesForm.python
  );
  const pythonRedux = useSelector((s) => s.python);

  const [fileOptions, setFileOptions] = useState([]);
  const [method, setMethod] = useState("");

  let initialValues = scenarioPythonRedux;
  if (method === "create" || method === "edit" || method === "touched") {
    initialValues = pythonRedux;
  }

  const pyFormik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      newName:
        method === "create"
          ? Yup.string().required("Fayl adı daxil edin")
          : Yup.string(),
    }),
    onSubmit: (values) => {
      if (method === "create") {
        postFile(values);
      }
      if (method === "edit") {
        putFile(values);
      }
    },
  });

  const resetPyFormik = () => {
    pyFormik.setValues(python);
  };

  const submitPyForm = () => {
    pyFormik.handleSubmit();
  };

  const pyInputHasError = (string) => {
    const keys = string.split(".");
    let fieldTouched = pyFormik.touched;
    let fieldErrors = pyFormik.errors;
    keys.forEach((keyStr) => {
      fieldTouched = fieldTouched?.[keyStr];
      fieldErrors = fieldErrors?.[keyStr];
    });

    if (fieldTouched && fieldErrors) {
      return true;
    }

    return false;
  };

  const getall = () => {
    api.getData(
      apiRoutes.python.list,
      (data) => {
        const fileOptionsData = data.map((file) => ({
          label: file.name,
          value: file.id,
        }));
        setFileOptions(fileOptionsData);
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const postFile = (values) => {
    const postBody = { name: values.newName, code: values.code };
    api.postData(
      apiRoutes.python.post,
      postBody,
      (_) => {
        getall();
        setMethod("");
        GL.notify.success({
          title: t("answer_success"),
          text: "Fayl yaradıldı",
        });
        resetPyFormik();
      },
      (err) => {
        GL.notify.error({
          title: t("answer_error"),
          text: "Fayl yaradıla bilmədi",
        });
        console.log("err", err);
      }
    );
  };

  const putFile = (values) => {
    const postBody = {
      name: values.newName,
      code: values.code,
    };
    const id = pyFormik.values.name;
    api.putData(
      `${apiRoutes.python.put}/${id}`,
      postBody,
      (data) => {
        getall();
        setMethod("");
        resetPyFormik();
        GL.notify.success({
          title: t("answer_success"),
          text: "Fayl dəyişdirildi",
        });
      },
      (err) => {
        GL.notify.error({
          title: t("answer_error"),
          text: "Fayl dəyişdirilə bilmədi",
        });
        console.log("err", err);
      }
    );
  };

  const getFilebyId = () => {
    if (method === "edit") {
      setMethod("");
    } else {
      setMethod("edit");
      const id = pyFormik.values.name;
      api.getData(
        `${apiRoutes.python.get}/${id}`,
        (data) => {
          pyFormik.setFieldValue("code", data.code);
          pyFormik.setFieldValue("newName", data.name);
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  };

  const handleSelect = (value) => {
    resetPyFormik();
    setMethod("");
    pyFormik.setFieldValue("name", value);
  };

  const createMode = () => {
    if (method === "create") {
      setMethod("");
    } else {
      resetPyFormik();
      setMethod("create");
    }
  };

  useEffect(() => {
    getall();
    pyFormik.setFieldTouched("setFormikTouched");
  }, []);

  useEffect(() => {
    dispatch(setPython(pyFormik.values));
    const { newName, ...dataForFormik } = pyFormik.values;
    formik.setFieldValue("stages.addStagesForm.python", dataForFormik);
  }, [pyFormik.values]);

  useEffect(() => {
    if (method === "" && Object.keys(pyFormik.touched).length) {
      setMethod("touched");
    }
  }, [pyFormik.touched]);

  return (
    <div className="risk-page">
      <h1 className="risk-page__title">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>{`${t("stages_add_stage")} - Python`}</div>
          <div>
            <Button onClick={createMode}>Yeni fayl</Button>
          </div>
        </div>
      </h1>
      <div className="risk-page__elements">
        <Row gutter={24}>
          <Col sm={24} md={18} lg={18}>
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <div style={{ width: "85%" }}>
                {method === "create" || method === "edit" ? null : (
                  <div className="input-box">
                    <span className="input-box__title">Fayl seçin</span>
                    <Select
                      options={fileOptions}
                      onChange={(value) => {
                        handleSelect(value);
                      }}
                      value={pyFormik.values.name}
                      onBlur={pyFormik.handleBlur("name")}
                      status={
                        inputHasError("stages.addStagesForm.python.name")
                          ? "error"
                          : ""
                      }
                    />
                    <div className="input-box__error-field">
                      {inputHasError("stages.addStagesForm.python.name")
                        ? formik.errors.stages.addStagesForm.python.name
                        : ""}
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingTop: "14px",
                }}
              >
                {pyFormik.values.name ? (
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={getFilebyId}
                  >
                    Redaktə et
                  </Button>
                ) : null}
              </div>
            </div>
            {method === "create" || method === "edit" ? (
              <div
                className="input-box"
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "16px",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "100%", marginBottom: "16px" }}>
                  <span className="input-box__title">Fayl adı</span>
                  <input
                    type="text"
                    className={pyInputHasError("newName") ? "error" : ""}
                    {...pyFormik.getFieldProps("newName")}
                  />
                  <div className="input-box__error-field">
                    {pyInputHasError("newName") ? pyFormik.errors.newName : ""}
                  </div>
                </div>
              </div>
            ) : null}
          </Col>
        </Row>
        <Row gutter={24}>
          {method === "create" || method === "edit" ? (
            <Col sm={24} md={18} lg={18}>
              <div style={{ width: "100%" }}>
                Kodu daxil edin
                <SimpleCodeEditor
                  value={pyFormik.values.code}
                  onValueChange={(code) => pyFormik.setFieldValue("code", code)}
                  highlight={(code) => highlight(code, languages.python)}
                  padding={16}
                  style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 14,
                    width: "100%",
                    border: "1px solid #ebedf0",
                    boxShadow: "0px 16px 40px -12px rgba(171, 186, 201, 0.2)",
                  }}
                />
                <div
                  style={{
                    marginBlock: "16px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button type={"primary"} onClick={submitPyForm}>
                    Faylı yadda saxla
                  </Button>
                </div>
              </div>
            </Col>
          ) : null}
        </Row>
      </div>

      <div className="risk-page__submittion">
        <FormSubmit cancel={resetFormik} submit={submitForm} />
      </div>
    </div>
  );
}

export default Phyton;
