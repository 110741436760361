import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { GetPagePermission, GetTableSettings } from "hooks/permissions";
import SuperTable from "components/Elements/Tabulator/SuperTable";
import GL from "libs/GL";

const pageId = "risk-appointment";

function RiskAppointment(props) {
  const { t } = useTranslation();
  const permissions = GetPagePermission(2);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );
  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {},
    },
    delete: {
      status: permissions["delete"],
      click: function (data) {},
    },
    show: {
      status: permissions["show"],
      click: function (data) {},
    },
    copy: {
      status: permissions["copy"],
      click: function (data) {},
    },
  };
  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/companies/add",
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/companies/multiDelete",
      },
    },
    settings: {
      id: pageId,
      type: "url",
      data: "http://localhost:8000/risk-appointment",
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "+ " + t("global_create", { name: t("appointment") }),
      createNewRoute: "risk-appointment/edit",
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 20,

        formatter: function (cell) {
          return GL.setSelectStatus(cell);
        },
      },
      {
        title: t("global_name"),
        field: "name",
      },
      {
        title: t("global_totalname"),
        field: "total_name",
      },
      {
        title: t("global_code"),
        field: "code",
      },
      {
        title: t("appointment_top"),
        field: "top_destination",
      },
      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        id: {
          dataType: "number",
          placeholder: t("global_search"),
        },
        name: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        total_name: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        code: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        top_destination: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        operations: {},
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {},
    },
  };
  return (
    <>
      <div style={containerStyle}>
        <div className="main-layout-wrapper__head">
          <div className="simple-head">
            <h1>{t("scenarios")}</h1>
            <span>{t("scenario_desc")}</span>
          </div>
        </div>
        <SuperTable data={options} />
      </div>
    </>
  );
}

export default RiskAppointment;
