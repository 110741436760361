export const URLQueryMethods = ({
  set,
  deleteParam,
  searchParamsHandler,
  history,
}) => {
  const setQueryType = set?.setQueryType;
  const setQueryValue = set?.setQueryValue;

  const deleteQueryType = deleteParam?.deleteQueryType;

  if (set && set.length) {
    set.forEach((setParams) => {
      const { setQueryType, setQueryValue } = setParams;
      if (setQueryType && setQueryValue) {
        searchParamsHandler.set(setQueryType, setQueryValue);
      }
    });
  } else if (setQueryType && setQueryValue) {
    searchParamsHandler.set(setQueryType, setQueryValue);
  }

  if (deleteParam && deleteParam.length) {
    deleteParam.forEach((deleteParamItem) => {
      const { deleteQueryType } = deleteParamItem;
      if (deleteQueryType) {
        searchParamsHandler.delete(deleteQueryType);
      }
    });
  } else if (deleteQueryType) {
    searchParamsHandler.delete(deleteQueryType);
  }

  const newQueryString = searchParamsHandler.toString();

  history.replace({
    pathname: history.location.pathname,
    search: newQueryString,
  });
};
