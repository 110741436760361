import "./ColorSelect.scss";

import React from "react";
import { Select } from "antd";

import { folderColorPalette } from "../../const/folderColorPalette";

function ColorSelect(props) {
  const { folderColor, onChange, onClear } = props.folderColorProps;
  return (
    <Select
      className={folderColor !== null ? "color-select active" : "color-select"}
      defaultValue={null}
      allowClear
      onClear={onClear}
      value={folderColor}
      onChange={onChange}
    >
      <Select.Option disabled value={null}>
        Rəng
      </Select.Option>
      {folderColorPalette.map((color) => (
        <Select.Option value={color}>
          <div
            style={{
              height: "24px",
              width: "24px",
              borderRadius: "50px",
              background: color,
            }}
          />
        </Select.Option>
      ))}
    </Select>
  );
}

export default ColorSelect;
