import API from "../const/API2";
import { apiRoutes } from "../const/apiroutes";
import GL from "../libs/GL";
import i18n from "i18n";

const { t } = i18n;
const api = new API();

class Roles {
  static async GET_LANG(lang, callback, errCallback) {
    api.getData(apiRoutes.languages.put + "/lang/" + lang, callback, (err) => {
      GL.notify.error({
        title: t('answer_unsuccess'),
        text: t('language_not_found'),
      });
      if (!errCallback) return;
      errCallback(err);
    });
  }

  static async LIST(callback, errCallback) {
    api.getData(apiRoutes.languages.list, callback, (err) => {
      GL.notify.error({
        title: t('answer_unsuccess'),
        text: t('language_not_found'),
      });
      if (!errCallback) return;
      errCallback(err);
    });
  }

  static async GET(id, callback, errCallback) {
    api.getData(`${apiRoutes.languages.get}/${id}`, callback, (err) => {
      GL.notify.error({
        title: t('answer_unsuccess'),
        text: t('language_not_found'),
      });
      if (!errCallback) return;
      errCallback(err);
    });
  }

  static async CREATE(postData, callback, errCallback) {
    api.postData(
      apiRoutes.languages.post,
      postData,
      (data) => {
        GL.notify.success({
          title: t("answer_success"),
          text: "Dil sazlaması yaradıldı",
        });
        callback(data);
      },
      (err) => {
        if (!errCallback) return;
        GL.notify.error({
          title: t('answer_unsuccess'),
          text: t('language_not_create'),
        });
        errCallback(err);
      }
    );
  }

  static async EDIT(id, body, callback, errCallback) {
    api.putData(
      `${apiRoutes.languages.put}/${id}`,
      body,
      (data) => {
        GL.notify.success({
          title: t("answer_success"),
          text: "Dil sazlaması dəyişdirildi",
        });
        callback(data);
      },
      (err) => {
        if (!errCallback) return;
        GL.notify.error({
          title: t('answer_unsuccess'),
          text: t('language_not_edited'),
        });
        errCallback(err);
      }
    );
  }

  static async DELETE(id, callback, errCallback) {
    api.deleteData(
      `${apiRoutes.languages.delete}/${id}`,
      (data) => {
        GL.notify.success({
          title: t("answer_success"),
          text: "Dil sazlaması silindi",
        });
        callback(data);
      },
      (err) => {
        if (!errCallback) return;
        GL.notify.error({
          title: t('answer_unsuccess'),
          text: t('language_not_deleted'),
        });
        errCallback(err);
      }
    );
  }
}

export default Roles;
