import React from "react";
import { Select, Space } from "antd";
import RenderFileOrFolderIcon from "../LibraryTable/RenderFileOrFolderIcon";
import { FolderOpenOutlined, StarOutlined } from "@ant-design/icons";

const { Option } = Select;

function FileTypeSelect({ onChange, onClear, filterType }) {
  return (
    <Select
      className={filterType !== null ? "active" : ""}
      allowClear
      onChange={onChange}
      onClear={onClear}
      value={filterType}
    >
      <Option key="null" disabled value={null}>
        <span>Növü</span>
      </Option>
      <Option key="1" value="1">
        <Space>
          <span>
            <RenderFileOrFolderIcon type="application/pdf" />
          </span>
          <span>PDF</span>
        </Space>
      </Option>
      <Option key="2" value="2">
        <Space>
          <span>
            <RenderFileOrFolderIcon type="application/vnd.ms-excel" />
          </span>
          <span>Excel</span>
        </Space>
      </Option>
      <Option key="3" value="3">
        <Space>
          <span>
            <RenderFileOrFolderIcon type="application/msword" />
          </span>
          <span>Word</span>
        </Space>
      </Option>
      <Option key="4" value="4">
        <Space>
          <span>
            <RenderFileOrFolderIcon type="text/plain" />
          </span>
          <span>Text</span>
        </Space>
      </Option>
      <Option key="5" value="5">
        <Space>
          <span>
            <FolderOpenOutlined />
          </span>
          <span>Qovluqlar</span>
        </Space>
      </Option>
    </Select>
  );
}

export default FileTypeSelect;
