import { t } from "i18next";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Modal, Skeleton, Row, Col, Button } from "antd";

import Risks from "models/Risks";
import { risk } from "const/initialDatas";
import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import SelectBox from "components/Elements/FileUploadForm/Form/SelectBox";

function ShowRisk({ showModal, setShowModal, modalData }) {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const initialValues = risk;

  const formik = useFormik({
    initialValues,
  });

  const cancel = () => {
    setShowModal(false);
  };

  const getRiskById = (riskId) => {
    setIsLoading(true);
    Risks.getRiskByID(riskId, (data) => {
      const values = {
        ...data,
        division: data.hasWorkFlow ? 1 : 2,
      };

      formik.setValues({ info: { ...values } });
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (showModal && modalData) {
      getRiskById(modalData);
    }
  }, [showModal]);

  const { values } = formik;

  function onSearchVoen() {
    const voen = values?.info?.taxId;
    if (!voen) return;
    history.replace("/profile#profile-general", { voen });
    console.log(voen);
  }

  return (
    <Modal
      title={t("risk_large_info")}
      bodyStyle={{
        height: 500,
      }}
      width={900}
      open={showModal}
      onCancel={cancel}
      footer={[]}
    >
      <div
        className="risk-page"
        style={{
          marginTop: "16px",
          paddingBottom: "0",
        }}
      >
        {isLoading ? (
          <Skeleton active />
        ) : (
          <div className="risk-page__elements">
            <Row gutter={24}>
              <Col sm={24} md={12} lg={12}>
                <InputBox
                  label="ID"
                  formik={formik}
                  field="id"
                  type="number"
                  required={false}
                  visibilty={true}
                  disabled={true}
                  isFake={true}
                />
                <div className="d-flex justify-content-between align-items-end form-flex-group">
                  <InputBox
                    label={t("scenario")}
                    formik={formik}
                    field="name"
                    type="text"
                    required={false}
                    visibilty={true}
                    disabled={true}
                    isFake={true}
                  />
                  <Button type="primary">
                    <SearchOutlined />
                  </Button>
                </div>

                <InputBox
                  label={t("separating_cari")}
                  formik={formik}
                  field="status"
                  type="text"
                  required={false}
                  visibilty={true}
                  disabled={true}
                  isFake={true}
                />
                <InputBox
                  label={t("director")}
                  formik={formik}
                  field="executorFullName"
                  type="text"
                  required={false}
                  visibilty={true}
                  disabled={true}
                  isFake={true}
                />
                <div className="d-flex justify-content-between align-items-end form-flex-group">
                  <InputBox
                    label={t("voen")}
                    formik={formik}
                    field="taxId"
                    type="text"
                    required={false}
                    visibilty={true}
                    disabled={true}
                    isFake={true}
                  />
                  <Button type="primary" onClick={onSearchVoen}>
                    <SearchOutlined />
                  </Button>
                </div>

                <InputBox
                  label={t("activities_title")}
                  formik={formik}
                  field="testfield"
                  type="text"
                  required={false}
                  visibilty={true}
                  disabled={true}
                  isFake={true}
                />
                <InputBox
                  label={t("pirority")}
                  formik={formik}
                  field="testfield"
                  type="text"
                  required={false}
                  visibilty={true}
                  disabled={true}
                  isFake={true}
                />
              </Col>
              <Col sm={24} md={12} lg={12}>
                <InputBox
                  label={t("separating_precedent")}
                  formik={formik}
                  field="precedent"
                  type="text"
                  required={false}
                  visibilty={true}
                  disabled={true}
                  isFake={true}
                />
                <InputBox
                  label={t("risk_type")}
                  formik={formik}
                  field="riskType"
                  type="text"
                  required={false}
                  visibilty={true}
                  disabled={true}
                  isFake={true}
                />
                <InputBox
                  label={t("risk_group")}
                  formik={formik}
                  field="riskGroup"
                  type="text"
                  required={false}
                  visibilty={true}
                  disabled={true}
                  isFake={true}
                />
                <InputBox
                  label={t("createdat")}
                  formik={formik}
                  field="createdAt"
                  type="text"
                  required={false}
                  visibilty={true}
                  disabled={true}
                  isFake={true}
                />
                <InputBox
                  label={t("global_lastexecute")}
                  formik={formik}
                  field="lastExecuteDate"
                  type="text"
                  required={false}
                  visibilty={true}
                  disabled={true}
                  isFake={true}
                />
                <InputBox
                  label={t("global_kurator")}
                  formik={formik}
                  field="curator"
                  type="text"
                  required={false}
                  visibilty={true}
                  disabled={true}
                  isFake={true}
                />
                <SelectBox
                  label={t("global_division")}
                  formik={formik}
                  field="division"
                  data={[
                    { id: 1, name: t("scenario_workflow") },
                    { id: 2, name: t("risk_sperate_rule") },
                  ]}
                  disabled={true}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ShowRisk;
