import "./AddFolderModal.scss";
import API from "const/API2";
import { apiRoutes } from "const/apiroutes";
import React, { useState } from "react";
import { Modal } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Table, Button } from 'antd';

import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import FormErrorHandler from "components/Elements/FileUploadForm/Form/FormErrorHandler";
import { folderColorPalette } from "../../const/folderColorPalette";

const api = new API();

function AddFolderModal({ isOpen, closeModal, title, submitAddFolder, globalFolderId }) {
  const [fileList, setFileList] = useState([]);
  const [folderList, setFolderList] = useState([]);

  const columns = [
    {
      title: 'Dosya Adı',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Yükleme Durumu',
      dataIndex: 'status',
      key: 'status',
      render: (val, record) => {
        if(val==0){
          return (<span style={{color:'#03a9f4'}}>Secildi</span>);
        }else if(val>0 && val<100){
          return (<span style={{color:'#ffc107'}}>Yukleniyor</span>);
        }else if(val==100){
          return (<span style={{color:'#8bc34a'}}>Yuklendi</span>)
        }else{
          return  (<span style={{color:'#f44336'}}>Hata Var!</span>)
        }
      },
    },
  ];

  const formik = useFormik({
    initialValues: {
      info: {
        name: "",
        color: "",
        parentId:globalFolderId
      },
    },
    validationSchema: Yup.object().shape({
      info: Yup.object().shape({
        name: Yup.string().required(),
        color: Yup.string().required(),
      }),
    }),
    onSubmit: (values) => {
      api.postData("library/folder",
      values.info,
      (folder) => {
        createFileFolders(0,folderList,folder.folderId,values.info,true,()=>{
          submitAddFolder();
          closeModal();
        });
        //startLoad(0,fileList,folder.folderId);
      },(err)=>{
        submitAddFolder();
        closeModal();
      });
    },
  });

  const createFileFolders = (i,h,fid,info,go,callback) => {
    if(i<h.length){
      var hh = h[i];
      var newinfo = {
        name: hh.name,
        color: info.color,
        parentId:fid
      };
      api.postData("library/folder",
      newinfo,
      (folder) => {
        createFiles(0,hh.files,folder.folderId,()=>{
          if(hh.folders.length>0){
            createFileFolders(0,hh.folders,folder.folderId,newinfo,true,()=>{
              i++;
              createFileFolders(i,h,fid,info,true,callback);
            });
          }else{
            i++;
            createFileFolders(i,h,fid,info,true,callback);
          }
        });
      },(err)=>{
        i++;
        createFileFolders(i,h,fid,info,true,callback);
      });
    }else{
      if(go){
        callback();
      }
    }
  }

  const setStatus = (filename,status,arr) => {
    var newArr = [];
    arr.map((a)=>{
      if(a.name==filename){
        a.status=status;
      }
      newArr.push(a);
    });
    setFileList(newArr);
  }

  const createFiles = (i,arr,folderId,callback) => {
    if(i<arr.length){
      var f = arr[i];
      const formData = new FormData();
      formData.append("file", f.file);
      formData.append("fileName", f.file.name);
      setStatus(f.name,1,arr);
      api.postData(`${apiRoutes.library.postFile}?folderId=${folderId}`,formData,(file)=>{
        setStatus(f.name,100,arr);
        i++;
        createFiles(i,arr,folderId,callback);
      },(err)=>{
        setStatus(f.name,101,arr);
        i++;
        createFiles(i,arr,folderId,callback);
      })
    }else{
      if(callback!==undefined){
        callback();
      }
    }
  }

  const openFolderModal = () => {
    var input = document.createElement('input');
    input.type = 'file';
    input.webkitdirectory = true;
    input.mozdirectory = true;
    input.addEventListener('change', handleChange);
    input.click();
  }

  function findFoldersHiearchy(files) {
    const root = { name: '', folders: [], files: [] };
    files.forEach(file => {
    var filePath = file.file.webkitRelativePath
    const parts = filePath.split('/');
    let currentNode = root;
    var len = parts.length-1;
    parts.forEach((part, index) => {
      if (part !== '') {
        if(index<len){
          let folderNode = currentNode.folders.find(folder => folder.name === part);
          if (!folderNode) {
            folderNode = { name: part, folders: [], files: [] };
            currentNode.folders.push(folderNode);
          }
          currentNode = folderNode;
        }else{
          currentNode.files.push(file);
        }
      }
    });
  });

  return root.folders;
  }


  const handleChange = (event) => {
    const files = event.target.files;
    var arr = [];
    if(files.length>0){
      for(var i=0;i<files.length;i++){
        arr.push({name:files[i].name,size:files[i].size,status:0,file:files[i]});
      }
      setFileList(arr);
    }
    var hiearchy = findFoldersHiearchy(arr);
    setFolderList(hiearchy);
  };

  return (
    <Modal
      className="add-folder-modal"
      title={title}
      open={isOpen}
      onOk={formik.handleSubmit}
      onCancel={closeModal}
      bodyStyle={{
        marginTop: "30px",
      }}
      okText="Yukle"
      width={440}
    >
      <InputBox
        label="Adı"
        formik={formik}
        field="name"
        type="text"
        required={true}
      />

      <p className="input-title">Qovluq rengi</p>
      <div className="color-buttons" style={{marginBottom:'20px'}}>
        {folderColorPalette.map((color, idx) => {
          return (
            <button
              key={idx}
              style={{
                background: color,
              }}
              onClick={() => formik.setFieldValue("info.color", color)}
              className={formik.values.info?.color === color ? "active" : ""}
            />
          );
        })}
      </div>
      <div style={{textAlign:'right', paddingRight:'15px',marginTop:'25px',marginBottom:'10px'}}>
      <Button onClick={openFolderModal} type="primary">Yuklenecek Qovluku Sec
      {
        fileList.length>0 && (<span style={{fontWeight:'bold'}}> - {fileList.length} Adet Fayl Secildi</span>)
      }
      </Button>
      </div>
      {
        fileList.length>0 && (
        <Table
          dataSource={fileList}
          columns={columns}
          pagination={{
            pageSize: 5,
            showSizeChanger: true,
            pageSizeOptions: ['5','10', '20', '50', '100'],
          }}
        />
        )
      }
      
      <FormErrorHandler formik={formik} field="info.color" />
    </Modal>
  );
}

export default AddFolderModal;
