import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import PageTitle from "components/Elements/PageTitle/PageTitle";
import AntTabs from "components/Elements/Tabs/AntTabs";
import Info from "./Info/Info";
import Stages from "./Stages/Stages";
import Parameters from "./Parameters/Paramters";
import WorkFlow from "./WorkFlow/WorkFlow";

import { setRiskData } from "redux/actions/index";
import { scenario } from "const/initialDatas";
import { apiRoutes } from "const/apiroutes";
import API from "const/API2";
import GL from "libs/GL";
import { useTranslation } from "react-i18next";

function AddRisk(props) {
  const { t } = useTranslation();
  var api = new API();
  const dispatch = useDispatch();
  const history = useHistory();
  const pageState = props.location.state;
  const reduxRisk = useSelector((state) => state.risk);
  const [validState, setValidState] = useState(null);

  // ! Dev: Aslan :
  const method = pageState?.method || "";
  const id = pageState?.id || "";
  // ! "method" variable comes from source page. Has value of "create" or "edit"
  // ! "id" variable comes from source page. Has "id" of scenario
  // ! if "method" is edit, id is required

  const [activeTab, setActiveTab] = useState("");

  const addSenarioEndpoint = apiRoutes.scenarios.add;
  const getScenarioByIdEndpoint = apiRoutes.scenarios.get;
  const putScenarioEndpoint = apiRoutes.scenarios.put;
  const hash = history.location.hash;

  let initialValues = scenario;
  if (method === "create") {
    initialValues = scenario;
  }
  if (method === "edit" || method === "create2") {
    initialValues = reduxRisk;
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validState,
    onSubmit: (values, { resetForm }) => {
      if (method === "create2" && !values.id) {
        getScenarioId(values.info.info);
      }
      if (method === "edit") {
        // ! putScenario icine info.info yox hamsi atilmalidi
        putScenario(values.info.info, resetForm);
      }
    },
  });

  const resetFormik = () => {
    history.replace("/");
    formik.setValues(scenario);
  };

  const submitForm = () => {
    formik.handleSubmit();
  };

  // ! ======================================== Formik shorthands =====================================
  const repeatErrkValue = formik.values.info.info.repeatErr;
  // ! ================================= End Formik shorthands ======================================

  // ! =================================== Formik Enums =========================================
  const repeatErrEnum = {};
  if (repeatErrkValue) {
    repeatErrEnum.repeatLast = true;
    repeatErrEnum.repeatPer = true;
  } else {
    repeatErrEnum.repeatLast = false;
    repeatErrEnum.repeatPer = false;
  }
  // ! ======================================== End Formik Enums ===========================================

  function onTabsChange(keyStr) {
    history.push({ hash: keyStr, state: pageState });
  }

  function getScenarioId(values) {
    const formikValues = {
      ...values,
      usersDto: null,
      riskGroupDto: {
        id: values.riskGroup,
      },
      leaders: { userIds: values.leaders },
    };
    delete formikValues.curator;
    delete formikValues.riskGroup;
    !repeatErrEnum.repeatLast && (formikValues.repeatLast = null);
    !repeatErrEnum.repeatPer && (formikValues.repeatPer = null);

    api.postData(
      addSenarioEndpoint,
      formikValues,
      (res) => {
        formik.setFieldValue("id", res.id);
        history.replace({ hash: hash, state: { method: "edit", id: res.id } });
        GL.notify.success({
          title: t("answer_success"),
          text: t("scenario_created"),
        });
      },
      (err) => {
        GL.notify.error({
          title: t("answer_error"),
          text: t("scenario_not_created"),
        });
        console.log("err =>", err);
      }
    );
  }

  function getScenarioById(id) {
    api.getData(
      `${getScenarioByIdEndpoint}/${id}`,
      (data) => {
        const scenarioId = data.id;
        const riskGroup = data.riskGroupDto?.id;

        delete data.id;
        delete data.usersDto;
        delete data.riskGroupDto;
        delete data.defaultImportance;
        delete data.defaultPriority;
        data.leaders = data.leaders?.userIds || [];

        formik.setFieldValue("info.info", data);
        formik.setFieldValue("id", scenarioId);
        formik.setFieldValue("info.info.riskGroup", riskGroup);
        if (data.workFlow) {
          formik.setFieldValue("workFlow.workFlowList", data.workFlow);
        } else {
          formik.setFieldValue(
            "workFlow.workFlowList",
            scenario.workFlow.workFlowList
          );
        }
      },
      (err) => {
        GL.notify.error({
          title: t("answer_error"),
          text: t("scenario_not_found"),
        });
        console.log("err =>", err);
      }
    );
  }

  function putScenario(values) {
    const formikValues = {
      ...values,
      riskGroupDto: {
        id: values.riskGroup,
      },
      leaders: { userIds: values.leaders },
    };
    delete formikValues.curator;
    delete formikValues.riskGroup;
    !repeatErrEnum.repeatLast && (formikValues.repeatLast = null);
    !repeatErrEnum.repeatPer && (formikValues.repeatPer = null);

    api.putData(
      `${putScenarioEndpoint}/${id}`,
      formikValues,
      (_) => {
        getScenarioById(id);
        GL.notify.success({
          title: t("answer_success"),
          text: t("scenario_edited"),
        });

        if (hash === "#info") {
          formik.setValues(scenario);
          history.replace("/");
        }
      },
      (err) => {
        console.log("err -->", err);
        GL.notify.success({
          title: t("answer_success"),
          text: t("scenario_not_edited"),
        });
      }
    );
  }

  const checkFormikValidity = () => {
    const formikIsTouched = !!Object.keys(formik.touched).length;
    const formikHasErrors = !!Object.keys(formik.errors).length;
    return formikIsTouched && !formikHasErrors;
  };

  let isFormikCurrentlyValid = checkFormikValidity();

  const inputHasError = (string) => {
    const keys = string.split(".");
    let fieldTouched = formik.touched;
    let fieldErrors = formik.errors;
    keys.forEach((keyStr) => {
      fieldTouched = fieldTouched?.[keyStr];
      fieldErrors = fieldErrors?.[keyStr];
    });
    if (fieldTouched && fieldErrors) {
      return true;
    }
    return false;
  };

  function updateSchema() {
    setValidState(
      Yup.object().shape({
        info: Yup.object().shape({
          info: Yup.object().shape({
            riskType: Yup.string().required(t("yup_choose_value")),
            name: Yup.string().required(t("yup_name")),
            code: Yup.string().required(t("yup_choose_value")),
            status: Yup.string().required(t("yup_choose_value")),
            precedent: Yup.number()
              .max(10, t("scenario_yup_precedent_max"))
              .min(0, t("scenario_yup_precedent_min"))
              .required(t("scenario_yup_precedent")),
            riskGroup: Yup.string().required(t("yup_choose_value")),
            riskBreak: Yup.string().required(t("yup_choose_value")),
            executionPer: Yup.string().required(
              t("scenario_yup_execution_per")
            ),
            leaders: Yup.array().min(1, "En az birini secin"),
            execUserGroup: Yup.number().required("İstifadəçi qrupu seçin"),
          }),
        }),
      })
    );
  }

  const items = [
    {
      key: "#info",
      label: t("global_info"),
      children: (
        <Info
          formik={formik}
          resetFormik={resetFormik}
          submitForm={submitForm}
          inputHasError={inputHasError}
          formConfigs={repeatErrEnum}
        />
      ),
    },
    {
      key: "#parameters",
      label: t("scenario_parameters"),
      children: <Parameters />,
      disabled: !isFormikCurrentlyValid || !formik.values.id,
    },
    {
      key: "#stages",
      label: t("scenario_stages"),
      children: <Stages />,
      disabled: !isFormikCurrentlyValid || !formik.values.id,
    },
    {
      key: "#workFlows",
      label: t("scenario_workflow"),
      children: <WorkFlow />,
      disabled: !isFormikCurrentlyValid || !formik.values.id,
    },
  ];

  useEffect(() => {
    if (!method || !pageState) {
      history.replace("/");
      return;
    } else if (!hash) {
      history.replace({ hash: "info", state: pageState });
      return;
    }
    setActiveTab(hash);
  }, [hash]);

  useEffect(() => {
    dispatch(setRiskData(formik.values));
  }, [formik.values]);

  useEffect(() => {
    updateSchema();
  }, [formik.values.info.info.repeatErr]);

  useEffect(() => {
    formik.setFieldTouched("setFormikTouched");
    if (method === "edit") {
      getScenarioById(id);
    }
  }, []);

  useEffect(() => {
    if (method === "create" && Object.keys(formik.touched).length) {
      history.replace({ hash, state: { method: "create2" } });
    }
  }, [formik.touched]);

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={t("scenarios")}
        small={t("scenario_desc")}
        // isDeactive={formik.values.info.status == "deactive"}
      />
      <AntTabs
        items={items}
        activeTab={activeTab}
        onTabsChange={onTabsChange}
      />
    </div>
  );
}

export default AddRisk;
