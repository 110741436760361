import "./FormSubmit.scss";
import React from "react";
import { useTranslation } from "react-i18next";

function FormSubmit(props) {
  const { t } = useTranslation();
  const { cancel, submit, extraButtonOptions } = props;

  let extraButton;
  if (extraButtonOptions) {
    const { type, label, onClick } = extraButtonOptions;
    const buttonType = type || "button";
    extraButton = (
      <button
        type={buttonType}
        onClick={onClick}
        className="form-submit__extraButton"
      >
        {label}
      </button>
    );
  }

  return (
    <div className="form-submit">
      <button onClick={cancel} className="form-submit__cancel">
        {t("cancel")}
      </button>
      <button type="submit" onClick={submit} className="form-submit__submit">
        {t("save")}
      </button>
      {extraButton}
    </div>
  );
}

export default FormSubmit;
