import "./Tabulator.css";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { Skeleton } from "antd";

import Tabulators from "./tabulator-extension";
import "./DynamicTableCss.css";

var table = null;

const AsyncTabulator = (props) => {
  const { t } = useTranslation();
  var settings = props.settings;
  var columns = props.columns;
  var filtering = props.filtering;
  var tableSettings = props.pageSettings;
  var onPageSettingsChange = settings.onPageSettingsChange;
  const history = useHistory();
  const pathname = history.location.pathname;
  useEffect(() => {
    table = new Tabulators("#tablom-" + settings.id, {
      pagination: false,
      paginationSize: 10,
      paginationSizeSelector: [10, 25, 50, 75, 100],
      movableColumns: true,
      paginationButton: "left",
      paginationCounter: function (
        pageSize,
        currentRow,
        currentPage,
        totalRows,
        totalPages
      ) {
        return `${t("global_page")} : ${currentPage}/${totalPages}, ${t(
          "global_row"
        )} : ${totalRows}`;
        //return `${totalPages} Səhifədən axtar`;
      },
      data: settings.data,
      layout: "fitColumns",
      columns: columns,
      ajaxLoader: <Skeleton />,
    });

    table.on("tableBuilt", function (data) {
      // debugger;
      settings.table = table;
      setTimeout(function () {
        table.doBest(filtering);
        var divs = document.getElementsByClassName("tabulator-col");
        for (var i = 0; i < divs.length; i++) {
          var div = divs[i];
          div.style.height = "53px";
        }

        var pag = document.getElementsByClassName("tabulator-paginator");
        if (pag.length > 0) {
          if (pag[0].children.length > 5) {
            pag[0].children[0].innerHTML = t("global_count");
            pag[0].children[2].innerHTML = t("global_first");
            pag[0].children[3].innerHTML = t("global_prev");
            pag[0].children[5].innerHTML = t("global_next");
            pag[0].children[6].innerHTML = t("global_last");
          }
        }
      }, 500);
    });
  }, [columns, filtering, settings, pathname]);

  const onPageSizeChange = (value) => {
    onPageSettingsChange("currentSize", value);
  };

  const onPageChange = (value) => {
    onPageSettingsChange("currentPage", value);
  };

  const onRenderPaginationPages = () => {
    const buttons = Array.from(
      { length: tableSettings?.totalPages },
      (_, index) => {
        if (
          index >= tableSettings?.currentPage &&
          index <= tableSettings?.currentPage + 5
        ) {
          return (
            <button
              className={`tabulator-page ${
                tableSettings?.currentPage === index ? "active" : ""
              }`}
              type="button"
              aria-label={`Show Page ${index + 1}`}
              title={`Show Page ${index + 1}`}
              data-page={index}
              onClick={() => onPageChange(index)}
              key={index}
            >
              {index + 1}
            </button>
          );
        }
      }
    );
    return buttons;
  };

  return (
    <React.Fragment>
      <div
        id={"tablom-" + settings.id}
        style={{ backgroundColor: "white", border: "1px solid #efefef" }}
      ></div>
      <div
        id="tabulator-loading"
        className={`tabulator-loading ${
          tableSettings.isLoading ? "active" : ""
        }`}
      >
        <Skeleton />
      </div>
      <div id="tabulator-footer" className="tabulator-footer">
        <div
          id="tabulator-footer-contents"
          className="tabulator-footer-contents"
        >
          <span className="tabulator-page-counter">
            {t("global_page")} : {tableSettings?.currentPage + 1}/
            {tableSettings?.totalPages}, {t("global_row")} :{" "}
            {tableSettings?.totalElements}
          </span>
          <span className="tabulator-paginator">
            <label>{t("global_count")}</label>
            <select
              className="tabulator-page-size"
              aria-label="Page Size"
              title="Page Size"
              value={tableSettings.currentSize}
              onChange={(event) => onPageSizeChange(event.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
            <button
              className="tabulator-page"
              type="button"
              aria-label="First Page"
              title="First Page"
              data-page="first"
              onClick={() => onPageChange(0)}
              disabled={tableSettings?.currentPage === 0}
            >
              {t("global_first")}
            </button>
            <button
              className="tabulator-page"
              type="button"
              aria-label="Prev Page"
              title="Prev Page"
              data-page="prev"
              onClick={() => onPageChange(tableSettings?.currentPage - 1)}
              disabled={tableSettings?.currentPage === 0}
            >
              {t("global_prev")}
            </button>
            <span className="tabulator-pages">{onRenderPaginationPages()}</span>

            <button
              className="tabulator-page"
              type="button"
              aria-label="Next Page"
              title="Next Page"
              data-page="next"
              onClick={() => onPageChange(tableSettings?.currentPage + 1)}
              disabled={
                tableSettings?.currentPage === tableSettings?.totalPages - 1
              }
            >
              {t("global_next")}
            </button>
            <button
              className="tabulator-page"
              type="button"
              aria-label="Last Page"
              title="Last Page"
              data-page="last"
              onClick={() => onPageChange(tableSettings?.totalPages - 1)}
              disabled={
                tableSettings?.currentPage === tableSettings?.totalPages - 1
              }
            >
              {t("global_last")}
            </button>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AsyncTabulator;
