// import store
import React from "react";
import { notification, Modal, Button } from "antd";
import HTML from "./HTML";
import i18n from "i18n";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx/xlsx.mjs";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

const { t } = i18n;

const icons = {
  run: (
    <svg
      width="14"
      height="13"
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="play-circle"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
      <path d="M719.4 499.1l-296.1-215A15.9 15.9 0 00398 297v430c0 13.1 14.8 20.5 25.3 12.9l296.1-215a15.9 15.9 0 000-25.8zm-257.6 134V390.9L628.5 512 461.8 633.1z"></path>
    </svg>
  ),
  edit: (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.35938 8.09375L9.48438 0.96875C10.0703 0.382812 11.0312 0.382812 11.6172 0.96875L12.5312 1.88281C12.6016 1.95312 12.6719 2.04688 12.7188 2.11719C13.1172 2.70312 13.0469 3.5 12.5312 4.01562L5.40625 11.1406C5.38281 11.1641 5.33594 11.1875 5.3125 11.2344C5.07812 11.4219 4.82031 11.5625 4.53906 11.6562L1.70312 12.4766C1.51562 12.5469 1.30469 12.5 1.16406 12.3359C1 12.1953 0.953125 11.9844 1 11.7969L1.84375 8.96094C1.9375 8.63281 2.125 8.32812 2.35938 8.09375ZM2.92188 9.28906L2.38281 11.1172L4.21094 10.5781C4.35156 10.5312 4.49219 10.4609 4.60938 10.3438L9.97656 4.97656L8.5 3.52344L3.15625 8.89062C3.13281 8.89062 3.13281 8.91406 3.10938 8.9375C3.01562 9.03125 2.96875 9.14844 2.92188 9.28906Z"
        fill="black"
      />
    </svg>
  ),
  show: (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 5.5C4.25 3.85938 5.58594 2.5 7.25 2.5C8.89062 2.5 10.25 3.85938 10.25 5.5C10.25 7.16406 8.89062 8.5 7.25 8.5C5.58594 8.5 4.25 7.16406 4.25 5.5ZM7.25 7.375C8.28125 7.375 9.125 6.55469 9.125 5.5C9.125 4.46875 8.28125 3.625 7.25 3.625C7.22656 3.625 7.20312 3.625 7.17969 3.625C7.22656 3.76562 7.25 3.88281 7.25 4C7.25 4.84375 6.57031 5.5 5.75 5.5C5.60938 5.5 5.49219 5.5 5.375 5.45312C5.375 5.47656 5.375 5.5 5.375 5.5C5.375 6.55469 6.19531 7.375 7.25 7.375ZM2.72656 2.14844C3.82812 1.11719 5.35156 0.25 7.25 0.25C9.125 0.25 10.6484 1.11719 11.75 2.14844C12.8516 3.15625 13.5781 4.375 13.9297 5.21875C14 5.40625 14 5.61719 13.9297 5.80469C13.5781 6.625 12.8516 7.84375 11.75 8.875C10.6484 9.90625 9.125 10.75 7.25 10.75C5.35156 10.75 3.82812 9.90625 2.72656 8.875C1.625 7.84375 0.898438 6.625 0.546875 5.80469C0.476562 5.61719 0.476562 5.40625 0.546875 5.21875C0.898438 4.375 1.625 3.15625 2.72656 2.14844ZM7.25 1.375C5.70312 1.375 4.46094 2.07812 3.5 2.96875C2.58594 3.8125 1.97656 4.79688 1.64844 5.5C1.97656 6.20312 2.58594 7.21094 3.5 8.05469C4.46094 8.94531 5.70312 9.625 7.25 9.625C8.77344 9.625 10.0156 8.94531 10.9766 8.05469C11.8906 7.21094 12.5 6.20312 12.8281 5.5C12.5 4.79688 11.8906 3.8125 10.9766 2.96875C10.0156 2.07812 8.77344 1.375 7.25 1.375Z"
        fill="black"
      />
    </svg>
  ),
  delete: (
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4375 2.375C10.7422 2.375 11 2.63281 11 2.9375C11 3.26562 10.7422 3.5 10.4375 3.5H10.1562L9.59375 11.1172C9.52344 11.9141 8.89062 12.5 8.09375 12.5H3.38281C2.58594 12.5 1.95312 11.9141 1.88281 11.1172L1.32031 3.5H1.0625C0.734375 3.5 0.5 3.26562 0.5 2.9375C0.5 2.63281 0.734375 2.375 1.0625 2.375H2.67969L3.54688 1.08594C3.78125 0.734375 4.20312 0.5 4.64844 0.5H6.82812C7.27344 0.5 7.69531 0.734375 7.92969 1.08594L8.79688 2.375H10.4375ZM4.64844 1.625C4.57812 1.625 4.50781 1.67188 4.48438 1.71875L4.03906 2.375H7.4375L6.99219 1.71875C6.96875 1.67188 6.89844 1.625 6.82812 1.625H4.64844ZM9.03125 3.5H2.44531L3.00781 11.0469C3.03125 11.2344 3.19531 11.375 3.38281 11.375H8.09375C8.28125 11.375 8.44531 11.2344 8.46875 11.0469L9.03125 3.5Z"
        fill="black"
      />
    </svg>
  ),
  copy: (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7656 2.16406L11.3359 0.734375C11.1953 0.59375 11.0078 0.5 10.7969 0.5H6.97656C6.13281 0.5 5.47656 1.17969 5.47656 2V8C5.5 8.84375 6.15625 9.5 7 9.5H11.5C12.3203 9.5 13 8.84375 13 8V2.70312C13 2.49219 12.9062 2.30469 12.7656 2.16406ZM11.875 8C11.875 8.21094 11.6875 8.375 11.5 8.375H6.97656C6.76562 8.375 6.60156 8.21094 6.60156 8V2.02344C6.60156 1.8125 6.76562 1.64844 6.97656 1.64844H9.97656L10 2.75C10 3.17188 10.3281 3.5 10.75 3.5H11.8516V8H11.875ZM7.375 11C7.375 11.2109 7.1875 11.375 7 11.375H2.47656C2.26562 11.375 2.10156 11.2109 2.10156 11V5.02344C2.10156 4.8125 2.28906 4.64844 2.47656 4.64844H4.75V3.5H2.47656C1.65625 3.5 0.976562 4.17969 0.976562 5L1 11C1 11.8438 1.65625 12.5 2.5 12.5H7C7.82031 12.5 8.5 11.8438 8.5 11V10.25H7.375V11Z"
        fill="black"
      />
    </svg>
  ),
  download: (
    <svg
      width="13"
      height="13"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
    >
      <path
        fill="currentColor"
        d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"
      />
    </svg>
  ),
};

class GL {
  static openSimpleModal(title, div, callback) {
    var h = document.getElementById("root").clientHeight - 40;
    Modal.info({
      title: title,
      width: 500,
      content: div,
      bodyStyle: { maxHeight: `${h}px` },
      okText: "Tamam",
      cancelText: "Kapat",
      closable: true,
      onOk: () => {
        callback();
      },
    });
  }

  static openSimpleTable(title, div) {
    var h = document.getElementById("root").clientHeight - 40;
    Modal.info({
      title: title,
      width: 900,
      content: div,
      bodyStyle: { maxHeight: `${h}px` },
      okText: "Tamam",
      cancelText: "Kapat",
      closable: true,
    });
  }

  static infoSingleModal(title, table) {
    var h = document.getElementById("root").clientHeight - 40;
    Modal.info({
      title: title,
      width: 600,
      content: table,
      bodyStyle: { maxHeight: `${h}px` },
      okText: "Tamam",
      cancelText: "Kapat",
      closable: true,
    });
  }

  // alikilic - filtering array data
  static dataFilter(arr, filter) {
    var arr2 = [];
    var id = [];
    if (filter.status == true) {
      for (var key in filter.filters) {
        var val = filter.filters[key];
        arr.map((a) => {
          if (a[key] == val && id.indexOf(a.id) == -1) {
            id.push(a.id);
            arr2.push(a);
          }
        });
      }
      return arr2;
    } else {
      return arr;
    }
  }

  // alikilic - Cheching Form Item Validation
  static formItemErrorHandler(formik, val) {
    const keys = val.split(".");
    let fieldTouched = formik.touched;
    let fieldErrors = formik.errors;
    keys.forEach((keyStr) => {
      fieldTouched = fieldTouched?.[keyStr];
      fieldErrors = fieldErrors?.[keyStr];
    });
    if (fieldTouched && fieldErrors) {
      return formik.errors.info[keys[1]];
    }
    return "";
  }

  // alikilic - getting Form Data for API
  static async getFormikData(formik, arr) {
    var obj = {};
    const errors = await formik.validateForm();
    if (errors["info"] == undefined) {
      arr.map((a) => {
        var item = formik.getFieldProps(`info.${a}`);
        if (item.value !== undefined) {
          obj[a] = item.value;
        }
      });
      return obj;
    } else {
      var errArr = [];
      for (var i in errors["info"]) {
        errArr.push(errors["info"][i]);
      }
      if (errArr.length > 0) {
        GL.notify.warning({
          title: "Boş xanaları doldurun",
          text: errArr.join(", "),
        });
      }
      return false;
    }
  }

  static openTableSettings(el, callback) {
    var h = document.getElementById("root").clientHeight - 40;
    let elem = el;
    Modal.confirm({
      width: 500,
      content: el,
      bodyStyle: { maxHeight: `${h}px` },
      icon: "",
      okText: "Ayarlari Kaydet",
      cancelText: "Iptal",
      onOk() {
        callback(elem);
      },
      onCancel() {
        callback(false);
      },
    });
  }

  // alikilic - it asking to user yes or no for process
  static yesNo(question, callback) {
    Modal.confirm({
      content: question,
      okText: "Bəli",
      cancelText: "Xeyr",
      onOk() {
        callback(true);
      },
      onCancel() {
        callback(false);
      },
    });
  }

  static jsonToPDFTable(jsonData, filename) {
    debugger;
    var pagename = "a4";
    var doc = new jsPDF({
      orientation: "landscape",
      format: "a2",
    });
    var headers = Object.keys(jsonData[0]);
    var rows = jsonData.map(function (item) {
      return Object.values(item);
    });
    if (headers.length > 5) {
      pagename = "a3";
    }
    if (headers.length > 10) {
      pagename = "a2";
    }
    if (headers.length > 15) {
      pagename = "a1";
    }
    if (headers.length > 20) {
      pagename = "a0";
    }
    var d = [headers].concat(rows);
    autoTable(doc, {
      body: d,
    });
    doc.save(`${filename}.pdf`);
  }

  static jsonToCSV(jsonData, filename) {
    var csvContent = "";
    var columnHeaders = Object.keys(jsonData[0]);
    csvContent += columnHeaders.join(",") + "\n";
    jsonData.forEach(function (item) {
      var row = columnHeaders.map(function (header) {
        return item[header];
      });
      csvContent += row.join(",") + "\n";
    });
    filename = `${filename}.csv`;
    var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, filename);
  }

  static jsonToExcel(jsonData, filename) {
    debugger;
    var columnHeaders = Object.keys(jsonData[0]);
    var workbook = XLSX.utils.book_new();
    var worksheet = XLSX.utils.json_to_sheet(jsonData);
    XLSX.utils.sheet_add_json(worksheet, jsonData, {
      header: columnHeaders,
      skipHeader: true,
      origin: -1,
    });
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    var excelData = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    var blob = new Blob([excelData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, filename + ".xlsx");
  }

  static datetimeToString(dateStr, withoutTime) {
    if (dateStr == null || dateStr == undefined) {
      return "";
    }
    if (typeof dateStr == "string") {
      var date = new Date(dateStr);
    } else {
      var date = dateStr;
    }

    var gun = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    var ay =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    var saat = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    var dk =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    var sn =
      date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();

    let formattedDate = `${gun}.${ay}.${date.getFullYear()} ${saat}:${dk}:${sn}`;
    if (withoutTime) formattedDate = `${gun}.${ay}.${date.getFullYear()}`;

    return formattedDate;
  }

  static getRiskColor(val) {
    val = Number(val);
    var arr = [
      { min: 0, max: 6, color: "#FFDD26" },
      { min: 6, max: 7, color: "#FFB034" },
      { min: 7, max: 8, color: "#FF681C" },
      { min: 8, max: 9, color: "#DF2638" },
      { min: 9, max: 10, color: "#8638e5" },
    ];
    var item = arr.find((obj) => {
      if (obj.min <= val && obj.max > val) {
        return true;
      }
    });
    if (item) {
      return item.color;
    } else {
      return "#000000";
    }
  }

  static calculateTwoDaysBetween(date) {
    if (!date) return 0;
    const dateNow = new Date();
    const dateSecond = new Date(date);
    const calculateTimes = dateSecond.getTime() - dateNow.getTime();
    const calculateDays = Math.ceil(calculateTimes / (1000 * 3600 * 24));
    return calculateDays;
  }

  static renderBadgeHtml(day) {
    if (!day) return "";
    return `<div class="ant-tag ${
      day === 0 ? "ant-tag-green" : day < 0 ? "ant-tag-magenta" : "ant-tag-blue"
    } css-yp8pcc" style="border-radius: 10px;">${
      day < 0
        ? `${Math.abs(day)} gün gecikib`
        : day === 0
        ? "Bugün"
        : `${day} gün qalıb`
    } </div>`;
  }

  // aslan - it asking to user yes or no for process
  static confirmDelete(skeleton, refresh, callback) {
    const onRefresh = () => {
      //debugger;
      refresh((component, config) => {
        confirmDialog.config = config;
        if (confirmDialog.config.okButtonStatus == false) {
          var buttons = HTML.getClass("ant-modal-confirm-btns");
          for (var i = 0; i < buttons[0].children.length; i++) {
            if (buttons[0].children[i].className == "ant-btn ant-btn-primary") {
              buttons[0].children[i].disabled = "disabled";
              buttons[0].children[i].style.opacity = 0.8;
            }
          }
        }
        confirmDialog.update({
          content: component,
        });
      });
    };
    var confirmDialog = Modal.confirm({
      icon: null,
      width: 500,
      content: skeleton,
      okText: "Bəli",
      cancelText: "Xeyr",
      closable: true,
      onOk() {
        //debugger;
        if (confirmDialog.config.okButtonStatus) {
          callback(true);
        } else {
          GL.notify.warning({
            text: "Baglantilari kaldirmadan silme islemini gerceklestiremezsiniz.",
          });
        }
      },
      onCancel() {
        callback(false);
      },
      getContainer() {
        //debugger;
        var divs = document.getElementsByClassName("ant-modal-confirm-btns");
        if (divs.length > 0) {
          var btn_id = "ant-modal-refresh-btn";
          if (!HTML.getId(btn_id)) {
            var div = divs[0];
            var firstButton = div.children[0];
            var button = HTML.button({
              innerHTML: "Refresh",
              id: btn_id,
              click: (e) => {
                onRefresh();
              },
            });
            div.insertBefore(button, firstButton);
          }
        }
      },
    });
    onRefresh();
    //debugger;
    return confirmDialog;
  }

  // alikilic - pushing notify to top right
  static notify = {
    error(obj) {
      var { title, text, callback } = this.getData(obj);
      title = title == undefined ? t("answer_error") : title;
      this.run("error", title, text, callback);
    },
    warning(obj) {
      var { title, text, callback } = this.getData(obj);
      title = title == undefined ? t("answer_warning") : title;
      this.run("warning", title, text, callback);
    },
    success(obj) {
      var { title, text, callback } = this.getData(obj);
      title = title == undefined ? t("answer_success") : title;
      this.run("success", title, text, callback);
    },
    info(obj) {
      var { title, text, callback } = this.getData(obj);
      title = title == undefined ? t("answer_info") : title;
      this.run("info", title, text, callback);
    },
    getData(obj) {
      var title = obj["title"] !== undefined ? obj["title"] : undefined;
      var text = obj["text"] !== undefined ? obj["text"] : "Mesaj Yok";
      var callback =
        obj["callback"] !== undefined ? obj["callback"] : undefined;
      return { title, text, callback };
    },
    run(type, title, text, callback) {
      notification.open({
        type: type,
        message: title,
        description: text,
        onClick: () => {
          if (callback !== undefined) {
            callback();
          }
        },
      });
    },
  };

  // if you have a json array, this function will give you
  static getColumnsFromJSONArray(arr, forbidens, mask) {
    var columns = [];
    if (arr.length > 0) {
      var d = arr[0];
      for (var i in d) {
        if (forbidens.indexOf(i) == -1) {
          var title = i.toLocaleUpperCase().split("_").join(" ");
          var field = i;
          if (mask[field] !== undefined) {
            title = mask[field];
          }
          columns.push({ title: title, field: field });
        }
      }
    }
    return columns;
  }

  static getURLQuery() {
    var urlSearch = window.location.search;
    var urlFilter = {
      status: false,
      filters: {},
    };
    if (urlSearch !== "") {
      urlSearch = urlSearch.replace("?", "");
      var part = urlSearch.split("=");
      if (part.length == 2) {
        var key = part[0];
        var value = Number(part[1]);
        urlFilter.status = true;
        urlFilter.filters[key] = value;
      }
    }
    return urlFilter;
  }

  static getArrayForSelect(data) {
    var arr = [];
    data.map((a) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    return arr;
  }

  static setSelectStatus(cell, isRisk, onChange, selected) {
    var data = cell.getData();
    var id;
    if (isRisk) {
      id = data?.risks?.id;
    } else {
      id = data?.id;
    }
    var div = document.createElement("div");
    div.classList.add("check-box");
    var input = document.createElement("input");
    input.type = "checkbox";
    input.id = "id-" + id;
    input.value = id;
    if (Array.isArray(selected)) input.checked = selected.includes(id);
    if (onChange) input.onchange = (e) => onChange(e, id);

    // input.classList.add("check-box");
    var label = document.createElement("label");
    label.innerText = id;
    label.for = "id-" + id;
    input.addEventListener("change", function (e) {
      var status = e.target.checked;
      var row = cell.getRow();
      if (status) {
        row.select();
      } else {
        row.deselect();
      }
    });
    div.appendChild(input);
    div.appendChild(label);
    return div;
  }

  static setButtons(cell, opt) {
    let data = cell.getData();
    var div = document.createElement("div");
    div.classList.add("table-icons");
    if (opt.run !== undefined) {
      if (opt.run?.status) {
        var btn0 = document.createElement("div");
        btn0.className = "tableIcons";
        btn0.innerHTML = `<svg width="14" height="13" viewBox="64 64 896 896" focusable="false" data-icon="play-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M719.4 499.1l-296.1-215A15.9 15.9 0 00398 297v430c0 13.1 14.8 20.5 25.3 12.9l296.1-215a15.9 15.9 0 000-25.8zm-257.6 134V390.9L628.5 512 461.8 633.1z"></path></svg>
        `;
        btn0.addEventListener(
          "click",
          (e) => {
            opt.run.click(data);
          },
          false
        );
        div.appendChild(btn0);
      }
    }
    if (opt.edit?.status) {
      var btn1 = document.createElement("div");
      //btn1.innerHTML = '<i class="fa-solid fa-pen tblIcon"></i>';
      btn1.className = "tableIcons";
      btn1.innerHTML = `<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.35938 8.09375L9.48438 0.96875C10.0703 0.382812 11.0312 0.382812 11.6172 0.96875L12.5312 1.88281C12.6016 1.95312 12.6719 2.04688 12.7188 2.11719C13.1172 2.70312 13.0469 3.5 12.5312 4.01562L5.40625 11.1406C5.38281 11.1641 5.33594 11.1875 5.3125 11.2344C5.07812 11.4219 4.82031 11.5625 4.53906 11.6562L1.70312 12.4766C1.51562 12.5469 1.30469 12.5 1.16406 12.3359C1 12.1953 0.953125 11.9844 1 11.7969L1.84375 8.96094C1.9375 8.63281 2.125 8.32812 2.35938 8.09375ZM2.92188 9.28906L2.38281 11.1172L4.21094 10.5781C4.35156 10.5312 4.49219 10.4609 4.60938 10.3438L9.97656 4.97656L8.5 3.52344L3.15625 8.89062C3.13281 8.89062 3.13281 8.91406 3.10938 8.9375C3.01562 9.03125 2.96875 9.14844 2.92188 9.28906Z" fill="black"/>
      </svg>
      `;
      btn1.addEventListener(
        "click",
        (e) => {
          opt.edit.click(data);
        },
        false
      );
      div.appendChild(btn1);
    }
    if (opt.show?.status) {
      var btn3 = document.createElement("div");
      btn3.className = "tableIcons";
      btn3.innerHTML = `<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.25 5.5C4.25 3.85938 5.58594 2.5 7.25 2.5C8.89062 2.5 10.25 3.85938 10.25 5.5C10.25 7.16406 8.89062 8.5 7.25 8.5C5.58594 8.5 4.25 7.16406 4.25 5.5ZM7.25 7.375C8.28125 7.375 9.125 6.55469 9.125 5.5C9.125 4.46875 8.28125 3.625 7.25 3.625C7.22656 3.625 7.20312 3.625 7.17969 3.625C7.22656 3.76562 7.25 3.88281 7.25 4C7.25 4.84375 6.57031 5.5 5.75 5.5C5.60938 5.5 5.49219 5.5 5.375 5.45312C5.375 5.47656 5.375 5.5 5.375 5.5C5.375 6.55469 6.19531 7.375 7.25 7.375ZM2.72656 2.14844C3.82812 1.11719 5.35156 0.25 7.25 0.25C9.125 0.25 10.6484 1.11719 11.75 2.14844C12.8516 3.15625 13.5781 4.375 13.9297 5.21875C14 5.40625 14 5.61719 13.9297 5.80469C13.5781 6.625 12.8516 7.84375 11.75 8.875C10.6484 9.90625 9.125 10.75 7.25 10.75C5.35156 10.75 3.82812 9.90625 2.72656 8.875C1.625 7.84375 0.898438 6.625 0.546875 5.80469C0.476562 5.61719 0.476562 5.40625 0.546875 5.21875C0.898438 4.375 1.625 3.15625 2.72656 2.14844ZM7.25 1.375C5.70312 1.375 4.46094 2.07812 3.5 2.96875C2.58594 3.8125 1.97656 4.79688 1.64844 5.5C1.97656 6.20312 2.58594 7.21094 3.5 8.05469C4.46094 8.94531 5.70312 9.625 7.25 9.625C8.77344 9.625 10.0156 8.94531 10.9766 8.05469C11.8906 7.21094 12.5 6.20312 12.8281 5.5C12.5 4.79688 11.8906 3.8125 10.9766 2.96875C10.0156 2.07812 8.77344 1.375 7.25 1.375Z" fill="black"/>
      </svg>
      `;
      btn3.addEventListener(
        "click",
        (e) => {
          opt.show.click(data);
        },
        false
      );
      div.appendChild(btn3);
    }
    if (opt.delete?.status) {
      var btn2 = document.createElement("div");
      btn2.className = "tableIcons";
      btn2.innerHTML = `<svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.4375 2.375C10.7422 2.375 11 2.63281 11 2.9375C11 3.26562 10.7422 3.5 10.4375 3.5H10.1562L9.59375 11.1172C9.52344 11.9141 8.89062 12.5 8.09375 12.5H3.38281C2.58594 12.5 1.95312 11.9141 1.88281 11.1172L1.32031 3.5H1.0625C0.734375 3.5 0.5 3.26562 0.5 2.9375C0.5 2.63281 0.734375 2.375 1.0625 2.375H2.67969L3.54688 1.08594C3.78125 0.734375 4.20312 0.5 4.64844 0.5H6.82812C7.27344 0.5 7.69531 0.734375 7.92969 1.08594L8.79688 2.375H10.4375ZM4.64844 1.625C4.57812 1.625 4.50781 1.67188 4.48438 1.71875L4.03906 2.375H7.4375L6.99219 1.71875C6.96875 1.67188 6.89844 1.625 6.82812 1.625H4.64844ZM9.03125 3.5H2.44531L3.00781 11.0469C3.03125 11.2344 3.19531 11.375 3.38281 11.375H8.09375C8.28125 11.375 8.44531 11.2344 8.46875 11.0469L9.03125 3.5Z" fill="black"/>
      </svg>
      `;

      btn2.addEventListener(
        "click",
        (e) => {
          opt.delete.click(data);
        },
        false
      );
      div.appendChild(btn2);
    }

    if (opt.copy?.status) {
      var btn4 = document.createElement("div");
      btn4.className = "tableIcons";
      btn4.innerHTML = `<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.7656 2.16406L11.3359 0.734375C11.1953 0.59375 11.0078 0.5 10.7969 0.5H6.97656C6.13281 0.5 5.47656 1.17969 5.47656 2V8C5.5 8.84375 6.15625 9.5 7 9.5H11.5C12.3203 9.5 13 8.84375 13 8V2.70312C13 2.49219 12.9062 2.30469 12.7656 2.16406ZM11.875 8C11.875 8.21094 11.6875 8.375 11.5 8.375H6.97656C6.76562 8.375 6.60156 8.21094 6.60156 8V2.02344C6.60156 1.8125 6.76562 1.64844 6.97656 1.64844H9.97656L10 2.75C10 3.17188 10.3281 3.5 10.75 3.5H11.8516V8H11.875ZM7.375 11C7.375 11.2109 7.1875 11.375 7 11.375H2.47656C2.26562 11.375 2.10156 11.2109 2.10156 11V5.02344C2.10156 4.8125 2.28906 4.64844 2.47656 4.64844H4.75V3.5H2.47656C1.65625 3.5 0.976562 4.17969 0.976562 5L1 11C1 11.8438 1.65625 12.5 2.5 12.5H7C7.82031 12.5 8.5 11.8438 8.5 11V10.25H7.375V11Z" fill="black"/>
      </svg>
      `;
      btn4.addEventListener(
        "click",
        (e) => {
          opt.copy.click(data);
        },
        false
      );
      div.appendChild(btn4);
    }
    if (opt.download?.status) {
      var btn5 = document.createElement("div");
      btn5.className = "tableIcons";
      btn5.innerHTML = `<svg width="13" height="13" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024"><path fill="currentColor" d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"/></svg>
      `;
      btn5.addEventListener(
        "click",
        (e) => {
          opt.download.click(data);
        },
        false
      );
      div.appendChild(btn5);
    }
    if (opt.danger?.status) {
      var btn6 = document.createElement("div");
      btn6.className = "tableIcons";
      btn6.innerHTML = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 100 100"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.0549 11.505C39.1101 9.41107 44.5283 8.33333 50 8.33333C55.4718 8.33333 60.89 9.41107 65.9452 11.505C71.0004 13.599 75.5937 16.6681 79.4628 20.5372C83.3319 24.4063 86.4011 28.9996 88.495 34.0549C90.589 39.1101 91.6667 44.5283 91.6667 50C91.6667 55.4717 90.589 60.8899 88.495 65.9451C86.4011 71.0004 83.3319 75.5937 79.4628 79.4628C75.5937 83.3319 71.0004 86.401 65.9452 88.495C60.89 90.5889 55.4718 91.6667 50 91.6667C44.5283 91.6667 39.1101 90.5889 34.0549 88.495C28.9997 86.401 24.4064 83.3319 20.5373 79.4628C16.6682 75.5937 13.599 71.0004 11.5051 65.9451C9.41111 60.8899 8.33337 55.4717 8.33337 50C8.33337 44.5283 9.41111 39.1101 11.5051 34.0549C13.599 28.9996 16.6682 24.4063 20.5373 20.5372C24.4064 16.6681 28.9997 13.599 34.0549 11.505ZM50 16.6667C45.6227 16.6667 41.2881 17.5289 37.2439 19.204C33.1997 20.8792 29.5251 23.3345 26.4298 26.4298C23.3345 29.5251 20.8792 33.1997 19.2041 37.2439C17.5289 41.2881 16.6667 45.6226 16.6667 50C16.6667 54.3774 17.5289 58.7119 19.2041 62.7561C20.8792 66.8003 23.3345 70.4749 26.4298 73.5702C29.5251 76.6655 33.1997 79.1208 37.2439 80.796C41.2881 82.4711 45.6226 83.3333 50 83.3333C54.3774 83.3333 58.712 82.4711 62.7562 80.796C66.8003 79.1208 70.475 76.6655 73.5703 73.5702C76.6656 70.4749 79.1209 66.8003 80.796 62.7561C82.4712 58.7119 83.3334 54.3774 83.3334 50C83.3334 45.6226 82.4712 41.2881 80.796 37.2439C79.1209 33.1997 76.6656 29.5251 73.5703 26.4298C70.475 23.3345 66.8003 20.8792 62.7562 19.204C58.712 17.5289 54.3774 16.6667 50 16.6667Z"
        fill="#DF2638"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 33.3333C52.3012 33.3333 54.1667 35.1988 54.1667 37.5V54.1667C54.1667 56.4678 52.3012 58.3333 50 58.3333C47.6989 58.3333 45.8334 56.4678 45.8334 54.1667V37.5C45.8334 35.1988 47.6989 33.3333 50 33.3333Z"
        fill="#DF2638"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 62.5C52.3012 62.5 54.1667 64.3655 54.1667 66.6667V66.7083C54.1667 69.0095 52.3012 70.875 50 70.875C47.6989 70.875 45.8334 69.0095 45.8334 66.7083V66.6667C45.8334 64.3655 47.6989 62.5 50 62.5Z"
        fill="#DF2638"
      />
    </svg>`;
      btn6.addEventListener(
        "click",
        (e) => {
          opt.danger.click(data);
        },
        false
      );
      div.appendChild(btn6);
    }
    return div;
  }

  static setAntTableButtons(data, operators) {
    function renderButton() {
      return Object.entries(operators).map(([key, { status, onClick }]) => {
        if (!status) return "";
        return (
          <div key={key} className="tableIcons" onClick={() => onClick(data)}>
            {icons[key]}
          </div>
        );
      });
    }
    return <div className="table-icons">{renderButton()}</div>;
  }

  static sortASC(obj) {
    function compareById(a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    }
    return obj.sort(compareById());
  }

  static sortDESC(obj) {
    function compareById(a, b) {
      if (a.id > b.id) {
        return -1;
      }
      if (a.id < b.id) {
        return 1;
      }
      return 0;
    }
    return obj.sort(compareById());
  }

  // ! ASLAN
  static createSlug(str) {
    return str
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/[^a-zA-Z0-9]/g, "");
  }

  static createFieldForColumn(str) {
    return str
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/[^a-zA-Z0-9\_]/g, "")
      .replace(/^\d+/, "");
  }
}

export default GL;
