import React, { useState, useEffect, useRef } from "react";
import { Modal, Space, Typography } from "antd";
import SelectBox from "components/Elements/FileUploadForm/Form/SelectBox";
import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import TextareaComponent from "components/Elements/FileUploadForm/Form/Textarea";
import Enum from "models/Enum";
import { useFormik } from "formik";
import * as Yup from "yup";
import Risks from "models/Risks";
import GL from "libs/GL";
import { useSelector } from "react-redux";

const { Title } = Typography;

const FileModal = ({
  isOpenModal,
  onOk,
  onCancel,
  riskId,
  itemId,
  onSetItemId,
}) => {
  const { activeRole } = useSelector(({ user }) => ({
    activeRole: user.data.roles?.find(({ status }) => status),
  }));
  const [categories, setCategories] = useState([]);
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);

  const formRef = useRef();

  const getEnumsByCategoryID = () => {
    Enum.getEnumsByCategoryID(12, (data) => {
      setCategories(data);
    });
  };

  useEffect(() => {
    getEnumsByCategoryID();
  }, []);

  const validationSchema = Yup.object().shape({
    info: Yup.object().shape({
      name: Yup.string().required("Ad daxil edin"),
      categoryId: Yup.number().required("Ad daxil edin"),
    }),
    file: Yup.mixed().nullable(false).required("Fayl daxil edin"),
  });

  const formik = useFormik({
    initialValues: {
      info: { name: "", description: "", categoryId: "", itemId: itemId ?? 0 },
      file: null,
    },
    validationSchema,
    onSubmit: () => {
      setIsFileUploadLoading(true);
      Risks.uploadRiskFile(
        riskId,
        formRef.current,
        () => {
          GL.notify.success({ text: "Fayıl uğurla yükləndi." });
          onOk();
          onSetItemId && onSetItemId(null);
          setIsFileUploadLoading(false);
        },
        () => setIsFileUploadLoading(false)
      );
    },
  });

  const { values, errors, touched } = formik;

  const hasFileError = errors?.file && touched?.file;

  const onHandleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <Modal
      title="Fayl yüklə"
      open={isOpenModal}
      okText="Yadda saxla"
      cancelText="Ləğv et"
      cancelButtonProps={{ className: "btn-secondary" }}
      okButtonProps={{ className: "btn-danger", loading: isFileUploadLoading }}
      onOk={onHandleSubmit}
      onCancel={onCancel}
    >
      <form className="pt-15" ref={formRef}>
        <Space direction="vertical" size={20}>
          <SelectBox
            label="Kateqoriya"
            field="categoryId"
            formik={formik}
            data={categories}
          />
          <InputBox
            label="Adı"
            placeholder="Ad daxil et"
            formik={formik}
            field="name"
            required
          />
          <TextareaComponent
            label="Təsviri"
            placeholder="Təsvir daxil et"
            field="description"
            formik={formik}
          />

          <label
            htmlFor="file"
            className={`Upload-label ${hasFileError && "error"}`}
          >
            <span className="ant-btn ant-btn-default btn-danger d-inline-flex align-items-center mr-10">
              Fayl seç
            </span>
            {hasFileError && (
              <span className="Upload-info error">Fayl seçilməyib</span>
            )}

            <input
              id="file"
              type="file"
              name="file"
              hidden
              onChange={(e) => formik.setFieldValue("file", e.target.files[0])}
            />
          </label>
          {values?.file && (
            <div className="d-flex flex-column">
              <Title level={5} className="mb-10">
                Fayl
              </Title>
              <div className="d-flex align-items-center Upload-files">
                <div className="flex-1">{values?.file?.name}</div>
                {/* <Close
                  className="cursor-pointer"
                  onClick={() => formik.setFieldValue("file", null)}
                /> */}
              </div>
            </div>
          )}
          <div className="d-none">
            <input type="text" hidden name="name" value={values.info.name} />
            <input
              type="text"
              hidden
              name="description"
              value={values.info.description}
            />
            <input
              type="text"
              hidden
              name="itemId"
              value={values.info.itemId}
            />
            <input
              type="text"
              hidden
              name="categoryId"
              value={values.info.categoryId}
            />
            <input type="text" hidden name="roleId" value={activeRole?.id} />
          </div>
        </Space>
      </form>
    </Modal>
  );
};

export default FileModal;
