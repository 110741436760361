import React from "react";

const Attachment = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_1622_751)">
        <path
          d="M12.5002 5.83332L7.08357 11.25C6.75205 11.5815 6.56581 12.0312 6.56581 12.5C6.56581 12.9688 6.75205 13.4185 7.08357 13.75C7.41509 14.0815 7.86473 14.2678 8.33357 14.2678C8.80242 14.2678 9.25205 14.0815 9.58357 13.75L15.0002 8.33332C15.6633 7.67028 16.0358 6.77101 16.0358 5.83332C16.0358 4.89564 15.6633 3.99637 15.0002 3.33332C14.3372 2.67028 13.4379 2.29779 12.5002 2.29779C11.5626 2.29779 10.6633 2.67028 10.0002 3.33332L4.58357 8.74999C3.58901 9.74455 3.03027 11.0935 3.03027 12.5C3.03027 13.9065 3.58901 15.2554 4.58357 16.25C5.57814 17.2446 6.92705 17.8033 8.33357 17.8033C9.7401 17.8033 11.089 17.2446 12.0836 16.25L17.5002 10.8333"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1622_751">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Attachment;
