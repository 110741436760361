import "../../RiskPageStyles.scss";

import React, { useEffect, useState } from "react";
import { Row, Col, Select, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { CopyOutlined, LinkOutlined, DeleteOutlined } from "@ant-design/icons";

import FormSubmit from "components/Elements/FormSubmit/FormSubmit2";
import RiskGroup from "models/RiskGroup";
import GL from "libs/GL";
import SelectBox from "components/Elements/FileUploadForm/Form/SelectBox";
import UseScenarioInfoUsers from "../../Hooks/ScenarioInfoUsers";
import UserGroup from "models/UserGroup";

function Info(props) {
  const { formik, resetFormik, submitForm, inputHasError } = props;

  const { TextArea } = Input;
  const { t } = useTranslation();
  const [scenarioUsers] = UseScenarioInfoUsers();

  const [riskGroupList, setRiskGroupList] = useState([]);
  const [userGroups, setUserGroups] = useState([]);

  const getUserGroup = () => {
    UserGroup.getUserGroupList((data) => {
      const updatedData = data?.map((item) => ({
        ...item,
        value: item.id,
        label: item.name,
      }));
      setUserGroups(updatedData);
    });
  };

  const getRiskGroups = () => {
    RiskGroup.getRiskGroupList(
      (data) => {
        const riskGroup = RiskGroup.getArrayForSelect(data);
        setRiskGroupList(riskGroup);
      },
      (err) => {
        console.log("err =>", err);
      }
    );
  };

  const handleCopyClick = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      GL.notify.success({
        title: t("answer_success"),
        text: t("global_copied"),
      });
    });
  };

  const removFromCompanyList = (index) => {
    GL.yesNo(t("question_deleting"), (status) => {
      if (status) {
        const deletedData = formik.values?.info?.info?.leaders?.splice(
          index,
          1
        );
        formik.setFieldValue("formik.values?.info?.info?.leaders", deletedData);
      }
    });
  };

  useEffect(() => {
    getRiskGroups();
    getUserGroup();
  }, []);

  return (
    <div className="risk-page" onSubmit={formik.handleSubmit}>
      <h1 className="risk-page__title">{t("global_info")}</h1>
      <div className="risk-page__elements">
        <Row gutter={24}>
          <Col sm={24} md={8} lg={8}>
            {formik.values.id ? (
              // ! Dev: Aslan. Dont`t show id field if the value is empty
              <div className="input-box">
                <span className="input-box__title">ID</span>
                <input
                  type="number"
                  disabled={true}
                  className={
                    inputHasError("id")
                      ? "error input-disabled"
                      : "input-disabled"
                  }
                  value={formik.getFieldProps("id").value}
                />
                <div className="input-box__error-field">
                  {inputHasError("id") ? formik.errors.id : ""}
                </div>
              </div>
            ) : null}
            <div className="input-box">
              <span className="input-box__title">{t("global_name")}</span>
              <input
                type="text"
                className={inputHasError("info.info.name") ? "error" : ""}
                {...formik.getFieldProps("info.info.name")}
              />
              <div className="input-box__error-field">
                {inputHasError("info.info.name")
                  ? formik.errors.info.info.name
                  : ""}
              </div>
            </div>
            <div className="input-box">
              <span className="input-box__title">{t("global_code")}</span>
              <input
                type="text"
                className={inputHasError("info.info.code") ? "error" : ""}
                value={formik.values.info.info.code}
                onChange={(e) => {
                  formik.setFieldValue(
                    "info.info.code",
                    GL.createFieldForColumn(e.target.value)
                  );
                }}
                onBlur={formik.handleBlur("info.info.code")}
              />
              <div className="input-box__error-field">
                {inputHasError("info.info.code")
                  ? formik.errors.info.info.code
                  : ""}
              </div>
            </div>
            <div className="input-box">
              <span className="input-box__title">{t("scenario_status")}</span>
              <Select
                options={[
                  { value: true, label: t("global_active") },
                  { value: false, label: t("global_deactive") },
                ]}
                value={formik.values.info.info.status}
                onChange={(value) =>
                  formik.setFieldValue("info.info.status", value)
                }
                onBlur={formik.handleBlur("info.info.status")}
                status={inputHasError("info.info.status") ? "error" : ""}
              />
              <div className="input-box__error-field">
                {inputHasError("info.info.status")
                  ? formik.errors.info.info.status
                  : ""}
              </div>
            </div>
            <div className="input-box">
              <span className="input-box__title">
                {t("scenario_risk_precedent")}
              </span>
              <input
                type="number"
                className={inputHasError("info.info.precedent") ? "error" : ""}
                {...formik.getFieldProps("info.info.precedent")}
              />
              <div className="input-box__error-field">
                {inputHasError("info.info.precedent")
                  ? formik.errors.info.info.precedent
                  : ""}
              </div>
            </div>
            <div className="input-box">
              <span className="input-box__title">
                {t("scenario_risk_type")}
              </span>
              <Select
                options={[
                  { value: "pre", label: t("scenario_risk_type_pre") },
                  { value: "post", label: t("scenario_risk_type_post") },
                ]}
                value={formik.values.info.info.riskType}
                onChange={(value) =>
                  formik.setFieldValue("info.info.riskType", value)
                }
                onBlur={formik.handleBlur("info.info.riskType")}
                status={inputHasError("info.info.riskType") ? "error" : ""}
              />{" "}
              <div className="input-box__error-field">
                {inputHasError("info.info.riskType")
                  ? formik.errors.info.info.riskType
                  : ""}
              </div>
            </div>
            <div className="input-box">
              <span className="input-box__title">İstifadəçi qrupu</span>
              <Select
                options={userGroups}
                value={formik.values.info.info.execUserGroup}
                onChange={(value) =>
                  formik.setFieldValue("info.info.execUserGroup", value)
                }
                onBlur={formik.handleBlur("info.info.execUserGroup")}
                status={inputHasError("info.info.execUserGroup") ? "error" : ""}
              />{" "}
              <div className="input-box__error-field">
                {inputHasError("info.info.execUserGroup")
                  ? formik.errors.info.info.execUserGroup
                  : ""}
              </div>
            </div>
          </Col>
          <Col sm={24} md={8} lg={8}>
            <div className="input-box">
              <span className="input-box__title">
                {t("scenario_risk_group")}
              </span>
              <Select
                options={riskGroupList}
                value={formik.values.info.info.riskGroup}
                onChange={(value) =>
                  formik.setFieldValue("info.info.riskGroup", value)
                }
                onBlur={formik.handleBlur("info.info.riskGroup")}
                status={inputHasError("info.info.riskGroup") ? "error" : ""}
              />{" "}
              <div className="input-box__error-field">
                {inputHasError("info.info.riskGroup")
                  ? formik.errors.info.info.riskGroup
                  : ""}
              </div>
            </div>
            <div className="input-box">
              <span className="input-box__title">
                {t("scenario_risk_break")}
              </span>
              <Select
                options={[
                  { value: true, label: t("global_yes") },
                  { value: false, label: t("global_no") },
                ]}
                value={formik.values.info.info.riskBreak}
                onChange={(value) =>
                  formik.setFieldValue("info.info.riskBreak", value)
                }
                onBlur={formik.handleBlur("info.info.riskBreak")}
                status={inputHasError("info.info.riskBreak") ? "error" : ""}
              />
              <div className="input-box__error-field">
                {inputHasError("info.info.riskBreak")
                  ? formik.errors.info.info.riskBreak
                  : ""}
              </div>
            </div>
            <div className="input-box">
              <span className="input-box__title">{t("scenario_exec_per")}</span>
              <input
                type="number"
                className={
                  inputHasError("info.info.executionPer") ? "error" : ""
                }
                {...formik.getFieldProps("info.info.executionPer")}
              />{" "}
              <div className="input-box__error-field">
                {inputHasError("info.info.executionPer")
                  ? formik.errors.info.info.executionPer
                  : ""}
              </div>
            </div>
            <div className="input-box">
              <span className="input-box__title">{t("global_tags")}</span>
              <Select
                className="tags"
                mode="tags"
                options={[]}
                value={formik.values.info.info.tags}
                onChange={(value) =>
                  formik.setFieldValue("info.info.tags", value)
                }
                onBlur={formik.handleBlur("info.info.tags")}
              />
            </div>
            <div className="input-box" style={{ marginTop: "40px" }}>
              <Input
                prefix={<LinkOutlined />}
                suffix={
                  <Button
                    onClick={() =>
                      handleCopyClick(
                        "https://service.risk.devsys.pro/http:/api/v1/engine/" +
                          formik.values.info.info.code
                      )
                    }
                  >
                    <CopyOutlined />
                  </Button>
                }
                value={
                  "https://service.risk.devsys.pro/http:/api/v1/engine/" +
                  formik.values.info.info.code
                }
              />
            </div>
          </Col>
          <Col sm={24} md={8} lg={8}>
            <div className="input-box">
              <span className="input-box__title">{t("global_qeyd")}</span>
              <TextArea
                autoSize
                rows={6}
                {...formik.getFieldProps("info.info.note")}
              />
            </div>
            <SelectBox
              label="Şube müdürlerini seçiniz"
              formik={formik}
              required={true}
              field="info.leaders"
              data={scenarioUsers}
              multi
              type="complex"
              settings={{
                parser: { value: "id", label: "firstName" },
                columns: ["id", "firstName", "lastName", "email"],
              }}
            />
            {formik.values.info?.info?.leaders?.map((a, s) => {
              return (
                <tr>
                  <td width="5%">{s + 1}</td>
                  <td width="70" style={{ paddingLeft: "13px" }}>
                    {`${a.firstName} ${a.lastName}`}
                  </td>
                  <td width="25%">
                    <Button
                      style={{ width: "100%" }}
                      onClick={removFromCompanyList.bind(this, s)}
                      type="primary"
                    >
                      <DeleteOutlined /> {t("global_delete_it")}
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Col>
        </Row>
      </div>

      <div className="risk-page__submittion">
        <FormSubmit cancel={resetFormik} submit={submitForm} />
      </div>
    </div>
  );
}

export default Info;
