import "./WorkFlow.scss";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";

import AddWorkFlow from "./AddWorkFlow/AddWorkFlow";
import { setRiskData } from "redux/actions/index";
import { useTranslation } from "react-i18next";
import { scenario } from "const/initialDatas";
import { apiRoutes } from "const/apiroutes";
import RiskStatus from "models/RiskStatus";
import ApiMethods from "const/API2";
import Diagram from "./Diagram2";
import GL from "libs/GL";

function WorkFlow() {
  const { t } = useTranslation();
  const API = new ApiMethods();
  const dispatch = useDispatch();

  const reduxRisk = useSelector((state) => state.risk);
  const reduxWorkFlowList = useSelector(
    (state) => state.risk.workFlow.workFlowList
  );

  const [visible, setVisible] = useState(false);
  const [riskStatusList, setRiskStatusList] = useState([]);

  // ! ====================== Formik ==================== //
  const WorkFlowSchema = Yup.object().shape({
    workFlow: Yup.object().shape({
      addWorkFlowForm: Yup.object().shape({
        id: Yup.string().required(t("yup_select")),
        risk_status: Yup.string().required(t("yup_select")),
        status_mean: Yup.string().required(t("workflow_yup_status")),
      }),
    }),
  });

  const formik = useFormik({
    initialValues: reduxRisk,
    validationSchema: WorkFlowSchema,
    onSubmit: (values) => {
      const random1 = Math.floor(Math.random() * 1000);
      const random2 = Math.floor(Math.random() * 1000);
      const a = values.workFlow.addWorkFlowForm;
      const newPosition =
        (reduxRisk.workFlow.workFlowList.nodeDataArray.length - 1) * 60;
      const data = {
        id: a.id,
        risk_status: a.risk_status,
        risk_status_text: a.risk_status_text,
        status_mean: a.status_mean,
        key: `key_${random1}_${random2}`,
        name: a.name,
        type: a.type,
        category: "Box",
        loc: "100 " + parseInt(newPosition),
        text: `${a.name} - ${a.risk_status_text} - ${a.status_mean}`,
      };
      handleSubmitNewNode(data);
      setVisible(false);
    },
  });

  // console.log("values ==>", formik.values.workFlow.workFlowList);

  const handleSubmitNewNode = (data) => {
    formik.setFieldValue("workFlow.workFlowList", {
      ...formik.values.workFlow.workFlowList,
      nodeDataArray: [
        ...formik.values.workFlow.workFlowList.nodeDataArray,
        data,
      ],
    });
  };

  const handleChange = (model) => {
    formik.setFieldValue("values.workFlow.workFlowList", {
      class: "go.GraphLinksModel",
      linkFromPortIdProperty: "fromPort",
      linkToPortIdProperty: "toPort",
      ...model,
    });
  };

  const resetFormik = () => {
    formik.setFieldValue(
      "workFlow.addWorkFlowForm",
      scenario.workFlow.addWorkFlowForm
    );
    formik.setErrors({});
    formik.setTouched({});
  };

  const submitForm = () => {
    formik.handleSubmit();
  };

  const inputHasError = (string) => {
    const keys = string.split(".");
    let fieldTouched = formik.touched;
    let fieldErrors = formik.errors;
    keys.forEach((keyStr) => {
      fieldTouched = fieldTouched?.[keyStr];
      fieldErrors = fieldErrors?.[keyStr];
    });

    if (fieldTouched && fieldErrors) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    resetFormik();
  }, [visible]);

  useEffect(() => {
    dispatch(setRiskData(formik.values));
  }, [dispatch, formik.values]);
  // ! ====================== Formik ==================== //

  const handleModal = () => {
    setVisible(!visible);
  };

  // ! ====================== API ==================== //
  function postWorkFlow() {
    const postData = {
      workFlow: reduxWorkFlowList,
    };

    API.putData(
      `${apiRoutes.scenarios.put}/${reduxRisk.id}`,
      postData,
      (_) => {
        GL.notify.success({
          title: t("answer_success"),
          text: t("workflow_added"),
        });
        resetFormik();
      },
      (_) => {
        GL.notify.error({
          title: t("answer_error"),
          text: t("workflow_not_added"),
        });
        resetFormik();
      }
    );
  }

  const getRiskStatuses = () => {
    RiskStatus.getRiskStatusList(
      (data) => {
        const listData = RiskStatus.getArrayForSelect(data);
        setRiskStatusList(listData);
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const setUserType = (_, data) => {
    formik.setFieldValue("workFlow.addWorkFlowForm.type", data.type);
    formik.setFieldValue("workFlow.addWorkFlowForm.name", data.label);
  };

  useEffect(() => {
    getRiskStatuses();
  }, []);
  // ! ====================== API ==================== //

  // console.log("workflow list ==>", formik.values.workFlow.workFlowList);

  return (
    <>
      <div className="risk-page">
        <span className="risk-page__title">{t("scenario_workflow")}</span>
        <Button className="risk-page__add-button" onClick={handleModal}>
          {t("global_add")}
        </Button>
        <Diagram
          values={formik.values.workFlow.workFlowList}
          handleChange={handleChange}
        />

        <Button
          className="risk-page__add-button"
          onClick={postWorkFlow}
          type="primary"
          style={{ marginBlock: "16px" }}
        >
          {t("save_changes")}
        </Button>
      </div>

      {visible ? (
        <Modal
          style={{ height: 700 }}
          open={visible}
          width={550}
          onCancel={() => {
            resetFormik();
            setVisible(false);
          }}
          onOk={() => {
            submitForm();
          }}
          okText={"OK"}
          cancelText={"Cancel"}
        >
          <AddWorkFlow
            location={{ state: { id: 0, method: "create" } }}
            riskStatusList={riskStatusList}
            setUserType={setUserType}
            formik={formik}
            inputHasError={inputHasError}
            resetFormik={resetFormik}
            submitForm={submitForm}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default WorkFlow;
