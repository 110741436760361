import i18n from "i18n";
const { t } = i18n;

export const menuJSON = [
  {
    id: "scenario",
    icon: "audit",
    name: t("menu_scenario"),
    group: 1,
    permission: 8,
    visibilty: false,
    route: "/",
    sub: [
      {
        id: "scenario-list",
        name: t("menu_scenario_list"),
        route: "/",
        group: 2,
        permission: 1,
        visibilty: false
      },
      {
        id: "scenario-risk-group",
        name: t("menu_scenario_risk_status"),
        route: "/risk-statuses",
        group: 4,
        permission: 1,
        visibilty: false
      },
      {
        id: "scenario-risk-status",
        name: t("menu_scenario_risk_group"),
        route: "/risk-groups",
        group: 3,
        permission: 1,
        visibilty: false
      }
    ]
  },
  {
    id: "risk",
    icon: "audit",
    name: t("menu_risk"),
    group: 1,
    permission: 9,
    visibilty: false,
    route: "/",
    sub: [
      {
        id: "risk-active",
        name: t("menu_risk_list"),
        route: "/risk-list/user",
        group: 6,
        permission: 1,
        visibilty: false
      },
      {
        id: "risk-pool",
        name: t("menu_risk_pool"),
        route: "/risk-list/pool",
        group: 5,
        permission: 1,
        visibilty: false
      },
      {
        id: "risk-archive",
        name: t("menu_risk_archive"),
        route: "/risk-list/archive",
        group: 7,
        permission: 1,
        visibilty: false
      },
      {
        id: "risk-separating",
        name: t("menu_risk_separating"),
        route: "/risk-seperating",
        group: 8,
        permission: 1,
        visibilty: false
      }
    ]
  },
  {
    id: "user",
    icon: "team",
    name: t("menu_users"),
    group: 1,
    permission: 10,
    visibilty: false,
    route: "/",
    sub: [
      {
        id: "user-list",
        name: t("menu_users_list"),
        route: "/users",
        group: 9,
        permission: 1,
        visibilty: false
      },
      {
        id: "user-roles",
        name: t("menu_roles"),
        route: "/roles",
        group: 11,
        permission: 1,
        visibilty: false
      },
      {
        id: "user-groups",
        name: t("menu_user_groups"),
        route: "/user-groups",
        group: 12,
        permission: 1,
        visibilty: false
      },
      {
        id: "user-positions",
        name: t("menu_user_positions"),
        route: "/positions",
        group: 13,
        permission: 1,
        visibilty: false
      }
    ]
  },
  {
    id: "organization",
    icon: "bank",
    name: t("menu_organisation"),
    group: 1,
    permission: 11,
    visibilty: false,
    route: "/",
    sub: [
      {
        id: "organization-companies",
        name: t("menu_companies"),
        route: "/companies",
        group: 14,
        permission: 1,
        visibilty: false
      },
      {
        id: "organization-departments",
        name: t("menu_departments"),
        route: "/departments",
        group: 15,
        permission: 1,
        visibilty: false
      },
      {
        id: "organization-sectors",
        name: t("menu_sectors"),
        route: "/sectors",
        group: 16,
        permission: 1,
        visibilty: false
      }
    ]
  },
  {
    id: "others",
    icon: "bank",
    name: t("menu_other"),
    group: 1,
    permission: 13,
    visibilty: false,
    route: "/",
    sub: [
      {
        id: "others-activities",
        name: t("menu_other_activites"),
        route: "/activities",
        group: 17,
        permission: 1,
        visibilty: false
      },
      {
        id: "others-portfolios",
        name: t("menu_other_potfolios"),
        route: "/portfolios",
        group: 18,
        permission: 1,
        visibilty: false
      },
      {
        id: "enums",
        name: t("menu_other_enum_g"),
        group: 19,
        permission: 1,
        visibilty: false,
        sub: [
          {
            id: "others-enums",
            name: t("menu_other_enum_statics"),
            route: "/enums",
            group: 19,
            permission: 1,
            visibilty: true
          },
          {
            id: "others-enum-category",
            name: t("menu_other_enum_category"),
            route: "/enum-categories",
            group: 20,
            permission: 1,
            visibilty: true
          }
        ]
      },
      {
        id: "others-mylists",
        name: t("menu_mylist"),
        route: "/my-list",
        group: 21,
        permission: 1,
        visibilty: false,
        sub: []
      },
      {
        id: "others-languages",
        name: t("menu_lang"),
        route: "/languages",
        group: 23,
        permission: 1,
        visibilty: false,
        sub: []
      },
      {
        id: "others-logs",
        name: t("logs"),
        route: "/logs",
        group: 23,
        permission: 1,
        visibilty: false,
        sub: []
      },
      {
        id: "others-logs",
        name: t("calendar_title"),
        route: "/calendar",
        group: 23,
        permission: 1,
        visibilty: false,
        sub: []
      }
    ]
  },
  {
    id: "profile",
    icon: "profile",
    name: "Profil",
    group: 1,
    permission: 14,
    visibilty: false,
    route: "/profile#profile-general",
    sub: []
  }
];

export const mainMenuJSON = [
  {
    id: "main",
    name: "Ana səhifə",
    group: 1,
    permission: 8,
    visibilty: true,
    route: "/main",
    sub: []
  },
  {
    id: "/main/collaborators",
    name: "Əməkdaşlar və göstəriciləri",
    group: 1,
    permission: 8,
    visibilty: true,
    route: "/main/collaborators",
    sub: []
  },
  {
    id: "/main/operations",
    name: "Əməliyyatlarım",
    group: 1,
    permission: 8,
    visibilty: true,
    route: "/main/operations",
    sub: []
  },
  {
    id: "/main/rtms-messenger",
    name: "Mesajlar",
    group: 1,
    permission: 8,
    visibilty: true,
    route: "/main/rtms-messenger",
    sub: []
  },
  {
    id: "/main/messages",
    name: "Mail",
    group: 1,
    permission: 8,
    visibilty: true,
    route: "/main/messages?tab=inbox",
    sub: []
  },
  {
    id: "/main/library",
    name: "Kitabxana",
    group: 1,
    permission: 8,
    visibilty: true,
    route: "/main/library",
    sub: [
      {
        id: "/main/library/main",
        name: "Əsas səhifə",
        group: 1,
        permission: 8,
        visibilty: true,
        route: "/main/library/main",
        sub: []
      },
      {
        id: "/main/library/catalog",
        name: "Kataloq",
        group: 1,
        permission: 8,
        visibilty: true,
        route: "/main/library/catalog",
        sub: []
      },
      {
        id: "/main/library/my-files",
        name: "Mənim fayllarım",
        group: 1,
        permission: 8,
        visibilty: true,
        route: "/main/library/my-files",
        sub: []
      }
    ]
  },
  {
    id: "/main/resources",
    name: "Resurslar",
    group: 1,
    permission: 8,
    visibilty: true,
    route: "/main/resources",
    sub: [
      {
        id: "/main/resources/product-chain",
        name: "Mal zənciri",
        group: 1,
        permission: 8,
        visibilty: true,
        route: "/main/resources/product-chain",
        sub: []
      },
      {
        id: "/main/resources/corporate-tree",
        name: "Korporativ ağac",
        group: 1,
        permission: 8,
        visibilty: true,
        route: "/main/resources/corporate-tree",
        sub: []
      },
      {
        id: "/main/resources/profile",
        name: "Profil məlumatları",
        group: 1,
        permission: 8,
        visibilty: true,
        route: "/main/resources/profile",
        sub: []
      }
    ]
  },
  {
    id: "/main/rvo",
    name: "RVÖ Modulu",
    group: 1,
    permission: 8,
    visibilty: true,
    route: "/main/rvo",
    sub: []
  },
  {
    id: "/main/edvdh",
    name: "ƏDVDH",
    group: 1,
    permission: 8,
    visibilty: true,
    route: "/main/edvdh",
    sub: []
  },
  {
    id: "/main/reports",
    name: "Hesabatlar",
    group: 1,
    permission: 8,
    visibilty: true,
    route: "/main/reports",
    sub: []
  }
];

export let menuIdList = [];

menuJSON.forEach((menu, menuIdx) => {
  const id = menuIdx + 1;
  menu.id = `${id}0`;
  menuIdList.push(menu.id);

  if (!menu.sub.length) return;

  menu.sub.forEach((subMenu, subMenuIdx) => {
    const subId = subMenuIdx + 1;
    subMenu.id = `${id}${subId}`;
  });
});
