export const apiRoutes = {
  // upload: {
  //   image: "image",
  //   file: "file",
  //   video: "video",
  // },
  // admin: {
  //   login: "login",
  //   logout: "logout",
  //   pages: "pages",
  //   subPages: "sub/pages",
  // },
  pages: {
    perms: "pages/pagePermission",
  },
  user: {
    list: "users?isActive=true",
    search: "users/search",
  },
  activities: {
    get: "activities/{id}",
    add: "activities",
    put: "activities",
    list: "activities?isActive=true",
  },
  departments: {
    get: "departments/{id}",
    add: "departments",
    search: "departments/search",
    put: "departments",
    list: "departments?isActive=true",
  },
  companies: {
    get: "companies/{id}",
    add: "companies",
    put: "companies",
    list: "companies?isActive=true",
  },
  enums: {
    get: "enums/{id}",
    add: "enums",
    put: "enums",
    list: "enums?isActive=true",
    search: "enums/search?categoryId=",
  },
  portfolios: {
    get: "portfolios/{id}",
    add: "portfolios",
    put: "portfolios",
    list: "portfolios?isActive=true",
  },
  logs: {
    list: "logs",
    details: (logId) => `logs/details/${logId}`,
  },
  enumCategories: {
    get: "enumCategories",
    add: "enumCategories",
    put: "enumCategories",
    delete: "enumCategories",
    list: "enumCategories?isActive=true",
  },
  userGroups: {
    get: "userGroups/{id}",
    add: "userGroups",
    put: "userGroups",
    list: "userGroups?isActive=true",
    getByID: "userGroups/",
    search: "userGroups/search?",
  },
  companyPortfolios: {
    get: "companies/getById/{id}",
    add: "companies",
    put: "companies",
    list: "companies?isActive=true",
  },
  riskGroups: {
    get: "riskGroup/{id}",
    add: "riskGroup",
    put: "riskGroup",
    list: "riskGroup?isActive=true",
  },
  riskStatuses: {
    get: "risksStatus/{id}",
    add: "risksStatus",
    put: "risksStatus",
    list: "risksStatus?isActive=true",
  },
  sectors: {
    get: "sectors",
    add: "sectors",
    put: "sectors",
    list: "sectors/?isActive=true",
    search: "sectors/search",
  },
  positions: {
    get: "positions/{id}",
    add: "positions",
    put: "positions",
    list: "positions?isActive=true",
  },
  parameters: {
    search: "parameters/search?scenarioId=",
    get: "parameters",
    add: "parameters",
    delete: "parameters",
    put: "parameters",
    list: "parameters?isActive=true",
  },
  users: {
    lgn: "sign/in",
    add: "users/register",
    get: "users/getMeByToken",
    put: "users/",
    getAll: "users",
    getById: "users/",
    delete: "users/",
    deleteCheck: "users/check/",
    updatePassword: "users/password/",
    refresh: "sign/refresh",
    searchExecutor: "users/search-executor?",
  },
  scenarios: {
    get: "scenarios",
    add: "scenarios",
    put: "scenarios",
    delete: "scenarios",
    copy: "scenarios/dublicate",
    list: "scenarios?isActive=true",
    config: "scenarios/updateUserInScenarios",
  },
  stages: {
    list: "stages?isActive=true",
    add: "stages",
    put: "stages",
    get: "stages",
  },
  roles: {
    list: "roles",
    add: "roles",
    put: "roles",
    get: "roles",
    getUserRole: "roles/code?key=",
  },
  separating: {
    list: "separating?isActive=true",
    add: "separating",
    put: "separating",
    get: "separating",
    delete: "separating",
  },
  risks: {
    list: "risks",
    getByID: "risks/",
    assignRisk: "risks/assignRisk/",
    sendRisk: "risks/sendRisk",
    getRiskFullData: "engine/getRiskFullData/",
    export: "risks/",
    details: "risks/detail",
    getFiles: (id, itemId) => `risks/${id}/get-file${itemId ? "?itemId=" + itemId : ""}`,
    approve: "risks/approve",
    sendBack: "risks/send-back",
    setExecute: "risks/set-execute",
    save: "risks/save",
  },
  riskFiles: {
    upload: "riskfiles/upload/",
    download: "riskfiles/download/",
    delete: (riskID, fieldID, roleId) =>
      `riskfiles/delete/${riskID}/${fieldID}/${roleId}`,
  },
  riskInvoice: {
    getInvoice: (riskId) => `risk-invoice/${riskId}`,
    putInvoice: "risk-invoice",
    getInvoiceItem: (itemId) => `risk-invoice/item/${itemId}`,
    saveInvoice: "risk-invoice/save",
  },
  comments: {
    post: "comments/comment",
  },
  myList: {
    list: "mylists?isActive=true",
    add: "mylists",
    get: "mylists",
    put: "mylists",
    delete: "mylists",
  },
  sublist: {
    get: "sublist",
    post: "sublist",
    put: "sublist",
    delete: "sublist",
  },
  languages: {
    get: "languages",
    post: "languages",
    put: "languages",
    delete: "languages",
    code: "languages",
    list: "languages",
  },
  profile: {
    get: "profil",
  },
  python: {
    get: "python",
    post: "python",
    put: "python",
    delete: "python",
    list: "python",
  },
  calendar: {
    get: "calendar",
    post: "calendar",
    getByID: (id) => `calendar/${id}`,
    put: (id) => `calendar/${id}`,
    delete: (id) => `calendar/${id}`,
  },
  resend: {
    getNumbers: "/resend/get-risk-number",
  },
  decision: {
    getDecisionByRiskId: (id) => `decision/${id}`,
    post: "decision",
    put: "decision",
  },
  library: {
    getLastAdded: "library/last-added",
    getLastViewed: "library/last-viewed",
    shareFileOrFolder: "ff-user-rel",
    deleteFromSharedFileOrFolder: "ff-user-rel",
    postFileToLastWatched: ({ fileId }) => `library/${fileId}/add-recent`,
    renameFolder: ({ folderId }) => `library/folder/${folderId}`,
    renameFile: ({ id }) => `library/${id}/rename`,
    deleteFileOrFolder: ({ id }) => `library/${id}`,
    postFile: "library/file",
    getLastSharedUsers: "library/my-last-shared-users",
    addFileToFavorites: ({ id }) => `favourite?id=${id}`,
    deleteFromFavorites: ({ id }) => `favourite?id=${id}`,
    getCreatedByList: ({ isCatalog }) => `library/created-list?isCatalog=${isCatalog}`,
  },
  mail: {
    getInbox: "messages/mail/inbox",
    getSend: "messages/mail/send",
    getMailDescription: "messages/mail",
    postReplyMail: "messages",
    postForwardMail: "messages",
    deleteMailWithType: "messages/mail",
    sendNewMail: "messages",
    uploadFile: "message-file",
    removeFile: "message-file",
    getMailSearch: "messages/mail/search",
    getUsers: "users",
  },
  chat: {
    getChats: "messages/chats",
    getGroups: "message-group/groups",
    getContacts: "message-group/contacts",
    getChatInfo: "messages/chats",
    getChatMessages: "messages/chats",
  },
  collaborators: {
    getAllCollaborators: ({ roleId, queryString }) =>
      `risks/collaborators?roleId=${roleId}${queryString ? "&" + queryString : ""}`,
    getAllCollaboratorsCount: "risks/collaborators-count",
    getSectorSelectData: ({ roleId }) => `sectors/select?roleId=${roleId}`,
    getGroupSelectData: ({ roleId, sectorId }) =>
      `userGroups/select?roleId=${roleId}${sectorId ? "&sectorId=" + sectorId : ""}`,
    getUsersSelectData: ({ roleId, group }) =>
      `users/select?roleId=${roleId}${group ? "&group=" + group : ""}`,
  },
};
