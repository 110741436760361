import { useState } from "react";

import { apiRoutes } from "const/apiroutes";
import API from "const/API2";
import GL from "libs/GL";
import { useEffect } from "react";

const api = new API();

function useShareFileOrFolder({ fileOrFolderId, getLastTableData }) {
  const [showShareModal, setShowShareModal] = useState(false);
  const [lastSharedUsers, setLastSharedUsers] = useState([]);

  const openShareModal = () => {
    setShowShareModal(true);
  };
  const closeShareModal = () => {
    setShowShareModal(false);
  };

  const submitShareFileOrFolder = (formikValues) => {
    const postData = {
      userIds: formikValues.userIds,
      catalog: formikValues.catalog,
      ffId: fileOrFolderId,
    };

    api.postData(
      apiRoutes.library.shareFileOrFolder,
      postData,
      (_) => {
        getLastTableData();
        GL.notify.success({ text: "Uğurla paylaşıldı" });
      },
      (err) => {
        GL.notify.error({ text: err.message ?? "Paylaşıla bilmədi" });
      }
    );
  };

  const getLastSharedUsers = () => {
    api.getData(
      apiRoutes.library.getLastSharedUsers,
      (data) => {
        setLastSharedUsers(data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const deleteFileOrFolderFromShared = ({ userIds }) => {
    api.deleteData(
      apiRoutes.library.deleteFromSharedFileOrFolder,
      () => {
        getLastTableData();
        GL.notify.success({ text: "Paylaşılan şəxslərdən silindi" });
      },
      () => {},
      {
        ffId: fileOrFolderId,
        userIds: userIds,
      }
    );
  };

  useEffect(() => {
    getLastSharedUsers();
  }, []);

  return {
    openShareModal,
    closeShareModal,
    submitShareFileOrFolder,
    deleteFileOrFolderFromShared,
    lastSharedUsers,
    showShareModal,
  };
}

export default useShareFileOrFolder;
