import React from "react";

const Success = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.7215 80.4561C28.8687 78.4612 31.4159 77.7742 33.4107 78.9214C36.2738 80.5681 39.3673 81.7768 42.5884 82.5074C44.8326 83.0164 46.2392 85.2483 45.7302 87.4925C45.2211 89.7367 42.9892 91.1433 40.745 90.6343C36.7128 89.7197 32.8403 88.2066 29.2561 86.1453C27.2613 84.998 26.5742 82.4509 27.7215 80.4561Z"
        fill="#09A552"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.2713 12.5013C54.7837 10.2579 57.0177 8.85461 59.2611 9.36698C68.4652 11.4691 76.6829 16.6338 82.5689 24.0156C88.4548 31.3974 91.6602 40.5588 91.6602 49.9999C91.6602 59.441 88.4548 68.6024 82.5689 75.9842C76.6829 83.366 68.4652 88.5307 59.2611 90.6328C57.0177 91.1452 54.7837 89.7419 54.2713 87.4985C53.7589 85.255 55.1622 83.021 57.4056 82.5087C64.7695 80.8268 71.3442 76.6947 76.0533 70.7889C80.7624 64.883 83.3268 57.5533 83.3268 49.9999C83.3268 42.4464 80.7624 35.1168 76.0533 29.2109C71.3442 23.305 64.7695 19.1729 57.4056 17.4911C55.1622 16.9788 53.7589 14.7447 54.2713 12.5013Z"
        fill="#09A552"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8095 56.4182C15.0202 55.7791 17.3304 57.0531 17.9695 59.2637C18.9585 62.6851 20.4922 65.9248 22.5114 68.8585C23.8161 70.7541 23.3372 73.3484 21.4416 74.6531C19.546 75.9579 16.9517 75.4789 15.647 73.5833C13.1204 69.9126 11.2015 65.859 9.96394 61.5781C9.32485 59.3674 10.5989 57.0573 12.8095 56.4182Z"
        fill="#09A552"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4903 27.6889C21.5031 28.8042 22.2307 31.3401 21.1154 33.3529L20.4358 34.5795C18.8479 37.6226 17.7153 40.9399 17.1255 44.4421C16.7433 46.7113 14.5939 48.2411 12.3247 47.8589C10.0555 47.4767 8.52571 45.3273 8.90789 43.0581C9.64864 38.6599 11.075 34.4917 13.0755 30.6714L13.1221 30.5848L13.8263 29.314C14.9416 27.3012 17.4775 26.5736 19.4903 27.6889Z"
        fill="#09A552"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.7297 12.5057C46.2396 14.7497 44.8339 16.9822 42.5899 17.4921C38.4901 18.4238 34.6049 20.1271 31.1417 22.5111C29.2463 23.816 26.6519 23.3372 25.347 21.4417C24.0422 19.5462 24.521 16.9518 26.4165 15.647C30.7505 12.6635 35.6125 10.5319 40.7433 9.36598C42.9873 8.85604 45.2198 10.2618 45.7297 12.5057Z"
        fill="#09A552"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.4463 38.7204C67.0735 40.3476 67.0735 42.9858 65.4463 44.6129L48.7797 61.2796C47.1525 62.9068 44.5143 62.9068 42.8871 61.2796L34.5538 52.9463C32.9266 51.3191 32.9266 48.6809 34.5538 47.0537C36.1809 45.4265 38.8191 45.4265 40.4463 47.0537L45.8334 52.4408L59.5538 38.7204C61.1809 37.0932 63.8191 37.0932 65.4463 38.7204Z"
        fill="#09A552"
      />
    </svg>
  );
};

export default Success;
