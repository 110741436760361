import cookie from "react-cookies";
import moment from "moment";
import { admin, user } from "../../const/api-delete";
import history from "./../../const/history";
import { apiRoutes } from "../../const/apiroutes";
import API from "../../const/API2";
import {
  LOADING_ON,
  LOADING_OFF,
  LOG_OUT,
  SET_NOTIFICATION,
  SET_STOCK,
  SET_USER,
  SET_USER_ROLE,
  SET_RISK_DATA,
  SET_COMPANY_DATA,
  SET_TOKEN,
  SET_DEPARTMENT_DATA,
  SET_SECTOR_DATA,
  SET_RISK_GROUP_DATA,
  SET_RISK_STATUS_DATA,
  SET_POSITION_DATA,
  SET_ENUM_DATA,
  CLEAR_COMPANY,
  SET_ROLES_DATA,
  SET_RISK_SEPERATE,
  SET_MY_LIST_DATA,
  SET_LANGUAGES,
  SET_PYTHON,
} from "./../types";

var api = new API();

export const getStock = () => async (dispatch) => {
  await admin.get("auth/stockinfo").then((res) => {
    dispatch({
      type: SET_STOCK,
      payload: res.data,
    });
  });
};

export const getUserData = () => async (dispatch) => {
  admin.dispatch = dispatch;

  dispatch({ type: LOADING_ON });
  await user
    .get("/userData")
    .then(({ data }) => {
      dispatch({
        type: SET_USER,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: LOG_OUT,
      });
    })
    .finally(() => {
      dispatch({ type: LOADING_OFF });
    });
};

export const logInUser = (username, password) => (dispatch) => {
  dispatch({ type: LOADING_ON });
  api.postData(
    apiRoutes.users.lgn,
    { pin: username, password: password },
    (res) => {
      const token = res.accessToken;
      dispatch({
        type: SET_TOKEN,
        payload: token,
      });
      api.getData(apiRoutes.users.get, (data) => {
        const roles = data.roles;
        const hasActiveRole = roles?.some((item) => item.status);
        if (!hasActiveRole) roles[0].status = true;
        var userData = {
          token: token,
          refreshToken: res.refreshToken,
          expirationDate: res.expirationDate,
          user: {
            id: data.id,
            name: `${data.firstName} ${data.lastName}`,
            username: data.pin,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            gender: data.gender,
            isActive: data.isActive,
            patronymic: data.patronymic,
            phone: data.phone,
            birthday: data.birthday,
            dateCreated: data.dateCreated,
            company: data.companiesDto == null ? "" : data.companiesDto.name,
            department:
              data.departmentsDto == null ? "" : data.departmentsDto.name,
            sector: data.sectorsDto == null ? "" : data.sectorsDto.name,
            mission: data.positionDto == null ? "" : data.positionDto.name,
            img:
              data.photoPath == null
                ? "http://localhost:3000/static/media/women.d15150db.jfif"
                : "",
          },
          roles,
        };
        dispatch({
          type: SET_USER,
          payload: userData,
        });
        dispatch({ type: LOADING_OFF });
      });
    },
    () => {
      dispatch({ type: LOADING_OFF });
    }
  );
};

export const toggleLoading = (payload) => ({
  type: payload ? LOADING_ON : LOADING_OFF,
});

export const logOut = () => {
  cookie.remove("accessToken");
  return {
    type: LOG_OUT,
  };
};

export const notify = (description, isHappy) => {
  return {
    type: SET_NOTIFICATION,
    payload: { description, isHappy },
  };
};

// ======================================== RTMS ==================================== //
// ======================================== RTMS ==================================== //

export const setUserRole = (newRole) => {
  history.push("/");
  return {
    type: SET_USER_ROLE,
    payload: newRole,
  };
};

export const setRiskData = (riskData) => {
  return {
    type: SET_RISK_DATA,
    payload: riskData,
  };
};

export const setRolesData = (rolesData) => {
  return {
    type: SET_ROLES_DATA,
    payload: rolesData,
  };
};

export const setRiskSeperating = (rolesData) => {
  return {
    type: SET_RISK_SEPERATE,
    payload: rolesData,
  };
};

export const setCompanyData = (data) => {
  return {
    type: SET_COMPANY_DATA,
    payload: data,
  };
};
export const clearCompanyData = (data) => {
  //debugger;
  return {
    type: CLEAR_COMPANY,
    payload: data,
  };
};

export const setDepartmentData = (departamentData) => {
  return {
    type: SET_DEPARTMENT_DATA,
    payload: departamentData,
  };
};

export const setSectorData = (sectorData) => {
  return {
    type: SET_SECTOR_DATA,
    payload: sectorData,
  };
};

export const setRiskGroupData = (riskGroupData) => {
  return {
    type: SET_RISK_GROUP_DATA,
    payload: riskGroupData,
  };
};

export const setRiskStatusData = (riskStatusData) => {
  return {
    type: SET_RISK_STATUS_DATA,
    payload: riskStatusData,
  };
};

export const setPositionData = (positionData) => {
  return {
    type: SET_POSITION_DATA,
    payload: positionData,
  };
};

export const setEnumData = (enumData) => {
  return {
    type: SET_ENUM_DATA,
    payload: enumData,
  };
};

export const setMyListData = (myListData) => {
  return {
    type: SET_MY_LIST_DATA,
    payload: myListData,
  };
};

export const setPython = (pythonData) => {
  return {
    type: SET_PYTHON,
    payload: pythonData,
  };
};

export const setLanguagesData = (languagesData) => {
  return {
    type: SET_LANGUAGES,
    payload: languagesData,
  };
};
