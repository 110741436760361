import React, { useState, useEffect } from "react";
import { Space, Input, Button, Row, Col, Modal } from "antd";
import { useSelector } from "react-redux";
import ExcelFunctions from "./ExcelFunctions";
import Variables from "./Variables";
import Operators from "./Operators";
import {
  DeleteOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import "./FormulBuilder.css";
import { useTranslation } from "react-i18next";

const FormulBuilder = (props) => {
  const { t } = useTranslation();
  const reduxScenarioId = useSelector((s) => s.risk);
  const { formik, formikPath } = props;
  const [visible, setVisible] = useState(false);
  var allparameters = [];
  if (reduxScenarioId.info.info.parameters.length > 0) {
    reduxScenarioId.info.info.parameters.map((a) => {
      allparameters.push({
        id: a.id,
        label: a.name,
        name: a.name,
        format: [{ type: "rule", color: "#000000", value: `[${a.code}]` }],
      });
    });
  }
  var data = {
    formuls: ExcelFunctions,
    types: Variables,
    parameters: allparameters,
    operators: Operators,
    result: [],
  };
  const { formuls, parameters, result, types, operators } = data;
  const [formul, setFormul] = useState(
    formik.getFieldProps(`${formikPath}.formul`).value || []
  );
  const [current, setCurrent] = useState([]);

  function placeElementInNestedArray(arr, addressArr, element) {
    let currentArray = arr;
    for (let i = 0; i < addressArr.length - 1; i++) {
      const index = parseInt(addressArr[i]);
      if (isNaN(index) || index >= currentArray.length) {
        throw new Error(t("invalid_address"));
      }
      if (currentArray.value !== undefined) {
        currentArray = currentArray.value[index];
      } else {
        currentArray = currentArray[index];
      }
    }

    const lastIndex = parseInt(addressArr[addressArr.length - 1]);
    if (isNaN(lastIndex) || lastIndex > currentArray.length) {
      throw new Error(t("invalid_address"));
    }

    var format = element.format;
    if (currentArray.value !== undefined) {
      currentArray.value[lastIndex].value = format;
    } else {
      currentArray[lastIndex].value = format;
    }
    return arr;
  }

  const openModal = (curr) => {
    setVisible(true);
    setCurrent(curr);
  };

  const handleModalClose = (data) => {
    setVisible(false);
    setCurrent([]);
  };

  const addToFormul = (data) => {
    onClick(current, data);
    setVisible(false);
    setCurrent([]);
  };
  const customDataAdd = (type) => {
    var idinput = "formule_type_" + type;
    var inpt = document.getElementById(idinput);
    if (inpt == null) {
      return false;
    }
    var val = inpt.value;
    if (val == "") {
      return false;
    }
    var types = ["number", "string", "date"];
    if (types.indexOf(type) == -1) {
      return false;
    }
    switch (type) {
      case "number": {
        addToFormul({
          name: val,
          type: "variable",
          format: [{ type: "rule", color: "#03a9f4", value: val }],
        });
        break;
      }
      case "string": {
        addToFormul({
          name: `"${val}"`,
          type: "variable",
          format: [{ type: "rule", color: "#03a9f4", value: `"${val}"` }],
        });
        break;
      }
      case "date": {
        var d = new Date(val);
        var y = d.getFullYear();
        var m = d.getMonth() + 1;
        var dy = d.getDate();
        addToFormul({
          name: `"${val}"`,
          type: "variable",
          format: [
            { type: "rule", color: "#03a9f4", value: `DATE(${y},${m},${dy})` },
          ],
        });
        break;
      }
    }
  };

  const onClick = (curr, ekle) => {
    var d = JSON.parse(JSON.stringify(formul));
    if (d.length == 0) {
      setFormul(ekle.format);
    } else {
      var e = placeElementInNestedArray(d, curr, ekle);
      setFormul(d);
    }
    // showResult();
  };

  const formulClear = () => {
    setFormul([]);
  };

  const formulRemove = (willDelete) => {
    if (willDelete.length == 0) {
      setFormul([]);
      return false;
    }
    var arr = JSON.parse(JSON.stringify(formul));
    if (!Array.isArray(arr) || !Array.isArray(willDelete)) {
      return arr;
    }
    let result = [...arr];
    let currentLevel = result;

    for (let i = 0; i < willDelete.length; i++) {
      const index = willDelete[i];
      if (index >= currentLevel.length) {
        return arr;
      }
      if (currentLevel.value !== undefined) {
        currentLevel = currentLevel.value[index];
      } else {
        currentLevel = currentLevel[index];
      }
    }
    if (currentLevel.value !== undefined) {
      currentLevel.value = [];
    } else {
      currentLevel = [];
    }
    setFormul(result);
    // showResult();
  };

  const hasError = () => {
    var pluses = document.getElementsByClassName("formbuilder_plus_icons");
    if (pluses.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const showResult = () => {
    // ! ========================================================================
    // if (isHaveError()) {
    //   //error var altta kirmizi yaziyi goster
    //   var formuls = document.getElementsByClassName("formul-res");
    //   var formulString = formuls[0].textContent;
    //   var gonderilecek = { formulString, formul };

    // } else {
    //   // error yok formike esitlenebilir
    //   setToFormik();
    // }
    // ! ========================================================================
    const formuls = document.getElementsByClassName("formul-res");
    const formulString = formuls[0].textContent;
    formik.setFieldValue(`${formikPath}.result`, formulString);
    formik.setFieldValue(`${formikPath}.formul`, formul);

    /*
    result
    resultValid
    formul
    */

    const formikHasError = hasError();
    if (formikHasError) {
      formik.setFieldValue(`${formikPath}.resultValid`, "");
      formik.setFieldTouched(`${formikPath}.resultValid`, true);
    } else {
      formik.setFieldValue(`${formikPath}.resultValid`, "Formula");
    }
  };

  // ! =========================================================================
  // const setToFormik = () => {
  //   var formuls = document.getElementsByClassName("formul-res");
  //   var pluses = document.getElementsByClassName("formbuilder_plus_icons");
  //   if (pluses.length > 0) {
  //     GL.notify.warning({
  //       title: "Formul Hatali",
  //       text: "Lutfen Netice Formulunuzu Dogru Giriniz",
  //     });
  //     return false;
  //   }
  //   var formulString = formuls[0].textContent;
  //   var gonderilecek = { formulString, formul };

  // };
  // ! =========================================================================

  const renderData = (data, parentIndex = []) => {
    var len = data.length;
    if (len > 0) {
      return data.map((item, index) => {
        if (item.type === "rule") {
          return (
            <span style={{ color: item.color, height: "25px" }} key={index}>
              {item.value}
              {index == len - 1 ? (
                <DeleteOutlined
                  className="formbuilder_delete_icons"
                  onClick={() => {
                    formulRemove(parentIndex);
                  }}
                  style={{ color: "#f44336" }}
                />
              ) : null}
            </span>
          );
        } else if (item.type === "div") {
          const currentIndex = [...parentIndex, index]; // parentIndex array'ine güncel index ekleniyor
          return (
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "2px",
                marginLeft: "2px",
                height: "25px",
              }}
              key={index}
              //onDrop={(event) => onDrop(event, "formul")}
              data-index={currentIndex.join("-")}
              data-sira={data.indexOf(item)}
            >
              {renderData(item.value, currentIndex)}{" "}
              {/* renderData fonksiyonu parentIndex ile çağrılıyor */}
            </span>
          );
        } else if (item.type === "param") {
          return (
            <span style={{ height: "25px" }} key={index}>
              {item.label}
            </span>
          );
        } else if (item.type === "variable") {
          return (
            <span style={{ height: "25px" }} key={index}>
              {item.value.toString()}
            </span>
          );
        } else {
          return null;
        }
      });
    } else {
      const currentIndex = [...parentIndex];
      return (
        <span
          data-index={currentIndex.join("-")}
          //onDrop={(event) => onDrop(event, "formul")}
          onClick={(event) => {
            openModal(currentIndex);
          }}
          className="quessign"
        >
          <PlusCircleOutlined className="formbuilder_plus_icons" />
        </span>
      );
    }
  };

  useEffect(() => {
    showResult();
  }, [formul]);

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          overflowX: "scroll",
        }}
        className="formul-res"
        //onDrop={(event) => onDrop(event, "formul")}
      >
        {renderData(formul, [])}
        {formul.length !== 0 ? (
          <CloseCircleOutlined
            onClick={() => {
              formulClear();
            }}
            className="formbuilder_delete_icons"
            style={{
              cursor: "pointer",
              color: "#0000FF",
              marginTop: "5px",
              marginLeft: "10px",
            }}
          />
        ) : null}
      </div>
      <Modal
        title={t("formul_use_items")}
        open={visible}
        width={1200}
        onCancel={handleModalClose}
        footer={null}
      >
        <div>
          <Row gutter={24} style={{ marginTop: "20px" }}>
            <Col sm={24} md={12} lg={6}>
              <div className="formul-h1" style={{ backgroundColor: "#ffc107" }}>
                Variables
              </div>
              <Row gutter={24}>
                {types.map((t, index) => (
                  <Col
                    sm={24}
                    md={12}
                    lg={8}
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                  >
                    <Button
                      key={index}
                      block
                      draggable
                      onClick={(event) => addToFormul(t)}
                      style={{ marginBottom: "5px", padding: "0" }}
                    >
                      {t.name}
                    </Button>
                  </Col>
                ))}
                <Col sm={24} md={24} lg={24} style={{ marginBottom: "10px" }}>
                  <Space.Compact>
                    <Input style={{ width: "35%" }} defaultValue="Num" />
                    <Input
                      id="formule_type_number"
                      style={{ width: "65%" }}
                      defaultValue=""
                      type="number"
                    />
                    <Button
                      onClick={() => {
                        customDataAdd("number");
                      }}
                    >
                      <PlusOutlined />
                    </Button>
                  </Space.Compact>
                </Col>
                <Col sm={24} md={24} lg={24} style={{ marginBottom: "10px" }}>
                  <Space.Compact>
                    <Input style={{ width: "35%" }} defaultValue="Str" />
                    <Input
                      id="formule_type_string"
                      style={{ width: "65%" }}
                      defaultValue=""
                      type="text"
                    />
                    <Button
                      onClick={() => {
                        customDataAdd("string");
                      }}
                    >
                      <PlusOutlined />
                    </Button>
                  </Space.Compact>
                </Col>
                <Col sm={24} md={24} lg={24} style={{ marginBottom: "10px" }}>
                  <Space.Compact>
                    <Input style={{ width: "35%" }} defaultValue="Date" />
                    <Input
                      id="formule_type_date"
                      style={{ width: "65%" }}
                      defaultValue=""
                      type="date"
                    />
                    <Button
                      onClick={() => {
                        customDataAdd("date");
                      }}
                    >
                      <PlusOutlined />
                    </Button>
                  </Space.Compact>
                </Col>
              </Row>
            </Col>
            <Col sm={24} md={12} lg={6}>
              <div className="formul-h1" style={{ backgroundColor: "#ffc107" }}>
                {t("formul_functions")}
              </div>
              <Row
                gutter={24}
                style={{ paddingLeft: "7px", paddingRight: "7px" }}
              >
                {formuls.map((t, index) => (
                  <Col
                    sm={24}
                    md={12}
                    lg={6}
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      paddingRight: "5px",
                      paddingLeft: "5px",
                    }}
                  >
                    <Button
                      key={index}
                      block
                      draggable
                      onClick={(event) => addToFormul(t)}
                    >
                      {t.name}
                    </Button>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col sm={24} md={12} lg={6}>
              <div className="formul-h1" style={{ backgroundColor: "#ffc107" }}>
                {t("operators")}
              </div>
              <Row
                gutter={24}
                style={{ paddingLeft: "7px", paddingRight: "7px" }}
              >
                {operators.map((t, index) => (
                  <Col
                    sm={24}
                    md={12}
                    lg={6}
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      paddingRight: "5px",
                      paddingLeft: "5px",
                    }}
                  >
                    <Button
                      key={index}
                      block
                      draggable
                      onClick={(event) => addToFormul(t)}
                      style={{ marginBottom: "5px", padding: "0" }}
                    >
                      {t.name}
                    </Button>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col sm={24} md={12} lg={6}>
              <div className="formul-h1" style={{ backgroundColor: "#ffc107" }}>
                {t("parameters")}
              </div>
              {parameters.map((t, index) => (
                <Button
                  style={{ margin: "2px" }}
                  key={index}
                  block
                  draggable
                  onClick={(event) => addToFormul(t)}
                >
                  {t.name}
                </Button>
              ))}
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default FormulBuilder;
