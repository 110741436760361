import ApiMethods from "const/API2";
import { apiRoutes } from "const/apiroutes";

const API = new ApiMethods();

class Resend {
  static getResendRiskNumber(roleId, callback, errCallback) {
    if (!roleId) return;
    let url = `${apiRoutes.resend.getNumbers}?roleId=${roleId}`;
    API.getData(
      url,
      (data) => callback && callback(data),
      (error) => errCallback && errCallback(error)
    );
  }
}

export default Resend;
