import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PageTitle from "components/Elements/PageTitle/PageTitle";
import FormSubmit from "components/Elements/FormSubmit/FormSubmit2";
import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import { languages } from "const/initialDatas";
import Languages from "models/Languages";
import { setLanguagesData } from "redux/actions/index";
import GL from "libs/GL";
import { useTranslation } from "react-i18next";

function LanguagesEdit(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const pageState = props.location.state;
  const method = pageState?.method || "";
  const id = pageState?.id || "";

  const reduxLanguages = useSelector((state) => state.languages);

  let initialValues;
  if (method === "create") {
    initialValues = languages;
  }
  if (method === "edit" || method === "create2") {
    initialValues = reduxLanguages;
  }

  const validationSchema = Yup.object().shape({
    info: Yup.object().shape({
      code: Yup.string().required(t("yup_code")),
      az: Yup.string().required(t("yup_value")),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (method === "create2") {
        Languages.CREATE(values.info, () => {
          history.replace("/languages");
        });
      }
      if (method === "edit") {
        Languages.EDIT(id, values.info, () => {
          history.replace("/languages");
        });
      }
    },
  });

  const submitForm = () => {
    formik.handleSubmit();
  };

  const resetFormik = () => {
    formik.setValues(languages);
  };

  useEffect(() => {
    formik.setFieldTouched("setFormikTouched");
    if (method === "edit") {
      Languages.GET(id, (data) => {
        formik.setFieldValue("info", data);
      });
    }
  }, []);

  useEffect(() => {
    dispatch(setLanguagesData(formik.values));
  }, [formik.values]);

  useEffect(() => {
    if (method === "create" && Object.keys(formik.touched).length) {
      history.replace({ state: { method: "create2" } });
    }
  }, [formik.touched]);

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={method == "edit" ? t("language_edit") : t("language_add")}
        small={t("language_desc")}
      />
      <div className="risk-page">
        <div className="risk-page__elements">
          <h2>{t("global_info")}</h2>
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label="ID"
                formik={formik}
                field="id"
                type="number"
                required={false}
                visibilty={method == "edit" ? true : false}
                disabled={method == "edit" ? true : false}
                isFake={true}
              />
              <InputBox
                label={t("global_code")}
                formik={formik}
                field="code"
                handleChange={(str) => GL.createFieldForColumn(str)}
                required={true}
                isFake={method == "edit" ? true : false}
                disabled={method == "edit" ? true : false}
              />
              <InputBox
                label={t("global_lang_az")}
                formik={formik}
                field="az"
                required={true}
              />
              <InputBox
                label={t("global_lang_tr")}
                formik={formik}
                field="tr"
                required={false}
              />
            </Col>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label={t("global_lang_ru")}
                formik={formik}
                field="ru"
                required={false}
              />
              <InputBox
                label={t("global_lang_en")}
                formik={formik}
                field="en"
                required={false}
              />
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit cancel={resetFormik} submit={submitForm} />
        </div>
      </div>
    </div>
  );
}

export default LanguagesEdit;
