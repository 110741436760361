import { Calendar, momentLocalizer } from "react-big-calendar";
import CalendarModel from "models/Calendar";
import moment from "moment";
import React, { useRef, useState } from "react";

import { Modal } from "antd";
import { useEffect } from "react";

const localizer = momentLocalizer(moment);

const MyCalendar = ({ show, setShowModal }) => {
  // ! ========================== EVENTS DATA SAMPLE ==========================
  const eventsArr = [
    {
      id: 0,
      title: "All Day Event very long title",
      allDay: true,
      start: new Date(2015, 3, 0),
      end: new Date(2015, 3, 1),
    },
    {
      id: 1,
      title: "Event 2",
      allDay: true,
      start: new Date(2015, 3, 1),
      end: new Date(2015, 3, 2),
    },
  ];
  // ! ========================== EVENTS DATA SAMPLE ==========================

  // ! ========================= EVENTS API REAL DATA =========================
  const apiEventsArr = [
    {
      id: 8,
      name: "Novruz 3",
      type: "Bayram/hüzn günü",
      status: true,
      vacationDate: "2023-06-28",
      isActive: true,
    },
    {
      id: 7,
      name: "Novruz 2",
      type: "Bayram/hüzn günü",
      status: true,
      vacationDate: "2023-06-22",
      isActive: true,
    },
    {
      id: 6,
      name: "Novruz",
      type: "Həftəsonu",
      status: true,
      vacationDate: "2023-06-24",
      isActive: true,
    },
  ];
  // ! ========================= EVENTS API REAL DATA =========================
  const [isWeekend, setIsWeekend] = useState(true);
  const [isHoliday, setIsHoliday] = useState(true);

  const [events, setEvents] = useState({
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: [],
  });

  const eventsRef = useRef({
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: [],
  });

  const hideModal = () => {
    setShowModal(false);
  };

  const CustomToolbar = (toolbar) => {
    const { label } = toolbar;

    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          {/* Render only the label or any other components you want */}
          <span className="rbc-toolbar-label">{label}</span>
        </span>
      </div>
    );
  };

  const eventStyleGeeter = (eventProps) => {
    return {
      style: {
        background: eventProps.type === "Həftəsonu" ? "red" : "green",
      },
    };
  };

  const CustomEvent = () => {
    return (
      <div
        style={{
          zIndex: "10",
          height: "30px",
        }}
      />
    );
  };

  const handleSelectSlot = (a) => {
    // Handle the slot selection logic
  };

  const handleClickCheckboxes = (checkboxType) => {
    const eventsCopy = JSON.parse(JSON.stringify(events));
    if (checkboxType === "weekend" ? isWeekend : isHoliday) {
      const filteredData = Object.keys(eventsCopy)?.map((calendarMonth) => {
        const monthlyEvents = events[calendarMonth];
        return monthlyEvents.filter(
          (evtsOfMonth) =>
            evtsOfMonth.type !==
            (checkboxType === "weekend" ? "Həftəsonu" : "Bayram/hüzn günü")
        );
      });

      setEvents(filteredData);
    } else {
      Object.keys(eventsRef.current).forEach((calendarMonth) => {
        const monthlyEvents = eventsRef.current[calendarMonth];
        monthlyEvents.forEach((evtsOfMonth) => {
          if (
            evtsOfMonth.type ===
            (checkboxType === "weekend" ? "Həftəsonu" : "Bayram/hüzn günü")
          ) {
            eventsCopy[calendarMonth].push(evtsOfMonth);
          }
        });
      });

      setEvents(eventsCopy);
    }
    checkboxType === "weekend"
      ? setIsWeekend(!isWeekend)
      : setIsHoliday(!isHoliday);
  };

  useEffect(() => {
    CalendarModel.getCalendarList((data) => {
      const eventsCopy = JSON.parse(JSON.stringify(events));
      data.forEach((evtDay) => {
        const momentDate = moment(evtDay.vacationDate);
        const monthIndex = momentDate.month();
        eventsCopy[monthIndex].push({
          allDay: true,
          type: evtDay.type,
          id: evtDay.id,
          name: evtDay.name,
          start: new Date(evtDay.vacationDate),
          end: new Date(evtDay.vacationDate),
        });
      });
      setEvents(eventsCopy);
      eventsRef.current = eventsCopy;
    });
  }, []);

  return (
    <Modal
      title="Kalendar Təqvimi"
      bodyStyle={{ height: 500 }}
      width={1200}
      open={show}
      onOk={hideModal}
      onCancel={hideModal}
    >
      <div>
        <div>
          <label htmlFor="weekend">
            <span
              style={{
                paddingRight: "16px",
              }}
            >
              Həftəsonu
            </span>
            <input
              type="checkbox"
              id="weekend"
              checked={isWeekend}
              onChange={() => handleClickCheckboxes("weekend")}
              style={{
                height: "unset",
                boxShadow: "unset",
                width: "unset",
              }}
            />
          </label>
        </div>
        <div>
          <label htmlFor="holiday">
            <span
              style={{
                paddingRight: "16px",
              }}
            >
              Bayram/hüzn günü
            </span>
            <input
              type="checkbox"
              id="holiday"
              checked={isHoliday}
              onChange={() => handleClickCheckboxes("holiday")}
              style={{
                height: "unset",
                boxShadow: "unset",
                width: "unset",
              }}
            />
          </label>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        {Object.keys(events)?.map((calendarMonth) => {
          const monthlyEvents = events[calendarMonth];
          const defaultMonth = moment(parseInt(calendarMonth) + 1, "MM");

          return (
            <div style={{ width: "calc(25% - 15px)" }} key={calendarMonth}>
              <Calendar
                localizer={localizer}
                style={{ height: 350 }}
                views={["month"]}
                components={{
                  toolbar: CustomToolbar,
                  event: CustomEvent,
                  // dateCellWrapper: renderCellContent,
                }}
                events={monthlyEvents}
                defaultDate={defaultMonth}
                tooltipAccessor="name"
                eventPropGetter={eventStyleGeeter}
                onSelectEvent={handleSelectSlot}
              />
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default MyCalendar;
