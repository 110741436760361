import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col, Select, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import PageTitle from "components/Elements/PageTitle/PageTitle";
import FormSubmit from "components/Elements/FormSubmit/FormSubmit2";
import { useHistory } from "react-router-dom";
import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import SelectBox from "components/Elements/FileUploadForm/Form/SelectBox";
import { setRiskSeperating } from "redux/actions";
import QueryBuilder from "./QueryBuilder";
import "./qbstyle.css";
import Separation from "models/Separation";
import RiskStatus from "models/RiskStatus";
import { riskSeperating } from "const/initialDatas";
import GroupsUsersSelect from "components/Elements/GroupsUsersSelect/GroupsUsersSelect";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

function RiskSeperatingEdit(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  var pageState = props.location.state;
  const reduxData = useSelector((state) => state.riskSeperating);

  const [riskStatusList, setRiskStatusList] = useState([]);

  const method = pageState?.method || "";
  const id = pageState?.id || "";

  if (!method) {
    history.replace("/risk-seperating");
  }

  let initialValues = riskSeperating;
  // if (method === "create") {
  //   initialValues = riskSeperating;
  // }
  if (
    // method === "edit" ||
    method === "create2"
  ) {
    initialValues = reduxData;
  }

  const postLoginSchema = Yup.object().shape({
    info: Yup.object().shape({
      id: Yup.string(),
      name: Yup.string().required(t('yup_name')),
      type: Yup.string().required("Lutfen Şərtləndirmə üsulu Seciniz"),
      status: Yup.boolean().required(t('yup_status')),
      repeat: Yup.boolean().required(t('separatin_yup_rep')),
      repeatPer: Yup.number().required(t('separatin_yup_rep2')),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: postLoginSchema,
    onSubmit: (values) => {
      if (method === "create2") {
        Separation.CREATE(
          values,
          () => {
            formik.setValues(riskSeperating);
            history.push("/risk-seperating");
          },
          (err) => {
            console.log("err", err);
          }
        );
      }
      if (method === "edit") {
        Separation.PUT(
          id,
          values,
          () => {
            formik.setValues(riskSeperating);
            history.push("/risk-seperating");
          },
          (err) => {
            console.log("err", err);
          }
        );
      }
    },
  });

  const submitForm = () => {
    formik.handleSubmit();
  };

  const resetFormik = () => {
    formik.setValues(riskSeperating);
  };

  var listscheme = { parser: { value: "value", label: "label", type: "type" } };

  var list3 = [
    { value: true, label: t('global_active') },
    { value: false, label: t('global_deactive') },
  ];

  var list4 = [
    { value: true, label: t('global_yes') },
    { value: false, label: t('global_no') },
  ];

  var list5 = [
    { value: "konstruktor", label: t('separating_konstruktor') },
    { value: "sql", label: t('separating_sql') },
  ];

  const typeChanged = (selected) => {
    var reduxDataObj = JSON.parse(JSON.stringify(reduxData));
    reduxDataObj.info.type = selected;
    dispatch(setRiskSeperating(reduxDataObj));
    formik.setFieldValue("info.type", selected);
  };

  const setUserType = (_, data) => {
    formik.setFieldValue("info.userType", data.type);
  };

  const OnQueryUpdate = (json, sql) => {
    formik.setFieldValue("info.sqlJson", json);
    formik.setFieldValue("info.sqlText", sql);
  };

  useEffect(() => {
    const getRiskStatuses = () => {
      RiskStatus.getRiskStatusList(
        (data) => {
          const listData = RiskStatus.getArrayForSelect(data);
          setRiskStatusList(listData);
        },
        (err) => {
          console.log("err", err);
        }
      );
    };

    getRiskStatuses();

    if (method === "edit") {
      Separation.GET(
        id,
        (data) => {
          formik.setValues(data);
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  }, []);

  useEffect(() => {
    dispatch(setRiskSeperating(formik.values));
  }, [formik.values]);

  useEffect(() => {
    if (method === "create" && Object.keys(formik.touched).length) {
      history.replace({ state: { ...pageState, method: "create2" } });
    }
  }, [formik.touched]);

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={
          pageState.method == "edit"
            ? t('separating_edit')
            : t('separating_add')
        }
        small={t('separating_desc')}
      />
      <div className="risk-page">
        <div className="risk-page__elements">
          <h2>{t("global_info")}</h2>
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label="ID"
                formik={formik}
                field="id"
                type="number"
                required={false}
                visibilty={pageState.method == "edit" ? true : false}
                disabled={pageState.method == "edit" ? true : false}
              />
              <InputBox
                label={t('global_name')}
                formik={formik}
                field="name"
                required={true}
              />
              <SelectBox
                label={t('separating_type')}
                formik={formik}
                required={true}
                field="type"
                data={list5}
                type="simple"
                settings={listscheme}
                onSelect={typeChanged}
              />
            </Col>
            <Col sm={24} md={8} lg={8}>
              <SelectBox
                label={t('global_status')}
                formik={formik}
                required={true}
                field="status"
                data={list3}
                type="simple"
                settings={listscheme}
              />
              <SelectBox
                label={t('separating_rep')}
                formik={formik}
                required={true}
                field="repeat"
                data={list4}
                type="simple"
                settings={listscheme}
              />
              <InputBox
                label={t('separating_rep_min')}
                type="number"
                formik={formik}
                field="repeatPer"
                required={true}
              />
            </Col>
          </Row>
          {formik.values.info.type !== "sql" ? (
            // Qruplaşdırma
            <Row gutter={24} style={{ marginBottom: "40px" }}>
              <Col sm={24} md={16} lg={16}>
                <QueryBuilder onUpdate={OnQueryUpdate} formik={formik} />
              </Col>
            </Row>
          ) : (
            // SQL Sorgu
            <Row gutter={24} style={{ marginBottom: "40px" }}>
              <Col sm={24} md={16} lg={16}>
                <div className="input-box">
                  <span className="input-box__title">{t('separating_sql')}</span>
                  <TextArea
                    autoSize
                    rows={6}
                    {...formik.getFieldProps("info.sqlText")}
                  />
                </div>
                <div style={{ textAlign: "right" }}>
                  <Button type="primary">{t('separating_result')}</Button>
                </div>
              </Col>
            </Row>
          )}
        </div>

        <>
          <h1 style={{ marginTop: "40px" }}>{t('separating_refresh')}</h1>
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <SelectBox
                label={t('separating_cari')}
                formik={formik}
                field="defaultStatus"
                data={riskStatusList}
                type="simple"
                settings={listscheme}
              />
              <InputBox
                label={t('separating_precedent')}
                type="number"
                formik={formik}
                field="precedent"
              />
              <InputBox
                label={t('global_lastexecute')}
                type="date"
                formik={formik}
                field="lastExecDate"
              />
              <GroupsUsersSelect
                onSelect={setUserType}
                label="Kurator"
                formik={formik}
                field="info.usersGroupsId"
                // data={kuratorList}
                // type="simple"
                settings={listscheme}
              />
            </Col>
            <Col sm={24} md={8} lg={8}>
              <div className="input-box">
                <span className="input-box__title">{t('global_qeyd')}</span>
                <TextArea
                  autoSize
                  rows={6}
                  {...formik.getFieldProps("info.note")}
                />
              </div>
              <div className="input-box">
                <span className="input-box__title">{t('global_tags')}</span>
                <Select
                  className="tags"
                  mode="tags"
                  value={formik.values.info.tags}
                  onChange={(value) => formik.setFieldValue("info.tags", value)}
                  onBlur={formik.handleBlur("info.tags")}
                />
              </div>
            </Col>
          </Row>
        </>

        <div className="risk-page__submittion">
          <FormSubmit cancel={resetFormik} submit={submitForm} />
        </div>
      </div>
    </div>
  );
}

export default RiskSeperatingEdit;
