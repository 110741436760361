import React, { useEffect, useMemo, useState } from "react";
import { GetPagePermission, GetTableSettings } from "hooks/permissions";
import GL from "libs/GL";
import SuperTable from "components/Elements/Tabulator/SuperTable";
import { useHistory } from "react-router-dom";
import { Skeleton } from "antd";
import EmptyPage from "components/Elements/Skelethon/EmptyPage";
import Separation from "models/Separation";
import { useTranslation } from "react-i18next";

const pageId = "risk-separating";

function RiskSeperating() {
  const { t } = useTranslation();
  const history = useHistory();
  const [seperateList, setSeperateList] = useState([]);
  const [loading, setLoading] = useState(false);
  const permissions = GetPagePermission(8);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );
  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {
        history.push({
          pathname: "risk-seperating/create",
          state: {
            id: data.id,
            method: "edit",
          },
        });
      },
    },
    delete: {
      status: permissions["delete"],
      click: function (data) {
        GL.yesNo(t("question_deleting"), (status1) => {
          if (status1) {
            Separation.DELETE(data.id, () => {
              getSeparationList();
            });
          }
        });
      },
    },
    show: {
      status: false,
      click: function (data) {},
    },
    run: {
      status: true,
      click: function (data) {
        GL.yesNo(t("question_run"), (res) => {
          if (res) {
            Separation.RUN(data.id, (isok, cev) => {
              if (isok) {
                GL.notify.success({
                  title: t("answer_success"),
                  text: cev,
                });
              }
            });
          }
        });
      },
    },
    copy: {
      status: false,
      click: function (data) {},
    },
  };
  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/risk-seperating/add",
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/risk-seperating/multiDelete",
      },
    },
    settings: {
      id: pageId,
      type: "data",
      data: seperateList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "+ " + t("global_create", { name: t("separating") }),
      createNewRoute: "risk-seperating/create",
      state: {
        method: "create",
      },
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 70,

        formatter: function (cell) {
          return GL.setSelectStatus(cell);
        },
      },
      {
        title: t("global_name"),
        field: "name",
      },
      {
        title: t("global_status"),
        field: "status",
      },

      {
        title: t("separating_type"),
        field: "type",
      },
      {
        title: t("separating_rep"),
        field: "repeat",
      },
      {
        title: t("separating_rep_min"),
        field: "repeatPer",
      },
      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        name: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        totalName: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        code: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        parentId: {
          dataType: "text",
          placeholder: t("global_search"),
        },
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {},
    },
  };

  function getSeparationList() {
    setLoading(true);
    Separation.LIST(
      (data) => {
        setSeperateList(data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);

        console.log("err", err);
      }
    );
  }

  useEffect(() => {
    getSeparationList();
  }, []);

  if (loading) {
    return <Skeleton active />;
  } else {
    if (seperateList.length === 0) {
      return (
        <EmptyPage
          route="risk-seperating/create"
          name="Risklərin bölüşdürülmə"
          state={options.settings.state}
        />
      );
    } else {
      return (
        <div style={containerStyle}>
          <div className="main-layout-wrapper__head">
            <div className="simple-head">
              <h1>{t("menu_risk_separating")}</h1>
              <span>{t("separating_desc")}</span>
            </div>
          </div>
          <SuperTable data={options} />
        </div>
      );
    }
  }
}

export default RiskSeperating;
