import axios from "axios";

import store from "../redux/store";
import { logOut } from "redux/actions";
import { notification } from "antd";
import GL from "libs/GL";
import { apiRoutes } from "./apiroutes";
import { SET_TOKEN, SET_USER_REFRESH_TOKEN } from "redux/types";

const apiUrl = process.env.REACT_APP_API_URL;
// const apiUrl = process.env.REACT_APP_API_URL_LOCAL;

class API {
  constructor() {
    this.store = store;
    this.state = store.getState();
    this.token = this.state.token;
    this.dispatch = store.dispatch;

    store.subscribe(() => {
      this.store = store;
      this.state = store.getState();
      this.token = this.state.token;
      this.dispatch = store.dispatch;
    });

    this.locale = localStorage.getItem("locale")
      ? localStorage.getItem("locale")
      : "az";

    this.baseUrl = apiUrl;

    this.axios = axios;

    this.axios.interceptors.request.use(
      function (config) {
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const response = error.response;
        const originalRequest = error.config;
        const refreshToken = this.state?.user?.data?.refreshToken;
        const body = {
          refreshToken,
        };

        if (response) {
          if (
            response.status === 401 &&
            response.data.error === "JWT Access Token Expired or Invalid" &&
            !originalRequest._retry
          ) {
            originalRequest._retry = true;
            const refresh = await this.getRefreshToken(body);

            if (refresh.status === 200) {
              this.dispatch({
                type: SET_TOKEN,
                payload: refresh.data.data.accessToken,
              });
              this.dispatch({
                type: SET_USER_REFRESH_TOKEN,
                payload: {
                  ...refresh.data.data,
                  token: refresh.data.data.accessToken,
                },
              });

              originalRequest.headers.Authorization = `Bearer ${refresh.data.data.accessToken}`;
              const responseData = await axios(originalRequest);
              return responseData;
            } else {
              this.dispatch(logOut());
            }
          } else if (
            response.status === 401 &&
            response.data.error === "Refresh Jwt Token expired or Invalid"
          ) {
            this.dispatch(logOut());
          }
        }

        return Promise.reject(error);
      }
    );
  }

  getRefreshToken(refreshToken) {
    return axios.post(
      `${this.baseUrl}/${apiRoutes.users.refresh}`,
      refreshToken
    );
  }

  getHeader() {
    const header = {
      "Content-Type": "application/json",
    };
    if (this.token) {
      header["Authorization"] = `Bearer ${this.token}`;
    }
    if (this.locale !== null) {
      header["accept-language"] = this.locale;
    }
    return header;
  }

  getData(url, callback, errCallback, onDownloadProgress) {
    const header = this.getHeader();
    this.axios
      .get(`${this.baseUrl}/${url}`, {
        headers: header,
        onDownloadProgress,
      })
      .then((res) => {
        if (callback) {
          callback(res.data.data, res.data?.paginationInfo);
        }
      })
      .catch((err) => {
        if (errCallback) {
          errCallback(err);
        }
        this.errorHandler(err);
      });
  }

  async getDataSync(url, errCallback) {
    const header = this.getHeader();
    try {
      const res = await this.axios.get(`${this.baseUrl}/${url}`, {
        headers: header,
      });
      return res.data.data;
    } catch (err) {
      if (errCallback) {
        errCallback(err);
      }

      return false;
    }
  }

  postData(url, bodyData, callback, errCallback, onUploadProgress) {
    const header = this.getHeader();
    this.axios
      .post(`${this.baseUrl}/${url}`, bodyData, {
        headers: header,
        onUploadProgress,
      })
      .then((res) => {
        if (callback) {
          callback(res.data.data);
        }
      })
      .catch((err) => {
        if (errCallback) {
          errCallback(err);
        }
        this.errorHandler(err);
      });
  }

  putData(url, bodyData, callback, errCallback) {
    const header = this.getHeader();
    this.axios
      .put(`${this.baseUrl}/${url}`, bodyData, { headers: header })
      .then((res) => {
        if (callback) {
          callback(res.data.data);
        }
      })
      .catch((err) => {
        if (errCallback) {
          errCallback(err);
        }
        this.errorHandler(err);
      });
  }

  deleteData(url, callback, errCallback, deleteBody) {
    const header = this.getHeader();
    this.axios
      .delete(`${this.baseUrl}/${url}`, {
        headers: header,
        data: deleteBody,
      })
      .then((res) => {
        if (res.data.error) {
          this.pushNotify("warning from delete", res.data.error);
          return null;
        } else {
          if (callback) {
            callback(res.data.data);
          }
        }
      })
      .catch((err) => {
        if (errCallback) {
          errCallback(err);
        }
        this.errorHandler(err);
      });
  }

  pushNotify = (type, message) => {
    notification.open({
      type: type,
      message: "Notification Title",
      description: message,
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };

  errorHandler = async (err) => {
    var message = "Hata var!";
    message = err?.toString();

    if (err["response"] !== undefined) {
      if (err.response["data"] !== undefined) {
        if (err.response.data.error !== "JWT Access Token Expired or Invalid")
          message = err.response.data.error;
      }
    }
    if (message !== "Error: Request failed with status code 401") {
      GL.notify.error({ text: message });
    }
  };
}

export default API;
