import React from "react";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";
import { notification } from "antd";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";
import { getUserData, logOut, toggleLoading } from "./../redux/actions";
import MainLayout from "./Layout/MainLayout/MainLayout";
import Loader from "./Elements/Loader";
import Routes from "../Routes";
import RoutesAuth from "./Pages/Auth/RoutesAuth";

// import logo from "../assets/img/rtms-logo.svg";
Sentry.init({
  dsn: "https://efb08269049a42d3ae3375596cc506cc@o4504852908867584.ingest.sentry.io/4504972890013696",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

class App extends React.Component {
  state = {
    collapsed: window.innerWidth < 1200,
    web: true,
    ismap: false,
    stocks: null,
    isWhite: true,
  };

  componentDidMount() {
    this.props.toggleLoading();
  }

  componentDidUpdate(prevProps) {
    const prev = prevProps.notification;
    const curr = this.props.notification;
    if (prev.notify !== curr.notify) {
      let desc = !curr.isHappy
        ? curr.description?.status === 400
          ? curr.description.data
          : this.props.t("errorMessage")
        : curr.description;
      notification.info({
        message: curr.isHappy
          ? this.props.t("successMessage")
          : this.props.t("errMessage"),
        description: desc.length ? desc : null,
        icon: curr.isHappy ? <SmileOutlined /> : <FrownOutlined />,
      });
    }
  }

  render() {
    return (
      <>
        {this.props.isLoading ? <Loader /> : null}

        {this.props.isLoggedIn ? (
          <MainLayout>
            <Routes />
          </MainLayout>
        ) : (
          <RoutesAuth />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ user, loader, notification }) => {
  return {
    isLoggedIn: user.isLoggedIn,
    isRoleSelected: user.isRoleSelected,
    isLoading: loader,
    notification,
  };
};

const exp = App;
export default connect(mapStateToProps, { getUserData, logOut, toggleLoading })(
  exp
);
