import React, { useState } from "react";
import { Modal, Space, Row, Col, Typography, Button, Skeleton } from "antd";
import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import "./EQFModal.scss";
import TableComponent from "./Table";
import { RecommendCheckbox } from "../Recommend/Recommend";
import SelectBox from "components/Elements/FileUploadForm/Form/SelectBox";
import TextareaComponent from "components/Elements/FileUploadForm/Form/Textarea";
import FooterTools from "../FooterTools/FooterTools";
import PoolAttachedModal from "../PoolPaginationModal/PoolAttachedModal";
import FileModal from "../FileModal/FileModal";
import useInvoice from "../../hook/useInvoice";

import { useFormik } from "formik";
import { recomendedData, invoiceSettingsChecks } from "const/initialDatas";
import dayjs from "dayjs";
import Recommend from "../Recommend/Recommend";
import * as Yup from "yup";
import useCategories from "../../hook/useCategories";
import useButtons from "./hooks/useButtons";
import useColumns from "./hooks/useColumns";

const { Title } = Typography;

const EQFModal = ({
  isModalOpen = false,
  onOk,
  onCancel,
  riskId,
  itemId,
  invoiceId,
  getRiskGivenData,
  oldExecutor,
  isDisabled,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenFileModal, setIsOpenFileModal] = useState(false);
  const onOpenFileModal = () => setIsOpenFileModal(true);
  const onCloseFileModal = () => setIsOpenFileModal(false);

  const {
    invoices,
    isPending,
    activeRole,
    sendChangableDecisionData,
    saveInvoiceItem,
  } = useInvoice(invoiceId, riskId, getRiskGivenData, setFormikValues);

  const createValidation = () => {
    const shcema = Yup.object({
      [activeRole]: Yup.object({
        note: Yup.string().when("checks.reasonId", {
          is: (val) => val === 27 || val === 28,
          then: () => Yup.string().required("Qeyd daxil edin"),
        }),
        checks: Yup.object({
          reasonId: Yup.number().nullable(),
        }),
      }),
    });

    return shcema;
  };

  const validationShcema = Yup.object().shape({
    info: createValidation(),
  });

  const formik = useFormik({
    initialValues: recomendedData,
    validationSchema: validationShcema,
    onSubmit: (values, { resetForm }) => {
      saveInvoiceItem(values.info, { onOk, resetForm });
    },
  });

  const { setValues, handleSubmit } = formik;

  function setFormikValues(values) {
    setValues(values);
  }

  const { columns, components } = useColumns({
    isDisabled,
    sendChangableDecisionData,
  });

  const { buttons, iconButtonList, extraButtons } = useButtons({
    isDisabled,
    onOpenFileModal,
    setIsOpenModal,
    handleSubmit,
    onOk,
  });
  const { commentCategories } = useCategories();

  return (
    <Modal
      title="EQF Baxış"
      open={isModalOpen}
      onCancel={onCancel}
      onOk={onOk}
      width={1180}
      footer={null}
    >
      <div className="EQFModal">
        <div className="EQFModalHistory">
          <Space direction="vertical" size={20}>
            <Row gutter={30}>
              <Col sm={24} md={8} lg={8}>
                <Space direction="vertical" size={20}>
                  <Title level={5}>Təqdim edən</Title>
                  <InputBox label="Adı" placeholder="Ad daxil et" />
                  <InputBox label="VÖEN-i" placeholder="VÖEN daxil et" />
                </Space>
              </Col>
              <Col sm={24} md={8} lg={8}>
                <Space direction="vertical" size={20}>
                  <Title level={5}>Əldə edən</Title>
                  <InputBox label="Adı" placeholder="Ad daxil et" />
                  <InputBox label="VÖEN-i" placeholder="VÖEN daxil et" />
                </Space>
              </Col>
              <Col sm={24} md={8} lg={8}>
                <Space direction="vertical" size={20}>
                  <Title level={5}>EQF</Title>
                  <div className="EQFModalAbout">
                    <div className="EQFModalAboutItem">
                      <strong>Tarixi:</strong>
                      <span>dd/mm/yyyy</span>
                    </div>
                    <div className="EQFModalAboutItem">
                      <strong>Nömrəsi:</strong>
                      <span>EQF.658978</span>
                    </div>
                    <div className="EQFModalAboutItem">
                      <strong>Statusu:</strong>
                      <span>Təsdiq edilmiş</span>
                    </div>
                    <div className="EQFModalAboutItem">
                      <strong>Təsdiq növü:</strong>
                      <span>Sistem</span>
                    </div>
                  </div>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="d-flex">
                <Button className="btn-primary ml-auto">Tarixçə</Button>
              </Col>
            </Row>
          </Space>
        </div>
        <div className="EQFModalTable VoenTableFontSize">
          <Row gutter={24}>
            <Col span={24}>
              {isPending && <Skeleton active />}
              {!isPending && (
                <TableComponent
                  columns={columns}
                  data={invoices}
                  pagination={{ pageSize: 50 }}
                  scroll={{ y: 145 }}
                  rowSelection={{}}
                  components={components}
                />
              )}
            </Col>
          </Row>
        </div>
        <div className="EQFModalTools">
          {activeRole !== "executor" && (
            <Space direction="vertical" size={20}>
              <Row gutter={24}>
                <Col sm={24} md={12} lg={12} className="mb-24">
                  <Recommend
                    disabled
                    title="İcraçının rəyi"
                    hasCenter
                    commentCategories={commentCategories}
                    formik={formik}
                    itemName="executor"
                  />
                </Col>

                <Col sm={24} md={12} lg={12} className="mb-24">
                  <Recommend
                    disabled
                    hasCenter
                    title={
                      activeRole === "headofdepartment"
                        ? "Rəhbər"
                        : "Şöbə rəisinin rəyi"
                    }
                    commentCategories={commentCategories}
                    formik={formik}
                    itemName={
                      activeRole === "headofdepartment"
                        ? "leader"
                        : "headofdepartment"
                    }
                  />
                </Col>
              </Row>
            </Space>
          )}

          <Row>
            <Col sm={24} md={12} lg={8}>
              <Space
                direction="vertical"
                size={20}
                className="EQFModalToolsBody"
              >
                <Title level={5}>Yekun nəticə</Title>
                <div className="EQFModalAmounts">
                  <div className="EQFModalAmountsItem">
                    <span>Cəmi əsas məbləğ:</span>
                    <div className="EQFModalLine" />
                    <strong>10.000,00</strong>
                  </div>
                  <div className="EQFModalAmountsItem">
                    <span>Əmtəəsiz hesab edilən:</span>
                    <div className="EQFModalLine" />
                    <strong>8.000,00</strong>
                  </div>
                  <div className="EQFModalAmountsItem">
                    <span>Cəmi ƏDV:</span>
                    <div className="EQFModalLine" />
                    <strong>1.800,00</strong>
                  </div>
                  <div className="EQFModalAmountsItem">
                    <span>Ləğv edilməli ƏDV:</span>
                    <div className="EQFModalLine" />
                    <strong>1.440,00</strong>
                  </div>
                </div>
              </Space>
            </Col>
            <Col sm={24} md={12} lg={8}>
              <Space
                direction="vertical"
                size={20}
                className="EQFModalToolsBody"
              >
                {isDisabled && <div className="RecommendDisabled"></div>}
                <Title level={5}>Qərar üzrə təkliflər</Title>
                <div className="w-100">
                  <RecommendCheckbox
                    label="Tərəflərin məlumatlandırılması:"
                    formik={formik}
                    field={`info.${activeRole}.checks.isInfo`}
                    isDisabled={
                      formik.values.info[activeRole].checks.isClosable
                    }
                  />
                  <RecommendCheckbox
                    label="Təqdim edənin RVÖ elan edilməsi:"
                    formik={formik}
                    field={`info.${activeRole}.checks.isRvo`}
                    isDisabled={
                      formik.values.info[activeRole].checks.isClosable
                    }
                  />
                  <RecommendCheckbox
                    label="Bağlanmalı risk:"
                    formik={formik}
                    field={`info.${activeRole}.checks.isClosable`}
                    onHandleChange={(isChecked) => {
                      if (isChecked) {
                        const updatedValues = {
                          ...invoiceSettingsChecks,
                          isClosable: isChecked,
                          reasonId:
                            formik.values.info[activeRole].checks.reasonId,
                        };
                        formik.setFieldValue(
                          `info.${activeRole}.checks`,
                          updatedValues
                        );
                      }
                    }}
                  />
                </div>
                <SelectBox
                  label="Səbəb seç"
                  formik={formik}
                  field={`${activeRole}.checks.reasonId`}
                  data={commentCategories}
                />
                <TextareaComponent
                  placeholder="Qeyd əlavə et"
                  formik={formik}
                  field={`${activeRole}.note`}
                />
              </Space>
            </Col>
            <Col sm={24} md={12} lg={8}>
              <Space
                direction="vertical"
                size={20}
                className="EQFModalToolsBody third-item-position"
              >
                {isDisabled && <div className="RecommendDisabled"></div>}
                <Title level={5}>Digər hovuza göndərmə</Title>
                <div className="w-100">
                  <RecommendCheckbox
                    label="OVN:"
                    formik={formik}
                    field={`info.${activeRole}.checks.isOvn`}
                    isDisabled={
                      formik.values.info[activeRole].checks.isClosable
                    }
                  />
                  <RecommendCheckbox
                    label="SVY:"
                    formik={formik}
                    field={`info.${activeRole}.checks.isSvy`}
                    isDisabled={
                      formik.values.info[activeRole].checks.isClosable
                    }
                  />
                  <RecommendCheckbox
                    label="KVY:"
                    formik={formik}
                    field={`info.${activeRole}.checks.isKvy`}
                    isDisabled={
                      formik.values.info[activeRole].checks.isClosable
                    }
                  />
                </div>

                <EQFCard oldExecutor={oldExecutor} />
              </Space>
            </Col>
          </Row>
        </div>

        <FooterTools
          iconButtons={iconButtonList}
          extraButtons={extraButtons}
          buttons={buttons}
        />
        {isOpenModal && (
          <PoolAttachedModal
            isModalOpen={isOpenModal}
            setIsModalOpen={setIsOpenModal}
            riskId={riskId}
            itemId={itemId}
            isDisabled={isDisabled}
          />
        )}

        {isOpenFileModal && (
          <FileModal
            isOpenModal={isOpenFileModal}
            onOk={onCloseFileModal}
            onCancel={onCloseFileModal}
            riskId={riskId}
            itemId={itemId}
          />
        )}
      </div>
    </Modal>
  );
};

export function EQFCard({ className, oldExecutor }) {
  const convertDate = oldExecutor?.date
    ? dayjs(new Date(oldExecutor?.date)).format("DD.MM.YYYY")
    : "";
  return (
    <div className={`EQFModalCard ${className}`}>
      <div className="EQFModalCardItem">
        <span>Əməliyyat tarixi</span>
        <div className="EQFModalLine" />
        <span>{convertDate}</span>
      </div>
      <div className="EQFModalCardItem">
        <span>İcraçı</span>
        <div className="EQFModalLine" />
        <span>{oldExecutor?.name}</span>
      </div>
    </div>
  );
}

export default EQFModal;
