import "./ShareModal.scss";

import React from "react";
import { useFormik } from "formik";
import { Modal, Divider, Button } from "antd";

import UsersSelect from "components/Elements/FileUploadForm/Form/UsersSelect";
import SwitchBox from "components/Elements/FileUploadForm/Form/Switch";
import { useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";

function ShareModal({
  isOpen,
  closeModal,
  title,
  submitShareFileOrFolder,
  lastSharedUsers,
  fileOrFolderSharedWith,
  deleteFileOrFolderFromShared,
}) {
  const reduxRoles = useSelector((s) => s.user.data.roles);
  const canShareToCatalog = reduxRoles.some(
    (role) =>
      role.status === true &&
      (role.id === 57 || role.id === 60 || role.id === 65)
  );

  const formik = useFormik({
    initialValues: {
      info: {
        userIds: [],
        userIdsCheckboxes: {},
        userIdsCheckboxesForDelete: {},
        catalog: false,
      },
    },
    onSubmit: (values) => {
      submitShareFileOrFolder({
        userIds: values.info.userIds,
        catalog: values.info.catalog,
      });
      closeModal();
    },
  });

  const updateSelectFromCheckboxValues = (id, val) => {
    if (val) {
      const userIdsCopy = [...formik.values.info.userIds, `${id}`];
      formik.setFieldValue("info.userIds", userIdsCopy);
    } else {
      const filteredValues = formik.values.info.userIds.filter(
        (formikUserId) => formikUserId != id
      );
      formik.setFieldValue("info.userIds", filteredValues);
    }
  };

  const updateCheckboxFromSelectValues = (selectValsArr) => {
    const newUserIdsCheckboxes = {};
    selectValsArr.forEach((selectId) => {
      newUserIdsCheckboxes[selectId] = true;
    });

    formik.setFieldValue("info.userIdsCheckboxes", newUserIdsCheckboxes);
  };

  const handleClickCheckbox = (id, val) => {
    formik.setFieldValue(`info.userIdsCheckboxes.${id}`, val);
    updateSelectFromCheckboxValues(id, val);
  };

  const handleClickDeleteCheckbox = (id, val) => {
    if (val) {
      formik.setFieldValue(`info.userIdsCheckboxesForDelete.${id}`, true);
    } else {
      delete formik.values.info.userIdsCheckboxesForDelete[id];
      formik.setFieldValue(
        `info.userIdsCheckboxesForDelete`,
        formik.values.info.userIdsCheckboxesForDelete
      );
    }
  };

  return (
    <Modal
      title={`"${title}" payaş`}
      className="library-share-modal"
      open={isOpen}
      onOk={formik.handleSubmit}
      onCancel={closeModal}
      bodyStyle={{
        marginTop: "30px",
      }}
      width={900}
    >
      <UsersSelect
        label="İstifadəçi əlavə et"
        required
        field="userIds"
        formik={formik}
        onSelect={updateCheckboxFromSelectValues}
      />
      <div
        style={{
          marginBlock: "20px",
        }}
      >
        {canShareToCatalog && (
          <SwitchBox
            label="Kataloqa əlavə edilsinmi ?"
            field="catalog"
            formik={formik}
          />
        )}
      </div>

      {!fileOrFolderSharedWith.length && lastSharedUsers.length ? (
        <>
          <Divider />
          <h2 className="prev-shared">Daha öncə paylaşdıqların</h2>
          <div className="shared-people-wrapper">
            {lastSharedUsers.map((person) => {
              return (
                <label className="shared-person-label">
                  <input
                    type="checkbox"
                    checked={
                      formik.values?.info?.userIdsCheckboxes?.[person.userId]
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleClickCheckbox(person.userId, e.target.checked)
                    }
                  />
                  <div className="shared-person-wrapper">
                    <div className="shared-person-avatar">
                      {person.name[0].toUpperCase()}
                    </div>
                    <div className="shared-person-info">
                      <span className="info-title">{person.name}</span>
                      <span className="info-position">
                        {person.position} - {person.department}
                      </span>
                    </div>
                  </div>
                </label>
              );
            })}
          </div>
        </>
      ) : null}

      {fileOrFolderSharedWith.length ? (
        <>
          <Divider />
          <h2 className="prev-shared">Paylaşılanlar</h2>

          <div className="shared-people-wrapper">
            {fileOrFolderSharedWith.map((shared) => {
              return (
                <label
                  className="shared-person-label"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    paddingInline: "5px",
                  }}
                >
                  <div className="shared-person-wrapper">
                    <div className="shared-person-avatar">
                      {shared.name[0].toUpperCase()}
                    </div>
                    <div className="shared-person-info">
                      <span className="info-title">{shared.name}</span>
                      <span className="info-position">
                        {shared.position} - {shared.department}
                      </span>
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    checked={
                      formik.values?.info?.userIdsCheckboxesForDelete?.[
                        shared.userId
                      ]
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleClickDeleteCheckbox(shared.userId, e.target.checked)
                    }
                  />
                </label>
              );
            })}
          </div>
        </>
      ) : null}
      {Object.keys(formik.values.info.userIdsCheckboxesForDelete || {})
        .length ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() =>
              deleteFileOrFolderFromShared({
                userIds: Object.keys(
                  formik.values.info.userIdsCheckboxesForDelete || {}
                ),
              })
            }
          >
            <DeleteOutlined />
          </Button>
        </div>
      ) : null}
    </Modal>
  );
}

export default ShareModal;
