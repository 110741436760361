import {
  ADD_SUB_TAB,
  ADD_TAB,
  ADD_TAB_WITH_CHILD,
  REMOVE_SUB_TAB,
  REMOVE_TAB,
  RESET_TABS,
} from "../types";
import { home } from "const/initialDatas";

const homeReducer = (state = home, action) => {
  switch (action.type) {
    case ADD_TAB: {
      const newTabs = [...state.tabs];
      newTabs.push(action.payload);
      return { ...state, tabs: newTabs };
    }

    case REMOVE_TAB: {
      let newDeleteTabs = [...state.tabs];
      newDeleteTabs = newDeleteTabs.filter(
        (reduxTab) => reduxTab.key !== action.payload
      );
      return {
        ...state,
        tabs: newDeleteTabs,
      };
    }

    case ADD_SUB_TAB: {
      const { newTab, parentTabQuery } = action.payload;
      const targetTab = [...state.tabs].find((tab) => {
        return tab.key === parentTabQuery;
      });
      targetTab.subTabs.push(newTab);
      return { ...state };
    }

    case REMOVE_SUB_TAB: {
      const { tabKey, subTabKey } = action.payload;

      const parentTab = state.tabs.find((stateTab) => stateTab.key === tabKey);
      parentTab.subTabs = parentTab.subTabs.filter(
        (stateSubTab) => stateSubTab.key !== subTabKey
      );

      return { ...state };
    }

    case ADD_TAB_WITH_CHILD: {
      const { parentTabName, parentTabKey, subTabName, subTabKey } =
        action.payload;

      const stateParentTab = state.tabs.find(
        (stateTab) => stateTab.key === parentTabKey
      );
      console.log("0", stateParentTab);

      if (stateParentTab) {
        const isAlreadyOpen = stateParentTab.subTabs.some(
          (stateTab) => stateTab.key === subTabKey
        );

        if (isAlreadyOpen) return state;
        const closeAllActiveTab = stateParentTab.subTabs?.map((item) => ({
          ...item,
          isActive: false,
        }));
        closeAllActiveTab.push({
          label: subTabName,
          children: `${subTabKey}`,
          key: `${subTabKey}`,
          closable: true,
          isActive: true,
        });
        stateParentTab.subTabs = closeAllActiveTab;
      } else {
        state.tabs.push({
          label: parentTabName,
          children: `${parentTabKey}`,
          key: `${parentTabKey}`,
          closable: true,
          subTabs: [
            {
              label: subTabName,
              children: `${subTabKey}`,
              key: `${subTabKey}`,
              closable: true,
              isActive: true,
            },
          ],
        });
      }

      return { ...JSON.parse(JSON.stringify(state)) };
    }

    case RESET_TABS: {
      return {
        tabs: [
          {
            label: "Ana səhifə",
            children: "default",
            key: "default",
            closable: false,
            subTabs: [],
          },
        ],
        subTabs: [],
      };
    }

    default:
      return state;
  }
};

export default homeReducer;
