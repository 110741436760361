import React, { useState, useEffect } from "react";
import DynamicTable from "components/Elements/DynamicTable/DynamicTable";
import FooterTools from "../../Components/FooterTools/FooterTools";
import Export from "components/Elements/Icons/Export";
import Risks from "models/Risks";
import { Skeleton, Result } from "antd";
import { useHistory } from "react-router-dom";
import { ExecutorModal } from "../../Components/AddExecutor/AddExecutor";
import { useSelector } from "react-redux";

function Pool(props) {
  const { activeRole } = useSelector(({ user }) => ({
    activeRole: user.data.roles?.find(({ status }) => status),
  }));
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const tab = queryParams.get("tab");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isOpenExecutorModal, setIsOpenExecutorModal] = useState(false);
  const onOpenExecutormodal = () => setIsOpenExecutorModal(true);
  const onCancelExecutorModal = () => setIsOpenExecutorModal(false);

  const getRiskDetails = () => {
    setIsLoading(true);
    Risks.getRiskDetails(
      "pool&roleId=" + activeRole?.id,
      (data) => {
        setData(data);
        setIsLoading(false);
      },
      () => {
        setData([]);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (tab === "unallocatedRisks") getRiskDetails();
  }, [tab]);

  const onCheckboxSelect = (_, id) => {
    setSelected((prevState) => {
      const hasItem = prevState.includes(id);
      if (hasItem) {
        return prevState.filter((item) => item !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  const onSetRiskExecutor = (formData) => {
    if (!formData) return;
    Risks.postRiskSetExecute(
      {
        ...formData.info,
        riskId: selected,
        lastExecuteDate: formData.info.lastExecuteDate + "T00:00:00",
      },
      () => {
        onCancelExecutorModal();
        getRiskDetails();
        setSelected([]);
      }
    );
  };

  return (
    <div className="w-100">
      {isLoading && <Skeleton active />}
      {!isLoading && data.length > 0 && (
        <React.Fragment>
          <DynamicTable
            pageId="tab-pool"
            {...props}
            data={data}
            onCheckboxSelect={onCheckboxSelect}
          />
          <div className="main-layout-wrapper__footer">
            <FooterTools
              buttons={[
                {
                  className: "btn-danger",
                  children: "İcraya ver",
                  onClick: onOpenExecutormodal,
                  disabled: !(selected.length > 0),
                },
              ]}
              extraButtons={[
                {
                  className: "btn-primary d-flex align-items-center",
                  children: "Export",
                  icon: <Export />,
                },
              ]}
            />
          </div>
        </React.Fragment>
      )}
      {!isLoading && data.length === 0 && (
        <Result status="404" title="Heç bir məlumat tapılmadı." />
      )}
      {isOpenExecutorModal && (
        <ExecutorModal
          open={isOpenExecutorModal}
          onOk={onCancelExecutorModal}
          onCancel={onCancelExecutorModal}
          onSubmit={onSetRiskExecutor}
        />
      )}
    </div>
  );
}

export default Pool;
