import Profile from "./Profile";
import i18n from "i18n";
const { t } = i18n;
export const sublist = [
  {
    id: "profile-general",
    name: t("profile_general"),
    route: "/profile#profile-general",
  },
  {
    id: "fin-info",
    name: t("fin_info"),
    route: "/profile#fin-info",
  },
  {
    id: "fin-founder",
    name: t("fin_founder"),
    route: "/profile#fin-founder",
  },
  {
    id: "fin-lead",
    name: t("fin_lead"),
    route: "/profile#fin-lead",
  },
  {
    id: "fin-country-limit",
    name: t("fin_country_limit"),
    route: "/profile#fin-country-limit",
  },
  {
    id: "tax-authority-action",
    name: t("tax_authority_action"),
    route: "/profile#tax-authority-action",
  },
  {
    id: "family-ties",
    name: t("family_ties"),
    route: "/profile#family-ties",
  },
  {
    id: "action-type-info",
    name: t("action_type_info"),
    route: "/profile#action-type-info",
  },
  {
    id: "co-founder",
    name: t("co_founder"),
    route: "/profile#co-founder",
  },
  {
    id: "nka-info",
    name: t("nka_info"),
    route: "/profile#nka-info",
  },
  {
    id: "pos-terminal-info",
    name: t("pos_terminal_info"),
    route: "/profile#pos-terminal-info",
  },
  {
    id: "entities",
    name: t("entities"),
    route: "/profile#entities",
  },
];

export default Profile;
