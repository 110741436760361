import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { Row, Col, Select } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";

import PageTitle from "components/Elements/PageTitle/PageTitle";
import FormSubmit from "components/Elements/FormSubmit/FormSubmit";

function RiskAppointmentEdit() {
  const { t } = useTranslation();

  const initialValues = {
    info: { id: "", name: "" },
  };

  const postLoginSchema = Yup.object().shape({
    info: Yup.object().shape({
      id: Yup.string().required(t("yup_id")),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: postLoginSchema,
  });

  useEffect(() => {
    console.log("formik useEffect", formik.errors);
  }, [formik.errors]);

  return (
    <div className="main-layout-wrapper">
      <PageTitle big={t("scenarios")} small={t("scenario_desc")} />
      <div className="risk-page">
        <div className="risk-page__elements">
          <h2>{t("global_info")}</h2>
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <div className="input-box">
                <span className="input-box__title">ID</span>
                <input type="text" {...formik.getFieldProps("info.id")} />
              </div>

              <div className="input-box">
                <span className="input-box__title">{t("global_name")}</span>
                <input type="text" {...formik.getFieldProps("info.name")} />
              </div>

              <div className="input-box">
                <span className="input-box__title">
                  {t("global_totalname")}
                </span>
                <input type="text" {...formik.getFieldProps("info.fullName")} />
              </div>
            </Col>
            <Col sm={24} md={8} lg={8}>
              <div className="input-box">
                <span className="input-box__title">{t("global_code")}</span>
                <input type="text" {...formik.getFieldProps("info.code")} />
              </div>
              <div className="input-box">
                <span className="input-box__title">{t("appointment_top")}</span>
                <Select
                  options={[
                    { value: "a-121", label: "A-121" },
                    { value: "b-1", label: "B-1" },
                  ]}
                  value={formik.values.info.code}
                  onChange={(value) =>
                    formik.setFieldValue("info.status", value)
                  }
                  onBlur={formik.handleBlur("info.name")}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit />
        </div>
      </div>
    </div>
  );
}

export default RiskAppointmentEdit;
