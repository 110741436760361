import React, { Fragment, useState } from "react";
import { Space, Button, Typography } from "antd";
const { Title } = Typography;
import { useEffect } from "react";
import {
  RightOutlined,
  DoubleRightOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useMailContext } from "./MailContextAPI";
import { useSelector } from "react-redux";
import MailReply from "./MailReply";
import MailForward from "./MailForward";
import { fileTypeIconsEnum } from "const/fileTypeIconsEnum";
import { MailModel } from "./models/MailModel";
import MailDescWithReply from "./MailDescWithReply";
import { useHistory } from "react-router";
import { fileTypeDownloadEnum } from "const/fileTypeDownloadEnum";
import Watermark from "@uiw/react-watermark";
import { Modal } from "antd";
import GL from "libs/GL";
const MailDesc = ({ setQueryParam, handleClickTab }) => {
  const { state, dispatch } = useMailContext();
  const {
    selectedRow,
    recordKey,
    isReplyVisible,
    replyText,
    isForwardVisible,
    value,
    forwardText,
  } = state;
  const { currentPage, currentInboxPage, currentSendPage, pageSize } =
    state.paginationInfo;

  const currentUserId = useSelector((state) => state.user.data.user.id);
  let selectedRowsLastItem = selectedRow[selectedRow.length - 1];

  const history = useHistory();
  const searchParamsHandler = new URLSearchParams(history.location.search);
  const urlMailId = searchParamsHandler.get("mailId");
  const activeTab = searchParamsHandler.get("tab");
  const [hover, onHover] = useState(false);
  const refreshPage = () => {
    activeTab === "inbox"
      ? MailModel.getInbox(
          currentInboxPage,
          pageSize,
          (modelDataInbox) => {
            dispatch({
              type: "SET_MAIL_INBOX",
              payload: modelDataInbox,
            });
          },
          (err) => {
            console.log(err, "ERROR MESSAGE FROM INBOX REQUEST"); //errtext
          }
        )
      : MailModel.getSend(
          currentSendPage,
          pageSize,
          (modelDataSend) => {
            dispatch({
              type: "SET_MAIL_SENT",
              payload: modelDataSend,
            });
          },
          (err) => {
            console.log(err, "ERROR MESSAGE FROM SEND REQUEST");
          }
        );
  };

  useEffect(() => {
    if (urlMailId) {
      MailModel.getMailDescription(urlMailId, (modelDataMailDes) => {
        if (modelDataMailDes[0].parentId !== null) {
          MailModel.getMailDescriptionWithParentId(
            urlMailId,
            modelDataMailDes[0].parentId,
            (resData) => {
              dispatch({ type: "SET_SELECTED_ROW", payload: resData });
            }
          );
        } else {
          dispatch({ type: "SET_SELECTED_ROW", payload: modelDataMailDes });
        }
      });
    }
  }, [urlMailId]);

  const handleReplyMail = async (e) => {
    e.preventDefault();
    const requestData = {
      from: currentUserId,
      userIds:
        selectedRow[selectedRow.length - 1].from.id === currentUserId
          ? selectedRow[selectedRow.length - 1].users.map((user) => user.userId)
          : [selectedRow[selectedRow.length - 1].from.id],

      typeId: 5,
      content: replyText,
      emailTypeId: 3,
      parentEmailId:
        selectedRowsLastItem.parentId !== null
          ? selectedRowsLastItem.parentId
          : selectedRowsLastItem.key,
    };

    MailModel.postReplyMail(
      requestData,
      (responseReply) => {
        if (typeof responseReply === "number") {
          handleClickTab("tab", activeTab);
          refreshPage();
          GL.notify.success({
            title: "Uğurlu əməliyyat!",
            text: "Cavab göndərildi",
          });
        }
      },
      (err) => {
        GL.notify.error({
          title: "Uğursuz əməliyyat!",
          text: "Cavab göndərilmədi.",
        });
      }
    );
  };

  const handleForwardMail = async (e) => {
    e.preventDefault();

    const requestData = {
      from: currentUserId,
      userIds: value,
      typeId: 5,
      content: forwardText,
      emailTypeId: 2,
      parentEmailId:
        selectedRowsLastItem.parentId !== null
          ? selectedRowsLastItem.parentId
          : selectedRowsLastItem.key,
    };

    MailModel.postReplyMail(
      requestData,
      (responseReply) => {
        if (typeof responseReply === "number") {
          handleClickTab("tab", activeTab);
          refreshPage();
          GL.notify.success({
            title: "Uğurlu əməliyyat!",
            text: "Cavab yönləndirildi.",
          });
        }
      },
      (err) => {
        GL.notify.error({
          title: "Uğursuz əməliyyat!",
          text: "Mesaj yönləndirilmədi.",
        });
      }
    );
  };

  const handleDeleteSingleMail = (e, recordKey) => {
    MailModel.deleteSingleMail(
      recordKey,
      (responseReply) => {
        if (responseReply) {
          handleClickTab("tab", "inbox");
          refreshPage();
          GL.notify.success({
            title: "Uğurlu əməliyyat!",
            text: "Mail silindi.",
          });
        }
      },
      (err) => {
        GL.notify.error({
          title: "Uğursuz əməliyyat!",
          text: "Mesaj silinmədi.",
        });
      }
    );
  };

  // ~~ WATERMARK IMPLEMENTATION
  const [showModal, setShowModal] = useState(false);
  const currentUserName = useSelector((state) => state.user.data.user.name);
  const [frame, setFrame] = useState("");

  const closeModal = () => setShowModal(false);

  // ! getPdf() Here we change original url to blob url. Because if we use original url, the server responds with 403 (Forbidden error)
  const getPdf = (url) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const documentUrl = url;

    fetch(documentUrl, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        setFrame(
          <iframe
            src={`${blobUrl}#toolbar=0`}
            width="100%"
            height={screen.height - 300}
          />
        );
      })
      .catch((error) => console.log("Error fetching document:", error));
  };

  // ! getImg() and getDoc() methods must be seperate in table component. Otherwise it won't rerender.
  const getImg = (url) => {
    setFrame(
      <img src={url} className="modal-image" width="100%" height="auto" />
    );
  };

  const getDoc = (url) => {
    // ~ Embed google component for displaying xls and doc files
    setFrame(
      <iframe
        width="100%"
        height={screen.height - 300}
        src={`https://docs.google.com/viewer?url=${encodeURIComponent(
          url
        )}&embedded=true`}
      >
        {" "}
      </iframe>
    );
  };

  // Date properties
  const newDate = new Date();
  const date = `${newDate.getDate()}.${newDate.getMonth()}.${newDate.getFullYear()}`;
  const time = `${newDate.getHours()}:${newDate.getMinutes()}:${newDate.getSeconds()}`;

  const documentFetchers = {
    pdf: getPdf,
    png: getImg,
    jpg: getImg,
    jpeg: getImg,
    svg: getImg,
    xls: getDoc,
    xlsx: getDoc,
    doc: getDoc,
    docx: getDoc,
  };

  return selectedRow.length === 1 ? (
    <Space direction="vertical" size="middle" style={{ position: "relative" }}>
      <Space direction="horizontal" size="large">
        <Space
          onClick={() => {
            dispatch({ type: "SET_MAIN_OPEN", payload: true });

            handleClickTab("tab", activeTab);
          }}
          onMouseEnter={() => onHover(true)}
          onMouseLeave={() => onHover(false)}
          style={{
            justifyContent: "start",
            cursor: "pointer",
            alignItems: "center",
            backgroundColor: hover ? "#F1F1F1" : "#fff",
            borderRadius: "50%",
            padding: "3px",
            paddingTop: "8px",
            paddingLeft: "8px",
            paddingRight: "8px",
            justifyContent: "center",
            transition: "all 0.3s ease-in-out",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9.57 5.92999L3.5 12L9.57 18.07"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.4999 12H3.66992"
              stroke="#292D32"
              strokeWidth="2"
              stroke-miterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Space>

        <Space direction="vertical" size="small">
          <Title
            style={{ fontSize: "18px", fontWeight: "600", color: "#1C2940" }}
          >
            {selectedRowsLastItem.title}
          </Title>
          <Title style={{ fontSize: "14px" }}>
            {" "}
            <span style={{ fontWeight: "600" }}>Kimdən:</span>{" "}
            {selectedRowsLastItem.from.name} ({selectedRowsLastItem.from.email})
          </Title>
          <Title style={{ fontSize: "14px" }}>
            <span style={{ fontWeight: "600" }}>Kimə:</span>{" "}
            {selectedRowsLastItem.users.map((to, index) => (
              <span>
                {to.name} ({to.email}){" "}
                {index !== selectedRowsLastItem.users.length - 1 ? "," : ""}
              </span>
            ))}
          </Title>
        </Space>
      </Space>

      <Space
        direction="vertical"
        size="large"
        style={{
          border: " 1px solid #EBEDF0",
          borderRadius: "6px",
          padding: "30px",
          boxShadow: "0px 16px 40px -12px rgba(171, 186, 201, 0.20);",
        }}
      >
        <Title style={{ fontSize: "16px", fontWeight: "400", height: "auto" }}>
          {selectedRowsLastItem.content}
        </Title>
        {selectedRowsLastItem.files.length > 0 && (
          <Space
            direction="vertical"
            style={{
              borderTop: "1px solid #EBEDF0",
              borderBottom: "1px solid #EBEDF0",
              padding: "30px 0px",
            }}
          >
            <Title style={{ fontSize: "16px" }}>Fayllar</Title>
            <Space direction="horizontal">
              {selectedRowsLastItem.files.map((file) => (
                <Fragment>
                  <Button
                    shape="round"
                    key={file.fileId}
                    style={{ marginRight: "5px", border: "1px solid #B6C0D0" }}
                  >
                    <Space
                      direction="horizontal"
                      size="small"
                      onClick={() => {
                        setShowModal(true);
                        // Select which function we will call according to file type
                        const mimeType = fileTypeDownloadEnum[file.type];
                        if (documentFetchers[mimeType]) {
                          documentFetchers[mimeType](file.url);
                        }
                      }}
                    >
                      <img
                        height={14}
                        width={14}
                        src={
                          fileTypeIconsEnum[file.type] ||
                          fileTypeIconsEnum.default
                        }
                        alt=""
                      />
                      <span> {file.name}</span>
                    </Space>
                  </Button>

                  <Modal
                    destroyOnClose
                    open={showModal}
                    title="Fayl"
                    onCancel={closeModal}
                    onOk={closeModal}
                  >
                    <Watermark
                      content={[
                        `${currentUserId} ${currentUserName},${date},${time}`,
                      ]}
                      rotate={20}
                      gapX={60}
                      gapY={100}
                      fontSize={10}
                      fontColor="#979797"
                      style={{ background: "#fff" }}
                    >
                      {frame}
                    </Watermark>
                  </Modal>
                </Fragment>
              ))}
            </Space>
          </Space>
        )}

        {isReplyVisible && <MailReply selectedRowY={selectedRowsLastItem} />}
        {isForwardVisible && <MailForward />}
        <Space
          direction="horizontal"
          style={{ justifyContent: "space-between" }}
        >
          <Button
            className="btn-delete-mail"
            style={{
              background: "",
              borderRadius: "4px",
              color: "black",
              fontWeight: "600",
            }}
            onClick={(e) => {
              GL.yesNo("Mail silinsin?", (answer) => {
                if (answer) {
                  handleDeleteSingleMail(e, recordKey);
                }
              });
            }}
            icon={<DeleteOutlined />}
          >
            Sil
          </Button>
          <Space>
            {" "}
            {!isForwardVisible && (
              <Button
                className="btn-reply-mail"
                style={{
                  background: "#E0A611",
                  borderRadius: "4px",
                  color: "#F8F9FB",
                  fontWeight: "600",
                }}
                onClick={(e) => {
                  isReplyVisible && handleReplyMail(e);
                  dispatch({ type: "SET_REPLY_VISIBLE", payload: true });
                }}
                icon={<RightOutlined />}
              >
                Cavabla
              </Button>
            )}
            {!isReplyVisible && (
              <Button
                className="btn-forward-mail"
                style={{
                  backgroundColor: "#09A552",
                  borderRadius: "4px",
                  color: "#F8F9FB",
                  fontWeight: "600",
                }}
                icon={<DoubleRightOutlined />}
                onClick={(e) => {
                  isForwardVisible && handleForwardMail(e);
                  dispatch({ type: "SET_FORWARD_VISIBLE", payload: true });
                }}
              >
                Yönləndir{" "}
              </Button>
            )}
            {isReplyVisible && (
              <Button
                className="cancel-button-new-mail"
                style={{
                  backgroundColor: "#EBEDF0",
                  color: "#000",
                  borderRadius: "4px",
                  transition: "0.4s all ease-out",
                }}
                onClick={() =>
                  dispatch({ type: "SET_REPLY_VISIBLE", payload: false })
                }
              >
                Ləğv et{" "}
              </Button>
            )}
            {isForwardVisible && (
              <Button
                className="cancel-button-new-mail"
                style={{
                  backgroundColor: "#EBEDF0",
                  color: "#000",
                  borderRadius: "4px",
                  transition: "0.4s all ease-out",
                }}
                onClick={() =>
                  dispatch({ type: "SET_FORWARD_VISIBLE", payload: false })
                }
              >
                Ləğv et{" "}
              </Button>
            )}
          </Space>
        </Space>
      </Space>
    </Space>
  ) : (
    <MailDescWithReply
      selectedRow={selectedRow}
      handleClickTab={handleClickTab}
    />
  );
};

export default MailDesc;
