import "./TopMenu.scss";

import React from "react";
import i18n from "i18n";
import { connect, useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Collapse,
  Popover,
  Tooltip,
  Avatar,
  Row,
  Col,
  Modal,
  Typography,
  Space,
  Dropdown,
} from "antd";
import {
  LogoutOutlined,
  SettingOutlined,
  SwitcherOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
} from "@ant-design/icons";

import woman from "assets/img/women.jfif";
import logo from "assets/img/rtms-logo.svg";
import { logOut } from "redux/actions";
import { SET_USER_ROLE } from "redux/types";
import GL from "libs/GL";
import { useHistory } from "react-router";
import UserInfoEdit from "../UserInfoEdit/UserInfoEdit";
const { Text } = Typography;

const TopMenu = (props) => {
  const { t } = i18n;

  const history = useHistory();
  const dispatch = useDispatch();
  const rolesRedux = useSelector((state) => state.user.data.roles);

  const [showRolesModal, setShowRolesModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const roleSwitch = () => {
    setShowRolesModal(true);
    setShowPopover(false);
  };

  const closeModal = () => {
    setShowRolesModal(false);
    // setShowPopover(false);
    setShowPasswordModal(false);
  };

  const handleSwitchRole = (role) => {
    debugger;
    var roleName = role.name;
    const reduxRolesCopy = JSON.parse(JSON.stringify(rolesRedux));
    reduxRolesCopy.forEach((element) => {
      element.status = false;
      if (element.name === roleName) {
        element.status = true;
      }
    });

    dispatch({
      type: SET_USER_ROLE,
      payload: reduxRolesCopy,
    });
    debugger;
    closeModal();
    if (role.path !== "/") {
      history.push(role.path);
    } else {
      history.push("/");
    }

    GL.notify.success({
      title: t("answer_success"),
      text: "İstifadəçi rolu dəyişdirildi",
    });
  };

  const handleShowPasswordModal = () => {
    setShowPasswordModal(true);
  };

  const logOut = () => {
    localStorage.removeItem("access_token");
    props.logOut();
    history.push("/");
  };
  var st = useSelector((state) => state.user);
  var user = st.data.user;
  var roles = st.data.roles;
  var Title = "";
  for (var i = 0; i < roles.length; i++) {
    if (roles[i].status) {
      Title = roles[i].name;
      break;
    }
  }
  var name = `${user.name} ${user.patronymic}`;
  const content = () => {
    return (
      <div className="profil-info">
        <Row className="border-bottom pt-1 pb-1 mb-10">
          <Col xs={4}>
            {/* <Avatar size={38} src={props.gender === 0 ? man : woman} /> */}
            <Avatar size={38} src={woman} />
          </Col>
          <Col className="border-right" xs={20}>
            <div
              style={{ justifyContent: "space-between" }}
              className="flex w-100 h-100 flex-align-center pr-1"
            >
              <h3>{name}</h3>
            </div>
          </Col>
        </Row>
        <div className="w-100  flex flex-between">
          <Button onClick={handleShowPasswordModal}>
            <SettingOutlined /> <span>Sazlamalar</span>
          </Button>
          <Button onClick={roleSwitch}>
            <SwitcherOutlined /> <span>Role Switch</span>
          </Button>
          <Button onClick={logOut}>
            <span>Çıxış</span> <LogoutOutlined />
          </Button>
        </div>
      </div>
    );
  };

  let localLanguage = localStorage.getItem("i18nextLng") || "az";
  const [language, setLanguage] = useState(localLanguage);

  function onLangaugeChange(lang) {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    history.go(0);
  }

  const items = [
    {
      key: "az",
      label: <span onClick={() => onLangaugeChange("az")}>Az</span>,
    },
    {
      key: "tr",
      label: <span onClick={() => onLangaugeChange("tr")}>Tr</span>,
    },
    {
      key: "en",
      label: <span onClick={() => onLangaugeChange("en")}>En</span>,
    },
    {
      key: "ru",
      label: <span onClick={() => onLangaugeChange("ru")}>Ru</span>,
    },
  ];

  const languages = {
    az: "Az",
    tr: "Tr",
    en: "En",
    ru: "Ru",
  };

  return (
    <div className="position-relative">
      <div className="top-menu flex-align-center flex ">
        {props.showDrawerButton ? (
          <Button type="primary" className="mr-20" onClick={props.toggleDrawer}>
            {props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        ) : null}
        {props.collapsed ? (
          <div className="top-menu-navigation">
            <Link to="/">
              <img className="animated fadeIn" src={logo} alt="" />
            </Link>
            <div>
              <ul className="top-menu-links">
                <a href="#">
                  <li>Bələdçi</li>
                </a>
                <a href="#">
                  <li>FAQ</li>
                </a>
                <a href="#">
                  <li style={{ width: "100px" }}>İstifadə qaydaları</li>
                </a>
              </ul>
            </div>
          </div>
        ) : null}

        {!props.collapsed ? (
          <div>
            <ul className="top-menu-links">
              <a href="">
                <li>Bələdçi</li>
              </a>
              <a href="">
                <li>FAQ</li>
              </a>
              <a href="">
                <li style={{ width: "100px" }}>İstifadə qaydaları</li>
              </a>
            </ul>
          </div>
        ) : null}
        <Dropdown menu={{ items }} placement="bottom">
          <Button className="language-button">{languages[language]}</Button>
        </Dropdown>
        <div
          className="top-menu-navigation"
          onClick={() => setShowPopover(!showPopover)}
        >
          <Space direction="vertical" size={0}>
            <Text strong>{name}</Text>
            <Text type="secondary">{Title} / 07.06.2023 18:00</Text>
          </Space>
          <Tooltip
            placement="bottomRight"
            // title={props.name}
          >
            <Popover
              className="ml-20"
              placement="bottomRight"
              content={content()}
              trigger="click"
              visible={showPopover}
            >
              {/* <Avatar size={35} src={props.gender === 0 ? man : woman} /> */}
              <Avatar shape="square" size={40} src={woman} />
            </Popover>
          </Tooltip>
        </div>
        {showRolesModal ? (
          <Modal
            open={showRolesModal}
            title="Rol sec"
            footer={[]}
            onOk={closeModal}
            onCancel={closeModal}
          >
            <div className="modal-content">
              {rolesRedux.map((role) => {
                return (
                  <div key={role.id} className="modal-content__item">
                    <span>{role.name}</span>
                    <Button
                      type={"primary"}
                      disabled={role.status}
                      onClick={() => handleSwitchRole(role)}
                    >
                      Gecis Yap
                    </Button>
                  </div>
                );
              })}
            </div>
          </Modal>
        ) : null}
        {showPasswordModal ? (
          <UserInfoEdit
            showPasswordModal={showPasswordModal}
            closeModal={closeModal}
          />
        ) : null}
      </div>
    </div>
  );
};
const mapStateToProps = ({ user }) => {
  return {
    isLoggedIn: user.isLoggedIn,
    // name: user.data.userName,
    // gender: user.data.userGender,
  };
};
export default connect(mapStateToProps, { logOut })(TopMenu);
