import "./AddFileOrFolderButton.scss";

import React from "react";
import { Button, Popover, Upload } from "antd";
import {
  PlusOutlined,
  FileAddOutlined,
  FolderAddOutlined,
} from "@ant-design/icons";

import useAddfile from "./useAddfile";
import useAddFolder from "./useAddFolder";
import AddFolderModal from "./AddFolderModal";

function AddFileorFolderButton({
  getLastAdded = null,
  getTableDatas = null,
  folderId = null,
}) {
  const {
    addFile,
    setFileList,
    setAddFilePopoverIsOpen,
    addFilePopoverIsOpen,
    fileList,
  } = useAddfile({
    getLastAdded,
    getTableDatas,
  });

  const {
    addFolderModalIsOpen,
    closeAddFolder,
    openAddFolder,
    submitAddFolder,
  } = useAddFolder({
    folderId,
    getLastAdded,
    getTableDatas,
    setAddFilePopoverIsOpen,
  });

  const content = (
    <div>
      <div>
        <Button
          style={{ border: "0" }}
          icon={<FolderAddOutlined />}
          onClick={openAddFolder}
        >
          Qovluq yüklə
        </Button>
      </div>
      <div>
        <Upload
          customRequest={({ file, onSuccess, onError }) => {
            addFile({ file, onSuccess, onError, folderId });
          }}
          fileList={fileList}
          setFileList={setFileList}
        >
          <Button style={{ border: "0" }} icon={<FileAddOutlined />}>
            Fayl yüklə
          </Button>
        </Upload>
      </div>
    </div>
  );

  return (
    <>
      <Popover
        content={content}
        title="Title"
        trigger="click"
        placement="bottom"
        open={addFilePopoverIsOpen}
        onOpenChange={setAddFilePopoverIsOpen}
      >
        <Button className="add-btn">
          Əlavə et <PlusOutlined />
        </Button>
      </Popover>

      {addFolderModalIsOpen && (
        <AddFolderModal
          isOpen={addFolderModalIsOpen}
          closeModal={closeAddFolder}
          title="Qovluq yarat"
          submitAddFolder={submitAddFolder}
          globalFolderId={folderId}
        />
      )}
    </>
  );
}

export default AddFileorFolderButton;
