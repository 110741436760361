import React from "react";
import clsx from "clsx";
import { Switch } from "antd";
import FormErrorHandler from "./FormErrorHandler";

const SwitchBox = (props) => {
  var formik = props?.formik;
  var field = props?.field;
  var label = props?.label;
  var required =
    props.required === undefined
      ? false
      : props.required === true
      ? true
      : false;
  var disabled = props.disabled ?? false;
  var visibilty = props.visibilty ?? true;

  const infoError = formik?.errors?.info || {};
  const infoTouched = formik?.touched?.info || {};
  const classes = clsx(
    disabled && "input-disabled",
    infoError[`${field}`] && infoTouched[`${field}`] && "error",
    "input-switch"
  );

  const onSwitchChange = (isChecked) => {
    formik.setFieldValue(`info.${field}`, isChecked);
  };

  return (
    <div
      style={{ display: visibilty === true ? "block" : "none" }}
      className="input-box"
    >
      <div className={classes}>
        <span>
          {label}{" "}
          {required === true ? <span style={{ color: "red" }}>*</span> : null}
        </span>
        <Switch
          disabled={disabled}
          checked={formik?.values?.info[field]}
          onChange={onSwitchChange}
        />
      </div>
      <FormErrorHandler formik={formik} field={`info.${field}`} />
    </div>
  );
};

export default SwitchBox;
