import React from "react";
import { Link } from "react-router-dom";

const VoenDetailItem = ({ title, value, href }) => {
  return (
    <div className="VoenDetailItem">
      <span className="VoenDetailItemTitle">{title}</span>
      <div className="VoenDetailItemBody">
        <span>{value}</span>
        {href && <Link to={href}>Ətraflı</Link>}
      </div>
    </div>
  );
};

export default VoenDetailItem;
