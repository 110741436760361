import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "./Login/Login";

function RoutesAuth() {
  const reduxUser = useSelector((state) => state.user);
  const { isLoggedIn, isRoleSelected } = reduxUser;

  return (
    <>
      {!isLoggedIn && <Redirect to="/auth/login" />}
      {isLoggedIn && !isRoleSelected && <Redirect to="/auth/user-role" />}

      <Switch>
        <Route exact path={`/auth/login`} component={Login} />
      </Switch>
    </>
  );
}

export default RoutesAuth;
