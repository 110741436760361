import React from "react";
import "./FormErrorHandler.css";

function FormErrorHandler(props) {
  const formik = props.formik;
  const field = props.field;

  const inputHasError = (string) => {
    const keys = string.split(".");
    let fieldTouched = formik?.touched;
    let fieldErrors = formik?.errors;
    keys.forEach((keyStr) => {
      fieldTouched = fieldTouched?.[keyStr];
      fieldErrors = fieldErrors?.[keyStr];
    });
    if (fieldTouched && fieldErrors) {
      return true;
    }
    return false;
  };

  const getInputErrorMessage = (string) => {
    const keys = string.split(".");
    let fieldErrors = formik.errors;
    keys.forEach((keyStr) => {
      fieldErrors = fieldErrors?.[keyStr];
    });

    return fieldErrors;
  };

  return (
    <div className="errorText">
      {inputHasError(field) ? getInputErrorMessage(field) : ""}
    </div>
  );
}

export default FormErrorHandler;
