import React from "react";

import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import TextareaBox from "components/Elements/FileUploadForm/Form/Textarea";
import Switch from "components/Elements/FileUploadForm/Form/Switch";
import DateTimePicker from "components/Elements/FileUploadForm/Form/DatePicker";
import SelectBox from "components/Elements/FileUploadForm/Form/SelectBox";

function DynamicField({ formik, method, listMethod, enumArr, listscheme }) {
  switch (formik.values.info.form_type) {
    case "character varying":
      return (
        <InputBox
          label="Default dəyər"
          field="form_default"
          type="text"
          required={false}
          formik={formik}
          visibilty={method == "edit" && listMethod == "edit" ? false : true}
          disabled={method == "edit" && listMethod == "edit" ? true : false}
        />
      );
    case "bigint":
      return (
        <InputBox
          label="Default dəyər"
          field="form_default"
          type="number"
          required={false}
          formik={formik}
          visibilty={method == "edit" && listMethod == "edit" ? false : true}
          disabled={method == "edit" && listMethod == "edit" ? true : false}
        />
      );
    case "double precision":
      return (
        <InputBox
          label="Default dəyər"
          field="form_default"
          type="number"
          required={false}
          formik={formik}
          visibilty={method == "edit" && listMethod == "edit" ? false : true}
          disabled={method == "edit" && listMethod == "edit" ? true : false}
        />
      );
    case "text":
      return (
        <TextareaBox
          rows={4}
          label="Default dəyər"
          field="form_default"
          required={false}
          formik={formik}
          visibilty={method == "edit" && listMethod == "edit" ? false : true}
          disabled={method == "edit" && listMethod == "edit" ? true : false}
        />
      );
    case "boolean":
      return (
        <Switch
          label="Default dəyər"
          field="form_default"
          required={false}
          formik={formik}
          visibilty={method == "edit" && listMethod == "edit" ? false : true}
          disabled={method == "edit" && listMethod == "edit" ? true : false}
        />
      );
    case "date":
      return (
        <DateTimePicker
          label="Default dəyər"
          field="form_default"
          required={false}
          formik={formik}
          visibilty={method == "edit" && listMethod == "edit" ? false : true}
          disabled={method == "edit" && listMethod == "edit" ? true : false}
        />
      );
    case "enums":
      return (
        <SelectBox
          label="Default dəyər"
          field="form_default"
          required={false}
          formik={formik}
          data={enumArr}
          type="simple"
          settings={listscheme}
          visibilty={method == "edit" && listMethod == "edit" ? false : true}
          disabled={method == "edit" && listMethod == "edit" ? true : false}
        />
      );
    case "time without time zone":
      return (
        <DateTimePicker
          label="Default dəyər"
          field="form_default"
          required={false}
          formik={formik}
          type="time"
          visibilty={method == "edit" && listMethod == "edit" ? false : true}
          disabled={method == "edit" && listMethod == "edit" ? true : false}
        />
      );
    case "timestamp without time zone":
      return (
        <DateTimePicker
          label="Default dəyər"
          field="form_default"
          required={false}
          formik={formik}
          showTime
          visibilty={method == "edit" && listMethod == "edit" ? false : true}
          disabled={method == "edit" && listMethod == "edit" ? true : false}
        />
      );
    default:
      return (
        <InputBox
          label="Default dəyər"
          field="form_default"
          type="number"
          required={false}
          formik={formik}
          visibilty={method == "edit" && listMethod == "edit" ? false : true}
          disabled={method == "edit" && listMethod == "edit" ? true : false}
        />
      );
  }
}

export default DynamicField;
