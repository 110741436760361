import { Skeleton } from "antd";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import GL from "libs/GL";
import API from "const/API2";
import { apiRoutes } from "const/apiroutes";
import EmptyPage from "components/Elements/Skelethon/EmptyPage";
import SuperTable from "components/Elements/Tabulator/SuperTable";
import { GetPagePermission, GetTableSettings } from "hooks/permissions";

const pageId = "parameters";

function Parameters({ scenarioNavigationDetails }) {
  const { t } = useTranslation();
  const history = useHistory();
  var dispatch = useDispatch();
  var api = new API();
  let endpoints = apiRoutes.parameters;
  const reduxRisk = useSelector((s) => s.risk);

  const [parameterList, setParameterList] = useState([]);
  const [loading, setLoading] = useState(false);

  const permissions = GetPagePermission(2);
  const tableSettings = GetTableSettings(pageId);

  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {
        history.push({
          pathname: "add-parameters",
          state: {
            method: "edit",
            id: data.id,
          },
        });
      },
    },
    delete: {
      status: permissions["delete"],
      click: function (data) {
        GL.yesNo(t("parameters_delete_confirm"), (status) => {
          if (status) {
            api.deleteData(
              `${endpoints.delete}/${data.id}`,
              (_) => {
                getParameterList();
                GL.notify.success({
                  title: t("answer_success"),
                  text: t("parameters_deleted"),
                });
              },
              (err) => {
                GL.notify.error({
                  title: t("answer_error"),
                  text: t("parameters_not_deleted"),
                });
                console.log("err =>", err);
              }
            );
          }
        });
      },
    },
    show: {
      status: permissions["show"],
      click: function (data) {},
    },
    copy: {
      status: permissions["copy"],
      click: function (data) {
        history.push({
          pathname: "add-parameters",
          state: {
            ...data,
            method: "copy",
          },
        });
      },
    },
  };

  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/companies/add",
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/companies/multiDelete",
      },
    },
    settings: {
      id: pageId,
      type: "data",
      data: parameterList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "+ " + t("global_create", { name: t("parameter") }),

      createNewRoute: "departments/create",
      toolbarStatus: false,
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 100,
        hozAlign: "center",
      },
      {
        title: t("global_name"),
        field: "name",
      },
      {
        title: t("global_code"),
        field: "code",
      },
      {
        title: t("parameters_type_number"),
        field: "type",
      },
      {
        title: t("parameters_identificator"),
        field: "isUnique",
      },
      {
        title: t("voen"),
        field: "voen",
      },
      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        id: {
          dataType: "number",
          placeholder: t("global_search"),
        },
        name: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        code: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        type: {
          dataType: "select",
          placeholder: t("global_all"),
        },
        voen: {
          dataType: "checkbox",
          placeholder: t("global_all"),
          mean: {
            true: t("global_yes"),
            false: t("global_no"),
            null: t("global_unknown"),
          },
        },
        isUnique: {
          dataType: "checkbox",
          placeholder: t("global_all"),
          mean: {
            true: t("global_yes"),
            false: t("global_no"),
            null: t("global_unknown"),
          },
        },
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {},
    },
  };

  const getValidArray = (arr, sid) => {
    var newArr = [];
    arr.map((a) => {
      var scenario_id = a.scenariosDto == undefined ? 0 : a.scenariosDto.id;
      if (sid == scenario_id) {
        var s = {
          id: a.id,
          name: a.name,
          code: a.code,
          type: a.type,
          isUnique: a.isUnique,
          voen: a.isVoen,
          // scenario_id: scenario_id,
          note: a.note,
        };
        newArr.push(s);
      }
    });
    return newArr;
  };

  const getParameterList = () => {
    setLoading(true);
    api.getData(
      endpoints.list,
      (data) => {
        var senario_id = reduxRisk.id;
        let d = getValidArray(data, senario_id);
        setParameterList(d);
        setLoading(false);
      },
      (_) => {
        setLoading(false);
      }
    );
  };

  const handleAdd = () => {
    history.push({
      pathname: "add-parameters",
      state: { method: "create" },
    });
  };

  useEffect(() => {
    getParameterList();
  }, []);

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {parameterList.length == 0 ? (
            <EmptyPage
              route="add-parameters"
              name="Parametre"
              state={{ method: "create" }}
            />
          ) : (
            <div className="risk-page">
              <span className="risk-page__title">
                {t("scenario_parameters")}
              </span>
              <button
                className="risk-page__add-button"
                onClick={handleAdd}
              >
                {t("paramters_add")}
              </button>
              <SuperTable data={options} />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Parameters;
