const ExcelFunctions = [
    {
      name: "IF", 
      type: "if",
      format: [
        { type: "rule", color:"#009688", value: "IF(" },
        { type: "div", value: [] },
        { type: "rule", color:"#009688", value: "," },
        { type: "div", value: [] },
        { type: "rule", color:"#009688", value: "," },
        { type: "div", value: [] },
        { type: "rule", color:"#009688", value: ")" },
      ],
    },
    {
      name: "SUM",
      type: "sum",
      format: [
        { type: "rule", color:"#ff9800", value: "SUM(" },
        { type: "div", value: [] },
        { type: "rule", color:"#ff9800", value: "," },
        { type: "div", value: [] },
        { type: "rule", color:"#ff9800", value: ")" },
      ],
    },
    {
        name: "AVG",
        type: "avg",
        format: [
          { type: "rule", color:"#9c27b0", value: "AVG(" },
          { type: "div", value: [] },
          { type: "rule", color:"#9c27b0", value: "," },
          { type: "div", value: [] },
          { type: "rule", color:"#9c27b0", value: ")" }
        ],
    },
    {
        name: "MIN",
        type: "min",
        format: [
          { type: "rule", color:"#3f51b5", value: "MIN(" },
          { type: "div", value: [] },
          { type: "rule", color:"#3f51b5", value: "," },
          { type: "div", value: [] },
          { type: "rule", color:"#3f51b5", value: ")" }
        ],
    },
    {
        name: "MAX",
        type: "max",
        format: [
          { type: "rule", color:"#f44336", value: "MAX(" },
          { type: "div", value: [] },
          { type: "rule", color:"#f44336", value: "," },
          { type: "div", value: [] },
          { type: "rule", color:"#f44336", value: ")" }
        ],
    },
    {
        name: "ABS",
        type: "abs",
        format: [
          { type: "rule", color:"#607d8b", value: "ABS(" },
          { type: "div", value: [] },
          { type: "rule", color:"#607d8b", value: ")" }
        ],
    },
    {
      name: "VALUE",
      type: "value",
      format: [
        { type: "rule", color:"#607d8b", value: "VALUE(" },
        { type: "div", value: [] },
        { type: "rule", color:"#607d8b", value: ")" }
      ],
  },
  ];

export default ExcelFunctions;