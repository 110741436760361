import React, { useState, useEffect } from "react";
import DynamicTable from "components/Elements/DynamicTable/DynamicTable";
import FooterTools from "../../Components/FooterTools/FooterTools";
import Export from "components/Elements/Icons/Export";
import Risks from "models/Risks";
import { Skeleton, Result } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function Resend(props) {
  const { activeRole } = useSelector(({ user }) => ({
    activeRole: user.data.roles?.find(({ status }) => status),
  }));
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const tab = queryParams.get("tab");
  const subTab = queryParams.get("subTab");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getRiskDetails = () => {
    setIsLoading(true);
    Risks.getRiskDetails(
      "resend&roleId=" + activeRole?.id,
      (data) => {
        setData(data);
        setIsLoading(false);
      },
      () => {
        setData([]);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (tab === "returnRisks") getRiskDetails();
  }, [tab, subTab]);

  return (
    <div className="w-100">
      {isLoading && <Skeleton active />}
      {!isLoading && data.length > 0 && (
        <React.Fragment>
          <DynamicTable pageId="tab-resend" {...props} data={data} />
          <div className="main-layout-wrapper__footer">
            <FooterTools
              buttons={[{ className: "btn-danger", children: "İcraya ver" }]}
              extraButtons={[
                {
                  className: "btn-primary d-flex align-items-center",
                  children: "Export",
                  icon: <Export />,
                },
              ]}
            />
          </div>
        </React.Fragment>
      )}
      {!isLoading && data.length === 0 && (
        <Result status="404" title="Heç bir məlumat tapılmadı." />
      )}
    </div>
  );
}

export default Resend;
