export const folderColorPalette = [
  "#FFDB00",
  "#EF4042",
  "#00BCF2",
  "#09A552",
  "#005745",
  "#D9D9D9",
  "#979797",
  "#11E0BB",
  "#ABE9FB",
  "#681EC6",
  "#414141",
  "#FF681C",
  "#1F6FEB",
  "#89CC63",
];
