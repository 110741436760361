import React from "react";
import { useState, useMemo } from "react";
import { EditableCell, EditableRow } from "../../EditableCell/EditableCell";

const useColumns = (tools) => {
  const { isDisabled, sendChangableDecisionData } = tools;
  const columns = useMemo(
    () => [
      {
        title: <h1 className="text-left p-10">EQF üzrə təfsilatlar</h1>,
        children: [
          {
            title: "Malın işin xidmətin adı",
            dataIndex: "serviceName",
            key: "serviceName",
          },
          {
            title: "Üst kateqoriya",
            dataIndex: "topCategory",
            key: "topCategory",
          },
          {
            title: "Alt kateqoriya",
            dataIndex: "subCategory",
            key: "subCategory",
          },
          {
            title: "Ölçü vahidi",
            dataIndex: "unit",
            key: "unit",
          },
          {
            title: "Miqdarı",
            dataIndex: "amount",
            key: "amount",
          },
          {
            title: "Vahidinin dəyəri",
            dataIndex: "unitCost",
            key: "unitCost",
          },
          {
            title: "ƏDV",
            dataIndex: "fakeVat",
            key: "fakeVat",
          },
          {
            title: "Cəmi dəyəri",
            dataIndex: "totalCost",
            key: "totalCost",
          },
        ],
      },
      {
        title: " ",
        width: 24,
      },
      {
        title: <h1 className="text-left p-10">Əmtəəsiz hesab edilən</h1>,
        children: [
          {
            title: "Cəmi əsas dəyər",
            dataIndex: "realPrice",
            key: "realPrice",
            onCell: (record) => ({
              record,
              editable: !isDisabled,
              dataIndex: "realPrice",
              title: "Cəmi əsas dəyər",
              type: "number",
              handleSave: (row, input) => sendChangableDecisionData(row, input),
            }),
          },
          {
            title: "ƏDV",
            dataIndex: "vat",
            key: "vat",
            onCell: (record) => ({
              record,
              editable: !isDisabled,
              dataIndex: "vat",
              title: "ƏDV",
              type: "number",
              handleSave: (row, input) => sendChangableDecisionData(row, input),
            }),
          },
          {
            title: "Cəmi dəyər",
            dataIndex: "price",
            key: "price",
            onCell: (record) => ({
              record,
              editable: !isDisabled,
              dataIndex: "price",
              title: "Cəmi dəyər",
              type: "number",
              handleSave: (row, input) => sendChangableDecisionData(row, input),
            }),
          },
        ],
      },
    ],
    [isDisabled]
  );

  const [components] = useState({
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  });
  return { columns, components };
};

export default useColumns;
