import "./styles/Library.scss";

import React from "react";
import { Divider, Empty, Skeleton } from "antd";

import FileCard from "./components/FileCard/FileCard";
import LibraryTable from "./components/LibraryTable/LibraryTable";
import { useLastAdded } from "./hooks/useLastAdded";
import { useLastViewed } from "./hooks/useLastViewed";

function LibraryMain() {
  const { fileCardsList, getLastAdded, filesLoading } = useLastAdded();
  const { lastViewedList, getLasrViewedListForTable, lastViewedLoading } =
    useLastViewed();
  return (
    <div className="library-page">
      <h1 className="page-title">Əsas səhifə</h1>

      <Divider />
      <h2 className="page-subtitle">Son əlavə olunanlar</h2>
      {filesLoading && <Skeleton active />}
      {!filesLoading && fileCardsList.length ? (
        <div className="file-cards-wrapper">
          {fileCardsList.map((fileCardData) => (
            <div key={fileCardData.id} className="file-card-wrapper">
              <FileCard
                {...fileCardData}
                getLasrViewedListForTable={getLasrViewedListForTable}
              />
            </div>
          ))}
        </div>
      ) : (
        <Empty description="Məlumat tapılmadı" />
      )}

      <Divider />
      <h2 className="page-subtitle">Son baxdıqlarınız</h2>
      <LibraryTable
        data={lastViewedList}
        getData={getLasrViewedListForTable}
        getLastAdded={getLastAdded}
        isLoading={lastViewedLoading}
      />
    </div>
  );
}

export default LibraryMain;
