import API from "../const/API2";
import { apiRoutes } from "../const/apiroutes";
import User from "./User";

var api = new API();

class UserGroup {
  constructor(obj) {
    this.id = obj["id"];
    this.name = obj["name"];
    this.userCount = obj["userCount"];
    this.sectorId = obj["sectorId"];
    if (obj["managerId"] !== null) {
      this.manager = new User(obj["managerId"]);
    } else {
      this.manager = new User();
    }
    this.isActive = obj["isActive"];

    if (obj["userGroupsRelList"] !== null) {
      this.userGroupsRelList = obj["userGroupsRelList"].map(
        (item) => new User(item.usersDto)
      );
    }
  }

  getName() {
    return this.name;
  }

  getFullName() {
    return this.totalName;
  }

  static getUserGroupsFromArray(data) {
    var arr = [];
    data.map((a) => {
      var c = new UserGroup(a);
      arr.push(c);
    });
    return arr;
  }

  static getUserGroupList(callback, errCallback) {
    api.getData(
      apiRoutes.userGroups.list,
      (data) => {
        var arr = UserGroup.getUserGroupsFromArray(data);
        callback(arr);
      },
      errCallback
    );
  }

  static getUserGroupByID(groupID, callback, errCallback) {
    if (!Boolean(groupID)) return;
    api.getData(
      apiRoutes.userGroups.getByID + groupID,
      (response) => {
        const group = new UserGroup(response);
        callback(group);
      },
      errCallback
    );
  }

  static getUserGroupBySectorID(sectorId, callback, errCallback) {
    if (!Boolean(sectorId)) return;
    api.getData(
      `${apiRoutes.userGroups.search}sectorId=${sectorId}`,
      (response) => {
        const groups = UserGroup.getUserGroupsFromArray(response);
        callback(groups);
      },
      errCallback
    );
  }

  getTableItem() {
    return {
      id: this.id,
      name: this.name,
      manager: this.manager.getName(),
      managerId: this.manager.id,
      userCount: this.userCount,
      isActive: this.isActive,
    };
  }

  static getForTableData(data) {
    var arr = [];
    data.map((a) => {
      arr.push(a.getTableItem());
    });
    return arr;
  }

  static sortDESC(a, b) {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    return 0;
  }

  static getArrayForSelect(data) {
    var arr = [];
    data.map((a) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    return arr;
  }

  static UPDATE(data, callback, errCallback) {
    if (data == false) {
      return null;
    }
    var id = data["id"];
    if (data.manager !== null) {
      data.managerId = {
        id: data.manager,
      };
    } else {
      delete data.manager;
    }
    if (id !== 0 && id !== "" && id !== null && id !== undefined) {
      api.putData(
        apiRoutes.userGroups.put + "/" + id,
        data,
        (res) => {
          if (callback !== undefined) {
            callback(res);
          }
        },
        errCallback
      );
    }
  }

  static CREATE(data, callback, errCallback) {
    if (data == false) {
      return null;
    }
    if (data["id"] !== undefined) {
      delete data["id"];
    }
    if (data.manager !== null) {
      data.managerId = {
        id: data.manager,
      };
    } else {
      delete data.manager;
    }
    api.postData(
      apiRoutes.userGroups.add,
      data,
      (res) => {
        if (callback !== undefined) {
          callback(res);
        }
      },
      errCallback
    );
  }

  static DELETE(id, callback) {
    if (id == 0 || id == "" || id == null || id == undefined) {
      return null;
    }

    api.deleteData(
      `${apiRoutes.userGroups.put}/${id}`,
      (data) => {
        if (callback) callback(true, data);
      },
      (err) => {
        if (callback) callback(false, err);
      }
    );
  }
}

export default UserGroup;
