import React from "react";
import { Button, Space, Typography, Input, AutoComplete } from "antd";
import { useMailContext } from "./MailContextAPI";
import { PlusOutlined } from "@ant-design/icons";
import "./Mail.css";
import { useHistory } from "react-router";
import { MailModel } from "./models/MailModel";
import { useState } from "react";
const { Title } = Typography;

const MailHeader = ({ setQueryParam, handleClickTab }) => {
  const { state, dispatch } = useMailContext();
  const history = useHistory();
  const {
    currentPage,
    currentSendPage,
    currentInboxPage,
    pageSize
  } = state.paginationInfo;

  const searchParamsHandler = new URLSearchParams(history.location.search);
  const activeTab = searchParamsHandler.get("tab");

  const showModal = () => dispatch({ type: "SET_SHOW_MODAL", payload: true });

  const [searchValue, setSearchValue] = useState();
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = value => {
    setSearchValue(value);

    if (value.length >= 1) {
      MailModel.getMailForSearch(
        value,
        resDataSearch => {
          setSearchResults(resDataSearch);
        },
        err => {
          console.log(err, "ERROR MESSAGE FROM SEARCH REQUEST");
        }
      );
    } else if (value === "") {
      setSearchResults([]);
    } else {
      setSearchResults([]);
    }
  };

  const refreshPage = activeTab => {
    activeTab === "inbox"
      ? MailModel.getInbox(
          currentInboxPage,
          pageSize,
          modelDataInbox => {
            dispatch({
              type: "SET_MAIL_INBOX",
              payload: modelDataInbox
            });
          },
          err => {
            console.log(err, "ERROR MESSAGE FROM INBOX REQUEST");
          }
        )
      : MailModel.getSend(
          currentSendPage,
          pageSize,
          modelDataSend => {
            dispatch({
              type: "SET_MAIL_SENT",
              payload: modelDataSend
            });
          },
          err => {
            console.log(err, "ERROR MESSAGE FROM SEND REQUEST");
          }
        );
  };

  const onSelect = (value, option) => {
    dispatch({ type: "SET_RECORD_KEY", payload: option.result.key });
    dispatch({ type: "SET_SHOW_MODAL", payload: false });
    dispatch({ type: "SET_MAIN_OPEN", payload: false });
    handleClickTab("mailId", option.result.key);

    setSearchValue("");
    setSearchResults([]);
  };

  return (
    <Space direction="vertical" size="large">
      <Title
        style={{
          fontSize: "26px",
          color: "#000",
          lineHeight: "16px",
          fontWeight: "600"
        }}
      >
        E-maillər
      </Title>
      <Space
        direction="horizontal"
        style={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <Space direction="horizontal" style={{ alignItems: "center" }}>
          <Button
            className={
              activeTab === "inbox" || activeTab === "new-mail"
                ? "button-mail-inbox active-btn-mail"
                : "button-mail-inbox"
            }
            onClick={() => {
              dispatch({ type: "SET_INBOX_OPEN", payload: true });
              dispatch({ type: "SET_SENT_OPEN", payload: false });
              dispatch({ type: "SET_MAIN_OPEN", payload: true });
              handleClickTab("tab", "inbox");
              refreshPage(activeTab);
            }}
            style={{
              background: activeTab === "inbox" ? "#C64D42" : "#F8F9FA",
              padding: "10px",
              border: "none",
              borderRadius: "7px",
              fontSize: "16px",
              width: 180
            }}
          >
            Gələnlər
          </Button>
          <Button
            className={
              activeTab === "sent"
                ? "button-mail-sent active-btn-mail"
                : "button-mail-sent"
            }
            onClick={() => {
              dispatch({ type: "SET_INBOX_OPEN", payload: false });
              dispatch({ type: "SET_SENT_OPEN", payload: true });
              dispatch({ type: "SET_COLOR", payload: "#fff" });
              dispatch({ type: "SET_MAIN_OPEN", payload: true });
              handleClickTab("tab", "sent");
              refreshPage(activeTab);
            }}
            style={{
              background: activeTab === "sent" ? "#C64D42" : "#F8F9FA",
              borderRadius: "7px",
              border: "none",
              fontSize: "16px",
              padding: "5px",
              width: 180
            }}
          >
            Göndərdiklərim
          </Button>
        </Space>
        {!searchParamsHandler.get("mailId") &&
          <Space>
            <Button
              className="new-mail-btn"
              style={{
                color: "white",
                border: "none",
                borderRadius: "6px",
                background: "#09A552",
                padding: "10px",
                fontSize: "16px",
                width: 200,
                height: "70px"
              }}
              onClick={() => {
                showModal();
              }}
            >
              Yeni mail göndər <PlusOutlined />
            </Button>
          </Space>}
      </Space>
      {!searchParamsHandler.get("mailId") &&
        <AutoComplete
          dropdownMatchSelectWidth={252}
          style={{ width: "100%" }}
          placeholder="Mail axtar"
          options={searchResults.map(result => {
            return {
              key: result.key,
              value: result.key,
              label: `${result.content && result.content}`,
              result: result
            };
          })}
          onSelect={onSelect}
          onSearch={handleSearch}
          value={searchValue}
        />}
    </Space>
  );
};

export default MailHeader;
