import { useState, useEffect, useCallback } from "react";
import { recomendedData, invoiceSettingsChecks } from "const/initialDatas";
import Risks from "models/Risks";
import { roles } from "./useInvoice";
const useRiskView = (tools) => {
  const { riskId, activeRisk, userId, formik, activeRole, deleteSubTab } =
    tools;
  const [isRiskPending, setIsRiskPending] = useState(false);
  const [nextUser, setNextUser] = useState(null);
  const [workflowUsers, setWorkflowUsers] = useState({
    next: {},
    prev: {},
    newUser: {},
  });
  const [executor, setExecutor] = useState(null);
  const [oldExecutor, setOldExecutor] = useState(null);
  const [groupId, setGroupId] = useState(null);

  const [tax, setTax] = useState("");

  const getRiskByID = useCallback((riskId) => {
    setIsRiskPending(true);
    Risks.getRiskByID(riskId, (data) => {
      console.log(data);
      const { hasWorkFlow, workFlow } = data;
      const executor = {
        userId: data.executor?.id,
        name: data.executor?.firstName + " " + data.executor?.lastName,
        riskId: Number(riskId),
        date: data.createdAt,
        hasIcon: false,
        statusId: data.riskStatus.id,
        hasWorkFlow,
      };
      const riskWorkUsers = {
        next: {},
        prev: {},
        newUser: {},
      };
      if (hasWorkFlow && data.executor) {
        const { nodeDataArray = [], linkDataArray = [] } = workFlow;
        const findGroup = nodeDataArray?.find((item) => item?.type === "group");
        const groupId = findGroup?.id.replace(/[\D\_]/g, "");
        setGroupId(groupId);
        const findKey = nodeDataArray?.find(
          (item) => item?.id === `user_${userId}`
        );
        const myKey = findKey?.key;
        const findNextFromKey = linkDataArray?.find(
          (item) => item?.from === myKey
        );
        const toNextKey = findNextFromKey?.to;
        const next = nodeDataArray?.find((item) => item?.key === toNextKey);
        const nextUserId = Number(next?.id?.replace("user_", ""));
        const newNextUserId = nextUserId
          ? nextUserId
          : activeRole?.label === "leader"
          ? userId
          : executor?.userId;
        riskWorkUsers.next = {
          ...next,
          userId: newNextUserId,
        };
        const findPrevToKey = linkDataArray?.find((item) => item?.to === myKey);
        const fromPrevKey = findPrevToKey?.from;
        const prev = nodeDataArray?.find((item) => item?.key === fromPrevKey);
        const prevUserId = Number(prev?.id?.replace("user_", ""));
        const newPrevUserId = prevUserId
          ? prevUserId
          : activeRole?.label === "leader"
          ? userId
          : executor?.userId;
        riskWorkUsers.prev = {
          ...prev,
          userId: newPrevUserId,
        };
      }

      const settings = data.settings;
      const executorObj = settings.executor;
      const headofdepartment = settings.headofdepartment;
      const leader = settings.leader;

      if (executorObj.status === "") {
        executorObj.checks = invoiceSettingsChecks;
        executorObj.note = "";
      }

      if (executorObj.status === "personal" && executorObj.userId !== userId) {
        executorObj.checks = invoiceSettingsChecks;
        executorObj.note = "";
      }

      if (headofdepartment.status === "") {
        headofdepartment.checks = invoiceSettingsChecks;
        headofdepartment.note = "";
      }

      if (
        headofdepartment.status === "personal" &&
        headofdepartment.userId !== userId
      ) {
        headofdepartment.checks = invoiceSettingsChecks;
        headofdepartment.note = "";
      }

      if (leader.status === "") {
        leader.checks = invoiceSettingsChecks;
        leader.note = "";
      }

      if (leader.status === "personal" && leader.userId !== userId) {
        leader.checks = invoiceSettingsChecks;
        leader.note = "";
      }

      formik.setValues({ info: settings });

      setNextUser(executor);
      setExecutor(executor);
      setOldExecutor(executor);
      setWorkflowUsers(riskWorkUsers);
      setIsRiskPending(false);
    });
  }, []);

  useEffect(() => {
    if (activeRisk === riskId) getRiskByID(riskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskId, activeRisk]);

  const onSetWorkflowUsers = (key, value) => {
    setExecutor((prev) => ({
      ...prev,
      hasIcon: value.info.userId ? false : true,
    }));
    setWorkflowUsers((prev) => ({ ...prev, [key]: { ...value.info } }));
  };

  const onSave = (values, onOpenRiskSubmitModal) => {
    let settings = {};
    if (activeRole?.label === "inspector") settings = values.info.executor;
    if (activeRole?.label === "leader") settings = values.info.leader;
    if (activeRole?.label === "departmenthead")
      settings = values.info.headofdepartment;
    let body = {
      riskId: Number(riskId),
      roleId: activeRole?.id,
      settings,
    };
    Risks.postSaveRiskData(body, () => {
      onOpenRiskSubmitModal();
    });
  };

  const onResend = useCallback(
    (onCloseExecutorModal, onOpenExecuterSuccessModal) => {
      const { prev, newUser } = workflowUsers;
      Risks.postRiskSendBack(
        {
          roleId: activeRole.id,
          userId: newUser.userId ? newUser.userId : prev.userId,
          riskId: Number(riskId),
          date: newUser?.lastExecuteDate,
        },
        (data) => {
          onCloseExecutorModal();
          onOpenExecuterSuccessModal();
          setTax(data.tax);
          onSetWorkflowUsers("newUser", {});
        },
        () => {
          onCloseExecutorModal();
        }
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [workflowUsers]
  );

  const onApprove = useCallback((onCloseSubmitModal, onOpenSuccessModal) => {
    Risks.postRiskApprove(
      {
        ...executor,
        roleId: activeRole.id,
        settings: formik.values.info[roles[activeRole.label]],
        userId: workflowUsers.next.userId,
      },
      (data) => {
        setTax(data.tax);
        onCloseSubmitModal();
        onOpenSuccessModal();
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteApproveTab = useCallback((onCloseSuccessModal) => {
    onCloseSuccessModal();
    setTax("");
    deleteSubTab(riskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteResendTab = useCallback((onCloseExecuterSuccessModal) => {
    onCloseExecuterSuccessModal();
    setTax("");
    deleteSubTab(riskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectExecutor = useCallback((executor, closeModal) => {
    if (!executor) {
      setExecutor(null);
      return;
    }
    const { info } = executor;
    const rId = Number(riskId);
    const newExecutor = {
      userId: info.user.value,
      name: info.user.label,
      riskId: rId,
      date: info.lastExecuteDate + "T00:00:00",
      hasIcon: oldExecutor?.userId === info.user.value,
      statusId: oldExecutor.statusId,
    };

    Risks.postRiskSetExecute(
      {
        riskId: [rId],
        lastExecuteDate: info.lastExecuteDate + "T00:00:00",
        userId: info.user.value,
      },
      () => {
        closeModal();
        setExecutor(newExecutor);
        deleteSubTab(riskId);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isRiskPending,
    nextUser,
    executor,
    workflowUsers,
    tax,
    groupId,
    oldExecutor,
    setExecutor,
    onSave,
    onResend,
    onApprove,
    onDeleteApproveTab,
    onDeleteResendTab,
    onSelectExecutor,
    onSetWorkflowUsers,
  };
};

export default useRiskView;
