import "./JsonViewer.scss";

import React from "react";
import ReactJson from "react-json-view";
import JsonModal from "../JsonModal/JsonModal";
import { t } from "i18next";

function JsonViewer(props) {
  const { formik, src, formikPath, isError, open, setOpen } = props;

  const onEdit = (edit) => {
    formik.setFieldValue(formikPath, edit.updated_src);
  };

  const onAdd = (add) => {
    formik.setFieldValue(formikPath, add.updated_src);
  };

  const onDelete = (del) => {
    formik.setFieldValue(formikPath, del.updated_src);
  };

  return (
    <div className={isError ? "json-viewer error" : "json-viewer"}>
      {open ? (
        <JsonModal
          open={open}
          setOpen={setOpen}
          formik={formik}
          formikPath={formikPath}
          title={t("json_editor")}
        />
      ) : null}

      <ReactJson
        src={src}
        displayDataTypes={false}
        displayObjectSize={false}
        name={null}
        shouldCollapse={() => false}
        onEdit={onEdit}
        onAdd={onAdd}
        onDelete={onDelete}
      />
    </div>
  );
}

export default JsonViewer;
