import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import Home from "./components/Pages/Home/Home";
import Companies from "./components/Pages/Companies/Companies";
import CompaniesEdit from "./components/Pages/Companies/CompaniesEdit";
import AddRisk from "./components/Pages/Scenario/AddRisk/AddRisk";
import ScenarioConfig from "./components/Pages/Scenario/ScenarioConfig/ScenarioConfig";
import AddStages from "./components/Pages/Scenario/AddRisk/Stages/AddStages/AddStages";
import AddParameters from "./components/Pages/Scenario/AddRisk/Parameters/AddParameters/AddParameters";
import AddWorkFlow from "./components/Pages/Scenario/AddRisk/WorkFlow/AddWorkFlow/AddWorkFlow";
import PreRisk from "./components/Pages/Scenario/PreRisk/PreRisk";
import Activities from "./components/Pages/Activities/Activities";
import ActivitiesEdit from "./components/Pages/Activities/ActivitiesEdit";
import UserGroups from "./components/Pages/UserGroups/UserGroups";
import UserGroupsEdit from "./components/Pages/UserGroups/UserGroupsEdit";
import Roles from "./components/Pages/Roles/Roles";
import RolesEdit from "./components/Pages/Roles/RolesEdit";
import Positions from "./components/Pages/Positions/Positions";
import PositionsEdit from "./components/Pages/Positions/PositionsEdit";
import Sectors from "./components/Pages/Sectors/Sectors";
import SectorsEdit from "./components/Pages/Sectors/SectorsEdit";
import Departments from "./components/Pages/Companies/Departments/Departments";
import DepartmentsEdit from "./components/Pages/Companies/Departments/DepartmentsEdit";
import RiskStatuses from "./components/Pages/RiskStatuses/RiskStatuses";
import RiskStatusesEdit from "./components/Pages/RiskStatuses/RiskStatusesEdit";
import RiskAppointment from "./components/Pages/Scenario/RiskAppointment/RiskAppointment";
import RiskAppointmentEdit from "./components/Pages/Scenario/RiskAppointment/RiskAppointmentEdit";
import RiskGroups from "./components/Pages/RiskGroups/RiskGroups";
import RiskGroupsEdit from "./components/Pages/RiskGroups/RiskGroupsEdit";

import RiskSeperating from "./components/Pages/RiskSeperating/RiskSeperating";
import RiskSeperatingEdit from "./components/Pages/RiskSeperating/RiskSeperatingEdit";

import Risk from "components/Pages/Risk/Risk";
import RiskEdit from "components/Pages/Risk/RiskEdit";

import Enums from "./components/Pages/Enums/Enums";
import EnumsEdit from "./components/Pages/Enums/EnumsEdit";
import Portfolios from "./components/Pages/Portfolios/Portfolios";
import PortfoliosEdit from "./components/Pages/Portfolios/PortfoliosEdit";
import UserList from "./components/Pages/User/UserList";
import UserListEdit from "./components/Pages/User/UserListEdit";

import MyList from "components/Pages/MyList/MyList";
import MyListEdit from "components/Pages/MyList/MyListEdit";
import SubList from "components/Pages/Sublist/SubList";
import SublistEdit from "components/Pages/Sublist/SublistEdit";
import AsyncTabulator from "components/Pages/AsyncTabulator/AsyncTabulator";
import EnumCategories from "components/Pages/EnumCategories/EnumCategories";
import EnumCategoriesEdit from "components/Pages/EnumCategories/EnumCategoriesEdit";
import Languages from "components/Pages/Languages/Languages";
import LanguagesEdit from "components/Pages/Languages/LanguagesEdit";
import Logs from "components/Pages/Logs/Logs";

import Profile from "components/Pages/Profile";
import Calendar from "components/Pages/Calendar/Calendar";
import CalendarCreate from "components/Pages/Calendar/CalendarCreate";
import Home from "components/Pages/Main/Home/Home";
import RTMSMessenger from "components/Pages/RTMSMessenger/RTMSMessenger";
import { AppProvider } from "components/Pages/RTMSMessenger/AppContext";
import Mail from "components/Pages/Mail/Mail";
import { MailProvider } from "components/Pages/Mail/MailContextAPI";
import LibraryMain from "components/Pages/Library/LibraryMain";
import LibraryCatalog from "components/Pages/Library/LibraryCatalog";
import LibraryMyFiles from "components/Pages/Library/LibraryMyFiles";
import MailDescWithReply from "components/Pages/Mail/MailDescWithReply";
import FolderDetailsCatalog from "components/Pages/Library/FolderDetailsCatalog";
import FolderDetailsMyFiles from "components/Pages/Library/FolderDetailsMyFiles";
import CollabHome from "components/Pages/Collaborators/pages/CollabHome/CollabHome";

function Routes() {
  return (
    <Switch>
      {/* <Route exact path={`/`} component={Home} /> */}
      <Route exact path={`/`} component={PreRisk} />
      <Route exact path={`/asynctabulator`} component={AsyncTabulator} />

      <Route exact path={`/activities`} component={Activities} />
      <Route exact path={`/activities/create`} component={ActivitiesEdit} />

      <Route exact path={`/user-groups`} component={UserGroups} />
      <Route exact path={`/user-groups/create`} component={UserGroupsEdit} />

      {/* Users */}

      <Route exact path={`/users`} component={UserList} />
      <Route exact path={`/users/create`} component={UserListEdit} />

      <Route exact path={`/roles`} component={Roles} />
      <Route exact path={`/roles/create`} component={RolesEdit} />

      <Route exact path={`/risk-groups`} component={RiskGroups} />
      <Route exact path={`/risk-groups/create`} component={RiskGroupsEdit} />

      <Route exact path={`/risk-seperating`} component={RiskSeperating} />
      <Route
        exact
        path={`/risk-seperating/create`}
        component={RiskSeperatingEdit}
      />

      <Route exact path={`/risk-list/user`} component={Risk} />
      <Route exact path={`/risk-list/user/edit`} component={RiskEdit} />
      <Route exact path={`/risk-list/pool`} component={Risk} />
      <Route exact path={`/risk-list/pool/edit`} component={RiskEdit} />

      <Route exact path={`/positions`} component={Positions} />
      <Route exact path={`/positions/create`} component={PositionsEdit} />

      <Route exact path={`/portfolios`} component={Portfolios} />
      <Route exact path={`/portfolios/create`} component={PortfoliosEdit} />

      <Route exact path={`/logs`} component={Logs} />

      <Route exact path={`/enums`} component={Enums} />
      <Route exact path={`/enums/create`} component={EnumsEdit} />

      <Route exact path={`/risk-statuses`} component={RiskStatuses} />
      <Route
        exact
        path={`/risk-statuses/create`}
        component={RiskStatusesEdit}
      />

      <Route exact path={`/risk-appointment`} component={RiskAppointment} />
      <Route
        exact
        path={`/risk-appointment/create`}
        component={RiskAppointmentEdit}
      />

      <Route exact path={`/sectors`} component={Sectors} />
      <Route exact path={`/sectors/create`} component={SectorsEdit} />

      <Route exact path={`/departments`} component={Departments} />
      <Route exact path={`/departments/create`} component={DepartmentsEdit} />

      <Route exact path={`/companies`} component={Companies} />
      <Route exact path={`/companies/create`} component={CompaniesEdit} />

      <Route exact path={`/add-risk`} component={AddRisk} />
      <Route exact path={`/add-stages`} component={AddStages} />
      <Route exact path={`/add-parameters`} component={AddParameters} />
      <Route exact path={`/add-workflow`} component={AddWorkFlow} />
      <Route exact path={`/scenario-config`} component={ScenarioConfig} />

      <Route exact path={`/languages`} component={Languages} />
      <Route exact path={`/languages/create`} component={LanguagesEdit} />

      <Route exact path={`/enum-categories`} component={EnumCategories} />
      <Route
        exact
        path={`/enum-categories/create`}
        component={EnumCategoriesEdit}
      />

      <Route exact path={`/my-list`} component={MyList} />
      <Route exact path={`/my-list/create`} component={MyListEdit} />
      <Route exact path={`/my-list/:sublistId`} component={SubList} />
      <Route
        exact
        path={`/sublist/create/:sublistId`}
        component={SublistEdit}
      />

      <Route
        exact
        path={`/sublist/create/:sublistId/:rowId`}
        component={SublistEdit}
      />

      <Route exact path="/profile" component={Profile} />
      <Route exact path="/calendar" component={Calendar} />
      <Route exact path="/calendar/create" component={CalendarCreate} />
      {/* Main Routes */}

      <Route exact path="/main" component={Home} />
      <Route exact path="/main/collaborators" component={CollabHome} />
      <Route
        exact
        path="/main/operations"
        component={() => <h1>Əməliyyatlarım</h1>}
      />
      <Route
        exact
        path="/main/rtms-messenger"
        component={() => (
          <AppProvider>
            <RTMSMessenger />
          </AppProvider>
        )}
      />
      <Route
        exact
        path="/main/messages"
        component={() => (
          <MailProvider>
            <Mail />
          </MailProvider>
        )}
      />
      <Route
        exact
        path="/main/messages/mail-desc"
        component={() => (
          <MailProvider>
            <MailDescWithReply />
          </MailProvider>
        )}
      />
      <Route exact path="/main/library/main" component={LibraryMain} />

      <Route exact path="/main/library/catalog" component={LibraryCatalog} />
      <Route
        exact
        path="/main/library/catalog/:catalogFolderId"
        component={FolderDetailsCatalog}
      />

      <Route exact path="/main/library/my-files" component={LibraryMyFiles} />
      <Route
        exact
        path="/main/library/my-files/:myFilesFolderId"
        component={FolderDetailsMyFiles}
      />
      <Route
        exact
        path="/main/resources/product-chain"
        component={() => <h1>Mal zənciri</h1>}
      />
      <Route
        exact
        path="/main/resources/corporate-tree"
        component={() => <h1>Korporativ ağac</h1>}
      />
      <Route
        exact
        path="/main/resources/profile"
        component={() => <h1>Profil məlumatları</h1>}
      />
      <Route exact path="/main/rvo" component={() => <h1>RVÖ Modulu</h1>} />
      <Route exact path="/main/edvdh" component={() => <h1>ƏDVDH</h1>} />
      <Route exact path="/main/reports" component={() => <h1>Hesabatlar</h1>} />

      <Redirect to="/" />
      <Route path="/">
        <p className="flex all-center h-100vh">Not found</p>
      </Route>
    </Switch>
  );
}

export default Routes;
