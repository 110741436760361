import { useState, useEffect } from "react";
import LibraryMainModel from "../models/LibraryMainModel";

export function useLastAdded() {
  const [fileCardsList, setFileCardsList] = useState([]);
  const [filesLoading, setFilesLoading] = useState(false);

  const getLastAdded = () => {
    setFilesLoading(true);
    LibraryMainModel.getLastAdded(
      (data) => {
        setFilesLoading(false);
        setFileCardsList(data);
      },
      (err) => {
        setFilesLoading(false);
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getLastAdded();
  }, []);

  return { fileCardsList, getLastAdded, filesLoading };
}
