import React from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { SearchOutlined } from "@ant-design/icons";

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SearchOutlined />
      </components.DropdownIndicator>
    )
  );
};

const AsyncSelectComponent = ({
  loadOptions,
  placeholder,
  onChange,
  label,
}) => {
  return (
    <AsyncSelect
      loadOptions={loadOptions}
      defaultOptions
      placeholder={placeholder}
      label={label}
      components={{ DropdownIndicator }}
      isClearable
      onChange={onChange}
      styles={{
        control: (baseStyle, { isFocused }) => ({
          ...baseStyle,
        }),
        input: (baseStyle) => ({ ...baseStyle, margin: 0, padding: 0 }),
        valueContainer: (baseStyle) => ({
          ...baseStyle,
          margin: 0,
          padding: "0 8px",
        }),
      }}
    />
  );
};

export default AsyncSelectComponent;
