import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { URLQueryMethods } from "utils/URLQueryMethods";
import API from "const/API2";
import GL from "libs/GL";

const api = new API();

function useGetMyFilesFolder(props) {
  const folderId = props?.folderId;
  const [myFilesTableData, setMyFilesTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const searchParamsHandler = new URLSearchParams(history.location.search);

  // * Type Filter Select Props --------------------------------------
  const filterType = searchParamsHandler.get("filterType") ?? null;
  const handleFilterTypeSelect = (val) => {
    URLQueryMethods({
      history,
      searchParamsHandler,
      set: [
        { setQueryType: "filterType", setQueryValue: val },
        {
          setQueryType: "page",
          setQueryValue: "0",
        },
      ],
    });
  };
  const handleClearFilterType = () => {
    URLQueryMethods({
      history,
      searchParamsHandler,
      deleteParam: [
        { deleteQueryType: "filterType" },
        { deleteQueryType: "folderColor" },
      ],
    });
  };
  const filterTypeProps = {
    onChange: handleFilterTypeSelect,
    onClear: handleClearFilterType,
    filterType: filterType,
  };
  // * End Type Filter Select Props ---------------------------------

  // * Pagination Page Props ----------------------------------------
  const [dataTotalLength, setDataTotalLength] = useState(0);
  const size = 8;
  const page = searchParamsHandler.get("page") ?? "0";
  const handleClickPagination = (pageNum) => {
    URLQueryMethods({
      history,
      searchParamsHandler,
      set: { setQueryType: "page", setQueryValue: `${pageNum}` },
    });
  };
  const paginationProps = {
    page,
    size,
    dataTotalLength,
    handleClickPagination,
  };
  // * End Pagination Page Props ------------------------------------

  // * IsModified Select Props --------------------------------------
  const isModified = searchParamsHandler.get("isModified") ?? null;
  const handleIsModifiedSelect = (val) => {
    URLQueryMethods({
      history,
      searchParamsHandler,
      set: [
        { setQueryType: "isModified", setQueryValue: val },
        {
          setQueryType: "page",
          setQueryValue: "0",
        },
      ],
    });
  };
  const handleClearIsModified = () => {
    URLQueryMethods({
      history,
      searchParamsHandler,
      deleteParam: { deleteQueryType: "isModified" },
    });
  };
  const isModifiedProps = {
    onChange: handleIsModifiedSelect,
    onClear: handleClearIsModified,
    isModified: isModified,
  };
  // * End IsModified Select Props ---------------------------------

  // * IsModified Select Props --------------------------------------
  const folderColor = searchParamsHandler.get("folderColor") ?? null;
  const handleFolderColorSelect = (val) => {
    URLQueryMethods({
      history,
      searchParamsHandler,
      set: [
        { setQueryType: "folderColor", setQueryValue: val },
        {
          setQueryType: "page",
          setQueryValue: "0",
        },
      ],
    });
  };
  const handleClearFolderColor = () => {
    URLQueryMethods({
      history,
      searchParamsHandler,
      deleteParam: { deleteQueryType: "folderColor" },
    });
  };
  const folderColorProps = {
    onChange: handleFolderColorSelect,
    onClear: handleClearFolderColor,
    folderColor: folderColor,
  };
  // * End IsModified Select Props ---------------------------------

  // * Table View Type Props ----------------------------------------
  const tableViewType = searchParamsHandler.get("tableViewType") ?? "table";
  const toggleTableViewType = () => {
    URLQueryMethods({
      history,
      searchParamsHandler,
      set: {
        setQueryType: "tableViewType",
        setQueryValue: tableViewType === "table" ? "cards" : "table",
      },
    });
  };
  const tableViewProps = {
    toggleTableViewType,
    tableViewType,
  };
  // * End Table View Type Props ------------------------------------

  // * Table View Type Props ----------------------------------------
  const isFavourite = searchParamsHandler.get("isFavourite") ?? "false";
  const toggleIsFavourite = () => {
    if (isFavourite === "true") {
      URLQueryMethods({
        history,
        searchParamsHandler,
        deleteParam: { deleteQueryType: "isFavourite" },
      });
    } else {
      URLQueryMethods({
        history,
        searchParamsHandler,
        set: [
          {
            setQueryType: "isFavourite",
            setQueryValue: "true",
          },
          {
            setQueryType: "page",
            setQueryValue: "0",
          },
        ],
      });
    }
  };
  const isFavoriteProps = {
    toggleIsFavourite,
    isFavourite,
  };
  // * End Table View Type Props ------------------------------------

  // * Search Value Props ------------------------------------------
  const searchValue = searchParamsHandler.get("searchValue");
  const [inputSearchValue, setInputSearchValue] = useState("");
  const searchProps = {
    value: inputSearchValue,
    onInput: (e) => setInputSearchValue(e.target.value),
    onSearch: () => setInputSearchValue(""),
    allowClear: true,
  };
  // * End Search Value Props --------------------------------------

  const getMyFilesFolder = (currPage = "0") => {
    setIsLoading(true);
    searchParamsHandler.set("page", currPage);
    const paramHandlerForApi = new URLSearchParams(
      searchParamsHandler.toString()
    );
    paramHandlerForApi.delete("tableViewType");

    paramHandlerForApi.set("size", size);
    const queryStringForApi = paramHandlerForApi.toString();

    api.getData(
      `library/${folderId}/filter-my-files?${queryStringForApi}`,
      (data, paginationInfo) => {
        setDataTotalLength(paginationInfo.totalElements);

        setIsLoading(false);
        setMyFilesTableData(data);
      },
      (err) => {
        setIsLoading(false);
        GL.notify.error({ text: err.message ?? "Xeta bas verdi !" });
      }
    );
  };

  useEffect(() => {
    // * debouncing search value
    const timeoutId = setTimeout(() => {
      if (inputSearchValue.trim()) {
        URLQueryMethods({
          history,
          searchParamsHandler,
          set: {
            setQueryType: "searchValue",
            setQueryValue: `${inputSearchValue}`,
          },
        });
      } else if (searchValue) {
        URLQueryMethods({
          history,
          searchParamsHandler,
          deleteParam: { deleteQueryType: "searchValue" },
        });
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputSearchValue]);

  useEffect(() => {
    getMyFilesFolder();
  }, [filterType, isModified, searchValue, folderColor, folderId, isFavourite]);

  useEffect(() => {
    getMyFilesFolder(page);
  }, [page]);

  return {
    myFilesTableData,
    filterTypeProps,
    tableViewType,
    isModifiedProps,
    tableViewProps,
    searchProps,
    paginationProps,
    isLoading,
    folderColorProps,
    isFavoriteProps,
    getMyFilesFolder,
  };
}

export default useGetMyFilesFolder;
