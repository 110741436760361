import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Button, Drawer } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import TopMenu from "./TopMenu/TopMenu";
import MenuList from "../../Elements/MenuList";

import logo from "../../../assets/img/vergiler_logo.svg";
import { SocketProvider } from "./SocketProvider/SocketProviderContextAPI";

function MainLayout(props) {
  const { Sider } = Layout;

  const [collapsed, setCollapsed] = useState(window.innerWidth < 1200);
  const [web, setWeb] = useState(true);

  const toggleButtons = () => {
    const className = `flex sider-btn ${
      collapsed ? "all-center" : "flex-between open"
    }`;
    return (
      <div className={className}>
        {!collapsed ? (
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        ) : null}
        <Button type="primary" onClick={onCollapse}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
    );
  };

  const handleResize = () => {
    setWeb(window.innerWidth > 1200);
    setCollapsed(window.innerWidth < 1200);
  };

  const onCollapse = () => {
    setWeb(window.innerWidth > 1200);
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    setWeb(window.innerWidth > 1200);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div id="page">
      <SocketProvider>
        {" "}
        <Layout className="letside">
          {web ? (
            <Sider
              className="side-menu"
              style={{ backgroundColor: "white" }}
              collapsed={collapsed}
              collapsedWidth={80}
              onCollapse={onCollapse}
              width={300}
            >
              {toggleButtons()}
              <MenuList collapsed={collapsed} />
            </Sider>
          ) : (
            <Drawer
              className="drawer"
              width={320}
              title={toggleButtons()}
              placement="left"
              closable={false}
              onClose={onCollapse}
              visible={!collapsed}
              key="key"
            >
              <MenuList collapsed={collapsed} />
            </Drawer>
          )}
          <Layout
            style={{
              backgroundColor: "#fff",
            }}
            className={collapsed ? "collapsedRight" : "nonCollapsedRight"}
          >
            <TopMenu
              toggleDrawer={onCollapse}
              showDrawerButton={!web}
              collapsed={collapsed}
            />
            {props.children}
          </Layout>
        </Layout>
      </SocketProvider>
    </div>
  );
}

export default MainLayout;
