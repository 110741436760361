import "./InfoCard.scss";

import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import React from "react";

function InfoCard(props) {
  const { title, count, todayCount, arrow, onClick } = props;
  const isTodayCountValid = !(todayCount == undefined || todayCount == null);
  return (
    <div className="collaborators-info-card" onClick={onClick}>
      <div className="top">
        <span className="name">{title}</span>
        {arrow === "up" && isTodayCountValid && (
          <span className="number-icon-wrapper up">
            <span className="number">{todayCount}</span>
            <span>
              <ArrowUpOutlined />
            </span>
          </span>
        )}
        {arrow === "down" && isTodayCountValid && (
          <span className="number-icon-wrapper down">
            <span className="number">{todayCount}</span>
            <span>
              <ArrowDownOutlined />
            </span>
          </span>
        )}
      </div>
      <div className="bottom">
        <span className="total">{count}</span>
      </div>
    </div>
  );
}

export default InfoCard;
