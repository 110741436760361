import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  GetPagePermission,
  GetTableSettings,
} from "../../../hooks/permissions";
import GL from "../../../libs/GL";
import SuperTable from "../../Elements/Tabulator/SuperTable";
import { useHistory } from "react-router-dom";
import { Skeleton } from "antd";
import RiskStatus from "../../../models/RiskStatus";
import EmptyPage from "../../Elements/Skelethon/EmptyPage";
import { useTranslation } from "react-i18next";
const pageId = "risk-statuses";

function RiskStatuses(props) {
  const { t } = useTranslation();
  // var api = new API()
  const history = useHistory();
  // let mainUrl = apiRoutes.riskStatuses
  // const { notify } = props;
  const [riskStatusList, setRiskStatusList] = useState([]);
  const [loading, setLoading] = useState(false);
  const permissions = GetPagePermission(4);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );
  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {
        history.push({
          pathname: "risk-statuses/create",
          state: {
            info: {
              id: data.id,
              name: data.name,
              message: data.message,
              isActive: data.isActive,
            },
            method: "edit",
          },
        });
      },
    },
    delete: {
      status: permissions["delete"],
      click: function (data) {
        GL.yesNo(t("question_deleting"), (status1) => {
          if (status1) {
            RiskStatus.DELETE(data.id, (status2, res) => {
              if (status2) {
                GL.notify.success({
                  title: t("answer_success"),
                  text: t("risk_status_deleted"),
                });
                getRiskStatusesList();
              } else {
                GL.notify.error({
                  title: t("answer_error"),
                  text: t("risk_status_not_deleted"),
                });
              }
            });
          }
        });
      },
    },
    show: {
      status: permissions["show"],
      click: function (data) {},
    },
    copy: {
      status: false,
      click: function (data) {
        history.push({
          pathname: "risk-statuses/create",
          state: {
            id: 0,
            name: data.name,
            message: data.message,
            isActive: data.isActive,
            method: "create",
          },
        });
      },
    },
  };
  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/risk-statuses/add",
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/risk-statuses/multiDelete",
      },
    },
    settings: {
      id: pageId,
      type: "data",
      data: riskStatusList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "+ " + t("global_create", { name: t("risk_status") }),
      createNewRoute: "risk-statuses/create",
      state: {
        id: " ",
        name: "",
        message: "",
        isActive: true,
        method: "create",
      },
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 70,

        formatter: function (cell) {
          return GL.setSelectStatus(cell);
        },
      },
      {
        title: t("global_name"),
        field: "name",
      },
      {
        title: t("risk_status_message"),
        field: "message",
      },
      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        name: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        message: {
          dataType: "text",
          placeholder: t("global_search"),
        },
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {},
    },
  };

  const getRiskStatusesList = () => {
    setLoading(true);
    RiskStatus.getRiskStatusList(
      (data) => {
        //data.reverse()
        setRiskStatusList(RiskStatus.getForTableData(data));
        setLoading(false);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    getRiskStatusesList();
  }, []);

  if (loading) {
    return <Skeleton active />;
  } else {
    if (riskStatusList.length == 0) {
      return (
        <EmptyPage
          route="risk-statuses/create"
          name={t("risk_statuses")}
          state={options.settings.state}
        />
      );
    } else {
      return (
        <div style={containerStyle}>
          <div className="main-layout-wrapper__head">
            <div className="simple-head">
              <h1>{t("risk_statuses")}</h1>
              <span>{t("risk_statuses_desc")}</span>
            </div>
          </div>
          <SuperTable data={options} />
        </div>
      );
    }
  }
}

export default RiskStatuses;
