import API from "../const/API2";
import { apiRoutes } from "../const/apiroutes";
import GL from "../../src/libs/GL";
var api = new API();

class Logs {
  constructor(obj) {
    this.id = obj["id"];
    this.endpoint = obj["endpoint"];
    this.type = obj["type"];
    this.method =
      obj["type"] == 1 ? "INSERT" : obj["type"] == 2 ? "UPDATE" : "DELETE";
    this.user_name = obj["user_name"];
    this.dateCreated = GL.datetimeToString(obj["dateCreated"]);
    this.details = obj["details"];
  }

  getTableItem() {
    return {
      id: this.id,
      endpoint: this.endpoint,
      type: this.type,
      user_name: this.user_name,
      details: this.details,
      method: this.method,
      dateCreated: this.dateCreated,
    };
  }

  static getForTableData(data) {
    var arr = [];
    data.map((a) => {
      arr.push(a.getTableItem());
    });
    return arr;
  }

  static getLogsFromArray(data) {
    var arr = [];
    data.map((a) => {
      var c = new Logs(a);
      arr.push(c);
    });
    return arr;
  }

  static getLogsList(callback, errCallback) {
    api.getData(
      apiRoutes.logs.list,
      (data) => {
        var arr = Logs.getLogsFromArray(data);
        callback(arr);
      },
      errCallback
    );
  }

  static getLogByID(logId, callback, errCallback) {
    if (!logId) return;
    api.getData(
      apiRoutes.logs.details(logId),
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errCallback) errCallback(error);
      }
    );
  }
}
export default Logs;
