import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import reducers from "./reducers/index";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  user: reducers.userReducer,
  token: reducers.tokenReducer,
  loader: reducers.loaderReducer,
  notification: reducers.notificationReducer,
  risk: reducers.riskReducer,
  company: reducers.companyReducer,
  department: reducers.departmentReducer,
  sector: reducers.sectorReducer,
  riskGroup: reducers.riskGroupReducer,
  riskStatus: reducers.riskStatusReducer,
  position: reducers.positionReducer,
  enum: reducers.enumReducer,
  tableSettings: reducers.tableSettingsReducer,
  roles: reducers.roleReducer,
  riskSeperating: reducers.riskSeperateReducer,
  users: reducers.usersReducer,
  userGroup: reducers.userGroupReducer,
  myList: reducers.myListReducer,
  sublist: reducers.sublistReducer,
  languages: reducers.LanguagesReducer,
  python: reducers.pythonReducer,
  python: reducers.pythonReducer,
  home: reducers.homeReducer,
});

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_SECRET_KEY,
});

const persistConfig = {
  key: " Root Reducer",
  storage,
  transforms: [encryptor],
  // whitelist: ["user"],
  // blacklist: ["user"],
};

const reducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
);
export const persistor = persistStore(store);
export default store;
