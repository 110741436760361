import React, { useState } from "react";
import Warning from "components/Elements/Icons/Warning";
import GL from "libs/GL";

const useTableColumns = (tools) => {
  const { onOpenREqfModal, onSetItemId, onSetInvoiceId, onOpenPoolModal } =
    tools;
  const operators = {
    show: {
      status: true,
      onClick: (data) => {
        onOpenREqfModal();
        onSetItemId(data.itemId);
        onSetInvoiceId(data.id);
      },
    },
  };

  const operatorsRule = {
    show: {
      status: true,
      onClick: (data) => {
        onOpenPoolModal();
        onSetItemId(data.itemId);
      },
    },
  };

  const [columns] = useState([
    {
      title: <h1 className="text-left p-10">Riskli EQF-lər barədə</h1>,
      children: [
        {
          title: "Malın işin xidmətin adı",
          dataIndex: "serviceName",
          key: "serviceName",
        },
        {
          title: "Üst kateqoriya",
          dataIndex: "topCategory",
          key: "topCategory",
        },
        {
          title: "Alt kateqoriya",
          dataIndex: "subCategory",
          key: "subCategory",
        },
        {
          title: "Ölçü vahidi",
          dataIndex: "unit",
          key: "unit",
        },
        {
          title: "Miqdarı",
          dataIndex: "amount",
          key: "amount",
        },
        {
          title: "Vahidinin dəyəri",
          dataIndex: "unitCost",
          key: "unitCost",
        },
        {
          title: "ƏDV",
          dataIndex: "fakeVat",
          key: "fakeVat",
        },
        {
          title: "Cəmi dəyəri",
          dataIndex: "totalCost",
          key: "totalCost",
        },
        {
          title: "Baxış",
          dataIndex: "",
          key: "x",
          width: 80,
          render: (_, item) => GL.setAntTableButtons(item, operators),
        },
      ],
    },
    {
      title: " ",
      width: 30,
    },
    {
      title: <h1 className="text-left p-10">Verilmiş qərarlar barədə</h1>,
      children: [
        {
          title: "Məlumat",
          dataIndex: "info",
          key: "info",
        },
        {
          title: "Əmtəəsiz əməliyyat",
          key: "amountSecond",
          children: [
            {
              title: "Əsas məbləğ",
              dataIndex: "realPrice",
              key: "realPrice",
            },
            {
              title: "ƏDV",
              dataIndex: "vat",
              key: "vat",
            },
          ],
        },
        {
          title: "Qoşma",
          dataIndex: "file",
          key: "file",
          render: (_, item) => {
            const buttons = GL.setAntTableButtons(item, operatorsRule);
            const differentDecision = item.differentDecision;
            const childrenLenght = buttons.props.children.length + 1;
            if (differentDecision)
              buttons.props.children.push(
                <div className="tableIcons" key={childrenLenght}>
                  <Warning />
                </div>
              );
            return buttons;
          },
        },
      ],
    },
  ]);
  return { columns };
};

export default useTableColumns;
