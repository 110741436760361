import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col, Skeleton } from "antd";
import PageTitle from "../../Elements/PageTitle/PageTitle";
import FormSubmit from "../../Elements/FormSubmit/FormSubmit2";
import InputBox from "../../Elements/FileUploadForm/Form/InputBox";
import SelectBox from "../../Elements/FileUploadForm/Form/SelectBox";
import DateTimePicker from "components/Elements/FileUploadForm/Form/DatePicker";
import GL from "../../../libs/GL";
import { useTranslation } from "react-i18next";

import Calendar from "models/Calendar";

const TYPE_LIST = [
  {
    id: "Bayram/hüzn günü",
    name: "Bayram/hüzn günü"
  },
  {
    id: "Həftəsonu",
    name: "Həftəsonu"
  }
];

const STATUS_LIST = [
  {
    id: true,
    name: "Aktiv"
  },
  {
    id: false,
    name: "Deaktiv"
  }
];

const CalendarCreate = props => {
  const { t } = useTranslation();
  const history = useHistory();
  var state = props.location.state;
  const method = state.method;
  const calendarId = state.id;
  const isCreate = method === "create";
  const [isLoading, setIsLoading] = useState(false);

  let initialValues = {
    info: {
      id: calendarId,
      name: "",
      type: "",
      vacationDate: "",
      status: true,
      isActive: true
    }
  };

  const validation = Yup.object().shape({
    info: Yup.object().shape({
      name: Yup.string().required(t("yup_name")),
      type: Yup.string().required(t("calendar_yup_type")),
      vacationDate: Yup.string().required(t("calendar_yup_date"))
    })
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      const body = { ...values.info };

      if (isCreate) {
        Calendar.Create(body, () => {
          GL.notify.success({ text: t("success_message") });
          resetForm();
          history.push("/calendar");
        });
      } else {
        Calendar.Update(body, () => {
          GL.notify.success({ text: t("success_message") });
          resetForm();
          history.push("/calendar");
        });
      }
    }
  });

  function onSubmit() {
    formik.handleSubmit();
  }

  function onReset() {
    formik.resetForm();
    history.push("/calendar");
  }

  const getCalendarByID = id => {
    if (id && id !== 0) {
      setIsLoading(true);
      Calendar.getCalendarItemByID(id, response => {
        const updateValues = {
          info: { ...response }
        };

        formik.setValues(updateValues);
        setIsLoading(false);
      });
    }
  };

  useEffect(
    () => {
      getCalendarByID(calendarId);
    },
    [calendarId]
  );

  if (isLoading) return <Skeleton active />;
  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={state.method === "edit" ? "Təqvim redakte et" : "Təqvim yarat"}
      />
      <div className="risk-page">
        <div className="risk-page__elements">
          <h2>
            {t("global_info")}
          </h2>
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label="ID"
                formik={formik}
                field="id"
                type="number"
                required={false}
                visibilty={state.method === "edit" ? true : false}
                disabled={state.method === "edit" ? true : false}
                isFake={true}
              />
              <DateTimePicker
                label={t("calendar_date")}
                formik={formik}
                field="vacationDate"
                required={true}
              />
              <InputBox
                label={t("global_name")}
                formik={formik}
                field="name"
                required={true}
              />
              <SelectBox
                label={t("calendar_type")}
                formik={formik}
                field="type"
                data={TYPE_LIST}
                required={true}
              />
              <SelectBox
                label={t("calendar_status")}
                formik={formik}
                field="status"
                data={STATUS_LIST}
              />
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit cancel={onReset} submit={onSubmit} />
        </div>
      </div>
    </div>
  );
};

export default CalendarCreate;
