import React, { useState, useEffect } from "react";
import Tabulators from "./tabulator-extension";
import "./Tabulator.css";
import "./DynamicTableCss.css";
import { useTranslation } from "react-i18next";
import GL from "libs/GL";

var table = null;

function DynamicTable(props) {
  //debugger;
  const { t } = useTranslation();
  var settings = props.settings;
  var columns = props.columns;
  var filtering = props.filtering;
  //var defaultHide = filtering.secretColumns;
  /*columns = columns.map((a)=>{
    if(defaultHide.indexOf(a.field)!==-1){
      a.visible=false;
    }
    return a;
  });
  if(settings['columnSort']!==undefined){
    var newColumns = [];
    var newSort = JSON.parse(JSON.stringify(settings['columnSort']));
    newSort.unshift('id');
    newSort.push('operations');
    newSort.map((a)=>{
      var col = columns.find((b)=>b.field==a);
      if(col){
        newColumns.push(col);
      }
    });
    columns = newColumns;
  }*/

  const exportTable = (fileName) => {
    var data = table.getData(true);
    GL.jsonToExcel(data, fileName);
  };

  function exportTableToPdf(fileName) {
    debugger;
    var data = table.getData(true);
    GL.jsonToPDFTable(data, fileName);
  }

  const [data, setData] = useState(false);

  useEffect(() => {
    if (props.buttonPressed !== undefined) {
      if (props.buttonPressed["type"] !== "") {
        switch (props.buttonPressed["type"]) {
          case "export": {
            exportTable(props.buttonPressed.data.fileName);
            break;
          }
          case "print": {
            exportTableToPdf(props.buttonPressed.data.fileName);
            break;
          }
        }
      }
    }
  }, [props.buttonPressed]);

  useEffect(() => {
    if (data == false) {
      setData(false);
      if (settings.type == "url") {
        table = new Tabulators("#tablom-" + settings.id, {
          pagination: "local",
          paginationSize: 10,
          paginationSizeSelector: [10, 25, 50, 75, 100],
          movableColumns: true,
          paginationButton: "left",
          paginationCounter: function (
            pageSize,
            currentRow,
            currentPage,
            totalRows,
            totalPages
          ) {
            return `${t("global_page")} : ${currentPage}/${totalPages}, ${t(
              "global_row"
            )} : ${totalRows}`;
            //return `${totalPages} Səhifədən axtar`;
          },
          ajaxURL: settings.data,
          layout: "fitColumns",
          columns: columns,
        });
      } else {
        table = new Tabulators("#tablom-" + settings.id, {
          pagination: "local",
          paginationSize: 10,
          paginationSizeSelector: [10, 25, 50, 75, 100],
          movableColumns: true,
          paginationButton: "left",
          paginationCounter: function (
            pageSize,
            currentRow,
            currentPage,
            totalRows,
            totalPages
          ) {
            return `${t("global_page")} : ${currentPage}/${totalPages}, ${t(
              "global_row"
            )} : ${totalRows}`;
            //return `${totalPages} Səhifədən axtar`;
          },
          data: settings.data,
          layout: "fitColumns",
          columns: columns,
        });
      }

      table.on("tableBuilt", function (data) {
        // debugger;
        setTimeout(function () {
          table.doBest(filtering);
          var divs = document.getElementsByClassName("tabulator-col");
          for (var i = 0; i < divs.length; i++) {
            var div = divs[i];
            div.style.height = "53px";
          }
          if (settings["setTable"] !== undefined) {
            settings.setTable(table);
          }
          var pag = document.getElementsByClassName("tabulator-paginator");
          if (pag.length > 0) {
            if (pag[0].children.length > 5) {
              pag[0].children[0].innerHTML = t("global_count");
              pag[0].children[2].innerHTML = t("global_first");
              pag[0].children[3].innerHTML = t("global_prev");
              pag[0].children[5].innerHTML = t("global_next");
              pag[0].children[6].innerHTML = t("global_last");
            }
          }
        }, 500);
      });
    }
  }, [columns, data, filtering, settings]);

  return (
    <div
      id={"tablom-" + settings.id}
      style={{ backgroundColor: "white", border: "1px solid #efefef" }}
    ></div>
  );
}

export default DynamicTable;
