import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col, Input } from "antd";
import PageTitle from "../../Elements/PageTitle/PageTitle";
import FormSubmit from "../../Elements/FormSubmit/FormSubmit";

import { useHistory } from "react-router-dom";
import GL from "../../../libs/GL";
import InputBox from "../../Elements/FileUploadForm/Form/InputBox";
import RiskStatus from "../../../models/RiskStatus";
import { useDispatch, useSelector } from "react-redux";
import { setRiskStatusData } from "../../../redux/actions";
import { useTranslation } from "react-i18next";
function RiskStatusesEdit(props) {
  const { t } = useTranslation();

  const history = useHistory();

  var state = props.location.state;

  const dispatch = useDispatch();
  const reduxRiskStatus = useSelector(state => state.riskStatus);
  if (state == undefined) {
    state = reduxRiskStatus;
  }
  let initialValues;

  const { method, ...stateRest } = state;
  if (state.method === "create") {
    if (reduxRiskStatus !== undefined) {
      if (reduxRiskStatus.method == undefined) {
        reduxRiskStatus.method = "create";
      }
      initialValues = reduxRiskStatus;
    } else {
      initialValues = stateRest;
    }
  }
  if (state.method === "edit") {
    initialValues = stateRest;
  }
  if (state.method === "copy") {
    initialValues = stateRest;
    state.method = "create";
  }

  const { TextArea } = Input;

  // const populateList = () => {
  //   RiskStatus.getRiskStatusList((data) => {
  //     setParentList(data);
  //   });
  // };
  useEffect(() => {
    formik.setFieldTouched("setFormikTouched");
    // populateList();
  }, []);

  var submitSettings = {
    type: state.method,
    save: async () => {
      if (state.id == 0) {
        var sendData = await GL.getFormikData(formik, ["name", "message"]);

        RiskStatus.CREATE(sendData, res => {
          formik.setValues(stateRest);
          GL.notify.success({ text: t("answer_success") });
          history.push("/risk-statuses");
        });
      }
    },
    edit: async () => {
      //debugger
      if (state.id !== 0) {
        var sendData = await GL.getFormikData(formik, [
          "id",
          "name",
          "message"
        ]);
        RiskStatus.UPDATE(sendData, res => {
          formik.setValues(stateRest);
          GL.notify.success({ text: t("answer_success") });
          history.push("/risk-statuses");
        });
      }
    },
    cancel: () => {
      history.push("/risk-statuses");
    }
  };

  const postLoginSchema = Yup.object().shape({
    info: Yup.object().shape({
      // id: Yup.string().required("Zəhmət olmasa ID daxil edin"),
      name: Yup.string().required(t("yup_adi")),
      message: Yup.string().required(t("yup_message"))
    })
  });

  const formik = useFormik({
    initialValues,
    validationSchema: postLoginSchema,
    onSubmit: (values, { resetForm }) => {
      console.log("submit formik values", values);
      // onPostLogin(values, resetForm);
    }
  });

  useEffect(
    () => {
      dispatch(setRiskStatusData(formik.values));
    },
    [formik.values]
  );

  useEffect(
    () => {
      if (method === "create" && Object.keys(formik.touched).length) {
        history.replace({ state: { ...state, method: "create" } });
      }
    },
    [formik.touched]
  );

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={
          state.method == "edit" ? t("risk_status_edit") : t("risk_status_add")
        }
        small={t("risk_statuses_desc")}
      />
      <div className="risk-page">
        <div className="risk-page__elements">
          <h2>
            {t("global_info")}
          </h2>
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label="ID"
                formik={formik}
                field="id"
                type="number"
                required={false}
                visibilty={state.method == "edit" ? true : false}
                disabled={state.method == "edit" ? true : false}
              />
              <InputBox
                label="Adı"
                formik={formik}
                field="name"
                required={true}
              />
              {/* <InputBox label="Servis mesaji" formik={formik} field="message" required={true} /> */}
              <span className="input-box__title">
                {t("risk_status_message")}
              </span>
              <TextArea
                autoSize
                rows={6}
                {...formik.getFieldProps("info.message")}
              />
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit myEvents={submitSettings} />
        </div>
      </div>
    </div>
  );
}

export default RiskStatusesEdit;
