import "./LibraryTableCards.scss";

import React, { Fragment, useState } from "react";
import { List, Popover, Divider, Skeleton, Empty } from "antd";
import { MoreOutlined, StarFilled } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import ActionsPopover from "../ActionsPopover/ActionsPopover";
import RenderFileOrFolderIcon from "../LibraryTable/RenderFileOrFolderIcon";
import { LibraryModel } from "models/Library";

import { fileTypeDownloadEnum } from "const/fileTypeDownloadEnum";
import Watermark from "@uiw/react-watermark";
import { useSelector } from "react-redux";
import { Modal } from "antd";
function LibraryTableCards({
  data,
  getData,
  paginationProps = null,
  isLoading = false,
}) {
  const history = useHistory();
  const [showActionsPopover, setShowActionsPopover] = useState({});

  const size = paginationProps?.size;
  const handleClickPagination = paginationProps?.handleClickPagination;
  const dataTotalLength = paginationProps?.dataTotalLength;
  const page = paginationProps?.page;

  const postFileToLastWatched = (fileId) => {
    LibraryModel.postFileToLastWatched(
      fileId,
      (_) => {},
      (err) => {
        console.log("err ==>", err);
      }
    );
  };

  if (isLoading) {
    return (
      <>
        <Skeleton active />
        <Skeleton active />
      </>
    );
  }
  if (!data.length) {
    return <Empty description="Məlumat tapılmadı" />;
  }

  // ~~ WATERMARK IMPLEMENTATION
  const [showModal, setShowModal] = useState(false);
  const currentUserId = useSelector((state) => state.user.data.user.id);
  const currentUserName = useSelector((state) => state.user.data.user.name);
  const [frame, setFrame] = useState("");

  const closeModal = () => setShowModal(false);

  // ! getPdf() Here we change original url to blob url. Because if we use original url, the server responds with 403 (Forbidden error)
  const getPdf = (url) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const documentUrl = url;

    fetch(documentUrl, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        setFrame(
          <iframe
            src={`${blobUrl}#toolbar=0`}
            width="100%"
            height={screen.height - 300}
          />
        );
      })
      .catch((error) => console.log("Error fetching document:", error));
  };

  // ! getImg() and getDoc() methods must be seperate in table component. Otherwise it won't rerender.
  const getImg = (url) => {
    setFrame(
      <img src={url} className="modal-image" width="100%" height="auto" />
    );
  };

  const getDoc = (url) => {
    // ~ Embed google component for displaying xls and doc files
    setFrame(
      <iframe
        width="100%"
        height={screen.height - 300}
        src={`https://docs.google.com/viewer?url=${encodeURIComponent(
          url
        )}&embedded=true`}
      >
        {" "}
      </iframe>
    );
  };

  // Date properties
  const newDate = new Date();
  const date = `${newDate.getDate()}.${newDate.getMonth()}.${newDate.getFullYear()}`;
  const time = `${newDate.getHours()}:${newDate.getMinutes()}:${newDate.getSeconds()}`;

  const documentFetchers = {
    pdf: getPdf,
    png: getImg,
    jpg: getImg,
    jpeg: getImg,
    svg: getImg,
    xls: getDoc,
    xlsx: getDoc,
    doc: getDoc,
    docx: getDoc,
  };

  return (
    <div className="library-table-cards">
      <List
        size="large"
        pagination={
          paginationProps
            ? {
                position: "top",
                align: "center",
                onChange: (val) => handleClickPagination(val - 1),
                pageSize: size,
                total: dataTotalLength,
                current: Number(page) + 1,
              }
            : false
        }
        dataSource={data}
        renderItem={(rowData) => {
          if (rowData.isFolder) {
            const url = rowData.isCatalogFolder
              ? "/main/library/catalog/"
              : "/main/library/my-files/";
            return (
              <button
                className="library-table-card folder"
                onDoubleClick={() => {
                  history.push(url + rowData.id);
                }}
              >
                <div className="card-top">
                  <div className="icon-wrapper">
                    <RenderFileOrFolderIcon
                      isFolder={rowData.isFolder}
                      folderColor={rowData.color}
                      url={rowData.url}
                      type={rowData.mimeType}
                      size="30px"
                    />
                  </div>
                  <div className="body-wrapper">
                    <span className="author-name">{rowData.updatedByName}</span>
                    <span className="file-name">{rowData.name}</span>
                  </div>
                </div>
                <Divider />
                <div className="card-bottom">
                  <div className="shared-profiles-wrapper">
                    {rowData.shared?.map((person, idx) => (
                      <span key={idx}>{person.firstLetter}</span>
                    ))}
                  </div>
                  <div className="favorite-icon-wrapper">
                    {rowData.isFavorite && <StarFilled />}
                  </div>
                </div>
                <div className="popover-wrapper">
                  <Popover
                    key={rowData.id}
                    trigger="click"
                    placement="left"
                    open={showActionsPopover[rowData.id]}
                    onOpenChange={(val) => {
                      setShowActionsPopover({
                        ...showActionsPopover,
                        [rowData.id]: val,
                      });
                    }}
                    content={
                      <ActionsPopover
                        rowData={rowData}
                        getLastTableData={getData}
                        setShowActionsPopover={setShowActionsPopover}
                        showActionsPopover={showActionsPopover}
                      />
                    }
                  >
                    <button className="last-viewed-table-row-action-trigger">
                      <MoreOutlined />
                    </button>
                  </Popover>
                </div>
              </button>
            );
          } else {
            return (
              <Fragment>
                <a
                  className="library-table-card"
                  target="_blank"
                  href={rowData.url}
                  onClick={() => {
                    setShowModal(true);
                    postFileToLastWatched(rowData.id);

                    // Select which function we will call according to file type
                    const mimeType = fileTypeDownloadEnum[rowData.mimeType];
                    if (documentFetchers[mimeType]) {
                      documentFetchers[mimeType](rowData.url);
                    }
                  }}
                >
                  <div className="card-top">
                    <div className="icon-wrapper">
                      <RenderFileOrFolderIcon
                        isFolder={rowData.isFolder}
                        folderColor={rowData.color}
                        url={rowData.url}
                        type={rowData.mimeType}
                        size="30px"
                      />
                    </div>
                    <div className="body-wrapper">
                      <span className="author-name">
                        {rowData.updatedByName}
                      </span>
                      <span className="file-name">{rowData.name}</span>
                    </div>
                  </div>
                  <Divider />
                  <div className="card-bottom">
                    <div className="shared-profiles-wrapper">
                      {rowData.shared?.map((person, idx) => (
                        <span key={idx}>{person.firstLetter}</span>
                      ))}
                    </div>
                    <div className="favorite-icon-wrapper">
                      {rowData.isFavorite && <StarFilled />}
                    </div>
                  </div>
                  <div className="popover-wrapper">
                    <Popover
                      key={rowData.id}
                      trigger="click"
                      placement="left"
                      open={showActionsPopover[rowData.id]}
                      onOpenChange={(val) => {
                        setShowActionsPopover({
                          ...showActionsPopover,
                          [rowData.id]: val,
                        });
                      }}
                      content={
                        <ActionsPopover
                          rowData={rowData}
                          getLastTableData={getData}
                          setShowActionsPopover={setShowActionsPopover}
                          showActionsPopover={showActionsPopover}
                        />
                      }
                    >
                      <button className="last-viewed-table-row-action-trigger">
                        <MoreOutlined />
                      </button>
                    </Popover>
                  </div>
                </a>
                <Modal
                  destroyOnClose
                  open={showModal}
                  title="Fayl"
                  onCancel={closeModal}
                  onOk={closeModal}
                >
                  <Watermark
                    content={[
                      `${currentUserId} ${currentUserName},${date},${time}`,
                    ]}
                    rotate={20}
                    gapX={60}
                    gapY={100}
                    fontSize={10}
                    fontColor="#979797"
                    style={{ background: "#fff" }}
                  >
                    {frame}
                  </Watermark>
                </Modal>
              </Fragment>
            );
          }
        }}
      />
    </div>
  );
}

export default LibraryTableCards;
