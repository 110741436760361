import React from "react";

const NotifyWarning = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.5072 13.1658L19.4412 29.7143C19.4051 29.7356 19.3687 29.7563 19.3319 29.7765C17.671 30.6894 16.6666 32.4023 16.6666 34.2376V64.587C16.6691 65.5003 16.9165 66.3962 17.3833 67.1813C17.8506 67.9674 18.5205 68.6135 19.323 69.0521C19.4009 69.0947 19.4774 69.1397 19.5524 69.1872L47.5526 86.8998C48.3076 87.2941 49.1472 87.5003 49.9999 87.5003C50.8527 87.5003 51.6923 87.2941 52.4473 86.8998L80.4474 69.1872C80.5196 69.1415 80.5931 69.0981 80.6679 69.057C82.3269 68.1451 83.3333 66.4291 83.3333 64.5917V34.2159C83.3447 32.3685 82.3582 30.6599 80.753 29.7472C80.5082 29.608 80.2819 29.4469 80.0756 29.2674L52.7677 13.1658C51.9597 12.7301 51.0559 12.5018 50.1374 12.5018C49.2189 12.5018 48.3151 12.7301 47.5072 13.1658ZM85.7863 23.0724C85.5697 22.8712 85.329 22.6908 85.0662 22.5359L56.9412 5.95254C56.9067 5.93217 56.8718 5.91231 56.8367 5.89294C54.785 4.76172 52.4803 4.16846 50.1374 4.16846C47.7945 4.16846 45.4898 4.76172 43.4381 5.89294C43.403 5.91231 43.3682 5.93217 43.3336 5.95254L15.2578 22.5069C10.9954 24.8777 8.33325 29.3541 8.33325 34.2376V64.5917L8.33326 64.6001C8.3381 67.0078 8.98973 69.3699 10.22 71.4395C11.4255 73.4674 13.1432 75.1415 15.1995 76.2943L43.2224 94.0213C43.2951 94.0673 43.3692 94.1111 43.4446 94.1525C45.4535 95.2554 47.7082 95.8337 49.9999 95.8337C52.2917 95.8337 54.5463 95.2554 56.5552 94.1525C56.6306 94.1111 56.7047 94.0673 56.7774 94.0213L84.8041 76.2919C89.032 73.9112 91.6666 69.4494 91.6666 64.5917V34.2537C91.6899 29.759 89.4673 25.5746 85.7863 23.0724Z"
        fill="#E0A611"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.9999 62.5C52.3011 62.5 54.1666 64.3655 54.1666 66.6667V66.7083C54.1666 69.0095 52.3011 70.875 49.9999 70.875C47.6987 70.875 45.8333 69.0095 45.8333 66.7083V66.6667C45.8333 64.3655 47.6987 62.5 49.9999 62.5Z"
        fill="#E0A611"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.6109 33.5756C50.358 33.0259 53.2102 33.4214 55.704 34.6978C58.2067 35.9741 60.2065 38.0563 61.3808 40.6086C62.5557 43.1622 62.8356 46.0373 62.1753 48.7696C61.515 51.5019 59.953 53.9319 57.7416 55.6672C55.5302 57.4024 52.7984 58.3417 49.9875 58.3332C47.6863 58.3263 45.8264 56.4552 45.8334 54.1541C45.8403 51.8529 47.7114 49.993 50.0125 49.9999C50.9495 50.0028 51.8601 49.6897 52.5972 49.1112C53.3344 48.5328 53.855 47.7228 54.0751 46.8121C54.2952 45.9013 54.2019 44.9429 53.8103 44.0917C53.4187 43.2405 52.7516 42.5462 51.9167 42.1208L51.9087 42.1167L51.9087 42.1167C51.0882 41.6964 50.1497 41.5661 49.2459 41.7469C48.342 41.9278 47.5259 42.4092 46.9303 43.1128C45.4435 44.8692 42.8144 45.0878 41.058 43.601C39.3016 42.1143 39.083 39.4852 40.5697 37.7287C42.3807 35.5893 44.8623 34.1255 47.6109 33.5756Z"
        fill="#E0A611"
      />
    </svg>
  );
};

export default NotifyWarning;
