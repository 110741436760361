import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { sublist } from "const/initialDatas";
import { setSublistInfo } from "redux/actions/sublistActions";
import GL from "libs/GL";
import Sublist from "models/Sublist";
import PageTitle from "components/Elements/PageTitle/PageTitle";
import { Row, Col, Skeleton } from "antd";
import FormSubmit from "components/Elements/FormSubmit/FormSubmit2";
import DynamicInputs from "./helper/DynamicInputs";
import { useTranslation } from "react-i18next";
import Enum from "models/Enum";

const SublistEdit = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const { sublistId, rowId } = props.match.params;
  const isCreate = !Boolean(rowId);
  const hasSubId = Boolean(sublistId);

  const [isLoading, setIsLoading] = useState(true);
  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({})
  );
  const [initialValues, setInitialValues] = useState({});
  const [enumLists, setEnumLists] = useState({});

  function getEnumsListByCategoryID(categoryID) {
    Enum.getEnumsByCategoryID(categoryID, (list) => {
      setEnumLists((prevList) => ({ ...prevList, [categoryID]: list }));
    });
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const body = values.info;

      if (isCreate) {
        Sublist.Create(sublistId, body, () => {
          onReset();
          dispatch(setSublistInfo(sublist));
          GL.notify.success({ text: t("success_message") });
          history.push(`/my-list/${sublistId}`);
        });
      } else {
        Sublist.Update(sublistId, rowId, body, () => {
          onReset();
          GL.notify.success({ text: t("success_message") });
          history.push(`/my-list/${sublistId}`);
        });
      }
    },
  });
  const { values, handleSubmit, setValues, setTouched, setErrors } = formik;

  function onSubmit() {
    handleSubmit();
  }

  function onReset() {
    setValues(sublist);
    setTouched({});
    setErrors({});
  }

  const getSublistByMyListID = (subId, hasSubId, rowId) => {
    setIsLoading(true);
    if (!hasSubId) return;
    Sublist.getSublistByMyListID(subId, (response) => {
      const fields = response?.settings?.fields || [];
      console.log(fields);
      const values = { fields };
      const schema = {};

      if (!isCreate) {
        Sublist.getSublistByID(sublistId, rowId, ([subItems]) => {
          if (!subItems) return;
          delete subItems.id;
          console.log(subItems);
          setValues({ info: { ...values, ...subItems } });
          setInitialValues({ info: { ...values, ...subItems } });
          setIsLoading(false);
        });
      } else {
        for (let field of fields) {
          values[field?.field] =
            field.type === "boolean" && field.default
              ? field.default
              : field.type === "boolean" && !field.default
              ? false
              : field.default;
        }

        setValues({ info: values });
        setInitialValues({ info: values });
        setIsLoading(false);
      }
      for (let field of fields) {
        if (field.type === "enums") {
          getEnumsListByCategoryID(field.enum);
        }
        if (!field?.nullable)
          schema[field?.field] = Yup.string().required(
            `${t("insert", { name: field.name })}`
          );
      }
      setValidationSchema(
        Yup.object().shape({ info: Yup.object().shape(schema) })
      );
    });
  };

  useEffect(() => {
    getSublistByMyListID(sublistId, hasSubId, rowId);
  }, [sublistId, hasSubId, rowId]);

  if (isLoading) return <Skeleton active />;

  return (
    <div className="main-layout-wrapper">
      <PageTitle big={t("sublist_title")} small={t("sublist_decs")} />
      <div className="risk-page">
        <h1 className="risk-page__title">{t("global_info")}</h1>
        <div className="risk-page__elements">
          <Row gutter={24}>
            <Col sm={24} md={24} lg={12}>
              {values?.info?.fields?.map((field, index) => (
                <DynamicInputs
                  key={index}
                  field={field}
                  formik={formik}
                  enumLists={enumLists}
                />
              ))}
            </Col>
          </Row>
        </div>
        <div className="risk-page__submittion">
          <FormSubmit cancel={onReset} submit={onSubmit} />
        </div>
      </div>
    </div>
  );
};

export default SublistEdit;
