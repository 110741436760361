import "./FileCard.scss";
import React, { Fragment } from "react";
import { fileTypeIconsEnum } from "const/fileTypeIconsEnum";
import { LibraryModel } from "models/Library";
import { useState } from "react";
import { Modal } from "antd";
import Watermark from "@uiw/react-watermark";
import { fileTypeDownloadEnum } from "const/fileTypeDownloadEnum";
import { useSelector } from "react-redux";
function FileCard({
  authorName,
  mimeType,
  name,
  url,
  id,
  getLasrViewedListForTable,
}) {
  const [showModal, setShowModal] = useState(false);
  const [googleDocsViewerUrl, setGoogleDocsViewerUrl] = useState("");
  const currentUserId = useSelector((state) => state.user.data.user.id);
  const closeModal = () => setShowModal(false);

  // ! getPdf() Here we change original url to blob url. Because if we use original url, the server responds with 403 (Forbidden error)
  // --> start
  const [docUrl, setDocUrl] = useState("");

  const getPdf = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const documentUrl = url;

    fetch(documentUrl, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        console.log("Blob URL:", blobUrl);
        setDocUrl(blobUrl);
      })
      .catch((error) => console.log("Error fetching document:", error));
  };
  // --> end

  const postFileToLastWatched = (fileId) => {
    LibraryModel.postFileToLastWatched(
      fileId,
      (_) => {
        getLasrViewedListForTable();
      },
      (err) => {
        console.log("err ==>", err);
      }
    );
  };

  // Iframes for individual type of documents
  const pdfIframe = (
    <iframe
      src={`${docUrl}#toolbar=0`}
      width="100%"
      height={screen.height - 300}
    />
  );

  const photoIframe = (
    <img
      src={url}
      alt={name}
      className="modal-image"
      width="100%"
      height="auto"
    />
  );

  const docIframe = (
    <iframe width="100%" height={screen.height - 300} src={googleDocsViewerUrl}>
      {" "}
    </iframe>
  );

  // Date properties
  const newDate = new Date();
  const date = `${newDate.getDate()}.${newDate.getMonth()}.${newDate.getFullYear()}`;
  const time = `${newDate.getHours()}:${newDate.getMinutes()}:${newDate.getSeconds()}`;

  const renderDocument = () => {
    switch (fileTypeDownloadEnum[mimeType]) {
      case "pdf":
        return pdfIframe;
      case "png":
      case "jpg":
      case "jpeg":
      case "svg":
        return photoIframe;
      case "xls":
      case "xlsx":
      case "doc":
      case "docx":
        return docIframe;
      default:
        return null; 
    }
  };
  return (
    <Fragment>
      {" "}
      <a
        className="file-card-link"
        onClick={() => {
          postFileToLastWatched(id);
          setShowModal(true);

          fileTypeDownloadEnum[mimeType] === "pdf" && getPdf();

          // ~ Embed google component for displaying xls and doc files
          setGoogleDocsViewerUrl(
            `https://docs.google.com/viewer?url=${encodeURIComponent(
              url
            )}&embedded=true`
          );
        }}
      >
        <img
          src={fileTypeIconsEnum[mimeType] || fileTypeIconsEnum.default}
          alt={name}
        />
        <span className="file-card-right">
          <span>{authorName}</span>
          <span>{name}</span>
        </span>
      </a>
      <Modal
        destroyOnClose
        open={showModal}
        title="Fayl"
        onCancel={closeModal}
        onOk={closeModal}
      >
        <Watermark
          content={[`${authorName} ${currentUserId}, ${date}, ${time}`]}
          rotate={20}
          gapX={60}
          gapY={100}
          fontSize={10}
          fontColor="#979797"
          style={{ background: "#fff" }}
        >
          {renderDocument()}
        </Watermark>
      </Modal>
    </Fragment>
  );
}

export default FileCard;
