import "./MessagesTable.scss";
import React from "react";

import { Table } from "antd";

const apiNotificationData = [
  {
    key: "1",
    isRead: false,
    type: "Müddəti bitən icralar",
    text: "15 iyun 2023-cü il tarixə icrası bitməyən 15 iş mövcuddur...",
    date: "14.07.2023",
  },
  {
    isRead: true,
    type: "Tələb olunan fayllar",
    text: " Salam. Tapşırığınıza uyğun olaraq tələb edilən faylları diqqətinizə çatdırıram. Əgər hər hansı əla Salam. Tapşırığınıza uyğun olaraq tələb edilən faylları diqqətinizə çatdırıram. Əgər hər hansı əlaSalam. Tapşırığınıza uyğun olaraq tələb edilən faylları diqqətinizə çatdırıram. Əgər hər hansı əlaSalam. Tapşırığınıza uyğun olaraq tələb edilən faylları diqqətinizə çatdırıram. Əgər hər hansı əlaSalam. Tapşırığınıza uyğun olaraq tələb edilən faylları diqqətinizə çatdırıram. Əgər hər hansı əlaSalam. Tapşırığınıza uyğun olaraq tələb edilən faylları diqqətinizə çatdırıram. Əgər hər hansı əlaSalam. Tapşırığınıza uyğun olaraq tələb edilən faylları diqqətinizə çatdırıram. Əgər hər hansı əla",
    date: "14.07.2023",
    key: "2",
  },
  {
    isRead: true,
    type: "Tələb olunan fayllar",
    text: "Salam. Tapşırığınıza uyğun olaraq tələb edilən faylları diqqətinizə çatdırıram. Əgər hər hansı əla...",
    date: "14.07.2023",
    key: "3",
  },
];

function MessagesTable() {
  const columns = [
    {
      title: "left",
      dataIndex: "left",
      key: "left",
      render: (_, data) => {
        return (
          <div
            className={data.isRead ? "table-raw-left" : "table-raw-left unread"}
          >
            {data.isRead ? null : <span className="new">Yeni: </span>}
            <span>
              <span className="type">{data.type} - </span>
              <span className="text">{data.text}</span>
            </span>
          </div>
        );
      },
    },
    {
      title: "right",
      dataIndex: "right",
      key: "right",
      width: 100,
      render: (_, data) => {
        return (
          <span
            className={
              data.isRead ? "table-raw-right" : "table-raw-right unread"
            }
          >
            {data.date}
          </span>
        );
      },
    },
  ];
  return (
    <div className="messages-ant-table">
      <Table
        dataSource={apiNotificationData}
        columns={columns}
        pagination={false}
        showHeader={false}
        bordered
      />
    </div>
  );
}

export default MessagesTable;
