import React, { useEffect, useState } from "react";
import { Modal, Skeleton, Row, Col } from "antd";
import { useFormik } from "formik";
import Update from "./Update";
import { useTranslation } from "react-i18next";
import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import Logs from "models/Logs";
import { isObject } from "utils/checking";
import ReactJson from "react-json-view";

const ShowModal = ({
  isShowOpenModal,
  onSetIsShowOpenModal,
  showModalData,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  let isUpdate = showModalData?.type === 2;

  const formik = useFormik({
    initialValues: { info: {} },
  });

  const { values, setValues } = formik;

  useEffect(() => {
    if (showModalData && isUpdate) {
      formik.setValues({ info: { ...showModalData } });
    } else {
      setIsLoading(true);
      Logs.getLogByID(showModalData.id, (response) => {
        if (response) {
          let convertNullToString = {};
          for (let [key, value] of Object.entries(response)) {
            convertNullToString[key] = value === null ? "" : value;
          }
          setValues({
            info: {
              ...convertNullToString,
            },
          });
        }
        setIsLoading(false);
      });
    }
  }, [showModalData]);

  return (
    <Modal
      title="Logun detallı məlumatları"
      bodyStyle={{
        height: "auto",
        minHeight: 500,
      }}
      width={isUpdate ? 900 : 500}
      open={isShowOpenModal}
      onCancel={() => onSetIsShowOpenModal(false)}
      onOk={() => onSetIsShowOpenModal(false)}
    >
      <div
        className="risk-page"
        style={{
          marginTop: "16px",
          paddingBottom: "0",
        }}
      >
        {isLoading ? (
          <Skeleton active />
        ) : (
          <div className="risk-page__elements">
            {isUpdate ? (
              <Update values={values} />
            ) : (
              <Row gutter={24}>
                <Col sm={24} md={24} lg={24}>
                  <div className="accordion-panel">
                    <div className="accordion-panel__info text-center">
                      <h4>Xananın adı</h4>
                      <h4>Dəyər</h4>
                    </div>
                  </div>
                  {Object.entries(values?.info).map(([key, value], index) => {
                    if (isObject(value) || Array.isArray(value)) {
                      return (
                        <div
                          key={index}
                          className="accordion-panel__info text-center"
                        >
                          <span>
                            {key.replace(key[0], key[0].toUpperCase())}
                          </span>
                          <strong>
                            <ReactJson collapsed src={value} />
                          </strong>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={index}
                          className="accordion-panel__info text-center"
                        >
                          <span>
                            {key.replace(key[0], key[0].toUpperCase())}
                          </span>
                          <strong>{value}</strong>
                        </div>
                      );
                    }
                  })}
                </Col>
              </Row>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ShowModal;
