import React from "react";
import { Row, Col } from "antd";
import InputBox from "../../../Elements/FileUploadForm/Form/InputBox";
import { useTranslation } from "react-i18next";

const Password = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <div className="risk-page">
      <div className="risk-page__elements">
        <h2>Şifrə</h2>
        <Row gutter={24}>
          <Col sm={24} md={8} lg={8}>
            <InputBox
              label={t("new_password")}
              formik={formik}
              field="password"
              required={true}
              type="password"
            />
            <InputBox
              label={t("confirm_password")}
              formik={formik}
              field="password2"
              required={true}
              type="password"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Password;
