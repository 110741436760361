import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Typography } from "antd";
import { Space } from "antd";
import "./RTMSMessenger.css";
import { useAppContext } from "./AppContext";
import { Circles } from "react-loader-spinner";
import { MessengerModel } from "./model/MessengerModel";
import "dayjs/locale/az";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
import InfiniteScroll from "react-infinite-scroll-component";
dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
import singleuser from "../../../assets/img/svguser.svg";
import useMySocket from "components/socket";

import { useSelector } from "react-redux";
const { Content } = Layout;
const { Title } = Typography;

const MessagesContentGroup = () => {
  const { state } = useAppContext();
  const { selectedUser, loading } = state;
  const activeUserId = useSelector((state) => state.user.data.user.id);

  let fullDateFormatType = "YYYY-MM-DD HH:mm";


  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const { mySocket: socket } = useMySocket();

  const listenerFunc = (e) => {
    const data = e.detail;
    setItems((prevItems) => [...prevItems, data]);

    MessengerModel.handleChatMessageClick(
      selectedUser.toId ? selectedUser.toId : selectedUser.id,
      true,
      0,
      10,
      (data) => {
        const reversedMessages = data.messages.reverse();
        setItems(reversedMessages);
      }
    );
  };


  useEffect(() => {
    document.addEventListener("incomingGroup", listenerFunc);

    return () => {
      document.removeEventListener(listenerFunc);
    };
  }, []);

  useEffect(() => {
    MessengerModel.handleChatMessageClick(
      selectedUser.toId,
      selectedUser.isGroup,
      0,
      10,
      (data) => {
        const reversedMessages = data.messages.reverse();
        setItems(reversedMessages);
      }
    );
  }, [selectedUser]);

  const fetchMoreData = () => {
    console.log("fetchdata");
    hasMore &&
      MessengerModel.handleChatMessageClick(
        selectedUser.toId,
        selectedUser.isGroup,
        page,
        10,
        (data) => {
          if (data.messages.length > 0) {
            data.messages.forEach((newMessage) => items.unshift(newMessage));
            setItems(items);

            setPage((page) => page + 1);
          } else {
            setHasMore(false);
          }
        }
      );
  };

  return loading ? (
    <Space
      style={{
        height: "63vh",
        overflow: "hidden",
        overflowY: "scroll",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Circles
        height="80"
        width="80"
        color="#c64d42"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </Space>
  ) : (
    selectedUser.isGroup && (
      <Content
        style={{
          height: "71vh",
          // overflow: "auto",
          backgroundColor: "white",
        }}
        className="messages-container"
      >
        <InfiniteScroll
          dataLength={items.length}
          next={fetchMoreData}
          inverse={true}
          hasMore={hasMore}
          loader={
            items.length > 9 && (
              <Circles
                height="30"
                width="30"
                color="#c64d42"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            )
          }
          height={530}
          endMessage={<div style={{ margin: "0 auto" }}>Mesajın sonu</div>}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          {items.length > 0 && (
            <Space direction="vertical">
              {items.map((message) => {
                if (activeUserId !== message.fromId) {
                  return (
                    <Space
                      style={{
                        color: "red",
                        padding: "15px",
                      }}
                    >
                      <img src={singleuser} alt="single user icon" />
                      <Title
                        style={{
                          width: 200,
                          fontSize: "14px",
                          backgroundColor: "#F8F9FA",
                          padding: "10px",
                          color: "#979797",
                          borderRadius: "12px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            fontSize: "10px",
                            color: "#000",
                          }}
                        >
                          {message.name}
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          {message.messageContent}
                        </div>
                      </Title>
                      <Title style={{ fontSize: "9px", color: "#ccc" }}>
                        {dayjs(message.messageTime).format(fullDateFormatType)}
                      </Title>
                    </Space>
                  );
                } else {
                  return (
                    <Space
                      style={{
                        color: "black",
                        padding: "15px",
                        justifyContent: "end",
                      }}
                    >
                      {" "}
                      <Title style={{ fontSize: "9px", color: "#ccc" }}>
                        {dayjs(message.messageTime).format(fullDateFormatType)}
                      </Title>
                      <Title
                        style={{
                          width: 200,
                          fontSize: "14px",
                          backgroundColor: "#C64D42",
                          padding: "10px",
                          color: "#FFFFFF",
                          borderRadius: "12px",
                        }}
                      >
                        {" "}
                        {message.messageContent}
                      </Title>
                    </Space>
                  );
                }
              })}
            </Space>
          )}
        </InfiniteScroll>
      </Content>
    )
  );
};

export default MessagesContentGroup;