import { TabulatorFull as Tabulator } from "tabulator-tables";

Tabulator.prototype.GL = {
  id: "",
  that: null,
  options: null,
  settings: {
    pk: "",
    styles: {
      ic: "",
      bt: "",
    },
    activeFilter: {},
    filters: {},
    queryTypes: {
      like: { label: "Oxşarını tap" },
      "=": { label: "Tam bərabər olanını tap" },
      "!=": { label: "Bərabər olmayanı tap" },
      in: { label: "Bir neçə variantdan tap" },
      "<=": { label: "Kiçik və bərabər olanını tap" },
      ">=": { label: "Böyük və bərabər olanını tap" },
      "<": { label: "Kiçik variantları tap" },
      ">": { label: "Böyük variantları tap" },
      range: { label: "Aralığa daxil olanları tap" },
      contains: { label: "Sahəyə daxil olanları tap" },
      within: { label: "Çəkilən həndəsəyə uyğun olanı tap" },
      intersect: { label: "Kəsişənləri tap" },
    },
    filterTypes: {
      number: ["like", "=", "!=", "in", "<=", ">=", "<", ">", "range"],
      text: ["like", "=", "!=", "in"],
      select: ["like", "=", "!=", "in"],
      checkbox: ["="],
      geometry: ["contains", "within", "intersect"],
      date: ["=", "!=", "in", "range"],
    },
    rowColor: {},
    secretColumns: [],
    columns: [],
    columnSort: [],
    modalSettings: {
      type: "like",
      isManuel: true,
      value: [""],
      selections: [],
      addedValues: [],
    },
  },
  rules: {
    simple: {},
    complex: {},
  },
  getLocalStorage() {
    var tables = localStorage.getItem("tables");
    if (tables == null) {
      localStorage.setItem("tables", JSON.stringify({}));
      tables = "{}";
    }
    return JSON.parse(tables);
  },
  getTableFromLocalStorage(tableid) {
    var tables = this.getLocalStorage();
    return tables[tableid];
  },
  getFieldFromLocalStorage(tableid, field) {
    //yyy
    var table = this.getTableFromLocalStorage(tableid);
    if (table !== undefined) {
    } else {
    }
  },
  localStorageUpdate() {
    var tables = this.getLocalStorage();
    var columns = this.settings.columns.map((a) => {
      return { title: a.title, field: a.field };
    });
    tables[this.id] = {
      rules: this.rules,
      id: this.id,
      columnSort: this.settings.columnSort,
      secretColumns: this.settings.secretColumns,
      columns: columns,
      uniqueColumn: this.settings.pk,
    };
    localStorage.setItem("tables", JSON.stringify(tables));
  },
  setLocalStorageToTable() {
    var tableid = this.id;
    var tables = localStorage.getItem("tables");
    if (tables !== null) {
      tables = JSON.parse(tables);
      if (tables[tableid] !== undefined) {
        // debugger;
        var settings = tables[tableid];
        this.rules = settings.rules;
        this.settings.secretColumns = settings.secretColumns;
        this.settings.uniqueColumn = settings.uniqueColumn;
        //this.settings.columnSort = settings.columnSort;
        this.filter();
      }
    }
  },
  filter() {
    //debugger;

    var rules = [];
    for (var i in this.rules.simple) {
      var fname = i;
      var rule = {
        field: fname,
        type: this.rules.simple[i].operator,
        value: this.rules.simple[i].value,
      };
      rules.push(rule);
    }

    for (var i in this.rules.complex) {
      var fname = i;
      var butonid = `filter-${this.id}-${fname}-filtericon`;
      var buton = document.getElementById(butonid);
      var newbuton = this.getFilterIcon(fname, true);
      buton.innerHTML = newbuton.innerHTML;
      var f = this.rules.complex[i];
      var value = this.rules.complex[i].value;
      switch (f.queryType) {
        case "range": {
          rules.push({ field: f.id, type: ">=", value: value[0] });
          rules.push({ field: f.id, type: "<=", value: value[1] });
          break;
        }
        case "in": {
          rules.push({ field: f.id, type: "in", value: value });
          break;
        }
        default: {
          rules.push({ field: f.id, type: f.queryType, value: value[0] });
        }
      }
    }
    //`filter-${this.id}-${field_name}-filtericon`;
    this.localStorageUpdate();
    this.that.setFilter(rules);
  },
  filterComplex() {
    //debugger;
    var { c, f, qt } = this.settings.activeFilter;
    var field = c.field;
    var data = this.that.getData(true);

    var selectValue = this.settings.modalSettings.type;
    var isManuel = this.settings.modalSettings.isManuel;
    var dataType = f.dataType;
    var value1 = `filter-${this.id}-value1`;
    var value2 = `filter-${this.id}-value2`;
    var values = [];
    // range veya in ise
    // range de value1 ve value2  values arrayine eklenecek
    // in de table listteki degerler values icerisine eklenecek
    if (selectValue == "range") {
      if (dataType == "number") {
        if (!(data[0][field] === Number(data[0][field]))) {
          var v1 = document.getElementById(value1).value;
          v1 = parseFloat(v1);
          values.push(v1);
          var v2 = document.getElementById(value2).value;
          v2 = parseFloat(v2);
          values.push(v2);
        } else {
          var v1 = document.getElementById(value1).value;
          v1 = Number(v1);
          values.push(v1);
          var v2 = document.getElementById(value2).value;
          v2 = Number(v2);
          values.push(v2);
        }
      } else {
        var v1 = document.getElementById(value1).value;
        v1 = parseFloat(v1);
        values.push(v1);
        var v2 = document.getElementById(value2).value;
        v2 = parseFloat(v2);
        values.push(v2);
      }
    } else if (selectValue == "in") {
      for (let i = 0; i < this.settings.modalSettings.value.length; i++) {
        if (dataType == "number") {
          if (!(data[0][field] === Number(data[0][field]))) {
            var v1 = this.settings.modalSettings.value[i];
          } else {
            var v1 = this.settings.modalSettings.value[i];
            v1 = Number(v1);
          }
        } else {
          var v1 = this.settings.modalSettings.value[i];
        }
        values.push(v1);
      }
    } else {
      var tag = document.getElementById(value1);
      var v1 = tag.value;
      /*if(dataType=='number'){
          v1=Number(v1);
        }*/
      values.push(v1);
    }
    var filterObj = {
      id: c.field,
      inputType: isManuel ? "manuel" : "select",
      queryType: selectValue,
      value: values,
      status: true,
    };
    this.rules.complex[field] = filterObj;
    this.filter();
    this.closeModal();
  },

  setFilterRule(fname, data, type, operator) {
    if (type == "simple") {
      if (data === "") {
        delete this.rules.simple[fname];
      } else {
        this.rules.simple[fname] = { value: data, operator };
      }
    }
    this.filter();
  },
  setStyle(el, className, type) {
    el.className = className;
    if (type === "input") {
      el.style.width = "calc(100% - 43px)";
    } else if (type == "select") {
      el.style.width = "calc(100% - 34px)";
    } else {
      el.style.width = "100%";
    }
    el.style.marginRight = "3px";
    return el;
  },
  getInput(field_name, type, operator, defaultValue) {
    var el = this.CREL("input");

    el.dataset["fname"] = field_name;
    el.type = type;
    el = this.setStyle(el, this.settings.styles.ic, "input");
    if (this.settings.filters[field_name]["placeholder"] !== undefined) {
      el.placeholder = this.settings.filters[field_name]["placeholder"];
    }

    el.addEventListener(
      "input",
      (e) => {
        if (this.settings.filters[field_name]["onChange"] !== undefined) {
          this.settings.filters[field_name]["onChange"](
            field_name,
            e.target.value
          );
        }

        if (!this.settings.filters[field_name]["isAsync"]) {
          this.setFilterRule(field_name, e.target.value, "simple", operator);
        }
      },
      false
    );
    if (defaultValue !== null) {
      el.value = defaultValue;
    }
    return el;
  },
  getValidDate(field_name, d) {
    var YY = d.getFullYear();
    var MM = d.getMonth() + 1;
    MM = MM < 10 ? `0${MM}` : MM;
    var DD = d.getDate();
    DD = DD < 10 ? `0${DD}` : DD;
    if (this.settings.filters[field_name]["dateformat"]) {
      var f = this.settings.filters[field_name]["dateformat"];
    } else {
      var f = { format: "YYYY-MM-DD", parser: "-" };
    }
    var parts = f.format.split("-");
    var ch = f.parser;
    var n = [];
    parts.map((a) => {
      switch (a) {
        case "YYYY": {
          n.push(YY);
          break;
        }
        case "DD": {
          n.push(DD);
          break;
        }
        case "MM": {
          n.push(MM);
          break;
        }
      }
    });
    return n.join(ch);
  },
  getDatePicker(field_name, type, operator, defaultValue) {
    var set = this.settings.filters[field_name];
    var el = this.CREL("input");
    el.dataset["fname"] = field_name;
    el.type = "date";
    el = this.setStyle(el, this.settings.styles.ic, "input");
    if (set["dateformat"] !== undefined) {
      var format = set.dateformat.format;
      var parser = set.dateformat.parser;
      if (defaultValue !== null) {
        var fp = format.split("-");
        var part = defaultValue.split(parser);
        var obj = {};
        var j = 0;
        for (var i in fp) {
          obj[fp[i]] = part[j];
          j++;
        }
        var value = `${obj["YYYY"]}-${obj["MM"]}-${obj["DD"]}`;
        el.value = value;
      }
    }
    el.addEventListener(
      "input",
      (e) => {
        if (type == "date") {
          if (e.target.value == "") {
            this.setFilterRule(field_name, "", "simple", operator);
          } else {
            var yil = Number(e.target.value.split("-")[0]);
            if (yil > 1900) {
              var d = this.getValidDate(field_name, new Date(e.target.value));
              if (this.settings.filters[field_name]["onChange"] !== undefined) {
                this.settings.filters[field_name]["onChange"](field_name, d);
              }
              if (!this.settings.filters[field_name]["isAsync"]) {
                this.setFilterRule(field_name, d, "simple", operator);
              }
            }
          }
        }
      },
      false
    );
    return el;
  },
  getSelect(field_name, options, operator, dtype, defaultValue) {
    var el = this.CREL("select");
    el.dataset["fname"] = field_name;
    if (this.settings.filters[field_name]["placeholder"] !== undefined) {
      el.innerHTML =
        '<option value="">' +
        this.settings.filters[field_name]["placeholder"] +
        "</option>";
    } else {
      el.innerHTML = '<option value="">Secenekler</option>';
    }
    //debugger;
    if (dtype == "boolean") {
      if (defaultValue == true) {
        defaultValue = "true";
      } else if (defaultValue == false) {
        defaultValue = "false";
      } else {
        defaultValue = null;
      }
    }
    options.map((o) => {
      var op = this.CREL("option");
      op.value = o.value;
      op.innerText = o.text;

      if (defaultValue == op.value) {
        op.selected = true;
        el.value = defaultValue;
      }
      el.appendChild(op);
    });
    el = this.setStyle(el, this.settings.styles.ic, "select");
    el.addEventListener(
      "input",
      (e) => {
        var val = e.target.value;
        switch (dtype) {
          case "boolean": {
            if (e.target.value === "true") {
              val = true;
            } else if (e.target.value === "false") {
              val = false;
            } else {
              val = "";
            }
            break;
          }
          case "integer": {
            val = parseInt(val);
            break;
          }
          case "double": {
            val = Number(val);
            break;
          }
        }
        if (this.settings.filters[field_name]["onChange"] !== undefined) {
          this.settings.filters[field_name]["onChange"](field_name, val);
        }
        if (!this.settings.filters[field_name]["isAsync"]) {
          this.setFilterRule(field_name, val, "simple", operator);
        }
      },
      false
    );
    return el;
  },
  getCheckbox(field_name, operator) {
    var el = this.CREL("select");
    el.dataset["fname"] = field_name;
    if (this.settings.filters[field_name]["placeholder"] !== undefined) {
      el.innerHTML =
        '<option value="">' +
        this.settings.filters[field_name]["placeholder"] +
        "</option>";
    } else {
      el.innerHTML = '<option value="">Secenekler</option>';
    }

    this.options.map((o) => {
      var op = this.CREL("option");
      op.value = o;
      op.innerText = o;
      el.appendChild(op);
    });
    el = this.setStyle(el, this.settings.styles.ic, "select");
    el.addEventListener(
      "input",
      (e) => {
        if (this.settings.filters[field_name]["onChange"] !== undefined) {
          this.settings.filters[field_name]["onChange"](
            field_name,
            e.target.value
          );
        }
        if (!this.settings.filters[field_name]["isAsync"]) {
          this.setFilterRule(field_name, e.target.value, "simple", operator);
        }
      },
      false
    );
    return el;
  },
  generateFilter(e, type, defaultValue) {
    let field_name = e.getField();
    var div = this.CREL("div");
    switch (type) {
      case "text": {
        var input = this.getInput(field_name, type, "like", defaultValue);
        break;
      }
      case "number": {
        var input = this.getInput(field_name, type, "=", defaultValue);
        break;
      }
      case "select": {
        var options = [];
        var items = this.that.options.data;
        items.map((a) => {
          if (options.indexOf(a[field_name]) == -1) {
            options.push({ value: a[field_name], text: a[field_name] });
          }
        });
        var input = this.getSelect(
          field_name,
          options,
          "=",
          "string",
          defaultValue
        );
        break;
      }
      case "date": {
        var input = this.getDatePicker(field_name, type, "=", defaultValue);
        break;
      }
      case "checkbox": {
        // debugger;
        var options = [];
        var tr = { value: true, text: "Aktif" };
        var fl = { value: false, text: "Pasif" };
        if (this.settings.filters[field_name]["mean"] !== undefined) {
          tr = {
            value: true,
            text: this.settings.filters[field_name]["mean"]["true"],
          };
          fl = {
            value: false,
            text: this.settings.filters[field_name]["mean"]["false"],
          };
        }
        var input = this.getSelect(
          field_name,
          [tr, fl],
          "=",
          "boolean",
          defaultValue
        );
        break;
      }
    }
    var button = this.getFilterIcon(field_name, false);
    button.addEventListener(
      "click",
      (e) => {
        this.openFilterDialog(field_name);
      },
      false
    );

    if (input !== undefined) {
      div.appendChild(input);
    }
    div.appendChild(button);
    div.style.display = "flex";
    div.style.justifyContent = "space-between";
    div.style.alignItems = "center";
    return div;
  },
  getFilterIcon(field_name, status) {
    var button = this.CREL("button");
    var color = status == false ? "currentColor" : "red";
    button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 512 512">
                          <path fill="${color}" d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"/>
                        </svg>`;

    button.dataset["fname"] = field_name;
    button.className = this.settings.styles.bt;
    button.id = `filter-${this.id}-${field_name}-filtericon`;
    return button;
  },
  updateFilter(c, filtSet) {
    //xxx
    if (c == undefined) {
      return null;
    }
    var tableJSON = this.getTableFromLocalStorage(this.id);
    var arr = [];
    if (c !== undefined) {
      if (c.length > 0) {
        c.map((a) => {
          if (tableJSON !== undefined) {
            var simple = tableJSON["rules"]["simple"];
            var complex = tableJSON["rules"]["complex"];
          } else {
            var simple = {};
            var complex = {};
          }
          var defaultValue = null;
          if (simple[a.field] !== undefined) {
            defaultValue = simple[a.field]["value"];
          }

          if (filtSet[a.field] !== undefined) {
            switch (filtSet[a.field].dataType) {
              case "text": {
                a.headerFilter = (e) => {
                  return this.generateFilter(e, "text", defaultValue);
                };
                break;
              }
              case "number": {
                a.headerFilter = (e) => {
                  return this.generateFilter(e, "number", defaultValue);
                };
                break;
              }
              case "select": {
                a.headerFilter = (e) => {
                  return this.generateFilter(e, "select", defaultValue);
                };
                break;
              }
              case "date": {
                a.headerFilter = (e) => {
                  return this.generateFilter(e, "date", defaultValue);
                };
                break;
              }
              case "checkbox": {
                a.headerFilter = (e) => {
                  return this.generateFilter(e, "checkbox", defaultValue);
                };
                break;
              }
            }
          }
          arr.push(a);
        });

        //debugger;
        var newColumns = [];
        if (this.settings.columnSort.length > 0) {
          var newSort = JSON.parse(JSON.stringify(this.settings.columnSort));
          newSort.unshift("id");
          newSort.push("operations");
          newSort.map((a) => {
            var col = arr.find((b) => b.field == a);
            if (col) {
              newColumns.push(col);
            }
          });
        } else {
          newColumns = arr;
        }
        this.settings.columns = newColumns;
        this.that.setColumns(newColumns);
        this.that.redraw(true);
      }
    }
  },
  updateColumns() {
    //debugger;
    var secrets = this.settings.secretColumns;
    var cols = this.settings.columns;
    var newColumns = [];
    if (this.settings.columnSort.length > 0) {
      var newSort = JSON.parse(JSON.stringify(this.settings.columnSort));
      newSort.unshift("id");
      newSort.push("operations");
      newSort.map((a) => {
        var col = cols.find((b) => b.field == a);
        if (col) {
          if (secrets.indexOf(a) !== -1) {
            col.visible = false;
          } else {
            col.visible = true;
          }
          newColumns.push(col);
        }
      });
    } else {
      newColumns = cols;
    }
    this.settings.columns = newColumns;
    this.that.setColumns(newColumns);
    this.that.redraw(true);
  },
  updateFormatter(c, filtSet) {
    var arr = [];
    if (c == undefined) {
      return null;
    }
    c.map((a) => {
      if (filtSet[a.field] !== undefined) {
        switch (filtSet[a.field].dataType) {
          case "checkbox": {
            if (filtSet[a.field]["mean"] !== undefined) {
              var means = filtSet[a.field]["mean"];
              a.formatter = (cell) => {
                var d = cell.getData();
                return means[d[a.field]];
              };
            }
            break;
          }
        }
      }
      arr.push(a);
    });
    return arr;
  },
  openFilterDialog(fname) {
    var c = this.settings.columns;
    var column = c.find((a) => {
      return a.field == fname;
    });
    var f = this.settings.filters[fname];
    var ft = this.settings.filterTypes[f.dataType];
    var qt = [];
    ft.map((a) => {
      qt.push({ value: a, text: this.settings.queryTypes[a].label });
    });
    this.designFilterHTML(column, f, qt);
  },
  designFilterHTML(c, f, qt) {
    this.settings.activeFilter = { c, f, qt };
    if (this.rules.complex[c.field] == undefined) {
      this.rules.complex[c.field] = {
        id: c.field,
        inputType: "manuel",
        dataType: f.dataType,
        queryType: "like",
        value: [],
        status: false,
      };
    }
    this.addDarkBG();
    this.addModalTemplate();
    this.updateFilter();
  },
  inputTypeSelected(type) {
    var { c, f, qt } = this.settings.activeFilter;
    this.rules.complex[c.field].inputType = type;
    this.addManuelComboSelection();
  },
  addValueInputs() {
    var { c, f, qt } = this.settings.activeFilter;
    var rule = this.rules.complex[c.field];
    var inputsId = `filter-${this.id}-inputs`;
    this.CLEAR(inputsId);
    var uniqsid = [];
    var uniqs = [];

    if (rule.inputType == "select") {
      var data = this.that.getData(true);
      data.map((d) => {
        if (uniqsid.indexOf(d[rule.id]) == -1) {
          uniqsid.push(d[rule.id]);
          uniqs.push({ value: d[rule.id], text: d[rule.id] });
        }
      });
      uniqs = this.sort(uniqs, "value", "asc");
    } else {
    }
  },
  addManuelComboSelection() {
    //debugger;
    var { c, f, qt } = this.settings.activeFilter;
    var metodId = `filter-${this.id}-metods`;
    this.CLEAR(metodId);
    var selection = "manuel";
    if (this.rules.complex[c.field] !== undefined) {
      selection = this.rules.complex[c.field]["inputType"];
    }
    // debugger;
    var divPar = this.CREL("div");
    divPar.style =
      "display: flex; width: 100%; margin-top: 10px; justify-content: space-between;";
    var divMan = this.CREL("div");
    divMan.innerHTML = "Dəyəri əl ilə yaz";
    divMan.style = `width: 49%; text-align: center; padding: 5px; border-top-left-radius: 5px; cursor: pointer; border-bottom-left-radius: 5px; font-size: 13px; display: flex; align-items: center; justify-content: center; ${
      selection == "manuel"
        ? "background-color: #c64d42; color: #fff;"
        : "background-color: #e4e4e4; color: #777;"
    }`;
    var divSel = this.CREL("div");
    divSel.innerHTML = "Dəyəri seçərək istifadə et";
    divSel.style = `width: 49%; text-align: center; padding: 5px; border-top-right-radius: 5px; cursor: pointer; border-bottom-right-radius: 5px; font-size: 13px; display: flex; align-items: center; justify-content: center; ${
      selection !== "manuel"
        ? "background-color: #c64d42; color: #fff;"
        : "background-color: #e4e4e4; color: #777;"
    }`;

    divMan.addEventListener("click", (e) => {
      this.settings.modalSettings.isManuel = true;
      this.inputTypeSelected("manuel");
      this.addValueInputs();
      this.changeInputs();
    });
    divSel.addEventListener("click", (e) => {
      // debugger;
      this.settings.modalSettings.isManuel = false;
      this.inputTypeSelected("select");
      this.addValueInputs();
      this.changeInputs();
    });
    divPar.appendChild(divMan);
    divPar.appendChild(divSel);
    this.APPEND(metodId, divPar);
    this.changeInputs();
    function changeInpTypes(value) {}
  },
  addFormItems() {
    //debugger;
    var { c, f, qt } = this.settings.activeFilter;
    var formId = `filter-${this.id}-form`;
    var metodId = `filter-${this.id}-metods`;
    var inputsId = `filter-${this.id}-inputs`;
    var form = document.getElementById(formId);
    var selectId = `filter-${this.id}-select`;
    var opts = [];
    qt.map((o) => {
      opts.push(`<option value="${o.value}">${o.text}</option>`);
    });

    var html1 = `<select id="${selectId}" style="width: 100%; padding: 7px; border: 1px solid #c5c5c5; border-radius: 5px;">${opts.join()}</select><div id="${metodId}"></div><div id="${inputsId}"></div>`;
    form.innerHTML = html1;
    var select = document.getElementById(`filter-${this.id}-select`);
    this.settings.modalSettings = {
      isManuel: this.rules.complex[c.field].inputType,
      selections: [],
      type: this.rules.complex[c.field].queryType,
      value: this.rules.complex[c.field].value,
    };
    document.getElementById(selectId).value =
      this.rules.complex[c.field]["queryType"];

    select.addEventListener(
      "change",
      (e) => {
        var selected =
          e.currentTarget.options[e.currentTarget.selectedIndex].value;
        this.settings.modalSettings.type = selected;
        this.changeInputs();
      },
      false
    );
    this.addManuelComboSelection();
    this.addValueInputs();
  },

  changeInputs() {
    //debugger;
    var inputsId = `filter-${this.id}-inputs`;
    var value1 = `filter-${this.id}-value1`;
    var value2 = `filter-${this.id}-value2`;
    var tbodyid = `filter-${this.id}-tbody`;
    var { c, f, qt } = this.settings.activeFilter;
    var field = c.field;
    var selectValue = this.settings.modalSettings.type;
    var isManuel = this.settings.modalSettings.isManuel;
    var options = [];
    var data = this.that.getData(true);
    data.map((a) => {
      if (options.indexOf(a[field]) == -1) {
        options.push(a[field]);
      }
    });

    if (
      selectValue == "range" &&
      this.rules.complex[c.field]["inputType"] == "manuel"
    ) {
      var inp1 = this.rules.complex[c.field]["value"][0];
      var inp2 = this.rules.complex[c.field]["value"][1];

      document.getElementById(inputsId).innerHTML = `
        <div style="display:flex;justify-content:space-between; width: 100%; margin-top:10px">
        <input id="${value1}" value="${
        inp1 ? inp1 : ""
      }" style="padding: 7px; height: 30px; margin-right: 5px; border: 1px solid #c5c5c5; border-radius: 5px;" placeholder="Baslangic Dəyər daxil edin"/>
        <input id="${value2}" value="${
        inp2 ? inp2 : ""
      }" style="padding: 7px; height: 30px; margin-left: 5px; border: 1px solid #c5c5c5; border-radius: 5px;" placeholder="Bitme Dəyər daxil edin"/>
        </div>
        `;
    }
    if (
      selectValue == "range" &&
      this.rules.complex[c.field]["inputType"] == "select"
    ) {
      var inp1 = this.rules.complex[c.field]["value"][0];
      var inp2 = this.rules.complex[c.field]["value"][1];
      document.getElementById(inputsId).innerHTML = `
        <div style="display:flex;justify-content:space-between; width: 100%; margin-top:10px">
        <select id="${value1}" style="width:100%; padding: 7px; height:30px; margin-right: 5px; border: 1px solid #c5c5c5; border-radius: 5px;">
        ${options.map((a) => {
          return `<option>${a}</option>`;
        })}
        </select>
        <select id="${value2}" style="width:100%; padding: 7px; height:30px; margin-left: 5px; border: 1px solid #c5c5c5; border-radius: 5px;">
        ${options.map((a) => {
          return `<option>${a}</option>`;
        })}
        </select>
        </div>
        `;
      document.getElementById(value1).value = inp1;
      document.getElementById(value2).value = inp2;
    }
    if (
      selectValue != "range" &&
      selectValue != "in" &&
      this.rules.complex[c.field]["inputType"] == "manuel"
    ) {
      document.getElementById(inputsId).innerHTML = `
        <div style="margin-top: 10px">
        <label style="font-size: 13px;margin-bottom: 8px;display:block;">Dəyər daxil edin</label>
        <input id="${value1}" value="${
        this.rules.complex[c.field]["value"][0]
          ? this.rules.complex[c.field]["value"][0]
          : ""
      }" style="height: 33px; padding: 7px; border: 1px solid #c5c5c5; border-radius: 5px;"/>
        </div>
        `;
    } else if (
      selectValue != "range" &&
      selectValue != "in" &&
      this.rules.complex[c.field]["inputType"] == "select"
    ) {
      document.getElementById(inputsId).innerHTML = `
        <div style="margin-top: 10px">
        <label style="font-size: 13px;margin-bottom: 8px;display:block;">Dəyər daxil edin</label>
        <select id="${value1}" style="width: 100%; padding: 7px; border: 1px solid #c5c5c5; border-radius: 5px;">
        ${options.map((a) => {
          return `<option value="${a}">${a}</option>`;
        })}
        </select>
        </div>
        `;
      document.getElementById(value1).value =
        this.rules.complex[c.field]["value"][0];
    } else if (
      selectValue == "in" &&
      this.rules.complex[c.field]["inputType"] == "manuel"
    ) {
      var tableValues = [];
      if (this.settings.modalSettings.value.length > 0) {
        this.settings.modalSettings.value.map((a) => {
          tableValues.push(a);
        });
      }
      document.getElementById(inputsId).innerHTML = `
        <div style="margin-top: 10px">
        <label style="font-size: 13px;margin-bottom: 8px;display:block;">Dəyər seçin</label>
        <div style="display:flex;">
        <input id="inp" style="padding: 7px; height: 33px; border: 1px solid #c5c5c5; border-radius: 5px; border-top-left-radius: 5px; border-bottom-left-radius: 5px; border-right:none;"/>
        <button style="border: 1px solid #c5c5c5;font-size: 16px;padding: 5px 10px;background:#fff; cursor:pointer; height:33px;" class="add-btn">+</button></div>
        <table style="width: 100%; border-collapse: collapse;margin-top:15px">
        <thead>
        <tr style="border-bottom:2px solid #e4e4e4;">
          <th style="text-align:left; padding:5px;">Dəyər</th>
          <th style="text-align:right; padding:10px 17px 10px 10px; style="color:white; background:#c64d42;">Sil</th>
        </tr>
        </thead>
        <tbody id="${tbodyid}">  
        ${
          tableValues &&
          tableValues.map((x, ij) => {
            return `<tr style="border-bottom:1px solid #e4e4e4;">
          <td style="text-align:left; padding:6px;">${x}</td>
          <td style="text-align:right; padding:6px;"><button data-field="${field}" data-index="${ij}" class="del-btn" style="padding: 7px 13px;border: none;cursor: pointer;background-color:#f14668;color:#fff;border-radius:3px">Sil</button></td>
        </tr>`;
          })
        }
        </tbody>
        </table>
        </div>
        `;
      document
        .getElementsByClassName("add-btn")[0]
        .addEventListener("click", () => {
          if (document.getElementById("inp").value == "") {
            alert("Xananı doldurun");
          } else {
            var newVal = document.getElementById("inp").value;
            this.settings.modalSettings.value.push(newVal);
            var totalInner = "";
            this.settings.modalSettings.value.forEach((x, ij) => {
              totalInner += `<tr style="border-bottom:1px solid #e4e4e4;">
            <td style="text-align:left; padding:6px;">${x}</td>
            <td style="text-align:right; padding:6px;"><button data-field="${field}" data-index="${ij}" class="del-btn" style="padding: 7px 13px;border: none;cursor: pointer;background-color:#f14668;color:#fff;border-radius:3px">Sil</button></td>
            </tr>`;
            });
            document.getElementById(tbodyid).innerHTML = totalInner;
            document.getElementById("inp").value = "";
          }
        });
    } else if (
      selectValue == "in" &&
      this.rules.complex[c.field]["inputType"] == "select"
    ) {
      var tableValues = [];
      if (this.settings.modalSettings.value.length > 0) {
        this.settings.modalSettings.value.map((a) => {
          if (tableValues.indexOf(a) == -1) {
            tableValues.push(a);
          }
        });
      }
      document.getElementById(inputsId).innerHTML = `
        <div style="margin-top: 10px">
        <label style="font-size: 13px;margin-bottom: 8px;display:block;">Dəyər seçin</label>
        <div style="display:flex;"><select id="inp" style="width: 100%; padding: 7px; border: 1px solid #c5c5c5; border-top-left-radius: 5px; border-bottom-left-radius: 5px; border-right:none;">
        ${options.map((a) => {
          return `<option>${a}</option>`;
        })}
        </select><button style="border: 1px solid #c5c5c5;font-size: 16px; height: 33px; padding: 5px 10px;background:#fff;cursor:pointer" class="add-btn" >+</button></div>
        <table style="width: 100%; border-collapse: collapse;margin-top:15px">
        <thead>
        <tr style="border-bottom:2px solid #e4e4e4;">
          <th style="text-align:left; padding:5px;">Dəyər</th>
          <th style="text-align:right; padding:10px 17px 10px 10px;">Sil</th>
        </tr>
        </thead>
        <tbody id="${tbodyid}">  
        ${
          tableValues &&
          tableValues.map((x, ij) => {
            return `<tr style="border-bottom:1px solid #e4e4e4;">
          <td style="text-align:left; padding:6px;">${x}</td>
          <td style="text-align:right; padding:6px;"><button data-field="${field}" data-index="${ij}" class="del-btn" style="padding: 7px 13px;border: none;cursor: pointer;background-color:#f14668;color:#fff;border-radius:3px">Sil</button></td>
        </tr>`;
          })
        }
        </tbody>
        </table>
        </div>
        `;
      document
        .getElementsByClassName("add-btn")[0]
        .addEventListener("click", () => {
          var newVal = document.getElementById("inp").value;
          this.settings.modalSettings.value.push(newVal);
          let totalInner = "";
          this.settings.modalSettings.value.forEach((x, ij) => {
            totalInner += `<tr style="border-bottom:1px solid #e4e4e4;">
            <td style="text-align:left; padding:6px;">${x}</td>
            <td style="text-align:right; padding:6px;"><button data-field="${field}" data-index="${ij}" class="del-btn" style="padding: 7px 13px;border: none;cursor: pointer;background-color:#f14668;color:#fff;border-radius:3px">Sil</button></td>
            </tr>`;
          });
          document.getElementById(tbodyid).innerHTML = totalInner;
        });
    }
    this.addEventToTableItems();
  },
  addEventToTableItems() {
    var deletes = document.getElementsByClassName("del-btn");
    for (var i = 0; i < deletes.length; i++) {
      var a = deletes[i];
      if (a.nodeName == "BUTTON") {
        a.addEventListener("click", (e) => {
          var index = Number(e.target.dataset.index);
          var field = e.target.dataset.field;
          this.deleteTableItems(field, index);
        });
      }
    }
  },
  deleteTableItems(field, index) {
    //debugger;
    var tbodyid = `filter-${this.id}-tbody`;
    var tableItems = this.rules.complex[field].value;
    tableItems.splice(index, 1);
    this.rules.complex[field] = tableItems;
    let totalInner = "";
    tableItems.forEach((x, ij) => {
      totalInner += `<tr style="border-bottom:1px solid #e4e4e4;">
        <td style="text-align:left; padding:6px;">${x}</td>
        <td style="text-align:right; padding:6px;"><button data-field="${field}" data-index="${ij}" class="del-btn" style="padding: 7px 13px;border: none;cursor: pointer;background-color:#f14668;color:#fff;border-radius:3px">Sil</button></td>
        </tr>`;
    });
    document.getElementById(tbodyid).innerHTML = totalInner;
    this.addEventToTableItems();
  },

  closeModal() {
    document.getElementById(`filter-${this.id}-parent`).remove();
    this.settings.modalSettings = {
      type: "like",
      isManuel: true,
      value: [""],
      selections: [],
    };
  },
  clearFilterForm() {
    var { c, f, qt } = this.settings.activeFilter;
    var field = c.field;
    var tables = JSON.parse(localStorage.getItem("tables"));
    delete tables[this.id].rules.complex[field];
    localStorage.removeItem("tables");
    localStorage.setItem("tables", JSON.stringify(tables));
    var butonid = `filter-${this.id}-${field}-filtericon`;
    var buton = document.getElementById(butonid);
    var newbuton = this.getFilterIcon(field, false);
    buton.innerHTML = newbuton.innerHTML;
    this.setLocalStorageToTable();
    this.closeModal();
  },
  addModalTemplate() {
    var { c, f, qt } = this.settings.activeFilter;
    var bg = document.getElementById(`filter-${this.id}-parent`);
    var div = this.CREL("div");
    var formId = `filter-${this.id}-form`;
    var btnCloseId = `filter-${this.id}-btnclose`;
    var btnCancelId = `filter-${this.id}-btncancel`;
    var btnClearId = `filter-${this.id}-btnclear`;
    var btnFilterId = `filter-${this.id}-btnfilter`;

    div.innerHTML = `<div style="position: fixed; top: 75px; background-color: #fff; border-radius: 5px; left: 50%; margin-left: -200px; width: 400px; font-family: sans-serif;">
        <header style="width: 100%; display: flex; border-top-left-radius: 5px; border-top-right-radius: 5px; justify-content: space-between; background-color: #c64d42; color: #fff; padding-left: 10px; padding-right: 4px; height: 50px; align-items: center;">
          <p style="font-size:14px;"><b>${c.title}</b> Sütununda Detayli Filtreleme</p> 
          <button id="${btnCloseId}" style="idth: 32px; height: 32px; background: transparent; border: none; color: #fff; cursor: pointer;">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
              <path fill="currentColor" d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/>
            </svg>
          </button>
        </header> 
        <section style="width: 100%; padding:10px">
          <div style="margin-bottom: 10px;">
            <div style="font-size:13px; margin-bottom: 8px;">Sorğu tipi seçin</div>
            <div id="${formId}"></div>
          </div>
        </section> 
        <footer style="width: 100%; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; background-color: #efefef; padding: 10px; display: flex; justify-content: flex-end;">
          <button id="${btnCancelId}" class="btntabltr" style="color:rgba(0,0,0,.7); background:#fff;">Ləğv</button>
          <button id="${btnClearId}" class="btntabltr" style="color:rgba(0,0,0,.7); background:#fff;">Filtri təmizləyin</button>
          <button id="${btnFilterId}" class="btntabltr" style="color:white; background:#c64d42;">Sorğula</button>
        </footer>
      </div>`;

    bg.appendChild(div);
    var btnclose = document.getElementById(btnCloseId);
    var btncancel = document.getElementById(btnCancelId);
    var btnclear = document.getElementById(btnClearId);
    var btnfilter = document.getElementById(btnFilterId);

    btnclose.addEventListener(
      "click",
      (e) => {
        this.closeModal();
      },
      false
    );
    btncancel.addEventListener(
      "click",
      (e) => {
        this.closeModal();
      },
      false
    );
    btnclear.addEventListener(
      "click",
      (e) => {
        this.clearFilterForm();
      },
      false
    );
    btnfilter.addEventListener(
      "click",
      (e) => {
        // debugger;
        this.filterComplex();
      },
      false
    );

    this.addFormItems();
    var inputsId = `filter-${this.id}-inputs`;
    var value1 = `filter-${this.id}-value1`;
    // document.getElementById(inputsId).innerHTML = `
    //   <div style="margin-top: 10px">
    //   <label style="font-size: 13px;margin-bottom: 8px;display:block;">Dəyər daxil edin</label>
    //   <input id="${value1}" style="width:100%; height: 33px; padding: 7px; border: 1px solid #c5c5c5; border-radius: 5px;"/>
    //   </div>
    //   `
    this.changeInputs();
  },
  addDarkBG() {
    var div = this.CREL("div");
    div.style.position = "fixed";
    div.style.top = 0;
    div.style.left = 0;
    div.style.width = "100%";
    div.style.height = "100%";
    div.style.zIndex = 6;
    div.style.backgroundColor = "rgb(0 0 0 / 40%)";
    div.id = `filter-${this.id}-parent`;
    // debugger;
    var body = document.getElementById("mainbody");
    if (body == null) {
      body = document.body;
    }
    body.appendChild(div);
  },
  CREL(name) {
    return document.createElement(name);
  },
  APPEND(name, el) {
    document.getElementById(name).appendChild(el);
  },
  CLEAR(name) {
    document.getElementById(name).innerHTML = "";
  },
  sort(obj, key, type) {
    var compare = () => {};
    if (type == "asc") {
      compare = (a, b) => {
        if (a !== undefined && b !== undefined) {
          if (a[key] < b[key]) {
            return -1;
          }
          if (a[key] > b[key]) {
            return 1;
          }
        }
        return 1;
      };
    } else {
      compare = (a, b) => {
        if (a !== undefined && b !== undefined) {
          if (a[key] > b[key]) {
            return -1;
          }
          if (a[key] < b[key]) {
            return 1;
          }
        }
        return 1;
      };
    }
    //var newarr = obj.sort(compare());
    return obj;
  },
  setSecretColumns(c, s) {
    if (c !== undefined) {
      return c?.map((a) => {
        if (s.indexOf(a.field) !== -1) {
          a.visible = false;
        }
        return a;
      });
    } else {
    }
  },
  setRowColor(c, s) {
    if (c == undefined) {
      return null;
    }
    var type = s.type;
    var field = s.field;
    return c.map((a) => {
      if (a.field == field) {
        switch (type) {
          case "=": {
            a.formatter = function (cell) {
              var rules = s.rules;
              var data = cell.getData();
              var value = data[field];
              if (rules[value] !== undefined) {
                cell.getRow().getElement().style.backgroundColor = rules[value];
              }
            };
            break;
          }
          case "range": {
            a.formatter = (cell) => {
              var rules = s.rules;
              var min = rules.min;
              var max = rules.max;
              var ranges = rules.ranges;
              ranges = this.sort(ranges, "max", "asc");
              var data = cell.getData();
              var value = Number(data[field]);
              for (var i = 0; i < ranges.length; i++) {
                var r = ranges[i];
                if (value >= min && value <= r.max) {
                  cell.getRow().getElement().style.backgroundColor = r.color;
                  min = r.max;
                  break;
                }
              }
              return value;
            };
            break;
          }
        }
      }
      return a;
    });
  },
  setSettings() {
    if (this.options !== null) {
      if (this.options["id"] !== undefined) {
        this.settings.id = this.options["id"];
      }
      if (this.options["styles"]["simpleFilterInput"] !== undefined) {
        this.settings.styles.ic = this.options.styles.simpleFilterInput;
      }
      if (this.options["styles"]["filterButton"] !== undefined) {
        this.settings.styles.bt = this.options.styles.filterButton;
      }
      if (this.options["uniqueColumn"] !== undefined) {
        this.settings.pk = this.options.uniqueColumn;
      }
      if (this.options["columnSort"] !== undefined) {
        this.settings.columnSort = this.options.columnSort;
      }
      if (this.options["secretColumns"] !== undefined) {
        this.settings.secretColumns = this.options["secretColumns"];
        this.that.options.columns = this.setSecretColumns(
          this.that.options.columns,
          this.options["secretColumns"]
        );
      }
      if (this.options["rowColor"] !== undefined) {
        this.settings.rowColor = this.options["rowColor"];
        this.that.options.columns = this.setRowColor(
          this.that.options.columns,
          this.settings.rowColor
        );
      }
      if (this.options["filters"] !== undefined) {
        this.settings.filters = this.options.filters;
        this.that.options.columns = this.updateFormatter(
          this.that.options.columns,
          this.options.filters
        );
        this.that.options.columns = this.updateFilter(
          this.that.options.columns,
          this.options.filters
        );
      }
      this.setLocalStorageToTable();
    }
  },
  run(options) {
    if (options["id"] !== undefined) {
      this.id = `table-${options["id"]}`;
    } else {
      this.id = `table-${Date.now()}`;
    }
    this.setSettings();
  },
};

Tabulator.prototype.doBest = function (options) {
  this.GL.that = this;
  this.GL.options = options;
  this.GL.run(options);
};

Tabulator.prototype.globalSearch = function (search) {
  //debugger;
  search = search.toLocaleLowerCase();
  if (search !== "") {
    var data = this.getData(true);
    var id = [];
    for (var i = 0; i < data.length; i++) {
      var a = data[i];
      if (id.indexOf(a.id) == -1) {
        for (var j in a) {
          var aranan = `${a[j]}`;
          aranan = aranan.toLocaleLowerCase();
          if (aranan.indexOf(search) !== -1) {
            id.push(a.id);
            break;
          }
        }
      }
    }
    if (id.length == 0) {
      id = [-1];
    }
    this.setFilter([{ field: "id", type: "in", value: id }]);
  } else {
    this.setFilter([]);
  }
};

export default Tabulator;
