import React from "react";
import { Input } from "antd";
import clsx from "clsx";
import { getIn } from "formik";

import FormErrorHandler from "./FormErrorHandler";

const TextareaComponent = (props) => {
  const { TextArea } = Input;

  const formik = props.formik;
  const field = props.field;
  const label = props.label;
  const rows = props.rows;
  const required = props.required ?? false;
  const visibilty = props.visibilty ?? true;
  const placeholder = props.placeholder;

  const infoError = getIn(formik?.errors?.info, field);
  const infoTouched = getIn(formik?.touched?.info, field);
  const classes = clsx(infoError && infoTouched && "error");

  return (
    <div
      style={{ display: visibilty === true ? "block" : "none" }}
      className="input-box"
    >
      <span className="input-box__title">
        {label}{" "}
        {required === true ? <span style={{ color: "red" }}>*</span> : null}
      </span>
      <TextArea
        autoSize
        rows={rows}
        className={classes}
        {...formik?.getFieldProps(`info.${field}`)}
        placeholder={placeholder}
      />
      <FormErrorHandler formik={formik} field={`info.${field}`} />
    </div>
  );
};

export default TextareaComponent;
