import "./PoolAttachedModal.scss";

import React, { useState, useEffect } from "react";
import { Table, Modal, Button, Skeleton } from "antd";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import Risks from "models/Risks";
import GL from "libs/GL";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function PoolAttachedModal({
  isModalOpen,
  setIsModalOpen,
  riskId,
  itemId,
  onSetItemId,
  isDisabled,
}) {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const { activeRole } = useSelector(({ user }) => ({
    activeRole: user.data.roles?.find(({ status }) => status),
  }));
  // ! TABLE PROPS ===========================

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const columns = [
    {
      title: "Ad",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Növü",
      dataIndex: "fileType",
      key: "fileType",
    },
    {
      title: "Kateqoriya",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Tarixi",
      dataIndex: "uploadDate",
      key: "uploadDate",
      render: (_, rowData) =>
        rowData.uploadDate ? GL.datetimeToString(rowData.uploadDate) : "",
    },
    {
      title: "Yükləyən",
      dataIndex: "uploader",
      key: "uploader",
    },
    {
      title: "Təsviri",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Əməliyyat",
      dataIndex: "operations",
      key: "operations",
      width: 140,
      render: (_, rawData) => {
        return (
          <div>
            {/* <Button type="text" style={{ padding: 5 }}>
              <EditOutlined />
            </Button> */}
            <Button
              type="text"
              style={{ padding: 5 }}
              onClick={() => downloadFile(rawData.url)}
            >
              <DownloadOutlined />
            </Button>
            <Button
              type="text"
              style={{ padding: 5 }}
              onClick={() => deleteFile(rawData.id)}
              disabled={isDisabled}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const [pagination, setPagination] = useState(1);

  const handlePaginationChange = (pagination, filters, sorter) => {
    // Update the state with new pagination information

    setPagination(pagination);
  };
  // ! TABLE PROPS ===========================

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    onSetItemId && onSetItemId(null);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    onSetItemId && onSetItemId(null);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getRiskFiles = (riskId, itemId) => {
    setIsLoading(true);
    Risks.getRiskFiles(riskId, itemId, (data) => {
      setData(data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getRiskFiles(riskId, itemId);
  }, [riskId, itemId]);

  function downloadFile(url) {
    if (!url) return;
    var a = document.createElement("a");
    a.href = url;
    a.click();
  }

  function deleteFile(itemId) {
    GL.yesNo(t("risk_files_delete_warning"), (isDelete) => {
      if (!isDelete) return;
      Risks.deleteRiskFile(
        riskId,
        itemId,
        activeRole.id,
        () => {
          const updateRiskFiles = data?.filter(({ id }) => id !== itemId);
          setData(updateRiskFiles);
          GL.notify.success({ text: t("risk_files_delete_success") });
        },
        () => {
          GL.notify.error({ text: t("answer_unsuccess") });
        }
      );
    });
  }
  return (
    <div className="PoolAttachedModal">
      <Modal
        title="Qoşmadakılar"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={1100}
        bodyStyle={{ height: 500, marginTop: 20 }}
        className="PoolAttachedModal"
      >
        <div className="table-toolbar">
          {selectedRows.length ? (
            <div className="buttons-wrapper">
              <button type="text" className="table-toolbar__button download">
                <DownloadOutlined />
              </button>
              <button type="text" className="table-toolbar__button delete">
                <DeleteOutlined />
              </button>
            </div>
          ) : null}
        </div>
        {isLoading && <Skeleton />}
        <Table
          dataSource={data}
          columns={columns}
          bordered
          rowSelection={rowSelection}
          pagination={{
            // total: 100,
            // current: 2,
            // size: 2,
            // position: ["bottomCenter"],

            // !==============
            // current: pagination,
            position: ["bottomCenter"],
            pageSize: 5,
            // onChange: handlePaginationChange,
            // !==============
          }}
          // scroll={{
          //   y: 400,
          // }}
          // style={{
          //   background: "red",
          //   display: "flex",
          //   flexDirection: "column",
          //   justifyContent: "space-between",
          //   height: "600px",
          // }}
        />
      </Modal>
    </div>
  );
}

export default PoolAttachedModal;
