import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import { Menu } from "antd";
import {
  FundOutlined,
  BankOutlined,
  TeamOutlined,
  UserOutlined,
  GlobalOutlined,
  SwapOutlined,
  ExceptionOutlined,
  ProfileOutlined,
  SelectOutlined,
  SmallDashOutlined,
  UserAddOutlined,
  SettingOutlined,
  RightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import clsx from "clsx";

import { GetMenuList, getSimplePermissions } from "hooks/permissions";
import { menuIdList, mainMenuJSON } from "const/menuJSON";
import { logOut } from "redux/actions";

const { SubMenu } = Menu;

const MenuList = () => {
  const [openKeys, setOpenKeys] = useState([]);
  const [isOpenSettings, setIsOpenSettings] = useState(false);

  const onOpenSettings = () => setIsOpenSettings((isOpen) => !isOpen);

  let menuItems = GetMenuList();
  const simplePermissions = getSimplePermissions();
  const rootSubmenuKeys = menuIdList;

  /*const getALlMyList = () => {
    MyList.LIST((response) => {
      const subList = response?.map((subItem) => ({
        id: uuid(),
        name: subItem.name,
        group: 21,
        permission: 1,
        visibilty: true,
        route: `/my-list/${subItem.id}`,
      }));
      const findMenuItemIndex = menuItems?.findIndex(
        (menuItem) => menuItem.id === "50"
      );
      if (findMenuItemIndex < 0) return;
      const menuItem = menuItems[findMenuItemIndex];
      const findSublistIndex = menuItem?.sub?.findIndex(
        (listItem) => listItem.id === "54"
      );
      if (findSublistIndex < 0) return;
      menuItem.sub[findSublistIndex].sub.concat(subList);
    });
  };

  useEffect(() => {
    getALlMyList();
  }, []);*/

  const menuIcons = {
    audit: <FundOutlined />,
    bank: <BankOutlined />,
    global: <GlobalOutlined />,
    team: <TeamOutlined />,
    user: <UserOutlined />,
    flow: <SwapOutlined />,
    riskList: <ExceptionOutlined />,
    list: <ProfileOutlined />,
    custom: <SelectOutlined />,
    other: <SmallDashOutlined />,
    profile: <UserAddOutlined />,
  };

  const onOpenChange = (openKeysList) => {
    const latestOpenKey = openKeysList.find(
      (key) => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeysList);
    } else {
      const opens = latestOpenKey ? [latestOpenKey] : [];
      setOpenKeys(opens);
    }
  };

  useEffect(() => {
    let exp = localStorage.getItem("exp");
    let logtime = localStorage.getItem("now");
    let expDate = parseInt(logtime) + parseInt(exp);
    let time0ut = expDate - Date.now();
    if (time0ut <= 0) {
      logOut();
    }
  }, [Date.now()]);

  const getSingle = (item) => {
    return (
      item.visibilty && (
        <Menu.Item key={item.id}>
          <Link to={item.route}>
            {menuIcons[item.icon]}
            <span>{item.name}</span>
          </Link>
        </Menu.Item>
      )
    );
  };

  const getMenu = (item) => {
    item.visibilty = false;
    if (simplePermissions[item.group] !== undefined) {
      if (simplePermissions[item.group].indexOf(item.permission) !== -1) {
        item.visibilty = true;
      }
    } else {
      item.visibilty = true;
    }

    if (item["sub"] !== undefined) {
      if (item.sub.length > 0) {
        return (
          item.visibilty && (
            <SubMenu
              key={item.id}
              title={
                <span>
                  {menuIcons[item.icon]}
                  <span>{item.name}</span>
                </span>
              }
            >
              {item.sub.map((sub) => {
                return getMenu(sub);
              })}
            </SubMenu>
          )
        );
      } else {
        return getSingle(item);
      }
    } else {
      return getSingle(item);
    }
  };

  return (
    <div className="w-100 h-100 position-relative overflow-hidden">
      <Menu
        openKeys={openKeys}
        // inlineCollapsed={collapsed}
        mode="inline"
        theme="light"
        color="black"
        onOpenChange={onOpenChange}
        className="menu-ul"
      >
        {mainMenuJSON.map((menuItem) => {
          return getMenu(menuItem);
        })}
      </Menu>
      <div
        className={clsx("SettingsBackdrop", isOpenSettings && "isOpenBackdrop")}
        onClick={onOpenSettings}
      ></div>
      <div className={clsx("Settings", isOpenSettings && "isOpenSettings")}>
        <div className="SettingsTitle" onClick={onOpenSettings}>
          <h5 className="d-flex align-items-center">
            <SettingOutlined /> Sazlamalar
          </h5>
          {isOpenSettings ? (
            <DownOutlined className="SettingsTitleIcon" />
          ) : (
            <RightOutlined className="SettingsTitleIcon" />
          )}
        </div>
        <div className="SettingsMenu">
          <Menu
            openKeys={openKeys}
            // inlineCollapsed={collapsed}
            mode="inline"
            theme="light"
            color="black"
            onOpenChange={onOpenChange}
            className="menu-ul"
          >
            {menuItems.map((menuItem) => {
              return getMenu(menuItem);
            })}
          </Menu>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ edit }) => {
  return { edit };
};

export default connect(mapStateToProps, { logOut })(MenuList);
