/* eslint-disable */
import { Modal, Button } from "antd";
import React, { useEffect, useState } from "react";

import { apiRoutes } from "const/apiroutes";
import API from "const/API2";
import GL from "libs/GL";
import Papa from 'papaparse';
import { useTranslation } from "react-i18next";


function CsvModal({ showModal, setShowModal, csvModalData, scenarioCode }) {
  const { t } = useTranslation();
  debugger;
  const api = new API();

  const [params, setParams] = useState([]);

  useEffect(() => {
    api.getData(
      `${apiRoutes.parameters.search}${csvModalData}`,
      (data) => {
        var template = {};
        data.map((a)=>{
            template[a.code]=''; 
        });
        setParams([template])
        console.log("parm list ==>", data);
      },
      (err) => {
        console.log("err ==>", err);
      }
    );
  }, []);

  const downloadTemplate = ()=>{
    GL.jsonToCSV(params,`scenario-${csvModalData}`);
  }

  const uploadTestData=()=>{
    var inputFile = document.createElement('input');
    inputFile.type="file";
    inputFile.accept='.csv';
    inputFile.addEventListener('change',(file)=>{
        debugger;
        var filecsv = file.target.files[0];
        var reader = new FileReader();
        reader.onload = function(event) {
            var csvData = event.target.result;
            Papa.parse(csvData, {
                header: true, 
                complete: function(results) {
                  results.data.pop();
                  var csv = results.data;
                  api.postData(`engine/${scenarioCode}`,csv,(gelen)=>{
                    GL.notify.success({ text: t("answer_success") });
                  })
                }
              });
        }
        reader.readAsText(filecsv);

    });
    inputFile.click();
  }

  return (
    <Modal
      //   title="csv"
      bodyStyle={{
        height: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      //   width={400}
      open={showModal}
      onOk={() => setShowModal(false)}
      onCancel={() => setShowModal(false)}
      footer={[]}
    >
      <div
        className="risk-page"
        style={{
          marginTop: "16px",
          paddingBottom: "0",
        }}
      >
        <div
          className="risk-page__elements"
          style={{ width: "100%", display: "flex", gap: "16px" }}
        >
          <div>
            <Button onClick={uploadTestData} type={"primary"}>Test üçün CSV faylı yüklə</Button>
          </div>
          <div>
            <Button onClick={downloadTemplate} type={"primary"}>Template CSV faylı köçür</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CsvModal;
