import React, { useState, useMemo } from "react";
import { Row, Col, Skeleton, Button, Space, Table } from "antd";
import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import Textarea from "components/Elements/FileUploadForm/Form/Textarea";
import SelectBox from "components/Elements/FileUploadForm/Form/SelectBox";
import Comments from "components/Elements/Comments/Comments";
import Risks from "models/Risks";
import GL from "libs/GL";
import { useTranslation } from "react-i18next";

const Info = ({ formik, isLoading }) => {
  const { t } = useTranslation();
  const WORKFLOW_LIST = [
    { id: 1, name: t("scenario_workflow") },
    { id: 2, name: t("risk_sperate_rule") },
  ];
  const values = formik?.values?.info || {};

  function getRiskFullData() {
    Risks.getRiskFullDataById(values.id, (data) => {
      var columns = [
        { title: "Acar", dataIndex: "title", key: "title" },
        { title: "Deger", dataIndex: "value", key: "value" },
      ];
      var d = [];
      var mask = {
        eqfPresenterCompany: "e-QF təqdim edənin Vergi orqanı",
        eqfPresenterName: "e-QF təqdim edənin Adı",
        eqfPresenterCreatedDate: "e-QF təqdim edənin VÖEN yaranma tarixi",
        eqfPresenterTin: "e-QF təqdim edənin VÖEN",
        eqfPresenterStatus: "e-QF təqdim edənin vəziyyəti",
        eqfReceiverCompany: "e-QF əldə edənin Vergi orqanı",
        eqfreceiverName: "e-QF əldə edənin Adı",
        eqfReceiverCreatedDate: "e-QF əldə edənin VÖEN yaranma tarixi",
        eqfReceiverTin: "e-QF əldə edənin VÖEN",
        eqfReceiverStatus: "e-QF əldə edənin vəziyyəti",
        productName: "Malın Adı",
        productAmountType: "Malın Ölçü vahidi",
        productAmount: "Malın Miqdarı",
        exciseAmount: "Aksiz məbləği",
        edvOperatedAmount: "ƏDV-yə cəlb edilən əməliyyatların məbləği",
        edvNotOperatedAmount: "ƏDV-yə cəlb edilməyən əməliyyatların məbləği",
        edvFreeOperatedAmount: "ƏDV-dən azad olunan əməliyyatların məbləği",
        edvZeroOperatedAmount:
          'ƏDV-yə "0" dərəcə ilə cəlb edilən əməliyyatların məbləği',
        edvAmount: "ƏDV məbləği",
        wayTax: "Yol vergisi",
        finalAmount: "Yekun məbləğ",
        eqlPresenterTurnover: "e-QF təqdim edənin ümumi dövriyyə",
        product: "Mal / Xidmət",
        risk: "e-QF təqdim edənin risk statusu",
        einvoiceType: "e-Qaimənin Növü",
        einvoiceUpdateStatus: "e-Qaimənin Yenilənmə statusu",
        einvoiceAcceptDate: "e-Qaimənin Təsdiq tarixi",
        einvoiceSeria: "e-Qaimənin Seriyası",
        einvoiceNumber: "e-Qaimənin Nömrəsi",
        einvoiceDate: "e-Qaimənin Tarixi",
      };

      for (var i in data) {
        var title = i.toLocaleUpperCase();
        if (mask[i] !== undefined) {
          title = mask[i];
        }
        d.push({
          key: i,
          value: data[i],
          title: title,
        });
      }
      GL.infoSingleModal(
        t("risk_large_info"),
        <div
          className="scroll-style"
          style={{ maxHeight: "700px", overflowY: "scroll" }}
        >
          <Table dataSource={d} columns={columns} pagination={false} />
        </div>
      );
    });
  }

  if (isLoading) return <Skeleton active />;

  return (
    <div className="risk-page">
      <h1 className="risk-page__title">{t("global_info")}</h1>
      <div className="risk-page__elements">
        <Row gutter={24}>
          <Col sm={24} md={8} lg={8}>
            <InputBox
              label="ID"
              formik={formik}
              field="id"
              type="number"
              required={false}
              visibilty={true}
              disabled={true}
              isFake={true}
            />
            <InputBox
              label={t("scenario")}
              formik={formik}
              field="name"
              type="text"
              required={false}
              visibilty={true}
              disabled={true}
              isFake={true}
            />
            <InputBox
              label={t("separating_cari")}
              formik={formik}
              field="status"
              type="text"
              required={false}
              visibilty={true}
              disabled={true}
              isFake={true}
            />
            <InputBox
              label={t("director")}
              formik={formik}
              field="executorFullName"
              type="text"
              required={false}
              visibilty={true}
              disabled={true}
              isFake={true}
            />
            <InputBox
              label={t("voen")}
              formik={formik}
              field="taxId"
              type="text"
              required={false}
              visibilty={true}
              disabled={true}
              isFake={true}
            />
            {/* 
            <InputBox
              label="Fəaliyyət növü"
              formik={formik}
              field="code"
              type="text"
              required={false}
              visibilty={true}
              disabled={true}
              isFake={true}
            /> */}

            <InputBox
              label={t("separating_precedent")}
              formik={formik}
              field="precedent"
              type="text"
              required={false}
              visibilty={true}
              disabled={true}
              isFake={true}
            />
            <InputBox
              label={t("risk_type")}
              formik={formik}
              field="riskType"
              type="text"
              required={false}
              visibilty={true}
              disabled={true}
              isFake={true}
            />
          </Col>
          <Col sm={24} md={8} lg={8}>
            <InputBox
              label={t("risk_group")}
              formik={formik}
              field="riskGroup"
              type="text"
              required={false}
              visibilty={true}
              disabled={true}
              isFake={true}
            />
            <InputBox
              label={t("createdat")}
              formik={formik}
              field="createdAt"
              type="text"
              required={false}
              visibilty={true}
              disabled={true}
              isFake={true}
            />
            <InputBox
              label={t("global_lastexecute")}
              formik={formik}
              field="lastExecuteDate"
              type="text"
              required={false}
              visibilty={true}
              disabled={true}
              isFake={true}
            />
            <InputBox
              label={t("global_kurator")}
              formik={formik}
              field="curator"
              type="text"
              required={false}
              visibilty={true}
              disabled={true}
              isFake={true}
            />
            <SelectBox
              label={t("global_division")}
              formik={formik}
              field="division"
              data={WORKFLOW_LIST}
              disabled={true}
            />
            <Textarea
              label={t("global_qeyd")}
              formik={formik}
              field="note"
              type="text"
              required={false}
            />
          </Col>
          <Col sm={24} md={8} lg={8}>
            <SelectBox
              mode="tags"
              label={t("global_tags")}
              formik={formik}
              field="tags"
              data={values.tags}
            />
            <Comments riskComments={values?.riskComments} riskFormik={formik} />
            <Space
              size="large"
              direction="vertical"
              style={{ width: "100%", paddingTop: "24px" }}
            >
              <Button
                type="primary"
                onClick={getRiskFullData}
                style={{
                  width: "100%",
                }}
              >
                Risk məlumatlarına detallı bax
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Info;
