import React from "react";

const Export = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <g clipPath="url(#clip0_1776_1843)">
        <path
          d="M3.83301 14.1667V15.8334C3.83301 16.2754 4.0086 16.6993 4.32116 17.0119C4.63372 17.3244 5.05765 17.5 5.49967 17.5H15.4997C15.9417 17.5 16.3656 17.3244 16.6782 17.0119C16.9907 16.6993 17.1663 16.2754 17.1663 15.8334V14.1667"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.33301 7.49998L10.4997 3.33331L14.6663 7.49998"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 3.33331V13.3333"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1776_1843">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Export;
