import React, { Fragment } from "react";
import { Space, Button, Typography } from "antd";
const { Title } = Typography;
import { useState } from "react";
import { useSelector } from "react-redux";
import user from "../../../assets/profil.svg";
import { fileTypeIconsEnum } from "const/fileTypeIconsEnum";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { fileTypeDownloadEnum } from "const/fileTypeDownloadEnum";
import Watermark from "@uiw/react-watermark";
import { Modal } from "antd";
import GL from "libs/GL";
dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);

let fullDateFormatType = "DD-MM-YYYY HH:mm";

const MailDisplaySkeleton = ({ mail }) => {
  // ~~ WATERMARK IMPLEMENTATION
  const [showModal, setShowModal] = useState(false);
  const currentUserName = useSelector((state) => state.user.data.user.name);
  const currentUserId = useSelector((state) => state.user.data.user.id);

  const [frame, setFrame] = useState("");

  const closeModal = () => setShowModal(false);

  // ! getPdf() Here we change original url to blob url. Because if we use original url, the server responds with 403 (Forbidden error)
  const getPdf = (url) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const documentUrl = url;

    fetch(documentUrl, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        setFrame(
          <iframe
            src={`${blobUrl}#toolbar=0`}
            width="100%"
            height={screen.height - 300}
          />
        );
      })
      .catch((error) => console.log("Error fetching document:", error));
  };

  // ! getImg() and getDoc() methods must be seperate in table component. Otherwise it won't rerender.
  const getImg = (url) => {
    setFrame(
      <img src={url} className="modal-image" width="100%" height="auto" />
    );
  };

  const getDoc = (url) => {
    // ~ Embed google component for displaying xls and doc files
    setFrame(
      <iframe
        width="100%"
        height={screen.height - 300}
        src={`https://docs.google.com/viewer?url=${encodeURIComponent(
          url
        )}&embedded=true`}
      >
        {" "}
      </iframe>
    );
  };

  // Date properties
  const newDate = new Date();
  const date = `${newDate.getDate()}.${newDate.getMonth()}.${newDate.getFullYear()}`;
  const time = `${newDate.getHours()}:${newDate.getMinutes()}:${newDate.getSeconds()}`;

  const documentFetchers = {
    pdf: getPdf,
    png: getImg,
    jpg: getImg,
    jpeg: getImg,
    svg: getImg,
    xls: getDoc,
    xlsx: getDoc,
    doc: getDoc,
    docx: getDoc,
  };

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        position: "relative",
      }}
    >
      <Space
        direction="horizontal"
        size="middle"
        style={{
          marginLeft: "30px",
          paddingTop: "15px",
          justifyContent: "space-between",
        }}
      >
        <Space direction="horizontal" size="middle">
          <img src={user} alt="user icon" />
          <Space direction="vertical" size="small">
            <Title style={{ fontSize: "14px" }}>
              {" "}
              <span style={{ fontWeight: "600" }}>Kimdən:</span>{" "}
              {mail.from.name} ({mail.from.email})
            </Title>
            <Title style={{ fontSize: "14px" }}>
              <span style={{ fontWeight: "600" }}>Kimə:</span>{" "}
              {mail.users.map((to, index) => (
                <span>
                  {to.name} ({to.email}){" "}
                  {index !== mail.users.length - 1 ? ", " : ""}
                </span>
              ))}
            </Title>
          </Space>
        </Space>

        <Space style={{ fontSize: "14px", marginRight: "30px" }}>
          {dayjs(mail.sendTime).format(fullDateFormatType)}
        </Space>
      </Space>

      <Space
        direction="vertical"
        size="large"
        style={{
          padding: "30px",
          borderTop: "1px solid #EBEDF0",
          borderBottom: "1px solid #EBEDF0",
        }}
      >
        <Title style={{ fontSize: "16px", fontWeight: "400", height: "auto" }}>
          {mail.type === "Reply" && (
            <span style={{ fontWeight: "600", color: "#1C2940" }}>Cavab: </span>
          )}

          {mail.type === "Forward" && (
            <span style={{ fontWeight: "600", color: "#1C2940" }}>
              Yönləndirilmiş:{" "}
            </span>
          )}
          {mail.content}
        </Title>
        {mail.files.length > 0 && (
          <Space
            direction="vertical"
            style={{
              borderTop: "1px solid #EBEDF0",
              borderBottom: "1px solid #EBEDF0",
              padding: "30px 0px",
            }}
          >
            <Title style={{ fontSize: "16px" }}>Fayllar</Title>
            <Space direction="horizontal">
              {mail.files.map((file) => (
                <Fragment>
                  <Button
                    shape="round"
                    key={file.fileId}
                    style={{
                      marginRight: "5px",
                      border: "1px solid #B6C0D0",
                    }}
                    onClick={() => {
                      setShowModal(true);
                      // Select which function we will call according to file type
                      const mimeType = fileTypeDownloadEnum[file.type];
                      if (documentFetchers[mimeType]) {
                        documentFetchers[mimeType](file.url);
                      }
                    }}
                  >
                    <Space direction="horizontal" size="small">
                      <img
                        src={
                          fileTypeIconsEnum[file.type] ||
                          fileTypeIconsEnum.default
                        }
                        alt=""
                      />
                      <span> {file.name}</span>
                    </Space>
                  </Button>

                  <Modal
                    destroyOnClose
                    open={showModal}
                    title="Fayl"
                    onCancel={closeModal}
                    onOk={closeModal}
                  >
                    <Watermark
                      content={[
                        `${currentUserId} ${currentUserName},${date},${time}`,
                      ]}
                      rotate={20}
                      gapX={60}
                      gapY={100}
                      fontSize={10}
                      fontColor="#979797"
                      style={{ background: "#fff" }}
                    >
                      {frame}
                    </Watermark>
                  </Modal>
                </Fragment>
              ))}
            </Space>
          </Space>
        )}
      </Space>
    </Space>
  );
};

export default MailDisplaySkeleton;
