import React, { createContext, useContext, useReducer } from "react";
import { useEffect } from "react";
import { MessengerModel } from "./model/MessengerModel";

const initialState = {
  toggleChat: false,
  toggleInfo: false,
  users: null,
  selectedUser: false,
  selectedItem: null,
  info: false,
  loading: false,
  isRead: false,
  isRecentOpen: true,
  isGroupOpen: false,
  isContactOpen: false,
  message: "",
  messageReceived: null,
  room: "",
  groups: [],
  contacts: [],
  messagesFromAPI: [],
  onClickId: null,
  onClickGroup: false,
};

const AppContext = createContext(initialState);

export const useAppContext = () => useContext(AppContext);

function appReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_CHAT":
      return { ...state, toggleChat: action.payload };
    case "TOGGLE_INFO":
      return { ...state, toggleInfo: action.payload };
    case "SET_USERS":
      return { ...state, users: action.payload };
    case "SET_SELECTED_USER":
      return { ...state, selectedUser: action.payload };
    case "SET_SELECTED_ITEM":
      return { ...state, selectedItem: action.payload };
    case "SET_INFO":
      return { ...state, info: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_IS_READ":
      return { ...state, isRead: action.payload };
    case "SET_IS_RECENT_OPEN":
      return { ...state, isRecentOpen: action.payload };
    case "SET_IS_GROUP_OPEN":
      return { ...state, isGroupOpen: action.payload };
    case "SET_IS_CONTACTS_OPEN":
      return { ...state, isContactOpen: action.payload };
    case "SET_MESSAGE":
      return { ...state, message: action.payload };
    case "SET_MESSAGE_RECEIVED":
      return { ...state, messageReceived: action.payload };
    case "SET_ROOM":
      return { ...state, room: action.payload };
    case "SET_GROUPS":
      return { ...state, groups: action.payload };
    case "SET_CONTACTS":
      return { ...state, contacts: action.payload };
      case "SET_CLICK_ID":
      return { ...state, onClickId: action.payload };
      case "SET_CLICK_GROUP":
        return { ...state, onClickGroup: action.payload };
    case "SET_MESSAGES_FROM_API":
      return { ...state, messagesFromAPI: action.payload };
    default:
      return state;
  }
}

export function AppProvider({ children }) {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const handleItemClick = (index) => {
    dispatch({ type: "SET_SELECTED_ITEM", payload: index });
  };

  useEffect(() => {
    MessengerModel.getChats((users) => {
      dispatch({ type: "SET_USERS", payload: users });
    });
  }, []);

  const contextValue = {
    state,
    dispatch,
    handleItemClick,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}
