import React, { useEffect, useMemo, useState } from "react";
import {
  GetPagePermission,
  GetTableSettings,
} from "../../../hooks/permissions";
import GL from "../../../libs/GL";
import SuperTable from "../../Elements/Tabulator/SuperTable";
import { useHistory } from "react-router-dom";
import { Skeleton, Button } from "antd";
import Enum from "../../../models/Enum";
import LanguagesModel from "../../../models/Languages";
import EmptyPage from "../../Elements/Skelethon/EmptyPage";
import { useTranslation } from "react-i18next";

const pageId = "languages";

function Languages(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [languageList, setLanguagesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const permissions = GetPagePermission(23);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );

  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {
        history.push({
          pathname: "languages/create",
          state: {
            id: data.id,
            method: "edit",
          },
        });
      },
    },
    delete: {
      status: permissions["delete"],
      click: function (data) {
        GL.yesNo(t("question_deleting"), (isOk) => {
          if (isOk) {
            LanguagesModel.DELETE(data.id, () => {
              getLanguagesList();
            });
          }
        });
      },
    },
    show: {
      status: false,
      click: function (data) {},
    },
    copy: {
      status: false,
      click: function (data) {},
    },
  };
  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/enums/add",
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/enums/multiDelete",
      },
    },
    settings: {
      id: pageId,
      type: "data",
      data: languageList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "+ " + t("global_create", { name: t("lang_word") }),
      createNewRoute: "languages/create",
      state: {
        method: "create",
      },
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 70,

        formatter: function (cell) {
          return GL.setSelectStatus(cell);
        },
      },
      {
        title: t("global_code"),
        field: "code",
      },
      {
        title: t("global_lang_az"),
        field: "az",
      },
      {
        title: t("global_lang_tr"),
        field: "tr",
      },
      {
        title: t("global_lang_ru"),
        field: "ru",
      },
      {
        title: t("global_lang_en"),
        field: "en",
      },

      {
        title: t("global_operations"),
        field: "operations",
        width: 130,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        code: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        az: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        tr: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        ru: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        en: {
          dataType: "text",
          placeholder: t("global_search"),
        },
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {},
    },
  };

  const downloadLang = (l) => {
    LanguagesModel.GET_LANG(l, (data) => {
      debugger;
      const json = JSON.stringify(data, null, 2);
      const blob = new Blob([json], { type: "application/json;charset=utf-8" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = l + ".json";
      a.click();
      URL.revokeObjectURL(url);
    });
  };

  const getLanguagesList = () => {
    setLoading(true);
    LanguagesModel.LIST((data) => {
      setLanguagesList(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getLanguagesList();
  }, []);

  if (loading) {
    return <Skeleton active />;
  } else {
    if (languageList.length == 0) {
      return (
        <EmptyPage
          route="languages/create"
          name={t("language_settings")}
          state={options.settings.state}
        />
      );
    } else {
      return (
        <div style={containerStyle}>
          <div className="main-layout-wrapper__head">
            <div className="simple-head">
              <h1>{t("language_settings")}</h1>
              <span>{t("language_desc")}</span>
            </div>
          </div>
          <SuperTable data={options} />
          <div style={{ width: "100%", marginTop: "15px", textAlign: "right" }}>
            <Button
              onClick={downloadLang.bind(this, "az")}
              style={{ marginLeft: "10px" }}
            >
              AZ {t("global_download")}
            </Button>
            <Button
              onClick={downloadLang.bind(this, "tr")}
              style={{ marginLeft: "10px" }}
            >
              TR {t("global_download")}
            </Button>
            <Button
              onClick={downloadLang.bind(this, "ru")}
              style={{ marginLeft: "10px" }}
            >
              RU {t("global_download")}
            </Button>
            <Button
              onClick={downloadLang.bind(this, "en")}
              style={{ marginLeft: "10px" }}
            >
              EN {t("global_download")}
            </Button>
          </div>
        </div>
      );
    }
  }
}

export default Languages;
