import "./XmlModal.scss";

import React from "react";
import { Modal, Input } from "antd";

function JsonModal(props) {
  const { open, setOpen, title, formik, formikPath, isError, error } = props;
  const { TextArea } = Input;

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      width={1000}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      title={title}
    >
      <TextArea
        autoSize
        rows={20}
        className={isError ? "error" : ""}
        {...formik.getFieldProps(formikPath)}
      />
      <div className="error-field">{isError ? error : ""}</div>
    </Modal>
  );
}

export default JsonModal;
