import ClosableTabs from "components/Elements/ClosableTabs/ClosableTabs";
import Default from "./Default/Default";
import Pool from "./Pool/Pool";
import React from "react";
import RiskReview from "./RiskReview/RiskReview";
import Executives from "./Executives/Executives";
import Resend from "./Resend/Resend";

function Home() {
  const TabComponentEnum = {
    default: Default,
    unallocatedRisks: Pool,
    riskReview: RiskReview,
    risksInExecution: Executives,
    returnRisks: Resend,
  };

  return (
    <div className="main-layout-wrapper">
      <ClosableTabs
        reduxName="home"
        className="remove-relative"
        TabComponentEnum={TabComponentEnum}
      />
    </div>
  );
}

export default Home;
