import React from "react";
import ExecuteDetail from "./ExecuteDetail";

const Executives = ({ addNewTab, addTabWithSubTab, deleteTab }) => {
  return (
    <ExecuteDetail
      addNewTab={addNewTab}
      addTabWithSubTab={addTabWithSubTab}
      deleteTab={deleteTab}
      pageId="tab-execute"
    />
  );
};

export default Executives;
