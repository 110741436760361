import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col } from "antd";
import PageTitle from "../../Elements/PageTitle/PageTitle";
import FormSubmit from "../../Elements/FormSubmit/FormSubmit";
import { useHistory } from "react-router-dom";
import GL from "../../../libs/GL";
import InputBox from "../../Elements/FileUploadForm/Form/InputBox";
import SelectBox from "../../Elements/FileUploadForm/Form/SelectBox";
import RiskGroup from "../../../models/RiskGroup";
import { useDispatch, useSelector } from "react-redux";
import { setRiskGroupData } from "../../../redux/actions";
import { riskGroup as initialData } from "../../../const/initialDatas";
import { useTranslation } from "react-i18next";
function RiskGroupsEdit(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [parentList, setParentList] = useState([]);
  var state = props.location.state;

  const dispatch = useDispatch();
  const reduxData = useSelector(state => state.riskGroup);
  if (state === undefined) {
    state = reduxData;
  }

  let initialValues;

  const { method, ...stateRest } = state;
  if (state.method === "create") {
    if (reduxData !== undefined) {
      if (reduxData.method === undefined) {
        reduxData.method = "create";
      }
      initialValues = reduxData;
    } else {
      initialValues = stateRest;
    }
  }
  if (state.method === "edit") {
    initialValues = stateRest;
  }
  if (state.method === "copy") {
    initialValues = stateRest;
    state.method = "create";
  }

  const populateList = () => {
    RiskGroup.getRiskGroupList(data => {
      setParentList(data);
    });
  };
  useEffect(() => {
    formik.setFieldTouched("setFormikTouched");
    populateList();
  }, []);

  var submitSettings = {
    type: state.method,
    save: async () => {
      if (state.info.id === 0) {
        var sendData = await GL.getFormikData(formik, [
          "name",
          "totalName",
          "code",
          "parentId"
        ]);

        RiskGroup.CREATE(sendData, res => {
          GL.notify.success({ text: t("answer_success") });
          dispatch(setRiskGroupData(stateRest));
          formik.setFieldValue(stateRest);
          history.push("/risk-groups");
        });
      }
    },
    edit: async () => {
      if (state.info.id !== 0) {
        var sendData = await GL.getFormikData(formik, [
          "id",
          "name",
          "totalName",
          "code",
          "parentId"
        ]);
        RiskGroup.UPDATE(sendData, res => {
          formik.setFieldValue(stateRest);
          dispatch(setRiskGroupData(stateRest));
          GL.notify.success({ text: t("answer_success") });
          history.push("/risk-groups");
        });
      }
    },
    cancel: () => {
      setRiskGroupData(JSON.parse(JSON.stringify(initialData)));
      history.push("/risk-groups");
    }
  };

  const postLoginSchema = Yup.object().shape({
    info: Yup.object().shape({
      id: Yup.string().nullable(),
      name: Yup.string().required(t("yup_name")),
      totalName: Yup.string().required(t("yup_totalname")),
      code: Yup.string().required(t("yup_code")),
      parent: Yup.string().nullable()
    })
  });

  const formik = useFormik({
    initialValues,
    validationSchema: postLoginSchema
  });

  useEffect(
    () => {
      console.log("formik useEffect", formik.errors);
    },
    [formik.errors]
  );

  useEffect(
    () => {
      dispatch(setRiskGroupData(formik.values));
    },
    [formik.values]
  );

  useEffect(
    () => {
      if (method === "create" && Object.keys(formik.touched).length) {
        history.replace({ state: { ...state, method: "create" } });
      }
    },
    [formik.touched]
  );
  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={
          state.method == "edit" ? t("risk_group_edit") : t("risk_group_add")
        }
        small={t("risk_group_desc")}
      />
      <div className="risk-page">
        <div className="risk-page__elements">
          <h2>
            {t("global_info")}
          </h2>
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label="ID"
                formik={formik}
                field="id"
                type="number"
                required={false}
                visibilty={state.method == "edit" ? true : false}
                disabled={state.method == "edit" ? true : false}
              />
              <InputBox
                label={t("global_name")}
                formik={formik}
                field="name"
                required={true}
              />
              <InputBox
                label={t("global_totalname")}
                formik={formik}
                field="totalName"
                required={true}
              />
            </Col>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label={t("global_code")}
                formik={formik}
                field="code"
                required={true}
              />
              <SelectBox
                label={t("risk_group_parent")}
                formik={formik}
                field="parentId"
                data={parentList}
                type="complex"
                settings={{
                  parser: { value: "id", label: "name" },
                  columns: ["id", "totalName", "parentId"]
                }}
              />
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit myEvents={submitSettings} />
        </div>
      </div>
    </div>
  );
}

export default RiskGroupsEdit;
