import "./CreatedBySelect.scss";

import { Select } from "antd";
import React, { useEffect, useState } from "react";

import LibraryMainModel from "../../models/LibraryMainModel";
import GL from "libs/GL";

function CreatedBySelect({ createdBy, setCreatedBy, isCatalog }) {
  const [options, setOption] = useState([]);

  useEffect(() => {
    LibraryMainModel.getCreatedByList(
      isCatalog,
      (data) => {
        setOption(data);
      },
      (err) => {
        GL.notify.error({
          text: err.message ?? "Qeydiyyata alan siyahısı tapılmadı",
        });
      }
    );
  }, []);

  return (
    <div className="created-by-select-wrapper">
      <Select
        mode="multiple"
        maxTagCount="responsive"
        onChange={setCreatedBy}
        value={createdBy ? createdBy.split(",") : []}
        options={options}
        placeholder="Qeydiyyata alan"
      />
    </div>
  );
}

export default CreatedBySelect;
