import React, { useState, useRef } from "react";
import { Space, Row, Col, Skeleton } from "antd";
import VoenDetail from "../VoenDetail/VoenDetail";
import TableComponent from "../Table/Table";
import Recommend from "../Recommend/Recommend";
import AddExecutor from "../AddExecutor/AddExecutor";
import FooterTools from "../../Components/FooterTools/FooterTools";
import FileModal from "../FileModal/FileModal";
import PoolAttachedModal from "../PoolPaginationModal/PoolAttachedModal";
import Notify from "../Notify/Notify";
import EQFModal from "../Table/EQFModal";
import { ExecutorModal } from "../AddExecutor/AddExecutor";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { recomendedData } from "const/initialDatas";
import { roles } from "../../hook/useInvoice";
import * as Yup from "yup";
import useRiskView from "../../hook/useRiskView";
import useCategories from "../../hook/useCategories";
import useInvoices from "../../hook/useInvoices";
import useFooterButtons from "../../hook/useFooterButtons";
import useTableColumns from "../../hook/useTableColumns";

const Execute = ({ riskId, deleteSubTab }) => {
  const { activeRole, userId } = useSelector(({ user }) => ({
    activeRole: user.data.roles?.find(({ status }) => status),
    userId: user.data?.user?.id,
  }));
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const activeRisk = queryParams.get("subTab");

  const [isOpenFileModeal, setIsOpenFileModal] = useState(false);
  const onOpenFileModal = () => setIsOpenFileModal(true);
  const onCloseFileModal = () => setIsOpenFileModal(false);

  const [isOpenPoolModeal, setIsOpenPoolModal] = useState(false);
  const onOpenPoolModal = () => setIsOpenPoolModal(true);

  const [isOpenSubmitModal, setIsOpenSubmitModal] = useState(false);
  const onOpenSubmitModal = () => setIsOpenSubmitModal(true);
  const onCloseSubmitModal = () => setIsOpenSubmitModal(false);

  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const onOpenSuccessModal = () => setIsOpenSuccessModal(true);
  const onCloseSuccessModal = () => setIsOpenSuccessModal(false);

  const [isOpenRiskSubmitModal, setIsOpenRiskSubmitModal] = useState(false);
  const onOpenRiskSubmitModal = () => setIsOpenRiskSubmitModal(true);
  const onCloseRiskSubmitModal = () => setIsOpenRiskSubmitModal(false);

  const [isOpenExecutorModal, setIsOpenExecutorModal] = useState(false);
  const onOpenExecutorModal = () => setIsOpenExecutorModal(true);
  const onCloseExecutorModal = () => setIsOpenExecutorModal(false);

  const [isOpenExecuterSuccessModal, setIsOpenExecuterSuccessModal] =
    useState(false);
  const onOpenExecuterSuccessModal = () => setIsOpenExecuterSuccessModal(true);
  const onCloseExecuterSuccessModal = () =>
    setIsOpenExecuterSuccessModal(false);

  const [isOpenEqfModal, setIsOpenEqfModal] = useState(false);
  const onOpenREqfModal = () => setIsOpenEqfModal(true);
  const onCloseEqfModal = () => {
    onSetItemId(null);
    setIsOpenEqfModal(false);
  };

  const [isOpenResend, setIsOpenResendModal] = useState(false);
  const onCloseResendModal = () => setIsOpenResendModal(false);
  const onOpenResendModal = () => setIsOpenResendModal(true);

  const [itemId, setItemId] = useState(null);
  const [invoiceId, setInvoiceId] = useState(null);
  const [isPrevExecutor, setIsPrevExecutor] = useState(false);
  const onSetIsPrevExecutor = (isChecked) => setIsPrevExecutor(isChecked);
  const onResetPrevExecutorData = () => {
    onCloseResendModal();
    onSetIsPrevExecutor(false);
  };

  const submitMethod = useRef("");

  const onSetItemId = (id) => setItemId(id);
  const onSetInvoiceId = (id) => setInvoiceId(id);

  const createValidation = () => {
    const shcema = Yup.object({
      [roles[activeRole?.label]]: Yup.object({
        note: Yup.string().when("checks.reasonId", {
          is: (val) => val === 27 || val === 28,
          then: () => Yup.string().required("Qeyd daxil edin"),
        }),
        checks: Yup.object({
          reasonId: Yup.number().nullable(),
        }),
      }),
    });

    return shcema;
  };
  const validationShcema = Yup.object().shape({
    info: createValidation(),
  });

  const formik = useFormik({
    initialValues: recomendedData,
    validationSchema: validationShcema,
    onSubmit: (values) => {
      if (submitMethod.current === "save")
        onSave(values, onOpenRiskSubmitModal);
      if (submitMethod.current === "resend") onOpenExecutorModal();
      if (submitMethod.current === "approve") onOpenSubmitModal();
    },
  });

  const { commentCategories } = useCategories();

  const {
    isRiskPending,
    nextUser,
    executor,
    workflowUsers,
    tax,
    groupId,
    oldExecutor,
    onSave,
    onResend,
    onApprove,
    onDeleteApproveTab,
    onDeleteResendTab,
    onSelectExecutor,
    onSetWorkflowUsers,
  } = useRiskView({
    riskId,
    activeRisk,
    activeRole,
    userId,
    formik,
    deleteSubTab,
  });

  const { isGivenLoading, givenData, getRiskGivenData } = useInvoices({
    riskId,
    activeRisk,
  });

  const statuses = {
    executor: {
      status: formik.values.info.executor.status,
      userId: formik.values.info.executor.userId,
      role: "inspector",
    },
    headofdepartment: {
      status: formik.values.info.headofdepartment.status,
      userId: formik.values.info.headofdepartment.userId,
      role: "departmenthead",
    },
    leader: {
      status: formik.values.info.leader.status,
      userId: formik.values.info.leader.userId,
      role: "leader",
    },
  };

  const checkStatus = (obj) => {
    let isDisabled = true;

    if (
      obj.status === "personal" &&
      executor?.userId === userId &&
      obj.role === activeRole.label
    )
      isDisabled = false;
    if (obj.status === "") isDisabled = true;
    if (
      obj.status === "" &&
      executor?.userId === userId &&
      obj.role === activeRole.label
    )
      isDisabled = false;
    if (obj.status === "global") isDisabled = true;

    return isDisabled;
  };

  const { iconButtonList, extraButtons, buttons } = useFooterButtons({
    formik,
    submitMethod,
    checkStatus,
    statuses,
    activeRole,
    nextUser,
  });

  const { columns } = useTableColumns({
    onOpenREqfModal,
    onSetItemId,
    onSetInvoiceId,
    onOpenPoolModal,
  });

  const onSubmitExecutor = (values) => {
    onCloseResendModal();
    onOpenExecutorModal();
    onSetWorkflowUsers("newUser", values);
  };

  if (isRiskPending) return <Skeleton isActive />;

  return (
    <div className="Execute">
      <Space direction="vertical" className="w-100" size={24}>
        <VoenDetail hasHistoryButton={activeRole?.label !== "inspector"} />
        {isGivenLoading && <Skeleton active />}
        {!isGivenLoading && givenData && (
          <Row>
            <TableComponent
              rowSelection={{}}
              columns={columns}
              data={givenData}
              pagination={{ pageSize: 50 }}
              scroll={{ y: 200 }}
            />
          </Row>
        )}

        <Row gutter={24}>
          <Col sm={24} md={12} lg={12} className="mb-24">
            <Recommend
              disabled={checkStatus(statuses.executor)}
              title="İcraçının rəyi"
              hasCenter
              commentCategories={commentCategories}
              formik={formik}
              itemName="executor"
            />
          </Col>

          {activeRole?.label !== "inspector" && (
            <Col sm={24} md={12} lg={12} className="mb-24">
              <Recommend
                disabled={checkStatus(statuses.headofdepartment)}
                hasCenter
                title="Şöbə rəisinin rəyi"
                commentCategories={commentCategories}
                formik={formik}
                itemName="headofdepartment"
              />
            </Col>
          )}

          {activeRole?.label !== "inspector" && (
            <Col sm={24} md={12} lg={12} className="mb-24">
              <Recommend
                disabled={checkStatus(statuses.leader)}
                hasCenter
                title="Yekun rəy (Rəhbər)"
                commentCategories={commentCategories}
                formik={formik}
                itemName="leader"
              />
            </Col>
          )}

          <Col sm={24} md={12} lg={12} className="d-flex flex-column mb-24">
            {activeRole?.label === "inspector" && (
              <Recommend
                disabled={!(activeRole?.label === "departmenthead")}
                className="h-100 mb-24"
                title="Şöbə rəisinin icra üzrə qeydi"
                commentCategories={commentCategories}
                formik={formik}
                itemName="headofdepartment"
              />
            )}

            <AddExecutor
              isAdd={!executor?.userId}
              className="h-auto"
              executer={executor}
              onSubmit={onSelectExecutor}
            />
          </Col>
        </Row>
      </Space>
      <div className="main-layout-wrapper__footer position-sticky">
        <FooterTools
          iconButtons={iconButtonList(onOpenFileModal, onOpenPoolModal)}
          extraButtons={extraButtons()}
          buttons={buttons(onOpenResendModal)}
        />
      </div>
      {isOpenEqfModal && (
        <EQFModal
          isModalOpen={isOpenEqfModal}
          onOk={onCloseEqfModal}
          onCancel={onCloseEqfModal}
          riskId={riskId}
          itemId={itemId}
          invoiceId={invoiceId}
          onSetItemId={onSetItemId}
          onSetInvoiceId={onSetInvoiceId}
          getRiskGivenData={getRiskGivenData}
          oldExecutor={oldExecutor}
          isDisabled={checkStatus(statuses[roles[activeRole.label]])}
        />
      )}

      {isOpenResend && (
        <ExecutorModal
          open={isOpenResend}
          groupId={groupId}
          hasPrevExecutor
          onCancel={onResetPrevExecutorData}
          onSetIsPrevExecutor={onSetIsPrevExecutor}
          isPrevExecutor={isPrevExecutor}
          onSubmit={onSubmitExecutor}
        />
      )}

      {isOpenFileModeal && (
        <FileModal
          isOpenModal={isOpenFileModeal}
          onOk={onCloseFileModal}
          onCancel={onCloseFileModal}
          riskId={riskId}
        />
      )}

      {isOpenPoolModeal && (
        <PoolAttachedModal
          isModalOpen={isOpenPoolModeal}
          setIsModalOpen={setIsOpenPoolModal}
          riskId={riskId}
          itemId={itemId}
          onSetItemId={onSetItemId}
          isDisabled={checkStatus(statuses[roles[activeRole.label]])}
        />
      )}

      <Notify
        isOpenModal={isOpenSubmitModal}
        message="Yekun qərarınız icraya yönəldiləcək və bundan sonra bu risk üzrə qərarınıza dəyişiklik edə bilməyəcksiniz.Bu əməliyyatı yerinə yetirmək istədiyinizdən əminsinizmi?"
        okButton
        okText="Bəli"
        onOk={() => onApprove(onCloseSubmitModal, onOpenSuccessModal)}
        cancelText="Ləğv et"
        onCancel={onCloseSubmitModal}
      />
      <Notify
        isOpenModal={isOpenSuccessModal}
        message={`${tax} saylı risk üzrə qərarınız uğurla icraya yönləndirildi!`}
        cancelText="Bağla"
        onCancel={() => onDeleteApproveTab(onCloseSuccessModal)}
      />
      <Notify
        isOpenModal={isOpenRiskSubmitModal}
        message="Uğurla yadda saxlanıldı!"
        cancelText="Bağla"
        onCancel={onCloseRiskSubmitModal}
        type="success"
      />
      {/*  */}
      <Notify
        isOpenModal={isOpenExecutorModal}
        message={
          workflowUsers.newUser.user ? (
            <span>
              Təkrar icra üçün icraçı{" "}
              <strong className="fw-700">
                {workflowUsers.newUser?.user?.label}
              </strong>{" "}
              yönləndiriləcək. Bu şəxs əməliyyata nəzarət edən ilkin şöbə rəisi{" "}
              <strong className="fw-700">{oldExecutor?.name}</strong>-dan
              fərqlidir. Bu əməliyyatı yerinə yetirmək istədiyinizdən
              əminsinizmi?
            </span>
          ) : (
            <span>
              Təkrar icra üçün icraçı{" "}
              <strong className="fw-700">{workflowUsers?.prev.name}</strong>{" "}
              yönləndiriləcək. Bu şəxs əməliyyata nəzarət edən ilkin şöbə rəisi
              ilə eynidir. Bu əməliyyatı yerinə yetirmək istədiyinizdən
              əminsinizmi?
            </span>
          )
        }
        okButton
        okText="Bəli"
        onOk={() => onResend(onCloseExecutorModal, onOpenExecuterSuccessModal)}
        cancelText="Ləğv et"
        onCancel={() => {
          onResetPrevExecutorData();
          onCloseExecutorModal();
        }}
      />
      <Notify
        isOpenModal={isOpenRiskSubmitModal}
        message="Uğurla yadda saxlanıldı!"
        cancelText="Bağla"
        onCancel={onCloseRiskSubmitModal}
        type="success"
      />
      <Notify
        isOpenModal={isOpenExecuterSuccessModal}
        message={`${tax} saylı risk təkrar icra üçün uğurla ${executor?.name} göndərildi`}
        cancelText="Bağla"
        onCancel={() => onDeleteResendTab(onCloseExecuterSuccessModal)}
        type="success"
      />
    </div>
  );
};

export default Execute;
