import "./AntTabs.scss";

import React from "react";
import { Tabs } from "antd";

function AntTabs(props) {
  const { activeTab, onTabsChange, items } = props;

  return (
    <div className="custom-ant-tabs">
      <Tabs
        items={items}
        activeKey={activeTab}
        onChange={onTabsChange}
        type="card"
      />
    </div>
  );
}

export default AntTabs;
