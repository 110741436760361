import React, { useEffect, useMemo, useState } from "react";
import {
  GetPagePermission,
  GetTableSettings,
} from "../../../hooks/permissions";
import GL from "../../../libs/GL";
import SuperTable from "../../Elements/Tabulator/SuperTable";
import { useHistory } from "react-router-dom";
import { Skeleton } from "antd";
import Company from "../../../models/Company";
import EmptyPage from "../../Elements/Skelethon/EmptyPage";
import ConfirmDelete from "../../Elements/ConfirmDelete/ConfirmDelete";
import { useTranslation } from "react-i18next";

const pageId = "companies";

function Companies(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [companiesList, setCompaniesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const permissions = GetPagePermission(14);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );
  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {
        //debugger;
        history.push({
          pathname: "companies/create",
          state: {
            info: {
              id: data.id,
              name: data.name,
              totalName: data.totalName,
              manager: data.managerId,
              isActive: data.isActive,
            },
            method: "edit",
          },
        });
      },
    },
    delete: {
      status: permissions["delete"],
      click: function (data) {
        GL.confirmDelete(
          <Skeleton active />,
          (callback) => {
            Company.getUsers(data.id, (users) => {
              //debugger;
              Company.getDepartmentsByCompanyID(data.id, (departments) => {
                const companyDepartmentsList = departments;
                var json = {
                  id: data.id,
                  title: t("question_deleting"),
                  desc: t("companies_delete"),
                  items: [
                    {
                      count: companyDepartmentsList.length,
                      detail: companyDepartmentsList,
                      required: true,
                      name: "Department",
                      route: `/departments?companyId=${data.id}`,
                    },
                    {
                      count: users.length,
                      required: false,
                      detail: users,
                      name: "Istifadeci",
                      route: `/users?companyId=${data.id}`,
                    },
                  ],
                };
                json.okButtonStatus =
                  companyDepartmentsList.length > 0 ? false : true;
                callback(<ConfirmDelete config={json} />, json);
              });
            });
          },
          (status1) => {
            //debugger;
            if (status1) {
              Company.DELETE(data.id, (status2, res) => {
                if (status2) {
                  GL.notify.success({
                    title: t("answer_success"),
                    text: t("companies_deleted"),
                  });
                  getCompanyList();
                } else {
                  GL.notify.error({
                    title: t("answer_error"),
                    text: t("companies_not_deleted"),
                  });
                }
              });
            }
          }
        );
      },
    },
    show: {
      status: permissions["show"],
      click: function (data) {},
    },
    copy: {
      status: false,
      click: function (data) {
        history.push({
          pathname: "companies/create",
          state: {
            info: {
              id: 0,
              name: data.name,
              totalName: data.totalName,
              manager: data.managerId,
              isActive: data.isActive,
            },
            method: "copy",
          },
        });
      },
    },
  };
  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/companies/add",
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/companies/multiDelete",
      },
    },
    settings: {
      id: pageId,
      type: "data",
      data: companiesList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      columnSort: [], //tableSettings.columnSort,
      buttonText: "+ " + t("global_create", { name: t("company") }),
      createNewRoute: "companies/create",
      state: {
        info: Company.getEmptyState(),
        method: "create",
      },
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 70,

        formatter: function (cell) {
          return GL.setSelectStatus(cell);
        },
      },
      {
        title: t("global_name"),
        field: "name",
      },
      {
        title: t("global_totalname"),
        field: "totalName",
      },
      {
        title: t("global_manager"),
        field: "manager",
      },
      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        name: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        totalName: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        manager: {
          dataType: "text",
          placeholder: t("global_search"),
        },
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      columnSort: tableSettings.columnSort,
      rowColor: {},
    },
  };

  const getCompanyList = () => {
    setLoading(true);
    Company.getCompanyList(
      (data) => {
        //data.reverse();
        setCompaniesList(Company.getForTableData(data));
        setLoading(false);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    getCompanyList();
  }, []);

  if (loading) {
    return <Skeleton active />;
  } else {
    if (companiesList.length == 0) {
      return (
        <EmptyPage
          route="companies/create"
          name={t("companies")}
          state={options.settings.state}
        />
      );
    } else {
      return (
        <div style={containerStyle}>
          <div className="main-layout-wrapper__head">
            <div className="simple-head">
              <h1>{t("companies")}</h1>
              <span>{t("companies_desc")}</span>
            </div>
          </div>
          <SuperTable data={options} />
        </div>
      );
    }
  }
}

export default Companies;
