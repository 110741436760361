import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col } from "antd";
import PageTitle from "../../Elements/PageTitle/PageTitle";
import FormSubmit from "../../Elements/FormSubmit/FormSubmit";
import { useHistory } from "react-router-dom";
import GL from "../../../libs/GL";
import User from "../../../models/User";
import Company from "../../../models/Company";
import InputBox from "../../Elements/FileUploadForm/Form/InputBox";
import SelectBox from "../../Elements/FileUploadForm/Form/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyData } from "../../../redux/actions";
import { useTranslation } from "react-i18next";
function CompaniesEdit(props) {
  //debugger;
  const { t } = useTranslation();
  const history = useHistory();
  const [managerList, setManagerList] = useState([]);

  var state = props.location.state;

  const dispatch = useDispatch();
  const reduxCompany = useSelector(state => state.company);
  if (state == undefined) {
    state = reduxCompany;
  }

  let initialValues;

  const { method, ...stateRest } = state;
  if (state.method === "create") {
    if (reduxCompany !== undefined) {
      if (reduxCompany.method == undefined) {
        reduxCompany.method = "create";
      }
      initialValues = reduxCompany;
    } else {
      initialValues = stateRest;
    }
  }
  if (state.method === "edit") {
    initialValues = stateRest;
  }
  if (state.method === "copy") {
    initialValues = stateRest;
    state.method = "create";
  }

  const populateList = () => {
    User.getUserList(data => {
      setManagerList(data);
    });
  };
  useEffect(() => {
    formik.setFieldTouched("setFormikTouched");

    populateList();
  }, []);

  var submitSettings = {
    type: state.method,
    save: async () => {
      //debugger;
      if (state.info.id == 0) {
        var sendData = await GL.getFormikData(formik, [
          "name",
          "totalName",
          "manager"
        ]);
        Company.CREATE(sendData, res => {
          dispatch(
            setCompanyData({ info: Company.getEmptyState(), method: "create" })
          );
          GL.notify.success({ text: t("answer_success") });
          history.push("/companies");
        });
      }
    },

    edit: async () => {
      //debugger;
      if (state.info.id !== 0) {
        var sendData = await GL.getFormikData(formik, [
          "id",
          "name",
          "totalName",
          "manager"
        ]);
        Company.UPDATE(sendData, res => {
          dispatch(
            setCompanyData({ info: Company.getEmptyState(), method: "create" })
          );
          GL.notify.success({ text: t("answer_success") });
          history.push("/companies");
        });
      }
    },
    cancel: () => {
      // formRef.current.resetFields();
      history.push("/companies");
    }
  };

  const validation = Yup.object().shape({
    info: Yup.object().shape({
      id: Yup.number(t("yup_id")),
      name: Yup.string().required(t("yup_name")),
      totalName: Yup.string().required(t("yup_totalname")),
      manager: Yup.string().nullable()
    })
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      // onPostLogin(values, resetForm);
    }
  });

  useEffect(
    () => {
      dispatch(setCompanyData(formik.values));
    },
    [formik.values]
  );

  useEffect(
    () => {
      if (method === "create" && Object.keys(formik.touched).length) {
        history.replace({ state: { ...state, method: "create" } });
      }
    },
    [formik.touched]
  );

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={
          state.method == "edit" ? t("companies_edit") : t("companies_create")
        }
        small={t("companies_desc")}
      />
      <div className="risk-page">
        <div className="risk-page__elements">
          <h2>
            {t("global_info")}
          </h2>
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label="ID"
                formik={formik}
                field="id"
                type="number"
                required={false}
                visibilty={state.method == "edit" ? true : false}
                disabled={state.method == "edit" ? true : false}
                isFake={true}
              />
              <InputBox
                label={t("global_name")}
                formik={formik}
                field="name"
                required={true}
              />
              <InputBox
                label={t("global_totalname")}
                formik={formik}
                field="totalName"
                required={true}
              />
              <SelectBox
                label={t("global_manager")}
                formik={formik}
                field="manager"
                data={managerList}
                type="complex"
                settings={{
                  parser: { value: "id", label: "name" },
                  columns: ["id", "name", "mail"]
                }}
              />
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit myEvents={submitSettings} />
        </div>
      </div>
    </div>
  );
}

export default CompaniesEdit;
