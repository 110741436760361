import React from "react";
import { Modal } from "antd";
import { useFormik } from "formik";

import InputBox from "components/Elements/FileUploadForm/Form/InputBox";

function RenameModal({
  isOpen,
  closeModal,
  title,
  submitRenameFileOrFolder,
  currentName,
}) {
  const formik = useFormik({
    initialValues: {
      info: {
        name: currentName,
      },
    },
    onSubmit: (values) => {
      submitRenameFileOrFolder(values.info.name);
      closeModal();
    },
  });

  return (
    <Modal
      title={title}
      // className="library-share-modal"
      open={isOpen}
      onOk={formik.handleSubmit}
      onCancel={closeModal}
      bodyStyle={{
        marginTop: "30px",
      }}
    >
      <InputBox
        label="Adı"
        formik={formik}
        field="name"
        type="text"
        required={true}
      />
    </Modal>
  );
}

export default RenameModal;
