import API from "../const/API2";
import { apiRoutes } from "../const/apiroutes";
var api = new API();

class Position {
  constructor(obj) {
    this.id = obj["id"];
    this.name = obj["name"];
    this.isActive = obj["isActive"];
  }

  static getPositionFromArray(data) {
    var arr = [];
    data.map((a) => {
      var c = new Position(a);
      arr.push(c);
    });
    return arr;
  }

  static getPositionList(callback, errCallback) {
    api.getData(
      apiRoutes.positions.list,
      (data) => {
        var arr = Position.getPositionFromArray(data);
        callback(arr);
      },
      errCallback
    );
  }

  getTableItem() {
    return {
      id: this.id,
      name: this.name,
      isActive: this.isActive,
    };
  }

  static getForTableData(data) {
    var arr = [];
    data.map((a) => {
      arr.push(a.getTableItem());
    });
    return arr;
  }

  static sortDESC(a, b) {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    return 0;
  }

  static getArrayForSelect(data) {
    var arr = [];
    data.map((a) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    return arr;
  }

  // static getPositionsFromArray(data) {
  //   var arr = [];
  //   data.map((a) => {
  //     var c = new Position(a);
  //     arr.push(c);
  //   });
  //   return arr;
  // }

  // static getPositionList(callback, errCallback) {
  //   api.getData(apiRoutes.positions.list, (data) => {
  //     var arr = Position.getPositionsFromArray(data);
  //     callback(arr);
  //   }, errCallback);
  // }

  static CREATE(data, callback, errCallback) {
    if (data == false) {
      return null;
    }
    if (data["id"] !== undefined) {
      delete data["id"];
    }
    api.postData(
      apiRoutes.positions.add,
      data,
      (res) => {
        if (callback !== undefined) {
          callback(res);
        }
      },
      errCallback
    );
  }

  static DELETE(id, callback) {
    if (id == 0 || id == "" || id == null || id == undefined) {
      return null;
    }
    id = Number(id);
    api.deleteData(
      `${apiRoutes.positions.put}/${id}`,
      (data) => {
        callback(true, data);
      },
      (err) => {
        callback(false, err);
      }
    );
  }

  static UPDATE(data, callback, errCallback) {
    if (data == false) {
      return null;
    }
    var id = data["id"];
    if (id !== 0 && id !== "" && id !== null && id !== undefined) {
      api.putData(
        apiRoutes.positions.put + "/" + id,
        data,
        (res) => {
          if (callback !== undefined) {
            callback(res);
          }
        },
        errCallback
      );
    }
  }
}

export default Position;
