import React from "react";

import { fileTypeIconsEnum } from "const/fileTypeIconsEnum";

function RenderFileOrFolderIcon({
  isFolder,
  folderColor,
  type,
  name,
  size = "20px",
}) {
  return (
    <span className="render-file-or-folder-icon">
      {isFolder ? (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 18 15"
            fill="none"
          >
            <path
              d="M3.16667 1.33301H6.5L9 3.83301H14.8333C15.2754 3.83301 15.6993 4.0086 16.0118 4.32116C16.3244 4.63372 16.5 5.05765 16.5 5.49967V12.1663C16.5 12.6084 16.3244 13.0323 16.0118 13.3449C15.6993 13.6574 15.2754 13.833 14.8333 13.833H3.16667C2.72464 13.833 2.30072 13.6574 1.98816 13.3449C1.67559 13.0323 1.5 12.6084 1.5 12.1663V2.99967C1.5 2.55765 1.67559 2.13372 1.98816 1.82116C2.30072 1.5086 2.72464 1.33301 3.16667 1.33301Z"
              stroke={folderColor}
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </>
      ) : (
        <>
          <img
            src={fileTypeIconsEnum[type] || fileTypeIconsEnum.default}
            alt={name}
            height={size}
          />
        </>
      )}
    </span>
  );
}

export default RenderFileOrFolderIcon;
