import React from "react";
import { Table } from "antd";
import "../Voen.scss";

const TableComponent = ({
  columns,
  data,
  header,
  footer,
  rowSelection,
  size,
  pagination,
  scroll,
  components,
}) => {
  return (
    <div className="VoenTable">
      <div className="VoenTableBody">
        <div className="VoenTableInner">
          <Table
            components={components}
            pagination={pagination || false}
            scroll={scroll}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            bordered
            size={size || "small"}
            title={header}
            footer={footer}
            className="VoenTableFontSize"
          />
        </div>
        <div className="VoenTableTotal"></div>
      </div>
    </div>
  );
};

export default TableComponent;
