import API from "../const/API2";
import { apiRoutes } from "../const/apiroutes";
import User from "./User";

var api = new API();

class Company {
  constructor(obj) {
    this.id = obj["id"];
    this.name = obj["name"];
    this.totalName = obj["totalName"];
    this.address = obj["address"];
    this.parentId = obj["parentId"];
    this.phone = obj["phone"];
    this.email = obj["email"];
    if (obj["managerId"] !== null) {
      this.manager = new User(obj["managerId"]);
    } else {
      this.manager = new User();
    }
    this.isActive = obj["isActive"];
    this.departments = [];
  }

  getName() {
    return this.name;
  }

  getFullName() {
    return this.totalName;
  }

  static getEmptyState() {
    return {
      id: 0,
      name: "",
      totalName: "",
      manager: null,
      isActive: true,
    };
  }
  //test
  static getCompaniesFromArray(data) {
    return data.map((a) => new Company(a));
  }

  static getCompanyList(callback, errCallback) {
    api.getData(
      apiRoutes.companies.list,
      (data) => {
        var arr = Company.getCompaniesFromArray(data);
        callback(arr);
      },
      errCallback
    );
  }

  getTableItem() {
    return {
      id: this.id,
      name: this.name,
      totalName: this.totalName,
      manager: this.manager.getName(),
      managerId: this.manager.id,
      isActive: this.isActive,
    };
  }

  static getForTableData(data) {
    return data.map((a) => a.getTableItem());
  }

  static getDepartments(callback) {
    //debugger;
    api.getData(apiRoutes.departments.list, (data) => {
      if (callback !== undefined) {
        var filtered_data = data.filter((a) => {
          var bool = false;
          if (a.companyDto == null) {
            bool = false;
          }
          if (this.id === a.companyDto.id) {
            bool = true;
          }
          return bool;
        });
        this.departments = filtered_data;
        callback(filtered_data);
      }
    });
  }

  static getDepartmentsByCompanyID(id, callback) {
    api.getData(
      apiRoutes.departments.put + "/search?companyId=" + Number(id),
      (data) => {
        if (callback !== undefined) {
          callback(data);
        }
      }
    );
  }

  static sortDESC(a, b) {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    return 0;
  }

  static getArrayForSelect(data) {
    return data.map((a) => {
      return {
        value: a.id,
        label: a.name,
      };
    });
  }

  static getUsers(company_id, callback, errCallback) {
    api.getData(
      `${apiRoutes.user.search}?companyId=${Number(company_id)}`,
      callback,
      errCallback
    );
  }

  static UPDATE(data, callback, errCallback) {
    if (data == false) {
      return null;
    }
    var id = data["id"];
    if (data.manager !== null) {
      data.managerId = {
        id: data.manager,
      };
    } else {
      delete data.manager;
    }
    if (id !== 0 && id !== "" && id !== null && id !== undefined) {
      api.putData(
        apiRoutes.companies.put + "/" + id,
        data,
        (res) => {
          if (callback !== undefined) {
            callback(res);
          }
        },
        errCallback
      );
    }
  }

  static CREATE(data, callback, errCallback) {
    if (data == false) {
      return null;
    }
    if (data["id"] !== undefined) {
      delete data["id"];
    }
    if (data.manager !== null) {
      data.managerId = {
        id: data.manager,
      };
    } else {
      delete data.manager;
    }
    api.postData(
      apiRoutes.companies.add,
      data,
      (res) => {
        if (callback !== undefined) {
          callback(res);
        }
      },
      errCallback
    );
  }

  static DELETE(id, callback) {
    if (id == 0 || id == "" || id == null || id == undefined) {
      return null;
    }
    id = Number(id);
    api.deleteData(
      `${apiRoutes.companies.put}/${id}`,
      (data) => {
        callback(true, data);
      },
      (err) => {
        callback(false, err);
      }
    );
  }
}

export default Company;
