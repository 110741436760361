import React from "react";
import { clsx } from "clsx";
import FormErrorHandler from "./FormErrorHandler";

function InputBox(props) {
  var formik = props.formik;
  var field = props.field;
  var label = props.label;
  var required =
    props.required === undefined
      ? false
      : props.required === true
      ? true
      : false;
  var disabled =
    props.disabled === undefined
      ? false
      : props.disabled === true
      ? true
      : false;
  var visibilty =
    props.visibilty === undefined
      ? true
      : props.visibilty === true
      ? true
      : false;
  var isFake =
    props.isFake === undefined ? false : props.isFake === true ? true : false;
  var type = props.type === undefined ? "text" : props.type;
  var isNumber = type === "number";
  const placeholder = props.placeholder;
  const infoError = formik?.errors?.info || {};
  const infoTouched = formik?.touched?.info || {};
  const classes = clsx(
    disabled && "input-disabled",
    infoError[`${field}`] && infoTouched[`${field}`] && "error"
  );

  if (isFake === true) {
    return (
      <>
        <div
          style={{ display: visibilty === true ? "block" : "none" }}
          className="input-box"
        >
          <span className="input-box__title">
            {label}{" "}
            {required === true ? <span style={{ color: "red" }}>*</span> : null}
          </span>
          <input
            disabled={true}
            className={disabled === true ? "input-disabled" : ""}
            type={type}
            value={formik.values.info[field]}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          className="input-box"
          style={{ display: visibilty === true ? "block" : "none" }}
        >
          <span className="input-box__title">
            {label}
            {required === true ? <span style={{ color: "red" }}>*</span> : null}
          </span>
          <input
            disabled={disabled}
            className={classes}
            type={type}
            placeholder={placeholder}
            {...formik?.getFieldProps(`info.${field}`)}
            onChange={(e) => {
              const { valueAsNumber, value } = e.target;
              const inputValue = isNumber ? valueAsNumber : value;
              if (props.handleChange) {
                formik.setFieldValue(
                  `info.${field}`,
                  props.handleChange(inputValue)
                );
              } else {
                formik.setFieldValue(`info.${field}`, inputValue);
              }
            }}
          />
          <FormErrorHandler formik={formik} field={`info.${field}`} />
        </div>
      </>
    );
  }
}

export default InputBox;
