const Operators = [
    {
      name: "=", 
      type: "=",
      format: [
        { type: "div", value: [] },
        { type: "rule", color:"#009688", value: "=" },
        { type: "div", value: [] },
      ],
    },
    {
      name: "<>", 
      type: "<>",
      format: [
        { type: "div", value: [] },
        { type: "rule", color:"#009688", value: "<>" },
        { type: "div", value: [] },
      ],
    },
    {
      name: "+", 
      type: "+",
      format: [
        { type: "div", value: [] },
        { type: "rule", color:"#009688", value: "+" },
        { type: "div", value: [] },
      ],
    },
    {
      name: "-", 
      type: "-",
      format: [
        { type: "div", value: [] },
        { type: "rule", color:"#009688", value: "-" },
        { type: "div", value: [] },
      ],
    },
    {
      name: "/", 
      type: "/",
      format: [
        { type: "div", value: [] },
        { type: "rule", color:"#009688", value: "/" },
        { type: "div", value: [] },
      ],
    },
    {
      name: "*", 
      type: "*",
      format: [
        { type: "div", value: [] },
        { type: "rule", color:"#009688", value: "*" },
        { type: "div", value: [] },
      ],
    },
    {
      name: "()", 
      type: "()",
      format: [
        { type: "rule", color:"#009688", value: "(" },
        { type: "div", value: [] },
        { type: "rule", color:"#009688", value: ")" },
      ],
    },
    {
      name: ">", 
      type: ">",
      format: [
        { type: "div", value: [] },
        { type: "rule", color:"#009688", value: ">" },
        { type: "div", value: [] },
      ],
    },
    {
      name: ">=", 
      type: ">=",
      format: [
        { type: "div", value: [] },
        { type: "rule", color:"#009688", value: ">=" },
        { type: "div", value: [] },
      ],
    },
    {
      name: "<", 
      type: "<",
      format: [
        { type: "div", value: [] },
        { type: "rule", color:"#009688", value: "<" },
        { type: "div", value: [] },
      ],
    },
    {
      name: "<=", 
      type: "<=",
      format: [
        { type: "div", value: [] },
        { type: "rule", color:"#009688", value: "<=" },
        { type: "div", value: [] },
      ],
    },
  ];

export default Operators;