import Company from "./Company";
import Department from "./Department";
import Position from "./Position";
import Sector from "./Sector";
import { apiRoutes } from "../const/apiroutes";
import API from "../const/API2";
var api = new API();

class User {
  constructor(obj) {
    if (obj == undefined) {
      obj = {};
    }
    this.id = obj["id"] == undefined ? null : obj["id"];
    this.firstName = obj["firstName"] === undefined ? null : obj["firstName"];
    this.lastName = obj["lastName"] === undefined ? null : obj["lastName"];
    this.patronymic =
      obj["patronymic"] === undefined ? null : obj["patronymic"];
    this.gender = obj["gender"] === undefined ? null : obj["gender"];
    this.birthday = obj["birthday"] === undefined ? null : obj["birthday"];
    this.pin = obj["pin"] === undefined ? null : obj["pin"];
    this.phone = obj["phone"] === undefined ? null : obj["phone"];
    this.cooperationNumber =
      obj["cooperationNumber"] === undefined ? null : obj["cooperationNumber"];
    this.dateCreated =
      obj["dateCreated"] === undefined ? null : obj["dateCreated"];
    this.email = obj["email"] === undefined ? null : obj["email"];
    this.isActive = obj["isActive"] === undefined ? null : obj["isActive"];
    this.photoPath = obj["photoPath"] === undefined ? null : obj["photoPath"];
    this.roles = obj["roles"] === undefined ? null : obj["roles"];
    this.defaultRole =
      obj["defaultRole"] === undefined ? null : obj["defaultRole"];
    this.name = `${this.firstName} ${this.lastName} `;

    if (obj["companiesDto"] !== null && obj["companiesDto"] !== undefined) {
      this.company = new Company(obj["companiesDto"]);
    } else {
      this.company = null;
    }

    if (obj["departmentsDto"] !== null && obj["departmentsDto"] !== undefined) {
      this.department = new Department(obj["departmentsDto"]);
    } else {
      this.department = null;
    }

    if (obj["positionDto"] !== null && obj["positionDto"] !== undefined) {
      this.position = new Position(obj["positionDto"]);
    } else {
      this.position = null;
    }

    if (obj["sectorsDto"] !== null && obj["sectorsDto"] !== undefined) {
      this.sector = new Sector(obj["sectorsDto"]);
    } else {
      this.sector = null;
    }
  }

  static readFromArray(arr) {
    var res = [];
    arr.map((a) => res.push(new User(a)));
    return res;
  }

  static getHTMLSelectOptions(arr) {
    var res = [];
    var users = User.readFromArray(arr);
    users.map((a) => {
      res.push({ label: a.getName(), value: a.id });
    });
    return res;
  }

  getName() {
    if (this.firstName !== null || this.lastName !== null) {
      return `${this.firstName} ${this.lastName}`;
    } else {
      return null;
    }
  }

  getFullName() {
    return `${this.getName} ${this.patronymic}`;
  }

  getTableItem() {
    return {
      id: this.id,
      name: this.getName(),
      patronymic: this.patronymic,
      gender: this.gender,
      birthday: this.birthday,
      pin: this.pin,
      phone: this.phone,
      cooperationNumber: this.cooperationNumber,
      dateCreated: this.dateCreated,
      email: this.email,
      isActive: this.isActive,
      photoPath: this.photoPath,
      roles: this.roles,
      company: this.company ? this.company.getName() : null,
      company_id: this.company ? this.company.id : null,
      department: this.department ? this.department.name : null,
      department_id: this.department ? this.department.id : null,
      sector: this.sector ? this.sector.name : null,
      sector_id: this.sector ? this.sector.id : null,
      position: this.position ? this.position.name : null,
      position_id: this.position ? this.position.id : null,
      defaultRole: this.defaultRole,
    };
  }

  static getForTableData = (arr) => arr.map((item) => item.getTableItem());

  static getUserList(callback) {
    api.getData(apiRoutes.user.list, (data) => {
      var arr = [];
      data.map((a) => {
        var u = new User(a);
        arr.push(u);
      });
      if (callback !== undefined) {
        callback(arr);
      }
    });
  }

  static getArrayForSelect(arr) {
    return arr.map((a) => ({
      value: a.id,
      label: a.getName(),
    }));
  }

  static getArrayForReactSelect(list) {
    return list.map(({ id, firstName, lastName, email }) => ({
      id,
      value: id,
      name: `${firstName} ${lastName}`,
      label: `${firstName} ${lastName}`,
      email,
    }));
  }

  static getUserById(userId, callback) {
    api.getData(apiRoutes.users.getById + userId, (data) => {
      const user = new User(data);
      callback(user);
    });
  }

  static async getUserBySearchKey(key) {
    if (!key) return;
    try {
      const response = await api.getDataSync(
        `${apiRoutes.users.searchExecutor}key=${key}`
      );
      const data = User.getArrayForReactSelect(response);
      return data;
    } catch (error) {
      return [];
    }
  }

  static Create(data, callback, errorCallback) {
    if (!data) return null;
    if (!data.id) delete data.id;
    api.postData(apiRoutes.users.add, data, callback, errorCallback);
  }

  static Update(data, callback, errorCallback) {
    if (!data) return null;
    api.putData(apiRoutes.users.put + data.id, data, callback, errorCallback);
  }

  static DeleteCheck(userId, callback, errorCallback) {
    if (!userId) return null;
    api.deleteData(
      apiRoutes.users.deleteCheck + userId,
      callback,
      errorCallback
    );
  }

  static Delete(userId, callback, errorCallback) {
    if (!userId) return null;
    api.deleteData(apiRoutes.users.delete + userId, callback, errorCallback);
  }

  static CHANGE_PASSWORD(userId, body, callback, errorCallback) {
    api.putData(
      apiRoutes.users.updatePassword + userId,
      body,
      callback,
      errorCallback
    );
  }
}

export default User;
