import React, { useEffect, useState } from "react";
import "./AddExecutor.scss";
import Warning from "components/Elements/Icons/Warning";
import { Space, Button, Modal, Row, Col, Checkbox } from "antd";
import SelectBox from "components/Elements/FileUploadForm/Form/SelectBox";
import DateTimePicker from "components/Elements/FileUploadForm/Form/DatePicker";
import Sector from "models/Sector";
import UserGroup from "models/UserGroup";
import { useFormik } from "formik";
import * as Yup from "yup";
import AsyncSelectComponent from "components/Elements/AsyncSelect/AsyncSelect";
import User from "models/User";
import dayjs from "dayjs";

const AddExecutor = ({ isAdd, className, executer, onSubmit, onSelect }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    if (onSelect) onSelect(null);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    if (onSelect) onSelect(null);
  };

  return (
    <div className={`AddExecutor ${className}`}>
      <div className="AddExecutorBody">
        <Space direction="vertical" size={20}>
          {executer && executer?.userId && (
            <ExecutorItem
              type="İcraçı"
              name={executer.name}
              date={executer.date}
              hasIcon={executer.hasIcon}
            />
          )}
        </Space>
      </div>
      {isAdd && (
        <div className="AddExecutorFooter">
          <Button className="btn-success" onClick={showModal}>
            İcraya ver
          </Button>
          {isModalOpen && (
            <ExecutorModal
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              onSubmit={(data) => onSubmit(data, handleOk)}
              onSelect={onSelect}
            />
          )}
        </div>
      )}
    </div>
  );
};

function ExecutorItem({ type, hasIcon, name, date }) {
  const convertDate = date ? dayjs(new Date(date)).format("DD.MM.YYYY") : "";
  return (
    <div className="AddExecutorItem">
      <div className="AddExecutorItemHeader">
        <strong>{type}</strong>
        {hasIcon && <Warning />}
      </div>
      <div className="AddExecutorItemFooter">
        <span>{name}</span>
        <div className="AddExecutorLine" />
        <span>{convertDate}</span>
      </div>
    </div>
  );
}

export function ExecutorModal({
  open,
  groupId,
  onOk,
  onCancel,
  hasPrevExecutor,
  onSubmit,
  onSelect,
  onSetIsPrevExecutor,
  isPrevExecutor,
}) {
  const [sectors, setSectors] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);

  const validationSchema = Yup.object().shape({
    info: Yup.object({
      userId: Yup.number().when("isPrev", {
        is: (val) => (val ? true : false),
        then: () => Yup.number().optional(),
        otherwise: () => Yup.number().required("Əməkdaş daxil edin!"),
      }),
      lastExecuteDate: Yup.string().required("Son icra tarixi daxil edin"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      info: {
        userId: "",
        sectorId: "",
        groupId: groupId ?? "",
        user: null,
        lastExecuteDate: "",
        isPrev: isPrevExecutor,
      },
    },
    validationSchema,
    onSubmit: (values) => {
      if (onSubmit) onSubmit(values);
    },
  });

  const {
    handleSubmit,
    values: { info },
    setFieldValue,
    errors,
    touched,
  } = formik;

  const getSectors = () => {
    Sector.getSectorList((data) => {
      setSectors(data);
    });
  };

  const getUserGroups = (sectorId) => {
    UserGroup.getUserGroupBySectorID(sectorId, (data) => {
      setGroups(data);
    });
  };

  const getUsers = (groupId) => [
    UserGroup.getUserGroupByID(groupId, (data) => {
      const userList = data.userGroupsRelList;
      if (!Array.isArray(userList) && userList.length === 0) return;
      setUsers(userList);
    }),
  ];

  useEffect(() => {
    getSectors();
  }, []);

  useEffect(() => {
    if (info.sectorId) getUserGroups(info.sectorId);
  }, [info.sectorId]);

  useEffect(() => {
    if (info.groupId) getUsers(info.groupId);
  }, [info.groupId]);

  const onFormSubmit = () => {
    handleSubmit();
  };

  const loadOptions = async (inputValue, callback) => {
    const response = await User.getUserBySearchKey(inputValue);
    callback(response);
  };

  const onAsyncSelectChange = (item) => {
    if (!item) setFieldValue("info.userId", "");
    if (item) {
      setFieldValue("info.userId", item.value);
      setFieldValue("info.user", item);
    }
  };

  const onSelectFormik = (_, user) => {
    if (!user) return;
    if (user) setFieldValue("info.user", user);
    if (onSelect) onSelect(user, user);
  };

  return (
    <Modal
      className="AddExecutorModal"
      title="İcraçı seçmə"
      open={open}
      onOk={onFormSubmit}
      onCancel={onCancel}
      okButtonProps={{ className: "btn-success" }}
      cancelButtonProps={{ className: "btn-secondary" }}
      okText="Əlavə et"
      cancelText="Ləğv et"
      bodyStyle={{ minHeight: 300 }}
    >
      <Row className="AddExecutorModalBody">
        <Space direction="vertical" size={20} className="w-100">
          {hasPrevExecutor && (
            <Col span={24}>
              <Checkbox
                checked={info.isPrev}
                onChange={(e) => {
                  formik.setFieldValue("info.isPrev", e.target.checked);
                  onSetIsPrevExecutor && onSetIsPrevExecutor(e.target.checked);
                }}
              >
                Əvvəlki icraçı
              </Checkbox>
            </Col>
          )}

          <Col span={24}>
            <DateTimePicker
              label="Son icra tarixi"
              formik={formik}
              field="lastExecuteDate"
              required
            />
          </Col>

          {!hasPrevExecutor && (
            <Col span={24}>
              <AsyncSelectComponent
                loadOptions={loadOptions}
                placeholder="İcraçı axtar"
                label="İcraçı axtar"
                onChange={onAsyncSelectChange}
              />
              {errors.info?.userId && touched.info?.userId && (
                <div className="errorText">{errors.info.userId}</div>
              )}
            </Col>
          )}

          {!hasPrevExecutor && (
            <Col span={24}>
              <SelectBox
                label="Şöbə"
                data={sectors}
                formik={formik}
                field="sectorId"
              />
            </Col>
          )}

          {!hasPrevExecutor && (
            <Col span={24}>
              <SelectBox
                label="Qrup"
                data={groups}
                formik={formik}
                field="groupId"
                disabled={!(groups.length > 0)}
              />
            </Col>
          )}

          <Col span={24}>
            <SelectBox
              label="Əməkdaş"
              data={users}
              formik={formik}
              field="userId"
              disabled={!(users.length > 0) || isPrevExecutor}
              onSelect={onSelectFormik}
            />
          </Col>
        </Space>
      </Row>
    </Modal>
  );
}

export default AddExecutor;
