import API from "../const/API2";
import { apiRoutes } from "../const/apiroutes";

var api = new API();

class EnumCategory {
  constructor(obj) {
    this.id = obj["id"] === undefined ? 0 : obj["id"];
    this.name = obj["name"] === undefined ? "" : obj["name"];
    this.isActive = obj["isActive"] === undefined ? false : obj["isActive"];
  }

  static getEnumCategoriesFromArray(data) {
    return data.map((a) => {
      return new EnumCategory(a);
    });
  }

  static getEnumCategoriesList(callback, errCallback) {
    api.getData(
      apiRoutes.enumCategories.list,
      (data) => {
        var arr = EnumCategory.getEnumCategoriesFromArray(data);
        callback(arr);
      },
      errCallback
    );
  }

  getTableItem() {
    return {
      id: this.id,
      name: this.name,
      isActive: this.isActive,
    };
  }

  static getForTableData(data) {
    return data.map((a) => {
      return a.getTableItem();
    });
  }

  static sortDESC(a, b) {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    return 0;
  }

  static getArrayForSelect(data) {
    return data.map((a) => {
      return {
        value: a.id,
        label: a.name,
      };
    });
  }

  static UPDATE(data, callback, errCallback) {
    if (data === false) {
      return null;
    }
    var id = data["id"];

    if (id !== 0 && id !== "" && id !== null && id !== undefined) {
      api.putData(
        apiRoutes.enumCategories.put + "/" + id,
        data,
        (res) => {
          if (callback !== undefined) {
            callback(res);
          }
        },
        errCallback
      );
    }
  }

  static CREATE(data, callback, errCallback) {
    if (data === false) {
      return null;
    }
    if (data["id"] !== undefined) {
      delete data["id"];
    }

    api.postData(
      apiRoutes.enumCategories.add,
      data,
      (res) => {
        if (callback !== undefined) {
          callback(res);
        }
      },
      errCallback
    );
  }

  static DELETE(id, callback) {
    if (id === 0 || id === "" || id === null || id === undefined) {
      return null;
    }
    id = Number(id);
    api.deleteData(
      `${apiRoutes.enumCategories.delete}/${id}`,
      (data) => {
        callback(true, data);
      },
      (err) => {
        callback(false, err);
      }
    );
  }
}

export default EnumCategory;
