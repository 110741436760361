import "./EmptyPage.scss";

import { useTranslation } from "react-i18next";
import { Button, Typography } from "antd";
import { useHistory } from "react-router";
import React from "react";

function EmptyPage(props) {
  const { t } = useTranslation();
  const history = useHistory();
  var route = props.route;
  var name = props.name;
  var state = props.state;

  function handleAddParameter() {
    history.push({
      pathname: route,
      state: state,
    });
  }

  return (
    <div className="emptypage">
      <div
        style={{ width: "400px", border: "1px dashed #ccc" }}
        className="epmtyText"
      >
        <Typography.Paragraph
          style={{ display: "flex", flexDirection: "column" }}
        >
          {t("emptypage_notfound", { name })} {t("emptypage_new", { name })}
          <Button
            style={{ marginTop: "40px", height: "55px !important" }}
            type="primary"
            onClick={handleAddParameter}
          >
            {t("global_create", { name })}
          </Button>
        </Typography.Paragraph>
      </div>
    </div>
  );
}

export default EmptyPage;
