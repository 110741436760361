import "./style/login.scss";

import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";

import { logInUser } from "redux/actions/index";
import { useTranslation } from "react-i18next";
import LoginLogo from "../../../../assets/img/loginLogo.png";
import Danger from "components/Elements/Icons/Danger";

const Login = (props) => {
  const { t } = useTranslation();
  const initialValues = {
    username: "",
    password: "",
  };
  const postLoginSchema = Yup.object().shape({
    username: Yup.string().required(t("yup_username")),
    password: Yup.string().required(t("yup_password")),
  });

  const onPostLogin = (values, resetForm) => {
    props.logInUser(values.username, values.password);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: postLoginSchema,
    onSubmit: (values, { resetForm }) => {
      onPostLogin(values, resetForm);
    },
  });

  const getInputClasses = (filedName) => {
    if (formik.touched[filedName] && formik.errors[filedName]) {
      return true;
    }
    if (formik.touched[filedName] && !formik.errors[filedName]) {
      return false;
    }
    return false;
  };

  return (
    <Row className="login-page w-100 h-100vh">
      <Col lg={12} md={12} className="login-page__left">
        <div className="flex all-center h-100vh loginbackColor">
          <div className="admin-login-box login-page">
            <div className="admin-login-row">
              <h1>{t("login_login")}</h1>
            </div>
            <form
              // onSubmit={logUserIn}
              onSubmit={formik.handleSubmit}
              className="mb"
            >
              <div className="admin-login-row">
                <span className="login-row__title">{t("login_fin")}</span>
                <input
                  type="text"
                  className={`${getInputClasses("username") ? "error" : ""}`}
                  {...formik.getFieldProps("username")}
                />

                <div className="input-error">
                  {formik.touched.username && formik.errors.username ? (
                    <div className="input-error__message">
                      {formik.errors.username}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="admin-login-row">
                <span className="login-row__title">{t("login_password")}</span>
                <input
                  type="password"
                  className={`${getInputClasses("password") ? "error" : ""}`}
                  {...formik.getFieldProps("password")}
                />

                <div className="input-error">
                  {formik.touched.password && formik.errors.password ? (
                    <div className="input-error__message">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="admin-login-row">
                <input type="submit" value={t("login_login")} />
              </div>
            </form>

            <div className="WebsiteNotify">
              <div className="WebsiteNotifyBody">
                <div className="WebsiteNotifyIcon">
                  <Danger />
                </div>
                <div className="WebsiteNotifyMessage">
                  <strong>Diqqət!</strong>
                  <span>
                    Sistemə üçüncü şəxslərin adından və ya digər qanunsuz giriş
                    edilməsi Azərbaycan Respublikası qanunvericiliyi ilə müəyyən
                    edilmiş məxsuliyyətə səbəb olur!
                  </span>
                </div>
              </div>
            </div>

            {/* <Divider orientation="center" plain>
              {t("logn_or")}
            </Divider> */}

            {/* <div className="other-login-types">
              <Link to="/" className="login-type">
                <span className="login-type__left">
                  <img src={Images.ldapLogin} />
                  <span>{t("login_ldap")}</span>
                </span>
                <span className="login-type__right">
                  <img src={Images.angleRight} />
                </span>
              </Link>
              <Link to="/" className="login-type">
                <span className="login-type__left">
                  <img src={Images.asanLogin} />
                  <span>{t("login_asan")}</span>
                </span>
                <span className="login-type__right">
                  <img src={Images.angleRight} />
                </span>
              </Link>
              <Link to="/" className="login-type">
                <span className="login-type__left">
                  <img src={Images.biometricLogin} />
                  <span>{t("login_biometric")}</span>
                </span>
                <span className="login-type__right">
                  <img src={Images.angleRight} />
                </span>
              </Link>
            </div> */}
          </div>
        </div>
      </Col>
      <Col lg={12} md={12} sm={0}>
        <div className="b-100 h-100vh loginbackground flex all-center">
          <div className="WebsiteInfo">
            <div className="WebsiteInfoBody">
              <img
                src={LoginLogo}
                alt="Vergi Risklərinin Təhlili və Monitorinqi Sistemi"
              />
              <span />

              <h2>Vergi Risklərinin Təhlili və Monitorinqi Sistemi</h2>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};
const mapStateToProps = ({ user }) => {
  return {
    loggedIn: user.isLoggedIn,
    message: user.message,
    notify: user.notify,
  };
};

export default connect(mapStateToProps, { logInUser })(Login);
