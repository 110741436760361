import API from "const/API2";
import { apiRoutes } from "const/apiroutes";
import GL from "libs/GL";

const api = new API();

function useToggleIsFavorite(getLastTableData) {
  const addToFavorites = ({ id }) => {
    api.postData(
      apiRoutes.library.addFileToFavorites({ id }),
      {},
      (_) => {
        GL.notify.success({ text: "Sevimlililərə əlavə edildi" });
        getLastTableData();
      },
      (_) => {
        GL.notify.error({ text: "Sevimlililərə əlavə edilə bilmədi" });
      }
    );
  };
  const deleteFromFavorites = ({ id }) => {
    api.deleteData(
      apiRoutes.library.deleteFromFavorites({ id }),
      (_) => {
        GL.notify.success({ text: "Sevimlililərdən silindi" });
        getLastTableData();
      },
      (_) => {
        GL.notify.error({ text: "Sevimlililərdən silinə bilmədi" });
      }
    );
  };

  return { addToFavorites, deleteFromFavorites };
}

export default useToggleIsFavorite;
