import "./JsonModal.scss";
import React, { useState } from "react";
import { Modal, Input } from "antd";
import { useTranslation } from "react-i18next";

function XmlModal(props) {
  const { t } = useTranslation();
  const { open, setOpen, title, formik, formikPath } = props;
  const { TextArea } = Input;

  const [jsonValue, setJsonVlue] = useState(
    beautifyJsonString(JSON.stringify(formik.getFieldProps(formikPath).value))
  );

  const [errTxt, setErrTxt] = useState("");

  const handleOk = () => {
    let jsonIsValid;
    try {
      jsonIsValid = !!JSON.parse(jsonValue);
    } catch (e) {
      jsonIsValid = false;
    }

    if (jsonIsValid) {
      formik.setFieldValue(formikPath, JSON.parse(jsonValue));
      setOpen(false);
    } else {
      setErrTxt(t("json_format_warning_message"));
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setJsonVlue(e.target.value);
  };

  function beautifyJsonString(str) {
    return str.replace(/,|{|(?=})/g, (match) => match + "\n");
  }

  return (
    <Modal
      width={1000}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      title={title}
    >
      <TextArea
        autoSize
        rows={20}
        value={jsonValue}
        onChange={handleChange}
        name={formik.getFieldProps(formikPath).name}
        className={errTxt ? "error" : ""}
      />
      <div className="error-field">{errTxt ? errTxt : ""}</div>
    </Modal>
  );
}

export default XmlModal;
