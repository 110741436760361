import {SET_TABLE_SETTINGS, GET_TABLE_SETTINGS} from "../types";

const defaultData = {};

const reducer = (data = defaultData, action) => {
  switch(action.type){
    case SET_TABLE_SETTINGS:{
      return data;
    }
    case GET_TABLE_SETTINGS:{
      return data;
    }
    default:{
      return data;
    }
  }
}

export default reducer;