import React from "react";
import { Typography } from "antd";
import { Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./RTMSMessenger.css";
import { useState } from "react";
import { AutoComplete } from "antd";
const { Title } = Typography;

const MessengerHeader = () => {
  // const [searchResults, setSearchResults] = useState([]);
  // const handleSearch = value => {
  //   setSearchValue(value);

  //   if (value.length >= 1) {
  //     MessengerModel.getContacts((data) => {
  //       dispatch({ type: "SET_CONTACTS", payload: data });
  //     });
  //     MailModel.getMailForSearch(
  //       value,
  //       resDataSearch => {
  //         console.log(resDataSearch, "RES DATA FROM SEARCH");
  //         setSearchResults(resDataSearch);
  //       },
  //       err => {
  //         console.log(err, "ERROR MESSAGE FROM SEARCH REQUEST");
  //       }
  //     );
  //   } else if (value === "") {
  //     setSearchResults([]);
  //   } else {
  //     setSearchResults([]);
  //   }
  // };

  return (
    <div>
      {" "}
      <Space
        direction="horizontal"
        style={{
          alignItems: "center",
          justifyContent: "start",
          padding: "30px 20px",
        }}
        size="large"
      >
        <Title style={{ color: "#000", fontSize: "16px", fontWeight: "600" }}>
          RTMS messenger
        </Title>
      </Space>
      <Space
        direction="horizontal"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <Input
          size="large"
          className="search-input-messenger"
          style={{
            backgroundColor: "#fff",
            borderColor: "#fff",
            boxShadow: "none",
            borderRadius: "5px",
            width: 220,
            height: "6vh",
          }}
          placeholder="Axtarış et"
          prefix={
            <SearchOutlined style={{ color: "#000", fontSize: "16px" }} />
          }
          onClick={() => console.log("salam")}
        />
        {/* <AutoComplete
          dropdownMatchSelectWidth={252}
          style={{
            width: "220px",
            height: "6vh",
            backgroundColor: "#fff",
            borderColor: "#fff",
            boxShadow: "none",
            borderRadius: "5px",
          }}
          placeholder="İstifadəçi axtar"
          options={searchResults.map((result) => {
            return {
              key: result.key,
              value: result.key,
              label: `${result.content && result.content}`,
              result: result,
            };
          })}
          onSelect={onSelect}
          onSearch={handleSearch}
          value={searchValue}
        /> */}
      </Space>
    </div>
  );
};

export default MessengerHeader;
