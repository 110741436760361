import Api from "const/API2";
import { apiRoutes } from "const/apiroutes";

const API = new Api();

class Invoice {
  constructor(invoice) {
    const {
      id,
      riskId,
      itemId,
      info,
      realPrice,
      price,
      vat,
      differentDecision,
      fakeData = {},
    } = invoice;

    this.id = id;
    this.riskId = riskId;
    this.itemId = itemId;
    this.info = info;
    this.realPrice = realPrice;
    this.price = price;
    this.vat = vat;
    this.differentDecision = differentDecision;
    this.fakeVat = fakeData.vat;
    this.unit = fakeData.unit;
    this.amount = fakeData.amount;
    this.unitCost = fakeData.unitCost;
    this.totalCost = fakeData.totalCost;
    this.serviceName = fakeData.serviceName;
    this.subCategory = fakeData.subCategory;
    this.topCategory = fakeData.topCategory;
  }

  static getDataConvertToInvoices(invoices) {
    if (!invoices) return [];
    const list = invoices.map((invoice) => new Invoice(invoice));
    return list;
  }

  static getInvoicesList(riskId, callback, errCallback) {
    if (!riskId) return;
    API.getData(
      apiRoutes.riskInvoice.getInvoice(riskId),
      (data) => {
        const list = Invoice.getDataConvertToInvoices(data);
        callback && callback(list);
      },
      (error) => {
        errCallback && errCallback(error);
      }
    );
  }

  static getInvoiceItemById(itemId, callback, errCallback) {
    if (!itemId) return;
    API.getData(
      apiRoutes.riskInvoice.getInvoiceItem(itemId),
      (response) => {
        const invoiceList = response?.invoiceLists?.map((item, index) => ({
          ...new Invoice(item),
          key: index,
        }));
        const data = {
          invoiceList,
          settings: response.settings,
        };
        callback && callback(data);
      },
      (error) => {
        errCallback && errCallback(error);
      }
    );
  }

  static putInvoiceItem(body, callback, errCallback) {
    if (!body) return;
    API.putData(
      apiRoutes.riskInvoice.putInvoice,
      body,
      (data) => callback && callback(data),
      (error) => errCallback && errCallback(error)
    );
  }

  static saveInvoiceItem(body, callback, errCallback) {
    if (!body) return;
    API.postData(
      apiRoutes.riskInvoice.saveInvoice,
      body,
      callback,
      errCallback
    );
  }
}

export default Invoice;
