import React from "react";
import {
  EditOutlined,
  StarOutlined,
  SendOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
  StarFilled,
} from "@ant-design/icons";

import useShareFileOrFolder from "./useShareFileOrFolder";
import ShareModal from "./ShareModal";
import useDetailsFileOrFolder from "./useDetailsFileOrFolder";
import FileDetailsModal from "./FileDetailsModal";
import useChangeFileOrFolderName from "./useChangeFileOrFolderName";
import RenameModal from "./RenameModal";
import useDeleteFileOrFolder from "./useDeleteFileOrFolder";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import useToggleIsFavorite from "./useToggleIsFavorite";

function ActionsPopover(props) {
  const {
    setShowActionsPopover,
    showActionsPopover,
    rowData,
    getLastTableData,
    getLastAdded,
  } = props;

  const { id, isFolder, name: fileOrFoldersName, shared } = rowData;

  const { detailsModalIsOpen, showDetailsModal, hideDetailsModal } =
    useDetailsFileOrFolder();

  const {
    openShareModal,
    closeShareModal,
    submitShareFileOrFolder,
    deleteFileOrFolderFromShared,
    lastSharedUsers,
    showShareModal,
  } = useShareFileOrFolder({
    fileOrFolderId: id,
    getLastTableData,
  });

  const {
    changeNameModalIsOpen,
    openChangeNameModal,
    closeChangeNameModal,
    submitRenameFileOrFolder,
  } = useChangeFileOrFolderName({
    id,
    isFolder,
    getLastTableData,
    getLastAdded,
  });

  const {
    confirmDeleteModalIsOpen,
    openConfirmDeleteModal,
    closeConfirmDeleteModal,
    submitDeleteFileOrFolder,
  } = useDeleteFileOrFolder({ id, isFolder, getLastTableData, getLastAdded });

  const { addToFavorites, deleteFromFavorites } =
    useToggleIsFavorite(getLastTableData);

  const hidePopover = () => {
    setShowActionsPopover({
      ...showActionsPopover,
      [id]: false,
    });
  };

  return (
    <>
      {showShareModal && (
        <ShareModal
          isOpen={showShareModal}
          closeModal={closeShareModal}
          title={fileOrFoldersName}
          submitShareFileOrFolder={submitShareFileOrFolder}
          deleteFileOrFolderFromShared={deleteFileOrFolderFromShared}
          lastSharedUsers={lastSharedUsers}
          fileOrFolderSharedWith={shared}
        />
      )}

      {detailsModalIsOpen && (
        <FileDetailsModal
          isOpen={detailsModalIsOpen}
          closeModal={hideDetailsModal}
          title={fileOrFoldersName}
          rowData={rowData}
        />
      )}

      {changeNameModalIsOpen && (
        <RenameModal
          isOpen={changeNameModalIsOpen}
          closeModal={closeChangeNameModal}
          title="Adını dəyiş"
          submitRenameFileOrFolder={submitRenameFileOrFolder}
          currentName={fileOrFoldersName}
        />
      )}

      {confirmDeleteModalIsOpen && (
        <ConfirmDeleteModal
          isOpen={confirmDeleteModalIsOpen}
          closeModal={closeConfirmDeleteModal}
          title={fileOrFoldersName}
          submitDeleteFileOrFolder={submitDeleteFileOrFolder}
        />
      )}

      <div className="last-viewed-table-row-actions">
        <button
          onClick={() => {
            openChangeNameModal();
            hidePopover();
          }}
        >
          <span>
            <EditOutlined />
          </span>
          <span>Adını dəyiş</span>
        </button>
        {rowData.isFavorite ? (
          <button
            onClick={() => {
              deleteFromFavorites({ id });
              hidePopover();
            }}
          >
            <span>
              <StarFilled />
            </span>
            <span>Seçilmişlərdən çıxart</span>
          </button>
        ) : (
          <button
            onClick={() => {
              addToFavorites({ id });
              hidePopover();
            }}
          >
            <span>
              <StarOutlined />
            </span>
            <span>Seçilmişlərə əlavə et</span>
          </button>
        )}
        <button
          onClick={() => {
            openShareModal();
            hidePopover();
          }}
        >
          <span>
            <SendOutlined />
          </span>
          <span>Paylaş</span>
        </button>
        <button
          onClick={() => {
            showDetailsModal();
            hidePopover();
          }}
        >
          <span>
            <InfoCircleOutlined />
          </span>
          <span>Haqqında</span>
        </button>
        <button
          onClick={() => {
            openConfirmDeleteModal();
            hidePopover();
          }}
        >
          <span>
            <DeleteOutlined />
          </span>
          <span>Sil</span>
        </button>
      </div>
    </>
  );
}

export default ActionsPopover;
