import API from "../const/API2";
import { apiRoutes } from "../const/apiroutes";

var api = new API();

class RiskStatus {
  constructor(obj) {
    this.id = obj["id"];
    this.name = obj["name"];
    this.message = obj["message"];
    this.isActive = obj["isActive"];
  }

  //   getName(){
  //     return this.name;
  //   }

  static getRiskStatusFromArray(data) {
    var arr = [];
    data.map((a) => {
      var c = new RiskStatus(a);
      arr.push(c);
    });
    return arr;
  }

  static getRiskStatusList(callback, errCallback) {
    api.getData(
      apiRoutes.riskStatuses.list,
      (data) => {
        var arr = RiskStatus.getRiskStatusFromArray(data);
        callback(arr);
      },
      errCallback
    );
  }

  getTableItem() {
    return {
      id: this.id,
      name: this.name,
      message: this.message,
      isActive: this.isActive,
    };
  }

  static getForTableData(data) {
    var arr = [];
    data.map((a) => {
      arr.push(a.getTableItem());
    });
    return arr;
  }

  static sortDESC(a, b) {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    return 0;
  }

  static getArrayForSelect(data) {
    var arr = [];
    data.map((a) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    return arr;
  }

  static UPDATE(data, callback, errCallback) {
    if (data == false) {
      return null;
    }
    var id = data["id"];

    if (id !== 0 && id !== "" && id !== null && id !== undefined) {
      api.putData(
        apiRoutes.riskStatuses.put + "/" + id,
        data,
        (res) => {
          if (callback !== undefined) {
            callback(res);
          }
        },
        errCallback
      );
    }
  }

  static CREATE(data, callback, errCallback) {
    if (data == false) {
      return null;
    }
    if (data["id"] !== undefined) {
      delete data["id"];
    }
    api.postData(
      apiRoutes.riskStatuses.add,
      data,
      (res) => {
        if (callback !== undefined) {
          callback(res);
        }
      },
      errCallback
    );
  }

  static DELETE(id, callback) {
    if (id == 0 || id == "" || id == null || id == undefined) {
      return null;
    }
    id = Number(id);
    api.deleteData(
      `${apiRoutes.riskStatuses.put}/${id}`,
      (data) => {
        callback(true, data);
      },
      (err) => {
        callback(false, err);
      }
    );
  }
}

export default RiskStatus;
