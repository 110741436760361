import React from "react";

const Danger = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.0549 11.505C39.1101 9.41107 44.5283 8.33333 50 8.33333C55.4718 8.33333 60.89 9.41107 65.9452 11.505C71.0004 13.599 75.5937 16.6681 79.4628 20.5372C83.3319 24.4063 86.4011 28.9996 88.495 34.0549C90.589 39.1101 91.6667 44.5283 91.6667 50C91.6667 55.4717 90.589 60.8899 88.495 65.9451C86.4011 71.0004 83.3319 75.5937 79.4628 79.4628C75.5937 83.3319 71.0004 86.401 65.9452 88.495C60.89 90.5889 55.4718 91.6667 50 91.6667C44.5283 91.6667 39.1101 90.5889 34.0549 88.495C28.9997 86.401 24.4064 83.3319 20.5373 79.4628C16.6682 75.5937 13.599 71.0004 11.5051 65.9451C9.41111 60.8899 8.33337 55.4717 8.33337 50C8.33337 44.5283 9.41111 39.1101 11.5051 34.0549C13.599 28.9996 16.6682 24.4063 20.5373 20.5372C24.4064 16.6681 28.9997 13.599 34.0549 11.505ZM50 16.6667C45.6227 16.6667 41.2881 17.5289 37.2439 19.204C33.1997 20.8792 29.5251 23.3345 26.4298 26.4298C23.3345 29.5251 20.8792 33.1997 19.2041 37.2439C17.5289 41.2881 16.6667 45.6226 16.6667 50C16.6667 54.3774 17.5289 58.7119 19.2041 62.7561C20.8792 66.8003 23.3345 70.4749 26.4298 73.5702C29.5251 76.6655 33.1997 79.1208 37.2439 80.796C41.2881 82.4711 45.6226 83.3333 50 83.3333C54.3774 83.3333 58.712 82.4711 62.7562 80.796C66.8003 79.1208 70.475 76.6655 73.5703 73.5702C76.6656 70.4749 79.1209 66.8003 80.796 62.7561C82.4712 58.7119 83.3334 54.3774 83.3334 50C83.3334 45.6226 82.4712 41.2881 80.796 37.2439C79.1209 33.1997 76.6656 29.5251 73.5703 26.4298C70.475 23.3345 66.8003 20.8792 62.7562 19.204C58.712 17.5289 54.3774 16.6667 50 16.6667Z"
        fill="#DF2638"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 33.3333C52.3012 33.3333 54.1667 35.1988 54.1667 37.5V54.1667C54.1667 56.4678 52.3012 58.3333 50 58.3333C47.6989 58.3333 45.8334 56.4678 45.8334 54.1667V37.5C45.8334 35.1988 47.6989 33.3333 50 33.3333Z"
        fill="#DF2638"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 62.5C52.3012 62.5 54.1667 64.3655 54.1667 66.6667V66.7083C54.1667 69.0095 52.3012 70.875 50 70.875C47.6989 70.875 45.8334 69.0095 45.8334 66.7083V66.6667C45.8334 64.3655 47.6989 62.5 50 62.5Z"
        fill="#DF2638"
      />
    </svg>
  );
};

export default Danger;
