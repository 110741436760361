import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  GetPagePermission,
  GetTableSettings,
} from "../../../hooks/permissions";
import GL from "../../../libs/GL";
import SuperTable from "../../Elements/Tabulator/SuperTable";
import { useHistory } from "react-router";
import { Skeleton } from "antd";
import User from "../../../models/User";
import EmptyPage from "../../Elements/Skelethon/EmptyPage";
import { userData } from "const/initialDatas";
import ConfirmDelete from "../../Elements/ConfirmDelete/ConfirmDelete";
import { useTranslation } from "react-i18next";

const pageId = "userlist";

function UserList() {
  const { t } = useTranslation();
  const history = useHistory();
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);

  const permissions = GetPagePermission(9);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );

  const getUserList = useCallback(() => {
    setLoading(true);
    User.getUserList((users) => {
      //users.reverse();
      setUserList(User.getForTableData(users));
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getUserList();
  }, [getUserList]);
  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {
        history.push({
          pathname: "users/create",
          state: {
            id: data.id,
            method: "edit",
          },
        });
      },
    },
    delete: {
      status: permissions["delete"],
      click: function (data) {
        GL.confirmDelete(
          <Skeleton active />,
          (callback) => {
            User.DeleteCheck(data.id, (response) => {
              console.log(response);
              const { errors, warnings } = response;
              var json = {
                id: data.id,
                title: t("question_deleting"),
                desc: t("user_deleting_desc"),
                items: [
                  {
                    count: errors.length,
                    detail: errors,
                    required: true,
                    name: t("global_error"),
                  },
                  {
                    count: warnings.length,
                    detail: warnings,
                    required: false,
                    name: t("global_warning"),
                  },
                ],
                hasError: Boolean(errors.length),
                hasWarning: Boolean(warnings.length),
              };
              json.okButtonStatus = !Boolean(errors.length);
              callback(<ConfirmDelete config={json} type="isUser" />, json);
            });
          },
          (status) => {
            if (status) {
              User.Delete(data.id, () => {
                GL.notify.success({
                  title: t("answer_success"),
                  text: t("user_deleted"),
                });
                getUserList();
              });
            }
          }
        );
      },
    },
    show: {
      status: permissions["show"],
      click: function (data) {},
    },
    copy: {
      status: false,
      click: function (data) {
        history.push({
          pathname: "user-groups/create",
          state: {
            id: 0,
            name: data.name,
            manager: data.managerId,
            isActive: data.isActive,
            method: "create",
          },
        });
      },
    },
  };
  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/users/create",
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/userGroups/multiDelete",
      },
    },
    settings: {
      id: pageId,
      type: "data",
      data: userList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: t("user_create"),
      createNewRoute: "users/create",
      state: {
        ...userData,
        method: "create",
      },
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 70,

        formatter: function (cell) {
          return GL.setSelectStatus(cell);
        },
      },
      {
        title: t("global_name_surname"),
        field: "name",
      },
      {
        title: t("global_company"),
        field: "company",
      },
      {
        title: t("department"),
        field: "department",
      },
      {
        title: t("sector"),
        field: "sector",
      },
      {
        title: t("position"),
        field: "position",
      },
      {
        title: t("pin"),
        field: "pin",
      },
      {
        title: t("email"),
        field: "email",
      },
      {
        title: t("mobile_phone"),
        field: "phone",
      },
      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        name: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        company: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        department: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        sector: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        position: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        pin: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        email: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        phone: {
          dataType: "text",
          placeholder: t("global_search"),
        },
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {},
    },
  };

  if (loading) {
    return <Skeleton active />;
  } else {
    if (userList.length == 0) {
      return (
        <EmptyPage
          route="users/create"
          name={t("menu_users")}
          state={options.settings.state}
        />
      );
    } else {
      return (
        <div style={containerStyle}>
          <div className="main-layout-wrapper__head">
            <div className="simple-head">
              <h1>{t("menu_users")}</h1>
              <span>{t("user_desc")}</span>
            </div>
          </div>
          <SuperTable data={options} />
        </div>
      );
    }
  }
}

export default UserList;
