import API from "const/API2";
import { apiRoutes } from "const/apiroutes";
import { useEffect } from "react";
import { useState } from "react";

var api = new API();

const UseScenarioInfoUsers = () => {
  const [scenarioUsers, setScenarioUsers] = useState([]);

  useEffect(() => {
    api.getData(`${apiRoutes.roles.getUserRole}departmenthead`, (data) => {
      setScenarioUsers(data);
    });
  }, []);

  return [scenarioUsers];
};

export default UseScenarioInfoUsers;
