import React from "react";
import { Button, Table } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import GL from "libs/GL";
import { t } from "i18next";

function Columns(props) {
  const { Column } = Table;
  const { formik, fields, setShowModal, setModalState } = props;

  const handleDeleteRaw = (rawName) => {
    GL.yesNo(t("question_deleting"), (status) => {
      if (status) {
        formik.setFieldValue(
          "info.fields",
          formik.values.info.fields.filter(
            (formikColumn) => formikColumn.name !== rawName
          )
        );
      }
    });
  };

  const handleEdit = (rawName) => {
    setModalState({ method: "edit", id: rawName });
    setShowModal(true);
  };

  return (
    <Table
      dataSource={fields}
      pagination={{
        position: ["none"],
      }}
    >
      <Column title={t("mylist_column_name")} key="name" dataIndex="name" />
      <Column title={t("mylist_data_type")} key="type" dataIndex="type" />
      <Column title={t("mylist_column_key")} key="field" dataIndex="field" />
      <Column
        title={t("mylist_is_unique")}
        key="unique"
        dataIndex="unique"
        render={(data) => <span>{`${data}`}</span>}
      />
      <Column
        title={t("mylist_nullable")}
        key="nullable"
        dataIndex="nullable"
        render={(data) => <span>{`${data}`}</span>}
      />
      <Column title={t("mylist_default")} key="default" dataIndex="default" />
      <Column
        title={t("global_operations")}
        key="operations"
        render={(raw) => {
          return (
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Button onClick={() => handleEdit(raw.name)}>
                <EditOutlined />
              </Button>
              <Button onClick={() => handleDeleteRaw(raw.name)}>
                <DeleteOutlined />
              </Button>
            </div>
          );
        }}
      />
    </Table>
  );
}

export default Columns;
