import React from "react";
import { Typography, Form, Input, Space } from "antd";
import { useMailContext } from "./MailContextAPI";
import { useSelector } from "react-redux";
const { Title } = Typography;
const { TextArea } = Input;

const MailReply = ({ selectedRowY }) => {
  const { dispatch } = useMailContext();
  const currentUserId = useSelector(state => state.user.data.user.id);

  return (
    <Space
      direction="vertical"
      style={{
        paddingTop: "15px",
        paddingLeft: "15px",
        backgroundColor: "white"
      }}
    >
      <Space direction="horizontal">
        <Space>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.49935 11.6667L4.16602 8.33333M4.16602 8.33333L7.49935 5M4.16602 8.33333H13.3327C14.2167 8.33333 15.0646 8.68452 15.6897 9.30964C16.3148 9.93477 16.666 10.7826 16.666 11.6667C16.666 12.5507 16.3148 13.3986 15.6897 14.0237C15.0646 14.6488 14.2167 15 13.3327 15H12.4993"
              stroke="#1C2940"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Space>
        <Title style={{ fontSize: "16px" }}>
          <span style={{ fontWeight: "600" }}>Kimə:</span>{" "}
          {selectedRowY.from.id === currentUserId
            ? selectedRowY.users.map(user => user.name)
            : selectedRowY.from.name}{" "}
          ({selectedRowY.from.id === currentUserId
            ? selectedRowY.users.map(user => user.email)
            : `${selectedRowY.from.email}`})
        </Title>
      </Space>
      <Title style={{ fontSize: "14px" }}>Mətn</Title>
      <Form.Item>
        <TextArea
          autoSize={{
            minRows: 5,
            maxRows: 5
          }}
          placeholder="Daxil et"
          style={{
            borderRadius: "6px",
            border: "1px solid #EBEDF0",
            boxShadow: "0px 16px 40px -12px rgba(171, 186, 201, 0.20)"
          }}
          onChange={e => {
            dispatch({ type: "SET_REPLY_TEXT", payload: e.target.value });
          }}
        />
      </Form.Item>
    </Space>
  );
};

export default MailReply;
