import "./FileDetailsModal.scss";

import React from "react";
import dayjs from "dayjs";
import { Modal } from "antd";

import RenderFileOrFolderIcon from "../LibraryTable/RenderFileOrFolderIcon";

function FileDetailsModal({ isOpen, closeModal, title, rowData }) {
  const {
    color,
    createdAt,
    createdByName,
    isFolder,
    shared,
    updatedAt,
    updatedByName,
    mimeType,
  } = rowData;

  return (
    <Modal
      title={`"${title}" detallı baxış`}
      className="file-details-modal"
      width={450}
      open={isOpen}
      onOk={closeModal}
      onCancel={closeModal}
      bodyStyle={{
        marginTop: "30px",
      }}
    >
      <div className="modal-body">
        <div className="icon-row">
          <div className="icon-wrapper">
            <RenderFileOrFolderIcon
              isFolder={isFolder}
              folderColor={color}
              type={mimeType}
              size="40px"
            />
          </div>
        </div>
        <h2 className="modal-subheader">
          {isFolder ? "Qovluq" : "Fayl"} haqqında
        </h2>

        <h3 className="modal-row-header">Qeydiyyat tarixi:</h3>
        <span className="modal-row-data">
          {dayjs(createdAt).format("DD.MM.YYYY")}
        </span>

        <h3 className="modal-row-header">Qeydiyyata alan:</h3>
        <span className="modal-row-data">{createdByName}</span>

        <h3 className="modal-row-header">Qovluğ kimlərlə paylaşılıb:</h3>
        <span className="modal-row-data details-modal-shared">
          {shared.map((person) => (
            <span>{person.firstLetter}</span>
          ))}
        </span>

        <h3 className="modal-row-header">Növü:</h3>
        <span className="modal-row-data">{isFolder ? "Qovluq" : "Fayl"}</span>

        {isFolder ? (
          <>
            <h3 className="modal-row-header">Qovluğun rəngi:</h3>
            <span
              style={{
                backgroundColor: color,
              }}
              className="modal-row-data color-indicator"
            ></span>
          </>
        ) : null}

        <h3 className="modal-row-header">Sonuncu dəfə düzəliş edilib:</h3>
        <span className="modal-row-data">
          {dayjs(updatedAt).format("DD.MM.YYYY")} - {updatedByName}
        </span>
      </div>
    </Modal>
  );
}

export default FileDetailsModal;
