import React, { useEffect, useRef } from "react";
import Profile from "models/Profile";
import { useHistory } from "react-router-dom";
import { Collapse, Row, Col, Button } from "antd";
import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import DateTimePicker from "components/Elements/FileUploadForm/Form/DatePicker";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

const initialValues = {
  info: {
    type: "profile-general",
    fin: "",
    processDate: null,
    calculationDate: null,
    paymentDate: null,
    ovnDate: null,
  },
};

const validationSchema = Yup.object().shape({
  info: Yup.object().shape({
    type: Yup.string().required("Tip daxil edin"),
    voen: Yup.number().required("VÖEN daxil edin"),
  }),
});

const Search = ({ onSetProfileInfo, onSetLoading }) => {
  const { t } = useTranslation();
  const {
    location: { hash, state },
  } = useHistory();

  const initialPageLoading = useRef(true);

  const formik = useFormik({
    initialValues: {
      info: {
        ...initialValues.info,
        voen: state?.voen ? state.voen : "",
      },
    },
    validationSchema,
    onSubmit: (values) => {
      onSetProfileInfo(null);
      onSetLoading(true);
      initialPageLoading.current = false;
      Profile.getProfileData(
        values.info,
        (data) => {
          onSetProfileInfo(data);
          onSetLoading(false);
        },
        () => {
          onSetLoading(false);
        }
      );
    },
  });

  const {
    handleSubmit,
    setFieldValue,
    values: {
      info: { type },
    },
  } = formik;

  useEffect(() => {
    if (state?.voen) {
      console.log(state?.voen);
      handleSubmit();
    }
  }, [state]);

  useEffect(() => {
    if (hash) setFieldValue("info.type", hash.replace("#", ""));
  }, [hash]);

  useEffect(() => {
    if (!initialPageLoading.current) handleSubmit();
  }, [type]);

  return (
    <div className="profile-search">
      <Collapse defaultActiveKey={["1"]} expandIconPosition="end">
        <Panel header="Axtarış" key="1">
          <form onSubmit={handleSubmit}>
            <Row gutter={24}>
              <Col lg={8} md={8} sm={12}>
                <InputBox
                  label={t("voen")}
                  type="number"
                  field="voen"
                  formik={formik}
                  required
                />
                <DateTimePicker
                  label={t("process_date")}
                  field="processDate"
                  formik={formik}
                />
                <DateTimePicker
                  label={t("calculation_date")}
                  field="calculationDate"
                  formik={formik}
                />
              </Col>
              <Col lg={8} md={8} sm={12}>
                <InputBox label={t("fin")} field="fin" formik={formik} />
                <DateTimePicker
                  label={t("payment_date")}
                  field="paymentDate"
                  formik={formik}
                />
              </Col>
              <Col lg={8} md={8} sm={12}>
                <InputBox
                  label={t("global_name")}
                  field="name"
                  formik={formik}
                />
                <DateTimePicker
                  label={t("ovn_date")}
                  field="ovnDate"
                  formik={formik}
                />
                <div className="profile-submit">
                  <Button htmlType="submit" type="primary">
                    {t("profile_search_show")}
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>
      </Collapse>
    </div>
  );
};

export default Search;
