import React, { useContext, useEffect, useRef } from "react";
import { Form, Input } from "antd";
import GL from "libs/GL";

const EditableContext = React.createContext(null);

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  ...restProps
}) => {
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (dataIndex) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, []);

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave(
        {
          ...record,
          ...values,
        },
        inputRef.current.input
      );
      inputRef.current.blur();
    } catch (errInfo) {
      GL.notify.error({ text: errInfo?.message });
    }
  };

  let childNode = children;
  if (editable) {
    childNode = (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} boş ola bilməz!`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          type={type ?? "text"}
          onPressEnter={save}
          className="table-input"
        />
      </Form.Item>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
