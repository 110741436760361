import "./Collaborators.scss";

import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Row, Col, Button, Table, Select } from "antd";

import InfoCard from "../../components/InfoCard/InfoCard";
import useCollaboratorsFilter from "../../hooks/useCollaboratorsFilter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Collaborators({ addNewTab, addTabWithSubTab, deleteTab }) {
  const history = useHistory();

  const reduxActiveRole = useSelector((reduxState) =>
    reduxState.user.data.roles.find((role) => role.status)
  );
  const {
    sectorSelectProps,
    userGroupSelectProps,
    userSelectProps,
    collabCount,
    collaboratorsTableProps,
  } = useCollaboratorsFilter({
    roleId: reduxActiveRole.id,
  });

  const rowProps = (record) => {
    return {
      onClick: () => {
        addNewTab("Əməkdaş", "risksInExecutionUser");
        history.push({
          search: `userId=${record.userId}`,
        });
      },
    };
  };

  const collabColumns = [
    {
      width: 60,
      title: "ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Əməkdaş",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Şöbə",
      dataIndex: "sector",
      key: "sector",
    },
    {
      title: "Qrup",
      dataIndex: "groups",
      key: "groups",
    },
    {
      title: "İcrada say",
      dataIndex: "execCount",
      key: "execCount",
    },
    {
      title: "Bu gün sıfır",
      dataIndex: "approveCount",
      key: "approveCount",
    },
    {
      title: "Gecikən icra",
      dataIndex: "lateExecCount",
      key: "lateExecCount",
    },
    {
      title: "Əlavə iş imkanı",
      dataIndex: "additional",
      key: "additional",
    },
    {
      title: "Reytinq",
      dataIndex: "rating",
      key: "rating",
    },
    {
      title: "",
      dataIndex: "isOnline",
      key: "isOnline",
    },
    {
      title: "Vəzifəsi",
      dataIndex: "position",
      key: "position",
    },
  ];

  return (
    <div className="risk-page">
      <div className="risk-page__elements">
        <form className="home-page__top-filters">
          <Row gutter={24}>
            <Col sm={24} md={6} lg={7}>
              <span>Şöbə</span>
              <Select allowClear {...sectorSelectProps} placeholder="Seç" />
            </Col>
            <Col sm={24} md={6} lg={7}>
              <span>Qrup</span>
              <Select allowClear {...userGroupSelectProps} placeholder="Seç" />
            </Col>
            <Col sm={24} md={6} lg={7}>
              <span>Əməkdaşlıq</span>
              <Select allowClear {...userSelectProps} placeholder="Seç" />
            </Col>
            <Col sm={24} md={6} lg={3}>
              <div className="home-page__top-filters-submit">
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  style={{ borderRadius: "4px", display: "inline-block", width: "100%" }}
                  onClick={collaboratorsTableProps.getAllCollaborators}
                >
                  Axtar
                </Button>
              </div>
            </Col>
          </Row>
        </form>
        <div className="collaborators-info-cards">
          <div className="collaborators-info-cards__item">
            <InfoCard
              key="1"
              title="Açıq icralar"
              count={collabCount.openExecCount}
              todayCount={collabCount.openTodayExecCount}
              arrow="up"
              onClick={() => {
                addNewTab("Açıq icralar", "risksInExecutionOpen");
              }}
            />
          </div>
          <div className="collaborators-info-cards__item">
            <InfoCard
              key="2"
              title="Gecikmələr"
              count={collabCount.lateExecCount}
              todayCount={collabCount.lateTodayExecCount}
              arrow="down"
              onClick={() => {
                addNewTab("Gecikmələr", "risksInExecutionLate");
              }}
            />
          </div>
          <div className="collaborators-info-cards__item">
            <InfoCard
              key="3"
              title="Dövr üzrə cəmi icra"
              count={collabCount.approveCount}
              todayCount={collabCount.approveTodayCount}
              arrow="up"
              onClick={() => {
                addNewTab("Dövr üzrə cəmi icra", "risksInExecution");
              }}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <Table
            bordered
            dataSource={collaboratorsTableProps.collabTableData}
            columns={collabColumns}
            pagination={{
              pageSize: 6,
            }}
            rowKey={(row) => row.userId}
            // onRow={rowProps}
          />
        </div>
      </div>
    </div>
  );
}
export default Collaborators;
