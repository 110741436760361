import React, { forwardRef, useRef } from "react";

const FileUploadRef = forwardRef(({ fileFormat, onChangeFileInput }, ref) => {
  const form = useRef(null);
  function onChangeFile() {
    onChangeFileInput(form?.current);
  }

  return (
    <React.Fragment>
      <form ref={form}>
        <input
          hidden
          name="file"
          type="file"
          ref={ref}
          accept={fileFormat}
          onChange={onChangeFile}
        />
      </form>
    </React.Fragment>
  );
});

export default FileUploadRef;
