import React from "react";
import { Modal, Progress } from "antd";

const ProgressModal = ({ isModalOpen, percent }) => {
  return (
    <Modal open={isModalOpen} footer={[]}>
      <Progress strokeLinecap="butt" percent={percent} />
    </Modal>
  );
};

export default ProgressModal;
