import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col } from "antd";
import PageTitle from "../../Elements/PageTitle/PageTitle";
import FormSubmit from "../../Elements/FormSubmit/FormSubmit";
import { useHistory } from "react-router-dom";
import GL from "../../../libs/GL";
import EnumCategory from "../../../models/EnumCategory";
import Enum from "../../../models/Enum";
import InputBox from "../../Elements/FileUploadForm/Form/InputBox";
import SelectBox from "../../Elements/FileUploadForm/Form/SelectBox";
import { setEnumData } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function EnumsEdit(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [enumCat, setEnumCat] = useState([]);
  var state = props.location.state;

  const dispatch = useDispatch();
  const reduxEnum = useSelector(state => state.enum);
  if (state == undefined) {
    state = reduxEnum;
  }

  let initialValues;

  const { method, ...stateRest } = state;
  if (state.method === "create") {
    if (reduxEnum !== undefined) {
      if (reduxEnum.method == undefined) {
        reduxEnum.method = "create";
      }
      initialValues = reduxEnum;
    } else {
      initialValues = stateRest;
    }
  }
  if (state.method === "edit") {
    initialValues = stateRest;
  }
  if (state.method === "copy") {
    initialValues = stateRest;
    state.method = "create";
  }

  const populateList = () => {
    EnumCategory.getEnumCategoriesList(data => {
      setEnumCat(data);
    });
  };
  useEffect(() => {
    formik.setFieldTouched("setFormikTouched");
    populateList();
  }, []);

  var submitSettings = {
    type: state.method,
    save: async () => {
      if (state.id == 0) {
        var sendData = await GL.getFormikData(formik, ["name", "eCategory"]);
        Enum.CREATE(sendData, res => {
          GL.notify.success({ text: t("answer_success") });
          history.push("/enums");
        });
      }
    },

    edit: async () => {
      if (state.id !== 0) {
        var sendData = await GL.getFormikData(formik, [
          "id",
          "name",
          "eCategory"
        ]);
        Enum.UPDATE(sendData, res => {
          GL.notify.success({ text: t("answer_success") });
          history.push("/enums");
        });
      }
    },
    cancel: () => {
      // formRef.current.resetFields();
      history.push("/enums");
    }
  };

  const validation = Yup.object().shape({
    info: Yup.object().shape({
      id: Yup.number(t("yup_id")),
      name: Yup.string().required(t("yup_name")),
      eCategory: Yup.string().nullable()
    })
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      // onPostLogin(values, resetForm);
    }
  });

  useEffect(
    () => {
      dispatch(setEnumData(formik.values));
    },
    [formik.values]
  );

  useEffect(
    () => {
      if (method === "create" && Object.keys(formik.touched).length) {
        history.replace({ state: { ...state, method: "create" } });
      }
    },
    [formik.touched]
  );

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={state.method == "edit" ? t("enums_edit") : t("enums_add")}
        small={t("enums_desc")}
      />
      <div className="risk-page">
        <div className="risk-page__elements">
          <h2>
            {t("global_info")}
          </h2>
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label="ID"
                formik={formik}
                field="id"
                type="number"
                required={false}
                visibilty={state.method == "edit" ? true : false}
                disabled={state.method == "edit" ? true : false}
                isFake={true}
              />
              <InputBox
                label={t("enum_key_category")}
                formik={formik}
                field="name"
                required={true}
              />
              {
                <SelectBox
                  label={t("enum_value")}
                  formik={formik}
                  field="eCategory"
                  data={enumCat}
                  type="complex"
                  settings={{
                    parser: { value: "id", label: "name" },
                    columns: ["id", "name"]
                  }}
                />
              }
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit myEvents={submitSettings} />
        </div>
      </div>
    </div>
  );
}

export default EnumsEdit;
