import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./assets/css/animate.css";
import "./assets/css/custom/custom.css";
import "antd/dist/antd.css";
import "react-calendar/dist/Calendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./assets/css/override/override.css";
import "./assets/css/global/global.scss";
import history from "./const/history";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./redux/store";
import "i18n";

// import './assets/css/main/main.css'
// import './assets/css/override.css';

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <App />
      </PersistGate>
    </Router>
  </Provider>,
  document.querySelector("#root")
);
