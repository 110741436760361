const Variables = [
    {
      name: "RESULT",
      type: "variable",
      format: [{ type: "rule", color:"#03a9f4", value: "(RESULT)" },],
    },
    {
      name: "FALSE",
      type: "variable",
      format: [{ type: "rule", color:"#03a9f4", value: "FALSE" },],
    },
    {
      name: "TRUE",
      type: "variable",
      format: [{ type: "rule", color:"#03a9f4", value: "TRUE" },],
    }
  ];

export default Variables;