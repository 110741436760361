import React, { useMemo, useState, useEffect } from "react";
import { Row, Col, Button, Collapse } from "antd";
import { isObject } from "utils/checking";
import ReactJson from "react-json-view";

const Update = ({ values }) => {
  let differencies = useMemo(() => {
    if (values?.info?.details?.first) {
      return Object.entries(values?.info?.details?.first).map(
        ([key, value]) => ({
          name: key,
          first: value,
          last: values?.info?.details?.last[key],
          isObject: isObject(value) ? true : Array.isArray(value),
        })
      );
    } else {
      return [];
    }
  }, [values?.info]);

  return (
    <Row gutter={24}>
      <Col span={24}>
        <div className="accordion-panel">
          <div className="accordion-panel__info grid text-center">
            <h4>Dəyişiklik olunan xananın adı</h4>
            <h4>Əvvəlki</h4>
            <h4>Sonrakı</h4>
          </div>
          {differencies.map((item, index) => {
            const { name, first, last, isObject } = item;
            return (
              <div key={index} className="accordion-panel__info grid">
                <span>{name}</span>
                <span>
                  {isObject ? (
                    <ReactJson src={first} collapsed />
                  ) : typeof first === "boolean" ? (
                    `${first}`
                  ) : (
                    first
                  )}
                </span>
                <span>
                  {isObject ? (
                    <ReactJson src={last} collapsed />
                  ) : typeof last === "boolean" ? (
                    `${last}`
                  ) : (
                    last
                  )}
                </span>
              </div>
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default Update;
