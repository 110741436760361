import { useState } from "react";

import API from "const/API2";
import { apiRoutes } from "const/apiroutes";
import GL from "libs/GL";

const api = new API();

function useAddfile({ getLastAdded, getTableDatas }) {
  const [addFilePopoverIsOpen, setAddFilePopoverIsOpen] = useState(false);
  const [fileList, setFileList] = useState([]);

  const addFile = ({ file, folderId, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);

    const url = folderId
      ? `${apiRoutes.library.postFile}?folderId=${folderId}`
      : apiRoutes.library.postFile;

    api.postData(
      url,
      formData,
      (_) => {
        onSuccess();
        getLastAdded && getLastAdded();
        getTableDatas && getTableDatas();
        setAddFilePopoverIsOpen(false);
        setFileList([]);
        GL.notify.success({ text: "Fayl yaradıldı" });
      },
      (err) => {
        onError();
        GL.notify.error({ text: err.message ?? "Fayl yaradıla bilmədi" });
      }
    );
  };

  return {
    setAddFilePopoverIsOpen,
    addFile,
    setFileList,
    fileList,
    addFilePopoverIsOpen,
  };
}
export default useAddfile;
