import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col } from "antd";
import PageTitle from "../../../Elements/PageTitle/PageTitle";
import FormSubmit from "../../../Elements/FormSubmit/FormSubmit";
import { useHistory } from "react-router-dom";
import GL from "../../../../libs/GL";
import InputBox from "../../../Elements/FileUploadForm/Form/InputBox";
import User from "../../../../models/User";
import Department from "../../../../models/Department";
import SelectBox from "../../../Elements/FileUploadForm/Form/SelectBox";
import Company from "../../../../models/Company";
import { useDispatch, useSelector } from "react-redux";
import { setDepartmentData } from "../../../../redux/actions";
import { useTranslation } from "react-i18next";
function DepartmentsEdit(props) {
  //debugger;
  const { t } = useTranslation();
  const history = useHistory();
  const [companyList, setCompanyList] = useState([]);
  const [managerList, setManagerList] = useState([]);

  var state = props.location.state;

  const dispatch = useDispatch();
  const reduxDepartment = useSelector(state => state.department);
  if (state === undefined) {
    state = reduxDepartment;
  }
  let initialValues;

  const { method, ...stateRest } = state;
  if (state.method === "create") {
    if (reduxDepartment !== undefined) {
      if (reduxDepartment.method === undefined) {
        reduxDepartment.method = "create";
      }
      initialValues = reduxDepartment;
    } else {
      initialValues = stateRest;
    }
  }
  if (state.method === "edit") {
    initialValues = stateRest;
  }
  if (state.method === "copy") {
    initialValues = stateRest;
    state.method = "create";
  }

  const populateList = () => {
    User.getUserList(data => {
      setManagerList(data);
    });
  };
  const companiesList = () => {
    Company.getCompanyList(data => {
      setCompanyList(data);
    });
  };

  useEffect(() => {
    formik.setFieldTouched("setFormikTouched");
    populateList();
    companiesList();
  }, []);

  var submitSettings = {
    type: state.method,
    save: async () => {
      //debugger;
      if (state.info.id === 0) {
        var sendData = await GL.getFormikData(formik, [
          "name",
          "totalName",
          "company",
          "manager"
        ]);
        Department.CREATE(sendData, res => {
          dispatch(
            setDepartmentData({
              info: Department.getEmptyState(),
              method: "create"
            })
          );
          GL.notify.success({ text: t("answer_success") });
          history.push("/departments");
        });
      }
    },
    edit: async () => {
      if (state.info.id !== 0) {
        var sendData = await GL.getFormikData(formik, [
          "id",
          "name",
          "totalName",
          "company",
          "manager"
        ]);
        Department.UPDATE(sendData, res => {
          dispatch(
            setDepartmentData({
              info: Department.getEmptyState(),
              method: "create"
            })
          );
          GL.notify.success({ text: t("answer_success") });
          history.push("/departments");
        });
      }
    },
    cancel: () => {
      history.push("/departments");
    }
  };

  const validation = Yup.object().shape({
    info: Yup.object().shape({
      id: Yup.string(),
      name: Yup.string().required(t("yup_name")),
      totalName: Yup.string().required(t("yup_totalname")),
      company: Yup.string().nullable(),
      manager: Yup.string().nullable()
    })
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      // onPostLogin(values, resetForm);
    }
  });

  useEffect(
    () => {
      //debugger;
      dispatch(setDepartmentData(formik.values));
    },
    [formik.values]
  );

  useEffect(
    () => {
      if (method === "create" && Object.keys(formik.touched).length) {
        history.replace({ state: { ...state, method: "create" } });
      }
    },
    [formik.touched]
  );

  return (
    <div className="main-layout-wrapper">
      <PageTitle
        big={
          state.method === "edit" ? t("departments_edit") : t("departments_add")
        }
        small={t("departments_desc")}
      />
      <div className="risk-page">
        <div className="risk-page__elements">
          <h2>
            {t("global_info")}
          </h2>
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              <InputBox
                label="ID"
                formik={formik}
                field="id"
                type="number"
                required={false}
                visibilty={state.method === "edit" ? true : false}
                disabled={state.method === "edit" ? true : false}
                isFake={true}
              />

              <InputBox
                label={t("global_name")}
                formik={formik}
                field="name"
                required={true}
              />
              <InputBox
                label={t("global_totalname")}
                formik={formik}
                field="totalName"
                required={true}
              />
            </Col>
            <Col sm={24} md={8} lg={8}>
              <SelectBox
                label={t("company")}
                formik={formik}
                field="company"
                data={companyList}
                type="complex"
                settings={{
                  parser: { value: "id", label: "totalName" },
                  columns: ["id", "name", "totalName"]
                }}
              />

              <SelectBox
                label={t("global_manager")}
                formik={formik}
                field="manager"
                data={managerList}
                type="complex"
                settings={{
                  parser: { value: "id", label: "name" },
                  columns: ["id", "name", "mail"]
                }}
              />
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit myEvents={submitSettings} />
        </div>
      </div>
    </div>
  );
}

export default DepartmentsEdit;
