import "./LibraryTable.scss";

import React, { Fragment, useState } from "react";
import dayjs from "dayjs";
import { Table, Popover, Skeleton, Empty, Button } from "antd";
import { MoreOutlined, StarOutlined, StarFilled } from "@ant-design/icons";

import { convertFileSize } from "utils/convertFileSize";
import ActionsPopover from "../ActionsPopover/ActionsPopover";
import RenderFileOrFolderIcon from "./RenderFileOrFolderIcon";
import useToggleIsFavorite from "../ActionsPopover/useToggleIsFavorite";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LibraryModel } from "models/Library";
import { fileTypeDownloadEnum } from "const/fileTypeDownloadEnum";
import Watermark from "@uiw/react-watermark";
import { useSelector } from "react-redux";
import { Modal } from "antd";

function LibraryTable({
  data,
  getData,
  getLastAdded = null,
  paginationProps = null,
  isLoading = false,
}) {
  const history = useHistory();
  const [showActionsPopover, setShowActionsPopover] = useState({});

  const size = paginationProps?.size;
  const handleClickPagination = paginationProps?.handleClickPagination;
  const dataTotalLength = paginationProps?.dataTotalLength;
  const page = paginationProps?.page;

  const { addToFavorites, deleteFromFavorites } = useToggleIsFavorite(getData);

  const postFileToLastWatched = (fileId) => {
    LibraryModel.postFileToLastWatched(
      fileId,
      (_) => {},
      (err) => {
        console.log("err ==>", err);
      }
    );
  };

  // ~~ WATERMARK IMPLEMENTATION
  const [showModal, setShowModal] = useState(false);
  const currentUserId = useSelector((state) => state.user.data.user.id);
  const currentUserName = useSelector((state) => state.user.data.user.name);
  const [frame, setFrame] = useState("");

  const closeModal = () => setShowModal(false);

  // ! getPdf() Here we change original url to blob url. Because if we use original url, the server responds with 403 (Forbidden error)
  const getPdf = (url) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const documentUrl = url;

    fetch(documentUrl, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        setFrame(
          <iframe
            src={`${blobUrl}#toolbar=0`}
            width="100%"
            height={screen.height - 300}
          />
        );
      })
      .catch((error) => console.log("Error fetching document:", error));
  };

  // ! getImg() and getDoc() methods must be seperate in table component. Otherwise it won't rerender.
  const getImg = (url) => {
    setFrame(
      <img src={url} className="modal-image" width="100%" height="auto" />
    );
  };

  const getDoc = (url) => {
    // ~ Embed google component for displaying xls and doc files
    setFrame(
      <iframe
        width="100%"
        height={screen.height - 300}
        src={`https://docs.google.com/viewer?url=${encodeURIComponent(
          url
        )}&embedded=true`}
      >
        {" "}
      </iframe>
    );
  };

  // Date properties
  const newDate = new Date();
  const date = `${newDate.getDate()}.${newDate.getMonth()}.${newDate.getFullYear()}`;
  const time = `${newDate.getHours()}:${newDate.getMinutes()}:${newDate.getSeconds()}`;

  const documentFetchers = {
    pdf: getPdf,
    png: getImg,
    jpg: getImg,
    jpeg: getImg,
    svg: getImg,
    xls: getDoc,
    xlsx: getDoc,
    doc: getDoc,
    docx: getDoc,
  };

  const columns = [
    {
      title: "",
      key: "isFavorite",
      dataIndex: "isFavorite",
      width: "10px",
      render: (_, rowData) => {
        if (rowData.isFavorite) {
          return (
            <Button
              onClick={() => deleteFromFavorites({ id: rowData.id })}
              className="last-viewed-table-row-favorite active"
            >
              <StarFilled />
            </Button>
          );
        } else {
          return (
            <Button
              className="last-viewed-table-row-favorite"
              onClick={() => addToFavorites({ id: rowData.id })}
            >
              <StarOutlined />
            </Button>
          );
        }
      },
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (_, rowData) => {
        if (rowData.isFolder) {
          const url = rowData.isCatalogFolder
            ? "/main/library/catalog/"
            : "/main/library/my-files/";
          return (
            <button
              onDoubleClick={() => {
                history.push(url + rowData.id);
              }}
              style={{
                width: "100%",
                cursor: "pointer",
                background: "transparent",
                border: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <RenderFileOrFolderIcon
                isFolder={rowData.isFolder}
                folderColor={rowData.color}
                type={rowData.mimeType}
                id={rowData.id}
              />
              {rowData.name}
            </button>
          );
        } else {
          return (
            <Fragment>
              <a
                className="render-file-or-folder-icon"
                onClick={() => {
                  setShowModal(true);
                  postFileToLastWatched(rowData.id);

                  // Select which function we will call according to file type
                  const mimeType = fileTypeDownloadEnum[rowData.mimeType];
                  if (documentFetchers[mimeType]) {
                    documentFetchers[mimeType](rowData.url);
                  }
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "10px",
                  color: "black",
                }}
              >
                <RenderFileOrFolderIcon
                  isFolder={rowData.isFolder}
                  folderColor={rowData.color}
                  type={rowData.mimeType}
                  id={rowData.id}
                />
                {rowData.name}
              </a>
              <Modal
                destroyOnClose
                open={showModal}
                title="Fayl"
                onCancel={closeModal}
                onOk={closeModal}
              >
                <Watermark
                  content={[
                    `${currentUserId} ${currentUserName},${date},${time}`,
                  ]}
                  rotate={20}
                  gapX={60}
                  gapY={100}
                  fontSize={10}
                  fontColor="#979797"
                  style={{ background: "#fff" }}
                >
                  {frame}
                </Watermark>
              </Modal>
            </Fragment>
          );
        }
      },
    },
    {
      title: "Qeydiyyata alan",
      key: "createdByName",
      dataIndex: "createdByName",
    },
    {
      title: "Həcmi",
      key: "size",
      dataIndex: "size",
      render: (sizeData) => {
        return sizeData ? convertFileSize(sizeData) : "-";
      },
    },
    {
      title: "Son dəyişiklik",
      key: "updatedAt",
      dataIndex: "updatedAt",
      render: (updatedAtData, rowData) => {
        const convertedDateString = dayjs(updatedAtData).format("DD.MM.YYYY");
        return (
          <span>
            {convertedDateString} - {rowData.updatedByName}
          </span>
        );
      },
    },
    {
      title: "Paylaşılanlar",
      key: "shared",
      dataIndex: "shared",
      render: (sharedData) => (
        <div className="last-viewed-table-row-shared">
          {sharedData?.map((person, idx) => (
            <span key={idx}>{person.firstLetter}</span>
          ))}
        </div>
      ),
    },
    {
      title: "",
      key: "actions",
      dataIndex: "actions",
      width: 10,
      render: (_, rowData) => {
        return (
          <Popover
            key={rowData.id}
            trigger="click"
            placement="left"
            open={showActionsPopover[rowData.id]}
            onOpenChange={(val) => {
              setShowActionsPopover({
                ...showActionsPopover,
                [rowData.id]: val,
              });
            }}
            content={
              <ActionsPopover
                rowData={rowData}
                getLastTableData={getData}
                getLastAdded={getLastAdded}
                setShowActionsPopover={setShowActionsPopover}
                showActionsPopover={showActionsPopover}
              />
            }
          >
            <button className="last-viewed-table-row-action-trigger">
              <MoreOutlined />
            </button>
          </Popover>
        );
      },
    },
  ];
  if (isLoading) {
    return (
      <>
        <Skeleton active />
        <Skeleton active />
      </>
    );
  }
  if (!data.length) {
    return <Empty description="Məlumat tapılmadı" />;
  }
  return (
    <Table
      bordered
      columns={columns}
      dataSource={data}
      pagination={
        paginationProps
          ? {
              position: ["topRight"],
              align: "center",
              onChange: (val) => handleClickPagination(val - 1),
              pageSize: size,
              total: dataTotalLength,
              current: Number(page) + 1,
            }
          : false
      }
      rowKey={(r) => r.id}
    />
  );
}

export default LibraryTable;
