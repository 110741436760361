import React, { useEffect, useState } from "react";
import { Select } from "antd";

import FormErrorHandler from "../FileUploadForm/Form/FormErrorHandler";
import ApiMethods from "const/API2";
import { apiRoutes } from "const/apiroutes";

function GroupsUsersSelect(props) {
  const API = new ApiMethods();
  const { Option } = Select;

  var formik = props.formik;
  var field = props.field;
  var label = props.label;
  var onSelect =
    props["onSelect"] === undefined ? undefined : props["onSelect"];
  var required =
    props.required === undefined
      ? false
      : props.required === true
      ? true
      : false;
  var disabled =
    props.disabled === undefined
      ? false
      : props.disabled === true
      ? true
      : false;

  const [kuratorList, setKuratorList] = useState([]);

  const inputHasError = (string) => {
    const keys = string.split(".");
    let fieldTouched = formik.touched;
    let fieldErrors = formik.errors;
    keys.forEach((keyStr) => {
      fieldTouched = fieldTouched?.[keyStr];
      fieldErrors = fieldErrors?.[keyStr];
    });

    if (fieldTouched && fieldErrors) {
      return true;
    }

    return false;
  };

  const getKuratorDropdown = async () => {
    const usersGroups = [];

    const groupData = await API.getDataSync(apiRoutes.userGroups.list);
    const usersData = await API.getDataSync(apiRoutes.user.list);

    if (groupData) {
      groupData.forEach((userGroup) => {
        usersGroups.push({
          value: `group_${userGroup.id}`,
          label: userGroup.name,
          type: "group",
        });
      });
    }
    if (usersData) {
      usersData.forEach((user) => {
        usersGroups.push({
          value: `user_${user.id}`,
          label: user.firstName + " " + user.lastName + " " + user.patronymic,
          type: "user",
        });
      });
    }

    setKuratorList(usersGroups);
  };

  const setFormValue = (val, option) => {
    formik.setFieldValue(`${field}`, val);
    if (onSelect !== undefined) {
      onSelect(val, option);
    }
  };

  useEffect(() => {
    getKuratorDropdown();
  }, []);

  return (
    <div className="input-box">
      <span className="input-box__title">
        {label}{" "}
        {required === true ? <span style={{ color: "red" }}>*</span> : null}
      </span>
      <Select
        disabled={disabled}
        showSearch
        optionFilterProp="children"
        allowClear
        // value={formik.values[field]}
        value={formik.getFieldProps(field).value}
        onChange={(value, option) => {
          setFormValue(value, option);
        }}
        onBlur={formik.handleBlur(`${field}`)}
        status={inputHasError(field) ? "error" : ""}
      >
        {kuratorList.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            type={option.type}
            label={option.label}
          >
            {option.label}
          </Option>
        ))}
      </Select>
      <FormErrorHandler formik={formik} field={`${field}`} />
    </div>
  );
}

export default GroupsUsersSelect;
