import React, { useState } from "react";
import {
  DownloadOutlined,
  PrinterOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, Row } from "antd";
import { useTranslation } from "react-i18next";
import GL from "libs/GL";
import ModalForm from "../ModalForm/ModalForm";
import CalendarModal from "components/Pages/Calendar/CalendarModal";

const { Search } = Input;

const TableToolbar = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const tableSettings = props.tableSettings;
  const buttonText = props.settings.buttonText;
  // ! settings for continue editing button -------------------------------------------------------------------
  const editButtonText = props.settings.editButtonText;
  const editDisabled = props.settings.editDisabled;
  const createIsDisabled = props.settings.createIsDisabled;
  const scenarioConfigs = props.settings.scenarioConfigs;
  const uploadFileDisabled = tableSettings.uploadFileDisabled;
  const exportIsDisabled = tableSettings.exportIsDisabled;
  const printIsDisabled = tableSettings.printIsDisabled;
  const searchIsDisabled = tableSettings.searchIsDisabled;
  const settingsIsDisabled = tableSettings.settingsIsDisabled;
  const [width, setWidth] = useState("250px");
  var searchText = "";
  const [modal, setModal] = useState(false);

  // ! end settings for continue editing button ---------------------------------------------------------------

  // ! settings for showCalendarBtn button -------------------------------------------------------------------
  const showCalendarBtn = props.settings.showCalendarBtn;

  const createNew = (event) => {
    props.settings.createNew(event);
  };

  const continueEditing = (event) => {
    props.settings.continueEditing(event);
  };

  const printTable = (event) => {
    if (!tableSettings.onRiskExportExcelAndPDF) {
      props.settings.printTable(event);
    } else {
      tableSettings.onRiskExportExcelAndPDF("pdf");
    }
  };

  const exportTable = (event) => {
    if (!tableSettings.onRiskExportExcelAndPDF) {
      props.settings.exportTable(event);
    } else {
      tableSettings.onRiskExportExcelAndPDF("excel");
    }
  };

  const uploadFile = (event) => {
    if (tableSettings.uploadFileFormSettings !== undefined) {
      var callback = tableSettings.uploadFileFormSettings.callback;
      var forms = tableSettings.uploadFileFormSettings.forms;
      var values = {};
      GL.openSimpleModal(
        "Lutfen bir Tur Seciniz",
        <ModalForm
          forms={forms}
          callback={(a) => {
            values = a;
          }}
        />,
        () => {
          callback(values);
        }
      );
    }
  };

  const searchFocused = () => {
    setWidth("100%");
  };

  const hideColumn = () => {
    props.settings.hideColumn();
  };

  const searchBlur = () => {
    if (searchText === "") {
      setWidth("250px");
    } else {
      setWidth("100%");
    }
  };

  const onSearch = (value) => {
    doSearch(value);
    searchText = value;
  };

  const handleEnterKeyPress = (event) => {
    searchText = event.target.value;
    doSearch(searchText);
  };

  const doSearch = (event) => {
    props.settings.doSearch(event);
  };

  const handleScenarioConfig = () => {
    props.settings.configScenario();
  };

  return (
    <>
      <Row gutter={24}>
        <Col sm={24} md={12} lg={10}>
          {!searchIsDisabled && (
            <Search
              placeholder={t("global_search")}
              allowClear
              style={{
                width: width,
                height: 40,
                marginBottom: 10,
                transition: "all 0.3s",
              }}
              onFocus={searchFocused}
              onBlur={searchBlur}
              onSearch={onSearch}
              onInput={handleEnterKeyPress}
            />
          )}
        </Col>
        <Col sm={24} md={12} lg={14}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {scenarioConfigs ? (
              <div style={{ marginLeft: "10px", height: "40px" }}>
                <Button
                  onClick={handleScenarioConfig}
                  // icon={<SettingOutlined />}
                  size={10}
                >
                  {t("scenaro_settings")}
                </Button>
              </div>
            ) : null}

            {uploadFileDisabled && (
              <div style={{ marginLeft: "10px", height: "40px" }}>
                <Button
                  onClick={uploadFile}
                  icon={<UploadOutlined />}
                  size={10}
                >
                  {t("globa_upload")}
                </Button>
              </div>
            )}

            {!exportIsDisabled && (
              <div style={{ marginLeft: "10px", height: "40px" }}>
                <Button
                  onClick={exportTable}
                  icon={<DownloadOutlined />}
                  size={10}
                >
                  {t("globa_export")}
                </Button>
              </div>
            )}

            {!printIsDisabled && (
              <div style={{ marginLeft: "10px" }}>
                <Button onClick={printTable} icon={<PrinterOutlined />}>
                  {t("global_print")}
                </Button>
              </div>
            )}

            {!editDisabled ? (
              <div style={{ marginLeft: "10px" }}>
                <Button
                  onClick={continueEditing}
                  size={"90px"}
                  style={{
                    backgroundColor: "#faad14 !important",
                    borderColor: "#faad14 !important",
                  }}
                >
                  {editButtonText}
                </Button>
              </div>
            ) : null}

            {!createIsDisabled ? (
              <div style={{ marginLeft: "10px" }}>
                <Button onClick={createNew} type={"primary"} size={"90px"}>
                  {buttonText}
                </Button>
              </div>
            ) : null}

            {showCalendarBtn ? (
              <div style={{ marginLeft: "10px" }}>
                <Button
                  onClick={() => setModal(true)}
                  type={"primary"}
                  size={"90px"}
                >
                  Kalendar baxış
                </Button>
              </div>
            ) : null}

            {!settingsIsDisabled && (
              <div style={{ marginLeft: "10px" }}>
                <Button
                  onClick={hideColumn}
                  icon={<SettingOutlined />}
                  size={10}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
      {modal && <CalendarModal show={modal} setShowModal={setModal} />}
    </>
  );
});

export default TableToolbar;
