import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import GL from "libs/GL";
import api from "const/API2";
import { apiRoutes } from "const/apiroutes";
import { myList } from "const/initialDatas";
import { setMyListData } from "redux/actions";
import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import SelectBox from "components/Elements/FileUploadForm/Form/SelectBox";
import DynamicField from "./DynamicField";
import { t } from "i18next";

function ColumnsEdit(props) {
  const {
    showModal,
    setShowModal,
    modalState,
    listFormik,
    listMethod,
    enumCategoryList,
  } = props;

  const API = new api();
  const dispatch = useDispatch();
  const reduxMyList = useSelector((state) => state.myList);

  // ! Dev: Aslan :
  const method = modalState?.method || "";
  const id = modalState?.id || "";

  const [enumArr, setEnumArr] = useState([]);
  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({
      info: Yup.object().shape({
        form_name: Yup.string().required(t("mylist_yup_column_name")),
        form_unique:
          method === "create"
            ? Yup.string().required(t("yup_choose_value"))
            : null,
        form_type:
          method === "create"
            ? Yup.string().required(t("yup_choose_value"))
            : null,
        form_field:
          method === "create"
            ? Yup.string().required(t("mylist_yup_colun_key"))
            : null,
      }),
    })
  );

  // ! ======================= FORMIK ==================== //

  let initialValues = myList;
  if (method === "create") {
    initialValues = myList;
    initialValues.info.name = reduxMyList.info.name;
    initialValues.info.description = reduxMyList.info.description;
    initialValues.info.fields = reduxMyList.info.fields;
    initialValues.info.form_name = "";
  }
  if (method === "edit") {
    initialValues = reduxMyList;
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (method === "create") {
        handleSubmit(values);
      }
      if (method === "edit") {
        handleEditRaw(values);
      }
    },
  });

  const submitForm = () => {
    formik.handleSubmit();
  };

  function handleSubmit(values) {
    const {
      form_name,
      form_type,
      form_field,
      form_nullable,
      form_default,
      form_unique,
      form_enum,
    } = values.info;
    const formikFieldsArrCopy = JSON.parse(JSON.stringify(values.info.fields));

    if (!formikFieldsArrCopy.some((data) => data.name === form_name)) {
      formikFieldsArrCopy.push({
        name: form_name,
        type: form_type,
        field: form_field,
        default: form_default,
        nullable: form_nullable,
        unique: form_unique,
        enum: form_enum,
      });

      formik.setFieldValue("info.fields", formikFieldsArrCopy);
      listFormik.setFieldValue("info.fields", formikFieldsArrCopy);
      listFormik.setFieldTouched("info.fields", true);
      setShowModal(false);
    } else {
      GL.notify.error({
        title: t("answer_success"),
        text: t("my_list_column_already_exist"),
      });
    }
  }

  function handleEditRaw(values) {
    const {
      form_name,
      form_type,
      form_field,
      form_nullable,
      form_default,
      form_unique,
      form_enum,
    } = values.info;
    let formikFieldsArrCopy = JSON.parse(JSON.stringify(values.info.fields));
    const editedData = formikFieldsArrCopy.map((field) => {
      if (field.name !== id) {
        return field;
      } else {
        return {
          ...field,
          name: form_name,
          type: form_type,
          field: form_field,
          nullable: form_nullable,
          default: form_default,
          unique: form_unique,
          enum: form_enum,
        };
      }
    });
    formik.setFieldValue("info.fields", editedData);
    listFormik.setFieldValue("info.fields", editedData);
    listFormik.setFieldTouched("info.fields", true);
    setShowModal(false);
  }

  const resetType = () => {
    formik.setFieldValue("info.form_default", "");
  };

  const getEnumForCategory = (value) => {
    resetType();

    if (value ?? false) {
      API.getData(`${apiRoutes.enums.search}${value}`, (data) => {
        const defaultValueArr = data.map((enumObj) => {
          return { value: enumObj.id, label: enumObj.name };
        });
        setEnumArr(defaultValueArr);
      });
    }
  };

  useEffect(() => {
    dispatch(setMyListData(formik.values));
  }, [formik.values]);

  useEffect(() => {
    if (formik.values.info.form_type === "enums" && method === "create") {
      setValidationSchema(
        Yup.object().shape({
          info: Yup.object().shape({
            form_enum: Yup.string().required(t("yup_choose_value")),
            form_name: Yup.string().required(t("mylist_yup_column_name")),
            form_unique:
              method === "create"
                ? Yup.string().required(t("yup_choose_value"))
                : null,
            form_type:
              method === "create"
                ? Yup.string().required(t("yup_choose_value"))
                : null,
            form_field:
              method === "create"
                ? Yup.string().required(t("mylist_yup_colun_key"))
                : null,
          }),
        })
      );
    }
  }, [formik.values.info.form_type]);

  useEffect(() => {
    if (method === "edit") {
      const formikValuesCopy = JSON.parse(JSON.stringify(formik.values.info));
      const selectedFieldObj = formik.values.info.fields?.find(
        (field) => field.name === id
      );

      getEnumForCategory(selectedFieldObj.enum);

      formik.setValues({
        info: {
          ...formikValuesCopy,
          form_name: selectedFieldObj.name,
          form_type: selectedFieldObj.type,
          form_field: selectedFieldObj.field,
          form_nullable: selectedFieldObj.nullable,
          form_default: selectedFieldObj.default,
          form_unique: selectedFieldObj.unique,
          form_enum: selectedFieldObj.enum,
        },
      });
    }
  }, []);

  // ! ======================= FORMIK ==================== //

  var listscheme = { parser: { value: "value", label: "label" } };

  return (
    <Modal
      title={
        method === "edit" ? t("mylist_edit_column") : t("mylist_create_column")
      }
      bodyStyle={{
        height: method === "create" || listMethod === "create2" ? 500 : 150,
      }}
      width={600}
      open={showModal}
      onOk={submitForm}
      onCancel={() => setShowModal(false)}
    >
      <div
        className="risk-page"
        style={{
          marginTop: "16px",
          paddingBottom: "0",
        }}
      >
        <div className="risk-page__elements">
          <InputBox
            label={t("mylist_column_name")}
            formik={formik}
            field="form_name"
            type="text"
            required={true}
          />
          <SelectBox
            label={t("mylist_data_type")}
            formik={formik}
            required={true}
            field="form_type"
            data={[
              { value: "bigint", label: "Rəqəm" },
              { value: "double precision", label: "Kəsrli sayı" },
              { value: "character varying", label: "Qısa Text" },
              { value: "text", label: "Uzun Text" },
              { value: "boolean", label: "True / False" },
              {
                value: "timestamp without time zone",
                label: "Tarix və saat",
              },
              { value: "date", label: "Tarix" },
              { value: "time without time zone", label: "Saat" },
              { value: "enums", label: "Enum Sabitleri" },
            ]}
            type="simple"
            settings={listscheme}
            visibilty={method == "edit" && listMethod == "edit" ? false : true}
            disabled={method == "edit" && listMethod == "edit" ? true : false}
            onSelect={resetType}
          />
          <InputBox
            label={t("mylist_column_key")}
            formik={formik}
            field="form_field"
            type="text"
            required={true}
            handleChange={(str) => GL.createFieldForColumn(str)}
            visibilty={method == "edit" && listMethod == "edit" ? false : true}
            disabled={method == "edit" && listMethod == "edit" ? true : false}
          />
          <SelectBox
            label={t("mylist_is_unique")}
            formik={formik}
            required={true}
            field="form_unique"
            data={[
              { value: true, label: "Bəli" },
              { value: false, label: "Xeyir" },
            ]}
            type="simple"
            settings={listscheme}
            visibilty={method == "edit" && listMethod == "edit" ? false : true}
            disabled={method == "edit" && listMethod == "edit" ? true : false}
          />
          <SelectBox
            label={t("mylist_data_is_required")}
            formik={formik}
            required={false}
            field="form_nullable"
            data={[
              { value: false, label: "Bəli" },
              { value: true, label: "Xeyir" },
            ]}
            type="simple"
            settings={listscheme}
            visibilty={method == "edit" && listMethod == "edit" ? false : true}
            disabled={method == "edit" && listMethod == "edit" ? true : false}
          />
          {formik.values.info.form_type === "enums" ? (
            <SelectBox
              label={t("mylist_enum_category")}
              formik={formik}
              required={true}
              field="form_enum"
              data={enumCategoryList}
              type="simple"
              settings={listscheme}
              visibilty={
                method == "edit" && listMethod == "edit" ? false : true
              }
              disabled={method == "edit" && listMethod == "edit" ? true : false}
              onSelect={getEnumForCategory}
            />
          ) : null}
          <DynamicField
            formik={formik}
            method={method}
            listMethod={listMethod}
            listscheme={listscheme}
            enumArr={enumArr}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ColumnsEdit;
