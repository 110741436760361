import ApiMethod from "const/API2";
import { apiRoutes } from "const/apiroutes";

const API = new ApiMethod();
export class LibraryModel {
  static postFileToLastWatched(id, callback, errorCallback) {
    API.postData(
      apiRoutes.library.postFileToLastWatched({ fileId: id }),
      {},
      (response) => {
        if (callback) callback(response);
      },
      (error) => {
        if (errorCallback) errorCallback(error);
      }
    );
  }
}
