import React, { useState } from "react";

function useDetailsFileOrFolder(rowData) {
  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);

  const showDetailsModal = () => {
    setDetailsModalIsOpen(true);
  };
  const hideDetailsModal = () => {
    setDetailsModalIsOpen(false);
  };

  return { detailsModalIsOpen, showDetailsModal, hideDetailsModal };
}

export default useDetailsFileOrFolder;
