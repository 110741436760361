import React, { useMemo, useEffect, useState } from "react";
import InputBox from "../FileUploadForm/Form/InputBox";
import { useFormik } from "formik";
import * as Yup from "yup";
import SelectBox from "../FileUploadForm/Form/SelectBox";

const ModalForm = (props) => {
  const { forms, callback } = props;
  var initialData = {};
  var validationSchema = {};

  useEffect(() => {
    forms.forEach((f) => {
      if (f.validation !== undefined) {
        validationSchema[f.field] = f.validation;
      }
      switch (f.dataType) {
        case "string": {
          if (f.value !== undefined && f.value !== "") {
            initialData[f.field] = f.value;
          } else {
            initialData[f.field] = "";
          }
          break;
        }
        default: {
        }
      }
    });
  }, [forms]);

  const validation = Yup.object().shape({
    info: Yup.object().shape(validationSchema),
  });

  const [values, setValues] = useState(initialData);

  const formik = useFormik({
    initialValues: { info: initialData },
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {},
  });

  const inputChange = (form, data) => {
    var current_values = JSON.parse(JSON.stringify(values));
    current_values[form.field] = data;
    setValues(current_values);
    callback(current_values);
  };

  return (
    <>
      <div>Malumatlar</div>
      {forms.map((f, i) => {
        switch (f.type) {
          case "simpleselect": {
            return (
              <SelectBox
                key={i}
                label={f.label ?? ""}
                formik={formik}
                field={f.field}
                data={f.data ?? []}
                onSelect={(select) => {
                  inputChange(f, select);
                }}
                type="simple"
                settings={{
                  parser: { value: "value", label: "label" },
                  columns: [],
                }}
              />
            );
          }
          default: {
          }
        }
      })}
    </>
  );
};

export default ModalForm;
