import React, { useState, useEffect } from "react";
import DynamicTable from "components/Elements/DynamicTable/DynamicTable";
import FooterTools from "../../Components/FooterTools/FooterTools";
import Export from "components/Elements/Icons/Export";
import Notify from "../../Components/Notify/Notify";
import Risks from "models/Risks";
import { Skeleton, Result } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const ExecuteDetail = ({ addNewTab, addTabWithSubTab, deleteTab, pageId }) => {
  const { activeRole } = useSelector(({ user }) => ({
    activeRole: user.data.roles?.find(({ status }) => status),
  }));

  // const reduxUserId = useSelector((s) => s.user.data.user.id);
  
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  
  const tab = queryParams.get("tab");
  const userId = queryParams.get("userId");
  
  const [isOpenSubmitModal, setIsOpenSubmitModal] = useState(false);
  const onOpenSubmitModal = () => setIsOpenSubmitModal(true);
  const onCloseSubmitModal = () => setIsOpenSubmitModal(false);

  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const onOpenSuccessModal = () => setIsOpenSuccessModal(true);
  const onCloseSuccessModal = () => setIsOpenSuccessModal(false);

  const [isOpenRiskSubmitModal, setIsOpenRiskSubmitModal] = useState(false);
  const onOpenRiskSubmitModal = () => setIsOpenRiskSubmitModal(true);
  const onCloseRiskSubmitModal = () => setIsOpenRiskSubmitModal(false);

  const [isOpenExecutorModal, setIsOpenExecutorModal] = useState(false);
  const onOpenExecutorModal = () => setIsOpenExecutorModal(true);
  const onCloseExecutorModal = () => setIsOpenExecutorModal(false);

  const [isOpenExecuterSuccessModal, setIsOpenExecuterSuccessModal] = useState(false);
  const onOpenExecuterSuccessModal = () => setIsOpenExecuterSuccessModal(true);
  const onCloseExecuterSuccessModal = () => setIsOpenExecuterSuccessModal(false);

  const extraButtons = [
    {
      icon: <Export />,
      className: "btn-primary d-flex align-items-center justify-content-center",
      children: "Export",
    },
  ];

  const buttons = [
    {
      className: "btn-warning",
      children: "Geri ver",
      onClick: onOpenRiskSubmitModal,
    },
    {
      className: "btn-danger",
      children: "Geri çağırma et",
      onClick: onOpenExecutorModal,
    },
    {
      className: "btn-success",
      children: "Təsdiqə göndər",
      onClick: onOpenSubmitModal,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getRiskDetails = ({ url }) => {
    setIsLoading(true);
    Risks.getRiskDetails(
      url,
      (data) => {
        setData(data);
        setIsLoading(false);
      },
      () => {
        setData([]);
        setIsLoading(false);
      }
    );
  };

  // console.log("closable tabs execute table data ===>", data);

  useEffect(() => {
    if (tab === "risksInExecution") {
      getRiskDetails({
        url: `execution&roleId=${activeRole?.id}`,
      });
    }
    if (tab === "risksInExecutionOpen") {
      getRiskDetails({ url: `execution&roleId=${activeRole?.id}&isLate=false` });
    }
    if (tab === "risksInExecutionLate") {
      getRiskDetails({ url: `execution&roleId=${activeRole?.id}&isLate=true` });
    }
    if (tab === "risksInExecutionUser") {
      getRiskDetails({
        url: `execution&roleId=${activeRole?.id}&userId=${userId}`,
      });
    }
  }, [tab]);

  return (
    <>
      {isLoading && <Skeleton active />}
      {!isLoading && data.length > 0 && (
        <>
          <DynamicTable pageId={pageId} addTabWithSubTab={addTabWithSubTab} data={data} />

          <div className="main-layout-wrapper__footer">
            <FooterTools extraButtons={extraButtons} buttons={buttons} />
          </div>
        </>
      )}

      {!isLoading && data.length === 0 && (
        <Result status="404" title="Heç bir məlumat tapılmadı." />
      )}
      <Notify
        isOpenModal={isOpenSubmitModal}
        message="Təsdiqidici sənədlərlə birgə rəyiniz yuxarı vəzifəli şəxsə göndəriləcək və bundan sonra bu risk üzrə qərarınıza dəyişiklik edə bilməyəcksiniz. Bu əməliyyatı yerinə yetirmək istədiyinizdən əminsinizmi?"
        okButton
        okText="Bəli"
        onOk={() => {
          onCloseSubmitModal();
          onOpenSuccessModal();
        }}
        cancelText="Ləğv et"
        onCancel={onCloseSubmitModal}
      />
      <Notify
        isOpenModal={isOpenSuccessModal}
        message="XXXXXXX saylı risk üzrə qərarınız baxılması üçün uğurla yuxarı vəzifəli şəxsə göndərildi!"
        cancelText="Bağla"
        onCancel={onCloseSuccessModal}
      />
      <Notify
        isOpenModal={isOpenRiskSubmitModal}
        message="Uğurla yadda saxlanıldı!"
        cancelText="Bağla"
        onCancel={onCloseRiskSubmitModal}
        type="success"
      />
      <Notify
        isOpenModal={isOpenExecutorModal}
        message="Təkrar icra üçün icraçı ZO_VƏLİYEV-ə yönləndiriləcək. Bu şəxs əməliyyatın ilkin icraçısından fərqlidir. Bu əməliyyatı yerinə yetirmək istədiyinizdən əminsinizmi?"
        okButton
        okText="Bəli"
        onOk={() => {
          onCloseExecutorModal();
          onOpenExecuterSuccessModal();
        }}
        cancelText="Ləğv et"
        onCancel={onCloseExecutorModal}
      />
      <Notify
        isOpenModal={isOpenRiskSubmitModal}
        message="Uğurla geri verildi!"
        cancelText="Bağla"
        onCancel={onCloseRiskSubmitModal}
        type="success"
      />
      <Notify
        isOpenModal={isOpenExecuterSuccessModal}
        message="XXXXXXX saylı risk təkrar icra üçün uğurla ZO_VƏLİYEV-ə göndərildi"
        cancelText="Bağla"
        onCancel={onCloseExecuterSuccessModal}
        type="success"
      />
    </>
  );
};

export default ExecuteDetail;
