import "../../../../RiskPageStyles.scss";

import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Row, Col, Select } from "antd";

import FormulBuilder from "components/Elements/Formul/FormulBuilder";
import FormSubmit from "components/Elements/FormSubmit/FormSubmit2";
import JsonViewer from "components/Elements/JsonViewer/JsonViewer";
import XmlViewer from "components/Elements/XmlViewer/XmlViewer";
import DataParser from "../DataParser";

function Soap(props) {
  const { t } = useTranslation();
  const { formik, resetFormik, submitForm, inputHasError } = props;

  const [showHeaderModal, setShowHeaderModal] = useState(false);
  const [showBodyModal, setShowBodyModal] = useState(false);

  return (
    <div className="risk-page">
      <h1 className="risk-page__title">
        {`${t("stages_add_stage")} - ${t("stages_soap")}`}
      </h1>

      <div className="risk-page__elements">
        <Row gutter={24}>
          <Col span={24}>
            <div className="input-box">
              <span className="input-box__title">{t("global_method")}</span>
              <Select
                options={[
                  { value: "get", label: "GET" },
                  { value: "post", label: "POST" },
                ]}
                value={formik.values.stages.addStagesForm.soap.method}
                onChange={(value) =>
                  formik.setFieldValue(
                    "stages.addStagesForm.soap.method",
                    value
                  )
                }
                onBlur={formik.handleBlur("stages.addStagesForm.soap.method")}
                status={
                  inputHasError("stages.addStagesForm.soap.method")
                    ? "error"
                    : ""
                }
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.soap.method")
                  ? formik.errors.stages.addStagesForm.soap.method
                  : ""}
              </div>
            </div>

            <div className="input-box">
              <span className="input-box__title">{t("global_endpoint")}</span>
              <input
                type="text"
                className={
                  inputHasError("stages.addStagesForm.soap.endPoint")
                    ? "error"
                    : ""
                }
                {...formik.getFieldProps("stages.addStagesForm.soap.endPoint")}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.soap.endPoint")
                  ? formik.errors.stages.addStagesForm.soap.endPoint
                  : ""}
              </div>
            </div>
          </Col>
          <Col sm={24} md={8} lg={8}>
            <div className="input-box height-unset">
              <span className="input-box__title-with-btn">
                <span>{t("stages_headers")}</span>
                <button onClick={() => setShowHeaderModal(true)}>
                  {t("global_add_edit")}
                </button>
              </span>
              <JsonViewer
                formik={formik}
                src={formik.values.stages.addStagesForm.soap.headers}
                formikPath="stages.addStagesForm.soap.headers"
                isError={inputHasError("stages.addStagesForm.soap.headers")}
                open={showHeaderModal}
                setOpen={setShowHeaderModal}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.soap.headers")
                  ? "Body json boş qala bilməz və `null` dəyəri ala bilməz"
                  : ""}
              </div>
            </div>
          </Col>
          <Col sm={24} md={8} lg={8}>
            <div className="input-box height-unset">
              <span className="input-box__title-with-btn">
                <span>{t("stages_body")}</span>
                <button onClick={() => setShowBodyModal(true)}>
                  {t("global_add_edit")}
                </button>
              </span>
              <XmlViewer
                formik={formik}
                formikPath="stages.addStagesForm.soap.body"
                isError={inputHasError("stages.addStagesForm.soap.body")}
                error={formik.errors.stages?.addStagesForm?.soap?.body}
                open={showBodyModal}
                setOpen={setShowBodyModal}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.soap.body")
                  ? formik.errors.stages.addStagesForm.soap.body
                  : ""}
              </div>
            </div>
          </Col>
          <Col sm={24} md={8} lg={8}>
            <DataParser
              stageFormik={formik}
              formikPath="stages.addStagesForm.soap.parser"
            />
            <div className="input-box__error-field">
              {inputHasError("stages.addStagesForm.soap.parser")
                ? formik.errors.stages.addStagesForm.soap.parser
                : ""}
            </div>
          </Col>
          <Col span={24}>
            <h2 className="risk-page__title">{t("global_result")}</h2>
            <FormulBuilder
              formik={formik}
              formikPath="stages.addStagesForm.soap"
            />
            <div className="input-box__error-field">
              {inputHasError("stages.addStagesForm.soap.resultValid")
                ? formik.errors.stages.addStagesForm.soap.resultValid
                : ""}
            </div>
          </Col>
        </Row>
      </div>

      <div className="risk-page__submittion">
        <FormSubmit cancel={resetFormik} submit={submitForm} />
      </div>
    </div>
  );
}

export default Soap;
