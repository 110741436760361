import "../../../RiskPageStyles.scss";

import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import FormSubmit from "components/Elements/FormSubmit/FormSubmit2";
import PageTitle from "components/Elements/PageTitle/PageTitle";
import { scenario } from "const/initialDatas";
import { apiRoutes } from "const/apiroutes";
import { setRiskData } from "redux/actions";
import API from "const/API2";
import GL from "libs/GL";

function AddParameters(props) {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const history = useHistory();
  const pageState = props.location.state;
  const reduxRisk = useSelector((state) => state.risk);
  // ! Dev: Aslan :
  const method = pageState?.method || "";
  const id = pageState?.id || "";

  // ! "method" variable comes from source page. Has value of "create", "edit" or "copy"
  // ! "id" variable comes from source page. Has "id" of parameter
  // ! if "method" is edit, id is required

  const dispatch = useDispatch();
  var api = new API();
  const addParametersEndpoint = apiRoutes.parameters.add;
  const getParametersEndpoint = apiRoutes.parameters.get;
  const putParametersEndpoint = apiRoutes.parameters.put;

  // ! -------------------------- initial values for formik model ---------------------------//
  let initialValues = scenario;
  if (method === "create") {
    // ! on parameter create, current redux risk state is called to preserve addrisk tabs data. only parameters field is resetted
    initialValues = {
      ...reduxRisk,
      parameters: {
        addParametersForm: scenario.parameters.addParametersForm,
      },
    };
  }
  if (method === "edit") {
    // ! on parameter edit current redux risk state is called to preserve addrisk tabs data.
    // ! parameter field of redux risk will be set by "getParameterById" function onmount
    initialValues = reduxRisk;
  }
  if (method === "copy") {
    // ! parameter copy works like parameter create. Only difference - initial post data is sent inside pageState
    // ! reduxrisk data is preserved to sav the previous data from addrisk tabs
    delete pageState.id;
    const parameterFields = { ...pageState, isVoen: pageState.voen };
    initialValues = {
      ...reduxRisk,
      parameters: {
        addParametersForm: parameterFields,
      },
    };
  }
  // ! ------------------------ end initial values for formik model -------------------------//

  const postLoginSchema = Yup.object().shape({
    parameters: Yup.object().shape({
      addParametersForm: Yup.object().shape({
        name: Yup.string().required(t("yup_name")),
        code: Yup.string().required(t("yup_code")),
        type: Yup.string().required(t("yup_select")),
        isUnique: Yup.string().required(t("yup_select")),
        isVoen: Yup.string().required(t("yup_select")),
      }),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: postLoginSchema,
    onSubmit: (values) => {
      if (method === "create" || method === "copy") {
        postParameter(values);
      }
      if (method === "edit") {
        putParameter(values);
      }
    },
  });

  function resetFormik() {
    formik.setFieldValue(
      "parameters.addParametersForm",
      scenario.parameters.addParametersForm
    );
    history.replace({
      pathname: "add-risk",
      hash: "parameters",
      state: { method: "edit", id: reduxRisk.id },
    });
  }

  function submitForm() {
    formik.handleSubmit();
  }

  function postParameter(values) {
    const addParametersForm = values.parameters.addParametersForm;
    const { name, code, type, isUnique, isVoen, note } = addParametersForm;

    const data = {
      code: code,
      type: type,
      scenariosDto: {
        id: values.id, // ! scenario id
      },
      note: note,
      name: name,
      isUnique: isUnique,
      isVoen: isVoen,
    };

    api.postData(
      addParametersEndpoint,
      data,
      (res) => {
        resetFormik();
        history.replace({
          pathname: "add-risk",
          hash: "parameters",
          state: { method: "edit", id: values.id },
        });
        GL.notify.success({
          title: t("answer_success"),
          text: t("parameters_created"),
        });
      },
      (err) => {
        resetFormik();
        GL.notify.error({
          title: t("answer_error"),
          text: t("parameters_not_created"),
        });
        console.log("err =>", err);
      }
    );
  }

  function getParameterById(id) {
    api.getData(
      `${getParametersEndpoint}/${id}`,
      (data) => {
        const parameterData = JSON.parse(JSON.stringify(data));
        const { scenariosDto, ...rest } = parameterData;
        formik.setFieldValue("parameters.addParametersForm", rest);
      },
      (err) => {
        GL.notify.error({
          title: t("answer_error"),
          text: t("parameters_not_found"),
        });
        console.log("err =>", err);
      }
    );
  }

  function putParameter(values) {
    const addParametersForm = values.parameters.addParametersForm;
    const { name, code, type, isUnique, isVoen, note } = addParametersForm;

    const data = {
      code: code,
      type: type,
      scenariosDto: {
        id: values.id,
      },
      note: note,
      name: name,
      isUnique: isUnique,
      isVoen: isVoen,
    };

    api.putData(
      `${putParametersEndpoint}/${id}`,
      data,
      (res) => {
        resetFormik();
        history.replace({
          pathname: "add-risk",
          hash: "parameters",
          state: { method: "edit", id: values.id },
        });
        GL.notify.success({
          title: t("answer_success"),
          text: t("parameters_edited"),
        });
      },
      (err) => {
        resetFormik();
        GL.notify.error({
          title: t("answer_error"),
          text: t("parameters_not_edited"),
        });
        console.log("err =>", err);
      }
    );
  }

  const inputHasError = (string) => {
    const keys = string.split(".");
    let fieldTouched = formik.touched;
    let fieldErrors = formik.errors;
    keys.forEach((keyStr) => {
      fieldTouched = fieldTouched?.[keyStr];
      fieldErrors = fieldErrors?.[keyStr];
    });

    if (fieldTouched && fieldErrors) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (method === "edit") {
      getParameterById(id);
    }
  }, []);

  useEffect(() => {
    dispatch(setRiskData(formik.values));
  }, [formik.values]);

  return (
    <div className="main-layout-wrapper">
      <PageTitle big={t("scenarios")} small={t("scenario_desc")} />

      <div className="risk-page">
        <h1 className="risk-page__title">
          {method === "edit" ? t("parameters_edit") : t("parameters_add")}
        </h1>
        <div className="risk-page__elements">
          <Row gutter={24}>
            <Col sm={24} md={8} lg={8}>
              {formik.values.parameters.addParametersForm.id ? (
                // ! Dev: Aslan. Dont`t show id field if the value is empty
                <div className="input-box">
                  <span className="input-box__title">ID</span>
                  <input
                    type="number"
                    disabled={true}
                    className={
                      inputHasError("parameters.addParametersForm.id")
                        ? "error input-disabled"
                        : " input-disabled"
                    }
                    value={
                      formik.getFieldProps("parameters.addParametersForm.id")
                        .value
                    }
                  />
                  <div className="input-box__error-field">
                    {inputHasError("parameters.addParametersForm.id")
                      ? formik.errors.parameters.addParametersForm.id
                      : ""}
                  </div>
                </div>
              ) : null}
              <div className="input-box">
                <span className="input-box__title">{t("global_name")}</span>
                <input
                  type="text"
                  className={
                    inputHasError("parameters.addParametersForm.name")
                      ? "error"
                      : ""
                  }
                  {...formik.getFieldProps("parameters.addParametersForm.name")}
                />
                <div className="input-box__error-field">
                  {inputHasError("parameters.addParametersForm.name")
                    ? formik.errors.parameters.addParametersForm.name
                    : ""}
                </div>
              </div>
              <div className="input-box">
                <span className="input-box__title">{t("global_code")}</span>
                <input
                  type="text"
                  className={
                    inputHasError("parameters.addParametersForm.code")
                      ? "error"
                      : ""
                  }
                  value={formik.values.parameters.addParametersForm.code}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "parameters.addParametersForm.code",
                      GL.createFieldForColumn(e.target.value)
                    );
                  }}
                  onBlur={formik.handleBlur(
                    "parameters.addParametersForm.code"
                  )}
                />
                <div className="input-box__error-field">
                  {inputHasError("parameters.addParametersForm.code")
                    ? formik.errors.parameters.addParametersForm.code
                    : ""}
                </div>
              </div>
            </Col>
            <Col sm={24} md={8} lg={8}>
              <div className="input-box">
                <span className="input-box__title">{t("parameters_type")}</span>
                <Select
                  options={[
                    { value: "double", label: t("global_number") },
                    { value: "string", label: t("global_text") },
                    { value: "date", label: t("global_date") },
                  ]}
                  value={formik.values.parameters.addParametersForm.type}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "parameters.addParametersForm.type",
                      value
                    )
                  }
                  onBlur={formik.handleBlur(
                    "parameters.addParametersForm.type"
                  )}
                  status={
                    inputHasError("parameters.addParametersForm.type")
                      ? "error"
                      : ""
                  }
                />
                <div className="input-box__error-field">
                  {inputHasError("parameters.addParametersForm.type")
                    ? formik.errors.parameters.addParametersForm.type
                    : ""}
                </div>
              </div>
              <div className="input-box">
                <span className="input-box__title">
                  {t("parameters_identificator")}
                </span>
                <Select
                  options={[
                    { value: true, label: t("global_yes") },
                    { value: false, label: t("global_no") },
                  ]}
                  value={formik.values.parameters.addParametersForm.isUnique}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "parameters.addParametersForm.isUnique",
                      value
                    )
                  }
                  onBlur={formik.handleBlur(
                    "parameters.addParametersForm.isUnique"
                  )}
                  status={
                    inputHasError("parameters.addParametersForm.isUnique")
                      ? "error"
                      : ""
                  }
                />
                <div className="input-box__error-field">
                  {inputHasError("parameters.addParametersForm.isUnique")
                    ? formik.errors.parameters.addParametersForm.isUnique
                    : ""}
                </div>
              </div>
              <div className="input-box">
                <span className="input-box__title">
                  {t("parameters_is_voen")}
                </span>
                <Select
                  options={[
                    { value: true, label: t("global_yes") },
                    { value: false, label: t("global_no") },
                  ]}
                  value={formik.values.parameters.addParametersForm.isVoen}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "parameters.addParametersForm.isVoen",
                      value
                    )
                  }
                  onBlur={formik.handleBlur(
                    "parameters.addParametersForm.isVoen"
                  )}
                  status={
                    inputHasError("parameters.addParametersForm.isVoen")
                      ? "error"
                      : ""
                  }
                />
                <div className="input-box__error-field">
                  {inputHasError("parameters.addParametersForm.isVoen")
                    ? formik.errors.parameters.addParametersForm.isVoen
                    : ""}
                </div>
              </div>
            </Col>
            <Col sm={24} md={8} lg={8}>
              <div className="input-box">
                <span className="input-box__title">{t("global_qeyd")}</span>
                <TextArea
                  autoSize
                  rows={6}
                  {...formik.getFieldProps("parameters.addParametersForm.note")}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="risk-page__submittion">
          <FormSubmit cancel={resetFormik} submit={submitForm} />
        </div>
      </div>
    </div>
  );
}

export default AddParameters;
