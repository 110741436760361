import { SET_SUBLIST_INFO } from "../types";
import { sublist } from "const/initialDatas";

const sublistReducer = (state = sublist, action) => {
  switch (action.type) {
    case SET_SUBLIST_INFO:
      return { ...action.payload };
    default:
      return state;
  }
};

export default sublistReducer;
