import React from "react";
import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import TextareaBox from "components/Elements/FileUploadForm/Form/Textarea";
import SwitchBox from "components/Elements/FileUploadForm/Form/Switch";
import DateTimePicker from "components/Elements/FileUploadForm/Form/DatePicker";
import SelectBox from "components/Elements/FileUploadForm/Form/SelectBox";

const DynamicInputs = ({ field, formik, enumLists }) => {
  const { field: inputFieldName, type, name, nullable, enum: enumID } = field;

  let list = enumLists[enumID] || [];

  switch (type) {
    case "bigint":
      return (
        <InputBox
          label={name}
          field={inputFieldName}
          type="number"
          required={!nullable}
          formik={formik}
        />
      );

    case "double precision":
      return (
        <InputBox
          label={name}
          field={inputFieldName}
          type="number"
          required={!nullable}
          formik={formik}
        />
      );

    case "text":
      return (
        <TextareaBox
          rows={4}
          label={name}
          field={inputFieldName}
          required={!nullable}
          formik={formik}
        />
      );

    case "boolean":
      return (
        <SwitchBox
          label={name}
          field={inputFieldName}
          required={!nullable}
          formik={formik}
        />
      );

    case "date":
      return (
        <DateTimePicker
          label={name}
          field={inputFieldName}
          required={!nullable}
          formik={formik}
        />
      );

    case "time without time zone":
      return (
        <DateTimePicker
          label={name}
          field={inputFieldName}
          required={!nullable}
          formik={formik}
          type="time"
        />
      );
    case "timestamp without time zone":
      return (
        <DateTimePicker
          label={name}
          field={inputFieldName}
          required={!nullable}
          formik={formik}
          showTime
        />
      );
    case "enums":
      return (
        <SelectBox
          label={name}
          field={inputFieldName}
          required={!nullable}
          formik={formik}
          data={list}
        />
      );
    default:
      return (
        <InputBox
          label={name}
          field={inputFieldName}
          type="text"
          required={!nullable}
          formik={formik}
        />
      );
  }
};

export default DynamicInputs;
