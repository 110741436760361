import API from "../const/API2";
import { apiRoutes } from "../const/apiroutes";

var api = new API();

class Activity {

  constructor(obj) {
    this.id= obj['id']===undefined?null:obj['id'];
    this.numberInList= obj['numberInList']===undefined?null:obj['numberInList'];
    this.name= obj['name']===undefined?null:obj['name'];
    this.parentId= obj['parentId']===undefined?null:obj['parentId'];
    this.isProductiveActivity= obj['isProductiveActivity']===undefined?null:obj['isProductiveActivity'];
    this.explanation= obj['explanation']===undefined?null:obj['explanation'];
    this.isActive= obj['isActive']===undefined?null:obj['isActive'];
  }

  static getActivityFromArray(data){
    var arr = [];
    data.map((a)=>{
      var c = new Activity(a);
      arr.push(c);
    });
    return arr;
  }

  static getActivityList(callback){
    api.getData(apiRoutes.activities.list,(data)=>{
      var arr = Activity.getActivityFromArray(data);
      callback(true,arr);
    },(err)=>{
      callback(false,err);
    });
  }

  static UPDATE(data,callback,errCallback){
    if(data===false){
      return null;
    }
    var id = data['id'];
    
    if(id!==0 && id!=='' && id!==null && id!==undefined){
      api.putData(apiRoutes.activities.put+'/'+id,data,(res)=>{
        if(callback!==undefined){callback(res);}
      },errCallback);
    }
  }

  static CREATE(data,callback,errCallback){
    if(data===false){
      return null;
    }
    if(data['id']!==undefined){
      delete data['id'];
    }
    
    api.postData(apiRoutes.activities.add,data,(res)=>{
      if(callback!==undefined){callback(res);}
    },errCallback);
  }

  static DELETE(id,callback) {
    if (id === 0 || id === "" || id === null || id === undefined) {
      return null;
    }
    id = Number(id);
    api.putData(
      `${apiRoutes.activities.put}/${id}`,
      { isActive: false },
      (data) => {
        callback(true, data);
      },
      (err) => {
        callback(false, err);
      }
    );
  }
}

export default Activity;
