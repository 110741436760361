import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";

import { GetPagePermission, GetTableSettings } from "hooks/permissions";
import GL from "libs/GL";
import SuperTable from "components/Elements/Tabulator/SuperTable";
import MyListModel from "models/MyList";
import EmptyPage from "components/Elements/Skelethon/EmptyPage";

const pageId = "MyList";

function MyList() {
  const { t } = useTranslation();
  const history = useHistory();
  const [myList, setMyList] = useState([]);
  const [loading, setLoading] = useState(false);

  const permissions = GetPagePermission(21);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%"
    }),
    []
  );
  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function(data) {
        history.push({
          pathname: "my-list/create",
          state: {
            id: data.id,
            method: "edit"
          }
        });
      }
    },
    delete: {
      status: permissions["delete"],
      click: function(data) {
        GL.yesNo(t("question_deleting"), status1 => {
          if (status1) {
            MyListModel.DELETE(
              data.id,
              () => {
                GL.notify.success({
                  title: t("answer_success"),
                  text: t("mylist_deleted")
                });
                getMyList();
              },
              () => {
                GL.notify.error({
                  title: t("answer_error"),
                  text: t("mylist_not_deleted")
                });
              }
            );
          }
        });
      }
    },
    show: {
      status: true,
      click: function(data) {
        debugger;
        history.push({ pathname: "my-list/" + data.id });
      }
    },
    copy: {
      status: false,
      click: function(data) {
        history.push({
          pathname: "my-list/create",
          state: {
            id: data.id,
            method: "copy"
          }
        });
      }
    }
  };
  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/userGroups/add"
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/userGroups/multiDelete"
      }
    },
    settings: {
      id: pageId,
      type: "data",
      data: myList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "+ " + t("global_create", { name: t("mylist") }),
      createNewRoute: "my-list/create",
      state: {
        method: "create"
      }
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 70,

        formatter: function(cell) {
          return GL.setSelectStatus(cell);
        }
      },

      {
        title: t("global_name"),
        field: "name"
      },

      {
        title: t("mylist_table_desc"),
        field: "description"
      },

      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function(cell) {
          return GL.setButtons(cell, operatorSet);
        }
      }
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        name: {
          dataType: "text",
          placeholder: t("global_search")
        },
        description: {
          dataType: "text",
          placeholder: t("global_search")
        }
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto"
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {}
    }
  };

  async function getMyList() {
    setLoading(true);
    await MyListModel.LIST(
      data => {
        setMyList(data || []);
        setLoading(false);
      },
      err => {
        setLoading(false);
      }
    );
  }

  useEffect(() => {
    getMyList();
  }, []);

  if (loading) {
    return <Skeleton active />;
  } else {
    if (myList.length == 0) {
      return (
        <EmptyPage
          route="my-list/create"
          name="Siyahı"
          state={options.settings.state}
        />
      );
    } else {
      return (
        <div style={containerStyle}>
          <div className="main-layout-wrapper__head">
            <div className="simple-head">
              <h1>
                {t("mylists")}
              </h1>
              <span>
                {t("mylist_desc")}
              </span>
            </div>
          </div>
          <SuperTable data={options} />
        </div>
      );
    }
  }
}

export default MyList;
