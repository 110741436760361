import React, { useState, useEffect, useCallback, useMemo } from "react";
import Eye from "components/Elements/Icons/Eye";
import Attachment from "components/Elements/Icons/Attachment";
import Export from "components/Elements/Icons/Export";
import { roles } from "./useInvoice";

const useFooterButtons = (tools) => {
  const { formik, submitMethod, checkStatus, statuses, activeRole, nextUser } =
    tools;

  const iconButtonList = (onOpenFileModal, onOpenPoolModal) => {
    return [
      {
        icon: <Attachment />,
        className:
          "btn-danger d-flex align-items-center justify-content-center",
        onClick: onOpenFileModal,
        disabled: checkStatus(statuses[roles[activeRole.label]]),
      },
      {
        icon: <Eye />,
        className:
          "btn-danger d-flex align-items-center justify-content-center",
        onClick: onOpenPoolModal,
      },
    ];
  };

  const extraButtons = () => {
    return [
      {
        icon: <Export />,
        className:
          "btn-primary d-flex align-items-center justify-content-center",
        children: "Export",
      },
    ];
  };

  const buttons = (onOpenResendModal) => [
    {
      className: "btn-warning",
      children: "Yadda saxla",
      onClick: () => {
        submitMethod.current = "save";
        formik.handleSubmit();
      },
      disabled: checkStatus(statuses[roles[activeRole.label]]),
    },
    {
      className: "btn-danger",
      children: "İcraçıya geri ver",
      onClick: onOpenResendModal,
      // () => {
      //   submitMethod.current = "resend";
      //   formik.handleSubmit();
      // },
      isActive: Boolean(nextUser) && activeRole?.label !== "inspector",
      disabled: checkStatus(statuses[roles[activeRole.label]]),
    },
    {
      className: "btn-success",
      children: "Təsdiq et",
      onClick: () => {
        submitMethod.current = "approve";
        formik.handleSubmit();
      },
      isActive: true,
      disabled: checkStatus(statuses[roles[activeRole.label]]),
    },
  ];
  return { iconButtonList, extraButtons, buttons };
};

export default useFooterButtons;
