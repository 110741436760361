import API from "const/API2";
import GL from "libs/GL";
import { useState } from "react";

const api = new API();

function useAddFolder({
  getLastAdded = null,
  getTableDatas = null,
  setAddFilePopoverIsOpen,
  folderId = null,
}) {
  const [addFolderModalIsOpen, setAddFolderModalIsOpen] = useState(false);

  const closeAddFolder = () => {
    setAddFolderModalIsOpen(false);
  };

  const openAddFolder = () => {
    setAddFolderModalIsOpen(true);
    setAddFilePopoverIsOpen(false);
  };

  const submitAddFolder = () => {
    debugger;
    getLastAdded && getLastAdded();
    getTableDatas && getTableDatas();
    GL.notify.success({ text: "Qovluq yaradıldı" });
  };

  return {
    addFolderModalIsOpen,
    closeAddFolder,
    openAddFolder,
    submitAddFolder,
  };
}

export default useAddFolder;
