import "../../../../RiskPageStyles.scss";

import { Row, Col, Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import { useEffect } from "react";

import FormSubmit from "components/Elements/FormSubmit/FormSubmit2";
import { apiRoutes } from "const/apiroutes";
import API from "const/API2";
import GL from "libs/GL";

function Info(props) {
  const { t } = useTranslation();
  const { formik, resetFormik, submitForm, inputHasError, formConfigs } = props;
  const { queryTypeEnum, repeatErrEnum, controlRuleEnum, queryTypeValue } =
    formConfigs;

  const api = new API();
  const reduxRisk = useSelector((s) => s.risk);

  const { TextArea } = Input;
  const [parameterList, setParameterList] = useState([]);

  const getParameters = () => {
    const getValidArray = (parametersArr) => {
      var newArr = [];
      parametersArr.forEach((param) => {
        newArr.push({
          value: param.id,
          label: param.name,
        });
      });
      return newArr;
    };
    api.getData(
      apiRoutes.parameters.search + reduxRisk.id,
      (data) => {
        let d = getValidArray(data);
        setParameterList(d);
      },
      (err) => {
        GL.notify.error({
          title: t("answer_error"),
          text: t("parameters_get_error"),
        });
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getParameters();
  }, []);

  return (
    <div className="risk-page">
      <h1 className="risk-page__title">{t("stages_add_stage")}</h1>
      <div className="risk-page__elements">
        <Row gutter={24}>
          <Col sm={24} md={12} lg={12}>
            {formik.values.stages.addStagesForm.info.id ? (
              // Dev: Aslan. Dont`t show id field if the value is empty
              <div className="input-box">
                <span className="input-box__title">ID</span>
                <input
                  type="number"
                  disabled={true}
                  className={
                    inputHasError("stages.addStagesForm.info.id")
                      ? "error input-disabled"
                      : "input-disabled"
                  }
                  value={
                    formik.getFieldProps("stages.addStagesForm.info.id").value
                  }
                />
                <div className="input-box__error-field">
                  {inputHasError("stages.addStagesForm.info.id")
                    ? formik.errors.stages.addStagesForm.info.id
                    : ""}
                </div>
              </div>
            ) : null}
            <div className="input-box">
              <span className="input-box__title">
                {t("stages_order_number")}
              </span>
              <input
                type="number"
                className={
                  inputHasError("stages.addStagesForm.info.orderNum")
                    ? "error"
                    : ""
                }
                {...formik.getFieldProps("stages.addStagesForm.info.orderNum")}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.info.orderNum")
                  ? formik.errors.stages.addStagesForm.info.orderNum
                  : ""}
              </div>
            </div>
            <div className="input-box">
              <span className="input-box__title">{t("global_name")}</span>
              <input
                type="text"
                className={
                  inputHasError("stages.addStagesForm.info.name") ? "error" : ""
                }
                {...formik.getFieldProps("stages.addStagesForm.info.name")}
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.info.name")
                  ? formik.errors.stages.addStagesForm.info.name
                  : ""}
              </div>
            </div>
            {controlRuleEnum.queryType ? (
              <div className="input-box">
                <span className="input-box__title">
                  {t("global_query_type")}
                </span>
                <Select
                  options={queryTypeEnum.optionsArr}
                  value={formik.values.stages.addStagesForm.info.queryType}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "stages.addStagesForm.info.queryType",
                      value
                    )
                  }
                  onBlur={formik.handleBlur(
                    "stages.addStagesForm.info.queryType"
                  )}
                  status={
                    inputHasError("stages.addStagesForm.info.queryType")
                      ? "error"
                      : ""
                  }
                />
                <div className="input-box__error-field">
                  {inputHasError("stages.addStagesForm.info.queryType")
                    ? formik.errors.stages.addStagesForm.info.queryType
                    : ""}
                </div>
              </div>
            ) : null}
            <div className="input-box">
              <span className="input-box__title">{t("global_status")}</span>
              <Select
                options={[
                  { value: true, label: t("global_active") },
                  { value: false, label: t("global_deactive") },
                ]}
                value={formik.values.stages.addStagesForm.info.status}
                onChange={(value) =>
                  formik.setFieldValue(
                    "stages.addStagesForm.info.status",
                    value
                  )
                }
                onBlur={formik.handleBlur("stages.addStagesForm.info.status")}
                status={
                  inputHasError("stages.addStagesForm.info.status")
                    ? "error"
                    : ""
                }
              />
              <div className="input-box__error-field">
                {inputHasError("stages.addStagesForm.info.status")
                  ? formik.errors.stages.addStagesForm.info.status
                  : ""}
              </div>
            </div>
            {controlRuleEnum.repeatErr ? (
              <div className="input-box">
                <span className="input-box__title">
                  {t("stages_repeaterr")}
                </span>
                <Select
                  options={[
                    { value: true, label: "Hə" },
                    { value: false, label: "Yox" },
                  ]}
                  value={formik.values.stages.addStagesForm.info.repeatErr}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "stages.addStagesForm.info.repeatErr",
                      value
                    )
                  }
                  onBlur={formik.handleBlur(
                    "stages.addStagesForm.info.repeatErr"
                  )}
                  status={
                    inputHasError("stages.addStagesForm.info.repeatErr")
                      ? "error"
                      : ""
                  }
                />
                <div className="input-box__error-field">
                  {inputHasError("stages.addStagesForm.info.repeatErr")
                    ? formik.errors.stages.addStagesForm.info.repeatErr
                    : ""}
                </div>
              </div>
            ) : null}
          </Col>
          <Col sm={24} md={12} lg={12}>
            {repeatErrEnum.repeatPer ? (
              <div className="input-box">
                <span className="input-box__title">
                  {t("stages_repeatper")}
                </span>
                <input
                  type="number"
                  className={
                    inputHasError("stages.addStagesForm.info.repeatPer")
                      ? "error"
                      : ""
                  }
                  {...formik.getFieldProps(
                    "stages.addStagesForm.info.repeatPer"
                  )}
                />
                <div className="input-box__error-field">
                  {inputHasError("stages.addStagesForm.info.repeatPer")
                    ? formik.errors.stages.addStagesForm.info.repeatPer
                    : ""}
                </div>
              </div>
            ) : null}
            {repeatErrEnum.repeatLast ? (
              <div className="input-box">
                <span className="input-box__title">
                  {t("stages_repeatlast")}
                </span>
                <input
                  type="number"
                  className={
                    inputHasError("stages.addStagesForm.info.repeatLast")
                      ? "error"
                      : ""
                  }
                  {...formik.getFieldProps(
                    "stages.addStagesForm.info.repeatLast"
                  )}
                />
                <div className="input-box__error-field">
                  {inputHasError("stages.addStagesForm.info.repeatLast")
                    ? formik.errors.stages.addStagesForm.info.repeatLast
                    : ""}
                </div>
              </div>
            ) : null}
            {/* {queryTypeEnum.checkParametr ? (
              <div className="input-box">
                <span className="input-box__title">
                  {t("stages_checkparameter")}
                </span>
                <Select
                  options={parameterList}
                  value={formik.values.stages.addStagesForm.info.checkParametr}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "stages.addStagesForm.info.checkParametr",
                      value
                    )
                  }
                  onBlur={formik.handleBlur(
                    "stages.addStagesForm.info.checkParametr"
                  )}
                  status={
                    inputHasError("stages.addStagesForm.info.checkParametr")
                      ? "error"
                      : ""
                  }
                />
                <div className="input-box__error-field">
                  {inputHasError("stages.addStagesForm.info.checkParametr")
                    ? formik.errors.stages.addStagesForm.info.checkParametr
                    : ""}
                </div>
              </div>
            ) : null}
            {queryTypeEnum.controlRule ? (
              <div className="input-box">
                <span className="input-box__title">
                  {t("stages_control_rule")}
                </span>
                <Select
                  options={controlRuleEnum.optionsArr}
                  value={formik.values.stages.addStagesForm.info.controlRule}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "stages.addStagesForm.info.controlRule",
                      value
                    )
                  }
                  onBlur={formik.handleBlur(
                    "stages.addStagesForm.info.controlRule"
                  )}
                  status={
                    inputHasError("stages.addStagesForm.info.controlRule")
                      ? "error"
                      : ""
                  }
                />
                <div className="input-box__error-field">
                  {inputHasError("stages.addStagesForm.info.controlRule")
                    ? formik.errors.stages.addStagesForm.info.controlRule
                    : ""}
                </div>
              </div>
            ) : null} */}
            {queryTypeEnum.compareValue ? (
              <div className="input-box">
                <span className="input-box__title">
                  {t("stages_compare_value")}
                </span>
                <input
                  type="text"
                  disabled="true"
                  className="input-disabled"
                  value={
                    formik.values.stages.addStagesForm[queryTypeValue]
                      ?.result || ""
                  }
                />
              </div>
            ) : null}
            {/* {queryTypeEnum.python ? (
              <div className="input-box">
                <span className="input-box__title">
                  {t("stages_select_phyton")}
                </span>
                <input
                  type="text"
                  className={
                    inputHasError("stages.addStagesForm.python") ? "error" : ""
                  }
                  {...formik.getFieldProps("stages.addStagesForm.python")}
                />
                <div className="input-box__error-field">
                  {inputHasError("stages.addStagesForm.python")
                    ? formik.errors.stages.addStagesForm.python
                    : ""}
                </div>
              </div>
            ) : null} */}
            <div className="input-box">
              <span className="input-box__title">{t("global_qeyd")}</span>
              <TextArea
                autoSize
                rows={6}
                {...formik.getFieldProps("stages.addStagesForm.info.note")}
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="risk-page__submittion">
        <FormSubmit cancel={resetFormik} submit={submitForm} />
      </div>
    </div>
  );
}

export default Info;
