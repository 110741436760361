import { SET_USERS_INFO, SET_USER_GROUP_INFO } from "../types";
import { userGroup, userData } from "const/initialDatas";

const userReducer = (state = userData, action) => {
  switch (action.type) {
    case SET_USERS_INFO:
      return { ...action.payload };
    default:
      return state;
  }
};

export const userGroupReducer = (state = userGroup, action) => {
  switch (action.type) {
    case SET_USER_GROUP_INFO:
      return { ...action.payload };
    default:
      return state;
  }
};

export default userReducer;
