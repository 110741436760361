import { useState, useEffect, useCallback } from "react";
import Enum from "models/Enum";

const useCategories = () => {
  const [commentCategories, setCommentCategories] = useState([]);

  const getCommentsCategories = useCallback(() => {
    Enum.getEnumsByCategoryID(19, (data) => {
      setCommentCategories(data);
    });
  }, []);

  useEffect(() => {
    getCommentsCategories();
  }, []);

  return { commentCategories };
};

export default useCategories;
