import ApiMethods from "const/API2";
import { apiRoutes } from "const/apiroutes";
import GL from "libs/GL";
import i18n from "i18n";

const { t } = i18n;
const API = new ApiMethods();

class Separation {
  static getValidPostBody = (values) => {
    const {
      name,
      type,
      status,
      repeat,
      repeatPer,
      sqlJson,
      sqlText,
      precedent,
      note,
      tags,
      userType,
      usersGroupsId,
      defaultStatus,
      lastExecDate,
    } = values;

    const postData = {
      name,
      type,
      status,
      repeat,
      repeatPer,
      sqlJson: type === "konstruktor" ? sqlJson : {},
      sqlText,
      precedent,
      note,
      tags,
      userType,
      lastExecDate,
      riskStatusDto: defaultStatus
        ? {
            id: defaultStatus,
          }
        : null,
    };

    if (userType === "user") {
      postData.usersDto = { id: +usersGroupsId.split("_")[1] };
    }
    if (userType === "group") {
      postData.userGroupDto = { id: +usersGroupsId.split("_")[1] };
    }

    return postData;
  };

  static TEST(sql,callback){
    API.postData(
      `${apiRoutes.separating.get}/showresult`,
      {query:sql},
      (data) => {
        callback(true,data);
      },
      (err) => {
        callback(false,err);
      }
    );
  }

  static RUN(id,callback){;
    API.getData(
      `${apiRoutes.separating.get}/run/${id}`,
      (data) => {
        callback(true,data);
      },
      (err) => {
        callback(false,err);
      }
    );
  }

  static async GET(id, callback, errCallback) {
    API.getData(
      `${apiRoutes.separating.get}/${id}`,
      (data) => {
        const { sqlJson, sqlText, defaultStatus, ...dataRest } = data;
        const separatingData = {
          info: {
            ...dataRest,
            defaultStatus: data.riskStatusDto?.id,
            sqlJson,
            sqlText,
            usersGroupsId: data.userId
              ? `user_${data.userId}`
              : `group_${data.userGroupId}`,
          },
        };
        callback(separatingData);
      },
      (err) => {
        errCallback(err);
      }
    );
  }

  static async LIST(callback, errCallback) {
    API.getData(
      apiRoutes.separating.list,
      (data) => {
        callback(data);
      },
      (err) => {
        errCallback(err);
      }
    );
  }

  static async PUT(id, putData, callback, errCallback) {
    const submitData = this.getValidPostBody(putData.info);
    API.putData(
      `${apiRoutes.separating.put}/${id}`,
      submitData,
      (data) => {
        callback(data);
        GL.notify.success({
          title: t("answer_success"),
          text: "Risklərin bölüşdürülməsi yeniləndi",
        });
      },
      (err) => {
        GL.notify.error({
          title: "Xəta !",
          text: "Risklərin bölüşdürülməsi yenilənmədi",
        });
        if (!errCallback) return;
        errCallback(err);
      }
    );
  }

  static async CREATE(postData, callback, errCallback) {
    const submitData = this.getValidPostBody(postData.info);
    API.postData(
      apiRoutes.separating.add,
      submitData,
      (data) => {
        callback(data);
        GL.notify.success({
          title: t("answer_success"),
          text: "Risklərin bölüşdürülməsi yaradıldı",
        });
      },
      (err) => {
        GL.notify.error({
          title: "Xəta !",
          text: "Risklərin bölüşdürülməsi yaradıla bilmədi",
        });
        if (!errCallback) return;
        errCallback(err);
      }
    );
  }

  static async DELETE(id, callback, errCallback) {
    API.deleteData(
      `${apiRoutes.separating.delete}/${id}`,
      (data) => {
        callback(data);
        GL.notify.success({
          title: t("answer_success"),
          text: "Risklərin bölüşdürülməsi silindi",
        });
      },
      (err) => {
        GL.notify.error({
          title: "Xəta !",
          text: "Risklərin bölüşdürülməsi silinmədi",
        });
        if (!errCallback) return;
        errCallback(err);
      }
    );
  }
}

export default Separation;
