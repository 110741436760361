import "../../../../RiskPageStyles.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Row, Col, Select } from "antd";

import FormulBuilder from "components/Elements/Formul/FormulBuilder";
import FormSubmit from "components/Elements/FormSubmit/FormSubmit2";
import { apiRoutes } from "const/apiroutes";
import API from "const/API2";
import GL from "libs/GL";
import MyList from "models/MyList";

function Info(props) {
  const { t } = useTranslation();
  const api = new API();
  const reduxRisk = useSelector((s) => s.risk);

  const { formik, resetFormik, submitForm, inputHasError, formConfigs } = props;
  const [parameterList, setParameterList] = useState([]);
  const [myList, setMyList] = useState([]);

  const getParameters = () => {
    const getValidArray = (parametersArr) => {
      var newArr = [];
      parametersArr.forEach((param) => {
        newArr.push({
          value: param.id,
          label: param.name,
        });
      });
      return newArr;
    };
    api.getData(
      apiRoutes.parameters.search + reduxRisk.id,
      (data) => {
        let d = getValidArray(data);
        setParameterList(d);
      },
      (err) => {
        GL.notify.error({
          title: t("answer_error"),
          text: "Parametr listi yüklənə bilmədi",
        });
        console.log(err);
      }
    );
  };

  const getMyLisit = () => {
    MyList.LIST(
      (data) => {
        const newMyListData = MyList.createSelectData(data);
        setMyList(newMyListData);
      },
      (err) => {
        console.log("my list err =>", err);
      }
    );
  };

  useEffect(() => {
    getParameters();
    getMyLisit();
  }, []);

  return (
    <div className="risk-page">
      <h1 className="risk-page__title">
        {`${t("stages_add")} - ${t("stages_static")}`}
      </h1>
      <div className="risk-page__elements">
        <Row gutter={24}>
          <Col sm={24} md={12} lg={12}>
            <div className="input-box">
              <span className="input-box__title">
                {t("stages_compare_value")}
              </span>
              <Select
                options={formConfigs.optionsArr}
                value={
                  formik.values.stages.addStagesForm.statik.chooseCompareOption
                }
                onChange={(value) =>
                  formik.setFieldValue(
                    "stages.addStagesForm.statik.chooseCompareOption",
                    value
                  )
                }
                onBlur={formik.handleBlur(
                  "stages.addStagesForm.statik.chooseCompareOption"
                )}
                status={
                  inputHasError(
                    "stages.addStagesForm.statik.chooseCompareOption"
                  )
                    ? "error"
                    : ""
                }
              />
              <div className="input-box__error-field">
                {inputHasError(
                  "stages.addStagesForm.statik.chooseCompareOption"
                )
                  ? formik.errors.stages.addStagesForm.statik
                      .chooseCompareOption
                  : ""}
              </div>
            </div>
          </Col>
          <Col sm={24} md={12} lg={12}>
            {formConfigs.static && (
              <div className="input-box">
                <span className="input-box__title">
                  {t("stages_static_value")}
                </span>
                <input
                  type="number"
                  className={
                    inputHasError("stages.addStagesForm.statik.static")
                      ? "error"
                      : ""
                  }
                  {...formik.getFieldProps(
                    "stages.addStagesForm.statik.static"
                  )}
                />
                <div className="input-box__error-field">
                  {inputHasError("stages.addStagesForm.statik.static")
                    ? formik.errors.stages.addStagesForm.statik.static
                    : ""}
                </div>
              </div>
            )}

            {formConfigs.parameter && (
              <div className="input-box">
                <span className="input-box__title">{t("parameter")}</span>
                <Select
                  options={parameterList}
                  value={formik.values.stages.addStagesForm.statik.parameter}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "stages.addStagesForm.statik.parameter",
                      value
                    )
                  }
                  onBlur={formik.handleBlur(
                    "stages.addStagesForm.statik.parameter"
                  )}
                  status={
                    inputHasError("stages.addStagesForm.statik.parameter")
                      ? "error"
                      : ""
                  }
                />{" "}
                <div className="input-box__error-field">
                  {inputHasError("stages.addStagesForm.statik.parameter")
                    ? formik.errors.stages.addStagesForm.statik.parameter
                    : ""}
                </div>
              </div>
            )}

            {formConfigs.list && (
              <div className="input-box">
                <span className="input-box__title">{t("stages_list")}</span>
                <Select
                  options={myList}
                  value={formik.values.stages.addStagesForm.statik.list}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "stages.addStagesForm.statik.list",
                      value
                    )
                  }
                  onBlur={formik.handleBlur("stages.addStagesForm.statik.list")}
                  status={
                    inputHasError("stages.addStagesForm.statik.list")
                      ? "error"
                      : ""
                  }
                />
                <div className="input-box__error-field">
                  {inputHasError("stages.addStagesForm.statik.list")
                    ? formik.errors.stages.addStagesForm.statik.list
                    : ""}
                </div>
              </div>
            )}
          </Col>
          <Col span={24}>
            <h2 className="risk-page__title">{t("global_result")}</h2>
            <FormulBuilder
              formik={formik}
              formikPath="stages.addStagesForm.statik"
            />
            <div className="input-box__error-field">
              {inputHasError("stages.addStagesForm.statik.resultValid")
                ? formik.errors.stages.addStagesForm.statik.resultValid
                : ""}
            </div>
          </Col>
        </Row>
      </div>

      <div className="risk-page__submittion">
        <FormSubmit cancel={resetFormik} submit={submitForm} />
      </div>
    </div>
  );
}

export default Info;
