import { DeleteOutlined, EditOutlined, MenuOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Modal } from "antd";
import * as Yup from "yup";

import SelectBox from "components/Elements/FileUploadForm/Form/SelectBox";
import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import "./DataParserCss.css";
import GL from "libs/GL";

const DataParser = ({ stageFormik, formikPath }) => {
  const { t } = useTranslation();

  const [draggedIndex, setDraggedIndex] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [dragStarted, setDragStarted] = useState(false);

  const stageFormikParserValue = stageFormik.getFieldProps(formikPath).value;

  const [data, setData] = useState(stageFormikParserValue || []);

  useEffect(() => {
    stageFormik.setFieldValue(formikPath, data);
  }, [data]);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleFormSubmit = (values) => {
    setData((prevData) => [...prevData, values]);
    setModalVisible(false);
  };

  const types = [
    { value: "object", label: "Object" },
    { value: "array", label: "Array" },
    { value: "variable", label: "Variable Property" },
  ];

  const dataTypes = [
    { value: "number", label: t("global_number") },
    { value: "string", label: t("dataparser_text") },
    { value: "boolean", label: t("stages_boolean") },
    { value: "date", label: t("global_date") },
    { value: "datetime", label: t("global_date_time") },
  ];

  var initialValues = {
    info: {
      type: "object",
      property: "",
      index: "",
      datatype: "",
      mode: "create",
      i: 0,
    },
  };

  const validation = Yup.object().shape({
    info: Yup.object().shape({
      type: Yup.string(t("yup_choose_value")),
      property: Yup.string().required(t("dataparser_yup_property")),
      index: Yup.number().required(t("dataparser_yup_index")),
      datatype: Yup.string().required(t("yup_choose_value")),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
  });

  const itemEdit = (i) => {
    debugger;
    var d = JSON.parse(JSON.stringify(data));
    var a = d[i];
    a.mode = "edit";
    a.i = i;
    formik.setFieldValue("info", a);
    setTimeout(() => {
      setModalVisible(true);
    }, 100);
  };

  const itemDelete = (i) => {
    var d = JSON.parse(JSON.stringify(data));
    var a = d[i];
    GL.yesNo(`${a.property} ${t("global_confirm_delete")}`, (res) => {
      if (res) {
        d.splice(i, 1);
        setData(d);
      }
    });
  };

  const handleOk = () => {
    var d = JSON.parse(JSON.stringify(data));
    var values = formik.values.info;
    var type = values.type;
    var property = values.property;
    var index = values.index;
    var datatype = values.datatype;

    var find = d.find((a) => a.property == property);
    var obj = {};
    switch (type) {
      case "object": {
        obj = { type: type, property: property, index: null, dataype: null };
        break;
      }
      case "array": {
        obj = { type: type, property: property, index: index, dataype: null };
        break;
      }
      case "variable": {
        obj = {
          type: type,
          property: property,
          index: null,
          dataype: datatype,
        };
        break;
      }
    }
    if (values.mode == "create") {
      if (find !== undefined) {
        GL.notify.warning({
          title: t("answer_warning"),
          text: `${property} ${t("dataparser_property_exists")}`,
        });
        return false;
      }
      d.push(obj);
    } else {
      var i = values.i;
      d[i] = obj;
    }
    setData(d);
    setModalVisible(false);
    formik.resetForm();
  };

  const handleDragStart = (e, index) => {
    setDragStarted(true);
    setDraggedIndex(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();

    const items = [...data];
    const draggedItem = items[draggedIndex];

    items.splice(draggedIndex, 1);
    items.splice(index, 0, draggedItem);

    setData(items);
    setDragStarted(false);
  };

  const scheme = { parser: { value: "value", label: "label" }, columns: [] };

  return (
    <>
      <div style={{ display: "flex" }}>
        <span
          style={{ display: "flex", justifyContent: "end" }}
          className="input-box__title-with-btn"
        >
          <button onClick={() => showModal(true)}>
            {t("dataparser") + " " + t("global_add")}
          </button>
        </span>

        <Modal
          title={t("dataparser")}
          open={modalVisible}
          onOk={handleOk}
          onCancel={handleModalCancel}
          closable={false}
          okText={t("global_add")}
          cancelText={t("global_cancel")}
          style={{ minWidth: "350px" }}
          bodyStyle={{
            minWidth: "300px",
            height: "auto",
            padding: "20px",
            backgroundColor: "#fff",
          }}
        >
          <div style={{ marginTop: "20px" }}></div>
          <SelectBox
            label={t("global_type")}
            formik={formik}
            field="type"
            data={types}
            type="simple"
            settings={scheme}
          />
          <div style={{ marginTop: "10px" }}></div>
          <InputBox
            label={t("global_name")}
            formik={formik}
            field="property"
            type="text"
            required={true}
          />
          {formik.values.info.type == "array" ? (
            <div>
              <div style={{ marginTop: "10px" }}></div>
              <InputBox
                label={t("dataparser_array_index")}
                formik={formik}
                field="index"
                type="number"
                required={true}
              />
            </div>
          ) : null}
          {formik.values.info.type == "variable" ? (
            <div>
              <div style={{ marginTop: "10px" }}></div>
              <SelectBox
                label={t("dataparser_data_type")}
                formik={formik}
                field="datatype"
                data={dataTypes}
                type="simple"
                settings={scheme}
              />
            </div>
          ) : null}
          <div style={{ marginTop: "10px" }}></div>
        </Modal>

        {/* DataParser ile ilgili diğer içerikler */}
      </div>
      <div className={"json-viewer dataparser-div1"}>
        {data.map((a, i) => {
          return (
            <div
              key={i}
              className={"dataparser-div2 movable"}
              draggable
              onDragStart={(e) => handleDragStart(e, i)}
              onDragLeave={(e) => handleDragOver(e, i)}
            >
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "5px" }}>
                  <MenuOutlined />
                </div>
                <div>
                  {a.property} : {a.type}
                  {a.type == "array"
                    ? ` (${a.index})`
                    : a.type == "variable"
                    ? ` (${a.dataype})`
                    : ""}
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div
                  className="dataparser-icon"
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                  onClick={itemEdit.bind(this, i)}
                >
                  <EditOutlined />
                </div>
                <div
                  onClick={itemDelete.bind(this, i)}
                  className="dataparser-icon"
                >
                  <DeleteOutlined />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DataParser;
