import API from "../const/API2";
import { apiRoutes } from "../const/apiroutes";
import EnumCategory from "./EnumCategory";

var api = new API();

class Enum {
  constructor(obj) {
    this.id = obj["id"];
    this.name = obj["name"];
    if (obj["enumCategoriesDto"] !== null) {
      this.enumCategoriesDto = new EnumCategory(obj["enumCategoriesDto"]);
    } else {
      this.enumCategoriesDto = new EnumCategory();
    }
    this.isActive = obj["isActive"];
  }

  getName() {
    return this.name;
  }

  static getEmptyState() {
    return {
      id: 0,
      name: "",
      enumCategoriesDto: null,
      isActive: true,
    };
  }

  static getEnumsFromArray(data) {
    var arr = [];
    data.map((a) => {
      var c = new Enum(a);
      arr.push(c);
    });
    return arr;
  }

  static getEnumsByCategoryID(categoryId, callback, errCallback) {
    if (!categoryId) return;
    api.getData(
      `${apiRoutes.enums.search}${categoryId}`,
      (response) => {
        const enumsFromArray = this.getEnumsFromArray(response);
        if (callback) callback(enumsFromArray);
      },
      (error) => {
        if (errCallback) errCallback(error);
      }
    );
  }

  static getEnumList(callback, errCallback) {
    api.getData(
      apiRoutes.enums.list,
      (data) => {
        var arr = Enum.getEnumsFromArray(data);
        callback(arr);
      },
      errCallback
    );
  }

  getTableItem() {
    return {
      id: this.id,
      name: this.name,
      eCategory: this.enumCategoriesDto.name,
      eCategoryId: this.enumCategoriesDto.id,
      isActive: this.isActive,
    };
  }

  static getForTableData(data) {
    var arr = [];
    data.map((a) => {
      arr.push(a.getTableItem());
    });
    return arr;
  }

  static sortDESC(a, b) {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    return 0;
  }

  static getArrayForSelect(data) {
    var arr = [];
    data.map((a) => {
      arr.push({
        value: a.id,
        label: a.name,
      });
    });
    return arr;
  }

  static UPDATE(data, callback, errCallback) {
    if (data == false) {
      return null;
    }
    var id = data["id"];
    if (data.enumCategoriesDto !== null) {
      data.enumCategoriesDto = {
        id: data.eCategory,
        name: data.eCategory,
      };
    } else {
      delete data.enumCategoriesDto;
    }
    if (id !== 0 && id !== "" && id !== null && id !== undefined) {
      api.putData(
        apiRoutes.enums.put + "/" + id,
        data,
        (res) => {
          if (callback !== undefined) {
            callback(res);
          }
        },
        errCallback
      );
    }
  }

  static CREATE(data, callback, errCallback) {
    if (data == false) {
      return null;
    }
    if (data["id"] !== undefined) {
      delete data["id"];
    }
    if (data.enumCategoriesDto !== null) {
      data.enumCategoriesDto = {
        id: data.eCategory,
        name: data.eCategory,
      };
    } else {
      delete data.enumCategoriesDto;
    }

    api.postData(
      apiRoutes.enums.add,
      data,
      (res) => {
        if (callback !== undefined) {
          callback(res);
        }
      },
      errCallback
    );
  }

  static DELETE(id, callback) {
    if (id == 0 || id == "" || id == null || id == undefined) {
      return null;
    }
    id = Number(id);
    api.putData(
      `${apiRoutes.enums.put}/${id}`,
      { isActive: false },
      (data) => {
        callback(true, data);
      },
      (err) => {
        callback(false, err);
      }
    );
  }
}

export default Enum;
