import React, { useEffect, useRef } from "react";
import go from "gojs";

const Diagram = ({ values, handleChange }) => {
  const $ = go.GraphObject.make;

  const diagramRef = useRef(null);
  const diagram = useRef(null);

  const model = values;

  function UpdateLinksAndNodes(evt) {
    const nodeDataArray = diagram?.current?.model?.nodeDataArray;
    const linkDataArray = diagram?.current?.model?.linkDataArray;

    const isLinkEvent =
      evt && (evt.name === "LinkDrawn" || evt.name === "LinkRelinked");
    if (isLinkEvent) {
      const link = evt.subject;
      const fromNode = link.fromNode;
      const selectedNodeKey = fromNode.key;

      const selectedNode =
        diagram?.current?.model?.findNodeDataForKey(selectedNodeKey);

      if (selectedNode) {
        let selectedNodeHaveOutgoingLinks = 0;

        linkDataArray.forEach((link) => {
          if (link.from === selectedNode.key) selectedNodeHaveOutgoingLinks++;
        });

        if (selectedNodeHaveOutgoingLinks > 1) {
          evt.diagram.remove(link);
        }
      }
    }

    handleChange({ nodeDataArray, linkDataArray });
  }

  function showLinkLabel(e) {
    var label = e.subject.findObject("LABEL");
    if (label !== null)
      label.visible = e.subject.fromNode.data.category === "Conditional";
  }

  function nodeStyle(e) {
    return [
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      {
        locationSpot: go.Spot.Center,
      },
    ];
  }

  function textStyle() {
    return {
      font: "bold 11pt Lato, Helvetica, Arial, sans-serif",
      stroke: "#F8F8F8",
    };
  }

  function makePort(name, align, spot, output, input) {
    var horizontal = align.equals(go.Spot.Top) || align.equals(go.Spot.Bottom);
    return $(go.Shape, {
      fill: "transparent",
      strokeWidth: 0,
      width: horizontal ? NaN : 8,
      height: !horizontal ? NaN : 8,
      alignment: align,
      stretch: horizontal ? go.GraphObject.Horizontal : go.GraphObject.Vertical,
      portId: name,
      fromSpot: spot,
      fromLinkable: output,
      toSpot: spot,
      toLinkable: input,
      cursor: "pointer",
      mouseEnter: (e, port) => {
        if (!e.diagram.isReadOnly) port.fill = "rgba(255,0,255,0.5)";
      },
      mouseLeave: (e, port) => (port.fill = "transparent"),
    });
  }

  useEffect(() => {
    if (!diagram?.current) {
      diagram.current = new go.Diagram(diagramRef.current, {
        LinkDrawn: showLinkLabel,
        LinkRelinked: showLinkLabel,
        "undoManager.isEnabled": true,
        // allowLink: false,
        // "linkingTool.direction": go.LinkingTool.ForwardsOnly,
        // allowDelete: false,
      });

      diagram.current.nodeTemplateMap.add(
        "Box",
        $(
          go.Node,
          "Table",
          nodeStyle(),
          { fromMaxLinks: 1 },
          $(
            go.Panel,
            "Auto",
            $(
              go.Shape,
              "RoundedRectangle",
              {
                fill: "#e3e3e3",
                stroke: "#00A9C9",
                strokeWidth: 2.5,
              },
              new go.Binding("figure", "figure")
            ),
            $(
              go.TextBlock,
              textStyle(),
              {
                margin: 8,
                maxSize: new go.Size(160, NaN),
                wrap: go.TextBlock.WrapFit,
                editable: false,
                stroke: "#333",
                font: "13px sans-serif",
              },
              new go.Binding("text").makeTwoWay()
            )
          ),
          makePort("T", go.Spot.Top, go.Spot.TopSide, false, true),
          makePort("L", go.Spot.Left, go.Spot.LeftSide, true, true),
          makePort("R", go.Spot.Right, go.Spot.RightSide, true, true),
          makePort("B", go.Spot.Bottom, go.Spot.BottomSide, true, false)
        )
      );

      diagram.current.nodeTemplateMap.add(
        "Start",
        $(
          go.Node,
          "Table",
          { deletable: false },
          nodeStyle(),
          $(
            go.Panel,
            "Spot",
            $(go.Shape, "Circle", {
              desiredSize: new go.Size(70, 70),
              fill: "#444",
              stroke: "#8bc34a",
              strokeWidth: 5,
            }),
            $(go.TextBlock, textStyle(), new go.Binding("text"))
          ),
          makePort("L", go.Spot.Left, go.Spot.Left, true, false),
          makePort("R", go.Spot.Right, go.Spot.Right, true, false),
          makePort("B", go.Spot.Bottom, go.Spot.Bottom, true, false)
        )
      );

      diagram.current.nodeTemplateMap.add(
        "Finish",
        $(
          go.Node,
          "Table",
          { deletable: false },
          nodeStyle(),
          $(
            go.Panel,
            "Spot",
            $(go.Shape, "Circle", {
              desiredSize: new go.Size(60, 60),
              fill: "#444",
              stroke: "#ff9800",
              strokeWidth: 5,
            }),
            $(go.TextBlock, "Finish", textStyle(), new go.Binding("text"))
          ),
          makePort("T", go.Spot.Top, go.Spot.Top, false, true),
          makePort("L", go.Spot.Left, go.Spot.Left, false, true),
          makePort("R", go.Spot.Right, go.Spot.Right, false, true)
        )
      );

      diagram.current.linkTemplate = $(
        go.Link,
        {
          routing: go.Link.AvoidsNodes,
          curve: go.Link.JumpOver,
          corner: 5,
          toShortLength: 4,
          relinkableFrom: true,
          relinkableTo: true,
          reshapable: true,
          resegmentable: true,
          mouseEnter: (e, link) =>
            (link.findObject("HIGHLIGHT").stroke = "rgba(30,144,255,0.2)"),
          mouseLeave: (e, link) =>
            (link.findObject("HIGHLIGHT").stroke = "transparent"),
          selectionAdorned: false,
        },
        new go.Binding("points").makeTwoWay(),
        $(go.Shape, {
          isPanelMain: true,
          strokeWidth: 8,
          stroke: "transparent",
          name: "HIGHLIGHT",
        }),
        $(
          go.Shape,
          { isPanelMain: true, stroke: "gray", strokeWidth: 2 },
          new go.Binding("stroke", "isSelected", (sel) =>
            sel ? "dodgerblue" : "gray"
          ).ofObject()
        ),
        $(go.Shape, { toArrow: "standard", strokeWidth: 0, fill: "gray" }),
        $(
          go.Panel,
          "Auto",
          {
            visible: false,
            name: "LABEL",
            segmentIndex: 2,
            segmentFraction: 0.5,
          },
          new go.Binding("visible", "visible").makeTwoWay(),
          $(go.Shape, "RoundedRectangle", { fill: "#F8F8F8", strokeWidth: 0 }),
          $(
            go.TextBlock,
            "Yes",
            {
              textAlign: "center",
              font: "10pt helvetica, arial, sans-serif",
              stroke: "#333333",
              editable: true,
            },
            new go.Binding("text").makeTwoWay()
          )
        )
      );

      diagram.current.addDiagramListener(
        "SelectionDeleting",
        UpdateLinksAndNodes
      );

      diagram.current.addDiagramListener(
        "ObjectSingleClicked",
        UpdateLinksAndNodes
      );

      diagram.current.addDiagramListener(
        "ExternalObjectsDropped",
        UpdateLinksAndNodes
      );

      diagram.current.addDiagramListener("PartResized", UpdateLinksAndNodes);

      diagram.current.addDiagramListener("PartRotated", UpdateLinksAndNodes);

      diagram.current.addDiagramListener("LinkDrawn", UpdateLinksAndNodes);

      diagram.current.addDiagramListener("LinkRelinked", UpdateLinksAndNodes);

      diagram.current.addDiagramListener("LinkReshaped", UpdateLinksAndNodes);

      diagram.current.addDiagramListener("SelectionMoved", UpdateLinksAndNodes);

      diagram.current.addChangedListener((e) => {
        if (e.change.xa === "Insert") {
          UpdateLinksAndNodes();
        }
      });

      diagram.current.toolManager.linkingTool.temporaryLink.routing =
        go.Link.Orthogonal;
      diagram.current.toolManager.relinkingTool.temporaryLink.routing =
        go.Link.Orthogonal;

      diagram.current.model = go.Model.fromJson(model);
    }
  }, []);

  useEffect(() => {
    diagram.current.model = go.Model.fromJson(values);
  }, [values.nodeDataArray.length]);

  return (
    <div
      ref={diagramRef}
      style={{
        width: "100%",
        height: "800px",
        backgroundColor: "#eee",
        borderRadius: "10px",
        marginTop: "10px",
      }}
    />
  );
};

export default Diagram;
