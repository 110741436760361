import React from "react";
import { Layout } from "antd";
import { Typography } from "antd";
import { Input, Space } from "antd";
import Button from "antd/lib/button";
import { SendOutlined, ExportOutlined} from "@ant-design/icons";
import "./RTMSMessenger.css";
import MessengerHeader from "./MessengerHeader";
import MessengerMenu from "./MessengerMenu";
import RecentsUsers from "./RecentsUsers";
import { useAppContext } from "./AppContext";
import InitialView from "./InitialView";
import MessagesContent from "./MessagesContent";
import MessengerUserInfo from "./UserInfo";
import GroupChat from "./GroupChat";
import { MessengerModel } from "./model/MessengerModel";
import ContactChat from "./ContactChat";
import MessagesContentGroup from "./MessagesContentGroup";
import MessagesContentGroupClick from "./MessagesContentGroupClick";
import useMySocket from "components/socket";
const { Header, Sider } = Layout;
const { Title } = Typography;
var XLSX = require("xlsx");

const RTMSMessenger = () => {
  const { state, dispatch } = useAppContext();
  const { mySocket: socket } = useMySocket();

  const {
    toggleChat,
    toggleInfo,
    selectedUser,
    isRecentOpen,
    isGroupOpen,
    isContactOpen,
    message,
    onClickId,
  } = state;

  const exportChat = () => {
    MessengerModel.handleChatMessageClick(
      selectedUser.toId ? selectedUser.toId : selectedUser.id,
      selectedUser.isGroup ? true : false,
      0,
      1000000000,
      (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data.messages);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Messages");
        XLSX.writeFile(workbook, "Messages.xlsx", { compression: true });
      }
    );
  };

  const sendMessage = () => {
    if (message) {
      const payload = {
        messageInfo: {
          to: selectedUser.toId ? selectedUser.toId : selectedUser.id,
          typeId: 4,
          groupId:
            selectedUser.isGroup ||
            (!selectedUser.isGroup && selectedUser.uid.startsWith("group"))
              ? onClickId
              : 1,

          content: message,
        },
        key: "message",
        type:
          selectedUser.isGroup ||
          (!selectedUser.isGroup && selectedUser.uid.startsWith("group"))
            ? "user-to-group"
            : "user-to-user",
      };

      socket.emit("message", payload);
      dispatch({ type: "SET_MESSAGE", payload: "" });
    }
  };

  return (
    <Layout hasSider style={{ backgroundColor: "#fff" }}>
      <Sider
        width={260}
        style={{
          height: "86vh",
          backgroundColor: "#F8F9FA",
          borderRadius: "9px",
          overflow: "hidden",
          overflowY: "scroll",
        }}
        className="left-sider"
      >
        <MessengerHeader />
        <MessengerMenu />

        {isRecentOpen && <RecentsUsers />}
        {isGroupOpen && <GroupChat />}
        {isContactOpen && <ContactChat />}
      </Sider>
      {toggleChat && isRecentOpen ? (
        <Space
          direction="vertical"
          style={{ height: "85vh", width: toggleInfo ? 700 : 800 }}
        >
          <Header
            style={{
              height: "7vh",
              backgroundColor: "#fff",
              boxShadow: "0px 11px 10px -15px #a6a7ae",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Title
              style={{
                color: "#404241",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => {
                dispatch({ type: "TOGGLE_INFO", payload: true });
                MessengerModel.getChatInfo(
                  selectedUser.toId,
                  selectedUser.isGroup,
                  (data) => {
                    console.log("INFO FROM REQUEST", data);
                    dispatch({ type: "SET_INFO", payload: data });
                  },
                  (err) => {
                    console.log("ERROR FROM INFO REQUEST", err);
                  }
                );
              }}
            >
              {selectedUser.name}
            </Title>
            <Button type="default" icon={<ExportOutlined />} onClick={() => exportChat()}>Export Chat</Button>
          </Header>

          {!selectedUser.isGroup && selectedUser.uid.startsWith("single") && (
            <MessagesContent />
          )}
          {selectedUser.isGroup && <MessagesContentGroup />}
          {!selectedUser.isGroup && selectedUser.uid.startsWith("group") && (
            <MessagesContentGroupClick />
          )}

          <Space
            style={{
              width: toggleInfo ? 700 : 800,
              alignSelf: "flex-end",
              backgroundColor: "#fff",
              borderTop: "1px solid #EBEBEB",
              borderBottom: "1px solid #EBEBEB",
              boxShadow: "0px 11px 10px -15px #a6a7ae",
            }}
          >
            <Input
              className="type-message-input"
              placeholder="Mesaj daxil et"
              style={{
                backgroundColor: "#fff",
                border: "none",
                width: toggleInfo ? 650 : 750,
                paddingLeft: "50px",
                height: "6vh",
                marginRight: "-8px",
              }}
              value={message}
              onChange={(e) =>
                dispatch({ type: "SET_MESSAGE", payload: e.target.value })
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevents adding a new line in the input
                  sendMessage();
                }
              }}
            />

            <Button
              style={{
                backgroundColor: "#C64D42",
                borderRadius: "20%",
                border: "none",
              }}
              onClick={sendMessage}
            >
              <SendOutlined
                style={{
                  fontSize: "16px",
                  color: "#fff",
                  boxShadow: "none",
                }}
              />
            </Button>
          </Space>
        </Space>
      ) : (
        <InitialView />
      )}

      {toggleChat && toggleInfo && isRecentOpen && <MessengerUserInfo />}
      {toggleChat && toggleInfo && isGroupOpen && <GroupInfo />}
    </Layout>
  );
};

export default RTMSMessenger;
