import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageTitle from "components/Elements/PageTitle/PageTitle";
import AntTabs from "components/Elements/Tabs/AntTabs";
import FormSubmit from "../../Elements/FormSubmit/FormSubmit2";
import { Info } from "./Info";
import { RiskDates } from "./RiskDates";
import { RiskHistory } from "./RiskHistory";
import { RiskFiles } from "./RiskFiles";
import { Parameters } from "./Parameters";
import GL from "libs/GL";
import RiskSendModal from "components/Elements/Comments/Modal";
import { useTranslation } from "react-i18next";
import { risk } from "const/initialDatas";

import Risks from "models/Risks";

const RiskEdit = (props) => {
  const { t } = useTranslation();
  const state = props.location?.state;
  const riskID = state?.info?.id;
  const history = useHistory();
  const hash = history.location.hash;
  const [activeTab, setActiveTab] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  var pathname = history.location.pathname;
  var poolOrUser = pathname == "/risk-list/user/edit" ? "user" : "pool";

  var pathConfig = {
    user: {
      list: "/risk-list/user",
    },
    pool: {
      list: "/risk-list/pool",
    },
  };

  useEffect(() => {
    if (!hash) {
      history.replace({ hash: "#info", state });
    }
    setActiveTab(hash);
  }, [hash]);

  function onTabsChange(keyStr) {
    history.push({ hash: keyStr, state });
  }

  const initialValues = risk;

  let validationSchema = Yup.object().shape({
    info: Yup.object().shape({
      id: Yup.number(),
      name: Yup.string().nullable(),
      riskStatus: Yup.string().nullable(),
      executor: Yup.string().nullable(),
      taxId: Yup.string().nullable(),
      riskType: Yup.string().nullable(),
      note: Yup.string().nullable(),
      precedent: Yup.string().nullable(),
      riskGroup: Yup.string().nullable(),
      curator: Yup.string().nullable(),
      createdAt: Yup.string().nullable(),
      lastExecuteDate: Yup.string().nullable(),
      tags: Yup.array(),
      riskStageHistory: Yup.array(),
      riskFiles: Yup.array(),
      parameters: Yup.array(),
      riskHistory: Yup.array(),
      riskComments: Yup.array(),
    }),
  });

  const getRiskById = (riskId) => {
    setIsLoading(true);
    Risks.getRiskByID(riskId, (data) => {
      const values = {
        ...data,
        division: data.hasWorkFlow ? 1 : 2,
      };
      formik.setValues({ info: { ...values } });
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getRiskById(riskID);
  }, [riskID]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      const data = { ...values.info };

      delete data.division;
      delete data.hasWorkFlow;
      delete data.curator;
      delete data.group;
      delete data.riskType;
      delete data.riskGroup;
      delete data.precedent;
      delete data.name;
      delete data.status;

      const body = {
        ...data,
      };

      Risks.Update(body, () => {
        resetForm();
        GL.notify.success({ text: "Risk yenilənməsi əməliyyatı uğurlu oldu" });
        history.push(pathConfig[poolOrUser].list);
      });
    },
  });

  function onSubmit() {
    formik.handleSubmit();
  }
  function onReset() {
    formik.handleReset();
  }

  const items = [
    {
      label: t("global_info"),
      children: <Info formik={formik} />,
      key: "#info",
    },
    {
      label: t("risk_dates"),
      children: (
        <RiskDates riskStageHistory={formik.values.info.riskStageHistory} />
      ),
      key: "#dates",
    },
    {
      label: t("risk_history_title"),
      children: <RiskHistory riskHistory={formik.values.info.riskHistory} />,
      key: "#changeDates",
    },
    {
      label: t("risk_files_title"),
      children: (
        <RiskFiles
          setFieldValue={formik.setFieldValue}
          riskFiles={formik.values.info.riskFiles}
          riskID={riskID}
        />
      ),
      key: "#attachmentFiles",
    },
    {
      label: t("parameter_title"),
      children: <Parameters values={formik.values.info} />,
      key: "#parametrValues",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onShowModal = (isShow) => setIsModalOpen(isShow);

  const extraButtonOptions = {
    label: t("global_send"),
    onClick: () => onShowModal(true),
  };

  return (
    <div className="main-layout-wrapper">
      <PageTitle big={t("risk_edit")} small={t("risk_desc")} />
      <AntTabs
        items={items}
        activeTab={activeTab}
        onTabsChange={onTabsChange}
      />
      {isModalOpen && (
        <RiskSendModal
          isModalOpen={isModalOpen}
          riskFormik={formik}
          onShowModal={onShowModal}
          pathConfig={pathConfig}
          poolOrUser={poolOrUser}
        />
      )}

      <div className="risk-page__submittion">
        <FormSubmit
          cancel={onReset}
          submit={onSubmit}
          extraButtonOptions={extraButtonOptions}
        />
      </div>
    </div>
  );
};

export default RiskEdit;
