import {
  ADD_SUB_TAB,
  ADD_TAB,
  ADD_TAB_WITH_CHILD,
  REMOVE_SUB_TAB,
  REMOVE_TAB,
  RESET_TABS,
} from "../types";

export const addTab = (values) => {
  return {
    type: ADD_TAB,
    payload: values,
  };
};

export const addTabWithChild = (
  parentTabName,
  parentTabKey,
  subTabName,
  subTabKey
) => {
  return {
    type: ADD_TAB_WITH_CHILD,
    payload: { parentTabName, parentTabKey, subTabName, subTabKey },
  };
};

export const removeTab = (tabKey) => {
  return {
    type: REMOVE_TAB,
    payload: tabKey,
  };
};

export const addSubTab = (values) => {
  return {
    type: ADD_SUB_TAB,
    payload: values,
  };
};

export const removeSubTab = (tabKey, subTabKey) => {
  return {
    type: REMOVE_SUB_TAB,
    payload: { tabKey, subTabKey },
  };
};

export const resetTabs = () => {
  return {
    type: RESET_TABS,
  };
};
