import React, { useCallback } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import { useDropzone } from "react-dropzone";
import { clsx } from "clsx";
import { useTranslation, Trans } from "react-i18next";

const FileUpload = (props) => {
  const { t } = useTranslation();
  var formik = props.formik;
  var field = props.field;
  var label = props.label;
  var required = props.required ? true : false;
  var disabled = props.disabled ? true : false;

  const infoError = formik.errors.info || {};
  const infoTouched = formik.touched.info || {};
  const classes = clsx(
    "input-body",
    disabled && "input-disabled",
    infoError[`${field}`] && infoTouched[`${field}`] && "error"
  );

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryStr = reader.result;
        formik.setFieldValue(`info.${field}`, binaryStr);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getInputProps, getRootProps } = useDropzone({ onDrop });

  let imageRender = <CloudUploadOutlined className="input-uplaod" />;

  if (formik.values.info[field]) {
    imageRender = (
      <img
        className="input-body__image"
        src={formik.values.info[field]}
        alt="Fotoşəkil"
      />
    );
  }

  return (
    <div className="input-box input-box__file">
      <span className="input-box__title">
        {label} {required ? <span style={{ color: "red" }}>*</span> : null}
      </span>
      <div {...getRootProps()} className={classes}>
        <input type="file" {...getInputProps()} />
        {imageRender}
        <p className="input-body__text">
          <Trans
            i18nKey="input_fileupload_add_image"
            components={{
              span: <span />,
            }}
            values={{ click_here: t("click_here") }}
          />
        </p>
        <p className="input-body__info">{t("input_fileupload_info")}</p>
      </div>
    </div>
  );
};

export default FileUpload;
