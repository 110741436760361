import "../../../RiskPageStyles.scss";

import React from "react";
import { Row, Col, Select } from "antd";
import { useTranslation } from "react-i18next";
import GroupsUsersSelect from "components/Elements/GroupsUsersSelect/GroupsUsersSelect";

function AddWorkFlow(props) {
  const { t } = useTranslation();
  const { riskStatusList, formik, inputHasError, setUserType } = props;

  const listscheme = {
    parser: { value: "value", label: "label", type: "type" },
  };

  return (
    <div
      className=""
      style={{ marginBottom: "25px" }}
    >
      <div className="risk-page modal">
        <h1 className="risk-page__title">{t("workflow_add")}</h1>
        <div className="risk-page__elements">
          <Row gutter={24}>
            <Col
              sm={24}
              md={24}
              lg={24}
            >
              <GroupsUsersSelect
                onSelect={setUserType}
                label={t("global_user_group")}
                formik={formik}
                field="workFlow.addWorkFlowForm.id"
                settings={listscheme}
              />

              <div className="input-box">
                <span className="input-box__title">Risk statusu</span>
                <Select
                  options={riskStatusList}
                  value={formik.values.workFlow.addWorkFlowForm.risk_status}
                  onChange={(value, option) => {
                    formik.setFieldValue(
                      "workFlow.addWorkFlowForm.risk_status",
                      value
                    );
                    formik.setFieldValue(
                      "workFlow.addWorkFlowForm.risk_status_text",
                      option.label
                    );
                  }}
                  onBlur={formik.handleBlur(
                    "workFlow.addWorkFlowForm.risk_status"
                  )}
                  status={
                    inputHasError("workFlow.addWorkFlowForm.risk_status")
                      ? "error"
                      : ""
                  }
                />
                <div className="input-box__error-field">
                  {inputHasError("workFlow.addWorkFlowForm.risk_status")
                    ? formik.errors.workFlow.addWorkFlowForm.risk_status
                    : ""}
                </div>
              </div>
              <div className="input-box">
                <span className="input-box__title">Status mətni</span>
                <input
                  type="text"
                  className={
                    inputHasError("workFlow.addWorkFlowForm.status_mean")
                      ? "error"
                      : ""
                  }
                  {...formik.getFieldProps(
                    "workFlow.addWorkFlowForm.status_mean"
                  )}
                />
                <div className="input-box__error-field">
                  {inputHasError("workFlow.addWorkFlowForm.status_mean")
                    ? formik.errors.workFlow.addWorkFlowForm.status_mean
                    : ""}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default AddWorkFlow;
