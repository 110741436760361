import React, { useState, useRef, useEffect } from "react";
import { Avatar, Button, Skeleton } from "antd";
import { UserOutlined, NotificationOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import Risks from "models/Risks";
import GL from "libs/GL";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

const Comments = ({ riskFormik }) => {
  const { t } = useTranslation();
  const { user } = useSelector(({ user }) => ({
    user: {
      id: user?.data?.user?.id,
      firstName: user?.data?.user?.firstName,
      lastName: user?.data?.user?.lastName,
      email: user?.data?.user?.email,
    },
  }));

  const commentListRef = useRef(undefined);

  const [isCommentLoading, setIsCommentLoading] = useState(false);

  const initialValues = {
    comment: "",
  };

  let validationCommentSchema = Yup.object().shape({
    comment: Yup.string().required(t("comments_yup_required")),
  });

  const commentFormik = useFormik({
    initialValues,
    validationSchema: validationCommentSchema,
    onSubmit: (values, { resetForm }) => {
      const body = {
        ...values,
        riskId: riskFormik?.values.info.id,
      };
      const comment = {
        id: uuid,
        comment: values.comment,
        user,
      };
      setIsCommentLoading(true);

      Risks.createComment(body, () => {
        riskFormik.setFieldValue("info.riskComments", [
          ...riskFormik?.values.info.riskComments,
          comment,
        ]);
        GL.notify.success({ text: t("comments_send_success") });
        resetForm();
        setIsCommentLoading(false);
      });
    },
  });

  function onSubmit() {
    commentFormik.handleSubmit();
  }

  const values = riskFormik?.values?.info || {};
  const comments = values.riskComments;

  useEffect(() => {
    if (commentListRef.current) {
      const scrollHeight = commentListRef.current.scrollHeight;
      commentListRef.current.scrollTop = scrollHeight;
    }
  }, [commentListRef.current, comments]);

  let commentList = <Skeleton active={true} />;
  if (comments?.length) {
    commentList = (
      <div className="comments-list scroll-style" re f={commentListRef}>
        {comments.map(({ id, comment, user: commenter }) => {
          const { firstName, lastName, id: commentUserId } = commenter;
          const fullName = `${firstName} ${lastName}`;
          const isUser = user.id === commentUserId;
          return (
            <div
              key={id}
              className={`comments-flex ${
                isUser ? "comments-flex__end" : "comments-flex__start"
              }`}
            >
              <div className="comments-inner">
                <div className="comments-inner__info">
                  <Avatar>{firstName ? firstName[0] : <UserOutlined />}</Avatar>
                  <span className="comments-inner__name">{fullName}</span>
                </div>
                <p className="comments-inner__text">{comment}</p>
                {/* <time className="comments-inner__time">03.04.2023</time> */}
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    commentList = (
      <div className="comments-empty">
        <Avatar shape="square" size={64} icon={<NotificationOutlined />} />
        <h3>{t("comments_empty")}</h3>
      </div>
    );
  }

  return (
    <div className="comments">
      <span className="input-box__title">{t("comments")}</span>
      <div className="comments-body">{commentList}</div>
      <div className="comments-form">
        <div className="comments-form__group">
          <input
            type="text"
            placeholder={t("comment")}
            {...commentFormik.getFieldProps("comment")}
            className={`${
              commentFormik.errors?.comment &&
              commentFormik.touched?.comment &&
              "error"
            }`}
          />
          {commentFormik.errors?.comment && commentFormik.touched?.comment && (
            <span>{commentFormik.errors?.comment}</span>
          )}
        </div>

        <Button type="primary" onClick={onSubmit} loading={isCommentLoading}>
          Göndər
        </Button>
      </div>
    </div>
  );
};

export default Comments;
