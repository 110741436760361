import React from "react";
import { Modal, Typography, Button } from "antd";
import NotifyWarning from "components/Elements/Icons/NotifyWarning";
import Success from "components/Elements/Icons/Success";
import Danger from "components/Elements/Icons/Danger";

const { Title } = Typography;

const icons = {
  success: <Success />,
  warning: <NotifyWarning />,
  danger: <Danger />,
};

const Notify = ({
  isOpenModal = false,
  type = "warning",
  icon = true,
  message = "Body text!",
  cancelButton = true,
  cancelText = "Cancel",
  onCancel,
  cancelButtonClassName,
  cancelButtonProps,
  okButton = false,
  okText = "Ok",
  onOk,
  okButtonClassName,
  okButtonProps,
}) => {
  return (
    <Modal open={isOpenModal} closeIcon={true} centered footer={null}>
      <div className="w-100 p-30 d-flex flex-column align-items-center">
        {icon && icons[type]}
        <Title level={5} className="mt-10 mb-10 text-center">
          {message}
        </Title>
        <div className="w-100 d-flex justify-content-center flex-wrap gap-10">
          {cancelButton && (
            <Button
              className={`btn-secondary ${cancelButtonClassName}`}
              onClick={onCancel}
              {...cancelButtonProps}
            >
              {cancelText}
            </Button>
          )}

          {okButton && (
            <Button
              className={`btn-success ${okButtonClassName}`}
              onClick={onOk}
              {...okButtonProps}
            >
              {okText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Notify;
