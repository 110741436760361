import React, { useEffect, useMemo, useState } from "react";
import {
  GetPagePermission,
  GetTableSettings,
} from "../../../hooks/permissions";
import GL from "../../../libs/GL";
import SuperTable from "../../Elements/Tabulator/SuperTable";
import { useHistory } from "react-router-dom";
import { Skeleton } from "antd";
import RiskGroup from "../../../models/RiskGroup";
import EmptyPage from "../../Elements/Skelethon/EmptyPage";
import { useTranslation } from "react-i18next";

const pageId = "risk-groups";

function RiskGroups(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [riskGroupList, setRiskGroupList] = useState([]);
  const [loading, setLoading] = useState(false);
  const permissions = GetPagePermission(14);
  const tableSettings = GetTableSettings(pageId);
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );
  const operatorSet = {
    edit: {
      status: permissions["edit"],
      click: function (data) {
        history.push({
          pathname: "risk-groups/create",
          state: {
            info: {
              id: data.id,
              name: data.name,
              totalName: data.totalName,
              parentId: data.parentId == null ? " " : data.parentId,
              code: data.code,
              isActive: data.isActive,
            },
            method: "edit",
          },
        });
      },
    },
    delete: {
      status: permissions["delete"],
      click: function (data) {
        GL.yesNo(t("question_deleting"), (status1) => {
          if (status1) {
            RiskGroup.DELETE(data.id, (status2, res) => {
              if (status2) {
                GL.notify.success({
                  title: t("answer_success"),
                  text: t("risk_group_deleted"),
                });
                getRiskGroupsList();
              } else {
                GL.notify.error({
                  title: t("answer_error"),
                  text: t("risk_group_not_deleted"),
                });
              }
            });
          }
        });
      },
    },
    show: {
      status: permissions["show"],
      click: function (data) {},
    },
    copy: {
      status: false,
      click: function (data) {
        history.push({
          pathname: "risk-groups/create",
          state: {
            info: {
              id: 0,
              name: data.name,
              totalName: data.totalName,
              parentId: data.parentId,
              code: data.code,
              isActive: data.isActive,
            },
            method: "create",
          },
        });
      },
    },
  };
  const options = {
    permissions: {
      add: {
        status: permissions["add"],
        endpoint: "/risk-groups/add",
      },
      multiDelete: {
        status: permissions["multidelete"],
        endpoint: "/risk-groups/multiDelete",
      },
    },
    settings: {
      id: pageId,
      type: "data",
      data: riskGroupList,
      maxRow: tableSettings.rowNums,
      pagination: tableSettings.pagination,
      buttonText: "+ " + t("global_create", { name: t("risk_group") }),
      createNewRoute: "risk-groups/create",
      state: {
        info: {
          id: 0,
          name: "",
          totalName: "",
          parentId: " ",
          code: "",
          isActive: true,
        },
        method: "create",
      },
    },
    columns: [
      {
        title: "ID",
        field: "id",
        width: 70,

        formatter: function (cell) {
          return GL.setSelectStatus(cell);
        },
      },
      {
        title: t("global_name"),
        field: "name",
      },
      {
        title: t("global_totalname"),
        field: "totalName",
      },
      {
        title: t("global_code"),
        field: "code",
      },
      {
        title: t("risk_group_parent"),
        field: "parentId",
      },
      {
        title: t("global_operations"),
        field: "operations",
        width: 110,
        formatter: function (cell) {
          return GL.setButtons(cell, operatorSet);
        },
      },
    ],
    filtering: {
      id: pageId,
      uniqueColumn: "id",
      filters: {
        name: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        totalName: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        code: {
          dataType: "text",
          placeholder: t("global_search"),
        },
        parentId: {
          dataType: "text",
          placeholder: t("global_search"),
        },
      },
      styles: {
        simpleFilterInput: "white",
        filterButton: "buto",
      },
      secretColumns: tableSettings.hideColumns,
      rowColor: {},
    },
  };

  const getRiskGroupsList = () => {
    setLoading(true);
    RiskGroup.getRiskGroupList(
      (data) => {
        //data.reverse()
        setRiskGroupList(RiskGroup.getForTableData(data));
        setLoading(false);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    getRiskGroupsList();
  }, []);

  if (loading) {
    return <Skeleton active />;
  } else {
    if (riskGroupList.length == 0) {
      return (
        <EmptyPage
          route="risk-groups/create"
          name={t("risk_group")}
          state={options.settings.state}
        />
      );
    } else {
      return (
        <div style={containerStyle}>
          <div className="main-layout-wrapper__head">
            <div className="simple-head">
              <h1>{t("risk_groups")}</h1>
              <span>{t("risk_group_desc")}</span>
            </div>
          </div>
          <SuperTable data={options} />
        </div>
      );
    }
  }
}

export default RiskGroups;
