import API from "../../../../const/API2";
import { apiRoutes } from "../../../../const/apiroutes";

var api = new API();

class LibraryMainModel {
  static async getLastAdded(callback, errCalback) {
    api.getData(
      apiRoutes.library.getLastAdded,
      (data) => {
        callback(data);
      },
      (err) => {
        if (errCalback) {
          errCalback(err);
        }
      }
    );
  }
  static async getLastViewed(callback, errCalback) {
    api.getData(
      apiRoutes.library.getLastViewed,
      (data) => {
        const lastViewedList = data.map((viewedFile) => {
          return {
            ...viewedFile,
            uid: (viewedFile.isFolder ? "folder" : "file") + viewedFile.id,
          };
        });
        callback(lastViewedList);
      },
      (err) => {
        if (errCalback) {
          errCalback(err);
        }
      }
    );
  }
  static async getCreatedByList(isCatalog, callback, errCalback) {
    api.getData(
      apiRoutes.library.getCreatedByList({ isCatalog: isCatalog }),
      (data) => {
        const createdBySelectData = data.map((user) => {
          return {
            value: `${user.userId}`,
            label: user.name,
          };
        });
        callback(createdBySelectData);
      },
      (err) => {
        if (errCalback) {
          errCalback(err);
        }
      }
    );
  }
}

export default LibraryMainModel;
