import API from "const/API2";
import { apiRoutes } from "const/apiroutes";

var api = new API();

export class MessengerModel {
  static async getChats(callback, errCallback) {
    api.getData(
      apiRoutes.chat.getChats,
      (data) => {
        if (callback) {
          const chatWithUids = data.map((chat) => ({
            ...chat,
            uid: `${chat.isGroup ? "group" : "single"}_${chat.toId}`,
          }));

          callback(chatWithUids);
        }
      },
      (err) => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async getGroups(callback, errCallback) {
    api.getData(
      apiRoutes.chat.getGroups,
      (data) => {
        if (callback) {
          const groupWithUids = data.map((group) => ({
            ...group,
            uid: `group_${group.id}`,
          }));
          callback(groupWithUids);
        }
      },
      (err) => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async getContacts(callback, errCallback) {
    api.getData(
      apiRoutes.chat.getContacts,
      (data) => {
        if (callback) {
          const chatWithUids = data.map((contact) => ({
            ...contact,
            uid: `single_${contact.id}`,
          }));
          callback(chatWithUids);
        }
      },
      (err) => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async getChatInfo(id, isGroup, callback, errCallback) {
    api.getData(
      `${apiRoutes.chat.getChatInfo}/${id}/info?isGroup=${isGroup}`,
      (data) => {
        if (callback) {
          callback(data);
        }
      },
      (err) => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }

  static async handleChatMessageClick(
    id,
    isGroup,
    page,
    size,
    callback,
    errCallback
  ) {
    api.getData(
      `${apiRoutes.chat.getChatMessages}/${id}?isGroup=${isGroup}&page=${page}&size=${size}`,
      (data) => {
        if (callback) {
          callback(data);
        }
      },
      (err) => {
        if (errCallback) {
          errCallback(err);
        }
      }
    );
  }
}
