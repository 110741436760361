import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";

import DynamicTable from "../Tabulator/DynamicTable";
import GL from "libs/GL";

function SelectionModal(props) {
  const { t } = useTranslation();
  const { open, setOpen, settings } = props;
  const [dataLoad] = useState(true);
  var type = settings["type"] === undefined ? "data" : settings["type"];
  var data = settings["data"] === undefined ? [] : settings["data"];
  var acceptedColumns =
    settings["columns"] === undefined ? [] : settings["columns"];
  var multi = settings["multi"] === undefined ? false : settings["multi"];
  var columns = [];
  var filters = {};
  var selectedsId = [];
  var selecteds = [];
  var secim = {
    title: "SECIM",
    field: "selection",
    width: 150,
    formatter: function (cell) {
      var celldata = cell.getData();
      if (celldata.selection) {
        return `<button title=${t(
          "selection_geometries"
        )} class="button is-success is-small"><span>${t(
          "global_choosed"
        )}</span></button>`;
      } else {
        return `<button title=${t(
          "selection_geometries"
        )} class="button is-light is-small"><span>${t(
          "global_choose"
        )}</span></button>`;
      }
    },
    hozAlign: "center",
    cellClick: function (e, cell) {
      var d = cell.getData();
      var uniq = d[filtering.uniqueColumn];
      var ind = selectedsId.indexOf(uniq);
      if (ind === -1) {
        d.selection = true;
        selecteds.push(d);
        selectedsId.push(uniq);
      } else {
        d.selection = false;
        selecteds.splice(ind, 1);
        selectedsId.splice(ind, 1);
      }
      cell.setValue(d.selection);
      if (multi === false) {
        handleOk();
      }
    },
  };
  if (type === "url") {
  } else {
    data = data.map((a) => {
      a.selection = false;
      return a;
    });
    if (acceptedColumns.length === 0) {
      columns = GL.getColumnsFromJSONArray(data, ["selection"], {
        name: t("global_username"),
      });
    } else {
      acceptedColumns.forEach((a) => {
        columns.push({
          title: a.split("_").join(" ").toLocaleUpperCase(),
          field: a,
        });
      });
    }

    for (var i = 0; i < columns.length; i++) {
      filters[columns[i].field] = {
        dataType: "text",
        placeholder: t("global_search"),
      };
    }
    filters["selection"] = {
      dataType: "checkbox",
      placeholder: t("selection_status_list"),
      mean: {
        true: `<button title=${t(
          "selection_geometries"
        )} class="btntabltr" style="background: #48c78e; width:65px;"><span>${t(
          "global_choosed"
        )}</span></button>`,
        false: `<button title=${t(
          "selection_geometries"
        )} class="btntabltr" style="background: #333333; width:65px;"><span>${t(
          "global_choose"
        )}</span></button>`,
        null: t("global_unknown"),
        type: "html",
        html: {
          true: t("selection_selecteds"),
          false: t("selection_not_selecteds"),
        },
      },
    };
    columns.push(secim);
    //setDataLoad(true);
  }

  const handleOk = () => {
    settings.onSelected(selecteds);
    setOpen(false);
  };

  const handleCancel = () => {
    settings.onCancel();
    setOpen(false);
  };

  var tableSettings = {
    id: "id-" + Date.now(),
    type: "data",
    data: data,
    maxRow: 10,
    pagination: true,
    buttonText: "",
    createNewRoute: "",
    state: {},
  };

  var filtering = {
    id: tableSettings.id,
    uniqueColumn: "id",
    filters: filters,
    styles: {
      simpleFilterInput: "white",
      filterButton: "buto",
    },
    secretColumns: [],
    rowColor: {},
  };

  return (
    <Modal
      width={1000}
      title={settings.title}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      //   confirmLoading={confirmLoading}
    >
      {dataLoad === true ? (
        <div style={{ marginTop: "20px" }}>
          <DynamicTable
            columns={columns}
            filtering={filtering}
            settings={tableSettings}
          />
        </div>
      ) : null}
    </Modal>
  );
}

export default SelectionModal;
