import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "antd";
import { useHistory } from "react-router";

import PageTitle from "components/Elements/PageTitle/PageTitle";
import InputBox from "components/Elements/FileUploadForm/Form/InputBox";
import TextareaBox from "components/Elements/FileUploadForm/Form/Textarea";
import { myList } from "const/initialDatas";
import { setMyListData } from "redux/actions";
import ColumnsEdit from "./Columns/ColumnsEdit";
import Columns from "./Columns/Columns";
import MyList from "models/MyList";
import FormSubmit from "components/Elements/FormSubmit/FormSubmit2";
import GL from "libs/GL";
import EnumCategory from "models/EnumCategory";
import { useTranslation } from "react-i18next";

function MyListEdit(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const pageState = props.location.state;
  const method = pageState?.method || "";
  const id = pageState?.id || "";

  const reduxMyList = useSelector((state) => state.myList);

  const [showModal, setShowModal] = useState(false);
  const [modalState, setModalState] = useState({ method: "", id: "" });
  const [enumCategoryList, setEnumCategoryList] = useState([]);

  // ! ======================= FORMIK ==================== //

  let initialValues;
  if (method === "create") {
    initialValues = {
      info: {
        id: "",
        name: "",
        description: "",
        fields: [],
        data: [],
        isActive: "",
        form_name: "",
        form_type: "",
        form_field: "",
        form_nullable: "",
        form_default: "",
        form_enum: "",
        form_unique: false,
      },
    };
  }
  if (method === "edit" || method === "create2" || method === "copy") {
    initialValues = reduxMyList;
  }

  const validationSchema = Yup.object().shape({
    info: Yup.object().shape({
      name: Yup.string().required(t("mylist_yup_name")),
      description: Yup.string().required(t("mylist_yup_desc")),
    }),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (method === "create2" || method === "copy") {
        MyList.CREATE(
          values,
          (_) => {
            history.replace("/my-list");
            GL.notify.success({
              title: t("answer_success"),
              text: t("mylist_created"),
            });
          },
          (err) => {
            GL.notify.error({
              title: t("answer_error"),
              text: t("mylist_not_created"),
            });
          }
        );
      }
      if (method === "edit") {
        MyList.EDIT(
          id,
          values,
          (_) => {
            history.replace("/my-list");
            GL.notify.success({
              title: t("answer_success"),
              text: t("mylist_edited"),
            });
          },
          (err) => {
            GL.notify.error({
              title: t("answer_error"),
              text: t("mylist_not_edited"),
            });
          }
        );
      }
    },
  });

  const submitForm = () => {
    formik.handleSubmit();
  };

  const resetFormik = () => {
    formik.setValues(myList);
  };

  useEffect(() => {
    dispatch(setMyListData(formik.values));
  }, [formik.values]);

  useEffect(() => {
    if (method === "create" && Object.keys(formik.touched).length) {
      history.replace({ state: { method: "create2" } });
    }
  }, [formik.touched]);

  // ! ======================= FORMIK ==================== //

  const handleOpenModal = () => {
    setModalState({ method: "create", id: "" });
    setShowModal(true);
  };

  const getMyListByID = () => {
    MyList.GET(
      id,
      (data) => {
        formik.setFieldValue("info", data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (method === "edit" || method === "copy") {
      getMyListByID();
    }
  }, []);

  useEffect(() => {
    EnumCategory.getEnumCategoriesList((data) => {
      data = EnumCategory.getArrayForSelect(data);
      setEnumCategoryList(data);
    });
  }, []);

  return (
    <>
      <div className="main-layout-wrapper">
        <PageTitle
          big={method === "edit" ? t("mylist_edit") : t("mylist_new")}
          small={t("mylist_desc")}
        />

        <div className="risk-page">
          <h1 className="risk-page__title">{t("global_info")}</h1>
          <div className="risk-page__elements">
            <Row gutter={24}>
              <Col sm={24} md={24} lg={18}>
                <InputBox
                  label={t("mylist_name")}
                  formik={formik}
                  field="name"
                  type="text"
                  required={true}
                />
                <TextareaBox
                  rows={6}
                  label={t("mylist_info")}
                  formik={formik}
                  field="description"
                  required={true}
                  {...formik.getFieldProps("description")}
                />
                <Button type={"primary"} onClick={handleOpenModal}>
                  {t("mylist_add_column")}
                </Button>
                <div
                  style={{
                    marginBlock: "16px",
                  }}
                >
                  <h2 className="risk-page__title">{t("mylist_columns")}</h2>
                  {/* ==================================== COLUMN TABLE ======================================= */}
                  <Columns
                    formik={formik}
                    fields={formik.values?.info?.fields}
                    setShowModal={setShowModal}
                    setModalState={setModalState}
                  />
                  {/* ==================================== COLUMN TABLE ======================================= */}
                  {/* ==================================== COLUMN EDIT ======================================= */}
                  {showModal && (
                    <ColumnsEdit
                      showModal={showModal}
                      setShowModal={setShowModal}
                      modalState={modalState}
                      listFormik={formik}
                      listMethod={method}
                      enumCategoryList={enumCategoryList}
                    />
                  )}
                  {/* ==================================== COLUMN EDIT ======================================= */}
                </div>
              </Col>
            </Row>
          </div>
          <div className="risk-page__submittion">
            <FormSubmit cancel={resetFormik} submit={submitForm} />
          </div>
        </div>
      </div>
    </>
  );
}

export default MyListEdit;
