import React from "react";
import { Button, Typography, Upload, Form, Input } from "antd";
import { Space } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import mailline from "../../../assets/img/mailline.svg";
import { useMailContext } from "./MailContextAPI";
import { useState } from "react";
import { useSelector } from "react-redux";
import "./Mail.css";
import SelectUserSearch from "./SelectUserSearch";
import { MailModel } from "./models/MailModel";
import { useEffect } from "react";
const { Title } = Typography;
const { TextArea } = Input;
import { useHistory } from "react-router";
import GL from "libs/GL";
const NewMail = ({ setQueryParam }) => {
  const { state, dispatch } = useMailContext();
  const [fileList, setFileList] = useState([]);
  const [fileIdList, setFileIdList] = useState([]);
  const [fileIdMap, setFileIdMap] = useState({});
  const [mailTitle, setMailTitle] = useState("");
  const [mailContext, setMailContent] = useState("");
  const { value } = state;
  const history = useHistory();
  const { currentPage, currentSendPage, pageSize } = state.paginationInfo;

  const searchParamsHandler = new URLSearchParams(history.location.search);
  const activeTab = searchParamsHandler.get("tab");

  const currentUserId = useSelector(state => state.user.data.user.id);

  const handleSendMail = async e => {
    e.preventDefault();

    const requestData = {
      from: currentUserId,
      userIds: value,
      typeId: 5,
      title: mailTitle,
      content: mailContext,
      fileIdList: fileIdList,
      emailTypeId: 1
    };

    MailModel.sendNewMail(
      requestData,
      responseReply => {
        if (typeof responseReply === "number") {
          setQueryParam("tab", activeTab);
          refreshPage();
          GL.notify.success({
            title: "Uğurlu əməliyyat!",
            text: "Mesaj göndərildi."
          });
        }
      },
      err => {
        GL.notify.error({
          title: "Uğursuz əməliyyat!",
          text: "Mesaj göndərilmədi."
        });
        console.log("POST SEND NEW MAILUNSUCCESSFULL", err);
      }
    );
  };

  const handleCancel = () => {
    dispatch({ type: "SET_SHOW_MODAL", payload: false });
  };

  //~ FILE REMOVING FROM SERVER START
  const handleRemoveFile = file => {
    const fileId = fileIdMap[file.uid];

    if (fileId) {
      MailModel.removeFile(fileId, res => {
        const updatedFileIdList = fileIdList.filter(id => id !== fileId);
        setFileIdList(updatedFileIdList);
      });
    }
  };

  const props = {
    customRequest: async ({ file, onSuccess, onError }) => {
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);

        MailModel.uploadFile(
          formData,
          fileUploadRes => {
            setFileIdList(prevState => [...prevState, fileUploadRes.id]);
            setFileIdMap(prevMap => ({
              ...prevMap,
              [file.uid]: fileUploadRes.id
            }));
            onSuccess();
            GL.notify.success({
              title: "Uğurlu əməliyyat!",
              text: "Fayllar yükləndi"
            });
          },
          err => {
            GL.notify.error({
              title: "Uğursuz əməliyyat!",
              text: "Fayllar yüklənmədi"
            });
          }
        );
      } catch (error) {
        onError(error);
      }
    },

    onChange({ file, fileList }) {
      if (file.status !== "uploading") {
        setFileList(fileList);
      } else if (file.status === "error") {
        console.error("File upload error:", file.response.error);
      }
    }
  };
  useEffect(() => {
    return () => {
      fileList.forEach(file => handleRemoveFile(file));
    };
  }, []);

  //~ FILE REMOVING FROM SERVER END

  const refreshPage = () => {
    MailModel.getSend(
      currentSendPage,
      pageSize,
      modelDataSend => {
        dispatch({
          type: "SET_MAIL_SENT",
          payload: modelDataSend
        });
      },
      err => {
        console.log(err, "ERROR MESSAGE FROM SEND REQUEST");
      }
    );
  };

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        backgroundColor: "white",
        width: 520,
        height: fileIdList.length === 0 ? "650px" : "750px",
        zIndex: "9999",
        display: "block",
        position: "fixed",
        bottom: "0",
        right: "0",
        marginBottom: "20px",
        marginRight: "70px",
        borderRadius: "7px",
        boxShadow: "0px 4px 34px 0px rgba(0, 0, 0, 0.05)",
        padding: "20px",
        transition: "0.4s all ease-in-out"
      }}
    >
      <Title style={{ fontSize: "20px", fontWeight: "600", color: "#000" }}>
        Yeni e-mail
      </Title>

      <img src={mailline} alt="line" width={460} />
      <Form layout="vertical" style={{ marginTop: "10px" }}>
        <Form.Item label="Kimə">
          <SelectUserSearch />
        </Form.Item>

        <Form.Item label="Mövzu">
          <Input
            bordered={false}
            required
            style={{
              borderRadius: "6px",
              border: "1px solid #EBEDF0",
              boxShadow: "0px 16px 40px -12px rgba(171, 186, 201, 0.20)",
              height: "40px"
            }}
            placeholder="Daxil et"
            onChange={e => setMailTitle(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Mətn">
          <TextArea
            required
            autoSize={{
              minRows: 5,
              maxRows: 5
            }}
            style={{
              borderRadius: "6px",
              border: "1px solid #EBEDF0",
              boxShadow: "0px 16px 40px -12px rgba(171, 186, 201, 0.20)"
            }}
            onChange={e => setMailContent(e.target.value)}
          />
        </Form.Item>
        <div
          className="scroll-for-files"
          style={{
            maxHeight: "200px",
            overflowY: fileList.length > 6 ? "auto" : ""
          }}
        >
          <Upload {...props} onRemove={file => handleRemoveFile(file)}>
            <Space direction="horizontal">
              {" "}<Button
                className="new-mail-file-choose"
                style={{
                  borderRadius: "4px",
                  background: "#C64D42",
                  border: "1px solid #C64D42",
                  color: "white"
                }}
                icon={<UploadOutlined />}
              >
                Fayl seç
              </Button> 
              {fileIdList.length === 0 &&
                <Title
                  style={{ fontSize: "14px", transition: "0.4s all ease-out" }}
                >
                  Fayl seçilməyib
                </Title>}
            </Space>
          </Upload>
        </div>
        <Space
          direction="horizontal"
          style={{ position: "absolute", right: "0", bottom: "0" }}
        >
          <Form.Item>
            <Button
              onClick={() => {
                handleCancel();
                setQueryParam("tab", "inbox");
                dispatch({ type: "SET_VALUE", payload: [] });
              }}
              className="cancel-button-new-mail"
              style={{
                backgroundColor: "#EBEDF0",
                color: "#000",
                borderRadius: "4px",
                transition: "0.4s all ease-out"
              }}
            >
              Ləğv et{" "}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              onClick={e => {
                handleSendMail(e);
                dispatch({ type: "SET_SHOW_MODAL", payload: false });
              }}
              className="submit-button-new-mail"
              style={{
                backgroundColor: "#C64D42",
                color: "#fff",
                borderRadius: "4px"
              }}
            >
              Göndər
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Space>
  );
};

export default NewMail;
