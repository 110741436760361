import React from "react";
import { Modal } from "antd";

function ConfirmDeleteModal({
  isOpen,
  closeModal,
  title,
  submitDeleteFileOrFolder,
}) {
  return (
    <Modal
      title={title}
      // className="library-share-modal"
      open={isOpen}
      onOk={() => {
        submitDeleteFileOrFolder();
        closeModal();
      }}
      onCancel={closeModal}
      bodyStyle={{
        marginTop: "30px",
      }}
    >
      "Silmek istediyinizden eminsinizmi ?"
    </Modal>
  );
}

export default ConfirmDeleteModal;
