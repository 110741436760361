import "./SubClosableTabs.scss";

import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addSubTab, removeSubTab } from "../../../redux/actions/homeActions";

function ClosableTabs(props) {
  const { reduxName, TabComponentEnum, addNewTab, deleteTab } = props;

  const queryType = "subTab";

  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const tabQuery = queryParams.get(queryType); //! potentially null
  const parentTabQuery = queryParams.get("tab"); //! potentially null

  const reduxValue = useSelector((state) => state[reduxName]);

  const [activeKey, setActiveKey] = useState(tabQuery);
  const [items, setItems] = useState([]);

  const setQueryParam = (queryType, queryValue) => {
    console.log(queryType, queryValue);
    queryParams.set(queryType, queryValue);
    const newQueryString = queryParams.toString();
    history.push({
      pathname: history.location.pathname,
      search: newQueryString,
    });
  };

  const deleteQuery = (queryType) => {
    queryParams.delete(queryType);
    const newQueryString = queryParams.toString();
    history.push({
      pathname: history.location.pathname,
      search: newQueryString,
    });
  };

  const handleTabFocusAfterDelete = (targetKey) => {
    //! tabi reduxdan burada silmirik
    //! bir oncekine focuslan
    //! bir onceki yoxdursa reduxdaki indexi 0 olana focuslan
    //! en az iki tab olacq hemise burada

    const isFocusedSubTab = targetKey === tabQuery;
    const reduxParent = reduxValue.tabs.find(
      (stateTab) => stateTab.key === parentTabQuery
    );

    let indexOfSubtabInRedux = -1;
    reduxParent.subTabs.forEach((stateSubTab, idx) => {
      if (stateSubTab.key === targetKey) indexOfSubtabInRedux = idx;
    });

    const newFocusedSubTab = reduxParent.subTabs[indexOfSubtabInRedux - 1];

    if (isFocusedSubTab) {
      newFocusedSubTab
        ? setQueryParam("subTab", newFocusedSubTab.key)
        : setQueryParam("subTab", reduxParent.subTabs[1].key);
    }
  };

  const addNewSubTab = (tabLabel, key) => {
    const parentReduxTab = reduxValue.tabs.find(
      (reduxTab) => reduxTab.key === parentTabQuery
    );

    const subTabIsAlreadyOpen = parentReduxTab.subTabs.some(
      (stateSubTab) => stateSubTab.key === key
    );

    if (!subTabIsAlreadyOpen) {
      const newTab = {
        label: tabLabel,
        children: `${key}`,
        key: `${key}`,
        closable: true,
      };
      dispatch(addSubTab({ newTab, parentTabQuery }));
    }
    setQueryParam(queryType, key);
  };

  const isLastSubTab = () => {
    const parentTabRedux = reduxValue.tabs.find(
      (reduxTab) => reduxTab.key === parentTabQuery
    );

    if (parentTabRedux.subTabs.length === 1) {
      return true;
    } else {
      return false;
    }
  };

  const deleteSubTab = (targetKey) => {
    if (isLastSubTab()) {
      deleteTab(parentTabQuery);
    } else {
      handleTabFocusAfterDelete(targetKey);
      dispatch(removeSubTab(parentTabQuery, targetKey));
    }
  };

  const onEdit = (targetKey, action) => {
    if (action !== "add") {
      deleteSubTab(targetKey);
    }
  };

  const onChange = (targetKey) => {
    setQueryParam(queryType, targetKey);
  };

  useEffect(() => {
    setActiveKey(tabQuery);
    console.log("tabQuery", tabQuery);
    const subTabs = reduxValue.tabs.find(
      (reduxTabs) => reduxTabs.key === parentTabQuery
    ).subTabs;
    if (!tabQuery) {
      //! active redux tab icinden gedib ilk subtabin keyini ver url`e (eger sub tab varsa. tab query herzaman olacaq varsayilir)

      if (Array.isArray(subTabs) && subTabs.length > 0) {
        const activeTabFind = subTabs[subTabs.length - 1];
        console.log(activeTabFind);
        setQueryParam(queryType, activeTabFind.key);
      }
    }
  }, [history.location]);

  useEffect(() => {
    const newTabComponentEnum = { ...TabComponentEnum };

    for (let [key, Value] of Object.entries(newTabComponentEnum)) {
      newTabComponentEnum[key] = (
        <Value
          key={key}
          addNewTab={addNewTab}
          addNewSubTab={addNewSubTab}
          deleteTab={deleteTab}
          deleteSubTab={deleteSubTab}
          activeTab={activeKey}
        />
      );
    }

    const itemsWithRealComponents = [];

    const activeReduxTab = reduxValue?.tabs?.find(
      ({ key }) => key === parentTabQuery
    );

    activeReduxTab.subTabs.forEach((subItem) => {
      if (newTabComponentEnum[subItem.children]) {
        itemsWithRealComponents.push({
          ...subItem,
          children: newTabComponentEnum[subItem.children],
        });
      } else {
        itemsWithRealComponents.push({
          ...subItem,
          children: <h1>Tapilmadi</h1>,
        });
      }
    });

    setItems(itemsWithRealComponents);
  }, [reduxValue, history.location, TabComponentEnum]);

  return (
    <div className="custom-editable-ant-tabs sub-tabs">
      <Tabs
        type="editable-card"
        onChange={onChange}
        activeKey={activeKey}
        onEdit={onEdit}
        items={items}
        hideAdd
      />
    </div>
  );
}

export default ClosableTabs;
