import "./styles/Library.scss";

import React from "react";
import { Divider, Select, Input } from "antd";
import { useParams } from "react-router-dom";
import {
  AppstoreOutlined,
  StarFilled,
  StarOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import LibraryTableCards from "./components/LibraryTableCards/LibraryTableCards";
import LibraryTable from "./components/LibraryTable/LibraryTable";
import FileTypeSelect from "./components/FileTypeSelect/FileTypeSelect";
import useGetMyFilesFolder from "./hooks/useGetMyFilesFolder";
import AddFileorFolderButton from "./components/AddFileOrFolderButton/AddFileorFolderButton";
import ColorSelect from "./components/ColorSelect/ColorSelect";

const { Search } = Input;

function FolderDetailsMyFiles() {
  const { myFilesFolderId } = useParams();

  const {
    myFilesTableData,
    filterTypeProps,
    tableViewType,
    isModifiedProps,
    tableViewProps,
    searchProps,
    paginationProps,
    isLoading,
    folderColorProps,
    isFavoriteProps,
    getMyFilesFolder,
  } = useGetMyFilesFolder({ folderId: myFilesFolderId });

  return (
    <div className="library-page">
      <h1 className="page-title">Mənim fayllarım: {myFilesFolderId}</h1>
      <div>
        <AddFileorFolderButton
          getTableDatas={getMyFilesFolder}
          folderId={myFilesFolderId}
        />
      </div>

      <Divider />

      <div className="filter-toolbar">
        <div className="filter-toolbar__item">
          <Select
            className={isModifiedProps.isModified !== null ? "active" : ""}
            onChange={isModifiedProps.onChange}
            onClear={isModifiedProps.onClear}
            value={isModifiedProps.isModified}
            allowClear
            options={[
              { value: null, label: "Dəyişiklik", disabled: true },
              { value: "true", label: "Olunub" },
              { value: "false", label: "Olunmayıb" },
            ]}
          />
        </div>

        <div className="filter-toolbar__item">
          <FileTypeSelect style={{ width: "100%" }} {...filterTypeProps} />
        </div>
        {filterTypeProps.filterType === "5" ? (
          <div
            className="filter-toolbar__item"
            style={{ width: "100px", minWidth: "unset" }}
          >
            <ColorSelect folderColorProps={folderColorProps} />
          </div>
        ) : null}

        <div className="filter-toolbar__item" style={{ minWidth: "unset" }}>
          <button
            className="is-favorite-button"
            onClick={isFavoriteProps.toggleIsFavourite}
          >
            {isFavoriteProps.isFavourite === "false" ? (
              <StarOutlined />
            ) : (
              <StarFilled className="filled-star-icon" />
            )}
          </button>
        </div>

        <div className="filter-toolbar__item">
          <button
            className="table-view-type"
            onClick={tableViewProps.toggleTableViewType}
          >
            {tableViewProps.tableViewType === "table" ? (
              <UnorderedListOutlined />
            ) : (
              <AppstoreOutlined />
            )}
          </button>
        </div>

        <div className="filter-toolbar__item search">
          <Search
            placeholder="Axtar"
            {...searchProps}
            style={{
              height: 40,
              marginBottom: 10,
              transition: "all 0.3s",
            }}
          />
        </div>
      </div>

      <Divider />

      {tableViewType === "table" ? (
        <LibraryTable
          data={myFilesTableData}
          getData={getMyFilesFolder}
          paginationProps={paginationProps}
          isLoading={isLoading}
        />
      ) : (
        <LibraryTableCards
          data={myFilesTableData}
          getData={getMyFilesFolder}
          paginationProps={paginationProps}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default FolderDetailsMyFiles;
