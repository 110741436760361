import React from "react";

function PageTitle(props) {
  const { big, small } = props;

  return (
    <div className="main-layout-wrapper__head">
      <div className="left">
        <span className="title">
          <h1>{big}</h1>
          <span>{small} </span>
        </span>
        {/* <span className="deactive">Ssenari deaktivdir</span> */}
      </div>
    </div>
  );
}

export default PageTitle;
